<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>

  <div class="row">
    <div class="col-12">
      <card>
        <h4 v-if="changelog_only" class="card-title">Changelog</h4>
        <div v-if="!changelog_only" class="row mb-3">
          <div class="col-lg-8 col-9">
            <div
              class="btn-group btn-group-toggle"
              :class="'float-left'"
              data-toggle="buttons"
            >
              <label
                v-for="(option, index) in categories"
                :key="index + j"
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: option.active }"
                :id="index"
              >
                <input
                  type="radio"
                  @click="switchTab(index, perPage, getOffset())"
                  name="options"
                  autocomplete="off"
                  :checked="option.active"
                />
                <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                <span class="d-block d-sm-none" style="font-size: xx-small; margin-right: -15px; margin-left: -15px">
                    {{ option.short_name }}
                  </span>
              </label>
            </div>
          </div>
          <div class="col text-right" v-if="hasPermissions('roadmap_create')">
            <base-button type="primary" size="sm" v-on:click="createNewTicket()">
              <i class="tim-icons el-icon-plus mr-1"></i> <span class="d-none d-sm-block">Create ticket</span>
            </base-button>
          </div>
        </div>

        <div class="d-none d-sm-block" v-if="!changelog_only">
          <div class="row" :key="j">
            <div
              class="col-lg-11"
            >
              <base-input>
                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Key</h3>
                    <div class="pop popover-body">
                      <div class="row container"  style="width: 350px">
                        <el-input
                          type="search"
                          clearable
                          prefix-icon="el-icon-search"
                          placeholder="    Key"
                          v-model="filters.key.value"
                        >
                        </el-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.key)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="getTickets(activeTabIndex, perPage, getOffset(), 0, 'filter')">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button slot="reference" :simple="!filters.key.actual" size="sm" type="primary">
                    Key <span v-if="filters.key.actual">: {{filters.key.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>
                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Summary</h3>
                    <div class="pop popover-body">
                      <div class="row container"  style="width: 350px">
                        <el-input
                          type="search"
                          clearable
                          prefix-icon="el-icon-search"
                          placeholder="    Summary"
                          v-model="filters.summary.value"
                        >
                        </el-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.summary)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="getTickets(activeTabIndex, perPage, getOffset(), 0, 'filter')">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button class="ml-2" slot="reference" :simple="!filters.summary.actual" size="sm" type="primary">
                    Summary <span v-if="filters.summary.actual">: {{filters.summary.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>

                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by ticket type</h3>
                    <div class="pop popover-body">
                      <div class="row container" style="min-width: 300px">
                        <base-input  style="min-width: 300px">
                          <el-select
                            v-model="filters.ticket_type.value"
                            class="select-primary"
                            name="game"
                            placeholder="Ticket type"
                            value-key="id"
                          >
                            <el-option
                              v-for="(t, i) in ticket_types"
                              class="select-primary"
                              :label="t.name"
                              :value="{id: t.id, name: t.name}"
                              :key="'TT' + i"
                            ></el-option>
                          </el-select>
                        </base-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.ticket_type)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="getTickets(activeTabIndex, perPage, getOffset(), 0, 'filter')">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button class="ml-2" slot="reference" :simple="!filters.ticket_type.actual" size="sm" type="primary">
                    Ticket type <span v-if="filters.ticket_type.actual">: {{filters.ticket_type.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>

                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by feature</h3>
                    <div class="pop popover-body">
                      <div class="row container" style="min-width: 300px">
                        <base-input  style="min-width: 300px">
                          <el-select
                            v-model="filters.feature.value"
                            class="select-primary"
                            name="game"
                            placeholder="Feature"
                            value-key="id"
                          >
                            <el-option
                              v-for="(f, i) in features"
                              class="select-primary"
                              :label="f.name"
                              :value="{id: f.id, name: f.name}"
                              :key="'FS' + i"
                            ></el-option>
                          </el-select>
                        </base-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.feature)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="getTickets(activeTabIndex, perPage, getOffset(), 0, 'filter')">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button class="ml-2" slot="reference" :simple="!filters.feature.actual" size="sm" type="primary">
                    Feature <span v-if="filters.feature.actual">: {{filters.feature.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>

                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Reported by</h3>
                    <div class="pop popover-body">
                      <div class="row container"  style="width: 350px">
                        <el-input
                          type="search"
                          clearable
                          prefix-icon="el-icon-search"
                          placeholder="    Reported by"
                          v-model="filters.reported_by.value"
                        >
                        </el-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.reported_by)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="getTickets(activeTabIndex, perPage, getOffset(), 0, 'filter')">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button v-if="activeTabIndex !== 3" class="ml-2" slot="reference" :simple="!filters.reported_by.actual" size="sm" type="primary">
                    Reported by <span v-if="filters.reported_by.actual">: {{filters.reported_by.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>

                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by priority</h3>
                    <div class="pop popover-body">
                      <div class="row container" style="min-width: 300px">
                        <base-input  style="min-width: 300px">
                          <el-select
                            v-model="filters.priority.value"
                            class="select-primary"
                            name="game"
                            placeholder="Priority"
                            value-key="id"
                          >
                            <el-option
                              v-for="(p, i) in priorities"
                              class="select-primary"
                              :label="p.name"
                              :value="{id: p.id, name: p.name}"
                              :key="'P' + i"
                            ></el-option>
                          </el-select>
                        </base-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.priority)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="getTickets(activeTabIndex, perPage, getOffset(), 0, 'filter')">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button v-if="activeTabIndex !== 3" class="ml-2" slot="reference" :simple="!filters.priority.actual" size="sm" type="primary">
                    Priority <span v-if="filters.priority.actual">: {{filters.priority.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>

                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by status</h3>
                    <div class="pop popover-body">
                      <div class="row container" style="min-width: 300px">
                        <base-input  style="min-width: 300px">
                          <el-select
                            v-model="filters.status.value"
                            class="select-primary"
                            name="game"
                            placeholder="Status"
                            value-key="id"
                          >
                            <el-option
                              v-for="(s, i) in statuses"
                              class="select-primary"
                              :label="s.name"
                              :value="{id: s.id, name: s.name}"
                              :key="'S' + i"
                            ></el-option>
                          </el-select>
                        </base-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.status)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="getTickets(activeTabIndex, perPage, getOffset(), 0, 'filter')">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button v-if="activeTabIndex !== 3" class="ml-2" slot="reference" :simple="!filters.status.actual" size="sm" type="primary">
                    Status <span v-if="filters.status.actual">: {{filters.status.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>

                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by vote count</h3>
                    <div class="pop popover-body">
                      <div class="row container" style="width: 350px; display: flex; justify-content: space-between; gap: 10px;">
                        <el-input
                          style="flex: 1;"
                          clearable
                          placeholder="From"
                          v-model.number="filters.vote_count.value_from"
                        >
                        </el-input>
                        <div style="display: flex; align-items: center; font-size: 16px; padding: 0 5px;">to</div>

                        <el-input style="flex: 1;"
                                  clearable
                                  placeholder="To"
                                  v-model.number="filters.vote_count.value_to"
                        >
                        </el-input>
                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetFieldRange(filters.vote_count)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="getTickets(activeTabIndex, perPage, getOffset(), 0, 'filter')">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button v-if="activeTabIndex !== 3" class="ml-2" slot="reference" :simple="!filters.vote_count.actual_to || !filters.vote_count.actual_from" size="sm" type="primary">
                    Votes <span v-if="filters.vote_count.actual_from && filters.vote_count.actual_to">: {{filters.vote_count.actual_from}}-{{filters.vote_count.actual_to}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>

                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by watcher count</h3>
                    <div class="pop popover-body">
                      <div class="row container" style="width: 350px; display: flex; justify-content: space-between; gap: 10px;">
                        <el-input
                          style="flex: 1;"
                          clearable
                          placeholder="From"
                          v-model.number="filters.watcher_count.value_from"
                        >
                        </el-input>
                        <div style="display: flex; align-items: center; font-size: 16px; padding: 0 5px;">to</div>

                        <el-input style="flex: 1;"
                                  clearable
                                  placeholder="To"
                                  v-model.number="filters.watcher_count.value_to"
                        >
                        </el-input>
                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetFieldRange(filters.watcher_count)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="getTickets(activeTabIndex, perPage, getOffset(), 0, 'filter')">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button v-if="activeTabIndex !== 3" class="ml-2" slot="reference" :simple="!filters.watcher_count.actual_to || !filters.watcher_count.actual_from" size="sm" type="primary">
                    Watchers <span v-if="filters.watcher_count.actual_from && filters.watcher_count.actual_to">: {{filters.watcher_count.actual_from}}-{{filters.watcher_count.actual_to}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>

                <el-tooltip content="Reset all filters"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <base-button link class="ml-2" v-on:click="resetAll()"  style="font-size: large; color:white">
                    <i class="tim-icons icon-refresh-01"></i>
                  </base-button>
                </el-tooltip>
                <span class="text-white">Total results: {{totalRows}}</span>
              </base-input>
            </div>
            <div class="col text-right" v-if="activeTabIndex !== 3">
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="perPage"
                placeholder="Per page"
                v-on:change="getTickets(activeTabIndex, perPage, getOffset(), 0, 'filter')"
              >
                <el-option
                  class="select-primary"
                  v-for="item in perPageOptions"
                  :key="item"
                  :label="item.toString()"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>

        <div class="row d-none d-sm-block" v-if="activeTabIndex !== 3">
          <div class="col">
            <badge type="primary">{{getAvailableVoteCount(total_vote_count)}}/{{available_votes}} votes available</badge>
          </div>
        </div>

        <div class="row d-block d-sm-none" v-if="!changelog_only">
          <div class="col-12">
            <div class="row">
              <div class="col">
                <badge type="primary">{{getAvailableVoteCount(total_vote_count)}}/{{available_votes}} votes available</badge>
              </div>
              <div class="col text-right">
                <b-icon icon="filter" scale="1" class="text-white" v-on:click="show_filters = !show_filters"></b-icon>
                <b-icon icon="arrow-counterclockwise" scale="1" class="ml-2 text-white" v-on:click="resetAll()"></b-icon>
              </div>
            </div>
            <div class="row" v-if="show_filters">
              <div
                class="col-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between"
              >
                <base-input>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Key</h3>
                      <div class="pop popover-body">
                        <div class="row container"  style="width: 350px">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Key"
                            v-model="filters.key.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.key)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getTickets(activeTabIndex, perPage, getOffset(), 0, 'filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" :simple="!filters.key.actual" size="sm" type="primary">
                      Key <span v-if="filters.key.actual">: {{filters.key.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Summary</h3>
                      <div class="pop popover-body">
                        <div class="row container"  style="width: 350px">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Summary"
                            v-model="filters.summary.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.summary)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getTickets(activeTabIndex, perPage, getOffset(),0, 'filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button class="ml-2" slot="reference" :simple="!filters.summary.actual" size="sm" type="primary">
                      Summary <span v-if="filters.summary.actual">: {{filters.summary.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by ticket type</h3>
                      <div class="pop popover-body">
                        <div class="row container" style="min-width: 300px">
                          <base-input  style="min-width: 300px">
                            <el-select
                              v-model="filters.ticket_type.value"
                              class="select-primary"
                              name="game"
                              placeholder="Ticket type"
                              value-key="id"
                            >
                              <el-option
                                v-for="(t, i) in ticket_types"
                                class="select-primary"
                                :label="t.name"
                                :value="{id: t.id, name: t.name}"
                                :key="'TT' + i"
                              ></el-option>
                            </el-select>
                          </base-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.ticket_type)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getTickets(activeTabIndex, perPage, getOffset(), 0, 'filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button class="ml-2" slot="reference" :simple="!filters.ticket_type.actual" size="sm" type="primary">
                      Ticket type <span v-if="filters.ticket_type.actual">: {{filters.ticket_type.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by feature</h3>
                      <div class="pop popover-body">
                        <div class="row container" style="min-width: 300px">
                          <base-input  style="min-width: 300px">
                            <el-select
                              v-model="filters.feature.value"
                              class="select-primary"
                              name="game"
                              placeholder="Feature"
                              value-key="id"
                            >
                              <el-option
                                v-for="(f, i) in features"
                                class="select-primary"
                                :label="f.name"
                                :value="{id: f.id, name: f.name}"
                                :key="'FS' + i"
                              ></el-option>
                            </el-select>
                          </base-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.feature)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getTickets(activeTabIndex, perPage, getOffset(), 0, 'filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button class="ml-2" slot="reference" :simple="!filters.feature.actual" size="sm" type="primary">
                      Feature <span v-if="filters.feature.actual">: {{filters.feature.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Reported by</h3>
                      <div class="pop popover-body">
                        <div class="row container"  style="width: 350px">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Reported by"
                            v-model="filters.reported_by.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.reported_by)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getTickets(activeTabIndex, perPage, getOffset(), 0, 'filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button class="ml-2" slot="reference" :simple="!filters.reported_by.actual" size="sm" type="primary">
                      Reported by <span v-if="filters.reported_by.actual">: {{filters.reported_by.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by priority</h3>
                      <div class="pop popover-body">
                        <div class="row container" style="min-width: 300px">
                          <base-input  style="min-width: 300px">
                            <el-select
                              v-model="filters.priority.value"
                              class="select-primary"
                              name="game"
                              placeholder="Priority"
                              value-key="id"
                            >
                              <el-option
                                v-for="(p, i) in priorities"
                                class="select-primary"
                                :label="p.name"
                                :value="{id: p.id, name: p.name}"
                                :key="'P' + i"
                              ></el-option>
                            </el-select>
                          </base-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.priority)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getTickets(activeTabIndex, perPage, getOffset(), 0, 'filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button class="ml-2" slot="reference" :simple="!filters.priority.actual" size="sm" type="primary">
                      Priority <span v-if="filters.priority.actual">: {{filters.priority.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by status</h3>
                      <div class="pop popover-body">
                        <div class="row container" style="min-width: 300px">
                          <base-input  style="min-width: 300px">
                            <el-select
                              v-model="filters.status.value"
                              class="select-primary"
                              name="game"
                              placeholder="Status"
                              value-key="id"
                            >
                              <el-option
                                v-for="(s, i) in statuses"
                                class="select-primary"
                                :label="s.name"
                                :value="{id: s.id, name: s.name}"
                                :key="'S' + i"
                              ></el-option>
                            </el-select>
                          </base-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.status)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getTickets(activeTabIndex, perPage, getOffset(), 0, 'filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button class="ml-2" slot="reference" :simple="!filters.status.actual" size="sm" type="primary">
                      Status <span v-if="filters.status.actual">: {{filters.status.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by vote count</h3>
                      <div class="pop popover-body">
                        <div class="row container" style="width: 350px; display: flex; justify-content: space-between; gap: 10px;">
                          <el-input
                            style="flex: 1;"
                            clearable
                            placeholder="From"
                            v-model.number="filters.vote_count.value_from"
                          >
                          </el-input>
                          <div style="display: flex; align-items: center; font-size: 16px; padding: 0 5px;">to</div>

                          <el-input style="flex: 1;"
                                    clearable
                                    placeholder="To"
                                    v-model.number="filters.vote_count.value_to"
                          >
                          </el-input>
                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetFieldRange(filters.vote_count)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getTickets(activeTabIndex, perPage, getOffset(), 0, 'filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button class="ml-2" slot="reference" :simple="!filters.vote_count.actual_to || !filters.vote_count.actual_from" size="sm" type="primary">
                      Votes <span v-if="filters.vote_count.actual_from && filters.vote_count.actual_to">: {{filters.vote_count.actual_from}}-{{filters.vote_count.actual_to}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by watcher count</h3>
                      <div class="pop popover-body">
                        <div class="row container" style="width: 350px; display: flex; justify-content: space-between; gap: 10px;">
                          <el-input
                            style="flex: 1;"
                            clearable
                            placeholder="From"
                            v-model.number="filters.watcher_count.value_from"
                          >
                          </el-input>
                          <div style="display: flex; align-items: center; font-size: 16px; padding: 0 5px;">to</div>

                          <el-input style="flex: 1;"
                                    clearable
                                    placeholder="To"
                                    v-model.number="filters.watcher_count.value_to"
                          >
                          </el-input>
                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetFieldRange(filters.watcher_count)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getTickets(activeTabIndex, perPage, getOffset(), 0, 'filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button class="ml-2" slot="reference" :simple="!filters.watcher_count.actual_to || !filters.watcher_count.actual_from" size="sm" type="primary">
                      Watchers <span v-if="filters.watcher_count.actual_from && filters.watcher_count.actual_to">: {{filters.watcher_count.actual_from}}-{{filters.watcher_count.actual_to}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                </base-input>
              </div>
            </div>
          </div>
        </div>

        <div v-if="activeTabIndex !== 3 && hasPermissions('roadmap_view')">
          <div class="mt-3" v-if="tickets.length <= 0">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No tickets match your search.</h4>
            </div>
          </div>

          <div class="row d-sm-block d-none" v-if="tickets.length > 0">
            <div class="col">
              <b-table striped hover :items="tickets"
                       :fields="['key', 'summary', 'ticket_type', 'feature', 'reported_by', 'priority', 'status', 'votes', 'watchers', 'actions']">
                <template v-slot:cell(summary)="row">
                  {{row.item.title}}
                  <i v-if="row.item.watching" class="tim-icons el-icon-view text-info ml-2"></i>
                </template>
                <template v-slot:cell(key)="row">
                  <span style="white-space: nowrap">
                    <a :href="'/roadmap/ticket?id=' + row.item.id">{{row.item.ticket_key}}</a>
                  </span>
                </template>
                <template v-slot:cell(feature)="row">
                  <badge :style="{'background-color': row.item.feature_badge_color}">{{row.item.feature_name}}</badge>
                </template>
                <template v-slot:cell(ticket_type)="row">
                  {{row.item.ticket_type_name}}
                </template>
                <template v-slot:cell(votes)="row">
                  {{row.item.vote_count}}
                </template>
                <template v-slot:cell(watchers)="row">
                  {{row.item.watcher_count}}
                </template>
                <template v-slot:cell(reported_by)="row">
                  <span v-if="!row.item.creator_id">-</span>
                  <span v-else><div style="font-weight: bold">{{row.item.company_name}}</div>
                    <sub class="font-italic">{{row.item.reporter_first_name}} {{row.item.reporter_last_name}}</sub>
                  </span>
                </template>
                <template v-slot:cell(priority)="row">
                   <span style="white-space: nowrap">
                    <i :class="getPriority(row.item.priority).icon" class="mr-1"
                       aria-hidden="true" :style="{'color' : getPriority(row.item.priority).color}"></i>
                        {{ getPriority(row.item.priority).name }}
                  </span>
                </template>
                <template v-slot:cell(status)="row">
                  <badge :style="{'background-color': row.item.label_color}">{{row.item.status_name}}</badge>
                </template>
                <template v-slot:cell(actions)="row">
                  <el-dropdown>
                    <i class="text-primary tim-icons el-icon-more"></i>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <a target="_blank" class="dropdown-item mt-2 mb-2" :href="'/roadmap/ticket?id=' + row.item.id">
                          <i class="tim-icons icon-notes text-info mr-2"></i> Ticket details
                        </a>
                        <a v-if="row.item.status === 5 && activeTabIndex === 1" target="_blank" class="dropdown-item mt-2 mb-2" @click="modifyTicket(row.item, 'open', 0, $event)" href="#">
                          <i style="color: #42b883" class="tim-icons icon-refresh-02 mr-2"></i> Reopen ticket
                        </a>
                        <a v-if="hasPermissions('roadmap_watch') && !row.item.watching" class="dropdown-item mt-2 mb-2" @click="modifyTicket(row.item, 'watch', 1, $event)" href="#">
                          <i class="tim-icons el-icon-view text-default mr-2"></i> Watch ticket
                        </a>
                        <a v-if="hasPermissions('roadmap_watch') && row.item.watching" class="dropdown-item mt-2 mb-2" @click="modifyTicket(row.item, 'watch', 0, $event)" href="#">
                          <i class="tim-icons el-icon-view text-danger mr-2"></i> Stop watching ticket
                        </a>
                        <a v-if="hasPermissions('roadmap_vote') && (activeTabIndex === 0 || activeTabIndex === 1) && row.item.status !== 4 && row.item.status !== 5 && row.item.status !== 9
                        && total_vote_count < available_votes" class="dropdown-item mt-2 mb-2" @click="modifyTicket(row.item, 'vote', 1, $event)" href="#">
                          <i style="color: #42b883" class="fa fa-thumbs-up mr-2"></i> Vote for this ticket
                        </a>
                        <a v-if="hasPermissions('roadmap_vote') && (activeTabIndex === 0 || activeTabIndex === 1) && row.item.status !== 4 && row.item.status !== 5 && row.item.status !== 9
                        && row.item.my_vote_count > 0" class="dropdown-item mt-2 mb-2" @click="modifyTicket(row.item, 'vote', 0, $event)" href="#">
                          <i class="fa fa-thumbs-down mr-2 text-danger"></i> Remove vote
                        </a>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </template>
              </b-table>
              <b-pagination v-on:input="getTickets(activeTabIndex, perPage, getOffset())"
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
              ></b-pagination>
            </div>
          </div>

          <div class="row d-sm-none d-block" v-if="tickets.length > 0">
            <div class="col">
              <b-table striped hover :items="tickets"
                       :fields="['tickets']">
                <template #head(tickets)="row">
                  <span>Total results: {{totalRows}}</span>
                </template>
                <template v-slot:cell(tickets)="row">
                  <div class="row">
                    <div class="col">
                      <h4 class="card-title">
                        <a :href="'/roadmap/ticket?id=' + row.item.id">
                        [{{row.item.ticket_key}}]
                        </a>
                        {{row.item.title}}
                        <i v-if="row.item.watching" class="tim-icons el-icon-view text-info ml-2"></i>
                      </h4>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <label>Reported by</label>
                      <p>
                        <span style="font-weight: bold">{{row.item.company_name}}</span>,
                        {{row.item.reporter_first_name}} {{row.item.reporter_last_name}}
                      </p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <label>Feature</label>
                      <div>
                        <badge :style="{'background-color': row.item.feature_badge_color}">{{row.item.feature_name}}</badge>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col">
                      <label>Ticket type</label>
                      <p>{{row.item.ticket_type_name}}</p>
                    </div>
                    <div class="col">
                      <label>Priority</label>
                      <div>
                        <i :class="getPriority(row.item.priority).icon" class="mr-1"
                           aria-hidden="true" :style="{'color' : getPriority(row.item.priority).color}"></i>
                            {{ getPriority(row.item.priority).name }}
                      </div>
                    </div>
                    <div class="col">
                      <label>Status</label>
                      <div>
                        <badge :style="{'background-color': row.item.label_color}">{{row.item.status_name}}</badge>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col">
                      <label>Vote count</label>
                      <p class="text-white">{{row.item.vote_count}}

                        <span v-if="hasPermissions('roadmap_vote') && activeTabIndex !== 2 && ((row.item.status !== 4 && row.item.status !== 5 && row.item.status !== 9
                          && total_vote_count < available_votes) ||
                          (row.item.status !== 4 && row.item.status !== 5 && row.item.status !== 9
                          && row.item.my_vote_count > 0))">
                          <el-dropdown >
                            <i class="text-primary tim-icons el-icon-more ml-2"></i>
                            <template #dropdown>
                              <el-dropdown-menu>
                                <a v-if="hasPermissions('roadmap_vote') && row.item.status !== 4 && row.item.status !== 5 && row.item.status !== 9
                                && total_vote_count < available_votes" class="dropdown-item mt-2 mb-2" @click="modifyTicket(row.item, 'vote', 1, $event)" href="#">
                                  <i style="color: #42b883" class="fa fa-thumbs-up mr-2"></i> Vote for this ticket
                                </a>
                                <a v-if="hasPermissions('roadmap_vote') && row.item.status !== 4 && row.item.status !== 5 && row.item.status !== 9
                                && row.item.my_vote_count > 0" class="dropdown-item mt-2 mb-2" @click="modifyTicket(row.item, 'vote', 0, $event)" href="#">
                                  <i class="fa fa-thumbs-down mr-2 text-danger"></i> Remove vote
                                </a>
                              </el-dropdown-menu>
                            </template>
                          </el-dropdown>
                        </span>
                      </p>
                    </div>
                    <div class="col">
                      <label>Watcher count</label>
                      <p class="text-white">{{row.item.watcher_count}}
                        <span v-if="hasPermissions('roadmap_watch')">
                          <el-dropdown>
                            <i class="text-primary tim-icons el-icon-more ml-2"></i>
                            <template #dropdown>
                              <el-dropdown-menu>
                                <a v-if="!row.item.watching" class="dropdown-item mt-2 mb-2" @click="modifyTicket(row.item, 'watch', 1, $event)" href="#">
                                  <i class="tim-icons el-icon-view text-default mr-2"></i> Watch ticket
                                </a>
                                <a v-if="row.item.watching" class="dropdown-item mt-2 mb-2" @click="modifyTicket(row.item, 'watch', 0, $event)" href="#">
                                  <i class="tim-icons el-icon-view text-danger mr-2"></i> Stop watching ticket
                                </a>
                              </el-dropdown-menu>
                            </template>
                          </el-dropdown>
                        </span>
                      </p>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>

        <div class="mb-3" v-if="activeTabIndex === 3 || changelog_only">
          <a href="/roadmap/legacy">View legacy changelog</a>
        </div>

        <div v-if="activeTabIndex === 3 || changelog_only">
          <div class="mt-3" v-if="tickets.length <= 0">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No tickets match your search.</h4>
            </div>
          </div>

          <div class="row" v-for="(w, i) in tickets" :key="'CH' + i">
            <div class="col">
              <h4 class="card-title">{{w.title}}</h4>
              <div class="row" v-for="(tt, i) in w.ticketsByType" :key="'TTCH' + i">
                <div class="col">
                  <p :style="{'color': tt.ticket_type_label_color}" class="text-uppercase">{{ tt.type }}</p>
                  <ul>
                    <li v-for="(ti, i) in tt.tickets" :key="'TICKS' + i">
                      <badge :style="{'background-color': ti.feature_badge_color}">{{ti.feature_name}}</badge>&nbsp;
                      {{ti.title}}
                      <router-link v-if="!changelog_only" tag="a" :to="'/roadmap/ticket/?id='+ ti.id">
                        <i class="tim-icons el-icon-top-right"></i>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>

    <modal :show.sync="modals.new_ticket"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4 class="card-title">Create a ticket</h4>
        <div class="row">
          <div class="col">
            <base-input required label="Ticket type" :error="errs.ticket_type">
              <el-select
                v-model="ticket.ticket_type"
                class="select-primary"
                value-key="id"
                placeholder="Ticket type">
                <el-option
                  v-for="(t, index) in ticket_types"
                  class="select-primary"
                  :label="t.name"
                  :value="{id: t.id,
                          name: t.name}"
                  :key="'T' + index"
                ></el-option>
              </el-select>
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <base-input required label="Feature" :error="errs.feature">
              <el-select
                v-model="ticket.feature"
                class="select-primary"
                value-key="id"
                placeholder="Feature">
                <el-option
                  v-for="(f, index) in features"
                  class="select-primary"
                  :label="f.name"
                  :value="{id: f.id,
                          name: f.name}"
                  :key="'F' + index"
                ></el-option>
              </el-select>
            </base-input>
          </div>
        </div>


        <div class="row">
          <div class="col">
            <base-input label="Ticket summary" :error="errs.summary" placeholder="Summary" required v-model="ticket.title"></base-input>
          </div>
        </div>
        <p style="font-size: small" class="text-white">Details</p>
        <div style="background-color: white; border-radius: 5px;">
          <div id="editor-container" style="background-color: white; border-radius: 5px;"></div>
        </div>
        <div class="row text-center mt-3">
          <div class="col">
            <base-button type="secondary" @click="modals.new_ticket = false">Cancel</base-button>
            <base-button class="ml-3" type="primary" @click="createTicket()">Create</base-button>
          </div>
        </div>
      </card>
    </modal>
  </div>
</div>
</template>

<script>
import {Badge} from 'src/components'
import {BPagination, BTable} from "bootstrap-vue";
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import swal from "sweetalert2";
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import {localDateTime} from "@/plugins/dates";
import {Dropdown, DropdownItem, DropdownMenu} from "element-ui";
import BaseAlert from "@/components/BaseAlert";
import _ from "lodash";

export default {
  name: "Changelog",
  components: {
    Badge,
    BTable,
    BPagination,
    BaseAlert,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
  },
  computed: {
    categories() {
      if(this.hasPermissions('roadmap_view')){
        return [{name: 'Open tickets', short_name: 'Open', icon: 'tim-icons icon-gift-2', active: true},
          {name: 'My tickets', short_name: 'My tickets', icon: 'tim-icons icon-settings-gear-63', active: false},
          {name: 'Work in progress', short_name: 'In progress', icon: 'tim-icons icon-settings-gear-63', active: false},
          {name: 'Changelog', short_name: 'Changelog', icon: 'tim-icons icon-settings-gear-63', active: false},
        ];
      } else {
        return [];
      }

    },
  },
  data(){
    return {
      loading: false,
      changelog_only: false,
      show_filters: false,
      total_vote_count: 0,
      perPageOptions: [5, 10, 25, 50],
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      quill: null,
      quill_comment: null,
      fields: ["component", "description"],
      modals: { new_ticket: false},
      selectedOption: 'Bug',
      feature: '',
      details: '',
      activeTabIndex: 0,
      j: 0,
      errs: {
        ticket_type: '',
        feature: '',
        summary: ''
      },
      ticket_types: [],
      statuses: [],
      features: [],
      priorities: [
        {id: 1, name: "Highest", icon: 'fa fa-angle-double-up', color: '#ec250d'},
        {id: 2, name: "High", icon: 'fa fa-angle-up', color: '#ec250d'},
        {id: 3, name: "Medium", icon: 'tim-icons icon-simple-delete', color: '#ff8d72'},
        {id: 4, name: "Low", icon: 'fa fa-angle-down', color: '#1d8cf8'},
        {id: 5, name: "Lowest", icon: 'fa fa-angle-double-down', color: '#1d8cf8'},
      ],
      ticket: {},
      tickets: [],
      comments: [],
      available_votes: 5,
      filters: {
        key: {
          value: '',
          actual: ''
        },
        summary: {
          value: '',
          actual: ''
        },
        ticket_type: {
          value: '',
          actual: ''
        },
        feature: {
          value: '',
          actual: ''
        },
        reported_by: {
          value: '',
          actual: ''
        },
        priority: {
          value: '',
          actual: ''
        },
        status: {
          value: '',
          actual: ''
        },
        vote_count:{
          value_from: '',
          actual_from: '',
          value_to: '',
          actual_to: '',
        },
        watcher_count: {
          value: '',
          actual: ''
        },
      }
    }
  },
  methods: {
    hasPermissions(scope){
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      const matchFound = permissions.some(element => element === scope);
      return matchFound
    },
    resetFieldRange(field){
      field.actual_from = ''
      field.value_from = ''
      field.actual_to = ''
      field.value_to = ''
      this.getTickets(this.activeTabIndex, this.perPage, this.getOffset(), 0, 'filter')
    },
    resetField(field){
      field.value = ''
      field.actual = ''
      this.getTickets(this.activeTabIndex, this.perPage, this.getOffset(), 0, 'filter')
    },
    getAvailableVoteCount(count){
      return (this.available_votes - count) >= 0 ? (this.available_votes - count) : 0
    },
    async createComment(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/work/' + this.ticket.id + '/comments'

      let data = {
        comment: this.quill_comment.root.innerHTML
      }

      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.quill_comment.root.innerHTML = ''
          this.getTicket(this.ticket)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    },
    async modifyTicket(item, action, modifier, event){
      event.preventDefault()
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/work/' + item.id

      let data = {
        action: action,
        modifier: modifier
      }

      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          let text = 'You are now watching this ticket'
          if(action === 'vote' && modifier === 1){
            text = 'You added a vote to the ticket'
          } else if(action === 'vote' && modifier === 0){
            text = 'You removed a vote from the ticket'
          }  else if(action === 'watch' && modifier === 0){
            text = 'You stopped watching this ticket'
          } else if(action === 'open'){
            text = 'The ticket has been reopened'
          }
          swal('Success', text, 'success')
          this.getTickets(this.activeTabIndex, this.perPage, this.getOffset())
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } if(err.response.status == 500){
            swal({
              title: 'Error',
              html: err.response.data,
              type: 'error',
            })
          }
        })
    },
    async getTicket(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/work/' + item.id

      axios.get(url, config)
        .then(response => {
          this.loading = false
          this.ticket = response.data.ticket
          this.comments = response.data.comments
          this.ticket.ticket_type = _.find(this.ticket_types, {id: this.ticket.type_id})
          this.ticket.priority = this.getPriority(this.ticket.priority)
          this.ticket.titleFixed = this.ticket.title
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    },
    getLocalDateTime(d){
      return localDateTime(d, this.date_format, this.time_format)
    },
    getPriority(priority){
      let p = _.find(this.priorities, {id: priority})
      return p
    },
    validateTicket(){
      let errors = 0

      if(!this.ticket.title){
        this.errs.summary = "Summary cannot be empty"
        errors++
      } else {
        this.errs.summary = ''
      }
      if(!this.ticket.feature){
        this.errs.feature = "Feature cannot be empty"
        errors++
      } else {
        this.errs.feature = ''
      }
      if(!this.ticket.ticket_type){
        this.errs.ticket_type = "Ticket type cannot be empty"
        errors++
      } else {
        this.errs.ticket_type = ''
      }

      return errors > 0 ? false : true
    },
    createNewTicket(){
      this.ticket = {
        ticket_type: {id: 3},
        priority: {id: 3}
      }
      this.quill.deleteText(0, this.quill.getLength());
      this.quill.format('color', 'black'); // Apply formatting
      this.modals.new_ticket = true
    },
    resetAll(){
      this.filters= {
        key: {
          value: '',
          actual: ''
        },
        summary: {
          value: '',
          actual: ''
        },
        ticket_type: {
          value: '',
          actual: ''
        },
        feature: {
          value: '',
          actual: ''
        },
        reported_by: {
          value: '',
          actual: ''
        },
        priority: {
          value: '',
          actual: ''
        },
        status: {
          value: '',
          actual: ''
        },
        vote_count:{
          value_from: '',
          actual_from: '',
          value_to: '',
          actual_to: '',
        },
        watcher_count: {
          value_from: '',
          actual_from: '',
          value_to: '',
          actual_to: '',
        },
      }
      this.getTickets(this.activeTabIndex, this.perPage, this.getOffset(), 0, 'filter')
    },
    switchTab(index, count, offset, init){
      this.activeTabIndex = index
      for(let n in this.categories){
        this.categories[n].active = false
      }
      this.categories[index].active = true

      this.currentPage = 1
      this.getTickets(this.activeTabIndex, count, offset, init)
      this.j++
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    groupTicketsByWeek(tickets) {
      // Helper function to get the week key, title, and start day of the week
      function getWeekKeyAndTitle(dateString) {
        if (dateString) {
          const date = new Date(dateString);
          const year = date.getFullYear();
          const startOfYear = new Date(year, 0, 1);
          const daysDifference = Math.floor((date - startOfYear) / (1000 * 60 * 60 * 24));
          const weekNumber = Math.ceil((daysDifference + startOfYear.getDay() + 1) / 7);

          // Calculate the start date of the week in local timezone
          const weekStartDate = new Date(date);
          const dayOfWeek = weekStartDate.getDay(); // Get day of the week (0 = Sunday, 6 = Saturday)
          const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // Adjust to Monday (or to previous Monday if Sunday)
          weekStartDate.setDate(weekStartDate.getDate() + diffToMonday);

          const month = weekStartDate.toLocaleString("default", { month: "long" });
          const day = weekStartDate.getDate();
          const title = `${month} ${day}, ${year}`;
          return {
            weekKey: `${year}-W${String(weekNumber).padStart(2, "0")}`,
            title
          };
        }
        return null;
      }

      const grouped = tickets.reduce((result, ticket) => {
        const keyAndTitle = getWeekKeyAndTitle(ticket.time_resolved);
        if (keyAndTitle) {
          const { weekKey, title } = keyAndTitle;
          if (!result[weekKey]) {
            result[weekKey] = { title, tickets: [] };
          }
          result[weekKey].tickets.push(ticket);
        }
        return result;
      }, {});

      // Convert to an array and sort by weekKey in descending order
      return Object.entries(grouped)
        .map(([weekKey, data]) => ({
          weekKey,
          title: data.title,
          ticketsByType: Object.entries(
            data.tickets.reduce((types, ticket) => {
              const type = ticket.ticket_type_name || "Unknown";
              if (!types[type]) {
                types[type] = { tickets: [], ticket_type_label_color: ticket.ticket_type_label_color || null };
              }
              types[type].tickets.push(ticket);
              return types;
            }, {})
          ).map(([type, { tickets, ticket_type_label_color }]) => ({
            type,
            ticket_type_label_color,
            tickets
          }))
        }))
        .sort((a, b) => b.weekKey.localeCompare(a.weekKey));
    },
    async getTickets(tab, count, offset, init, source){
      if(source === 'filter'){
        this.currentPage = 1
      }
      this.filters.key.actual = this.filters.key.value
      this.filters.summary.actual = this.filters.summary.value
      this.filters.reported_by.actual = this.filters.reported_by.value
      this.filters.vote_count.actual_to = this.filters.vote_count.value_to
      this.filters.vote_count.actual_from = this.filters.vote_count.value_from
      this.filters.watcher_count.actual_to = this.filters.watcher_count.value_to
      this.filters.watcher_count.actual_from = this.filters.watcher_count.value_from

      this.filters.feature.actual = this.filters.feature.value.name
      let feature = this.filters.feature.value.id
      if(init && this.$route.query.feature !== ''){
        feature = Number(this.$route.query.feature)
      }
      if(feature === undefined || isNaN(feature)){
        feature = ''
      }

      this.filters.ticket_type.actual = this.filters.ticket_type.value.name
      let ticket_type = this.filters.ticket_type.value.id
      if(init && this.$route.query.ticket_type !== ''){
        ticket_type = Number(this.$route.query.ticket_type)
      }
      if(ticket_type === undefined || isNaN(ticket_type)){
        ticket_type = ''
      }

      this.filters.status.actual = this.filters.status.value.name
      let status = this.filters.status.value.id
      if(init && this.$route.query.status !== ''){
        status = Number(this.$route.query.status)
      }
      if(status === undefined || isNaN(status)){
        status = ''
      }

      this.filters.priority.actual = this.filters.priority.value.name
      let priority = this.filters.priority.value.id
      if(init && this.$route.query.priority !== ''){
        priority = Number(this.$route.query.priority)
      }
      if(priority === undefined || isNaN(priority)){
        priority = ''
      }

      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/work?tab=' + tab +
        "&summary=" + encodeURIComponent(this.filters.summary.actual)
        +"&key=" + encodeURIComponent(this.filters.key.actual)
        +"&reported_by=" + encodeURIComponent(this.filters.reported_by.actual)
        +"&feature=" + encodeURIComponent(feature)
        +"&ticket_type=" + encodeURIComponent(ticket_type)
        +"&status=" + encodeURIComponent(status)
        +"&priority=" + encodeURIComponent(priority)
        +"&vote_count_to=" + encodeURIComponent(this.filters.vote_count.actual_to)
        +"&vote_count_from=" + encodeURIComponent(this.filters.vote_count.actual_from)
        +"&watcher_count_to=" + encodeURIComponent(this.filters.watcher_count.actual_to)
        +"&watcher_count_from=" + encodeURIComponent(this.filters.watcher_count.actual_from) +
        '&count=' + count + '&offset=' + offset

      axios.get(url, config)
        .then(response => {
          this.loading = false
          this.ticket_types = response.data.ticket_types
          this.features = response.data.features
          this.tickets = response.data.tickets
          if(this.activeTabIndex === 3 || this.changelog_only){
            this.tickets = this.groupTicketsByWeek(this.tickets)
          }
          this.total_vote_count = response.data.total_vote_count
          this.statuses = response.data.statuses
          this.totalRows = response.data.total_rows

          if(!this.changelog_only){
            if(init && this.$route.query.feature) {
              this.$nextTick(() => {
                let featureObj = _.find(this.features, {id: feature})
                this.filters.feature.value = featureObj
                this.filters.feature.actual = featureObj ? featureObj.name : ''
                this.j++
              });
            }
            if(init && this.$route.query.status) {
              this.$nextTick(() => {
                let statusObj = _.find(this.statuses, {id: status})
                this.filters.status.value = statusObj
                this.filters.status.actual = statusObj ? statusObj.name : ''
                this.j++
              });
            }
            if(init && this.$route.query.ticket_type) {
              this.$nextTick(() => {
                let typeObj = _.find(this.ticket_types, {id: ticket_type})
                this.filters.ticket_type.value = typeObj
                this.filters.ticket_type.actual = typeObj ? typeObj.name : ''
                this.j++
              });
            }
            if(init && this.$route.query.priority) {
              this.$nextTick(() => {
                let priorityObj = _.find(this.priorities, {id: priority})
                this.filters.priority.value = priorityObj
                this.filters.priority.actual = priorityObj ? priorityObj.name : ''
                this.j++
              });
            }

            this.addQueryParameters()
          }
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    },
    async createTicket(){
      if(!this.validateTicket()){
        return
      }

      if(JSON.parse(localStorage.getItem('profile')).first_name === '' ||
        !JSON.parse(localStorage.getItem('profile')).first_name ||
        JSON.parse(localStorage.getItem('profile')).last_name === '' ||
        !JSON.parse(localStorage.getItem('profile')).last_name){
        swal('Error', 'Please add your first and last name to your profile before creating a ticket', 'error')
        return
      }

      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/work'

      let data = {
        title: this.ticket.title,
        description: this.getEditorContent(),
        type_id: this.ticket.ticket_type.id,
        feature_id: this.ticket.feature.id
      }

      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.modals.new_ticket = false
          this.getTickets(this.activeTabIndex, this.perPage, this.getOffset())
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    },
    getEditorContent() {
      // Get the HTML content of the editor
      return this.quill.root.innerHTML;
    },
    addQueryParameters() {
      this.$nextTick(() => {
        this.currentPage = this.$route.query.page
      });

      const newQuery = {
        ...this.$route.query,
        tab: this.activeTabIndex,
        count: this.perPage,
        page: this.currentPage,
        key: this.filters.key.value,
        summary: this.filters.summary.value,
        reported_by: this.filters.reported_by.value,
        feature: this.filters.feature.value.hasOwnProperty('id') ? this.filters.feature.value.id : this.filters.feature.value,
        ticket_type: this.filters.ticket_type.value.hasOwnProperty('id') ? this.filters.ticket_type.value.id : this.filters.ticket_type.value,
        status: this.filters.status.value.hasOwnProperty('id') ? this.filters.status.value.id : this.filters.status.value,
        priority: this.filters.priority.value.hasOwnProperty('id') ? this.filters.priority.value.id : this.filters.priority.value,
        vote_count_to: this.filters.vote_count.value_to,
        vote_count_from: this.filters.vote_count.value_from,
        watcher_count_to: this.filters.watcher_count.value_to,
        watcher_count_from: this.filters.watcher_count.value_from
      }
      this.$router.replace({query: newQuery}).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    },
    getOffset(){
      return Number((this.currentPage * this.perPage) - this.perPage)
    },
  },
  mounted(){
    const toolbarOptions = [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
      ['link', 'image'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'color': [
          "#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
          "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
          "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
          "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
          "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"
        ]}, { 'background': [] }],          // dropdown with defaults from theme
      ['clean']                                         // remove formatting button
    ];

    this.quill = new Quill('#editor-container', {
      theme: 'snow', // Choose 'snow' or 'bubble'
      formats: [
        'header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
        'list', 'indent', 'link', 'image', 'color', 'background'
      ],
      placeholder: 'Add ticket description...',
      modules: {
        toolbar: toolbarOptions
      },
    });

    this.quill.format('color', 'black');
    this.quill.root.style.color = 'black';
    this.quill.on('text-change', (delta, oldDelta, source) => {
      // Check if the editor is empty
      if (this.quill.getLength() === 1) {
        this.quill.format('color', 'black'); // Apply black formatting if editor is empty
      }

      // Ensure new lines inherit black text
      const selection = this.quill.getSelection();
      const lastOp = delta.ops[delta.ops.length - 1];
      if (lastOp && lastOp.insert === '\n') {
        // Parse the HTML to find the latest <li>
        const listItems = this.quill.root.querySelectorAll('li');
        const lastListItem = listItems[listItems.length - 1];

        if (lastListItem) {
          // Check if the <li> already contains a <span> with color styling
          const hasColorSpan = lastListItem.querySelector('span[style*="color"]');
          if (!hasColorSpan) {
            // Wrap the text inside <li> with a <span> and apply color styling
            const listItemText = lastListItem.innerHTML;
            lastListItem.innerHTML = `<span style="color: black;">${listItemText}</span>`;
          }
        }
      }
    });


    this.filters = {
      key: {
        value: this.$route.query.key ? this.$route.query.key : '',
        actual: this.$route.query.key ? this.$route.query.key : ''
      },
      summary: {
        value: this.$route.query.summary ? this.$route.query.summary : '',
        actual: this.$route.query.summary ? this.$route.query.summary : ''
      },
      feature: {
        value: this.$route.query.feature ? this.$route.query.feature : '',
        actual: this.$route.query.feature ? this.$route.query.feature : ''
      },
      ticket_type: {
        value: this.$route.query.ticket_type ? this.$route.query.ticket_type : '',
        actual: this.$route.query.ticket_type ? this.$route.query.ticket_type : ''
      },
      status: {
        value: this.$route.query.status ? this.$route.query.status : '',
        actual: this.$route.query.status ? this.$route.query.status : ''
      },
      reported_by: {
        value: this.$route.query.reported_by ? this.$route.query.reported_by : '',
        actual: this.$route.query.reported_by ? this.$route.query.reported_by : ''
      },
      priority: {
        value: this.$route.query.priority ? this.$route.query.priority : '',
        actual: this.$route.query.priority ? this.$route.query.priority : ''
      },
      vote_count: {
        value_to: this.$route.query.vote_count_to ? this.$route.query.vote_count_to : '',
        actual_to: this.$route.query.vote_count_to ? this.$route.query.vote_count_to : '',
        value_from: this.$route.query.vote_count_from ? this.$route.query.vote_count_from : '',
        actual_from: this.$route.query.vote_count_from ? this.$route.query.vote_count_from : ''
      },
      watcher_count: {
        value_to: this.$route.query.watcher_count_to ? this.$route.query.watcher_count_to : '',
        actual_to: this.$route.query.watcher_count_to ? this.$route.query.watcher_count_to : '',
        value_from: this.$route.query.watcher_count_from ? this.$route.query.watcher_count_from : '',
        actual_from: this.$route.query.watcher_count_from ? this.$route.query.watcher_count_from : ''
      },
    }

    this.perPage = this.$route.query.count ? Number(this.$route.query.count) : 10
    this.currentPage = this.$route.query.page ? Number(this.$route.query.page) : 1
    if(!this.hasPermissions('roadmap_view')){
      this.changelog_only = true
      this.getTickets(3, 1, 10000)
    } else {
      let tab = this.$route.query.hasOwnProperty('tab') ? this.$route.query.tab : 0
      this.switchTab(Number(tab), this.perPage, this.getOffset(), 1)
    }

  }
}
</script>

<style scoped>

</style>
