<template>
<div>
  <!--    Breadcrumbs-->
  <breadcrumb style="margin-left: -15px">
    <breadcrumb-item>
      <a href="/settings/company">Company settings</a>
    </breadcrumb-item>
    <breadcrumb-item>
      <a href="/settings/migration">Data migration</a>
    </breadcrumb-item>
    <breadcrumb-item>
      <span>{{ log.type }}
        <span v-if="isDuplicate">[Duplicates]</span>
      </span>
    </breadcrumb-item>
  </breadcrumb>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>

  <div class="row">
    <div class="col">
      <card>
        <div class="row">
          <div class="col">
            <h4 style="margin-bottom: 0px;">{{ log.type }} <span class="mr-2" v-if="isDuplicate">[Duplicates]</span>
              <badge>{{log.number_of_records}} records</badge></h4>
            <small style=" margin-top:0; font-style: italic">Uploaded by {{log.first_name}} {{log.last_name}}
              on  {{getLocalDate(log.timestamp)}}, {{getLocalTime(log.timestamp)}}
            </small>
          </div>
          <div class="col text-right">
            <base-button icon class="btn-link text-white" v-on:click="downloadCSV()">
              <i class="tim-icons icon-cloud-download-93"></i>
            </base-button>
            <el-select
              class="select-primary mb-3 pagination-select"
              v-model="perPage"
              placeholder="Per page"
              v-on:change="getMigrationRecords(perPage, getOffset(), 'filter')"
            >
              <el-option
                class="select-primary"
                v-for="(item, index) in perPageOptions"
                :key="'B' + index"
                :label="item.toString()"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>


        <div class="row mt-2" style="overflow-x: scroll">
          <div class="col">
            <b-table :items="migration_records"
                     >
              <template v-slot:cell(type_name)="row">
                <badge>{{row.item.type_name}}</badge>
              </template>
              <template v-slot:cell(opted_out_email)="row">
                <i v-if="row.item.opted_out_email" class="tim-icons icon-check-2 text-success"></i>
                <i v-else-if="!row.item.opted_out_email" class="tim-icons icon-simple-remove text-danger"></i>
              </template>
              <template v-slot:cell(opted_out_photo_sharing)="row">
                <i v-if="row.item.opted_out_photo_sharing" class="tim-icons icon-check-2 text-success"></i>
                <i v-else-if="!row.item.opted_out_photo_sharing" class="tim-icons icon-simple-remove text-danger"></i>
              </template>
              <template v-slot:cell(birthday)="row">
                <span v-if="row.item.birthday !== '0000-00-00'"> {{getFormattedDate(row.item.birthday)}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(date_joined)="row">
                <span v-if="!row.item.date_joined.includes('0000-00-00')"> {{getLocalDate(row.item.date_joined)}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(date_last_visited)="row">
                <span v-if="!row.item.date_last_visited.includes('0000-00-00')"> {{getLocalDate(row.item.date_last_visited)}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(start_date)="row">
                <span v-if="row.item.start_date !== '0000-00-00'"> {{getFormattedDate(row.item.start_date)}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(end_date)="row">
                <span v-if="row.item.end_date !== '0000-00-00'"> {{getFormattedDate(row.item.end_date)}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(delivery_date)="row">
                <span v-if="row.item.delivery_date !== '0000-00-00'"> {{getFormattedDate(row.item.delivery_date)}}</span>
              </template>
              <template v-slot:cell(date_purchased)="row">
                <span v-if="row.item.date_purchased !== '0000-00-00'"> {{getFormattedDate(row.item.date_purchased)}}</span>
              </template>
              <template v-slot:cell(start_time)="row">
                <span v-if="row.item.start_time"> {{getTime(row.item.start_time)}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(end_time)="row">
                <span v-if="row.item.end_time"> {{getTime(row.item.end_time)}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(status)="row">
                <badge type="primary"> {{row.item.status}}</badge>
              </template>
              <template v-slot:cell(total_value)="row">
                {{getAmount(row.item.total_value)}}
              </template>
              <template v-slot:cell(price)="row">
                {{getAmount(row.item.price)}}
              </template>
              <template v-slot:cell(paid)="row">
                {{getAmount(row.item.paid)}}
              </template>
              <template v-slot:cell(due)="row">
                {{getAmount(row.item.due)}}
              </template>
              <template v-slot:cell(taxes)="row">
                {{getAmount(row.item.taxes)}}
              </template>
              <template v-slot:cell(fees)="row">
                {{getAmount(row.item.fees)}}
              </template>
              <template v-slot:cell(total)="row">
                {{getAmount(row.item.total)}}
              </template>
              <template v-slot:cell(refunded)="row">
                {{getAmount(row.item.refunded)}}
              </template>
              <template v-slot:cell(value_amount)="row">
                {{getAmount(row.item.value_amount)}}
              </template>
              <template v-slot:cell(remaining)="row">
                {{getAmount(row.item.remaining)}}
              </template>
              <template v-slot:cell(spent)="row">
                {{getAmount(row.item.spent)}}
              </template>
              <template v-slot:cell(redemption_type)="row">
                <badge v-if="!row.item.redemption_type">percentage</badge>
                <badge v-if="row.item.redemption_type">value</badge>
              </template>
              <template v-slot:cell(order_number)="row">
                #{{row.item.order_number}}
              </template>
              <template v-slot:cell(date_created)="row">
                {{getLocalDate(row.item.date_created)}}, {{getLocalTime(row.item.date_created)}}
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              v-on:input="getMigrationRecords(perPage, getOffset())"
            ></b-pagination>
          </div>
        </div>
      </card>
    </div>
  </div>
</div>
</template>

<script>
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import { BTable, BPagination } from 'bootstrap-vue'
import {BaseAlert} from "@/components";
import Badge from "@/components/Badge";
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import {dayOfWeek, formattedDate, time} from "@/plugins/dateFormatter";
import axios from "axios";
import swal from "sweetalert2";

export default {
  name: "MigrationLog",
  data(){
    return {
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      loading: false,
      isDuplicate: false,
      migration_records: [],
      log: '',
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      perPageOptions: [5, 10, 25, 50],
      number_format: JSON.parse(localStorage.getItem('group')).number_format,
      currency_symbol: '$',
      currency: JSON.parse(localStorage.getItem('group')).currency,
      currency_format: JSON.parse(localStorage.getItem('group')).currency_format,
    }
  },
  components: {
    BreadcrumbItem,
    Breadcrumb,
    BaseAlert,
    BTable,
    BPagination,
    Badge
  },
  methods: {
    jsonToCsvHeader(jsonArray) {
      if (!Array.isArray(jsonArray) || jsonArray.length === 0) {
        return '';
      }

      // Extract keys from the first object
      const headers = Object.keys(jsonArray[0]);

      // Format headers: replace underscores with spaces and capitalize first letter of each word
      const formattedHeaders = headers.map(header =>
        header
          .replace(/_/g, ' ') // Replace underscores with spaces
          .replace(/\b\w/g, char => char.toUpperCase()) // Capitalize first letter of each word
      );

      // Join headers with commas and add a newline
      return formattedHeaders.join(',') + '\n';
    },
    async downloadCSV(){
      //get the entire data set
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }

      this.loading = true
      const count = 5000
      const csvData = await this.getAllRecords(config, count)
      this.loading = false

      console.log(csvData)

      let csv = this.jsonToCsvHeader(csvData)
      console.log(csv)
      csvData.forEach(row => {
        let f = []
        for (const [key, value] of Object.entries(row)) {
          let val = value
          if(key === 'status'){
            val = value == 0 ? 'Cancelled' : 'Active'
          }
          if(key === 'birthday' || key === 'booking_date'){
            val = this.getFormattedDate(value)
          }
          if(key === 'first_name' || key === 'customer_first_name' || key === 'customer_last_name'
            || key === 'last_name' || key === 'referral_source' || key === 'payment_gateway_transaction_id'
            || key === 'player_experience_level' || key === 'card_type' || key === 'games'){
            val = (value) ? this.capitalizeFirstLetter(this.escapeCSVValue(value)) : '-'
          }
          if(key === 'booking_time' || key === 'start_time' || key === 'end_time'){
            val = this.getTime(value)
          }
          if(key === 'completed' || key === 'opted_out_of_posting_photos' || key === 'transaction_has_due_amount'
            || key === 'opted_out_of_receiving_emails' || key === 'can_be_combined' || key === 'internal_only'
            || key === 'applicable_to_miscellaneous_items'){
            val = (value) ? 'Yes' : 'No'
          }
          if(key === 'redemption_type'){
            val = (value) ? 'Percentage discount' : 'Value discount'
          }
          if(key === 'value_type'){
            val = (value === 0) ? 'Per transaction' : 'Per participant'
          }
          if(key === 'transaction_date'  || key === 'purchase_time' || key === 'date_joined' || key === 'date_last_visited'){
            if(!value || value.includes('0000')){
              val = '-'
            } else {
              val = this.escapeCSVValue(this.getLocalDate(value)) + ' ' + this.escapeCSVValue(this.getLocalTime(value))
            }
          }
          if(key === 'expiry'){
            val = (value) ? this.getExpiry(value) : '-'
          }
          if(key === 'last_four' || key === 'payment_collected_by' || key === 'description'){
            val = (value) ? (value) : '-'
          }
          if(key === 'promo_codes'){
            val = (value) ? this.escapeCSVValue(value) : '-'
          }
          if(key === 'payment_method'){
            val = (value) ? this.getPaymentMethod(value) : '-'
          }
          if(key === 'quantity'){
            val = (value === -1) ? 'Unlimited' : (value)
          }
          if(key === 'due' || key === 'tips' || key === 'tax' || key === 'fee' || key === 'total' || key === 'refunded' || key === 'customer_credit'
            || key === 'price' || key === 'paid' || key === 'discount' || key === 'value_amount'){
            val = this.getAmount(value)
          }

          f.push(val);
        }

        csv += f
        csv += "\n";
      });

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'migrations.csv';
      anchor.click();
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    escapeCSVValue(value) {
      if (typeof value === 'string') {
        // Escape double quotes by doubling them
        value = value.replace(/"/g, '""');

        // If the value contains a comma, wrap it in double quotes
        if (value.includes(',') || value.includes('\n')) {
          value = `"${value}"`;
        }
      }
      return value;
    },
    async getAllRecords(config, count){
      let url = ''
      let promises = []
      let csvData = []
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id

      let offset = 0
      let duplicates = this.$route.query.duplicates ? this.$route.query.duplicates : 0
      while(offset <= this.totalRows){

        url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
          '/migrations/' + this.$route.query.id + '?type=' + this.$route.query.type + '&count='
          + count + '&offset=' + offset + '&duplicates=' + duplicates
        promises.push(
          axios.get(url, config)
            .then(response =>{
              csvData.push(response.data.records)
            })
            .catch(err => {
                console.log(err)
                if (err.response.status == 401) {
                  this.$router.push('/forbidden')
                } else {
                  swal('Error!', 'Something went wrong', 'error')
                }
              }
            )
        )
        offset = offset + count
      }

      await Promise.all(promises)

      // merge all response arrays into one
      let arr = []
      csvData.forEach((element) => {
        arr = arr.concat(element)

      })
      return arr
    },
    getAmount(integer){
      if(integer == null){
        return
      }
      if(this.number_format === 1 && this.currency_format === 0){
        return this.currency_symbol + integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',')
      } else if(this.number_format === 1 && this.currency_format === 1){
        return integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',') + ' ' + this.currency
      } else if (this.number_format === 0 && this.currency_format === 1){
        return parseFloat(integer).toFixed(2) + ' ' + this.currency
      }
      return this.currency_symbol + parseFloat(integer).toFixed(2);
    },
    getLocalTime(d){
      let local = new Date(d)
      return this.getTime(local.getHours() + ':' + local.getMinutes())
    },
    getLocalDate(d){
      let local = new Date(d)
      let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
      let final = this.getFormattedDate(form)
      return this.getDayOfWeek(form) + final
    },
    getTime(d) {
      return time(this.time_format, d)
    },
    getDayOfWeek(d){
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        return dayOfWeek(d) + ', '
      }
      return ''
    },
    getFormattedDate(d){
      if(d instanceof Date){
        d = d.getFullYear() + '-' + (d.getMonth()+1) + '-' + d.getDate()
      }
      return formattedDate(this.date_format, d)
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getMigrationRecords(count, offset, source){
      if(source === 'filter'){
        this.currentPage = 1
      }
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let duplicates = this.$route.query.duplicates ? this.$route.query.duplicates : 0
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/migrations/' + this.$route.query.id + '?type=' + this.$route.query.type + '&count='
        + count + '&offset=' + offset + '&duplicates=' + duplicates
      this.loading = true

      this.axios.get(url, config)
        .then(response => {
          this.migration_records = response.data.records
          this.log = response.data.migration_log
          this.totalRows = response.data.total_rows
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    },
    getOffset(){
      return parseInt((this.currentPage * this.perPage) - this.perPage)
    },
  },
  mounted() {
    this.isDuplicate = this.$route.query.duplicates
    this.getMigrationRecords(this.perPage, 0)
  }
}
</script>

<style scoped>

</style>
