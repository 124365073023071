import { render, staticRenderFns } from "./GiftCards.vue?vue&type=template&id=31037b66&scoped=true"
import script from "./GiftCards.vue?vue&type=script&lang=js"
export * from "./GiftCards.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31037b66",
  null
  
)

export default component.exports