<template>
  <div class="content">
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>
    <!--Medium and large screens-->
    <div class="row d-none d-sm-block overflow-scroll">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Past Games</h4>
          <div class="row">
            <div
              class="col d-flex"
            >
                <base-input>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by game</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <base-input>
                            <el-select
                              v-model="filters.game.value"
                              class="select-primary"
                              name="game"
                              placeholder="Game"
                              value-key="id"
                            >
                              <el-option
                                v-for="game in gameOptions"
                                class="select-primary"
                                :label="game.name"
                                :value="{id: game.id, name: game.name}"
                                :key="'A' + game.id"
                              ></el-option>
                            </el-select>
                          </base-input>
                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.game)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateGameInstances('filter')">Update</base-button>
                        </div>
                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                      Game <span v-if="filters.game.actual">: {{filters.game.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by booking date range</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <div>
                            <base-radio v-model="selectedDateRangeOption" name="radio0" class="mb-3">
                              Within the last
                            </base-radio>
                          </div>
                          <div class="text-dark d-inline col-lg-3 col-md-3 col-sm-6 col-8">
                            <input v-model="filters.booking_day.value" class="border-light text-dark form-control" type="number"/>
                          </div>
                          <div class="mt-2">
                            days
                          </div>
                        </div>

                        <div class="row container">
                          <div>
                            <base-radio v-model="selectedDateRangeOption" name="radio1" class="mb-3">
                              From
                            </base-radio>
                          </div>
                          <div class="text-dark d-inline col-lg-5 col-md-4 col-sm-8 col-12">
                            <el-date-picker
                              type="date"
                              placeholder="date"
                              value-format="yyyy-MM-dd"
                              :format="day_of_week + date_format_cal"
                              v-model="filters.booking_range.value_from"
                            >
                            </el-date-picker>
                          </div>
                          <div class="mt-2">
                            to
                          </div>
                          <div class="text-dark d-inline col-lg-5 col-md-4 col-sm-8 col-12">
                            <el-date-picker
                              type="date"
                              placeholder="date"
                              :format="day_of_week + date_format_cal"
                              value-format="yyyy-MM-dd"
                              v-model="filters.booking_range.value_to"
                            >
                            </el-date-picker>
                          </div>
                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.booking_day, filters.booking_range)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateGameInstances('filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                      Booking Date
                      <span v-if="filters.booking_day.actual">: within {{filters.booking_day.actual}} days</span>
                      <span v-if="filters.booking_range.actual_from">:
                          between {{filters.booking_range.actual_from}} and {{filters.booking_range.actual_to}}
                        </span>
                      &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by first name</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Name"
                            v-model="filters.gameMaster.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.gameMaster)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateGameInstances('filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                      Game Master <span v-if="filters.gameMaster.actual">: {{filters.gameMaster.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <el-tooltip content="Reset all filters"
                              effect="light"
                              :open-delay="300"
                              placement="top">
                    <base-button link class="ml-2" v-on:click="resetAll()"  style="font-size: large; color:white">
                      <i class="tim-icons icon-refresh-01"></i>
                    </base-button>
                  </el-tooltip>
                  <span class="text-white">Total&nbsp;results:&nbsp;{{totalRows}}</span>
                </base-input>
              </div>
              <div class="mr-3">
                <el-select
                  class="select-primary mb-3 pagination-select"
                  v-model="perPage"
                  placeholder="Per page"
                  v-on:change="updateGameInstances('filter')"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          <div class="mt-3" v-if="noDataAvailable === true">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No data available.</h4>
            </div>
          </div>
          <div v-if="noDataAvailable === false" class="row table-content">
            <div v-if="load" class="col-md-12 col-lg-12 col-sm-12 col-12">
              <b-table  striped hover :items="gameInstances"
                        :current-page="currentPage"
                        :fields="fields">
                <template v-slot:cell(booking)="row">
                  <div class="row">
                    <div class="col">
                      <span style="font-size: medium">{{row.item.game}}</span>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <span>
                        <i class="tim-icons icon-calendar-60 mr-1 text-primary"></i>
                        <span class="mr-2">{{getDate(row.item.booking_date)}}</span>
                        <i class="tim-icons icon-time-alarm mr-1 text-primary"></i>
                        <span>{{getSimpleTime(row.item.booking_start_time)}}</span>
                      </span>
                    </div>
                  </div>

                </template>
                <template v-slot:cell(player_count)="row">
                  <div v-if="row.item.players">
                    <el-popover trigger="hover"
                                placement="bottom">
                      <div>
                        <div class="popover-body">
                          <div v-for="(p, i) in parseJSON(row.item.players)" :key="'C' + i">
                            {{p.first_name}} {{p.last_name}}
                          </div>
                        </div>
                      </div>
                      <base-button class="like btn-link"
                                   type="info"
                                   size="sm"
                                   icon
                                   slot="reference">
                        <span class="text-white">x{{row.item.waiver_count}}</span>
                      </base-button>
                    </el-popover>
                  </div>
                  <div v-else>
                    <base-button class="like btn-link"
                                 type="info"
                                 size="sm"
                                 icon
                                 slot="reference">
                      <span class="text-white">x{{row.item.waiver_count}}</span>
                    </base-button>
                  </div>
                </template>
                <template v-slot:cell(start_time)="row">
                  <span>{{getTime(row.item.start_time)}}</span>
                </template>
                <template v-slot:cell(completed)="row">
                  <span>{{getCompleted(row.item.completed)}}</span>
                </template>
                <template v-slot:cell(game_master)="row">
                  <span>{{row.item.gm_first_name}} {{row.item.gm_last_name}}</span>
                </template>
                <template v-slot:cell(photo_email)="row">
                  <badge v-if="row.item.is_emailed_to_group > 0" type="success">Sent x{{row.item.is_emailed_to_group}}</badge>
                  <badge v-if="row.item.is_emailed_to_group === 0" type="warning">Not sent</badge>
                  <badge v-if="row.item.is_emailed_to_group === null" type="info">No photo taken</badge>
                </template>
                <template v-slot:cell(actions)="row">
                  <div class="row">
                    <router-link tag="a" :to="'history/instance?id=' + row.item.id">
                      View game details
                    </router-link>
                  </div>
                </template>
              </b-table>
              <b-pagination v-on:input="updateGameInstances()"
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
              ></b-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>

    <!--Mobile-->
    <div class="row mt-3 d-block d-sm-none">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Past Games</h4>
          <div class="row table-content">
            <div
              class="col-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between"
            >
              <base-input>
                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by game</h3>
                    <div class="pop popover-body">
                      <div class="row container">
                        <base-input>
                          <el-select
                            v-model="filters.game.value"
                            class="select-primary"
                            name="game"
                            placeholder="Game"
                            value-key="id"
                          >
                            <el-option
                              v-for="game in gameOptions"
                              class="select-primary"
                              :label="game.name"
                              :value="{id: game.id, name: game.name}"
                              :key="'A' + game.id"
                            ></el-option>
                          </el-select>
                        </base-input>
                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.game)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="updateGameInstances('filter')">Update</base-button>
                      </div>
                    </div>
                  </div>
                  <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                    Game <span v-if="filters.game.actual">: {{filters.game.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>
                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by booking date range</h3>
                    <div class="pop popover-body">
                      <div class="row container">
                        <div>
                          <base-radio v-model="selectedDateRangeOption" name="radio0" class="mb-3">
                            Within the last
                          </base-radio>
                        </div>
                        <div class="text-dark d-inline col-lg-3 col-md-3 col-sm-6 col-8">
                          <input v-model="filters.booking_day.value" class="border-light text-dark form-control" type="number"/>
                        </div>
                        <div class="mt-2">
                          days
                        </div>
                      </div>

                      <div class="row container">
                        <div>
                          <base-radio v-model="selectedDateRangeOption" name="radio1" class="mb-3">
                            Between
                          </base-radio>
                        </div>
                        <div class="text-dark d-inline col-lg-4 col-md-4 col-sm-8 col-12">
                          <el-date-picker
                            type="date"
                            placeholder="date"
                            value-format="yyyy-MM-dd"
                            v-model="filters.booking_range.value_from"
                          >
                          </el-date-picker>
                        </div>
                        <div class="mt-2">
                          and
                        </div>
                        <div class="text-dark d-inline col-lg-4 col-md-4 col-sm-8 col-12">
                          <el-date-picker
                            type="date"
                            placeholder="date"
                            value-format="yyyy-MM-dd"
                            v-model="filters.booking_range.value_to"
                          >
                          </el-date-picker>
                        </div>
                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.booking_day, filters.booking_range)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="updateGameInstances('filter')">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                    Booking Date
                    <span v-if="filters.booking_day.actual">: within {{filters.booking_day.actual}} days</span>
                    <span v-if="filters.booking_range.actual_from">:
                        between {{filters.booking_range.actual_from}} and {{filters.booking_range.actual_to}}
                      </span>
                    &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>
                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by first name</h3>
                    <div class="pop popover-body">
                      <div class="row container">
                        <el-input
                          type="search"
                          clearable
                          prefix-icon="el-icon-search"
                          placeholder="    Name"
                          v-model="filters.gameMaster.value"
                        >
                        </el-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.gameMaster)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="updateGameInstances('filter')">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                    Game Master <span v-if="filters.gameMaster.actual">: {{filters.gameMaster.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>
                <el-tooltip content="Reset all filters"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <base-button link class="ml-2" v-on:click="resetAll()"  style="font-size: large; color:white">
                    <i class="tim-icons icon-refresh-01"></i>
                  </base-button>
                </el-tooltip>
                <span class="ml-3 text-white">Total&nbsp;results:&nbsp;{{totalRows}}</span>
              </base-input>
            </div>
            <div class="row ml-3 align-content-start">
              <div class="col-12">
                <p>Items per page:</p>
                <el-select
                  class="select-primary mb-3 pagination-select"
                  v-model="perPage"
                  placeholder="Per page"
                  v-on:change="updateGameInstances('filter')"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="mt-3" v-if="noDataAvailable === true">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No data available.</h4>
            </div>
          </div>
          <div v-if="noDataAvailable === false" class="row table-content">
            <div v-if="load" class="col-md-12 col-lg-12 col-sm-12 col-12">
              <b-table  striped hover :items="gameInstances"
                        :current-page="currentPage"
                        :fields="mobile_fields">
                <template v-slot:cell(games)="row">
                  <div class="row">
                    <div class="col-6">
                      <span class="text-warning">{{row.item.game}}</span>
                    </div>
                    <div class="col-6">
                      <div class="row">
                        <router-link tag="a" :to="'history/instance?id=' + row.item.id">
                          View game details
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <i class="tim-icons icon-calendar-60 text-warning"></i> {{getDate(row.item.booking_date)}}
                      <i class="tim-icons icon-time-alarm text-warning"></i>&nbsp;{{getSimpleTime(row.item.booking_start_time)}}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      Start time: <span>{{getTime(row.item.start_time)}}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      Completed? <span>{{getCompleted(row.item.completed)}}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      Game master: <span>{{row.item.gm_first_name}} {{row.item.gm_last_name}}</span>
                    </div>
                  </div>
                </template>
              </b-table>
              <b-pagination v-on:input="updateGameInstances()"
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            :limit="3"
              ></b-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>

  </div>
</template>

<script>
  import { Popover, Select, Option, DatePicker, TimeSelect, Tooltip } from 'element-ui';
  import { BaseCheckbox, BaseRadio, Modal } from 'src/components/index';
  import { Auth } from 'aws-amplify'
  import { BFormInput, BTable, BPagination } from 'bootstrap-vue'
  import { API_LOCATION } from '../../../api-config'
  const axios = require('axios')
  import swal from 'sweetalert2';
  import {date, time, simpleTime, formattedDate, dayOfWeek} from "@/plugins/dateFormatter";
  import Badge from "@/components/Badge";
  import _ from "lodash";

  export default {
    name: "GameInstances",
    components: {
      [DatePicker.name]: DatePicker,
      [Select.name]: Select,
      [Option.name]: Option,
      [TimeSelect.name]: TimeSelect,
      [Popover.name]: Popover,
      BFormInput,
      BTable,
      BPagination,
      BaseCheckbox,
      BaseRadio,
      Modal,
      Tooltip,
      Badge
    },
    data() {
      return {
        loading: false,
        load: false,
        noDataAvailable: false,
        selectedInstance: { uploaded_image: 'https://console.offthecouch.io/img/placeholder.jpg'},
        date_format_cal: '',
        day_of_week: '',
        file: '',
        uploadUrl: '',
        error: '',
        modals: {
          upload_photo: false
        },
        filters: {
          gameMaster: {
            value: '',
            actual: ''
          },
          game: {
            value: '',
            actual: ''
          },
          booking_day: {
            value: '',
            actual: '',
          },
          booking_range:{
            value_from: '',
            actual_from: '',
            value_to: '',
            actual_to: '',
          }
        },
        gameInstances: [],
        perPage: 10,
        currentPage: 1,
        totalRows: 0,
        perPageOptions: [5, 10, 25, 50],
        selectedDateRangeOption: 'radio0',
        fields: ['booking', 'player_count', 'start_time', 'completed', 'photo_email', 'game_master', 'actions'],
        mobile_fields: ['games'],
        editing: [],
        value: '',
        gameOptions: '',
        selectedGame: '',
        time_format: JSON.parse(localStorage.getItem('group')).time_format,
        date_format: JSON.parse(localStorage.getItem('group')).date_format,
      };
    },
    methods: {
      getDayOfWeek(d){
        if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
          return dayOfWeek(d) + ', '
        }
        return ''
      },
      parseJSON(item){
        return JSON.parse(item)
      },
      async updateGameInstances(source){
        if(source === 'filter'){
          this.currentPage = 1
        }
        //set first name
        this.filters.gameMaster.actual = this.filters.gameMaster.value

        //set game
        this.filters.game.actual = this.filters.game.value.name

        // set booking date
        let to = ''
        let from = ''
        if (this.selectedDateRangeOption === 'radio0' && this.filters.booking_day.value !== ''){
          this.filters.booking_range.actual_from = ''
          this.filters.booking_range.actual_to = ''
          this.filters.booking_range.value_from = ''
          this.filters.booking_range.value_to = ''
          this.filters.booking_day.actual = this.filters.booking_day.value
          let today = new Date()
          to = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
          // to = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate() + 1).padStart(2, '0')
          let dt = new Date()
          dt.setDate(dt.getDate() - this.filters.booking_day.actual)
          from = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate()
        }
        if (this.selectedDateRangeOption === 'radio1' && this.filters.booking_range.value_to !== '' && this.filters.booking_range.value_from !== ''){
          this.filters.booking_day.actual = ''
          this.filters.booking_day.value = ''
          this.filters.booking_range.actual_from = this.filters.booking_range.value_from
          this.filters.booking_range.actual_to = this.filters.booking_range.value_to
          to = this.filters.booking_range.actual_to
          from = this.filters.booking_range.actual_from
        }

        let count = this.perPage
        let offset = parseInt((this.currentPage * this.perPage) - this.perPage)
        await this.getGameInstances(count, offset, this.filters.game.value.id, this.filters.gameMaster.actual, to, from)
      },
      getAuthToken() {
        return new Promise ( function(resolve) {
          Auth.currentSession()
            .then(data =>{
              resolve(data.idToken.jwtToken)
            })
            .catch(err => console.log(err))
        })
      },
      getCompleted(c){
        if (c == 0){
          return 'No'
        } else {
          return 'Yes'
        }
      },
      getDate(d){
        return this.getDayOfWeek(d) + formattedDate(this.date_format, d)
      },
      getTime(d){
        if(d[0] == 0 || !d){
          return '-'
        }
        return time(this.time_format, d)
      },
      getSimpleTime(d){
        return time(this.time_format, d)
      },
      async getGames () {
        this.loading = true
        var token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games'
        axios.get(url, config)
          .then(response => {
            this.gameOptions = response.data
            this.filters = {
              gameMaster: {
                value: this.$route.query.game_master ? this.$route.query.game_master : '',
                actual: this.$route.query.game_master ? this.$route.query.game_master : ''
              },
              game: {
                value: this.$route.query.game ? _.find(this.gameOptions, { id: Number(this.$route.query.game)}) : '',
                actual: this.$route.query.game ?  _.find(this.gameOptions, { id: Number(this.$route.query.game)}) : ''
              },
              booking_day: {
                value: '',
                actual: '',
              },
              booking_range:{
                value_from: this.$route.query.from ? this.$route.query.from : '',
                actual_from: this.$route.query.from ? this.$route.query.from : '',
                value_to: this.$route.query.to ? this.$route.query.to : '',
                actual_to: this.$route.query.to ? this.$route.query.to : '',
              }
            }
            this.updateGameInstances()
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              this.$router.push('/forbidden')
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      async getGameInstances (count, offset, game, game_master, to, from) {

        if(game_master === undefined){
          game_master = ''
        }
        if(game === undefined){
          game = ''
        }
        if(to === undefined){
          to = ''
        }
        if(from === undefined){
          from = ''
        }

        var token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/game-instances?count=' + count + "&offset=" + offset + "&game=" + encodeURIComponent(game) +
                  "&game_master=" + encodeURIComponent(game_master) + "&to=" + to + "&from=" + from
        this.load = false
        await this.getGameInstanceURL(url, config)
        this.load = true
      },
      getGameInstanceURL(url, config){
        this.loading = true
        axios.get(url, config)
          .then(response => {
            this.loading = false
            if(this.gameInstances.length <=0){
              this.currentPage = Number(this.$route.query.page)
            }
            this.gameInstances = response.data.gameInstances
            if(this.gameInstances.length <= 0) {
              this.noDataAvailable = true
            } else {
              this.noDataAvailable = false
            }
            this.totalRows = response.data.totalRows
            this.addQueryParameters()

          })
          .catch(err => {
            console.log(err)
            this.loading = false
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.status == 500){
              swal({
                title: 'Error',
                html: 'The feature has not been enabled as part of your subscription plan.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })


      },
      resetAll(){
        this.filters= {
          gameMaster: {
            value: '',
              actual: ''
          },
          game: {
            value: '',
              actual: ''
          },
          booking_day: {
            value: '',
              actual: '',
          },
          booking_range:{
            value_from: '',
              actual_from: '',
              value_to: '',
              actual_to: '',
          }
        }
        this.updateGameInstances('filter')
      },
      resetField(field1, field2){
        if(field1){
          field1.value = ''
          field1.actual = ''
        }
        if(field2){
          field2.actual_from = ''
          field2.value_from = ''
          field2.actual_to = ''
          field2.value_to = ''
        }
        this.updateGameInstances('filter')
      },
      async savePhoto(){
        this.loading = true
        await this.saveFile()
        var token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let gameInstanceId = this.selectedGame.id
        let url = API_LOCATION + 'companies/' + companyId + '/games/instances/' + gameInstanceId + '/photo-upload'

        let data = { uploaded_image:  this.uploadUrl.split("?")[0]}

        axios.post(url, data, config)
          .then(response => {
            this.loading = false
            this.modals.upload_photo = false
            this.selectedGame = ''
            this.file = ''
            this.uploadUrl = ''
            swal({
              title: 'Photo uploaded!',
              text: 'Go to game details to view photo.',
              type: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              confirmButtonText: 'Ok',
              buttonsStyling: false
            })
          }).catch((err) => {
            console.log(err)
            this.loading = false
            if(err.response.status == 401){
              this.$router.push('/forbidden')
            } else {
              swal({
                title: 'Something went wrong!',
                type: 'error',
                confirmButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Try again',
                buttonsStyling: false
              })
            }

        })
      },
      getOffset(){
        return Number((this.currentPage * this.perPage) - this.perPage)
      },
      addQueryParameters() {
        this.$nextTick(() => {
          this.currentPage = this.$route.query.page
        });

        const newQuery = {
          ...this.$route.query,
          count: this.perPage,
          page: this.currentPage,
          game_master: this.filters.gameMaster.value,
          game: this.filters.game.value ? this.filters.game.value.id : '',
          to: this.filters.booking_range.value_to,
          from: this.filters.booking_range.value_from,
        }
        this.$router.replace({query: newQuery}).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      }
    },
    mounted() {
      this.date_format_cal = this.date_format
      this.date_format_cal =  this.date_format_cal.replaceAll('Y', 'y')
      this.date_format_cal = this.date_format_cal.replaceAll('D', 'd')
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        this.day_of_week = 'ddd, '
      }
      this.perPage = Number(this.$route.query.count)
      this.currentPage = Number(this.$route.query.page)
      this.selectedDateRangeOption = 'radio1'
      this.getGames()
    }
  };
</script>
<style scoped>
  .pagination-select{
    width: 100px;
  }
  .table-content {
    overflow-x: scroll;
  }
  .pop {
    max-width: 600px;
  }
  #loading {
    min-height: 1000px;
    display: flex;
    align-items: center;
  }

  .el-loading-spinner .path {
    stroke: #6aff8d !important;
  }

  .el-loading-mask {
    background: rgba(0, 0, 0, 0.7) !important;
  }
</style>
