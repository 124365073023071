<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>
    <!--Customers - Medium and large screens-->
    <div class="row mt-3 overflow-scroll">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <div class="d-block d-sm-none">
            <div class="row justify-content-between">
              <div class="col">
                <h4 slot="header" class="card-title">Customers</h4>
              </div>
              <div class="col text-right">
                <b-icon icon="filter" scale="1" class="text-white" v-on:click="show_filters = !show_filters"></b-icon>
                <b-icon icon="arrow-counterclockwise" scale="1" class="ml-2 text-white" v-on:click="resetAll()"></b-icon>
              </div>
            </div>
            <div class="row" v-show="show_filters">
              <div
                class="col d-flex"
              >
                <base-input>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by first name</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    First name"
                            v-model="filters.firstName.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.firstName)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getCustomers(perPage, getOffset(), 'filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                      First Name <span v-if="filters.firstName.actual">: {{filters.firstName.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by last name</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Last name"
                            v-model="filters.lastName.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.lastName)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getCustomers(perPage, getOffset(), 'filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                      Last Name <span v-if="filters.lastName.actual">: {{filters.lastName.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by email</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Email"
                            v-model="filters.email.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.email)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getCustomers(perPage, getOffset(), 'filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                      Email <span v-if="filters.email.actual">: {{filters.email.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by phone number</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Phone number"
                            v-model="filters.phone.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.phone)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getCustomers(perPage, getOffset(), 'filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                      Phone number <span v-if="filters.phone.actual">: {{filters.phone.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by game</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <base-input>
                            <el-select multiple
                                       v-model="filters.game.value"
                                       class="select-primary"
                                       name="game"
                                       placeholder="Game"
                            >
                              <el-option
                                v-for="game in gameOptions"
                                class="select-primary"
                                :label="game.name"
                                :value="game.name"
                                :key="game.id"
                              ></el-option>
                            </el-select>
                          </base-input>
                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.game)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getCustomers(perPage, getOffset(), 'filter')">Update</base-button>
                        </div>
                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                      Game: <span v-if="filters.game.actual" v-for="(g, i) in filters.game.actual" :key="'GA' + i">&nbsp; {{g}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by tag</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Tag"
                            v-model="filters.tag.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.tag)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getCustomers(perPage, getOffset(), 'filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                      Tag <span v-if="filters.tag.actual">: {{filters.tag.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                </base-input>
              </div>
            </div>
          </div>

          <div class="d-none d-sm-block">
            <div class="row justify-content-between">
              <div class="col">
                <h4 slot="header" class="card-title">Customers</h4>
              </div>
            </div>
            <div class="row">
              <div
                class="col"
              >
                <base-input>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by first name</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    First name"
                            v-model="filters.firstName.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.firstName)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getCustomers(perPage, getOffset(), 'filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                      First Name <span v-if="filters.firstName.actual">: {{filters.firstName.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by last name</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Last name"
                            v-model="filters.lastName.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.lastName)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getCustomers(perPage, getOffset(), 'filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                      Last Name <span v-if="filters.lastName.actual">: {{filters.lastName.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by email</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Email"
                            v-model="filters.email.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.email)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getCustomers(perPage, getOffset(), 'filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                      Email <span v-if="filters.email.actual">: {{filters.email.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by phone number</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Phone number"
                            v-model="filters.phone.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.phone)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getCustomers(perPage, getOffset(), 'filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                      Phone number <span v-if="filters.phone.actual">: {{filters.phone.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by game</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <base-input>
                            <el-select multiple
                                       v-model="filters.game.value"
                                       class="select-primary"
                                       name="game"
                                       placeholder="Game"
                            >
                              <el-option
                                v-for="game in gameOptions"
                                class="select-primary"
                                :label="game.name"
                                :value="game.name"
                                :key="game.id"
                              ></el-option>
                            </el-select>
                          </base-input>
                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.game)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getCustomers(perPage, getOffset(), 'filter')">Update</base-button>
                        </div>
                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                      Game: <span v-if="filters.game.actual" v-for="(g, i) in filters.game.actual" :key="'GA' + i">&nbsp; {{g}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by tag</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Tag"
                            v-model="filters.tag.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.tag)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="getCustomers(perPage, getOffset(), 'filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                      Tag <span v-if="filters.tag.actual">: {{filters.tag.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-tooltip content="Reset all filters"
                              effect="light"
                              :open-delay="300"
                              placement="top">
                    <base-button link class="ml-2" v-on:click="resetAll()"  style="font-size: large; color:white">
                      <i class="tim-icons icon-refresh-01"></i>
                    </base-button>
                  </el-tooltip>
                  <span class="ml-3 text-white">Total&nbsp;results:&nbsp;{{totalRows}}</span>
                </base-input>
              </div>
              <div class="mr-3">
                <base-button v-if="hasPermissions('download_data')" icon class="btn-link text-white" v-on:click="downloadCSV()">
                  <i class="tim-icons icon-cloud-download-93"></i>
                </base-button>
                <el-select
                  class="select-primary mb-3 pagination-select"
                  v-model="perPage"
                  placeholder="Per page"
                  v-on:change="getCustomers(perPage, getOffset(), 'filter')"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <!--   no data panel -->
          <div v-if="empty==true" class="mt-3" >
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No customers to display.</h4>
            </div>
          </div>

          <!--        Mobile-->
          <div class="row d-sm-none d-block">
            <div class="col">
              <b-table v-if="empty==false" striped hover :items="customers"
                       :fields="['customers']">
                <template v-slot:head(customers)="row">
                  <span>Total results: {{totalRows}}</span>
                </template>
                <template v-slot:cell(customers)="row">
                  <div class="row">
                    <div class="col">
                      <p class="text-white">{{row.item.first_name}} {{row.item.last_name}}
                        <router-link tag="a" :to="'/customers/details?id=' + row.item.end_user_id">
                          <i class="tim-icons el-icon-top-right"></i>
                        </router-link>
                        &nbsp;<badge v-if="row.item.is_minor" type="primary">Minor</badge>
                      </p>
                      <p v-if="row.item.birthday && !row.item.birthday.includes('000')" class="text-warning">
                        <i class="tim-icons icon-gift-2"></i> {{getDate(row.item.birthday)}}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <small v-if="row.item.email">
                        <i class="tim-icons icon-email-85"></i> {{row.item.email}}
                      </small>
                      <small v-if="row.item.phone">
                        <i class="tim-icons icon-mobile"></i>
                        {{row.item.phone}}
                      </small>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col">
                      <label>Date joined</label>
                      <p>
                        <span v-if="!row.item.date_joined.includes('0000-00-00')"> {{getLocalDate(row.item.date_joined)}}</span>
                        <span v-else>-</span>
                      </p>
                    </div>
                    <div class="col">
                      <label>Date last visited</label>
                      <p>
                        <span v-if="!row.item.date_last_visited.includes('0000-00-00')"> {{getLocalDate(row.item.date_last_visited)}}</span>
                        <span v-else>-</span>
                      </p>
                    </div>

                  </div>
                </template>
              </b-table>
            </div>
          </div>

          <!--        Large screens-->
          <div class="row d-sm-block d-none">
            <div class="col">
              <b-table v-if="empty==false" striped hover :items="customers"
                       :fields="fields">
                <template v-slot:cell(name)="row">
                  <p class="text-white">{{row.item.first_name}} {{row.item.last_name}}
                    &nbsp;<badge v-if="row.item.is_minor" type="primary">Minor</badge>
                  </p>
                  <p v-if="row.item.birthday && !row.item.birthday.includes('000')" class="text-warning">
                    <i class="tim-icons icon-gift-2"></i> {{getDate(row.item.birthday)}}
                  </p>
                  <p v-if="!row.item.first_name && !row.item.last_name">-</p>
                </template>
                <template v-slot:cell(contact_information)="row">
                  <p v-if="!row.item.phone && !row.item.email">-</p>
                  <p v-if="row.item.email" class="text-white">
                    <i class="tim-icons icon-email-85"></i> {{row.item.email}}
                  </p>
                  <p v-if="row.item.phone" class="text-white">
                    <i class="tim-icons icon-mobile"></i>
                    {{row.item.phone}}
                  </p>
                </template>
                <template v-slot:cell(date_joined)="row">
                  <span v-if="!row.item.date_joined.includes('0000-00-00')"> {{getLocalDate(row.item.date_joined)}}</span>
                  <span v-else>-</span>
                </template>
                <template v-slot:cell(last_visited)="row">
                  <span v-if="!row.item.date_last_visited.includes('0000-00-00')"> {{getLocalDate(row.item.date_last_visited)}}</span>
                  <span v-else>-</span>
                </template>
                <template v-slot:cell(games)="row">
                  <span v-for="(g, i) in formatJSON(row.item.games)" :key="'G' + i">
                    <badge type="primary">{{g.game_name}}</badge>&nbsp;
                  </span>
                </template>
                <template v-slot:cell(tags)="row">
              <span v-if="formatJSON(row.item.tags).length > 1" v-for="(t, i) in formatJSON(row.item.tags)" :key="'T' + i">
                <badge type="warning">{{t.tag_name}}</badge>&nbsp;
              </span>
                  <span v-else>-</span>
                </template>

                <template v-slot:cell(actions)="row">
                  <el-dropdown>
                    <base-button
                      simple
                      type="primary"
                      link
                    > <i style="font-size: medium" class="tim-icons el-icon-more"></i>
                    </base-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <a class="dropdown-item" target="_blank" :href="'/customers/details?id=' + row.item.end_user_id">
                          <i class="tim-icons text-info el-icon-user mr-2"></i> View customer details
                        </a>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </template>
              </b-table>
            </div>
          </div>
          <b-pagination v-on:input="getCustomers(perPage, getOffset())"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
          ></b-pagination>
        </card>
      </div>
    </div>

  </div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import {BTable, BPagination, BIcon, BIconFilter, BIconArrowCounterclockwise} from 'bootstrap-vue'
import Badge from "@/components/Badge";
import {dayOfWeek, formattedDate, time} from '../../plugins/dateFormatter'
import swal from "sweetalert2";
import _ from "lodash";
import {Dropdown, DropdownItem, DropdownMenu} from "element-ui";


export default {
  name: "Players",
  components: {
    Badge,
    BTable,
    BPagination,
    BIcon,
    BIconFilter,
    BIconArrowCounterclockwise,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem
  },
  data() {
    return {
      show_filters: false,
      loading: false,
      empty: false,
      customers: [],
      gameOptions: '',
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      fields: ['name', 'contact_information', 'date_joined', 'last_visited', 'games', 'tags', 'actions'],
      perPageOptions: [5, 10, 25, 50],
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      filters: {
        firstName: {
          value: '',
          actual: ''
        },
        lastName: {
          value: '',
          actual: ''
        },
        email: {
          value: '',
          actual: ''
        },
        phone: {
          value: '',
          actual: ''
        },
        game: {
          value: '',
          actual: ''
        },
        tag: {
          value: '',
          actual: ''
        },
      }
    }
  },
  methods: {
    hasPermissions(scope){
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      const matchFound = permissions.some(element => element === scope);
      return matchFound
    },
    getLocalDate(d){
      let local = new Date(d)
      let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
      let final = this.getFormattedDate(form)
      return this.getDayOfWeek(form) + final
    },
    getDayOfWeek(d){
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        return dayOfWeek(d) + ', '
      }
      return ''
    },
    getFormattedDate(d){
      if(d instanceof Date){
        d = d.getFullYear() + '-' + (d.getMonth()+1) + '-' + d.getDate()
      }
      return formattedDate(this.date_format, d)
    },
    formatJSON(string){
      return JSON.parse(string)
    },
    resetAll(){
      this.filters = {
        firstName: {
          value: '',
            actual: ''
        },
        lastName: {
          value: '',
            actual: ''
        },
        email: {
          value: '',
            actual: ''
        },
        phone: {
          value: '',
            actual: ''
        },
        game: {
          value: '',
            actual: ''
        },
        tag: {
          value: '',
          actual: ''
        },
      }
      this.getCustomers(this.perPage, this.getOffset(), 'filter')
    },
    resetField(field){
      field.value = ''
      field.actual = ''

      this.getCustomers(this.perPage, this.getOffset(), 'filter')
    },
    getOffset(){
      return Number((this.currentPage * this.perPage) - this.perPage)
    },
    getDate(d){
      return formattedDate(d)
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getCustomers(count, offset, source){
      if(source === 'filter'){
        this.currentPage = 1
      }
      //set params
      this.filters.firstName.actual = this.filters.firstName.value
      this.filters.lastName.actual = this.filters.lastName.value
      this.filters.email.actual = this.filters.email.value
      this.filters.phone.actual = this.filters.phone.value
      this.filters.game.actual = this.filters.game.value
      this.filters.tag.actual = this.filters.tag.value

      this.loading = true
      var token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/customers?count=' + count + '&offset=' + offset +
        "&first_name=" + encodeURIComponent(this.filters.firstName.actual) +  "&last_name=" + encodeURIComponent(this.filters.lastName.actual)
        +  "&email=" + encodeURIComponent(this.filters.email.actual) +  "&phone=" + encodeURIComponent(this.filters.phone.actual) + "&game=" + encodeURIComponent(this.filters.game.actual) + "&tag=" + encodeURIComponent(this.filters.tag.actual)
      axios.get(url, config)
        .then(response => {

          this.loading = false
          this.customers = response.data.customers
          this.totalRows = response.data.totalRows
          if(this.customers.length <= 0){
            this.empty = true
          } else {
            this.empty = false
          }
          this.addQueryParameters()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getGames () {
      this.loading = true
      var token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games'
      axios.get(url, config)
        .then(response => {
          this.loading = false
          this.gameOptions = response.data
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }``
        })
    },
    async getAllCustomers(config, count){
      let url = ''
      let promises = []
      let csvData = []
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id

      let offset = 0
      while(offset <= this.totalRows){

        url = API_LOCATION + 'companies/' + companyId  + '/groups/' + companyGroupId +
          '/customers?count=' + count + "&offset=" + offset + "&game="
        promises.push(
          axios.get(url, config)
            .then(response =>{
              csvData.push(response.data.customers)
            })
            .catch(err => {
                console.log(err)
                if (err.response.status == 401) {
                  swal({
                    title: 'Error',
                    html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                    type: 'error',
                  }).then((response)=> {
                    this.$router.push('/')
                  })
                } else {
                  swal('Error!', 'Something went wrong', 'error')
                }
              }
            )
        )
        offset = offset + count
      }

      await Promise.all(promises)

      // merge all response arrays into one
      let arr = []
      csvData.forEach((element) => {
        arr = arr.concat(element)

      })
      return arr
    },
    escapeCSVValue(value) {
      if (typeof value === 'string') {
        // Escape double quotes by doubling them
        value = value.replace(/"/g, '""');

        // If the value contains a comma, wrap it in double quotes
        if (value.includes(',') || value.includes('\n')) {
          value = `"${value}"`;
        }
      }
      return value;
    },
    async downloadCSV(){
      //get the entire data set
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }

      this.loading = true
      const count = 5000
      const csvData = await this.getAllCustomers(config, count)
      this.loading = false

      let csv = 'First Name,Last Name,Birthday, Email,Phone\n';
      csvData.forEach(row => {
        csv += [this.escapeCSVValue(row.first_name), this.escapeCSVValue(row.last_name), row.birthday,
          this.escapeCSVValue(row.email), this.escapeCSVValue(row.phone)]
        csv += "\n";
      });

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'customers.csv';
      anchor.click();
    },
    addQueryParameters() {
      this.$nextTick(() => {
        this.currentPage = this.$route.query.page
      });
      const newQuery = {
        ...this.$route.query,
        count: this.perPage,
        page: this.currentPage,
        first_name: this.filters.firstName.value,
        last_name: this.filters.lastName.value,
        email: this.filters.email.value,
        phone: this.filters.phone.value,
        game: this.filters.game.value,
        tag: this.filters.tag.value,
      }
      this.$router.replace({query: newQuery}).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    }
  },
  mounted() {
    this.getGames()
    this.perPage = Number(this.$route.query.count)
    this.currentPage = Number(this.$route.query.page)
    this.filters = {
      firstName: {
        value: this.$route.query.first_name ? this.$route.query.first_name : '',
        actual: this.$route.query.first_name ? this.$route.query.first_name : ''
      },
      lastName: {
        value: this.$route.query.last_name ? this.$route.query.last_name : '',
        actual: this.$route.query.last_name ? this.$route.query.last_name : ''
      },
      email: {
        value: this.$route.query.email ? this.$route.query.email : '',
        actual: this.$route.query.email ? this.$route.query.email : ''
      },
      phone: {
        value: this.$route.query.phone ? this.$route.query.phone : '',
        actual: this.$route.query.phone ? this.$route.query.phone : ''
      },
      game: {
        value: this.$route.query.game ? this.$route.query.game : '',
        actual: this.$route.query.game ? this.$route.query.game : ''
      },
      tag: {
        value: this.$route.query.tag ? this.$route.query.tag : '',
        actual: this.$route.query.tag ? this.$route.query.tag : ''
      },
    }

    this.getCustomers(this.perPage, this.getOffset())
  }
}
</script>

<style scoped>

</style>
