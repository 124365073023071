<template>
  <div>
    <!--    Breadcrumbs-->
    <breadcrumb style="margin-left: -15px">
      <breadcrumb-item>
        <a href="/dashboards">Dashboards</a>
      </breadcrumb-item>
      <breadcrumb-item>
        <a href="/dashboards/settings">Dashboard settings</a>
      </breadcrumb-item>
      <breadcrumb-item>
        <span>{{dashboard.name}}</span>
      </breadcrumb-item>
    </breadcrumb>

    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>

    <div class="row">
      <div class="col">
        <h4>{{dashboard.name}}</h4>
      </div>
      <div class="col text-right">
        <el-dropdown>
          <base-button class="mr-2" type="default" size="sm" :disabled="add_widget">Add widget</base-button>
          <template #dropdown>
            <el-dropdown-menu>
              <a class="dropdown-item mt-2 mb-2" href="#"  @click="addSection(1, $event)">
                Small
              </a>
              <a class="dropdown-item mt-2 mb-2" @click="addSection(2, $event)" href="#">
                Medium
              </a>
              <a class="dropdown-item mt-2 mb-2" @click="addSection(3, $event)" href="#">
                Large
              </a>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
<!--        <base-button class="mr-2" type="default" size="sm">Change layout</base-button>-->
        <base-button class="mr-2"  type="primary" size="sm">Done</base-button>
<!--        <base-button type="primary" icon size="sm"><i class="tim-icons el-icon-more"></i> </base-button>-->
        <el-dropdown>
          <base-button
            size="sm"
            type="primary"
            icon
          > <i class="tim-icons el-icon-more"></i>
          </base-button>
          <template #dropdown>
            <el-dropdown-menu>
              <a class="dropdown-item mt-2 mb-2" href="#">
                <i class="tim-icons icon-link-72 text-info mr-2"></i> Go to dashboard
              </a>
              <a class="dropdown-item mt-2 mb-2" @click="editDashboard(row.item, $event)" href="#">
                <i class="tim-icons icon-pencil text-warning mr-2"></i> Rename
              </a>
              <a class="dropdown-item mt-2 mb-2" @click="editDashboard(row.item, $event)" href="#">
                <i class="tim-icons icon-single-copy-04 text-default mr-2"></i> Copy dashboard
              </a>
              <a class="dropdown-item mt-2 mb-2" @click="editDashboard(row.item, $event)" href="#">
                <i class="tim-icons icon-refresh-02 text-default mr-2"></i> Configure automatic refresh
              </a>
              <hr>
              <a class="dropdown-item mt-2 mb-2" @click="deleteDashboard(row.item, $event)" href="#">
                <i class="tim-icons icon-trash-simple text-danger mr-2"></i> Delete dashboard
              </a>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>

    <div class="row mt-3">
      <div :class="getColSize(w.size)" v-for="(w, index) in widgets" :key="index + 'W' + j">
        <card :style="{'border-top': '3px solid ' + w.highlight_color }" style="border-radius: 3px">
          <div v-if="!w.edit">
            <div class="row mb-3">
              <div :class="getSubColSize(w.size)" class="text-right">
                <el-dropdown>
                  <i class="text-white tim-icons el-icon-more"></i>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <div style="display: flex; flex-direction: column;" class="ml-3">
                        <small style="margin-bottom: 5px;">Highlight color</small>
                        <div style="display: flex; gap: 5px;">
                          <span v-on:click="setColor(w, '#e14eca')"
                                style="cursor: pointer; background-color: #e14eca; border-radius: 3px; width: 20px; height: 20px; display: inline-block;">
                          </span>
                          <span v-on:click="setColor(w, '#1d8cf8')"
                                style="cursor: pointer; background-color: #1d8cf8; border-radius: 3px; width: 20px; height: 20px; display: inline-block;">
                          </span>
                          <div v-on:click="setColor(w, '#ff8d72')"
                               style="cursor: pointer; background-color: #ff8d72; border-radius: 3px; width: 20px; height: 20px;">
                          </div>
                          <div v-on:click="setColor(w, '#00f2c3')"
                               style="cursor: pointer; background-color: #00f2c3; border-radius: 3px; width: 20px; height: 20px;">
                          </div>
                          <div v-on:click="setColor(w, '#fd5d93')"
                               style="cursor: pointer; background-color: #fd5d93; border-radius: 3px; width: 20px; height: 20px;">
                          </div>
                        </div>
                      </div>
                      <hr>
                      <a class="dropdown-item mt-2 mb-2" @click="editWidget(w, $event)" href="#">
                        <i class="tim-icons icon-pencil text-warning mr-2"></i> Configure
                      </a>
                      <hr>
                      <a class="dropdown-item mt-2 mb-2" @click="deleteWidget(w, $event)" href="#">
                        <i class="tim-icons icon-trash-simple text-danger mr-2"></i> Delete widget
                      </a>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </div>
            <div v-if="w.widget_type_id === 3">
              <div class="row">
                <div class="col text-right">
                  <h4>{{w.title}}</h4>
                </div>
              </div>
              <div class="row">
                <div class="col text-right">
                  <h1 style="font-size: xxx-large" class="card-title">
                    {{getAmount(w.data_type, w.data[0].statistic)}}</h1>
                </div>
              </div>
              <div class="row">
                <div class="col text-right">
                  <p class="card-title">{{w.label}}</p>
                </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="row">
              <div class="col">
                <base-input :key="j + 'TT'" placeholder="Title" v-on:change="j++" v-model="w.temp_title" label="Widget title (optional)">
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <base-input :key="j + 'TL'" placeholder="Label" v-on:change="j++" v-model="w.temp_label" label="Widget label (optional)">
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <base-input label="Widget type" required>
                  <el-select
                    v-model="w.temp_widget_type"
                    class="select-primary"
                    value-key="id" v-on:change="j++"
                    placeholder="Widget type">
                    <el-option
                      v-for="(wt, index) in widget_types"
                      class="select-primary"
                      :label="wt.name"
                      :value="{id: wt.id,
                          name: wt.name}"
                      :key="'WTE' + index + j"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>

            <div class="row" v-if="w.temp_widget_type && w.temp_widget_type.id === 1">
              <div class="col">
                <base-input label="Chart type">
                  <el-select
                    v-model="w.temp_chart_type"
                    class="select-primary"
                    value-key="id" v-on:change="j++"
                    placeholder="Type">
                    <el-option
                      v-for="(ct, index) in chart_types"
                      class="select-primary"
                      :label="ct.name"
                      :value="{id: ct.id,
                          name: ct.name}"
                      :key="'cte' + index + j"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>

            <div class="row" v-if="w.temp_widget_type && w.temp_widget_type.id !== 2">
              <div class="col">
                <base-input label="Metric type">
                  <el-select
                    v-model="w.temp_metric_type"
                    class="select-primary"
                    value-key="id" v-on:change="j++"
                    placeholder="Type">
                    <el-option
                      v-for="(wmt, index) in metric_types"
                      class="select-primary"
                      :label="wmt.name"
                      :value="{id: wmt.id,
                          name: wmt.name}"
                      :key="'wmte' + index + j"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>



            <div class="row" v-if="w.temp_widget_type && w.temp_widget_type.id !== 2">
              <div class="col">
                <base-input label="Data source" required>
                  <el-select
                    v-model="w.temp_source"
                    class="select-primary"
                    value-key="id" v-on:change="updateFields()"
                    placeholder="Source">
                    <el-option
                      v-for="(s, index) in sources"
                      class="select-primary"
                      :label="s.name"
                      :value="{id: s.id,
                          name: s.name}"
                      :key="'SE' + index + j"
                    ></el-option>
                  </el-select>
                </base-input>

              </div>
            </div>

            <div class="row" v-if="w.temp_source">
              <div class="col">
                <base-input label="Field" required>
                  <el-select
                    v-model="w.temp_field"
                    class="select-primary"
                    value-key="id" v-on:change="j++"
                    placeholder="Source">
                    <el-option
                      v-for="(f, index) in fields"
                      class="select-primary"
                      :label="f.name"
                      :value="{id: f.id,
                          name: f.name}"
                      :key="'FE' + index + j"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <base-button class="mr-2" v-on:click="cancelEdit(w)" type="default" size="sm">Cancel</base-button>
                <base-button class="mr-2" v-on:click="updateWidget(w)" type="primary" size="sm">Save</base-button>
              </div>
            </div>
          </div>


        </card>
      </div>


      <div :class="getColSize(widget.size)" v-if="add_widget">
        <card style="border-top: 3px solid #e14eca; border-radius: 3px; padding: 30px">

          <div class="row">
            <div :class="getSubColSize(widget.size)">
              <base-input placeholder="Title" v-model="widget.title" label="Widget title (optional)">
              </base-input>
            </div>
          </div>

          <div class="row">
            <div :class="getSubColSize(widget.size)">
              <base-input placeholder="Label" v-model="widget.label" label="Widget label (optional)">
              </base-input>
            </div>
          </div>

          <div class="row">
            <div :class="getSubColSize(widget.size)">
              <base-input label="Widget type" required>
                <el-select
                  v-model="widget.widget_type"
                  class="select-primary"
                  value-key="id"
                  placeholder="Widget type">
                  <el-option
                    v-for="(wt, index) in widget_types"
                    class="select-primary"
                    :label="wt.name"
                    :value="{id: wt.id,
                          name: wt.name}"
                    :key="'WT' + index"
                  ></el-option>
                </el-select>
              </base-input>
            </div>
          </div>

          <!--          <div class="row">-->
          <!--            <div class="col-lg-6">-->
          <!--              <base-button >-->
          <!--                <img src="https://console.offthecouch.io/img/pandas/search.png">-->
          <!--              </base-button>-->
          <!--            </div>-->
          <!--          </div>-->

          <div class="row" v-if="widget.widget_type && widget.widget_type.id === 1">
            <div :class="getSubColSize(widget.size)">
              <base-input label="Chart type">
                <el-select
                  v-model="widget.chart_type"
                  class="select-primary"
                  value-key="id"
                  placeholder="Type">
                  <el-option
                    v-for="(ct, index) in chart_types"
                    class="select-primary"
                    :label="ct.name"
                    :value="{id: ct.id,
                          name: ct.name}"
                    :key="'ct' + index"
                  ></el-option>
                </el-select>
              </base-input>
            </div>
          </div>

          <div class="row" v-if="widget.widget_type && widget.widget_type.id !== 2">
            <div :class="getSubColSize(widget.size)">
              <base-input label="Metric type">
                <el-select
                  v-model="widget.metric_type"
                  class="select-primary"
                  value-key="id"
                  placeholder="Type">
                  <el-option
                    v-for="(wmt, index) in metric_types"
                    class="select-primary"
                    :label="wmt.name"
                    :value="{id: wmt.id,
                          name: wmt.name}"
                    :key="'wmt' + index"
                  ></el-option>
                </el-select>
              </base-input>
            </div>
          </div>



          <div class="row" v-if="widget.widget_type && widget.widget_type.id !== 2">
            <div :class="getSubColSize(widget.size)">
              <base-input label="Data source" required>
                <el-select
                  v-model="widget.source"
                  class="select-primary"
                  value-key="id" v-on:change="updateFields()"
                  placeholder="Source">
                  <el-option
                    v-for="(s, index) in sources"
                    class="select-primary"
                    :label="s.name"
                    :value="{id: s.id,
                          name: s.name}"
                    :key="'S' + index"
                  ></el-option>
                </el-select>
              </base-input>

            </div>
          </div>

          <div class="row" v-if="widget.source">
            <div :class="getSubColSize(widget.size)">
              <base-input label="Field" required>
                <el-select
                  v-model="widget.field"
                  class="select-primary"
                  value-key="id"
                  placeholder="Source">
                  <el-option
                    v-for="(f, index) in fields"
                    class="select-primary"
                    :label="f.name"
                    :value="{id: f.id,
                          name: f.name}"
                    :key="'F' + index"
                  ></el-option>
                </el-select>
              </base-input>

            </div>
          </div>

          <div class="row">
            <div class="col">
              <base-button class="mr-2" v-on:click="add_widget = false" type="default" size="sm">Cancel</base-button>
              <base-button class="mr-2" v-on:click="createWidget()" type="primary" size="sm">Save</base-button>
            </div>
          </div>


        </card>
      </div>

      <div v-if="!add_widget" class="col">
        <div class="text-center" style="border: 1.5px solid #e14eca; border-radius: 3px; padding: 30px; height: 13em">
          <h4 class="mt-3">Add a widget.</h4>
        </div>

      </div>
    </div>



  </div>

</template>

<script>
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import swal from "sweetalert2";
import {BTable} from "bootstrap-vue";
import {Dropdown, DropdownItem, DropdownMenu} from "element-ui";

export default {
  name: "EditDashboard",
  components: {
    BreadcrumbItem,
    Breadcrumb,
    BTable,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem
  },
  data() {
    return {
      loading: false,
      add_widget: false,
      dashboard: '',
      selectedWidgetOption: '',
      selectedStatType: '',
      sources: [],
      widget_types: [],
      metric_types: [],
      chart_types: [],
      fields: [],
      all_fields: [],
      widgets: [],
      widget: {
        widget_type: '',
        widget_subtype: '',
        source: '',
        field: '',
        title: '',
        label: '',
        size: 2,
        position: 1
      },
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      currency_symbol: '$',
      currency: JSON.parse(localStorage.getItem('group')).currency,
      currency_format: JSON.parse(localStorage.getItem('group')).currency_format,
      number_format: JSON.parse(localStorage.getItem('group')).number_format,
      j: 0,
    }
  },
  methods:{
    setColor(item, color){
      item.highlight_color = color
      this.updateWidget(item, 'highlight_color')
    },
    getAmount(data_format, integer){
      if(integer == null){
        return
      }
      if(data_format === 'currency'){
        if(this.number_format === 1 && this.currency_format === 0){
          return this.currency_symbol + integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',')
        } else if(this.number_format === 1 && this.currency_format === 1){
          return integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',') + ' ' + this.currency
        } else if (this.number_format === 1 && this.currency_format === 1){
          return parseFloat(integer).toFixed(2) + ' ' + this.currency
        }
        return this.currency_symbol + parseFloat(integer).toFixed(2);
      }
      return integer
    },
    updateFields(){
      this.fields = this.all_fields.filter(item => item.table_id === this.widget.source.id);
      this.j++
    },
    cancelEdit(item){
      item.edit = false
      this.j++
    },
    editWidget(item, event){
      event.preventDefault()
      item.edit = true
      item.temp_title = item.title
      item.temp_label = item.label
      item.temp_widget_type = _.find(this.widget_types, {id: item.widget_type_id})
      item.temp_chart_type = item.chart_type
      item.temp_metric_type =  _.find(this.metric_types, {id: item.metric_type_id})
      item.temp_source = _.find(this.sources, {id: item.table_id})
      this.fields = this.all_fields.filter(it => it.table_id === item.table_id);
      item.temp_field = _.find(this.fields, {id: item.field_id})
      this.j++
    },
    addSection(size, event){
      event.preventDefault()
      this.add_widget = true
      this.widget.size = size
    },
    getColSize(size){
      if(size === 2){
        return 'col-lg-6'
      } else if(size === 3){
        return 'col-lg-12'
      }
      else if(size === 1){
        return 'col-lg-3'
      }
    },
    getSubColSize(size){
      if(size === 3){
        return 'col-lg-6'
      } else {
        return 'col-lg-12'
      }
    },
    async updateWidget(item, action){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let dashboardId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId
        + '/dashboards/' + dashboardId + '/widgets/' + item.id

      let data = {}

      if(action === 'highlight_color'){
        data = {
          action: 'highlight_color',
          highlight_color: item.highlight_color
        }
      } else {
        this.loading = true
        data = {
          widget_type: item.temp_widget_type.id,
          label: item.temp_label,
          title: item.temp_title,
          size: item.temp_size,
          metric_type: item.temp_metric_type ? item.temp_metric_type.id : null,
          chart_type: item.temp_chart_type ? item.temp_chart_type.id : null,
          table_id: item.temp_source ? item.temp_source.id : null ,
          field_id: item.temp_field ? item.temp_field.id : null
        }
      }

      this.axios.put(url, data, config)
        .then(response => {
          this.loading = false
          this.widget = ''
          this.add_widget = false
          this.getDashboard()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if (err.response.status == 401) {
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async deleteWidget(item, event){
      event.preventDefault()
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let dashboardId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/dashboards/'
        + dashboardId + '/widgets/' + item.id

      this.axios.delete(url, config)
        .then(response => {
          this.loading = false
          this.getDashboard()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if (err.response.status == 401) {
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async createWidget(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let dashboardId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/dashboards/' + dashboardId + '/widgets'

      let data = {
        widget_type: this.widget.widget_type.id,
        label: this.widget.label,
        title: this.widget.title,
        size: this.widget.size,
        position: this.widgets.length,
        metric_type: this.widget.metric_type ? this.widget.metric_type.id : null,
        chart_type: this.widget.chart_type ? this.widget.chart_type.id : null,
        table_id: this.widget.source ? this.widget.source.id : null ,
        field_id: this.widget.field ? this.widget.field.id : null
      }
      this.axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.add_widget = false
          this.getDashboard()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if (err.response.status == 401) {
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getDashboard() {
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let dashboardId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/dashboards/' + dashboardId

      this.axios.get(url, config)
        .then(response => {
          this.loading = false
          this.dashboard = response.data.dashboard
          this.widgets = response.data.widgets
          this.widget_types = response.data.widget_types
          this.chart_types = response.data.chart_types
          this.metric_types = response.data.metric_types
          this.sources = response.data.data_source_tables
          this.all_fields = response.data.data_source_fields

        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if (err.response.status == 401) {
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    }
  },
  mounted() {
    this.getDashboard()
  }
}
</script>

<style scoped>

</style>
