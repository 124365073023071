<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>
  <!--  Breadcrumb-->
  <breadcrumb style="margin-left: -15px">
    <breadcrumb-item>
      <a href="/reports">Report builder</a>
    </breadcrumb-item>
    <breadcrumb-item>
      <span>{{ report.name }}</span>
    </breadcrumb-item>
  </breadcrumb>

  <div class="row">
    <div class="col">
      <card>
        <label v-if="downloading">Downloading...</label>
        <el-progress class="mb-3" v-if="downloading"
                     :percentage="percent_downloaded" />
        <h4 class="card-title">{{ report.name }}</h4>

        <div class="row">
<!--          Filters-->
          <div class="col-lg-10">
<!--            Customer report-->
            <base-input>
              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Filter by group</h3>
                  <div class="pop popover-body">
                    <div class="row" style="width: 350px">
                      <div class="col">
                        <base-input>
                          <el-select
                            v-model="filters.group_name.value"
                            class="select-primary"
                            name="game"
                            multiple
                            placeholder="Group"
                            value-key="id"
                          >
                            <el-option
                              v-for="(group, i) in groups"
                              class="select-primary"
                              :label="group.name"
                              :value="{name: group.name, id: group.id}"
                              :key="'GR' + i"
                            ></el-option>
                          </el-select>
                        </base-input>
                      </div>
                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.group_name)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>
                  </div>
                </div>
                <base-button v-show="fieldExists('group_name')" slot="reference" class="ml-1" :simple="filters.group_name.actual.length <= 0" size="sm" type="primary">
                  Group <span v-if="filters.group_name.actual.length > 0">: {{getGameLabels(filters.group_name.actual)}}
                </span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">First name</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="First name"
                        v-model="filters.first_name.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.first_name)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('first_name')" slot="reference" class="ml-1" :simple="!filters.first_name.actual" size="sm" type="primary">
                  First name <span v-if="filters.first_name.actual">: "{{filters.first_name.actual}}"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Last name</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Last name"
                        v-model="filters.last_name.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.last_name)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('last_name')" slot="reference" class="ml-1" :simple="!filters.last_name.actual" size="sm" type="primary">
                  Last name <span v-if="filters.last_name.actual">: "{{filters.last_name.actual}}"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Payment method</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Payment method"
                        v-model="filters.payment_methods.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.payment_methods)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('payment_method')" slot="reference" class="ml-1" :simple="!filters.payment_methods.actual" size="sm" type="primary">
                  Payment method <span v-if="filters.payment_methods.actual">: "{{filters.payment_methods.actual}}"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Card type</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Card type"
                        v-model="filters.card_type.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.card_type)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('card_type')" slot="reference" class="ml-1" :simple="!filters.card_type.actual" size="sm" type="primary">
                  Card type <span v-if="filters.card_type.actual">: "{{filters.card_type.actual}}"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Last four</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Last four"
                        v-model="filters.last_four.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.last_four)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('last_four')" slot="reference" class="ml-1" :simple="!filters.last_four.actual" size="sm" type="primary">
                  Last four <span v-if="filters.last_four.actual">: "{{filters.last_four.actual}}"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Expiry</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Expiry"
                        v-model="filters.expiry.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.expiry)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('expiry')" slot="reference" class="ml-1" :simple="!filters.expiry.actual" size="sm" type="primary">
                  Expiry <span v-if="filters.expiry.actual">: "{{filters.expiry.actual}}"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Payment gateway id</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Payment gateway id"
                        v-model="filters.payment_gateway_id.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.payment_gateway_id)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('payment_gateway_transaction_id')" slot="reference" class="ml-1" :simple="!filters.payment_gateway_id.actual" size="sm" type="primary">
                  Payment gateway id <span v-if="filters.payment_gateway_id.actual">: "{{filters.payment_gateway_id.actual}}"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Promo codes</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Promo codes"
                        v-model="filters.promo_codes.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.promo_codes)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('promo_codes')" slot="reference" class="ml-1" :simple="!filters.promo_codes.actual" size="sm" type="primary">
                  Promo codes <span v-if="filters.promo_codes.actual">: "{{filters.promo_codes.actual}}"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Payment collected by</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Payment collected by"
                        v-model="filters.payment_collected_by.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.payment_collected_by)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('payment_collected_by')" slot="reference" class="ml-1" :simple="!filters.payment_collected_by.actual" size="sm" type="primary">
                  Payment collected by <span v-if="filters.payment_collected_by.actual">: "{{filters.payment_collected_by.actual}}"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Email</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Email"
                        v-model="filters.email.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.email)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('email')" slot="reference" class="ml-1" :simple="!filters.email.actual" size="sm" type="primary">
                  Email <span v-if="filters.email.actual">: {{filters.email.actual}}</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Birthday</h3>
                  <div class="pop popover-body">
                    <div class="row" style="width: 450px">
                      <div class="col-lg-6 text-dark d-inline">
                        <label>From</label>
                        <el-date-picker
                          type="date"
                          placeholder="Date"
                          value-format="yyyy-MM-dd"
                          :format="day_of_week + '' + date_format_cal"
                          v-model="filters.birthday.value_from"
                        >
                        </el-date-picker>
                      </div>
                      <div class="col-lg-6 text-dark d-inline ">
                        <label>To</label>
                        <el-date-picker
                          type="date"
                          placeholder="Date"
                          value-format="yyyy-MM-dd"
                          :format="day_of_week + '' + date_format_cal"
                          v-model="filters.birthday.value_to"
                        >
                        </el-date-picker>
                      </div>
                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.birthday, filters.birthday)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('birthday')" slot="reference" class="ml-1" :simple="!filters.birthday.actual_to" size="sm" type="primary">
                  Birthday <span v-if="filters.birthday.actual_to && filters.birthday.actual_from">: {{getFormattedDate(filters.birthday.actual_from)}}-{{getFormattedDate(filters.birthday.actual_to)}}
                </span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Date joined</h3>
                  <div class="pop popover-body">
                    <div class="row" style="width: 450px">
                      <div class="col-lg-6 text-dark d-inline">
                        <label>From</label>
                        <el-date-picker
                          type="date"
                          placeholder="Date"
                          value-format="yyyy-MM-dd"
                          :format="day_of_week + '' + date_format_cal"
                          v-model="filters.date_joined.value_from"
                        >
                        </el-date-picker>
                      </div>
                      <div class="col-lg-6 text-dark d-inline ">
                        <label>To</label>
                        <el-date-picker
                          type="date"
                          placeholder="Date"
                          value-format="yyyy-MM-dd"
                          :format="day_of_week + '' + date_format_cal"
                          v-model="filters.date_joined.value_to"
                        >
                        </el-date-picker>
                      </div>
                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.date_joined, filters.date_joined)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('date_joined')" slot="reference" class="ml-1" :simple="!filters.date_joined.actual_to" size="sm" type="primary">
                  Date joined <span v-if="filters.date_joined.actual_to && filters.date_joined.actual_from">: {{getFormattedDate(filters.date_joined.actual_from)}}-{{getFormattedDate(filters.date_joined.actual_to)}}
                </span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Date last visited</h3>
                  <div class="pop popover-body">
                    <div class="row" style="width: 450px">
                      <div class="col-lg-6 text-dark d-inline">
                        <label>From</label>
                        <el-date-picker
                          type="date"
                          placeholder="Date"
                          value-format="yyyy-MM-dd"
                          :format="day_of_week + '' + date_format_cal"
                          v-model="filters.date_last_visited.value_from"
                        >
                        </el-date-picker>
                      </div>
                      <div class="col-lg-6 text-dark d-inline ">
                        <label>To</label>
                        <el-date-picker
                          type="date"
                          placeholder="Date"
                          value-format="yyyy-MM-dd"
                          :format="day_of_week + '' + date_format_cal"
                          v-model="filters.date_last_visited.value_to"
                        >
                        </el-date-picker>
                      </div>
                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.date_last_visited, filters.date_last_visited)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('date_last_visited')" slot="reference" class="ml-1" :simple="!filters.date_last_visited.actual_to" size="sm" type="primary">
                  Date last visited <span v-if="filters.date_last_visited.actual_to && filters.date_last_visited.actual_from">: {{getFormattedDate(filters.date_last_visited.actual_from)}}-{{getFormattedDate(filters.date_last_visited.actual_to)}}
                </span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Phone</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Phone"
                        v-model="filters.phone.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.phone)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('phone')" slot="reference" class="ml-1" :simple="!filters.phone.actual" size="sm" type="primary">
                  Phone <span v-if="filters.phone.actual">: {{filters.phone.actual}}</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Address</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Address"
                        v-model="filters.address.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.address)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('address_line_1') || fieldExists('address_line_2')" slot="reference" class="ml-1" :simple="!filters.address.actual" size="sm" type="primary">
                  Address <span v-if="filters.address.actual">: {{filters.address.actual}}</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">City</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="City"
                        v-model="filters.city.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.city)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('city')" slot="reference" class="ml-1" :simple="!filters.city.actual" size="sm" type="primary">
                  City <span v-if="filters.city.actual">: {{filters.city.actual}}</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">State</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="State"
                        v-model="filters.state.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.state)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('state')" slot="reference" class="ml-1" :simple="!filters.state.actual" size="sm" type="primary">
                  State <span v-if="filters.state.actual">: "{{filters.state.actual}}"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Zip</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Zip"
                        v-model="filters.zip.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.zip)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('zip')" slot="reference" class="ml-1" :simple="!filters.zip.actual" size="sm" type="primary">
                  Zip <span v-if="filters.zip.actual">: {{filters.zip.actual}}</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Country</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Country"
                        v-model="filters.country.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.country)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('country')" slot="reference" class="ml-1" :simple="!filters.country.actual" size="sm" type="primary">
                  Country <span v-if="filters.country.actual">: {{filters.country.actual}}</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Referral</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Referral"
                        v-model="filters.referral_source.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.referral_source)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('referral_source')" slot="reference" class="ml-1" :simple="!filters.referral_source.actual" size="sm" type="primary">
                  Referral <span v-if="filters.referral_source.actual">: {{filters.referral_source.actual}}</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Experience</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Experience"
                        v-model="filters.player_experience_level.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.player_experience_level)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('player_experience_level')" slot="reference" class="ml-1" :simple="!filters.player_experience_level.actual" size="sm" type="primary">
                  Experience <span v-if="filters.player_experience_level.actual">: {{filters.player_experience_level.actual}}</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Opted out of receiving emails</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <div class="col">
                        <base-radio v-model="filters.opted_out_of_receiving_emails.value" name="1" class="mb-3">
                          Yes
                        </base-radio>
                        <base-radio v-model="filters.opted_out_of_receiving_emails.value" name="0" class="mb-3">
                          No
                        </base-radio>
                        <base-radio v-model="filters.opted_out_of_receiving_emails.value" name="null" class="mb-3">
                          No information available
                        </base-radio>
                      </div>
                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.opted_out_of_receiving_emails)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('opted_out_of_receiving_emails')" slot="reference" class="ml-1" :simple="!filters.opted_out_of_receiving_emails.actual" size="sm" type="primary">
                  Opted out of emails <span v-if="filters.opted_out_of_receiving_emails.actual">:
                  <span style="display: inline-block" v-if="filters.opted_out_of_receiving_emails.actual == 0">No</span>
                  <span style="display: inline-block" v-else>Yes</span>
                </span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Opted out of posting photos</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <div class="col">
                        <base-radio v-model="filters.opted_out_of_posting_photos.value" name="1" class="mb-3">
                          Yes
                        </base-radio>
                        <base-radio v-model="filters.opted_out_of_posting_photos.value" name="0" class="mb-3">
                          No
                        </base-radio>
                        <base-radio v-model="filters.opted_out_of_posting_photos.value" name="null" class="mb-3">
                          No information available
                        </base-radio>
                      </div>
                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.opted_out_of_posting_photos)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('opted_out_of_posting_photos')" slot="reference" class="ml-1" :simple="!filters.opted_out_of_posting_photos.actual" size="sm" type="primary">
                  Opted out of photos <span v-if="filters.opted_out_of_posting_photos.actual">:
                  <span style="display: inline-block" v-if="filters.opted_out_of_posting_photos.actual == 0">No</span>
                  <span style="display: inline-block" v-else>Yes</span>
                </span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

<!--              Games-->
              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Start time</h3>
                  <div class="pop popover-body">
                    <div class="row" style="width: 450px">
                      <div class="col-lg-6 text-dark d-inline">
                        <label>From</label>
                        <el-time-picker
                          v-model="filters.start_time.value_from"
                          :format="picker_time_format" placeholder="HH:MM"
                        ></el-time-picker>
                      </div>
                      <div class="col-lg-6 text-dark d-inline ">
                        <label>To</label>
                        <el-time-picker
                          v-model="filters.start_time.value_to"
                          :format="picker_time_format" placeholder="HH:MM"
                        ></el-time-picker>
                      </div>
                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.start_time, filters.start_time)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('start_time')" slot="reference" class="ml-1" :simple="!filters.start_time.actual_to" size="sm" type="primary">
                  Start time <span v-if="filters.start_time.actual_to && filters.start_time.actual_from">: {{getLocalTime(filters.start_time.actual_from)}}-{{getLocalTime(filters.start_time.actual_to)}}
                </span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">End time</h3>
                  <div class="pop popover-body">
                    <div class="row" style="width: 450px">
                      <div class="col-lg-6 text-dark d-inline">
                        <label>From</label>
                        <el-time-picker
                          v-model="filters.end_time.value_from"
                          :format="picker_time_format" placeholder="HH:MM"
                        ></el-time-picker>
                      </div>
                      <div class="col-lg-6 text-dark d-inline ">
                        <label>To</label>
                        <el-time-picker
                          v-model="filters.end_time.value_to"
                          :format="picker_time_format" placeholder="HH:MM"
                        ></el-time-picker>
                      </div>
                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.end_time, filters.end_time)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('end_time')" slot="reference" class="ml-1" :simple="!filters.end_time.actual_to" size="sm" type="primary">
                  End time <span v-if="filters.end_time.actual_to && filters.end_time.actual_from">: {{getLocalTime(filters.end_time.actual_from)}}-{{getLocalTime(filters.end_time.actual_to)}}
                </span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Filter by game</h3>
                  <div class="pop popover-body">
                    <div class="row" style="width: 350px">
                      <div class="col">
                        <base-input>
                          <el-select
                            v-model="filters.game_name.value"
                            class="select-primary"
                            name="game"
                            multiple
                            placeholder="Game"
                            value-key="id"
                          >
                            <el-option
                              v-for="(game, i) in games"
                              class="select-primary"
                              :label="game.name"
                              :value="{name: game.name, id: game.id}"
                              :key="'G' + i"
                            ></el-option>
                          </el-select>
                        </base-input>
                      </div>
                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.game_name)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>
                  </div>
                </div>
                <base-button v-show="fieldExists('game_name')" slot="reference" class="ml-1" :simple="filters.game_name.actual.length <= 0" size="sm" type="primary">
                  Game <span v-if="filters.game_name.actual.length > 0">: {{getGameLabels(filters.game_name.actual)}}
                </span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Team experience level</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <div class="col">
                        <base-radio v-model="filters.team_experience_level.value" name="1" class="mb-3">
                          New
                        </base-radio>
                        <base-radio v-model="filters.team_experience_level.value" name="2" class="mb-3">
                          Some have played before
                        </base-radio>
                        <base-radio v-model="filters.team_experience_level.value" name="3" class="mb-3">
                          Experienced
                        </base-radio>
                        <base-radio v-model="filters.team_experience_level.value" name="4" class="mb-3">
                          Expert
                        </base-radio>
                      </div>
                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.team_experience_level)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('team_experience_level')" slot="reference" class="ml-1" :simple="!filters.team_experience_level.actual" size="sm" type="primary">
                  Team experience level <span v-if="filters.team_experience_level.actual">:
                  <span style="display: inline-block" v-if="filters.team_experience_level.actual == 1">New</span>
                  <span style="display: inline-block" v-if="filters.team_experience_level.actual == 2">Some have played before</span>
                  <span style="display: inline-block" v-if="filters.team_experience_level.actual == 3">Experienced</span>
                  <span style="display: inline-block" v-if="filters.team_experience_level.actual == 4">Expert</span>
                </span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>


              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Pre-game notes</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Start time"
                        v-model="filters.pre_game_notes.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.pre_game_notes)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('pre_game_notes')" slot="reference" class="ml-1" :simple="!filters.pre_game_notes.actual" size="sm" type="primary">
                  Pre-game notes <span v-if="filters.pre_game_notes.actual">: "{{filters.pre_game_notes.actual}}"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Post-game notes</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Start time"
                        v-model="filters.post_game_notes.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.post_game_notes)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('post_game_notes')" slot="reference" class="ml-1" :simple="!filters.post_game_notes.actual" size="sm" type="primary">
                  Post-game notes <span v-if="filters.post_game_notes.actual">: "{{filters.post_game_notes.actual}}"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>
              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Completed</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <div class="col">
                        <base-radio v-model="filters.completed.value" name="1" class="mb-3">
                          Yes
                        </base-radio>
                        <base-radio v-model="filters.completed.value" name="0" class="mb-3">
                          No
                        </base-radio>
                      </div>
                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.completed)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('completed')" slot="reference" class="ml-1" :simple="!filters.completed.actual" size="sm" type="primary">
                  Completed <span v-if="filters.completed.actual">:
                  <span style="display: inline-block" v-if="filters.completed.actual == 1">Yes</span>
                  <span style="display: inline-block" v-if="filters.completed.actual == 0">No</span>
                </span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Game master first name</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="First name"
                        v-model="filters.game_master_first_name.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.game_master_first_name)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('game_master_first_name')" slot="reference" class="ml-1" :simple="!filters.game_master_first_name.actual" size="sm" type="primary">
                  Game master first name <span v-if="filters.game_master_first_name.actual">: "{{filters.game_master_first_name.actual}}"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Game master last name</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Last name"
                        v-model="filters.game_master_last_name.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.game_master_last_name)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('game_master_last_name')" slot="reference" class="ml-1" :simple="!filters.game_master_last_name.actual" size="sm" type="primary">
                  Game master last name <span v-if="filters.game_master_last_name.actual">: "{{filters.game_master_last_name.actual}}"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Booking date</h3>
                  <div class="pop popover-body">
                    <div class="row" style="width: 450px">
                      <div class="col-lg-6 text-dark d-inline">
                        <label>From</label>
                        <el-date-picker
                          type="date"
                          placeholder="Date"
                          value-format="yyyy-MM-dd"
                          :format="day_of_week + '' + date_format_cal"
                          v-model="filters.booking_date.value_from"
                        >
                        </el-date-picker>
                      </div>
                      <div class="col-lg-6 text-dark d-inline ">
                        <label>To</label>
                        <el-date-picker
                          type="date"
                          placeholder="Date"
                          value-format="yyyy-MM-dd"
                          :format="day_of_week + '' + date_format_cal"
                          v-model="filters.booking_date.value_to"
                        >
                        </el-date-picker>
                      </div>
                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.booking_date, filters.booking_date)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('booking_date')" slot="reference" class="ml-1" :simple="!filters.booking_date.actual_to" size="sm" type="primary">
                  Booking date <span v-if="filters.booking_date.actual_to && filters.booking_date.actual_from">: {{getFormattedDate(filters.booking_date.actual_from)}}-{{getFormattedDate(filters.booking_date.actual_to)}}
                </span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Booking time</h3>
                  <div class="pop popover-body">
                    <div class="row" style="width: 450px">
                      <div class="col-lg-6 text-dark d-inline">
                        <label>From</label>
                        <el-time-picker
                          v-model="filters.booking_time.value_from"
                          :format="picker_time_format" placeholder="HH:MM"
                        ></el-time-picker>
                      </div>
                      <div class="col-lg-6 text-dark d-inline ">
                        <label>To</label>
                        <el-time-picker
                          v-model="filters.booking_time.value_to"
                          :format="picker_time_format" placeholder="HH:MM"
                        ></el-time-picker>
                      </div>
                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.booking_time, filters.booking_time)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('booking_time')" slot="reference" class="ml-1" :simple="!filters.booking_time.actual_to" size="sm" type="primary">
                  Booking time <span v-if="filters.booking_time.actual_to && filters.booking_time.actual_from">: {{getLocalTime(filters.booking_time.actual_from)}}-{{getLocalTime(filters.booking_time.actual_to)}}
                </span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Number of hints</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Number of hints"
                        v-model="filters.number_of_hints.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.number_of_hints)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('number_of_hints')" slot="reference" class="ml-1" :simple="!filters.number_of_hints.actual" size="sm" type="primary">
                  Number of hints <span v-if="filters.number_of_hints.actual">: {{filters.number_of_hints.actual}}</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>
              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Number of breaks</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Number of breaks"
                        v-model="filters.number_of_breaks.value"
                      >
                      </el-input>
                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.number_of_breaks)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('number_of_breaks')" slot="reference" class="ml-1" :simple="!filters.number_of_breaks.actual" size="sm" type="primary">
                  Number of breaks <span v-if="filters.number_of_breaks.actual">: {{filters.number_of_breaks.actual}}</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

<!--              Transactions-->
              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Transaction id</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Transaction id"
                        v-model="filters.transaction_id.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.transaction_id)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('transaction_id')" slot="reference" class="ml-1" :simple="!filters.transaction_id.actual" size="sm" type="primary">
                  Transaction id<span v-if="filters.transaction_id.actual">: "{{filters.transaction_id.actual}}"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Customer first name</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Customer first name"
                        v-model="filters.customer_first_name.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.customer_first_name)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('customer_first_name')" slot="reference" class="ml-1" :simple="!filters.customer_first_name.actual" size="sm" type="primary">
                  Customer first name <span v-if="filters.customer_first_name.actual">: "{{filters.customer_first_name.actual}}"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Customer last name</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Customer last name"
                        v-model="filters.customer_last_name.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.customer_last_name)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('customer_last_name')" slot="reference" class="ml-1" :simple="!filters.customer_last_name.actual" size="sm" type="primary">
                  Customer last name <span v-if="filters.customer_last_name.actual">: "{{filters.customer_last_name.actual}}"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Customer email</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Customer email"
                        v-model="filters.customer_email.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.customer_email)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('customer_email')" slot="reference" class="ml-1" :simple="!filters.customer_email.actual" size="sm" type="primary">
                  Customer email <span v-if="filters.customer_email.actual">: "{{filters.customer_email.actual}}"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Customer phone</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Customer phone"
                        v-model="filters.customer_phone.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.customer_phone)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('customer_phone')" slot="reference" class="ml-1" :simple="!filters.customer_phone.actual" size="sm" type="primary">
                  Customer phone <span v-if="filters.customer_phone.actual">: "{{filters.customer_phone.actual}}"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Customer credit</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Customer credit"
                        v-model="filters.customer_credit.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.customer_credit)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('customer_credit')" slot="reference" class="ml-1" :simple="!filters.customer_credit.actual" size="sm" type="primary">
                  Customer credit <span v-if="filters.customer_credit.actual">: {{getAmount(filters.customer_credit.actual)}}</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Price</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Price"
                        v-model="filters.price.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.price)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('price')" slot="reference" class="ml-1" :simple="!filters.price.actual" size="sm" type="primary">
                  Price <span v-if="filters.price.actual">: {{getAmount(filters.price.actual)}}</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Fee</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Fee"
                        v-model="filters.fee.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.fee)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('fee') && report.report_type_id !== 4" slot="reference" class="ml-1" :simple="!filters.fee.actual" size="sm" type="primary">
                  Fee <span v-if="filters.fee.actual">: {{getAmount(filters.fee.actual)}}</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Discount</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Discount"
                        v-model="filters.discount.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.discount)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('fee')" slot="reference" class="ml-1" :simple="!filters.discount.actual" size="sm" type="primary">
                  Discount <span v-if="filters.discount.actual">: {{getAmount(filters.discount.actual)}}</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Tax</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Tax"
                        v-model="filters.tax.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.tax)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('tax') && report.report_type_id !== 4" slot="reference" class="ml-1" :simple="!filters.tax.actual" size="sm" type="primary">
                  Tax <span v-if="filters.tax.actual">: {{getAmount(filters.tax.actual)}}</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Tips</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Tips"
                        v-model="filters.tips.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.tips)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('tips')" slot="reference" class="ml-1" :simple="!filters.tips.actual" size="sm" type="primary">
                  Tips <span v-if="filters.tips.actual">: {{getAmount(filters.tips.actual)}}</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Tip assignees</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Tip assignees"
                        v-model="filters.tip_assignees.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.tip_assignees)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('tip_assignees')" slot="reference" class="ml-1" :simple="!filters.tip_assignees.actual" size="sm" type="primary">
                  Tip assignees <span v-if="filters.tip_assignees.actual">: {{filters.tip_assignees.actual}}</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Total</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Total"
                        v-model="filters.total.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.total)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('total')" slot="reference" class="ml-1" :simple="!filters.total.actual" size="sm" type="primary">
                  Total <span v-if="filters.total.actual">: {{getAmount(filters.total.actual)}}</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Paid</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Paid"
                        v-model="filters.paid.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.paid)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('paid')" slot="reference" class="ml-1" :simple="!filters.paid.actual" size="sm" type="primary">
                  Paid <span v-if="filters.paid.actual">: {{getAmount(filters.paid.actual)}}</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Refunded</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Refunded"
                        v-model="filters.refunded.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.refunded)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('refunded')" slot="reference" class="ml-1" :simple="!filters.refunded.actual" size="sm" type="primary">
                  Refunded <span v-if="filters.refunded.actual">: {{getAmount(filters.refunded.actual)}}</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Due</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Due"
                        v-model="filters.due.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.due)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('due')" slot="reference" class="ml-1" :simple="!filters.due.actual" size="sm" type="primary">
                  Due <span v-if="filters.due.actual">: {{getAmount(filters.due.actual)}}</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Status</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <div class="col">
                        <base-radio v-model="filters.status.value" name="1" class="mb-3">
                          Active
                        </base-radio>
                        <base-radio v-model="filters.status.value" name="0" class="mb-3">
                          Cancelled
                        </base-radio>
                      </div>
                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.status)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('status')" slot="reference" class="ml-1" :simple="!filters.status.actual" size="sm" type="primary">
                  Status <span v-if="filters.team_experience_level.actual">:
                  <span style="display: inline-block" v-if="filters.status.actual == 1">Active</span>
                  <span style="display: inline-block" v-if="filters.status.actual == 0">Cancelled</span>
                </span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Transaction has due amount</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <div class="col">
                        <base-radio v-model="filters.transaction_has_due_amount.value" name="1" class="mb-3">
                          Yes
                        </base-radio>
                        <base-radio v-model="filters.transaction_has_due_amount.value" name="0" class="mb-3">
                          No
                        </base-radio>
                      </div>
                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.transaction_has_due_amount)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('transaction_has_due_amount')" slot="reference" class="ml-1" :simple="!filters.transaction_has_due_amount.actual" size="sm" type="primary">
                  Transaction has due amount <span v-if="filters.transaction_has_due_amount.actual">:
                  <span style="display: inline-block" v-if="filters.transaction_has_due_amount.actual == 0">No</span>
                  <span style="display: inline-block" v-else>Yes</span>
                </span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Transaction date</h3>
                  <div class="pop popover-body">
                    <div class="row" style="width: 450px">
                      <div class="col-lg-6 text-dark d-inline">
                        <label>From</label>
                        <el-date-picker
                          type="date"
                          placeholder="Date"
                          value-format="yyyy-MM-dd"
                          :format="day_of_week + '' + date_format_cal"
                          v-model="filters.transaction_date.value_from"
                        >
                        </el-date-picker>
                      </div>
                      <div class="col-lg-6 text-dark d-inline ">
                        <label>To</label>
                        <el-date-picker
                          type="date"
                          placeholder="Date"
                          value-format="yyyy-MM-dd"
                          :format="day_of_week + '' + date_format_cal"
                          v-model="filters.transaction_date.value_to"
                        >
                        </el-date-picker>
                      </div>
                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.transaction_date, filters.transaction_date)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('transaction_date')" slot="reference" class="ml-1" :simple="!filters.transaction_date.actual_to" size="sm" type="primary">
                  Transaction date <span v-if="filters.transaction_date.actual_to && filters.transaction_date.actual_from">: {{getFormattedDate(filters.transaction_date.actual_from)}}-{{getFormattedDate(filters.transaction_date.actual_to)}}
                </span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

<!--              Purchases-->
              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Purchase name</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Purchase name"
                        v-model="filters.purchase_name.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.purchase_name)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('purchase_name')" slot="reference" class="ml-1" :simple="!filters.purchase_name.actual" size="sm" type="primary">
                  Purchase name <span v-if="filters.purchase_name.actual">: "{{filters.purchase_name.actual}}"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Purchase type</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Purchase type"
                        v-model="filters.purchase_type.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.purchase_type)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('purchase_type')" slot="reference" class="ml-1" :simple="!filters.purchase_type.actual" size="sm" type="primary">
                  Purchase type <span v-if="filters.purchase_type.actual">: "{{filters.purchase_type.actual}}"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Quantity</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">

                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Quantity"
                        v-model.number="filters.quantity.value"
                      >
                      </el-input>

                    </div>
                    <div v-if="report.report_type_id === 7" class="row container mt-1 mb-1">
                      <base-checkbox v-model="filters.quantity.unlimited">Unlimited</base-checkbox>
                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.quantity)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('quantity')" slot="reference" class="ml-1" :simple="filters.quantity.actual === ''" size="sm" type="primary">
                  Quantity <span v-if="!filters.quantity.unlimited && filters.quantity.actual !== ''">: "{{filters.quantity.actual}}"</span> &nbsp;
                  <span v-if="filters.quantity.unlimited">: "Unlimited"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Purchase date</h3>
                  <div class="pop popover-body">
                    <div class="row" style="width: 450px">
                      <div class="col-lg-6 text-dark d-inline">
                        <label>From</label>
                        <el-date-picker
                          type="date"
                          placeholder="Date"
                          value-format="yyyy-MM-dd"
                          :format="day_of_week + '' + date_format_cal"
                          v-model="filters.purchase_time.value_from"
                        >
                        </el-date-picker>
                      </div>
                      <div class="col-lg-6 text-dark d-inline ">
                        <label>To</label>
                        <el-date-picker
                          type="date"
                          placeholder="Date"
                          value-format="yyyy-MM-dd"
                          :format="day_of_week + '' + date_format_cal"
                          v-model="filters.purchase_time.value_to"
                        >
                        </el-date-picker>
                      </div>
                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.purchase_time, filters.purchase_time)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('purchase_time')" slot="reference" class="ml-1" :simple="!filters.purchase_time.actual_to" size="sm" type="primary">
                  Purchase date <span v-if="filters.purchase_time.actual_to && filters.purchase_time.actual_from">: {{getFormattedDate(filters.purchase_time.actual_from)}}-{{getFormattedDate(filters.purchase_time.actual_to)}}
                </span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

<!--              Promo codes-->
              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Promo code name</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Promo code name"
                        v-model="filters.promo_code_name.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.promo_code_name)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('promo_code_name')" slot="reference" class="ml-1" :simple="!filters.promo_code_name.actual" size="sm" type="primary">
                  Promo code name <span v-if="filters.promo_code_name.actual">: "{{filters.promo_code_name.actual}}"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Description</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Description"
                        v-model="filters.description.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.description)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('description')" slot="reference" class="ml-1" :simple="!filters.description.actual" size="sm" type="primary">
                  Description <span v-if="filters.description.actual">: "{{filters.description.actual}}"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Redemption type</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <div class="col">
                        <base-radio v-model="filters.redemption_type.value" name="0" class="mb-3">
                          Percentage discount
                        </base-radio>
                        <base-radio v-model="filters.redemption_type.value" name="1" class="mb-3">
                          Value discount
                        </base-radio>
                      </div>
                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.redemption_type)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('redemption_type')" slot="reference" class="ml-1" :simple="!filters.redemption_type.actual" size="sm" type="primary">
                  Redemption type <span v-if="filters.redemption_type.actual">:
                  <span style="display: inline-block" v-if="filters.redemption_type.actual === 0">Percentage discount</span>
                  <span style="display: inline-block" v-else>Value discount</span>
                </span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Value type</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <div class="col">
                        <base-radio v-model="filters.value_type.value" name="0" class="mb-3">
                          Per transaction
                        </base-radio>
                        <base-radio v-model="filters.value_type.value" name="2" class="mb-3">
                          Per participant
                        </base-radio>
                      </div>
                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.value_type)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('value_type')" slot="reference" class="ml-1" :simple="!filters.value_type.actual" size="sm" type="primary">
                  Value type <span v-if="filters.value_type.actual">:
                  <span style="display: inline-block" v-if="filters.value_type.actual === 0">Per transaction</span>
                  <span style="display: inline-block" v-else>Per participant</span>
                </span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Value amount</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Value amount"
                        v-model.number="filters.value_amount.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.value_amount)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('value_amount')" slot="reference" class="ml-1" :simple="!filters.value_amount.actual" size="sm" type="primary">
                  Value amount <span v-if="filters.value_amount.actual">: "{{filters.value_amount.actual}}"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Can be combined</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <div class="col">
                        <base-radio v-model="filters.can_be_combined.value" name="1" class="mb-3">
                          Yes
                        </base-radio>
                        <base-radio v-model="filters.can_be_combined.value" name="0" class="mb-3">
                          No
                        </base-radio>
                      </div>
                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.can_be_combined)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('can_be_combined')" slot="reference" class="ml-1" :simple="!filters.can_be_combined.actual" size="sm" type="primary">
                  Can be combined <span v-if="filters.can_be_combined.actual">:
                  <span style="display: inline-block" v-if="filters.can_be_combined.actual === 0">No</span>
                  <span style="display: inline-block" v-else>Yes</span>
                </span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Internal only</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <div class="col">
                        <base-radio v-model="filters.internal_only.value" name="1" class="mb-3">
                          Yes
                        </base-radio>
                        <base-radio v-model="filters.internal_only.value" name="0" class="mb-3">
                          No
                        </base-radio>
                      </div>
                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.internal_only)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('internal_only')" slot="reference" class="ml-1" :simple="!filters.internal_only.actual" size="sm" type="primary">
                  Internal only <span v-if="filters.internal_only.actual">:
                  <span style="display: inline-block" v-if="filters.internal_only.actual === 0">No</span>
                  <span style="display: inline-block" v-else>Yes</span>
                </span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Applicable to miscellaneous items</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <div class="col">
                        <base-radio v-model="filters.applicable_to_miscellaneous_items.value" name="1" class="mb-3">
                          Yes
                        </base-radio>
                        <base-radio v-model="filters.applicable_to_miscellaneous_items.value" name="0" class="mb-3">
                          No
                        </base-radio>
                      </div>
                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.applicable_to_miscellaneous_items)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('applicable_to_miscellaneous_items')" slot="reference" class="ml-1" :simple="!filters.applicable_to_miscellaneous_items.actual" size="sm" type="primary">
                  Applicable to miscellaneous items <span v-if="filters.applicable_to_miscellaneous_items.actual">:
                  <span style="display: inline-block" v-if="filters.applicable_to_miscellaneous_items.actual === 0">No</span>
                  <span style="display: inline-block" v-else>Yes</span>
                </span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Number of times used</h3>
                  <div class="pop popover-body">
                    <div class="row container" style="width: 300px">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="Number of times used"
                        v-model.number="filters.number_of_times_used.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.number_of_times_used)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button v-show="fieldExists('number_of_times_used')" slot="reference" class="ml-1" :simple="!filters.number_of_times_used.actual" size="sm" type="primary">
                  Number of times used <span v-if="filters.number_of_times_used.actual">: "{{filters.number_of_times_used.actual}}"</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Filter by games</h3>
                  <div class="pop popover-body">
                    <div class="row" style="width: 350px">
                      <div class="col">
                        <base-input>
                          <el-select
                            v-model="filters.games.value"
                            class="select-primary"
                            name="game"
                            multiple
                            placeholder="Games"
                            value-key="id"
                          >
                            <el-option
                              v-for="(game, i) in games"
                              class="select-primary"
                              :label="game.name"
                              :value="{name: game.name, id: game.id}"
                              :key="'G' + i"
                            ></el-option>
                          </el-select>
                        </base-input>
                      </div>
                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.games)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="generateReport()">Update</base-button>
                    </div>
                  </div>
                </div>
                <base-button v-show="fieldExists('games')" slot="reference" class="ml-1" :simple="filters.games.actual.length <= 0" size="sm" type="primary">
                  Games <span v-if="filters.games.actual.length > 0">: {{getGameLabels(filters.games.actual)}}
                </span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-tooltip content="Reset all filters"
                          effect="light"
                          :open-delay="300"
                          placement="top">
                <base-button link class="ml-2" v-on:click="resetAll()"  style="font-size: large; color:white">
                  <i class="tim-icons icon-refresh-01"></i>
                </base-button>
              </el-tooltip>
              <span class="text-white">Total&nbsp;results:&nbsp;{{totalRows}}</span>
            </base-input>

          </div>

          <div class="col text-right">
            <base-button icon class="btn-link text-white" v-if="hasPermissions('download_data') && !downloading" v-on:click="downloadCSV()">
              <i class="tim-icons icon-cloud-download-93"></i>
            </base-button>
            <el-select
              class="select-primary mb-3 pagination-select"
              v-model="perPage"
              placeholder="Per page"
              v-on:change="generateReport()"
            >
              <el-option
                class="select-primary"
                v-for="(item, index) in perPageOptions"
                :key="'B' + index"
                :label="item.toString()"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row" style="overflow-x: scroll">
          <div class="col">
            <b-table v-if="data.length > 0" striped hover :items="data"
                     :fields="fields">
              <!--          Customer fields-->
<!--              <template #head(email)="row">-->
<!--                <span>Email <i class="tim-icons icon-minimal-down"></i> </span>-->
<!--              </template>-->
              <template v-slot:cell(birthday)="row">
                <span>{{getFormattedDate(row.item.birthday)}}</span>
              </template>
              <template v-slot:cell(date_joined)="row">
                <span v-if="row.item.date_joined && !row.item.date_joined.includes('0000')">
                  {{getLocalDate(row.item.date_joined)}}, {{getLocalTime(row.item.date_joined)}}
                </span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(date_last_visited)="row">
                <span v-if="row.item.date_last_visited && !row.item.date_last_visited.includes('0000')">
                  {{getLocalDate(row.item.date_last_visited)}}, {{getLocalTime(row.item.date_last_visited)}}
                </span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(phone)="row">
                <span v-if="row.item.phone">{{row.item.phone}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(first_name)="row">
                <span v-if="row.item.first_name">{{row.item.first_name}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(customer_credit)="row">
                <span v-if="row.item.customer_credit >=0">{{getAmount(row.item.customer_credit)}}</span>
              </template>
              <template v-slot:cell(last_name)="row">
                <span v-if="row.item.last_name">{{row.item.last_name}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(address_line_1)="row">
                <span v-if="row.item.address_line_1">{{row.item.address_line_1}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(address_line_2)="row">
                <span v-if="row.item.address_line_2">{{row.item.address_line_2}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(city)="row">
                <span v-if="row.item.city">{{row.item.city}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(state)="row">
                <span v-if="row.item.state">{{row.item.state}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(zip)="row">
                <span v-if="row.item.zip">{{row.item.zip}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(country)="row">
                <span v-if="row.item.country">{{row.item.country}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(opted_out_of_posting_photos)="row">
                <span v-if="row.item.opted_out_of_posting_photos === 1">Yes</span>
                <span v-if="row.item.opted_out_of_posting_photos === 0">No</span>
                <span v-if="row.item.opted_out_of_posting_photos === null">-</span>
              </template>
              <template v-slot:cell(opted_out_of_receiving_emails)="row">
                <span v-if="row.item.opted_out_of_receiving_emails === 1">Yes</span>
                <span v-if="row.item.opted_out_of_receiving_emails === 0">No</span>
                <span v-if="row.item.opted_out_of_receiving_emails === null">-</span>
              </template>
              <template v-slot:cell(referral_source)="row">
                <span v-if="row.item.referral_source">{{ capitalizeFirstLetter(row.item.referral_source) }}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(player_experience_level)="row">
                <span v-if="row.item.player_experience_level">{{ capitalizeFirstLetter(row.item.player_experience_level) }}</span>
                <span v-else>-</span>
              </template>
              <!--          Game fields-->
              <template v-slot:cell(start_time)="row">
                <span>{{getTime(row.item.start_time)}}</span>
              </template>
              <template v-slot:cell(end_time)="row">
                <span>{{getTime(row.item.end_time)}}</span>
              </template>
              <template v-slot:cell(booking_time)="row">
                <span>{{getTime(row.item.booking_time)}}</span>
              </template>
              <template v-slot:cell(pre_game_notes)="row">
                <span v-if="row.item.pre_game_notes">{{row.item.pre_game_notes}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(team_experience_level)="row">
                <span v-if="row.item.team_experience_level">{{row.item.team_experience_level}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(post_game_notes)="row">
                <span v-if="row.item.post_game_notes">{{row.item.post_game_notes}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(completed)="row">
                <span v-if="row.item.completed">Yes</span>
                <span v-else>No</span>
              </template>
              <template v-slot:cell(booking_date)="row">
                <span v-if="row.item.booking_date">{{getFormattedDate(row.item.booking_date)}}</span>
                <span v-else>-</span>
              </template>
              <!--          Transactions-->
              <template v-slot:cell(customer_first_name)="row">
                <span v-if="row.item.customer_first_name">{{row.item.customer_first_name}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(customer_last_name)="row">
                <span v-if="row.item.customer_last_name">{{row.item.customer_last_name}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(customer_email)="row">
                <span v-if="row.item.customer_email">{{row.item.customer_email}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(customer_phone)="row">
                <span v-if="row.item.customer_phone">{{row.item.customer_phone}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(transaction_id)="row">
                <span>#{{row.item.transaction_id}}</span>
              </template>
              <template v-slot:cell(transaction_has_due_amount)="row">
                <span v-if="row.item.transaction_has_due_amount">Yes</span>
                <span v-else>No</span>
              </template>
              <template v-slot:cell(status)="row">
                <span v-if="row.item.status">Active</span>
                <span v-else>Cancelled</span>
              </template>
              <template v-slot:cell(tax)="row">
                <span>{{getAmount(row.item.tax)}}</span>
              </template>
              <template v-slot:cell(discount)="row">
                <span>{{getAmount(row.item.discount)}}</span>
              </template>
              <template v-slot:cell(fee)="row">
                <span>{{getAmount(row.item.fee)}}</span>
              </template>
              <template v-slot:cell(tips)="row">
                <span>{{getAmount(row.item.tips)}}</span>
              </template>
              <template v-slot:cell(tip_assignees)="row">
                <span v-if="row.item.tip_assignees">{{row.item.tip_assignees}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(price)="row">
                <span>{{getAmount(row.item.price)}}</span>
              </template>
              <template v-slot:cell(paid)="row">
                <span>{{getAmount(row.item.paid)}}</span>
              </template>
              <template v-slot:cell(total)="row">
                <span>{{getAmount(row.item.total)}}</span>
              </template>
              <template  v-slot:cell(refunded)="row">
                <span>{{getAmount(row.item.refunded)}}</span>
              </template>
              <template v-slot:cell(due)="row">
                <span>{{getAmount(row.item.due)}}</span>
              </template>
              <template v-slot:cell(promo_codes)="row">
                <span v-if="!row.item.promo_codes">-</span>
                <span v-else>{{ row.item.promo_codes }}</span>
              </template>
              <template v-slot:cell(transaction_date)="row">
                <span v-if="row.item.transaction_date">{{getLocalDate(row.item.transaction_date)}}, {{getLocalTime(row.item.transaction_date)}}</span>
                <span v-else>-</span>
              </template>
<!--              Purchases-->
              <template v-slot:cell(purchase_name)="row">
                <span v-if="row.item.purchase_name && row.item.purchase_type === 'Gift card'">{{getGiftCardCode(row.item.purchase_name)}}</span>
                <span v-if="row.item.purchase_name && row.item.purchase_type !== 'Gift card'">{{row.item.purchase_name}}</span>
                <span v-if="!row.item.purchase_name">-</span>
              </template>
              <template v-slot:cell(purchase_time)="row">
                <span v-if="row.item.purchase_time">{{getLocalDate(row.item.purchase_time)}}, {{getLocalTime(row.item.purchase_time)}}</span>
                <span v-else>-</span>
              </template>
<!--              Payments-->
              <template v-slot:cell(payment_gateway_transaction_id)="row">
                <span v-if="row.item.payment_gateway_transaction_id">{{row.item.payment_gateway_transaction_id}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(payment_method)="row">
                <span>{{getPaymentMethod(row.item.payment_method)}}</span>
              </template>
              <template v-slot:cell(expiry)="row">
                <span>{{getExpiry(row.item.expiry)}}</span>
              </template>
              <template v-slot:cell(last_four)="row">
                <span v-if="row.item.last_four">{{row.item.last_four}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(card_type)="row">
                <span v-if="row.item.card_type">{{capitalizeFirstLetter(row.item.card_type)}}</span>
                <span v-else>-</span>
              </template>
<!--              Promo codes-->
              <template v-slot:cell(description)="row">
                <span v-if="row.item.description">{{row.item.description}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(games)="row">
                <span v-if="row.item.games">{{row.item.games}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(redemption_type)="row">
                <span v-if="row.item.redemption_type === 0">Percentage discount</span>
                <span v-if="row.item.redemption_type === 1">Value discount</span>
              </template>
              <template v-slot:cell(value_type)="row">
                <span v-if="row.item.value_type === 0">Per transaction</span>
                <span v-if="row.item.value_type === 2">Per participant</span>
              </template>
              <template v-slot:cell(value_amount)="row">
                <span>{{getAmount(row.item.value_amount)}}</span>
              </template>
              <template v-slot:cell(quantity)="row">
                <span v-if="row.item.quantity === -1">Unlimited</span>
                <span v-if="row.item.quantity > -1">{{ row.item.quantity }}</span>
              </template>
              <template v-slot:cell(purchase_start_date)="row">
                <span v-if="row.item.purchase_start_date">{{getLocalDate(row.item.purchase_start_date)}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(purchase_end_date)="row">
                <span v-if="row.item.purchase_end_date">{{getLocalDate(row.item.purchase_end_date)}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(event_start_date)="row">
                <span v-if="row.item.event_start_date">{{getLocalDate(row.item.event_start_date)}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(event_end_date)="row">
                <span v-if="row.item.event_end_date">{{getLocalDate(row.item.event_end_date)}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(event_start_time)="row">
                <span v-if="row.item.event_start_time">{{getLocalTime(row.item.event_start_time)}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(event_end_time)="row">
                <span v-if="row.item.event_end_time">{{getLocalTime(row.item.event_end_time)}}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(can_be_combined)="row">
                <span v-if="row.item.can_be_combined === 1">Yes</span>
                <span v-if="row.item.can_be_combined === 0">No</span>
              </template>
              <template v-slot:cell(applicable_to_miscellaneous_items)="row">
                <span v-if="row.item.applicable_to_miscellaneous_items === 1">Yes</span>
                <span v-if="row.item.applicable_to_miscellaneous_items === 0">No</span>
              </template>
              <template v-slot:cell(internal_only)="row">
                <span v-if="row.item.internal_only === 1">Yes</span>
                <span v-if="row.item.internal_only === 0">No</span>
              </template>
            </b-table>

          </div>
        </div>

        <b-pagination  @click.native="generateReport()"
                       v-model="currentPage"
                       :total-rows="totalRows"
                       :per-page="perPage"
        ></b-pagination>

      </card>
    </div>
  </div>
</div>
</template>

<script>
import {Auth} from "aws-amplify";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import {API_LOCATION} from "@/api-config";
import swal from "sweetalert2";
import { BTable, BPagination } from 'bootstrap-vue'
import {formattedDate, time} from "@/plugins/dateFormatter";
import currencies from "@/assets/currencies.json";
import axios from "axios";
import _ from "lodash";
import {Progress, TimePicker} from "element-ui";

export default {
  name: "GeneratedReport",
  components: {
    BreadcrumbItem,
    Breadcrumb,
    BTable,
    BPagination,
    [TimePicker.name]: TimePicker,
    [Progress.name]: Progress,
  },
  data() {
    return {
      downloading: false,
      percent_downloaded: 0,
      loading: false,
      currencies: currencies.currencies,
      number_format: JSON.parse(localStorage.getItem('group')).number_format,
      currency_symbol: '$',
      currency: JSON.parse(localStorage.getItem('group')).currency,
      currency_format: JSON.parse(localStorage.getItem('group')).currency_format,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      groups: [],
      picker_time_format: 'hh:mm A',
      date_format_cal: '',
      day_of_week: '',
      data: [],
      fields: [],
      games: [],
      report: '',
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      perPageOptions: [5, 10, 25, 50],
      filters: {
        tips: {
          value: '',
          actual: ''
        },
        promo_code_name: {
          value: '',
          actual: ''
        },
        description: {
          value: '',
          actual: ''
        },
        redemption_type: {
          value: '',
          actual: ''
        },
        value_type: {
          value: '',
          actual: ''
        },
        value_amount: {
          value: '',
          actual: ''
        },
        can_be_combined: {
          value: '',
          actual: ''
        },
        number_of_times_used: {
          value: '',
          actual: ''
        },
        games: {
          value: '',
          actual: ''
        },
        applicable_to_miscellaneous_items: {
          value: '',
          actual: ''
        },
        internal_only: {
          value: '',
          actual: ''
        },
        exclusion_dates: {
          value: '',
          actual: ''
        },
        restricted_to_days_of_week: {
          value: '',
          actual: ''
        },
        purchase_start_date: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        purchase_end_date: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        event_start_date: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        event_end_date: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        event_start_time: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        event_end_time: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        transaction_has_due_amount: {
          value: '',
          actual: ''
        },
        promo_codes: {
          value: '',
          actual: ''
        },
        tip_assignees: {
          value: '',
          actual: ''
        },
        date_joined: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        date_last_visited: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        payment_collected_by: {
          value: '',
          actual: ''
        },
        payment_methods: {
          value: '',
          actual: ''
        },
        expiry: {
          value: '',
          actual: ''
        },
        last_four: {
          value: '',
          actual: ''
        },
        card_type: {
          value: '',
          actual: ''
        },
        payment_gateway_id: {
          value: '',
          actual: ''
        },
        group_name: {
          value: '',
          actual: ''
        },
        first_name: {
          value: '',
          actual: ''
        },
        last_name: {
          value: '',
          actual: ''
        },
        customer_credit: {
          value: '',
          actual: ''
        },
        opted_out_of_receiving_emails: {
          value: '',
          actual: ''
        },
        birthday: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        state: {
          value: '',
          actual: ''
        },
        email: {
          value: '',
          actual: ''
        },
        phone: {
          value: '',
          actual: ''
        },
        address: {
          value: '',
          actual: ''
        },
        city: {
          value: '',
          actual: ''
        },
        zip: {
          value: '',
          actual: ''
        },
        country: {
          value: '',
          actual: ''
        },
        opted_out_of_posting_photos: {
          value: '',
          actual: ''
        },
        player_experience_level: {
          value: '',
          actual: ''
        },
        referral_source: {
          value: '',
          actual: ''
        },
        start_time: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        end_time: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        game_name: {
          value: '',
          actual: ''
        },
        team_experience_level: {
          value: '',
          actual: ''
        },
        pre_game_notes: {
          value: '',
          actual: ''
        },
        post_game_notes: {
          value: '',
          actual: ''
        },
        completed: {
          value: '',
          actual: ''
        },
        game_master_first_name: {
          value: '',
          actual: ''
        },
        game_master_last_name: {
          value: '',
          actual: ''
        },
        booking_date: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        booking_time: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        number_of_hints: {
          value: '',
          actual: ''
        },
        number_of_breaks: {
          value: '',
          actual: ''
        },
        transaction_id: {
          value: '',
          actual: ''
        },
        customer_first_name: {
          value: '',
          actual: ''
        },
        customer_last_name: {
          value: '',
          actual: ''
        },
        customer_email: {
          value: '',
          actual: ''
        },
        customer_phone: {
          value: '',
          actual: ''
        },
        price: {
          value: '',
          actual: ''
        },
        fee: {
          value: '',
          actual: ''
        },
        discount: {
          value: '',
          actual: ''
        },
        tax: {
          value: '',
          actual: ''
        },
        total: {
          value: '',
          actual: ''
        },
        paid: {
          value: '',
          actual: ''
        },
        refunded: {
          value: '',
          actual: ''
        },
        due: {
          value: '',
          actual: ''
        },
        status: {
          value: '',
          actual: ''
        },
        transaction_date: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        purchase_name: {
          value: '',
          actual: ''
        },
        purchase_type: {
          value: '',
          actual: ''
        },
        quantity: {
          value: '',
          actual: '',
          unlimited: false
        },
        purchase_time: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        }
      }
    }
  },
  methods: {
    hasPermissions(scope){
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      const matchFound = permissions.some(element => element === scope);
      return matchFound
    },
    getExpiry(dateString){
      if(!dateString){
        return '-'
      }
      const [year, month] = dateString.split('-');
      return `${month}/${year.slice(-2)}`;
    },
    getPaymentMethod(method){
      return method
        .charAt(0).toUpperCase() + method.slice(1)
        .replace(/_/g, ' ');
    },
    getGiftCardCode(code){
      if(!code){
        return
      }
      if(isNaN(code)){
        return code
      }
      let c = code.match(/.{1,4}/g);
      return c.join(' ')
    },
    getGameLabels(games){
      if(games.length === 0){
        return ''
      }
      let label = games[0].name
      for(let i = 1; i < games.length; i++){
        label += ', ' + games[i].name
      }
      return label
    },
    resetAll(){
      this.filters = {
        tips: {
          value: '',
          actual: ''
        },
        tip_assignees: {
          value: '',
          actual: ''
        },
        promo_code_name: {
          value: '',
          actual: ''
        },
        description: {
          value: '',
          actual: ''
        },
        redemption_type: {
          value: '',
          actual: ''
        },
        value_type: {
          value: '',
          actual: ''
        },
        value_amount: {
          value: '',
          actual: ''
        },
        can_be_combined: {
          value: '',
          actual: ''
        },
        number_of_times_used: {
          value: '',
          actual: ''
        },
        games: {
          value: '',
          actual: ''
        },
        applicable_to_miscellaneous_items: {
          value: '',
          actual: ''
        },
        internal_only: {
          value: '',
          actual: ''
        },
        exclusion_dates: {
          value: '',
          actual: ''
        },
        restricted_to_days_of_week: {
          value: '',
          actual: ''
        },
        purchase_start_date: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        purchase_end_date: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        event_start_date: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        event_end_date: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        event_start_time: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        event_end_time: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        transaction_has_due_amount: {
          value: '',
          actual: ''
        },
        date_joined: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        date_last_visited: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        promo_codes: {
          value: '',
          actual: ''
        },
        payment_collected_by: {
          value: '',
          actual: ''
        },
        payment_methods: {
          value: '',
          actual: ''
        },
        expiry: {
          value: '',
          actual: ''
        },
        last_four: {
          value: '',
          actual: ''
        },
        card_type: {
          value: '',
          actual: ''
        },
        payment_gateway_id: {
          value: '',
          actual: ''
        },
        group_name: {
          value: '',
          actual: ''
        },
        first_name: {
          value: '',
          actual: ''
        },
        last_name: {
          value: '',
          actual: ''
        },
        customer_credit: {
          value: '',
          actual: ''
        },
        opted_out_of_receiving_emails: {
          value: '',
          actual: ''
        },
        birthday: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        state: {
          value: '',
          actual: ''
        },
        email: {
          value: '',
          actual: ''
        },
        phone: {
          value: '',
          actual: ''
        },
        address: {
          value: '',
          actual: ''
        },
        city: {
          value: '',
          actual: ''
        },
        zip: {
          value: '',
          actual: ''
        },
        country: {
          value: '',
          actual: ''
        },
        opted_out_of_posting_photos: {
          value: '',
          actual: ''
        },
        player_experience_level: {
          value: '',
          actual: ''
        },
        referral_source: {
          value: '',
          actual: ''
        },
        start_time: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        end_time: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        game_name: {
          value: '',
          actual: ''
        },
        team_experience_level: {
          value: '',
          actual: ''
        },
        pre_game_notes: {
          value: '',
          actual: ''
        },
        post_game_notes: {
          value: '',
          actual: ''
        },
        completed: {
          value: '',
          actual: ''
        },
        game_master_first_name: {
          value: '',
          actual: ''
        },
        game_master_last_name: {
          value: '',
          actual: ''
        },
        booking_date: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        booking_time: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        number_of_hints: {
          value: '',
          actual: ''
        },
        number_of_breaks: {
          value: '',
          actual: ''
        },
        transaction_id: {
          value: '',
          actual: ''
        },
        customer_first_name: {
          value: '',
          actual: ''
        },
        customer_last_name: {
          value: '',
          actual: ''
        },
        customer_email: {
          value: '',
          actual: ''
        },
        customer_phone: {
          value: '',
          actual: ''
        },
        price: {
          value: '',
          actual: ''
        },
        fee: {
          value: '',
          actual: ''
        },
        discount: {
          value: '',
          actual: ''
        },
        tax: {
          value: '',
          actual: ''
        },
        total: {
          value: '',
          actual: ''
        },
        paid: {
          value: '',
          actual: ''
        },
        refunded: {
          value: '',
          actual: ''
        },
        due: {
          value: '',
          actual: ''
        },
        status: {
          value: '',
          actual: ''
        },
        transaction_date: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        },
        purchase_name: {
          value: '',
          actual: ''
        },
        purchase_type: {
          value: '',
          actual: ''
        },
        quantity: {
          value: '',
          actual: '',
          unlimited: false
        },
        purchase_time: {
          value_to: '',
          value_from: '',
          actual_to: '',
          actual_from: ''
        }
      }
      this.generateReport()
    },
    fieldExists(field){
      return _.includes(this.fields,  field )
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    async getAllData(config, count){
      let url = ''
      let csvData = []
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let reportId = this.$route.query.id

      let offset = 0
      const maxRetries = 3;
      while(offset <= this.totalRows){
        url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/reports/' + reportId +
          '/run?offset=' + offset + `&count=` + count

        if(this.filters.group_name.actual != ''){
          let group_arr = []
          for(let n in this.filters.group_name.value){
            group_arr.push(this.filters.group_name.value[n].id)
          }
          url += '&groups=[' + encodeURIComponent(group_arr) + ']'
        }
        if(this.filters.payment_methods.actual != ''){
          url += '&payment_methods=' + encodeURIComponent(this.filters.payment_methods.value)
        }
        if(this.filters.payment_collected_by.actual != ''){
          url += '&payment_collected_by=' + encodeURIComponent(this.filters.payment_collected_by.value)
        }
        if(this.filters.promo_codes.actual != ''){
          url += '&promo_codes=' + encodeURIComponent(this.filters.promo_codes.value)
        }
        if(this.filters.card_type.actual != ''){
          url += '&card_type=' + encodeURIComponent(this.filters.card_type.value)
        }
        if(this.filters.expiry.actual != ''){
          url += '&expiry=' + encodeURIComponent(this.filters.expiry.value)
        }
        if(this.filters.last_four.actual != ''){
          url += '&last_four=' + encodeURIComponent(this.filters.last_four.value)
        }
        if(this.filters.payment_gateway_id.actual != ''){
          url += '&payment_gateway_id=' + encodeURIComponent(this.filters.payment_gateway_id.value)
        }
        if(this.filters.first_name.actual != ''){
          url += '&first_name=' + encodeURIComponent(this.filters.first_name.value)
        }
        if(this.filters.last_name.actual != ''){
          url += '&last_name=' + encodeURIComponent(this.filters.last_name.value)
        }
        if(this.filters.opted_out_of_receiving_emails.actual != ''){
          url += '&email_optout=' + encodeURIComponent(this.filters.opted_out_of_receiving_emails.value)
        }
        if(this.filters.birthday.actual_to != null && this.filters.birthday.actual_from != null &&
          this.filters.birthday.actual_to != '' && this.filters.birthday.actual_from != ''){
          url += '&birthday_from=' + encodeURIComponent(this.filters.birthday.value_from) +
            '&birthday_to=' + encodeURIComponent(this.filters.birthday.value_to)
        }
        if(this.filters.date_last_visited.actual_to != null && this.filters.date_last_visited.actual_from != null &&
          this.filters.date_last_visited.actual_to != '' && this.filters.date_last_visited.actual_from != ''){
          url += '&date_last_visited_from=' + encodeURIComponent(this.filters.date_last_visited.value_from) +
            '&date_last_visited_to=' + encodeURIComponent(this.filters.date_last_visited.value_to)
        }
        if(this.filters.date_joined.actual_to != null && this.filters.date_joined.actual_from != null &&
          this.filters.date_joined.actual_to != '' && this.filters.date_joined.actual_from != ''){
          url += '&date_joined_from=' + encodeURIComponent(this.filters.date_joined.value_from) +
            '&date_joined_to=' + encodeURIComponent(this.filters.date_joined.value_to)
        }
        if(this.filters.state.actual != ''){
          url += '&state=' + encodeURIComponent(this.filters.state.value)
        }
        if(this.filters.email.actual != ''){
          url += '&email=' + encodeURIComponent(this.filters.email.value)
        }
        if(this.filters.phone.actual != ''){
          url += '&phone=' + encodeURIComponent(this.filters.phone.value)
        }
        if(this.filters.address.actual != ''){
          url += '&address=' + encodeURIComponent(this.filters.address.value)
        }
        if(this.filters.city.actual != ''){
          url += '&city=' + encodeURIComponent(this.filters.city.value)
        }
        if(this.filters.zip.actual != ''){
          url += '&zip=' + encodeURIComponent(this.filters.zip.value)
        }
        if(this.filters.country.actual != ''){
          url += '&country=' + encodeURIComponent(this.filters.country.value)
        }
        if(this.filters.opted_out_of_posting_photos.actual != ''){
          url += '&picture_optout=' + encodeURIComponent(this.filters.opted_out_of_posting_photos.value)
        }
        if(this.filters.transaction_has_due_amount.actual != ''){
          url += '&transaction_has_due_amount=' + encodeURIComponent(this.filters.transaction_has_due_amount.value)
        }
        if(this.filters.player_experience_level.actual != ''){
          url += '&player_experience_level=' + encodeURIComponent(this.filters.player_experience_level.value)
        }
        if(this.filters.referral_source.actual != ''){
          url += '&referral_source=' + encodeURIComponent(this.filters.referral_source.value)
        }
        //Games
        if(this.filters.start_time.actual_to != null && this.filters.start_time.actual_from != null &&
          this.filters.start_time.actual_to != '' && this.filters.start_time.actual_from != '' ){
          let from = this.getLocalTime(this.filters.start_time.value_from)
          from = ('0' + from.split(':')[0]).slice(-2) + ':' + from.split(':')[1]
          let to = this.getLocalTime(this.filters.start_time.value_to)
          to = ('0' + to.split(':')[0]).slice(-2) + ':' + to.split(':')[1]
          url += '&start_time_from=' + encodeURIComponent(from) +
            '&start_time_to=' + encodeURIComponent(to)
        }
        if(this.filters.end_time.actual_to != null && this.filters.end_time.actual_from != null &&
          this.filters.end_time.actual_to != '' && this.filters.end_time.actual_from != ''){
          let from = this.getLocalTime(this.filters.end_time.value_from)
          from = ('0' + from.split(':')[0]).slice(-2) + ':' + from.split(':')[1]
          let to = this.getLocalTime(this.filters.end_time.value_to)
          to = ('0' + to.split(':')[0]).slice(-2) + ':' + to.split(':')[1]
          url += '&end_time_from=' + encodeURIComponent(from) +
            '&end_time_to=' + encodeURIComponent(to)
        }
        if(this.filters.game_name.actual !== '' && this.filters.game_name.actual.length > 0){
          let arr = []
          for(let n in this.filters.game_name.value){
            arr.push(this.filters.game_name.value[n].id)
          }
          url += '&games=[' + encodeURIComponent(arr) + ']'
        }
        if(this.filters.team_experience_level.actual != ''){
          url += '&team_experience_level=' + encodeURIComponent(this.filters.team_experience_level.value)
        }
        if(this.filters.pre_game_notes.actual != ''){
          url += '&pre_game_notes=' + encodeURIComponent(this.filters.pre_game_notes.value)
        }
        if(this.filters.post_game_notes.actual != ''){
          url += '&post_game_notes=' + encodeURIComponent(this.filters.post_game_notes.value)
        }
        if(this.filters.completed.actual != ''){
          url += '&completed=' + encodeURIComponent(this.filters.completed.value)
        }
        if(this.filters.game_master_first_name.actual != ''){
          url += '&game_master_first_name=' + encodeURIComponent(this.filters.game_master_first_name.value)
        }
        if(this.filters.game_master_last_name.actual != ''){
          url += '&game_master_last_name=' + encodeURIComponent(this.filters.game_master_last_name.value)
        }
        if(this.filters.booking_date.actual_to != null && this.filters.booking_date.actual_from != null &&
          this.filters.booking_date.actual_to != '' && this.filters.booking_date.actual_from != ''){
          url += '&booking_date_from=' + encodeURIComponent(this.filters.booking_date.value_from) +
            '&booking_date_to=' + encodeURIComponent(this.filters.booking_date.value_to)
        }
        if(this.filters.booking_time.actual_to != null && this.filters.booking_time.actual_from != null &&
          this.filters.booking_time.actual_to != '' && this.filters.booking_time.actual_from != ''){
          let from = this.getLocalTime(this.filters.booking_time.value_from)
          from = ('0' + from.split(':')[0]).slice(-2) + ':' + from.split(':')[1]
          let to = this.getLocalTime(this.filters.booking_time.value_to)
          to = ('0' + to.split(':')[0]).slice(-2) + ':' + to.split(':')[1]
          url += '&booking_time_from=' + encodeURIComponent(from) +
            '&booking_time_to=' + encodeURIComponent(to)
        }
        if(this.filters.number_of_hints.actual != ''){
          url += '&number_of_hints=' + encodeURIComponent(this.filters.number_of_hints.value)
        }
        if(this.filters.number_of_breaks.actual != ''){
          url += '&number_of_breaks=' + encodeURIComponent(this.filters.number_of_breaks.value)
        }

        //Transactions
        if(this.filters.transaction_id.actual != ''){
          url += '&transaction_id=' + encodeURIComponent(this.filters.transaction_id.value)
        }
        if(this.filters.customer_first_name.actual != ''){
          url += '&customer_first_name=' + encodeURIComponent(this.filters.customer_first_name.value)
        }
        if(this.filters.customer_last_name.actual != ''){
          url += '&customer_last_name=' + encodeURIComponent(this.filters.customer_last_name.value)
        }
        if(this.filters.customer_email.actual != ''){
          url += '&customer_email=' + encodeURIComponent(this.filters.customer_email.value)
        }
        if(this.filters.customer_phone.actual != ''){
          url += '&customer_phone=' + encodeURIComponent(this.filters.customer_phone.value)
        }
        if(this.filters.price.actual != ''){
          url += '&price=' + encodeURIComponent(this.filters.price.value)
        }
        if(this.filters.customer_credit.actual != ''){
          url += '&customer_credit=' + encodeURIComponent(this.filters.customer_credit.value)
        }
        if(this.filters.fee.actual != ''){
          url += '&fee=' + encodeURIComponent(this.filters.fee.value)
        }
        if(this.filters.tips.actual != ''){
          url += '&tips=' + encodeURIComponent(this.filters.tips.value)
        }
        if(this.filters.tip_assignees.actual != ''){
          url += '&tip_assignees=' + encodeURIComponent(this.filters.tip_assignees.value)
        }
        if(this.filters.discount.actual != ''){
          url += '&discount=' + encodeURIComponent(this.filters.discount.value)
        }
        if(this.filters.tax.actual != ''){
          url += '&tax=' + encodeURIComponent(this.filters.tax.value)
        }
        if(this.filters.total.actual != ''){
          url += '&total=' + encodeURIComponent(this.filters.total.value)
        }
        if(this.filters.paid.actual != ''){
          url += '&paid=' + encodeURIComponent(this.filters.paid.value)
        }
        if(this.filters.refunded.actual != ''){
          url += '&refunded=' + encodeURIComponent(this.filters.refunded.value)
        }
        if(this.filters.due.actual != ''){
          url += '&due=' + encodeURIComponent(this.filters.due.value)
        }
        if(this.filters.status.actual != ''){
          url += '&status=' + encodeURIComponent(this.filters.status.value)
        }
        if(this.filters.transaction_date.actual_to != null && this.filters.transaction_date.actual_from != null &&
          this.filters.transaction_date.actual_to != '' && this.filters.transaction_date.actual_from != ''){
          let userStartDate = this.filters.transaction_date.value_from
          let userEndDate = this.filters.transaction_date.value_to


          let startLocalDate = new Date(`${userStartDate}T00:00:00`);
          let endLocalDate = new Date(`${userEndDate}T23:59:59`);

          let from = startLocalDate.toISOString();
          let to = endLocalDate.toISOString();

          url += '&transaction_date_from=' + from +
            '&transaction_date_to=' + to
        }
        //Purchases
        if(this.filters.purchase_time.actual_to != null && this.filters.purchase_time.actual_from != null &&
          this.filters.purchase_time.actual_to != '' && this.filters.purchase_time.actual_from != ''){
          let userStartDate = this.filters.purchase_time.value_from
          let userEndDate = this.filters.purchase_time.value_to


          let startLocalDate = new Date(`${userStartDate}T00:00:00`);
          let endLocalDate = new Date(`${userEndDate}T23:59:59`);

          let from = startLocalDate.toISOString();
          let to = endLocalDate.toISOString();
          url += '&purchase_time_from=' + from +
            '&purchase_time_to=' + to
        }
        if(this.filters.purchase_name.actual != ''){
          url += '&purchase_name=' + encodeURIComponent(this.filters.purchase_name.value)
        }
        if(this.filters.purchase_type.actual != ''){
          url += '&purchase_type=' + encodeURIComponent(this.filters.purchase_type.value)
        }
        if(this.filters.quantity.actual !== '' || this.filters.quantity.unlimited){
          let q = this.filters.quantity.unlimited ? -1 : this.filters.quantity.value
          url += '&quantity=' + encodeURIComponent(q)
        }

        //promo codes
        if(this.filters.promo_code_name.actual !== ''){
          url += '&promo_code_name=' + encodeURIComponent(this.filters.promo_code_name.value)
        }
        if(this.filters.description.actual !== ''){
          url += '&description=' + encodeURIComponent(this.filters.description.value)
        }
        if(this.filters.redemption_type.actual !== ''){
          url += '&redemption_type=' + encodeURIComponent(this.filters.redemption_type.value)
        }
        if(this.filters.value_type.actual !== ''){
          url += '&value_type=' + encodeURIComponent(this.filters.value_type.value)
        }
        if(this.filters.value_amount.actual !== ''){
          url += '&value_amount=' + encodeURIComponent(this.filters.value_amount.value)
        }
        if(this.filters.can_be_combined.actual !== ''){
          url += '&can_be_combined=' + encodeURIComponent(this.filters.can_be_combined.value)
        }
        if(this.filters.number_of_times_used.actual !== ''){
          url += '&number_of_times_used=' + encodeURIComponent(this.filters.number_of_times_used.value)
        }

        if(this.filters.applicable_to_miscellaneous_items.actual !== ''){
          url += '&applicable_to_miscellaneous_items=' + encodeURIComponent(this.filters.applicable_to_miscellaneous_items.value)
        }
        if(this.filters.internal_only.actual !== ''){
          url += '&internal_only=' + encodeURIComponent(this.filters.internal_only.value)
        }

        if(this.filters.games.actual !== '' && this.filters.games.actual.length > 0){
          let arr = []
          for(let n in this.filters.games.value){
            arr.push(this.filters.games.value[n].name)
          }
          url += '&games=[' + encodeURIComponent(arr) + ']'
        }

        let retries = 0;
        while (retries < maxRetries) {
          try {
            const response = await axios.get(url, config);
            csvData.push(...response.data.result); // Flatten and add directly
            break; // Exit retry loop on success
          } catch (err) {
            retries++;

            if (retries >= maxRetries) {
              console.warn(`Skipping request for offset ${offset} after ${maxRetries} failed attempts.`);
            }
          }
        }
        offset = offset + count
        this.percent_downloaded = ((offset/this.totalRows) * 100).toFixed(0)
      }

      let arr = []
      csvData.forEach((element) => {
        arr = arr.concat(element)

      })
      return arr
    },
    async downloadCSV(){
      //get the entire data set
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }

      let count = 5000
      if(this.report.report_type_id === 4){ count = 100 }
      this.downloading = true
      let csvData = await this.getAllData(config, count)
      this.downloading = false

      let fields = Object.keys(this.data[0]);
      let csv = this.capitalizeFirstLetter(fields[0].replaceAll('_', ' '))
      for(let n = 1; n < fields.length; n++){
        csv += ', ' + this.capitalizeFirstLetter(fields[n].replaceAll('_', ' '))
      }
      csv += "\n";


      csvData.forEach(row => {
        let f = []
        for (const [key, value] of Object.entries(row)) {
          let val = value
          if(key === 'status'){
            val = value == 0 ? 'Cancelled' : 'Active'
          }
          if(key === 'birthday' || key === 'booking_date'){
            val = this.getFormattedDate(value)
          }
          if(key === 'first_name' || key === 'customer_first_name' || key === 'customer_last_name'
            || key === 'last_name' || key === 'referral_source' || key === 'payment_gateway_transaction_id'
            || key === 'player_experience_level' || key === 'card_type' || key === 'games'){
            val = (value) ? this.capitalizeFirstLetter(this.escapeCSVValue(value)) : '-'
          }
          if(key === 'booking_time' || key === 'start_time' || key === 'end_time'){
            val = this.getTime(value)
          }
          if(key === 'completed' || key === 'opted_out_of_posting_photos' || key === 'transaction_has_due_amount'
            || key === 'opted_out_of_receiving_emails' || key === 'can_be_combined' || key === 'internal_only'
            || key === 'applicable_to_miscellaneous_items'){
            val = (value) ? 'Yes' : 'No'
          }
          if(key === 'redemption_type'){
            val = (value) ? 'Percentage discount' : 'Value discount'
          }
          if(key === 'value_type'){
            val = (value === 0) ? 'Per transaction' : 'Per participant'
          }
          if(key === 'transaction_date' || key === 'purchase_time' || key === 'date_joined' || key === 'date_last_visited'){
            if(!value || value.includes('0000')){
              val = '-'
            } else {
              val = this.escapeCSVValue(this.getLocalDate(value)) + ' ' + this.escapeCSVValue(this.getLocalTime(value))
            }
          }
          if(key === 'expiry'){
            val = (value) ? this.getExpiry(value) : '-'
          }
          if(key === 'last_four' || key === 'payment_collected_by' || key === 'description'){
            val = (value) ? (value) : '-'
          }
          if(key === 'promo_codes' || key === 'tip_assignees'){
            val = (value) ? this.escapeCSVValue(value) : '-'
          }
          if(key === 'payment_method'){
            val = (value) ? this.getPaymentMethod(value) : '-'
          }
          if(key === 'quantity'){
            val = (value === -1) ? 'Unlimited' : (value)
          }
          if(key === 'due' || key === 'tips' || key === 'tax' || key === 'fee' || key === 'total' || key === 'refunded' || key === 'customer_credit'
            || key === 'price' || key === 'paid' || key === 'discount' || key === 'value_amount'){
            val = this.getAmount(value)
          }

          f.push(val);
        }

        csv += f
        csv += "\n";
      });

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'report.csv';
      anchor.click();
    },
    escapeCSVValue(value) {
      if (typeof value === 'string') {
        // Escape double quotes by doubling them
        value = value.replace(/"/g, '""');

        // If the value contains a comma, wrap it in double quotes
        if (value.includes(',') || value.includes('\n')) {
          value = `"${value}"`;
        }
      }
      return value;
    },
    getLocalDate(d){
      let local = new Date(d)
      let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
      return this.getFormattedDate(form)
    },
    getLocalTime(d){
      let local = new Date(d)
      let mins =  local.getMinutes()
      if(mins < 10){
        mins = '0' + mins
      }
      return this.getTime(local.getHours() + ':' + mins)
    },
    getAmount(integer){
      if(integer == null){
        return
      }
      if(this.number_format === 1 && this.currency_format === 0){
        return this.currency_symbol + integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',')
      } else if(this.number_format === 1 && this.currency_format === 1){
        return integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',') + ' ' + this.currency
      } else if (this.number_format === 1 && this.currency_format === 1){
        return parseFloat(integer).toFixed(2) + ' ' + this.currency
      }
      return this.currency_symbol + parseFloat(integer).toFixed(2);
    },
    getTime(d) {
      if(d instanceof Date){
        d = d.toISOString()
      }
      return time(this.time_format, d)
    },
    getFormattedDate(d){
      if(d === '0000-00-00'){
        return '-'
      }
      return formattedDate(this.date_format, d)
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    resetField(field1, field2){
      if(field1){
        field1.value = ''
        field1.actual = ''
      }
      if(field1.hasOwnProperty('unlimited')){
        field1.unlimited = false
      }
      if(field2){
        field2.actual_from = ''
        field2.value_from = ''
        field2.actual_to = ''
        field2.value_to = ''
      }
      this.generateReport()
    },
    setFilters(){
      this.filters.group_name.actual = this.filters.group_name.value
      //Customers
      this.filters.first_name.actual = this.filters.first_name.value
      this.filters.last_name.actual = this.filters.last_name.value
      this.filters.opted_out_of_receiving_emails.actual = this.filters.opted_out_of_receiving_emails.value
      this.filters.birthday.actual_to = this.filters.birthday.value_to
      this.filters.birthday.actual_from = this.filters.birthday.value_from
      this.filters.date_last_visited.actual_to = this.filters.date_last_visited.value_to
      this.filters.date_last_visited.actual_from = this.filters.date_last_visited.value_from
      this.filters.date_joined.actual_to = this.filters.date_joined.value_to
      this.filters.date_joined.actual_from = this.filters.date_joined.value_from
      this.filters.state.actual = this.filters.state.value
      this.filters.email.actual = this.filters.email.value
      this.filters.phone.actual = this.filters.phone.value
      this.filters.address.actual = this.filters.address.value
      this.filters.city.actual = this.filters.city.value
      this.filters.zip.actual = this.filters.zip.value
      this.filters.country.actual = this.filters.country.value
      this.filters.opted_out_of_posting_photos.actual = this.filters.opted_out_of_posting_photos.value
      this.filters.player_experience_level.actual = this.filters.player_experience_level.value
      this.filters.referral_source.actual = this.filters.referral_source.value

    //  Games
      this.filters.start_time.actual_to = this.filters.start_time.value_to
      this.filters.start_time.actual_from = this.filters.start_time.value_from
      this.filters.end_time.actual_to = this.filters.end_time.value_to
      this.filters.end_time.actual_from = this.filters.end_time.value_from
      this.filters.game_name.actual = this.filters.game_name.value
      this.filters.team_experience_level.actual = this.filters.team_experience_level.value
      this.filters.pre_game_notes.actual = this.filters.pre_game_notes.value
      this.filters.post_game_notes.actual = this.filters.post_game_notes.value
      this.filters.completed.actual = this.filters.completed.value
      this.filters.game_master_first_name.actual = this.filters.game_master_first_name.value
      this.filters.game_master_last_name.actual = this.filters.game_master_last_name.value
      this.filters.booking_date.actual_to = this.filters.booking_date.value_to
      this.filters.booking_date.actual_from = this.filters.booking_date.value_from
      this.filters.booking_time.actual_to = this.filters.booking_time.value_to
      this.filters.booking_time.actual_from = this.filters.booking_time.value_from
      this.filters.number_of_hints.actual = this.filters.number_of_hints.value
      this.filters.number_of_breaks.actual = this.filters.number_of_breaks.value

    //  Transactions
      this.filters.transaction_id.actual = this.filters.transaction_id.value
      this.filters.customer_last_name.actual = this.filters.customer_last_name.value
      this.filters.customer_first_name.actual = this.filters.customer_first_name.value
      this.filters.customer_email.actual = this.filters.customer_email.value
      this.filters.customer_phone.actual = this.filters.customer_phone.value
      this.filters.price.actual = this.filters.price.value
      this.filters.customer_credit.actual = this.filters.customer_credit.value
      this.filters.fee.actual = this.filters.fee.value
      this.filters.tips.actual = this.filters.tips.value
      this.filters.tip_assignees.actual = this.filters.tip_assignees.value
      this.filters.discount.actual = this.filters.discount.value
      this.filters.tax.actual = this.filters.tax.value
      this.filters.total.actual = this.filters.total.value
      this.filters.paid.actual = this.filters.paid.value
      this.filters.refunded.actual = this.filters.refunded.value
      this.filters.due.actual = this.filters.due.value
      this.filters.status.actual = this.filters.status.value
      this.filters.transaction_date.actual_from = this.filters.transaction_date.value_from
      this.filters.transaction_date.actual_to = this.filters.transaction_date.value_to
      this.filters.promo_codes.actual = this.filters.promo_codes.value


      //Purchases
      this.filters.purchase_name.actual = this.filters.purchase_name.value
      this.filters.purchase_type.actual = this.filters.purchase_type.value
      this.filters.quantity.actual = this.filters.quantity.value
      this.filters.purchase_time.actual_from = this.filters.purchase_time.value_from
      this.filters.purchase_time.actual_to = this.filters.purchase_time.value_to
      this.filters.transaction_has_due_amount.actual = this.filters.transaction_has_due_amount.value

      //Payments
      this.filters.payment_methods.actual = this.filters.payment_methods.value
      this.filters.payment_collected_by.actual = this.filters.payment_collected_by.value
      this.filters.card_type.actual = this.filters.card_type.value
      this.filters.expiry.actual = this.filters.expiry.value
      this.filters.last_four.actual = this.filters.last_four.value
      this.filters.payment_gateway_id.actual = this.filters.payment_gateway_id.value

      //Promo codes
      this.filters.promo_code_name.actual = this.filters.promo_code_name.value
      this.filters.description.actual = this.filters.description.value
      this.filters.redemption_type.actual = this.filters.redemption_type.value
      this.filters.value_type.actual = this.filters.value_type.value
      this.filters.value_amount.actual = this.filters.value_amount.value
      this.filters.can_be_combined.actual = this.filters.can_be_combined.value
      this.filters.number_of_times_used.actual = this.filters.number_of_times_used.value
      this.filters.applicable_to_miscellaneous_items.actual = this.filters.applicable_to_miscellaneous_items.value
      this.filters.internal_only.actual = this.filters.internal_only.value
      this.filters.games.actual = this.filters.games.value
    },
    buildUrl(){
      let count = this.perPage
      let offset = parseInt((this.currentPage * this.perPage) - this.perPage)
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let reportId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/reports/' + reportId +
        '/run?offset=' + offset + `&count=` + count

      if(this.filters.group_name.actual != ''){
        let group_arr = []
        for(let n in this.filters.group_name.value){
          group_arr.push(this.filters.group_name.value[n].id)
        }
        url += '&groups=[' + encodeURIComponent(group_arr) + ']'
      }
      if(this.filters.payment_methods.actual !== ''){
        url += '&payment_methods=' + encodeURIComponent(this.filters.payment_methods.value)
      }
      if(this.filters.promo_codes.actual !== ''){
        url += '&promo_codes=' + encodeURIComponent(this.filters.promo_codes.value)
      }
      if(this.filters.payment_collected_by.actual !== ''){
        url += '&payment_collected_by=' + encodeURIComponent(this.filters.payment_collected_by.value)
      }
      if(this.filters.card_type.actual !== ''){
        url += '&card_type=' + encodeURIComponent(this.filters.card_type.value)
      }
      if(this.filters.expiry.actual !== ''){
        url += '&expiry=' + encodeURIComponent(this.filters.expiry.value)
      }
      if(this.filters.last_four.actual !== ''){
        url += '&last_four=' + encodeURIComponent(this.filters.last_four.value)
      }
      if(this.filters.payment_gateway_id.actual !== ''){
        url += '&payment_gateway_id=' + encodeURIComponent(this.filters.payment_gateway_id.value)
      }
      if(this.filters.first_name.actual !== ''){
        url += '&first_name=' + encodeURIComponent(this.filters.first_name.value)
      }
      if(this.filters.last_name.actual !== ''){
        url += '&last_name=' + encodeURIComponent(this.filters.last_name.value)
      }
      if(this.filters.opted_out_of_receiving_emails.actual !== ''){
        url += '&email_optout=' + encodeURIComponent(this.filters.opted_out_of_receiving_emails.value)
      }
      if(this.filters.transaction_has_due_amount.actual !== ''){
        url += '&transaction_has_due_amount=' + encodeURIComponent(this.filters.transaction_has_due_amount.value)
      }
      if(this.filters.birthday.actual_to !== null && this.filters.birthday.actual_from != null &&
        this.filters.birthday.actual_to !== '' && this.filters.birthday.actual_from != ''){
        url += '&birthday_from=' + encodeURIComponent(this.filters.birthday.value_from) +
          '&birthday_to=' + encodeURIComponent(this.filters.birthday.value_to)
      }
      if(this.filters.date_last_visited.actual_to !== null && this.filters.date_last_visited.actual_from != null &&
        this.filters.date_last_visited.actual_to !== '' && this.filters.date_last_visited.actual_from != ''){
        url += '&date_last_visited_from=' + encodeURIComponent(this.filters.date_last_visited.value_from) +
          '&date_last_visited_to=' + encodeURIComponent(this.filters.date_last_visited.value_to)
      }
      if(this.filters.date_joined.actual_to !== null && this.filters.date_joined.actual_from != null &&
        this.filters.date_joined.actual_to !== '' && this.filters.date_joined.actual_from != ''){
        url += '&date_joined_from=' + encodeURIComponent(this.filters.date_joined.value_from) +
          '&date_joined_to=' + encodeURIComponent(this.filters.date_joined.value_to)
      }
      if(this.filters.state.actual !== ''){
        url += '&state=' + encodeURIComponent(this.filters.state.value)
      }
      if(this.filters.email.actual !== ''){
        url += '&email=' + encodeURIComponent(this.filters.email.value)
      }
      if(this.filters.phone.actual !== ''){
        url += '&phone=' + encodeURIComponent(this.filters.phone.value)
      }
      if(this.filters.address.actual !== ''){
        url += '&address=' + encodeURIComponent(this.filters.address.value)
      }
      if(this.filters.city.actual !== ''){
        url += '&city=' + encodeURIComponent(this.filters.city.value)
      }
      if(this.filters.zip.actual !== ''){
        url += '&zip=' + encodeURIComponent(this.filters.zip.value)
      }
      if(this.filters.country.actual !== ''){
        url += '&country=' + encodeURIComponent(this.filters.country.value)
      }
      if(this.filters.opted_out_of_posting_photos.actual !== ''){
        url += '&picture_optout=' + encodeURIComponent(this.filters.opted_out_of_posting_photos.value)
      }
      if(this.filters.player_experience_level.actual !== ''){
        url += '&player_experience_level=' + encodeURIComponent(this.filters.player_experience_level.value)
      }
      if(this.filters.referral_source.actual !== ''){
        url += '&referral_source=' + encodeURIComponent(this.filters.referral_source.value)
      }
      if(this.filters.referral_source.actual !== ''){
        url += '&referral_source=' + encodeURIComponent(this.filters.referral_source.value)
      }

      //Games
      if(this.filters.start_time.actual_to !== null && this.filters.start_time.actual_from !== null &&
        this.filters.start_time.actual_to !== '' && this.filters.start_time.actual_from !== '' ){
        let from = this.getLocalTime(this.filters.start_time.value_from)
        from = ('0' + from.split(':')[0]).slice(-2) + ':' + from.split(':')[1]
        let to = this.getLocalTime(this.filters.start_time.value_to)
        to = ('0' + to.split(':')[0]).slice(-2) + ':' + to.split(':')[1]
        url += '&start_time_from=' + encodeURIComponent(from) +
          '&start_time_to=' + encodeURIComponent(to)
      }
      if(this.filters.end_time.actual_to !== null && this.filters.end_time.actual_from !== null &&
        this.filters.end_time.actual_to !== '' && this.filters.end_time.actual_from !== ''){
        let from = this.getLocalTime(this.filters.end_time.value_from)
        from = ('0' + from.split(':')[0]).slice(-2) + ':' + from.split(':')[1]
        let to = this.getLocalTime(this.filters.end_time.value_to)
        to = ('0' + to.split(':')[0]).slice(-2) + ':' + to.split(':')[1]
        url += '&end_time_from=' + encodeURIComponent(from) +
          '&end_time_to=' + encodeURIComponent(to)
      }
      if(this.filters.game_name.actual !== '' && this.filters.game_name.actual.length > 0){
        let arr = []
        for(let n in this.filters.game_name.value){
          arr.push(this.filters.game_name.value[n].id)
        }
        url += '&games=[' + encodeURIComponent(arr) + ']'
      }
      if(this.filters.team_experience_level.actual !== ''){
        url += '&team_experience_level=' + encodeURIComponent(this.filters.team_experience_level.value)
      }
      if(this.filters.pre_game_notes.actual !== ''){
        url += '&pre_game_notes=' + encodeURIComponent(this.filters.pre_game_notes.value)
      }
      if(this.filters.post_game_notes.actual !== ''){
        url += '&post_game_notes=' + encodeURIComponent(this.filters.post_game_notes.value)
      }
      if(this.filters.completed.actual !== ''){
        url += '&completed=' + encodeURIComponent(this.filters.completed.value)
      }
      if(this.filters.game_master_first_name.actual !== ''){
        url += '&game_master_first_name=' + encodeURIComponent(this.filters.game_master_first_name.value)
      }
      if(this.filters.game_master_last_name.actual !== ''){
        url += '&game_master_last_name=' + encodeURIComponent(this.filters.game_master_last_name.value)
      }
      if(this.filters.booking_date.actual_to !== null && this.filters.booking_date.actual_from !== null &&
        this.filters.booking_date.actual_to !== '' && this.filters.booking_date.actual_from !== ''){
        url += '&booking_date_from=' + encodeURIComponent(this.filters.booking_date.value_from) +
          '&booking_date_to=' + encodeURIComponent(this.filters.booking_date.value_to)
      }
      if(this.filters.booking_time.actual_to !== null && this.filters.booking_time.actual_from !== null &&
        this.filters.booking_time.actual_to !== '' && this.filters.booking_time.actual_from !== ''){
        let from = this.getLocalTime(this.filters.booking_time.value_from)
        from = ('0' + from.split(':')[0]).slice(-2) + ':' + from.split(':')[1]
        let to = this.getLocalTime(this.filters.booking_time.value_to)
        to = ('0' + to.split(':')[0]).slice(-2) + ':' + to.split(':')[1]
        url += '&booking_time_from=' + encodeURIComponent(from) +
          '&booking_time_to=' + encodeURIComponent(to)
      }
      if(this.filters.number_of_hints.actual !== ''){
        url += '&number_of_hints=' + encodeURIComponent(this.filters.number_of_hints.value)
      }
      if(this.filters.number_of_breaks.actual !== ''){
        url += '&number_of_breaks=' + encodeURIComponent(this.filters.number_of_breaks.value)
      }

      //Transactions
      if(this.filters.transaction_id.actual !== ''){
        url += '&transaction_id=' + encodeURIComponent(this.filters.transaction_id.value)
      }
      if(this.filters.customer_first_name.actual !== ''){
        url += '&customer_first_name=' + encodeURIComponent(this.filters.customer_first_name.value)
      }
      if(this.filters.customer_last_name.actual !== ''){
        url += '&customer_last_name=' + encodeURIComponent(this.filters.customer_last_name.value)
      }
      if(this.filters.customer_email.actual !== ''){
        url += '&customer_email=' + encodeURIComponent(this.filters.customer_email.value)
      }
      if(this.filters.customer_phone.actual !== ''){
        url += '&customer_phone=' + encodeURIComponent(this.filters.customer_phone.value)
      }
      if(this.filters.price.actual !== ''){
        url += '&price=' + encodeURIComponent(this.filters.price.value)
      }
      if(this.filters.customer_credit.actual !== ''){
        url += '&customer_credit=' + encodeURIComponent(this.filters.customer_credit.value)
      }
      if(this.filters.fee.actual !== ''){
        url += '&fee=' + encodeURIComponent(this.filters.fee.value)
      }
      if(this.filters.tips.actual !== ''){
        url += '&tips=' + encodeURIComponent(this.filters.tips.value)
      }
      if(this.filters.tip_assignees.actual !== ''){
        url += '&tip_assignees=' + encodeURIComponent(this.filters.tip_assignees.value)
      }
      if(this.filters.discount.actual !== ''){
        url += '&discount=' + encodeURIComponent(this.filters.discount.value)
      }
      if(this.filters.tax.actual !== ''){
        url += '&tax=' + encodeURIComponent(this.filters.tax.value)
      }
      if(this.filters.total.actual !== ''){
        url += '&total=' + encodeURIComponent(this.filters.total.value)
      }
      if(this.filters.paid.actual !== ''){
        url += '&paid=' + encodeURIComponent(this.filters.paid.value)
      }
      if(this.filters.refunded.actual !== ''){
        url += '&refunded=' + encodeURIComponent(this.filters.refunded.value)
      }
      if(this.filters.due.actual !== ''){
        url += '&due=' + encodeURIComponent(this.filters.due.value)
      }
      if(this.filters.status.actual !== ''){
        url += '&status=' + encodeURIComponent(this.filters.status.value)
      }
      if(this.filters.transaction_date.actual_to !== null && this.filters.transaction_date.actual_from !== null &&
        this.filters.transaction_date.actual_to !== '' && this.filters.transaction_date.actual_from !== ''){
        let userStartDate = this.filters.transaction_date.value_from
        let userEndDate = this.filters.transaction_date.value_to


        let startLocalDate = new Date(`${userStartDate}T00:00:00`);
        let endLocalDate = new Date(`${userEndDate}T23:59:59`);

        let from = startLocalDate.toISOString();
        let to = endLocalDate.toISOString();

        url += '&transaction_date_from=' + from +
          '&transaction_date_to=' + to

      }
      //Purchases
      if(this.filters.purchase_time.actual_to !== null && this.filters.purchase_time.actual_from !== null &&
        this.filters.purchase_time.actual_to !== '' && this.filters.purchase_time.actual_from !== ''){
        let userStartDate = this.filters.purchase_time.value_from
        let userEndDate = this.filters.purchase_time.value_to


        let startLocalDate = new Date(`${userStartDate}T00:00:00`);
        let endLocalDate = new Date(`${userEndDate}T23:59:59`);

        let from = startLocalDate.toISOString();
        let to = endLocalDate.toISOString();
        url += '&purchase_time_from=' + from +
          '&purchase_time_to=' + to
      }
      if(this.filters.purchase_name.actual !== ''){
        url += '&purchase_name=' + encodeURIComponent(this.filters.purchase_name.value)
      }
      if(this.filters.purchase_type.actual !== ''){
        url += '&purchase_type=' + encodeURIComponent(this.filters.purchase_type.value)
      }
      if(this.filters.quantity.actual !== '' || this.filters.quantity.unlimited){
        let q = this.filters.quantity.unlimited ? -1 : this.filters.quantity.value
        url += '&quantity=' + encodeURIComponent(q)
      }

      //promo codes
      if(this.filters.promo_code_name.actual !== ''){
        url += '&promo_code_name=' + encodeURIComponent(this.filters.promo_code_name.value)
      }
      if(this.filters.description.actual !== ''){
        url += '&description=' + encodeURIComponent(this.filters.description.value)
      }
      if(this.filters.redemption_type.actual !== ''){
        url += '&redemption_type=' + encodeURIComponent(this.filters.redemption_type.value)
      }
      if(this.filters.value_type.actual !== ''){
        url += '&value_type=' + encodeURIComponent(this.filters.value_type.value)
      }
      if(this.filters.value_amount.actual !== ''){
        url += '&value_amount=' + encodeURIComponent(this.filters.value_amount.value)
      }
      if(this.filters.can_be_combined.actual !== ''){
        url += '&can_be_combined=' + encodeURIComponent(this.filters.can_be_combined.value)
      }
      if(this.filters.number_of_times_used.actual !== ''){
        url += '&number_of_times_used=' + encodeURIComponent(this.filters.number_of_times_used.value)
      }
      if(this.filters.applicable_to_miscellaneous_items.actual !== ''){
        url += '&applicable_to_miscellaneous_items=' + encodeURIComponent(this.filters.applicable_to_miscellaneous_items.value)
      }
      if(this.filters.internal_only.actual !== ''){
        url += '&internal_only=' + encodeURIComponent(this.filters.internal_only.value)
      }
      if(this.filters.games.actual !== '' && this.filters.games.actual.length > 0){
        let arr = []
        for(let n in this.filters.games.value){
          arr.push(`"${this.filters.games.value[n].name}"`);
        }
        url += '&games=[' + encodeURIComponent(arr) + ']'
      }

      return url
    },
    async generateReport(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      this.setFilters()
      let url = this.buildUrl()

      this.axios.get(url, config)
        .then(response => {
          this.loading = false
          this.report = response.data.report
          this.fields = []
          this.groups = response.data.groups
          this.games = response.data.games

          for(let n in response.data.fields){
            this.fields.push(response.data.fields[n].field)
          }
          this.data = response.data.result
          this.totalRows = response.data.total_rows
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    }
  },
  mounted() {
    if (this.time_format === 'HH:mm') {
      this.picker_time_format = 'HH:mm'
    }
    this.date_format_cal = this.date_format
    this.date_format_cal =  this.date_format_cal.replaceAll('Y', 'y')
    this.date_format_cal = this.date_format_cal.replaceAll('D', 'd')
    if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
      this.day_of_week = 'ddd, '
    }
    this.generateReport()
  }
}
</script>

<style scoped>

</style>
