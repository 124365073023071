<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>

    <!--  Feature not enabled-->
    <div v-if="feature_not_enabled" class="mt-3" >
      <div class="font-icon-detail">
            <span style="font-size: 3em">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
        <h4 class="mt-3">This feature is not enabled.</h4>
        <base-button type="primary" v-if="hasPermissions('billing_view')"
                     v-on:click="goToBilling()" class="mt-3">Enable feature</base-button>
        <p style="font-size: medium" v-else>Please contact a site administrator to activate.</p>
      </div>
    </div>

    <div v-if="!feature_not_enabled">
      <!--    Fonts-->
      <div v-for="(f, i) in fonts" :key="'C' + i">
        <link rel="stylesheet" :href="'https://fonts.googleapis.com/css?family=' + f.family">
      </div>

      <div class="row">
        <div :class="customColWidth">
          <card card-body-classes="table-full-width">
            <!--        Title-->
            <div class="row mb-3">
              <div class="col">
                <div
                  class="btn-group btn-group-toggle"
                  :class="'float-left'"
                  data-toggle="buttons"
                >
                  <label
                    v-for="(option, index) in categories"
                    :key="index + 'j'"
                    class="btn btn-sm btn-primary btn-simple"
                    :class="{ active: option.active }"
                    :id="index"
                  >
                    <input
                      type="radio"
                      @click="switchTab(index)"
                      name="options"
                      autocomplete="off"
                      :checked="option.active"
                    />
                    <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                    <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
                  </label>
                </div>
              </div>
            </div>

            <!--        General settings-->
            <div v-if="activeTabIndex === 0">
              <div class="row">
                <div class="col-md-12 col-lg-12 col-sm-12 col-12">
                  <label>Title</label>
                  <base-input type="text" placeholder="Enter some text" v-model="selectedBss.title"></base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-lg-12 col-sm-12 col-12">
                  <label>Subtext</label>
                  <base-input type="text" placeholder="Enter some text" v-model="selectedBss.subtext"></base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-lg-6 col-sm-12 col-12">
                  <label>
                    <div>Redirect link
                      <el-popover trigger="hover"
                                  placement="right">

                        <div>
                          <div class="popover-body">This link will appear on the last page of the customer booking experience.<br/>
                            Use it to redirect customer to your website or social media. <br/>
                            It will go to https://offthecouch.io by default</div>
                        </div>
                        <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                      </el-popover>
                    </div>
                  </label>
                  <base-input :error="errs.redirect_url" type="text" placeholder="http://example.com" v-model="selectedBss.redirect_url"></base-input>
                </div>
                <div class="col-md-12 col-lg-4 col-sm-12 col-12" v-if="selectedBss.auto_redirect">
                  <label>
                    <div>Redirect delay (seconds)
                      <el-popover trigger="hover"
                                  placement="right">

                        <div>
                          <div class="popover-body">The amount of time before the user is automatically redirected to the specified location</div>
                        </div>
                        <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                      </el-popover>
                    </div>
                  </label>
                  <base-input placeholder="15s"
                              name="delay"
                  >
                    <el-input-number v-model="selectedBss.redirect_delay" :min="1" :max="1000"/>
                  </base-input>
                </div>
                <div class="col-md-12 col-lg-6 col-sm-12 col-12" v-if="!selectedBss.auto_redirect">
                  <label>
                    <div>Redirect button text
                      <el-popover trigger="hover"
                                  placement="right">

                        <div>
                          <div class="popover-body">This is the label for the button that will redirect the user <br/> to the desired location when a booking is completed</div>
                        </div>
                        <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                      </el-popover>
                    </div>
                  </label>
                  <base-input :error="errs.redirect_button_text" type="text" placeholder="Back" v-model="selectedBss.redirect_button_text"></base-input>
                </div>


              </div>
              <div class="row">
                <div class="col">
                  <base-checkbox type="checkbox"
                                 v-model="selectedBss.auto_redirect"> Automatically redirect the user when a booking has been completed
                  </base-checkbox>
                </div>
                <div class="col">
                  <base-checkbox type="checkbox"
                                 v-model="selectedBss.display_waiver_link"> Include waiver link on booking confirmation
                  </base-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <base-checkbox type="checkbox"
                                 v-model="selectedBss.allow_autoscroll"> Auto rotate images
                  </base-checkbox>
                </div>
                <div class="col">
                  <base-checkbox type="checkbox"
                                 v-model="selectedBss.display_progress_bar"> Display progress bar</base-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <base-checkbox type="checkbox"
                                 v-model="selectedBss.show_unavailable_slots"> Show sold out and blocked slots on booking site</base-checkbox>
                </div>
                <div class="col">
                  <base-checkbox type="checkbox" v-if="selectedBss.display_progress_bar"
                                 v-model="selectedBss.display_section_titles"> Display progress bar section titles</base-checkbox>
                </div>
              </div>
              <div class="row mt-2" v-if="selectedBss.show_unavailable_slots">
                <div class="col-6">
                  <base-input v-model="selectedBss.booked_text" label="Custom text for sold out slots"></base-input>
                </div>
              </div>
              <div class="row" v-if="selectedBss.show_unavailable_slots">
                <div class="col-6">
                  <base-input v-model="selectedBss.blocked_text" label="Custom text for blocked slots"></base-input>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-6">
                  <base-input v-model="selectedBss.call_to_book_text" label="Custom text for slots marked as Call To Book"></base-input>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-lg-8">
                  <label>
                    <div>Insufficient resource error message
                      <el-popover trigger="hover"
                                  placement="right">

                        <div>
                          <div class="popover-body">This message will appear on the booking site <br>
                            when the customer attempts to make a booking that <br>
                            you do not have resources to cover</div>
                        </div>
                        <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                      </el-popover>
                    </div>
                  </label>
                  <base-input>
                  <textarea
                    class="mt-1 form-control"
                    placeholder="Start typing..."
                    name="notes"
                    v-model="selectedBss.insufficient_resource_message"
                    rows="2"></textarea>
                  </base-input>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <base-checkbox type="checkbox"
                                 v-model="selectedBss.show_total_pricing_on_events"> Show event pricing with taxes and fees included
                  </base-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <base-checkbox type="checkbox"
                                 v-model="selectedBss.show_promo_code_field"> Show promo code field
                  </base-checkbox>
                </div>
              </div>
              <div class="row mt-2" v-if="selectedBss.show_promo_code_field">
                <div class="col-lg-6">
                  <base-input label="Promo code field placeholder text" v-model="selectedBss.promo_code_placeholder_text">
                  </base-input>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <base-checkbox type="checkbox"
                                 v-model="selectedBss.clear_stale_carts"> "In Cart" expiration
                  </base-checkbox>
                </div>
              </div>
              <div class="row mt-2" v-if="selectedBss.clear_stale_carts">
                <div class="col">
                  <label>Cart timeout</label>
                  <div class="row">
                    <div class="col-lg-2">
                      <base-input v-model="selectedBss.cart_timeout_value"
                                  type="number">
                      </base-input>
                    </div>
                    <div class="col-lg-3">

                      <base-input>
                        <el-select
                          v-model="selectedBss.cart_timeout_period"
                          class="select-primary"
                          value-key="id"
                          v-on:change="t++"
                        >
                          <el-option
                            v-for="(tp, index) in timeout_periods"
                            class="select-primary"
                            :label="tp.label"
                            :value="{id: tp.id, label: tp.label}"
                            :key="'L' + index"
                          ></el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                </div>
              </div>


              <div class="row">
                <div class="col">
                  <base-checkbox type="checkbox"
                                 v-model="selectedBss.unblock_incomplete_bookings"> Unblock bookings with incomplete payments
                  </base-checkbox>
                </div>
              </div>
              <div class="row mt-2" v-if="selectedBss.unblock_incomplete_bookings">
                <div class="col">
                  <label>Incomplete booking timeout</label>
                  <div class="row">
                    <div class="col-lg-2">
                      <base-input v-model="selectedBss.timeout_value"
                                  type="number">
                      </base-input>
                    </div>
                    <div class="col-lg-3">

                      <base-input>
                        <el-select
                          label="Select game"
                          v-model="selectedBss.timeout_period"
                          class="select-primary"
                          value-key="id"
                          v-on:change="t++"
                        >
                          <el-option
                            v-for="(tp, index) in timeout_periods"
                            class="select-primary"
                            :label="tp.label"
                            :value="{id: tp.id, label: tp.label}"
                            :key="'M' + index"
                          ></el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col">
                  <base-button type="primary" v-on:click="updateBss()">Save changes</base-button>
                </div>
              </div>
            </div>

            <!--        Appearance-->
            <div v-if="activeTabIndex === 1">

              <p class="card-title text-uppercase">General</p>
              <div class="row">
                <div class="col-4">
                  <base-input label="Font" class="text-danger">
                    <el-select
                      v-model="selectedBss.font_family"
                      class="select-primary"
                      :style="{'font-family': selectedBss.font_family}"
                      name="font"
                      placeholder="Font"
                    >
                      <el-option
                        v-for="(f, i) in orderedFonts" :key="'B' + i"
                        :style="{'font-family': f.family}"
                        class="select-primary"
                        :label="f.family"
                        :value="f.family"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-2">
                  <base-input label="Text color">
                    <el-color-picker v-model="selectedBss.text_color" show-alpha></el-color-picker>
                  </base-input>
                </div>
                <div class="col">
                  <base-input label="Background color">
                    <el-color-picker v-model="selectedBss.background_color" show-alpha></el-color-picker>
                  </base-input>
                </div>
              </div>
              <p class="card-title text-uppercase">Logo</p>
              <div class="row">
                <div class="col">
                  <base-checkbox v-model="selectedBss.show_logo">Show logo on booking site</base-checkbox>
                </div>
              </div>
              <div class="row mt-3" v-if="selectedBss.show_logo">
                <div class="col-lg-1">
                  <base-input v-model.number="selectedBss.logo_height" label="Logo height"></base-input>
                </div>
                <div class="col-lg-1">
                  <base-input v-model.number="selectedBss.logo_width" label="Logo width"></base-input>
                </div>
                <div class="col-lg-2">
                  <base-input label="Logo fit">
                    <el-select
                      v-model="selectedBss.logo_fit"
                      class="select-primary"
                    >
                      <el-option
                        v-for="option in fitOptions"
                        class="select-primary"
                        :label="option"
                        :value="option"
                        :key="option"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <div class="row" v-if="selectedBss.show_logo">
                <div class="col">
                  <div>
                    <img :height="selectedBss.logo_height" :width="selectedBss.logo_width" :style="{'object-fit': selectedBss.logo_fit}" :src="selectedBss.logo" alt="preview" />
                  </div>
                </div>
              </div>
              <div class="row mt-2" v-if="selectedBss.show_logo">
                <div class="col-md-12 col-lg-12 col-sm-12 col-12">
                  <span class="btn btn-primary btn-sm btn-simple btn-file">Upload
                      <input
                        accept="image/*"
                        @change="handlePreview"
                        type="file"
                        name="..."
                        class="valid"
                        :multiple="false"
                        aria-invalid="false"
                      />
                    </span>
                </div>
              </div>

              <p class="card-title text-uppercase mt-3">Cards</p>
              <div class="row">
                <div class="col-4">
                  <base-input label="Font" class="text-danger">
                    <el-select
                      v-model="selectedBss.font_family_card"
                      class="select-primary"
                      :style="{'font-family': selectedBss.font_family_card}"
                      name="font"
                      placeholder="Font"
                    >
                      <el-option
                        v-for="(f, i) in orderedFonts" :key="'C' + i"
                        :style="{'font-family': f.family}"
                        class="select-primary"
                        :label="f.family"
                        :value="f.family"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-2">
                  <base-input label="Text color">
                    <el-color-picker v-model="selectedBss.text_color_card" show-alpha></el-color-picker>
                  </base-input>
                </div>
                <div class="col-2">
                  <base-input label="Link color">
                    <el-color-picker v-model="selectedBss.link_color" show-alpha></el-color-picker>
                  </base-input>
                </div>
                <div class="col-2">
                  <base-input label="Button color">
                    <el-color-picker v-model="selectedBss.button_color" show-alpha></el-color-picker>
                  </base-input>
                </div>
                <div class="col">
                  <base-input label="Background color">
                    <el-color-picker v-model="selectedBss.card_color" show-alpha></el-color-picker>
                  </base-input>
                </div>
              </div>
              <div class="row">

                <div class="col-3">
                  <base-checkbox type="checkbox"
                                 v-model="selectedBss.show_calendar_link"> Include link to calendar view</base-checkbox>
                </div>
                <div class="col">
                  <base-checkbox type="checkbox"
                                 v-model="selectedBss.show_game_details_over_image"> Display informational fields on top of game image</base-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <base-checkbox type="checkbox"
                                 v-model="selectedBss.show_game_title"> Show game title</base-checkbox>
                </div>
                <div class="col-3">
                  <base-checkbox type="checkbox"
                                 v-model="selectedBss.show_number_of_players"> Show number of players</base-checkbox>
                </div>
                <div class="col-3">
                  <base-checkbox type="checkbox"
                                 v-model="selectedBss.show_game_difficulty"> Show game difficulty</base-checkbox>
                </div>
                <div class="col-3">
                  <base-checkbox type="checkbox"
                                 v-model="selectedBss.show_game_duration"> Show game duration</base-checkbox>
                </div>
              </div>




              <div class="row mt-3">
                <div class="col">
                  <base-button type="primary" v-on:click="updateBss()">Save changes</base-button>
                </div>
              </div>
            </div>

            <!--          Custom fields-->
            <div v-if="activeTabIndex === 2">

              <div class="row">
                <div class="col">
                  <base-button type="primary" v-on:click="addCustomField()">
                    <i class="tim-icons el-icon-plus mr-1"></i> Add custom field
                  </base-button>
                </div>
              </div>

              <div v-if="custom_fields.length <= 0" class="mt-3" >
                <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
                  <h4 class="mt-3">No custom fields have been created.</h4>
                </div>
              </div>

              <div class="row" v-if="custom_fields.length > 0">
                <div class="col">
                  <table class='table' v-if="games.length > 0">
                    <thead>
                    <tr>
                      <th scope='col'></th>
                      <th scope='col'>Field Preview</th>
                      <th scope='col'>Type</th>
                      <th scope='col'>Required</th>
                      <th scope='col'>Creates alert</th>
                      <th scope='col'>Conditions</th>
                      <th scope='col'>Status</th>
                      <th scope='col'>Actions</th>
                    </tr>
                    </thead>
                    <tbody tag='tbody' v-model='custom_fields' is='draggable'>
                    <tr v-on:dragend="updateCustomFieldOrder()" v-for='(item, index) in custom_fields' :key="'G' + index">
                      <td scope='row'>
                        <i style="cursor: move" class="tim-icons el-icon-rank"></i>
                      </td>
                      <td scope='row' style="max-width: 400px">
                        <div class="row" >
                          <div class="col" v-if="item.type.id === 1">
                            <input type="checkbox" disabled>
                            {{item.label}}
                            <span v-for="(el, i) in item.external_links" :key="'ELI' + i">
                              <a target="_blank" :href="el.link">{{el.link_text}}</a>
                            </span>
                            <span v-for="(p, i) in item.policies" :key="'PLI' + i">
                              <base-button style="color: #ba54f5; font-weight:300; font-size: small; padding: 0"
                                           size="sm" link class="btn btn-link" href="" v-on:click="openPolicy(p)">{{p.policy_link_text}}</base-button>
                            </span>
                            <modal :show.sync="modals.policy"
                                   body-classes="p-0"
                                   modal-classes="modal-dialog-centered">
                              <card type="secondary"
                                    header-classes="bg-white pb-5"
                                    body-classes="px-lg-4 py-lg-4s"
                                    class="border-0 mb-0">

                                <p class="text-white" v-html="selectedPolicy"></p>
                                <div class="row mt-3 justify-content-center">
                                  <div class="col text-center">
                                    <base-button v-on:click="modals.policy = false" type="primary">I agree</base-button>
                                  </div>
                                </div>
                              </card>
                            </modal>
                          </div>
                          <div class="col" v-if="item.type.id === 2">
                            <base-input :label="item.label">
                            </base-input>
                          </div>
                          <div class="col" v-if="item.type.id === 3">
                            <div class="row">
                              <div class="col">
                                <base-input :label="item.label">
                                  <el-select
                                    v-model="item.selectedOption"
                                    class="select-primary"
                                    v-on:change="dropdown_key++" style="word-break: break-word"
                                    value-key="id">
                                    <el-option
                                      v-for="(c, i) in item.dropdown_options"
                                      class="select-primary"
                                      :label="c.name"
                                      :value="{name: c.name, id: c.id,
                          follow_up_field_label: c.follow_up_field_label,
                          has_follow_up_field: c.has_follow_up_field}"
                                      :key="'DO' + i"
                                    >
                                    </el-option>
                                  </el-select>
                                </base-input>
                              </div>

                              <div class="col" :key="dropdown_key" v-if="item.selectedOption">
                                <base-input :label="item.selectedOption.follow_up_field_label" v-if="item.selectedOption && item.selectedOption.has_follow_up_field"></base-input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td scope='row'>
                        <span>{{item.type.type}}</span>
                      </td>
                      <td scope='row'>
                        <i class="tim-icons icon-check-2 text-success" v-if="item.required"></i>
                        <i class="tim-icons icon-simple-remove text-danger" v-if="!item.required"></i>
                      </td>
                      <td scope='row'>
                        <i class="tim-icons icon-check-2 text-success" v-if="item.add_alert"></i>
                        <i class="tim-icons icon-simple-remove text-danger" v-if="!item.add_alert"></i>
                      </td>
                      <td scope='row'>
                        <span v-if="item.visibility === 0">Always show</span>
                        <span v-if="item.visibility === 1">Show only when a specific item is in cart</span>
                      </td>
                      <td scope='row'>
                        <badge v-if="item.active" type="success">active</badge>
                        <badge v-if="!item.active" type="danger">deactivated</badge>
                      </td>
                      <td scope='row'>
                        <div class="row">
                          <el-dropdown>
                            <base-button
                              simple
                              type="primary"
                              link
                            > <i style="font-size: medium" class="tim-icons el-icon-more"></i>
                            </base-button>
                            <template #dropdown>
                              <el-dropdown-menu>
                                <a class="dropdown-item mb-2" href="#" v-if="item.active"
                                   @click="editCustomField('deactivate', item)">
                                  <span class="active-dot mt-2 mr-2"></span> Deactivate field
                                </a>
                                <a class="dropdown-item mb-2" href="#" v-if="!item.active"
                                   @click="editCustomField('activate', item)">
                                  <span class="inactive-dot mt-2 mr-2"></span> Activate field
                                </a>
                                <a class="dropdown-item mb-2" @click="editCustomFieldModal(item)" href="#">
                                  <i class="tim-icons icon-pencil text-warning mr-2"></i> Edit field
                                </a>
                                <hr>
                                <a class="dropdown-item mb-2" @click="deleteCustomFieldPrompt(item)" href="#">
                                  <i class="tim-icons icon-trash-simple text-danger mr-2"></i> Delete field
                                </a>
                              </el-dropdown-menu>
                            </template>
                          </el-dropdown>
                        </div>
                      </td>

                    </tr>
                    </tbody>
                  </table>

                  <!--                <b-table striped hover :items="custom_fields"-->
                  <!--                         :fields="[-->
                  <!--                           {key: 'order', tdClass: 'align-top'},-->
                  <!--                           {key: 'preview', tdClass: 'align-top'},-->
                  <!--                           {key: 'type', tdClass: 'align-top'},-->
                  <!--                         {key: 'required', tdClass: 'align-top'},-->
                  <!--                           {key: 'status', tdClass: 'align-top'},-->
                  <!--                         {key: 'appears', tdClass: 'align-top'},-->
                  <!--                         {key: 'actions', tdClass: 'align-top'},-->
                  <!--                         ]">-->
                  <!--                  <template v-slot:cell(type)="row">-->
                  <!--                    <div >-->

                  <!--                      <span v-else>{{row.item.type.type}}</span>-->
                  <!--                    </div>-->
                  <!--                  </template>-->
                  <!--                  <template v-slot:cell(required)="row">-->
                  <!--                    <span v-if="!row.item.editing && row.item.required">Yes</span>-->
                  <!--                    <span v-if="!row.item.editing && !row.item.required">No</span>-->
                  <!--                  </template>-->
                  <!--&lt;!&ndash;                  <template v-slot:cell(appears)="row">&ndash;&gt;-->
                  <!--&lt;!&ndash;                    When Demo game is in cart&ndash;&gt;-->
                  <!--&lt;!&ndash;&lt;!&ndash;                    <base-checkbox v-if="row.item.editing" v-model="row.item.required"></base-checkbox>&ndash;&gt;&ndash;&gt;-->
                  <!--&lt;!&ndash;&lt;!&ndash;                    <span v-if="!row.item.editing && row.item.required">Yes</span>&ndash;&gt;&ndash;&gt;-->
                  <!--&lt;!&ndash;&lt;!&ndash;                    <span v-if="!row.item.editing && !row.item.required">No</span>&ndash;&gt;&ndash;&gt;-->
                  <!--&lt;!&ndash;                  </template>&ndash;&gt;-->
                  <!--                  <template v-slot:cell(label)="row">-->


                  <!--                    <span v-else>{{row.item.label}}</span>-->
                  <!--&lt;!&ndash;                    <base-input>&ndash;&gt;-->
                  <!--&lt;!&ndash;                      <input type="checkbox"></input>Add link&ndash;&gt;-->
                  <!--&lt;!&ndash;                    </base-input>&ndash;&gt;-->

                  <!--&lt;!&ndash;                    <base-checkbox v-model="row.item.link" v-if="row.item.type.id === 1"></base-checkbox>&ndash;&gt;-->
                  <!--&lt;!&ndash;                    <base-checkbox v-model="row.item.link"  v-if="row.item.type.id === 2">Add link</base-checkbox>&ndash;&gt;-->
                  <!--                  </template>-->
                  <!--                  <template v-slot:cell(actions)="row">-->
                  <!--                    <el-dropdown v-if="!row.item.editing">-->
                  <!--                      <base-button-->
                  <!--                        simple-->
                  <!--                        type="primary"-->
                  <!--                        link-->
                  <!--                      > <i style="font-size: medium" class="tim-icons el-icon-more"></i>-->
                  <!--                      </base-button>-->
                  <!--                      <template #dropdown>-->
                  <!--                        <el-dropdown-menu>-->
                  <!--                          <a class="dropdown-item mb-2" href="#" v-if="row.item.transaction_id" @click="viewBookingDetails(row.item)">-->
                  <!--                            <i class="tim-icons text-info icon-notes mr-2"></i> View transaction details-->
                  <!--                          </a>-->
                  <!--                          <a class="dropdown-item mb-2" href="#" v-if="row.item.status == 'available' || row.item.status == 'checked' || row.item.status == 'call_to_book'"-->
                  <!--                             @click="addToCart(row.item)">-->
                  <!--                            <i style="color: #42b883" class="tim-icons icon-cart mr-2"></i> Add to cart-->
                  <!--                          </a>-->
                  <!--                          <a class="dropdown-item mb-2" href="#" v-if="row.item.active"-->
                  <!--                             @click="editCustomField('deactivate', row.item)">-->
                  <!--                            <span class="active-dot mt-2 mr-2"></span> Deactivate field-->
                  <!--                          </a>-->
                  <!--                          <a class="dropdown-item mb-2" href="#" v-if="!row.item.active"-->
                  <!--                             @click="editCustomField('activate', row.item)">-->
                  <!--                            <span class="inactive-dot mt-2 mr-2"></span> Activate field-->
                  <!--                          </a>-->
                  <!--                          <a class="dropdown-item mb-2" @click="row.item.editing = true" href="#">-->
                  <!--                            <i class="tim-icons icon-pencil text-warning mr-2"></i> Edit field-->
                  <!--                          </a>-->
                  <!--                          <hr>-->
                  <!--                          <a class="dropdown-item mb-2" @click="deleteCustomFieldPrompt(row.item)" href="#">-->
                  <!--                            <i class="tim-icons icon-trash-simple text-danger mr-2"></i> Delete field-->
                  <!--                          </a>-->
                  <!--                        </el-dropdown-menu>-->
                  <!--                      </template>-->
                  <!--                    </el-dropdown>-->
                  <!--                    <el-tooltip content="Cancel" v-if="row.item.editing"-->
                  <!--                                effect="light"-->
                  <!--                                :open-delay="300"-->
                  <!--                                placement="top">-->
                  <!--                      <base-button style="margin-right: 0px"-->
                  <!--                                   type="danger"-->
                  <!--                                   class="closed btn btn-link"-->
                  <!--                                   aria-label="Close"-->
                  <!--                                   icon-->
                  <!--                                   @click="row.item.editing = false"-->
                  <!--                      >-->
                  <!--                        <i class="tim-icons icon-simple-remove"></i>-->
                  <!--                      </base-button>-->
                  <!--                    </el-tooltip>-->
                  <!--                    <el-tooltip content="Save" v-if="row.item.editing"-->
                  <!--                                effect="light"-->
                  <!--                                :open-delay="300"-->
                  <!--                                placement="top">-->
                  <!--                      <base-button style="margin-right: 0px"-->
                  <!--                                   type="success"-->
                  <!--                                   class="closed btn btn-link"-->
                  <!--                                   aria-label="Close"-->
                  <!--                                   icon-->
                  <!--                                   @click="saveCustomField(row.item)"-->
                  <!--                      >-->
                  <!--                        <i class="tim-icons icon-check-2"></i>-->
                  <!--                      </base-button>-->
                  <!--                    </el-tooltip>-->
                  <!--                  </template>-->
                  <!--                </b-table>-->
                </div>
              </div>
            </div>

          </card>
        </div>
        <div class="col-lg-5 col-12">
          <card v-if="activeTabIndex === 0 ">
            <div class="row justify-content-between">
              <div class="col">
                <h4 class="card-title">Booking site content</h4>
              </div>
              <div class="col text-right">
                <badge style="margin-top: -5px" v-if="!selectedBss.is_active" type="danger">inactive</badge>
                <badge v-if="selectedBss.is_active" type="success">active</badge>
                <el-tooltip content="More actions"
                            effect="light"

                            :open-delay="300"
                            placement="top">
                  <el-dropdown>
                    <base-button
                      link
                      type="primary"
                      size="sm"
                    > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                    </base-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <a class="dropdown-item mt-1 mb-2" target="_blank" :href="'https://offthecouch.io/book/' + selectedBss.code">
                          <i class="tim-icons text-info icon-link-72 mr-2"></i> Go to booking site
                        </a>
                        <a class="dropdown-item mt-1 mb-2" target="_blank" v-on:click="copy('https://offthecouch.io/book/' + selectedBss.code)">
                          <i class="tim-icons text-default icon-single-copy-04 mr-2"></i> Copy booking site link
                        </a>
                        <hr>
                        <a class="dropdown-item mb-2" v-if="selectedBss.is_active  && games.length > 0 && payment_settings.length > 0" @click="activate(0)" href="#">
                          <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="inactive-dot mr-2"></span> Deactivate booking site
                        </a>
                        <a class="dropdown-item mb-2" v-if="!selectedBss.is_active && games.length > 0 && payment_settings.length > 0" @click="activate(1)" href="#">
                          <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="active-dot mr-2"></span> Activate booking site
                        </a>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </el-tooltip>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div v-if="games.length <= 0 || payment_settings.length <=0">
                  <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
                    <h4 v-if="games.length <= 0" class="mt-3">You must set up at least one game for this group before you can activate the booking site</h4>
                    <h4 v-if="payment_settings.length <= 0 && games.length > 0" class="mt-3">You must set up a payment gateway for this group before you can activate the booking site</h4>
                  </div>
                </div>

                <table class='table' v-if="games.length > 0">
                  <thead>
                  <tr>
                    <th scope='col'></th>
                    <th scope='col'>Game</th>
                    <th scope='col'>Actions</th>
                  </tr>
                  </thead>
                  <tbody tag='tbody' v-model='games' is='draggable'>
                  <tr v-on:dragend="updateSiteContent('update_order', item)"  v-for='(item, index) in games' :key="'G' + index">
                    <td scope='row'>
                      <i style="cursor: move" class="tim-icons el-icon-rank"></i>
                    </td>
                    <td scope='row'>
                      <div class="row" >
                        <div class="col">
                          <p class="text-white">{{item.name}}</p>
                          <badge v-if="item.is_active" type="success">displayed on main site</badge>
                          <badge v-if="!item.is_active" type="danger">not displayed on main site</badge>
                          <badge v-if="item.has_unique_link" class="ml-lg-2" type="default">has unique booking page</badge>
                        </div>
                      </div>
                    </td>
                    <td scope='row'>
                      <div class="row">
                        <el-dropdown>
                          <base-button
                            link
                            type="primary"
                            size="sm"
                          > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                          </base-button>
                          <template #dropdown>
                            <el-dropdown-menu>
                              <a class="dropdown-item mt-1 mb-2" target="_blank" :href="'https://offthecouch.io/book/' + selectedBss.code + '/' + item.game_id">
                                <i class="tim-icons text-info icon-link-72 mr-2"></i> Go to {{item.name}}'s booking site
                              </a>
                              <a class="dropdown-item mt-1 mb-2" target="_blank" v-on:click="copy('https://offthecouch.io/book/' + selectedBss.code  + '/' + item.game_id)">
                                <i class="tim-icons text-default icon-single-copy-04 mr-2"></i> Copy  {{item.name}}'s  booking site link
                              </a>
                              <hr>
                              <a class="dropdown-item mb-2" v-if="item.has_unique_link" @click="updateSiteContent('deactivate_unique_site', item)" href="#">
                                <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="inactive-dot mr-2"></span> Deactivate {{item.name}}'s booking site
                              </a>
                              <a class="dropdown-item mb-2" v-if="!item.has_unique_link" @click="updateSiteContent('activate_unique_site', item)" href="#">
                                <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="active-dot mr-2"></span> Activate {{item.name}}'s booking site
                              </a>
                              <a v-if="!item.is_active" class="dropdown-item mt-1 mb-2" target="_blank" @click="updateSiteContent('add_to_site', item)">
                                <i class="tim-icons text-success icon-simple-add mr-2"></i> Add to main booking site
                              </a>
                              <a v-if="item.is_active" class="dropdown-item mt-1 mb-2" target="_blank" @click="updateSiteContent('remove_from_site', item)">
                                <i class="tim-icons text-danger icon-simple-remove mr-2"></i> Remove from main booking site
                              </a>
                            </el-dropdown-menu>
                          </template>
                        </el-dropdown>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row justify-content-between">
              <div class="col">
                <el-switch active-color="#e14eca"
                           v-model="selectedBss.gift_cards_enabled"  />
                <label> &nbsp; Enable gift card purchases on main booking site</label>
              </div>
            </div>
            <div class="row justify-content-between">
              <div class="col">
                <el-switch active-color="#e14eca"
                           v-model="selectedBss.gift_card_unique_link_enabled"  />
                <label> &nbsp; Enable gift card purchases on
                  <a style="font-size: small" target="_blank" :href="'https://offthecouch.io/book/' + group.code + '/gift-card'">
                    https://offthecouch.io/book/{{group.code}}/gift-card
                  </a>
                </label>
              </div>
            </div>
          </card>
          <card v-if="activeTabIndex === 0">
            <h4 slot="header" class="card-title">Embed booking site</h4>
            <p>Copy the following HTML and embed it in your code to display bookings on your website.
             Click <a target="_blank" href="https://codepen.io/omnisqrl/pen/YPKgrwd">here</a> for live example.</p>
            <div class="mt-2">
              <base-alert type="white" class="text-muted">

                <div class="row">
                  <div class="col-10" style="padding-right: 0">
                    <code>{{ embedded_text }}</code>
                  </div>
                  <div class="col text-right">
                    <base-button class="like btn-link"
                                 type="default"
                                 size="sm" style="padding: 0px 0px 2px"

                                 v-on:click="copy(embedded_text)">
                      <i class="tim-icons icon-single-copy-04"></i>
                    </base-button>
                  </div>
                </div>
              </base-alert>
            </div>
          </card>

          <!--        <card v-if="activeTabIndex === 1">-->
          <!--          <h4 slot="header" class="card-title">Card layout</h4>-->
          <!--          <p>Copy the following HTML and embed it in your code to display bookings on your website.</p>-->
          <!--          <div class="mt-2">-->
          <!--            <base-alert type="white" class="text-muted">-->

          <!--              <div class="row">-->
          <!--                <div class="col-10" style="padding-right: 0">-->
          <!--                  <code>{{ embedded_text }}</code>-->
          <!--                </div>-->
          <!--                <div class="col text-right">-->
          <!--                  <base-button class="like btn-link"-->
          <!--                               type="default"-->
          <!--                               size="sm" style="padding: 0px 0px 2px"-->

          <!--                               v-on:click="copy(embedded_text)">-->
          <!--                    <i class="tim-icons icon-single-copy-04"></i>-->
          <!--                  </base-button>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </base-alert>-->
          <!--          </div>-->
          <!--        </card>-->
          <card v-if="activeTabIndex === 3 ">
            <div class="row">
              <div class="col">
                <h4 class="card-title">Card layout</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-10">
                <grid-layout :layout.sync="layout"
                             :style="layoutCss"
                             :col-num="3"
                             :max-rows="5"
                             :row-height="100"
                             :is-draggable="true"
                             :is-resizable="false"
                             :vertical-compact="false"
                             :use-css-transforms="true"
                >
                  <grid-item v-for="(item, index) in layout" :key="index + 'A'"
                             :style="{'background-color': 'inherit', 'border-radius': '3px', 'border': '1px solid white'}"
                             :x="item.x"
                             :is-bounded="true"
                             :y="item.y"
                             :w="item.w"
                             :h="item.h"
                             :i="item.i">
                    <!--                >-->
                    <!--                  <div class="text" v-if="item.name === 'Image'">-->
                    <!--                    <img src="https://console.offthecouch.io/img/image_placeholder.jpg" style="object-fit: cover; max-height: 300px">-->
                    <!--                  </div>-->
                    <div class="text">
                      <p  style="margin: auto">
                        {{item.name}}</p>
                    </div>
                  </grid-item>
                </grid-layout>
              </div>
            </div>
          </card>
        </div>
      </div>
      <!--Custom field-->
      <modal :show.sync="modals.custom_field"
             body-classes="p-0"
             modal-classes="modal-dialog-centered modal-lg">
        <card type="secondary"
              header-classes="bg-white pb-5"
              body-classes="px-lg-4 py-lg-4s"
              class="border-0 mb-0">
          <h4 v-if="selectedField.hasOwnProperty('id')" class="card-title">Edit custom field</h4>
          <h4 v-else class="card-title">Add custom field</h4>


          <div class="row">
            <div class="col-lg-6">
              <base-input label="Field type">
                <el-select
                  v-model="selectedField.type"
                  class="select-primary"
                  value-key="id"
                  v-on:change="adjustCustomFieldType(selectedField)"
                  placeholder="Type">
                  <el-option
                    v-for="(c, i) in custom_field_types"
                    class="select-primary"
                    :label="c.type"
                    :value="{type: c.type, id: c.id}"
                    :key="'C' + i"
                  ></el-option>
                </el-select>
              </base-input>
            </div>
          </div>

          <div>
            <base-input label="Label" v-model="selectedField.label"></base-input>
            <div class="row mt-2"  v-if="selectedField.type.id === 1">
              <div class="col">
                <base-button type="primary" v-on:click="addExternalLink(selectedField)" size="sm">
                  <i class="tim-icons el-icon-plus"></i>Add an external link
                </base-button>
                <base-button class="ml-2" type="primary" v-on:click="addPolicy(selectedField)" size="sm">
                  <i class="tim-icons el-icon-plus"></i>Add a policy pop-up
                </base-button>
              </div>
            </div>
            <div class="row mt-2"  v-if="selectedField.type.id === 1" v-for="(e, i) in selectedField.external_links" :key="'EL' + i">
              <div class="col">
                <base-input :label="'External link text ' + (i+1)" v-model="e.link_text"></base-input>
              </div>
              <div class="col">
                <base-input label="Link URL" v-model="e.link"></base-input>
              </div>
              <div class="col-lg-1">
                <base-button style="margin-top: 25px; margin-left: -20px" type="danger" icon link
                             v-on:click="removeOption(selectedField.external_links, i)" size="sm">
                  <i class="tim-icons icon-simple-remove"></i>
                </base-button>
              </div>
            </div>
            <div class="row mt-2"  v-if="selectedField.type.id === 1" v-for="(p, i) in selectedField.policies" :key="'PO' + i">
              <div class="col">
                <base-input :label="'Policy link text ' + (i+1)" v-model="p.policy_link_text"></base-input>
              </div>
              <div class="col-lg-1">
                <base-button style="margin-top: 25px; margin-left: -20px" type="danger" icon link
                             v-on:click="removeOption(selectedField.policies, i)" size="sm">
                  <i class="tim-icons icon-simple-remove"></i>
                </base-button>
              </div>
              <div class="col-lg-12">
                <label>Policy content</label>
                <editor v-model="p.policy"
                        api-key="qs89hwib76rceahcpau0cxv1bxz229nbfkfwoxfgo7kd96wz"
                        :init="{
                                   height: 200,
                                   menubar: false,
                                   plugins: [
                                     'advlist autolink lists link image charmap print preview anchor',
                                     'searchreplace visualblocks code fullscreen',
                                     'insertdatetime media table paste code help wordcount'
                                   ],
                                   force_br_newlines : true,
                                  force_p_newlines : false,
                                  forced_root_block : '',
                                  branding: false,
                                  statusbar: false,
                                   toolbar:
                                     'formatselect | bold italic forecolor backcolor | \
                                     alignleft aligncenter alignright alignjustify | \
                                     bullist numlist outdent indent | image | link '
                                 }"
                />
              </div>
            </div>
            <div class="row mt-2"  v-if="selectedField.type.id === 3">
              <div class="col">
                <base-button type="primary" v-on:click="addDropDownOption(selectedField)" size="sm">
                  <i class="tim-icons el-icon-plus"></i>Add a dropdown option
                </base-button>
              </div>
            </div>
            <div class="row mt-2"  v-if="selectedField.type.id === 3" v-for="(d, i) in selectedField.dropdown_options" :key="'O' + i">
              <div class="col">
                <base-input :label="'Option ' + (i + 1)" v-model="d.name"></base-input>
              </div>
              <div class="col-lg-2">
                <el-tooltip content="Remove option"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <base-button style="margin-top: 25px; margin-left: -20px; margin-right: -20px" type="danger" icon link
                               v-on:click="removeOption(selectedField.dropdown_options, i)" size="sm">
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </el-tooltip>
                <el-tooltip content="Add follow up field"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <base-button style="margin-top: 25px; margin-left: 10px; margin-right: -30px" type="success" icon link v-if="!d.has_follow_up_field"
                               v-on:click="d.has_follow_up_field = 1" size="sm">
                    <i class="tim-icons icon-simple-add"></i>
                  </base-button>
                </el-tooltip>
              </div>
              <div class="col" v-if="d.has_follow_up_field">
                <base-input label="Follow up field label" v-model="d.follow_up_field_label"></base-input>
              </div>
              <div class="col-lg-1" v-if="d.has_follow_up_field">
                <el-tooltip content="Remove option"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <base-button style="margin-top: 25px; margin-left: -20px; margin-right: -20px" type="danger" icon link
                               v-on:click="d.has_follow_up_field = false" size="sm">
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </el-tooltip>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <base-input label="Visibility options">
                <el-select
                  v-model="selectedField.visibility"
                  class="select-primary"
                  value-key="id"
                  placeholder="Options">
                  <el-option
                    v-for="(v, i) in custom_fields_visibility_options"
                    class="select-primary"
                    :label="v.label"
                    :value="{label: v.label, id: v.id}"
                    :key="'V' + i"
                  ></el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-lg-6" v-show="selectedField.visibility.id === 1">
              <base-input label="Items in cart">
                <el-select multiple
                           v-model="selectedField.visibility_conditions"
                           class="select-primary"
                           value-key="id"
                           placeholder="Item">
                  <el-option
                    v-for="(ic, i) in items_in_cart_options"
                    class="select-primary"
                    :label="ic.name"
                    :value="{name: ic.name, id: ic.id}"
                    :key="'IC' + i"
                  ></el-option>
                </el-select>
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <base-checkbox v-if="!selectedField.hasOwnProperty('id')" v-model="selectedField.active"> Activate on booking site</base-checkbox>
              <base-checkbox  v-model="selectedField.required"> Make field required</base-checkbox>
              <base-checkbox v-if="selectedField.type.id === 1" v-model="selectedField.interaction_required">Requires user interaction</base-checkbox>
              <base-checkbox  v-model="selectedField.add_alert"> Create an alert on booking calendar</base-checkbox>
            </div>
          </div>

          <!--        <p class="text-white" v-html="selectedPolicy"></p>-->
          <div class="row mt-3 justify-content-center">
            <div class="col text-center">
              <base-button v-on:click="modals.custom_field = false" type="default">Cancel</base-button>
              <base-button class="ml-2" v-on:click="saveCustomField(selectedField)" type="primary">Save</base-button>
            </div>
          </div>
        </card>
      </modal>
    </div>
  </div>
</template>

<script>
import {API_LOCATION, BOOKING_URL} from "@/api-config";
import axios from "axios";
import swal from "sweetalert2";
import {Auth} from "aws-amplify";
import {ColorPicker, Select, Option, Tooltip, Dropdown, DropdownItem, DropdownMenu, Switch} from 'element-ui';
import BaseSwitch from "@/components/BaseSwitch";
import {BTable} from "bootstrap-vue";
import _ from "lodash";
import currencies from "@/assets/currencies.json";
import {Badge, BaseAlert} from 'src/components';
import draggable from 'vuedraggable'
import VueGridLayout from 'vue-grid-layout';
import Editor from '@tinymce/tinymce-vue'

export default {
  name: "BookingSiteTemplate",
  components: {
    BaseSwitch,
    [ColorPicker.name]: ColorPicker,
    [Tooltip.name]: Tooltip,
    [Select.name]: Select,
    [Option.name]: Option,
    BTable,
    Badge,
    BaseAlert,
    draggable,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    Editor,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Switch.name]: Switch,
  },
  data() {
    return {
      group: JSON.parse(localStorage.getItem('group')),
      t: 0,
      j: 0,
      timeout_periods: [{id: 0, label: 'minute(s)'}, {id: 1, label: 'hour(s)'}, {id: 2, label: 'day(s)'}],
      items_in_cart_options: [{id: 0, name: 'Gift card'}],
      custom_fields_visibility_options: [
        {id: 0, label: 'Always show'},
        {id: 1, label: 'Show only when a specific item is in cart'},
      ],
      selectedField: {
        type: {id: 1, type: 'Checkbox'},
        label: '',
        active: false,
        dropdown_options: [],
        external_links: [],
        policies: [],
        required: 0,
        add_alert: 0,
        visibility: {id: 0, label: 'Always show'},
        visibility_conditions: [],
        interaction_required: 0
      },
      customColWidth: 'col-lg-7 col-12',
      dropdown_key: 0,
      selectedPolicy: '',
      modals: {
        policy: false,
        custom_field: false,
      },
      dragging: false,
      custom_field_types: [
        {
          id: 1,
          type: 'Checkbox'
        },
        {
          id: 2,
          type: 'Input field'
        },
        {
          id: 3,
          type: 'Dropdown'
        }
      ],
      feature_not_enabled: false,
      custom_fields: [],
      loading: false,
      payment_settings: [],
      embedded_text: 'iframe',
      currencies: currencies.currencies,
      number_format: JSON.parse(localStorage.getItem('group')).number_format,
      currency_symbol: '$',
      fitOptions: ['cover', 'fill', 'contain', 'scale-down', 'none'],
      game_fields: ['game', 'displayed_on_booking_site'],
      selectedBss: '',
      file: '',
      games: [],
      fonts: '',
      errs: {
        redirect_url: '',
        redirect_button_text: ''
      },
      activeTabIndex: 0,
      layout: [],
      layoutCss: {'background-color': 'blue', 'border-radius': '3px',
        'border': '1px solid white',
        'height': '450px'},
    }
  },
  computed: {
    orderedFonts: function () {
      _.remove(this.fonts, {
        family: 'Libre Baskerville'
      });
      _.remove(this.fonts, {
        family: 'Material Icons'
      });
      _.remove(this.fonts, {
        family: 'Material Icons Outlined'
      });
      _.remove(this.fonts, {
        family: 'Material Icons Sharp'
      });
      _.remove(this.fonts, {
        family: 'Material Icons Round'
      });
      _.remove(this.fonts, {
        family: 'M PLUS Rounded 1c'
      });
      return _.orderBy(this.fonts, 'family')
    },
    categories() {
      return [{ name: 'General settings', icon: 'tim-icons icon-settings', active: true },
        { name: 'Appearance', icon: 'tim-icons icon-palette', active: false },
        { name: 'Custom fields', icon: 'tim-icons icon-bullet-list-67', active: false },
      ];
    }
  },
  methods: {
    goToBilling(){
      this.$router.push('/settings/billing')
    },
    hasPermissions(scope){
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      const matchFound = permissions.some(element => element === scope);
      return matchFound
    },
    async updateSiteContent(action, item){
      if(action === 'deactivate_unique_site'){
        item.has_unique_link = 0
      } else if(action === 'activate_unique_site'){
        item.has_unique_link = 1
      } else if(action === 'add_to_site'){
        item.is_active = 1
      } else if(action === 'remove_from_site'){
        item.is_active = 0
      }

      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/booking-site-settings/' + this.selectedBss.id + '/content'

      let data = {
        action: action,
        item: item,
        games: this.games
      }
      axios.put(url, data, config)
        .then((res) => {
          this.loading = false
          swal('Success', 'Site content has been updated', 'success')
          this.getBookingSiteSettings()
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    openPolicy(item){
      this.selectedPolicy = item.policy
      this.modals.policy = true
    },
    removeOption(array, index){
      array.splice(index, 1);
    },
    editCustomFieldModal(item){
      this.selectedField =  {
        id: item.id,
        type: item.type,
        label: item.label,
        active: item.active,
        dropdown_options: item.dropdown_options,
        external_links: item.external_links,
        policies: item.policies,
        required: item.required,
        add_alert: item.add_alert,
        interaction_required: item.interaction_required,
        visibility: _.find(this.custom_fields_visibility_options, {id: item.visibility}),
        visibility_conditions: item.visibility_conditions
      }
      this.modals.custom_field = true
    },
    async editCustomField(action, item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/booking-site-settings/' + this.selectedBss.id + '/custom-fields?id='+ item.id

      let data = {
        action: action
      }
      axios.put(url, data, config)
        .then((res) => {
          this.loading = false
          swal('Success', 'Custom field has been updated', 'success')
          this.getBookingSiteSettings()
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    deleteCustomFieldPrompt(item){
      swal({
        title: 'Are you sure?',
        text: `The custom field will be permanently deleted.`,
        type: 'warning',
        showCancelButton: true
      }).then((response)=> {
        if(response.value == true){
          this.deleteCustomField(item)
        }
      })
    },
    async deleteCustomField(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/booking-site-settings/' + this.selectedBss.id + '/custom-fields?id='+ item.id

      axios.delete(url, config)
        .then((res) => {
          this.loading = false
          swal('Success', 'Custom field has been removed', 'success')
          this.getBookingSiteSettings()
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async updateCustomFieldOrder(){
      this.dragging = false
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/booking-site-settings/' + this.selectedBss.id + '/custom-fields'

      let data = {
        custom_fields: this.custom_fields,
        action: 'reorder'
      }

      axios.put(url, data, config)
        .then((res) => {
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })

    },
    async saveCustomField(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/booking-site-settings/' + this.selectedBss.id + '/custom-fields'

      if(item.id){
        url = API_LOCATION + 'companies/' + companyId + '/booking-site-settings/' + this.selectedBss.id + '/custom-fields?id=' + item.id
      }

      let data = {
        type: item.type.id,
        label: item.label,
        external_links: item.external_links,
        policies: item.policies,
        dropdown_options: item.dropdown_options,
        position: this.custom_fields.length,
        required: item.required,
        visibility: item.visibility,
        visibility_conditions: item.visibility_conditions,
        active: item.active,
        add_alert: item.add_alert,
        interaction_required: item.interaction_required
      }

      if(item.id){
        data.action = 'update'
        axios.put(url, data, config)
          .then((res) => {
            this.loading = false
            this.modals.custom_field = false
            this.getBookingSiteSettings()
          })
          .catch((err) => {
            this.loading = false
            console.error(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.status == 500){
              swal({
                title: 'Error',
                html: 'The feature has not been enabled as part of your subscription plan.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      } else {
        axios.post(url, data, config)
          .then((res) => {
            this.loading = false
            this.modals.custom_field = false
            this.getBookingSiteSettings()
          })
          .catch((err) => {
            this.loading = false
            console.error(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.status == 500){
              swal({
                title: 'Error',
                html: 'The feature has not been enabled as part of your subscription plan.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      }
    },
    adjustCustomFieldType(item){

    },
    addPolicy(item){
      item.policies.push({'policy_link_text': '', 'policy': ''})
    },
    addExternalLink(item){
      item.external_links.push({'link': '', 'link_text': ''})
    },
    addDropDownOption(item){
      item.dropdown_options.push({'name': '', 'follow_up_field_label': '', 'has_follow_up_field': 0})
    },
    addCustomField(){
      this.selectedField = {
        type: {id: 1, type: 'Checkbox'},
        label: '',
        active: false,
        dropdown_options: [],
        external_links: [],
        policies: [],
        required: 0,
        add_alert: 0,
        interaction_required: 0,
        visibility: {id: 0, label: 'Always show'},
        visibility_conditions: []
      }
      this.modals.custom_field = true
    },
    getIframe(){
      this.embedded_text = `<div id="otcContainer"></div>
                        <script>
                        let f=document.createElement("iframe");f.src="https://offthecouch.io/book/`+ this.selectedBss.code + `";let scrollTop=0;f.width="100%",f.style.border="none",f.style.display="block",f.style.overflow="hidden",f.style.height="auto",window.addEventListener("message",e=>{"https://offthecouch.io"===e.origin&&"updateHeight"===e.data.action&&(f.style.height=e.data.height+"px"),"https://offthecouch.io"===e.origin&&"scrollToTop"===e.data.action&&(scrollTop=findPosY(document.getElementById("otcContainer")),window.scrollTo({top:scrollTop-30,behavior:"smooth"}))}),document.getElementById("otcContainer").appendChild(f);function findPosY(e){var t=0;if(e.offsetParent)for(;e.offsetParent;)t+=e.offsetTop,e=e.offsetParent;else e.y&&(t+=e.y);return t}
                        <\/script>`
    },
    copy(item) {
      navigator.clipboard.writeText(item);
    },
    switchTab(index){
      this.activeTabIndex = index
      for(let n in this.categories){
        this.categories[n].active = false
      }
      this.categories[index].active = true
      if(this.activeTabIndex !== 0){
        this.customColWidth = 'col-lg-12'
      } else {
        this.customColWidth = 'col-lg-7 col-12'
      }
      this.j++
    },
    getAmount(type, amount){
      if(type === 2){
        return amount + '%'
      } else {
        if(this.number_format === 1){
          return this.currency_symbol + amount.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',')
        }
        return this.currency_symbol + amount.toFixed(2)
      }
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    handlePreview (){
      let file = event.target.files[0];
      this.selectedBss.logo = URL.createObjectURL(file);
      this.file = file
      this.$emit('change', file);
    },
    async saveFile() {
      this.uploadUrl = await this.getSignedURL(this.file)
      let uploaded = await this.uploadFile(this.file)
    },
    async getSignedURL (file) {
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'upload'

      let payload = {
        filePath: file.name,
        contentType: file.type
      }

      return new Promise ( function(resolve) {
        axios.post(url, payload, config)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            swal({
              title: 'Error!',
              text: `You must upload a player photo before saving!`,
              type: 'error',
              confirmButtonColor: '#fd5d93',
              confirmButtonText: 'Try Again'
            })
            console.error(err)
          })
      })
    },
    uploadFile (file) {
      let url = this.uploadUrl
      let config = { headers: {
          'Content-Type': file.type
        }}

      let payload = file
      return new Promise ( function(resolve) {
        axios.put(url, payload, config)
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            console.error(err)
          })
      })
    },
    visitPortal(){
      let url = BOOKING_URL + this.selectedBss.code
      window.open(url, "_blank");
    },
    isValidUrl(url){
      try {
        let parsedURL = new URL(url);
        return parsedURL.protocol === "http:" || parsedURL.protocol === "https:";
      } catch (error) {
        return false;
      }
    },
    async updateBss(){
      let errors = 0
      if(!this.isValidUrl(this.selectedBss.redirect_url)){
        this.errs.redirect_url = 'Please enter a valid URL'
        errors++
      } else {
        this.errs.redirect_url = ''
      }

      if(this.selectedBss.auto_redirect == 0 && this.selectedBss.redirect_button_text == ''){
        this.errs.redirect_button_text = 'Button text cannot be empty'
        errors++
      } else {
        this.errs.redirect_button_text = ''
      }
      if(errors > 0){
        if(this.activeTabIndex !== 0){
          swal({
            title: 'Error!',
            text: `You have an error in your form. Please review and try again.`,
            type: 'error',
            confirmButtonClass: 'btn btn-success btn-fill',
            confirmButtonText: 'Ok',
            buttonsStyling: false
          })
        }
        return
      }

      if(this.file != ''){
        await this.saveFile()
        this.selectedBss.logo = this.uploadUrl.split("?")[0]
      }

      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/booking-site-settings/' + this.selectedBss.id

      let payload = {
        'bss': this.selectedBss,
        'games': this.games
      }
      this.loading = true
      axios.put(url, payload, config)
        .then((res) => {
          this.loading = false
          swal({
            title: 'Success!',
            text: `Booking site settings have been updated`,
            type: 'success'
          })

          this.getBookingSiteSettings()
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    },
    async activate(activate){
      if(activate){
        if(this.games.length <= 0){
          swal({
            title: 'Error!',
            text: `You cannot activate your booking site with no games set up for this group.`,
            type: 'error',
            confirmButtonColor: '#fd5d93',
            confirmButtonText: 'Confirm'
          })
          return
        }
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/booking-site-settings/' + this.selectedBss.id + '/activate'

      let payload = {
        'is_active': activate
      }

      this.selectedBss.is_active = activate

      // localStorage.setItem('selectedBss', JSON.stringify(this.selectedBss))

      axios.post(url, payload, config)
        .then((res) => {
          this.loading = false
          if(activate == 1){
            swal({
              title: 'Success',
              text: `Booking site has been activated`,
              type: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              confirmButtonText: 'Ok',
              buttonsStyling: false
            })
          } else {
            swal({
              title: 'Success',
              text: `Booking site has been deactivated`,
              type: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              confirmButtonText: 'Ok',
              buttonsStyling: false
            })
          }
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getBookingSiteSettings(){
      let settingsId = this.$route.query.id

      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/booking-site-settings/' + settingsId

      axios.get(url, config)
        .then((res) => {
          localStorage.removeItem('editing')
          this.selectedBss = res.data.booking_site_settings
          this.selectedBss.gift_cards_enabled = Boolean(this.selectedBss.gift_cards_enabled)
          this.selectedBss.gift_card_unique_link_enabled = Boolean(this.selectedBss.gift_card_unique_link_enabled)
          this.games =  _.orderBy(res.data.games, 'position')
          this.items_in_cart_options = [{id: 0, name: 'Gift card'}]
          for(let n in this.games){
            this.items_in_cart_options.push({id: this.games[n].game_id, name: this.games[n].name})
          }
          this.payment_settings = res.data.payment_settings
          this.custom_fields = []

          this.selectedBss.timeout_period = _.find(this.timeout_periods, {id: this.selectedBss.timeout_period})
          this.selectedBss.cart_timeout_period = _.find(this.timeout_periods, {id: this.selectedBss.cart_timeout_period})
          for(let n in res.data.custom_fields){
            this.custom_fields.push({
              type: _.find(this.custom_field_types, { id: res.data.custom_fields[n].type }),
              label: res.data.custom_fields[n].label,
              id: res.data.custom_fields[n].id,
              active: res.data.custom_fields[n].active,
              visibility: res.data.custom_fields[n].visibility,
              visibility_conditions: res.data.custom_fields[n].show_when_gift_card_in_cart ?
                JSON.parse(res.data.custom_fields[n].visibility_conditions).concat([{id: 0, name: 'Gift card'}]) : JSON.parse(res.data.custom_fields[n].visibility_conditions),
              show_when_gift_card_in_cart: res.data.custom_fields[n].show_when_gift_card_in_cart,
              position: res.data.custom_fields[n].position,
              dropdown_options: res.data.custom_fields[n].dropdown_options === null ? [] : JSON.parse(res.data.custom_fields[n].dropdown_options),
              external_links: res.data.custom_fields[n].external_links === null ? [] : JSON.parse(res.data.custom_fields[n].external_links),
              policies: res.data.custom_fields[n].policies === null ? [] : JSON.parse(res.data.custom_fields[n].policies),
              required: res.data.custom_fields[n].required,
              add_alert: res.data.custom_fields[n].add_alert,
              interaction_required: res.data.custom_fields[n].interaction_required
            })

            if(this.custom_fields[n].type.id === 3){
              this.custom_fields[n].dropdown_options = _.orderBy(this.custom_fields[n].dropdown_options, 'id')
            }
            if(this.custom_fields[n].type.id === 3){
              this.custom_fields[n].dropdown_options = _.orderBy(this.custom_fields[n].dropdown_options, 'id')
            }
          }

          this.setCardLayout()
          this.getIframe()
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            this.feature_not_enabled = true
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    getFonts(){
      let url = API_LOCATION + 'fonts'

      axios.get(url)
        .then(response => {
          this.fonts = response.data
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    setCardLayout(){
      this.layout = [
        {"x":0,"y":0,"w":3,"h":3,"i":"1", "name": "Image"},
        {"x":0,"y":3,"w":3,"h":2,"i":"2", "name": "Availability"},
      ]

      this.layoutCss = {'background-color': this.selectedBss.card_color, 'border-radius': '3px',
        'border': '1px solid white',
        'height': '560px'}
    }
  },
  mounted(){
    this.currency_symbol =  _.find(this.currencies, { code: JSON.parse(localStorage.getItem('group')).currency }).symbol
    this.getBookingSiteSettings()
    this.getFonts()

  }
}
</script>

<style scoped>

</style>
