<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>
    <div v-if="empty==false" class="row mt-3 overflow-scroll">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <div class="d-block d-sm-none">
            <div class="row justify-content-between">
              <div class="col">
                <h4 slot="header" class="card-title">Transactions</h4>
              </div>
              <div class="col text-right">
                <b-icon icon="filter" scale="1" class="text-white" v-on:click="show_filters = !show_filters"></b-icon>
                <b-icon icon="arrow-counterclockwise" scale="1" class="ml-2 text-white" v-on:click="resetAll()"></b-icon>
              </div>
            </div>
            <div class="row">
              <div class="col" v-if="hasPermissions('inventory_create') || hasPermissions('transactions_update')">
                <base-button size="sm" v-on:click="createTransaction()" type="primary" >
                  <i class="tim-icons icon-simple-add mr-2"></i> Create new transaction</base-button>
              </div>
            </div>
            <div class="row" v-show="show_filters">
              <div
                class="col d-flex justify-content-between"
              >
                <base-input>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by transaction id</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Id"
                            v-model="filters.transaction_id.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.transaction_id)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateTransactions('filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                      Transaction Id<span v-if="filters.transaction_id.actual">: #{{filters.transaction_id.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by transaction date</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <div>
                            <base-radio v-model="selectedDateRangeOption" name="radio0" class="mb-3">
                              Within the last
                            </base-radio>
                          </div>
                          <div class="text-dark d-inline col-lg-3 col-md-3 col-sm-6 col-8">
                            <input v-model="filters.transaction_day.value" class="border-light text-dark form-control" type="number"/>
                          </div>
                          <div class="mt-2">
                            days
                          </div>
                        </div>

                        <div class="row container">
                          <div>
                            <base-radio v-model="selectedDateRangeOption" name="radio1" class="mb-3">
                              From
                            </base-radio>
                          </div>
                          <div class="text-dark d-inline col-lg-5 col-md-4 col-sm-8 col-12">
                            <el-date-picker
                              type="date"
                              placeholder="date"
                              value-format="yyyy-MM-dd"
                              :format="day_of_week + date_format_cal"
                              v-model="filters.transaction_range.value_from"
                            >
                            </el-date-picker>
                          </div>
                          <div class="mt-2">
                            to
                          </div>
                          <div class="text-dark d-inline col-lg-5 col-md-4 col-sm-8 col-12">
                            <el-date-picker
                              type="date"
                              placeholder="date"
                              value-format="yyyy-MM-dd"
                              :format="day_of_week + date_format_cal"
                              v-model="filters.transaction_range.value_to"
                            >
                            </el-date-picker>
                          </div>
                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.transaction_day, filters.transaction_day)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateTransactions('filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                      Transaction Date
                      <span v-if="filters.transaction_day.actual">: within {{filters.transaction_day.actual}} days</span>
                      <span v-if="filters.transaction_range.actual_from">:
                          between {{filters.transaction_range.actual_from}} and {{filters.transaction_range.actual_to}}
                        </span>
                      &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by customer first name</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    First name"
                            v-model="filters.firstName.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.firstName)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateTransactions('filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                      First Name <span v-if="filters.firstName.actual">: {{filters.firstName.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by customer last name</h3>
                      <div class="pop popover-body">


                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Last name"
                            v-model="filters.lastName.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.lastName)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateTransactions('filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                      Last Name <span v-if="filters.lastName.actual">: {{filters.lastName.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by customer email</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Email"
                            v-model="filters.email.value"
                          >
                          </el-input>
                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.email)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateTransactions('filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                      Email <span v-if="filters.email.actual">: {{filters.email.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by transaction payment status</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <div class="col-12">
                            <base-radio v-model="filters.payment.value" name="due" class="mb-3">
                              Due
                            </base-radio>
                          </div>
                          <div class="col-12">
                            <base-radio v-model="filters.payment.value" name="paid" class="mb-3">
                              Fully paid
                            </base-radio>
                          </div>


                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.payment)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateTransactions('filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                      Payment <span v-if="filters.payment.actual">: {{filters.payment.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by transaction status</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <base-radio v-model="filters.status.value" name="active" class="mb-3">
                            Active
                          </base-radio>
                          <base-radio v-model="filters.status.value" name="cancelled" class="mb-3">
                            Cancelled
                          </base-radio>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.status)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateTransactions('filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                      Status <span v-if="filters.status.actual">: {{filters.status.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                </base-input>
              </div>
            </div>
          </div>


          <div class="d-none d-sm-block">
            <div class="row">
              <div class="col">
                <h4 slot="header" class="card-title">Transactions</h4>
              </div>
              <div class="col text-right" v-if="hasPermissions('inventory_create') || hasPermissions('transactions_update')">
                <base-button size="sm" v-on:click="createTransaction()" type="primary" >
                  <i class="tim-icons icon-simple-add mr-2"></i> Create new transaction</base-button>
              </div>
            </div>

            <div class="row mt-4">
              <div
                class="col"
              >

                <base-input>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by transaction id</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Id"
                            v-model="filters.transaction_id.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.transaction_id)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateTransactions('filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" type="primary">
                      Transaction Id<span v-if="filters.transaction_id.actual">: #{{filters.transaction_id.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by transaction date</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <div>
                            <base-radio v-model="selectedDateRangeOption" name="radio0" class="mb-3">
                              Within the last
                            </base-radio>
                          </div>
                          <div class="text-dark d-inline col-lg-3 col-md-3 col-sm-6 col-8">
                            <input v-model="filters.transaction_day.value" class="border-light text-dark form-control" type="number"/>
                          </div>
                          <div class="mt-2">
                            days
                          </div>
                        </div>

                        <div class="row container">
                          <div>
                            <base-radio v-model="selectedDateRangeOption" name="radio1" class="mb-3">
                              From
                            </base-radio>
                          </div>
                          <div class="text-dark d-inline col-lg-5 col-md-4 col-sm-8 col-12">
                            <el-date-picker
                              type="date"
                              placeholder="date"
                              value-format="yyyy-MM-dd"
                              :format="day_of_week + date_format_cal"
                              v-model="filters.transaction_range.value_from"
                            >
                            </el-date-picker>
                          </div>
                          <div class="mt-2">
                            to
                          </div>
                          <div class="text-dark d-inline col-lg-5 col-md-4 col-sm-8 col-12">
                            <el-date-picker
                              type="date"
                              placeholder="date"
                              value-format="yyyy-MM-dd"
                              :format="day_of_week + date_format_cal"
                              v-model="filters.transaction_range.value_to"
                            >
                            </el-date-picker>
                          </div>
                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.transaction_day, filters.transaction_day)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateTransactions('filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                      Transaction Date
                      <span v-if="filters.transaction_day.actual">: within {{filters.transaction_day.actual}} days</span>
                      <span v-if="filters.transaction_range.actual_from">:
                          between {{filters.transaction_range.actual_from}} and {{filters.transaction_range.actual_to}}
                        </span>
                      &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by customer first name</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    First name"
                            v-model="filters.firstName.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.firstName)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateTransactions('filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                      First Name <span v-if="filters.firstName.actual">: {{filters.firstName.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by customer last name</h3>
                      <div class="pop popover-body">


                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Last name"
                            v-model="filters.lastName.value"
                          >
                          </el-input>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.lastName)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateTransactions('filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                      Last Name <span v-if="filters.lastName.actual">: {{filters.lastName.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>
                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by customer email</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <el-input
                            type="search"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="    Email"
                            v-model="filters.email.value"
                          >
                          </el-input>
                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.email)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateTransactions('filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                      Email <span v-if="filters.email.actual">: {{filters.email.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by transaction payment status</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <div class="col-12">
                            <base-radio v-model="filters.payment.value" name="due" class="mb-3">
                              Due
                            </base-radio>
                          </div>
                          <div class="col-12">
                            <base-radio v-model="filters.payment.value" name="paid" class="mb-3">
                              Fully paid
                            </base-radio>
                          </div>


                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.payment)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateTransactions('filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                      Payment <span v-if="filters.payment.actual">: {{filters.payment.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-popover trigger="click"
                              placement="bottom">
                    <div>
                      <h3 class="popover-header">Filter by transaction status</h3>
                      <div class="pop popover-body">
                        <div class="row container">
                          <base-radio v-model="filters.status.value" name="active" class="mb-3">
                            Active
                          </base-radio>
                          <base-radio v-model="filters.status.value" name="cancelled" class="mb-3">
                            Cancelled
                          </base-radio>

                        </div>
                        <div class="mt-2">
                          <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.status)" type="primary">Reset</base-button>
                          <base-button size="sm" type="info" v-on:click="updateTransactions('filter')">Update</base-button>
                        </div>

                      </div>
                    </div>
                    <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                      Status <span v-if="filters.status.actual">: {{filters.status.actual}}</span> &nbsp;
                      <i class="tim-icons icon-minimal-down"></i>
                    </base-button>
                  </el-popover>

                  <el-tooltip content="Reset all filters"
                              effect="light"
                              :open-delay="300"
                              placement="top">
                    <base-button link class="ml-2" v-on:click="resetAll()"  style="font-size: large; color:white">
                      <i class="tim-icons icon-refresh-01"></i>
                    </base-button>
                  </el-tooltip>
                  <span class="text-white">Total&nbsp;results:&nbsp;{{totalRows}}</span>
                </base-input>
              </div>
              <div class="col-lg-2 text-right">
                <base-button v-if="hasPermissions('download_data')" icon class="btn-link text-white" v-on:click="downloadCSV()">
                  <i class="tim-icons icon-cloud-download-93"></i>
                </base-button>
                <el-select
                  class="select-primary mb-3 pagination-select"
                  v-model="perPage"
                  placeholder="Per page"
                  v-on:change="updateTransactions('filter')"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>


          <div class="mt-3" v-if="transactions.length <= 0">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No transactions exist.</h4>
            </div>
          </div>
          <div class="row d-block d-sm-none">
            <div class="col">
              <b-table v-if="transactions.length > 0" striped hover :items="transactions"
                       :fields="['transactions']">
                <template v-slot:head(transactions)="row">
                  <span>Total results: {{totalRows}}</span>
                </template>
                <template v-slot:cell(transactions)="row">
                  <div class="row justify-content-between">
                    <div class="col-3">
                      <small class="text-white">#{{row.item.order_number}}</small>
                    </div>
                    <div class="col text-right">
                      <small class="text-white" v-if="row.item.timestamp">
                        {{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}
                      </small>
                      <span v-else>-</span>
                    </div>
                  </div>
                  <div class="row justify-content-between">
                    <div class="col-8">
                      <p class="text-white">{{row.item.first_name}} {{row.item.last_name}}</p>
                      <small v-if="!row.item.email">-</small>
                      <small v-if="row.item.email" class="text-white">
                        <i class="tim-icons icon-email-85 mr-1"></i> {{row.item.email}}
                      </small>
                    </div>
                    <div class="col text-right">
                      <badge v-if="row.item.status == 1" type="success">active</badge>
                      <badge v-if="row.item.status == 0" type="danger">cancelled</badge>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col">
                      <small class="text-white">
                        Total: {{getAmount(row.item.total)}}
                      </small>
                    </div>
                    <div class="col text-center">
                      <small class="text-white">
                        Paid: {{getAmount(row.item.paid)}}
                      </small>
                    </div>
                    <div class="col text-right">
                      <small v-if="row.item.due === 0" class="text-white">Due: {{getAmount(row.item.due)}}</small>
                      <small v-if="row.item.due > 0 || row.item.due < 0" class="text-danger">Due: {{getAmount(row.item.due)}}</small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div
                        class="btn-group btn-group-toggle mt-1 mb-2"
                        :class="'float-left'"
                        data-toggle="buttons" style="width:100%"
                      >
                        <label class="btn btn-sm btn-primary btn-simple">
                          <base-button
                            @click.native="viewTransactionDetails(row.item)"
                            class="like btn-link"
                            type="primary"
                            size="sm"
                            icon
                          ><i class="tim-icons icon-notes"></i>
                          </base-button>
                        </label>
                        <label class="btn btn-sm btn-primary btn-simple" >
                          <base-button
                            @click.native="cancelTransactionPrompt(row.item, $event)"
                            class="like btn-link"
                            type="primary"
                            size="sm"
                            icon
                          ><i class="tim-icons icon-trash-simple"></i>
                          </base-button>
                        </label>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
          </div>

          <div class="row d-none d-sm-block">
            <div class="col">
              <b-table v-if="transactions.length > 0" striped hover :items="transactions"
                       :fields="fields">
                <template v-slot:cell(transaction_id)="row">
                  <p class="text-white">#{{row.item.order_number}}</p>
                </template>
                <template v-slot:cell(transaction_date)="row">
                  <p class="text-white">{{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}</p>
                </template>
                <template v-slot:cell(customer_name)="row">
                  <p class="text-white">{{row.item.first_name}} {{row.item.last_name}}</p>
                </template>
                <template v-slot:cell(total)="row">
                  <p class="text-white">{{getAmount(row.item.total)}}</p>
                </template>
                <template v-slot:cell(paid)="row">
                  <p class="text-white">{{getAmount(row.item.paid)}}</p>
                </template>
                <template v-slot:cell(due)="row">
                  <p v-if="row.item.due === 0" class="text-white">{{getAmount(row.item.due)}}</p>
                  <p v-if="row.item.due > 0 || row.item.due < 0" class="text-danger">{{getAmount(row.item.due)}}</p>
                </template>
                <template v-slot:cell(status)="row">
                  <badge v-if="row.item.status == 1" type="success">active</badge>
                  <badge v-if="row.item.status == 0" type="danger">cancelled</badge>
                </template>
                <template v-slot:cell(customer_email)="row">
                  <p v-if="!row.item.email">-</p>
                  <p v-if="row.item.email" class="text-white">
                    {{row.item.email}}
                  </p>
                </template>
                <template v-slot:cell(actions)="row">
                  <el-dropdown>
                    <base-button
                      simple
                      type="primary"
                      link
                    > <i style="font-size: medium" class="tim-icons el-icon-more"></i>
                    </base-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <a class="dropdown-item mt-1 mb-2" target="_blank" :href="'/purchases/transactions/details?id=' + row.item.id">
                          <i class="tim-icons text-info icon-notes mr-2"></i> View transaction details
                        </a>
                        <hr v-if="row.item.status === 1">
                        <a v-if="row.item.status === 1" class="dropdown-item mb-2" @click="cancelTransactionPrompt(row.item, $event)" href="#">
                          <i class="tim-icons icon-trash-simple text-danger mr-2"></i> Cancel transaction
                        </a>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </template>
              </b-table>
            </div>
          </div>

          <b-pagination v-on:input="updateTransactions()"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
          ></b-pagination>
        </card>
      </div>
    </div>

    <!--    Cancel booking Modal-->
    <modal :show.sync="modals.cancel_transaction"
           body-classes="p-0"
           modal-classes="modal-dialog-centered">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4 class="card-title">Cancel transaction</h4>
        <base-alert type="danger">
          <i class="tim-icons icon-alert-circle-exc"></i>
          Cancelling this transaction will open up availability for all bookings made in this transaction. Any amount paid will need to be manually refunded to the customer.
        </base-alert>

        <div class="row">
          <div class="col">
            <base-input label="Select status for the freed up time slots">
              <el-select
                label="Select status"
                v-model="selectedCancellationStatus"
                class="select-primary"
                value-key="id"
                placeholder="Status">
                <el-option
                  v-for="(status, index) in slot_statuses"
                  class="select-primary"
                  :label="status.label"
                  :value="{id: status.id, label: status.label, status: status.status}"
                  :key="'CTSTATUS' + index"
                ></el-option>
              </el-select>
            </base-input>
          </div>
        </div>
        <div class="row justify-content-center mt-3">
          <base-button type="secondary" @click="modals.cancel_transaction = false">Cancel</base-button>
          <base-button class="ml-3" type="primary" @click="cancelTransaction(selectedTransaction, selectedCancellationStatus.status)">Cancel transaction</base-button>
        </div>
      </card>
    </modal>

  </div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import swal from "sweetalert2";
import { BFormInput, BTable, BPagination, BIcon, BIconFilter, BIconArrowCounterclockwise } from 'bootstrap-vue'
import {time, formattedDate, dayOfWeek} from "@/plugins/dateFormatter";
import {Badge, BaseAlert} from '@/components';
import axios from "axios";
import currencies from "@/assets/currencies.json";
import _ from "lodash";
import {Dropdown, DropdownItem, DropdownMenu} from "element-ui";


export default {
  name: "Transactions",
  components: {
    BFormInput,
    BTable,
    Badge,
    BPagination,
    BIcon,
    BIconFilter,
    BIconArrowCounterclockwise,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    BaseAlert
  },
  data() {
    return {
      show_filters: false,
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      currencies: currencies.currencies,
      number_format: JSON.parse(localStorage.getItem('group')).number_format,
      currency_symbol: '$',
      currency: JSON.parse(localStorage.getItem('group')).currency,
      currency_format: JSON.parse(localStorage.getItem('group')).currency_format,
      perPageOptions: [5, 10, 25, 50],
      date_format_cal: '',
      day_of_week: '',
      empty: false,
      loading: false,
      transactions: [],
      selectedCancellationStatus: {id: 1},
      selectedTransaction: '',
      modals: {
        cancel_transaction: false
      },
      slot_statuses: [
        {id: 1, label: 'Available', status: 'available'},
        {id: 2, label: 'Blocked', status: 'blocked'},
        {id: 3, label: 'Call to book', status: 'call_to_book'},
      ],
      fields: ['transaction_id', 'transaction_date', 'customer_name', 'customer_email', 'total', 'paid', 'due', 'status', 'actions'],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      selectedDateRangeOption: '',
      filters: {
        firstName: {
          value: '',
          actual: ''
        },
        lastName: {
          value: '',
          actual: ''
        },
        email: {
          value: '',
          actual: ''
        },
        payment: {
          value: '',
          actual: ''
        },
        transaction_id: {
          value: '',
          actual: ''
        },
        status: {
          value: '',
          actual: ''
        },
        transaction_day: {
          value: '',
          actual: '',
        },
        transaction_range:{
          value_from: '',
          actual_from: '',
          value_to: '',
          actual_to: '',
        },
      },
      to: '',
      from: ''
    }
  },
  methods: {
    async createTransaction(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/transactions/initial'
      let data = {}


      this.axios.post(url, data, config)
        .then(response => {
          this.$router.push({ path: '/purchases/transactions/details', query: { id:  response.data }})
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    hasPermissions(scope){
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      const matchFound = permissions.some(element => element === scope);
      return matchFound
    },
    cancelTransactionPrompt(item, event) {
      event.preventDefault()
      this.modals.cancel_transaction = true
      this.selectedTransaction = item
      this.selectedCancellationStatus = {id: 1, label: 'Available', status: 'available'}
    },
    async cancelTransaction(item, new_status){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId
        + '/transactions/' + item.id + '?new_status=' + new_status
      let count = this.perPage
      let offset = parseInt((this.currentPage * this.perPage) - this.perPage)
      let from = this.filters.transaction_range.actual_from
      let to = this.filters.transaction_range.actual_to

      this.axios.delete(url, config)
        .then(response => {
          swal({
            title: 'Success',
            text: "Transaction has been cancelled",
            type: 'success',
            confirmButtonColor: '#1d8cf8',
            confirmButtonText: 'OK'
          })
          this.modals.cancel_transaction = false
          this.getTransactions(count, offset, this.filters.transaction_id.actual, this.filters.firstName.actual,
            this.filters.lastName.actual, this.filters.email.actual, to, from, this.filters.status.actual, this.filters.payment.actual)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    resetAll(){
      this.filters =  {
        firstName: {
          value: '',
          actual: ''
        },
        lastName: {
          value: '',
          actual: ''
        },
        email: {
          value: '',
          actual: ''
        },
        payment: {
          value: '',
          actual: ''
        },
        transaction_id: {
          value: '',
          actual: ''
        },
        status: {
          value: '',
          actual: ''
        },
        transaction_day: {
          value: '',
          actual: '',
        },
        transaction_range:{
          value_from: '',
          actual_from: '',
          value_to: '',
          actual_to: '',
        },
      }
      this.updateTransactions('filter')
    },
    getDayOfWeek(d){
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        return dayOfWeek(d) + ', '
      }
      return ''
    },
    getStatus(status){
      if(status === 1){
        return 'Active'
      } else {
        return 'Cancelled'
      }
    },
    escapeCSVValue(value) {
      if (typeof value === 'string') {
        // Escape double quotes by doubling them
        value = value.replace(/"/g, '""');

        // If the value contains a comma, wrap it in double quotes
        if (value.includes(',') || value.includes('\n')) {
          value = `"${value}"`;
        }
      }
      return value;
    },
    async downloadCSV(){
      //get the entire data set
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }

      this.loading = true
      let count = 5000
      const csvData = await this.getAllTransactions(config, count, this.filters.transaction_id.actual, this.filters.firstName.actual,
        this.filters.lastName.actual, this.filters.email.actual, this.to, this.from, this.filters.status.actual, this.filters.payment.actual)
      this.loading = false

      let csv = 'Transaction Id, Transaction Date, Customer Name, Customer Email, Total, Paid, Due, Status\n';
      csvData.forEach(row => {
        csv += [this.escapeCSVValue(row.order_number),
          this.getLocalDateNoDay(row.timestamp),
          this.escapeCSVValue(row.first_name + ' ' + row.last_name),
          this.escapeCSVValue(row.email),
          row.total.toFixed(2),
          row.paid.toFixed(2),
          row.due.toFixed(2),
          this.getStatus(row.status)]
        csv += "\n";
      });

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'transactions.csv';
      anchor.click();
    },
    async getAllTransactions(config, count, t_id, first_name, last_name, email, to, from, status, payment){
      let url = ''
      let promises = []
      let csvData = []
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id


      let offset = 0
      while(offset <= this.totalRows){

        url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/transactions?count=' + count + "&offset=" + offset +
           "&first_name=" + encodeURIComponent(first_name) + "&last_name=" + encodeURIComponent(last_name) + "&email=" + encodeURIComponent(email)
          + "&payment=" + encodeURIComponent(payment) + "&to=" + to + "&from=" + from + "&t_id=" + t_id + "&status=" + status

        promises.push(
          axios.get(url, config)
            .then(response =>{
              csvData.push(response.data.transactions)
            })
            .catch(err => {
                console.log(err)
                if (err.response.status == 401) {
                  swal({
                    title: 'Error',
                    html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                    type: 'error',
                  }).then((response)=> {
                    this.$router.push('/')
                  })
                } else {
                  swal('Error!', 'Something went wrong', 'error')
                }
              }
            )
        )
        offset = offset + Number(count)
      }

      await Promise.all(promises)

      // merge all response arrays into one
      let arr = []
      csvData.forEach((element) => {
        arr = arr.concat(element)

      })
      return arr
    },
    getLocalTime(d){
      if(d === undefined){
        return
      }
      let local = new Date(d)
      let mins =  local.getMinutes()
      if(mins < 10){
        mins = '0' + mins
      }
      return this.getTime(local.getHours() + ':' + mins)
    },
    getTime(d) {
      return time(this.time_format, d)
    },
    getLocalDateNoDay(d){
      return this.getFormattedDate(d) + '  ' + this.getLocalTime(d)
    },
    getLocalDate(d){
      if(d === undefined){
        return
      }
      let local = new Date(d)
      let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
      let final = this.getFormattedDate(form)
      // let final = (local.getMonth() + 1).toString().padStart(2, '0') + "/" + local.getDate().toString().padStart(2, '0') + "/" + local.getFullYear()
      return this.getDayOfWeek(form) + final
    },
    getFormattedDate(d){
      return formattedDate(this.date_format, d)
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    getAmount(integer){
      if(integer == null){
        return
      }
      if(this.number_format === 1 && this.currency_format === 0){
        return this.currency_symbol + integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',')
      } else if(this.number_format === 1 && this.currency_format === 1){
        return integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',') + ' ' + this.currency
      } else if (this.number_format === 1 && this.currency_format === 1){
        return parseFloat(integer).toFixed(2) + ' ' + this.currency
      }
      return this.currency_symbol + parseFloat(integer).toFixed(2);
    },
    viewTransactionDetails(item){
      this.$router.push({ path: '/purchases/transactions/details', query: { id: item.id }})
    },
    resetField(field1, field2){
      if(field1){
        field1.value = ''
        field1.actual = ''
      }
      if(field2){
        field2.actual_from = ''
        field2.value_from = ''
        field2.actual_to = ''
        field2.value_to = ''
      }
      this.updateTransactions('filter')
    },
    async updateTransactions(source){

      //set transaction id
      this.filters.transaction_id.actual = this.filters.transaction_id.value

      //set first name
      this.filters.firstName.actual = this.filters.firstName.value

      //set last name
      this.filters.lastName.actual = this.filters.lastName.value

      //set email
      this.filters.email.actual = this.filters.email.value

      //set status
      this.filters.status.actual = this.filters.status.value

      //set status
      this.filters.payment.actual = this.filters.payment.value

      // set booking date
      this.to = ''
      this.from = ''
      if (this.selectedDateRangeOption === 'radio0' && this.filters.transaction_day.value !== ''){
        this.filters.transaction_range.actual_from = ''
        this.filters.transaction_range.actual_to = ''
        this.filters.transaction_range.value_from = ''
        this.filters.transaction_range.value_to = ''
        this.filters.transaction_day.actual = this.filters.transaction_day.value
        let today = new Date()
        let dt = new Date()
        dt.setDate(dt.getDate() - this.filters.transaction_day.actual)
        let userStartDate = dt.getFullYear() + "-"  + ('0' + (dt.getMonth() + 1)).slice(-2) + "-" + ('0' + dt.getDate()).slice(-2);
        let userEndDate = today.getFullYear() + "-" + ('0' + (today.getMonth() + 1)).slice(-2) + "-" + ('0' + today.getDate()).slice(-2);

        let startLocalDate = new Date(`${userStartDate}T00:00:00`);
        let endLocalDate = new Date(`${userEndDate}T23:59:59`);

        this.from = startLocalDate.toISOString();
        this.to = endLocalDate.toISOString();
      }

      if (this.selectedDateRangeOption === 'radio1' && this.filters.transaction_range.value_to !== '' && this.filters.transaction_range.value_from !== ''){
        this.filters.transaction_day.actual = ''
        this.filters.transaction_day.value = ''
        this.filters.transaction_range.actual_from = this.filters.transaction_range.value_from
        this.filters.transaction_range.actual_to = this.filters.transaction_range.value_to

        let userStartDate = this.filters.transaction_range.actual_from
        let userEndDate = this.filters.transaction_range.actual_to

        let startLocalDate = new Date(`${userStartDate}T00:00:00`);
        let endLocalDate = new Date(`${userEndDate}T23:59:59`);

        this.from = startLocalDate.toISOString();
        this.to = endLocalDate.toISOString();
      }
      if(source === 'filter'){
        this.currentPage = 1
      }
      let count = this.perPage
      let offset = parseInt((this.currentPage * this.perPage) - this.perPage)
      await this.getTransactions(count, offset, this.filters.transaction_id.actual, this.filters.firstName.actual,
        this.filters.lastName.actual, this.filters.email.actual, this.to, this.from, this.filters.status.actual, this.filters.payment.actual)
    },
    async getTransactions(count, offset, t_id, first_name, last_name, email, to, from, status, payment){
      if(first_name === undefined){
        first_name = ''
      }
      if(last_name === undefined){
        last_name = ''
      }
      if(email === undefined){
        email = ''
      }
      if(to === undefined){
        to = ''
      }
      if(from === undefined){
        from = ''
      }
      if(status === undefined){
        status = ''
      }
      if(t_id === undefined){
        t_id = ''
      }
      if(payment === undefined){
        payment = ''
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId  + '/transactions?count=' + count + "&offset=" + offset
        + "&first_name=" + encodeURIComponent(first_name) + "&last_name=" + encodeURIComponent(last_name) + "&email=" + encodeURIComponent(email)
        + "&payment=" + encodeURIComponent(payment) + "&to=" + to + "&from=" + from + "&t_id=" + t_id + "&status=" + status
      this.axios.get(url, config)
        .then(response => {
          this.transactions = response.data.transactions
          this.totalRows = response.data.totalRows
          this.addQueryParameters()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }else if(err.response.status == 500){
            console.log('50000000')
            swal({
              title: 'Ooops',
              text: 'Booking does not exist',
              type: 'error',
              confirmButtonColor: '#fd5d93',
              confirmButtonText: 'Back to bookings'
            }).then((response)=> {
              if(response.value == true){
                this.$router.push('/bookings')
              }
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    addQueryParameters() {
      this.$nextTick(() => {
        this.currentPage = this.$route.query.page
      });

      const newQuery = {
        ...this.$route.query,
        count: this.perPage,
        page: this.currentPage,
        first_name: this.filters.firstName.value,
        last_name: this.filters.lastName.value,
        email: this.filters.email.value,
        to: this.filters.transaction_range.value_to,
        from: this.filters.transaction_range.value_from,
        payment: this.filters.payment.value,
        transaction_id: this.filters.transaction_id.value,
        status: this.filters.status.value
      }
      this.$router.replace({query: newQuery}).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    },
    getOffset(){
      return Number((this.currentPage * this.perPage) - this.perPage)
    },
  },
  mounted(){
    this.date_format_cal = this.date_format
    this.date_format_cal =  this.date_format_cal.replaceAll('Y', 'y')
    this.date_format_cal = this.date_format_cal.replaceAll('D', 'd')
    if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
      this.day_of_week = 'ddd, '
    }
    this.currency_symbol =  _.find(this.currencies, { code: JSON.parse(localStorage.getItem('group')).currency }).symbol
    this.perPage = Number(this.$route.query.count)
    this.currentPage = Number(this.$route.query.page)
    this.selectedDateRangeOption = 'radio1'
    this.filters = {
      firstName: {
        value: this.$route.query.first_name ? this.$route.query.first_name : '',
        actual: this.$route.query.first_name ? this.$route.query.first_name : ''
      },
      lastName: {
        value: this.$route.query.last_name ? this.$route.query.last_name : '',
        actual: this.$route.query.last_name ? this.$route.query.last_name : ''
      },
      email: {
        value: this.$route.query.email ? this.$route.query.email : '',
        actual: this.$route.query.email ? this.$route.query.email : ''
      },
      payment: {
        value: this.$route.query.payment ? this.$route.query.payment : '',
        actual: this.$route.query.payment ? this.$route.query.payment : ''
      },
      transaction_id: {
        value: this.$route.query.transaction_id ? this.$route.query.transaction_id : '',
        actual: this.$route.query.transaction_id ? this.$route.query.transaction_id : ''
      },
      status: {
        value: this.$route.query.status ? this.$route.query.status : '',
        actual: this.$route.query.status ? this.$route.query.status : '',
      },
      transaction_day: {
        value: '',
        actual: '',
      },
      transaction_range:{
        value_from: this.$route.query.from ? this.$route.query.from : '',
        actual_from: this.$route.query.from ? this.$route.query.from : '',
        value_to: this.$route.query.to ? this.$route.query.to : '',
        actual_to: this.$route.query.to ? this.$route.query.to : ''
      },
    }
    //this.getTransactions(this.perPage, this.getOffset())
    this.updateTransactions()
  }
}
</script>

<style scoped>

</style>
