<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>

    <div class="row">
      <div class="col">
        <h2 class="card-title">{{dashboard.name}}</h2>
      </div>
    </div>

    <div class="row">
      <div class="col-2">
        <base-input>
          <el-select
            class="select-primary mb-3 pagination-select"
            v-model="period"
            placeholder="Date range"
            value-key="id"
          >
            <el-option
              class="select-primary"
              v-for="(item, index) in periodOptions"
              :key="'I' + index"
              :label="item.label"
              :value="{ label : item.label, id: item.id}"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-lg-2" v-if="custom_range">
        <base-input style=" margin-left: -15px">
          <el-date-picker
            style="border: 0; border-width:0px;"
            type="date"
            placeholder="From"
            :format="day_of_week + date_format_cal"
            v-model="from_cal"
            @change="validateCustomDates()"
          >
          </el-date-picker>
        </base-input>
      </div>
      <div class="col-lg-2" v-if="custom_range">
        <base-input style=" margin-left: -15px">
          <el-date-picker
            style="border: 0; border-width:0px;"
            type="date"
            placeholder="To"
            :format="day_of_week + date_format_cal"
            v-model="to_cal"
            @change="validateCustomDates()"
          >
          </el-date-picker>
        </base-input>
      </div>
      <div class="col-lg-2" v-if="custom_range">
        <base-button v-on:click="getMetrics('custom')"
                     style="margin-top: 0; margin-left: -15px; padding-bottom: 9px; padding-top: 9px" type="primary">Update</base-button>
      </div>
      <div class="col text-right">
        <el-dropdown>
          <base-button v-if="dashboards.length > 1" class="mr-2" type="primary" link size="sm">More dashboards
            <i class="tim-icons icon-minimal-down ml-2"></i>
          </base-button>
          <template #dropdown>
            <el-dropdown-menu>
              <a v-for="(d, i) in dashboards" :key="'D' + i" class="dropdown-item mt-2 mb-2" href="#">
                {{ d.name }}
              </a>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <base-button type="primary" size="sm" v-on:click="goToDashboardSettings()">
          <i class="tim-icons icon-settings-gear-63 mr-2"></i> Dashboard settings
        </base-button>
        <!--        <el-dropdown>-->
        <!--          <base-button-->
        <!--            size="sm"-->
        <!--            type="primary"-->
        <!--            icon-->
        <!--          > <i class="tim-icons el-icon-more"></i>-->
        <!--          </base-button>-->
        <!--          <template #dropdown>-->
        <!--            <el-dropdown-menu>-->
        <!--              <a class="dropdown-item mt-2 mb-2" href="#">-->
        <!--                <i class="tim-icons icon-link-72 text-info mr-2"></i> Go to dashboard-->
        <!--              </a>-->
        <!--              <a class="dropdown-item mt-2 mb-2" @click="editDashboard(row.item, $event)" href="#">-->
        <!--                <i class="tim-icons icon-pencil text-warning mr-2"></i> Rename-->
        <!--              </a>-->
        <!--              <a class="dropdown-item mt-2 mb-2" @click="editDashboard(row.item, $event)" href="#">-->
        <!--                <i class="tim-icons icon-single-copy-04 text-default mr-2"></i> Copy dashboard-->
        <!--              </a>-->
        <!--              <a class="dropdown-item mt-2 mb-2" @click="editDashboard(row.item, $event)" href="#">-->
        <!--                <i class="tim-icons icon-refresh-02 text-default mr-2"></i> Configure automatic refresh-->
        <!--              </a>-->
        <!--              <hr>-->
        <!--              <a class="dropdown-item mt-2 mb-2" @click="deleteDashboard(row.item, $event)" href="#">-->
        <!--                <i class="tim-icons icon-trash-simple text-danger mr-2"></i> Delete dashboard-->
        <!--              </a>-->
        <!--            </el-dropdown-menu>-->
        <!--          </template>-->
        <!--        </el-dropdown>-->
      </div>
    </div>

    <div class="row">
      <div :class="getColSize(w.size)" v-for="(w, index) in widgets" :key="index + 'W' + j">
        <card :style="{'border-top': '3px solid ' + w.highlight_color }" style="border-radius: 3px">
          <div v-if="w.widget_type_id === 3">
            <div class="row">
              <div class="col text-right">
                <h4>{{w.title}}</h4>
              </div>
            </div>
            <div class="row">
              <div class="col text-right">
                <h1 style="font-size: xxx-large" class="card-title">
                  {{getAmount(w.data_type, w.data[0].statistic)}}</h1>
              </div>
            </div>
            <div class="row">
              <div class="col text-right">
                <p class="card-title">{{w.label}}</p>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>

  </div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import swal from "sweetalert2";
import axios from "axios";
import {Dropdown, DropdownItem, DropdownMenu} from "element-ui";

export default {
  name: "DashboardDisplay",
  components: {
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem
  },
  data() {
    return {
      loading: false,
      dashboard: '',
      dashboards: [],
      widgets: [],
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      currency_symbol: '$',
      currency: JSON.parse(localStorage.getItem('group')).currency,
      currency_format: JSON.parse(localStorage.getItem('group')).currency_format,
      number_format: JSON.parse(localStorage.getItem('group')).number_format,
      j: 0,
      period: {
        id: 1,
        label: 'Last 7 days'
      },
      periodOptions: [
        {
          id: 0,
          label: 'Last 24 hours'
        },
        {
          id: 1,
          label: 'Last 7 days'
        },
        {
          id: 2,
          label: 'Last 30 days'
        },
        {
          id: 3,
          label: 'Custom'
        }
      ],
    }
  },
  methods: {
    getAmount(data_format, integer){
      if(integer == null){
        return
      }
      if(data_format === 'currency'){
        if(this.number_format === 1 && this.currency_format === 0){
          return this.currency_symbol + integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',')
        } else if(this.number_format === 1 && this.currency_format === 1){
          return integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',') + ' ' + this.currency
        } else if (this.number_format === 1 && this.currency_format === 1){
          return parseFloat(integer).toFixed(2) + ' ' + this.currency
        }
        return this.currency_symbol + parseFloat(integer).toFixed(2);
      }
      return integer
    },
    getColSize(size){
      if(size === 2){
        return 'col-lg-6'
      } else if(size === 3){
        return 'col-lg-12'
      }
      else if(size === 1){
        return 'col-lg-3'
      }
    },
    goToDashboardSettings(){
      this.$router.push('/dashboards/settings')
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    getMainDashboardId(url, config) {
      return new Promise ( function(resolve) {
        axios.get(url, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
      })
    },
    async getMainDashboard(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/dashboards/pinned'
      let dashboard = await this.getMainDashboardId(url, config)
      const dashboardId = dashboard.id
      this.dashboards = dashboard.dashboards
      url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/dashboards/' + dashboardId
      this.axios.get(url, config)
        .then(response => {
          this.loading = false
          this.dashboard = response.data.dashboard
          this.widgets = response.data.widgets
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if (err.response.status == 401) {
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    }
  },
  mounted() {
    this.getMainDashboard()
  }
}
</script>

<style scoped>

</style>
