<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>

  <!--    Breadcrumbs-->
  <breadcrumb style="margin-left: -15px">
    <breadcrumb-item>
      <a href="/dashboards">Dashboards</a>
    </breadcrumb-item>
    <breadcrumb-item>
      <span>Dashboard settings</span>
    </breadcrumb-item>
  </breadcrumb>

  <div class="row">
    <div class="col">
      <card>
        <div class="row">
          <div class="col">
            <h4 class="card-title">Dashboards</h4>
          </div>
          <div class="col text-right">
            <base-button type="primary" size="sm" v-on:click="createNewDashboard()">
              <i class="tim-icons el-icon-plus mr-1"></i> <span class="d-none d-sm-block">Create dashboard</span>
            </base-button>
          </div>
        </div>

        <!--   No data panel -->
        <div v-if="dashboards.length <= 0" class="mt-3" >
          <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            <h4 class="mt-3">No dashboards have been created.</h4>
          </div>
        </div>

        <div class="row mt-3" v-if="dashboards.length > 0">
          <div class="col">
            <b-table  striped hover :items="dashboards"
                     :fields="['name', 'created_by', 'actions']">
              <template v-slot:cell(name)="row">
                <p>{{row.item.name}}
                  <i v-if="row.item.pinned" class="tim-icons text-info icon-pin ml-2"></i>
                </p>
                <small>{{row.item.description}}</small>
              </template>
              <template v-slot:cell(created_by)="row">
                <p v-if="row.item.creator_id">{{row.item.first_name}} {{row.item.last_name}}</p>
                <p v-else>System</p>
                <small v-if="row.item.creator_id" style="font-style: italic">
                  {{getLocalDateTime(row.item.created_time, date_format)}}
                </small>
              </template>
              <template v-slot:cell(actions)="row">
                <el-dropdown>
                  <base-button
                    simple
                    type="primary"
                    link
                  > <i style="font-size: medium" class="tim-icons el-icon-more"></i>
                  </base-button>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <a v-if="!row.item.pinned"
                         class="dropdown-item mt-2 mb-2" href="#" @click="updateDashboard(row.item, $event, 'pin')">
                        <i class="tim-icons icon-pin text-default mr-2"></i> Pin dashboard
                      </a>
                      <a class="dropdown-item mt-2 mb-2" href="#">
                        <i class="tim-icons icon-link-72 text-info mr-2"></i> Go to dashboard
                      </a>
                      <a class="dropdown-item mt-2 mb-2" @click="editDashboard(row.item, $event)" href="#">
                        <i class="tim-icons icon-pencil text-warning mr-2"></i> Edit dashboard
                      </a>
                      <hr>
                      <a class="dropdown-item mt-2 mb-2" @click="deleteDashboard(row.item, $event)" href="#">
                        <i class="tim-icons icon-trash-simple text-danger mr-2"></i> Delete dashboard
                      </a>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </template>
            </b-table>
          </div>
        </div>
      </card>
    </div>
  </div>

  <modal :show.sync="modals.create"
         body-classes="p-0"
         modal-classes="modal-dialog-centered modal-lg">
    <card type="secondary"
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0">

      <h4 class="card-title">Create dashboard</h4>
      <div class="row">
        <div class="col">
          <base-input required label="Name" v-model="name" placeholder="Name"></base-input>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <base-input label="Description">
            <el-input
              v-model="description"
              :rows="5"
              type="textarea"
              placeholder="Description"
            />
          </base-input>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col text-center">
          <base-button type="secondary" @click="modals.create = false">Cancel</base-button>
          <base-button class="ml-3" type="primary" @click="createDashboard()">Save</base-button>
        </div>
      </div>
    </card>
  </modal>

</div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import swal from "sweetalert2";
import {BTable} from "bootstrap-vue";
import {localDateTime} from "@/plugins/dates";
import {Dropdown, DropdownItem, DropdownMenu} from "element-ui";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";

export default {
  name: "ViewDashboards",
  components: {
    BTable,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    BreadcrumbItem,
    Breadcrumb,
  },
  data() {
    return {
      loading: false,
      dashboards: [],
      name: '',
      description: '',
      modals: {
        create: false
      },
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
    }
  },
  methods: {
    getLocalDateTime(d){
      return localDateTime(d, this.date_format, this.time_format)
    },
    editDashboard(item, event){
      event.preventDefault()
      this.$router.push({ path: '/dashboards/edit', query: { id:  item.id }})
    },
    async updateDashboard(item, event, action){
      event.preventDefault()
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId
        + '/dashboards/' + item.id

      let data = {}

      if(action === 'pin'){
        data = {
          action: 'pin',
        }
      }

      this.axios.put(url, data, config)
        .then(response => {
          this.loading = false
          swal('Success', 'Dashboard has been pinned', 'success')
          this.getDashboards()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if (err.response.status == 401) {
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async deleteDashboard(item, event){
      event.preventDefault()

      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/dashboards/' + item.id

      this.axios.delete(url, config)
        .then(response => {
          this.loading = false
          swal('Success', 'Dashboard has been deleted', 'success')
          this.getDashboards()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async createDashboard(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/dashboards'

      let data = {
        name: this.name,
        description: this.description
      }
      this.axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.$router.push({ path: '/dashboards/edit', query: { id:  response.data }})
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    createNewDashboard(){
      this.modals.create = true
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getDashboards(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/dashboards'

      this.axios.get(url, config)
        .then(response => {
          this.loading = false
          this.dashboards = response.data.dashboards
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    }
  },
  mounted() {
    this.getDashboards()
  }
}
</script>

<style scoped>

</style>
