<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>
  <!--  Breadcrumb-->
  <breadcrumb style="margin-left: -15px">
    <breadcrumb-item>
      <a href="/reports">Report builder</a>
    </breadcrumb-item>
    <breadcrumb-item>
      <span>{{ report_name }}</span>
    </breadcrumb-item>
  </breadcrumb>

  <div class="row">
    <div class="col">
      <card>
        <h4 class="card-title">Edit report</h4>

        <div class="row">
          <div class="col-lg-4">
            <div class="row">
              <div class="col">
                <base-input label="Name" :error="errs.name" placeholder="Name" v-model="report.name"></base-input>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <base-input label="Description">
                  <el-input
                    v-model="report.description"
                    :rows="10"
                    type="textarea"
                    placeholder="Description"
                  />
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <base-checkbox v-model="report.is_date_range">Show results for a given date range
                  <el-popover trigger="hover"
                              placement="right">

                    <div>
                      <div class="popover-body">
                        <span v-if="report.report_type_id == 1">
                          The report will show customers based on their <br/>initial interaction date with the company
                        </span>
                        <span v-if="report.report_type_id == 2">
                          The report will show games by game form submission date.
                        </span>
                        <span v-if="report.report_type_id == 3">
                          The report will show transactions by creation date.
                        </span>
                        <span v-if="report.report_type_id == 4">
                          The report will show purchases by purchase date.
                        </span>
                        <span v-if="report.report_type_id == 6">
                          The report will show payments by payment date.
                        </span>
                        <span v-if="report.report_type_id == 7">
                          The report will show promo codes by configured event date range.
                        </span>
                      </div>
                    </div>
                    <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                  </el-popover>
                </base-checkbox>
              </div>
            </div>

            <div class="row mt-3 mb-3" v-if="report.is_date_range">
              <div class="col">
                <base-input label="Start date" :error="errs.start_date">
                  <el-date-picker
                    type="date"
                    placeholder="mm-dd-yyyy"
                    value-format="yyyy-MM-dd"
                    :format="day_of_week + '' + date_format_cal"
                    name="date"
                    v-model="report.report_start_date"
                  >
                  </el-date-picker>
                </base-input>
              </div>
              <div class="col">
                <base-input label="End date" :error="errs.end_date">
                  <el-date-picker
                    type="date"
                    placeholder="mm-dd-yyyy"
                    value-format="yyyy-MM-dd"
                    :format="day_of_week + '' + date_format_cal"
                    name="date"
                    v-model="report.report_end_date"
                  >
                  </el-date-picker>
                </base-input>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col-lg-3">
                <base-input label="Report type">
                  <b-list-group >
                    <b-list-group-item style="background-color: inherit; color: white" v-for="(rt, i) in report_types" :key="'RT' + i">
                      <base-radio v-on:input="setFieldData()" v-model="report.report_type_id" :name="rt.id.toString()" style="margin-top: 0">{{ rt.name }}</base-radio>
                    </b-list-group-item>
                  </b-list-group>
                </base-input>
              </div>

              <div class="col-lg-4">
                <label>Fields</label>
                <div style="border: 1px solid white; padding-bottom: 15px; padding-left: 20px; padding-right: 30px; padding-top: 5px; border-radius: 3px">
                  <draggable
                    :list="field_options"
                    @start="dragging = true"
                    @end="dragging = false"
                  >
                    <div class="row"
                         v-for="element in field_options"
                         :key="element.name"
                    >
                      <div class="col" >
                        <base-checkbox  draggable="true" v-model="element.selected">{{ element.label }}
                        </base-checkbox>
                      </div>
                      <div class="col-lg-1 mt-2" >
                        <i style="color: grey; cursor: move" class="tim-icons el-icon-rank"></i>
                      </div>
                    </div>
                  </draggable>
                </div>
              </div>

<!--              <div class="col" :key="t_key">-->
<!--                <base-input label="Fields">-->
<!--                  <el-transfer target-order="push" :titles="['Field options', 'Selected fields']" @change="handleTransferChange"-->
<!--                                v-model="report.fields" :data="field_options" />-->
<!--                </base-input>-->
<!--              </div>-->
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <base-button type="default" v-on:click="cancel()">Cancel</base-button>
            <base-button type="primary" class="ml-lg-2" v-on:click="saveReport()">Save</base-button>
          </div>
        </div>

      </card>
    </div>
  </div>


</div>
</template>

<script>
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import swal from "sweetalert2";
import {Transfer} from "element-ui";
import { BListGroupItem, BListGroup } from 'bootstrap-vue'
import _ from "lodash";
import draggable from 'vuedraggable'

export default {
  name: "EditReport",
  components: {
    BreadcrumbItem,
    Breadcrumb,
    BListGroupItem,
    BListGroup,
    [Transfer.name]: Transfer,
    draggable
  },
  data() {
    return {
      dragging: false,
      loading: false,
      errs: {
        name: '',
        start_date: '',
        end_date: ''
      },
      report: {
        name: '',
        description: '',
        report_type_id: 1,
        fields: []
      },
      report_types: [],
      report_fields: [],
      field_options: [],
      selected_fields: [],
      report_name: '',
      day_of_week: '',
      date_format_cal: '',
      date_format: JSON.parse(localStorage.getItem('group')).date_format
    }
  },
  methods: {
    handleTransferChange(value, direction, movedKeys){
      this.t_key++
    },
    cancel(){
      this.$router.push('/reports')
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    setFields(fields){
      let new_fields = []
      for(let n in fields){
        if(fields[n].selected){
          new_fields.push({
            id: fields[n].key
          })
        }
      }
      return new_fields
    },
    validate(){
      let errors = 0
      if(this.report.name === ''){
        this.errs.name = "Report name cannot be empty"
        errors++
      } else {
        this.errs.name = ''
      }

      if(this.report.is_date_range == true && (this.report.report_start_date == '' || this.report.report_start_date === null)){
        this.errs.start_date = "Invalid start date"
        errors++
      } else {
        this.errs.start_date = ''
      }

      if(this.report.is_date_range == true && (this.report.report_end_date == '' || this.report.report_end_date === null)){
        this.errs.end_date = "Invalid end date"
        errors++
      } else {
        this.errs.end_date = ''
      }

      return errors > 0 ? false : true
    },
    async saveReport(){
      if(!this.validate()){
        return
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let reportId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/reports/' + reportId

      let data = this.report

      this.report.fields = this.setFields(this.field_options)

      this.axios.put(url, data, config)
        .then(response => {
          this.loading = false
          swal('Success!', 'Report has been updated', 'success')
          this.$router.push('/reports')
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    setFieldData(){
      this.field_options = []
      for(let n in this.selected_fields) {
        if (parseInt(this.report.report_type_id) === this.selected_fields[n].report_type_id){
          this.field_options.push({
            key: this.selected_fields[n].report_field_id,
            label: this.capitalizeFirstLetter(this.selected_fields[n].field.replaceAll('_', ' ')),
            selected: true
          })
        }
      }
      for(let n in this.report_fields){
        if(parseInt(this.report.report_type_id) === this.report_fields[n].report_type_id){
          if(!(_.find(this.selected_fields, { report_field_id: this.report_fields[n].id }))){
            this.field_options.push({
              key: this.report_fields[n].id,
              label: this.capitalizeFirstLetter(this.report_fields[n].field.replaceAll('_', ' ')),
              selected: false
            })
          }
        }
      }
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    async getReport(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let reportId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/reports/' + reportId

      this.axios.get(url, config)
        .then(response => {
          this.loading = false
          this.report = response.data.report
          this.report_types = response.data.report_types
          this.report_fields = response.data.report_fields
          this.selected_fields = response.data.selected_fields
          this.report_name = this.report.name

          this.setFieldData()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    }
  },
  mounted() {
    this.date_format_cal = this.date_format
    this.date_format_cal =  this.date_format_cal.replaceAll('Y', 'y')
    this.date_format_cal = this.date_format_cal.replaceAll('D', 'd')
    if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
      this.day_of_week = 'ddd, '
    }
    this.getReport()
  }
}
</script>

<style scoped>

</style>
