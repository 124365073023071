<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>


    <card>
      <div class="row mb-3" v-if="!add_promo_code && !edit_promo_code">
        <div class="col">
          <div
            class="btn-group btn-group-toggle"
            :class="'float-left'"
            data-toggle="buttons"
          >
            <label
              v-for="(option, index) in categories"
              :key="index + j"
              class="btn btn-sm btn-primary btn-simple"
              :class="{ active: option.active }"
              :id="index"
            >
              <input
                type="radio"
                @click="switchTab(index)"
                name="options"
                autocomplete="off"
                :checked="option.active"
              />
              <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
              <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
            </label>
          </div>
        </div>
        <div class="col text-right d-sm-block d-none" v-if="activeTabIndex === 0">
          <base-button v-on:click="addNewPromo()" type="primary" size="sm">
            <i class="tim-icons el-icon-plus"></i> Create new promo code</base-button>
        </div>
        <div class="col text-right d-sm-none d-block">
          <base-button v-on:click="addNewPromo()" type="primary" size="sm">
            <i class="tim-icons el-icon-plus"></i></base-button>
        </div>
      </div>

      <div class="d-sm-none d-block">
        <div class="row justify-content-between">
          <div class="col">
            <b-icon icon="filter" scale="1" class="text-white" v-on:click="show_filters = !show_filters"></b-icon>
            <b-icon icon="arrow-counterclockwise" scale="1" class="ml-2 text-white" v-on:click="resetAll()"></b-icon>
          </div>
        </div>
      </div>
<!--      Filter and pagination header - desktop -->
      <div class="d-sm-block d-none">
        <div class="row" v-if="!add_promo_code && !edit_promo_code">
          <div class="col">
            <base-input>
              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Filter by promo code</h3>
                  <div class="pop popover-body">
                    <div class="row container">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="    Promo code"
                        v-model="filters.name.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.name)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="getPromos(perPage, getOffset(currentPage, perPage), archived, 'filter')">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button slot="reference" :simple="!filters.name.actual" size="sm" type="primary">
                  Promo code <span v-if="filters.name.actual">: {{filters.name.actual}}</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>

              <el-tooltip content="Reset all filters"
                          effect="light"
                          :open-delay="300"
                          placement="top">
                <base-button link class="ml-2" v-on:click="resetAll()"  style="font-size: large; color:white">
                  <i class="tim-icons icon-refresh-01"></i>
                </base-button>
              </el-tooltip>
              <span class="ml-3 text-white">Total&nbsp;results:&nbsp;{{totalRows}}</span>
            </base-input>
          </div>
          <div class="col text-right">
            <base-button v-if="hasPermissions('download_data')" icon class="btn-link text-white" v-on:click="downloadCSV()">
              <i class="tim-icons icon-cloud-download-93"></i>
            </base-button>
            <el-select
              class="select-primary mb-3 pagination-select"
              v-model="perPage"
              placeholder="Per page"
              v-on:change="getPromos(perPage, getOffset(currentPage, perPage), 0, 'filter')"
            >
              <el-option
                class="select-primary"
                v-for="item in perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>

      <div class="d-sm-none d-block">
        <div class="row mt-2" v-if="show_filters && !add_promo_code && !edit_promo_code">
          <div class="col">
            <base-input>
              <el-popover trigger="click"
                          placement="bottom">
                <div>
                  <h3 class="popover-header">Filter by promo code</h3>
                  <div class="pop popover-body">
                    <div class="row container">
                      <el-input
                        type="search"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="    Promo code"
                        v-model="filters.name.value"
                      >
                      </el-input>

                    </div>
                    <div class="mt-2">
                      <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.name)" type="primary">Reset</base-button>
                      <base-button size="sm" type="info" v-on:click="getPromos(perPage, getOffset(currentPage, perPage), archived, 'filter')">Update</base-button>
                    </div>

                  </div>
                </div>
                <base-button slot="reference" :simple="!filters.name.actual" size="sm" type="primary">
                  Promo code <span v-if="filters.name.actual">: {{filters.name.actual}}</span> &nbsp;
                  <i class="tim-icons icon-minimal-down"></i>
                </base-button>
              </el-popover>
            </base-input>
          </div>
        </div>
      </div>

      <div class="mt-3" v-if="!add_promo_code && promo_codes.length <=0 && !edit_promo_code">
        <div class="font-icon-detail">
          <span style="font-size: 3em">
            <i class="fas fa-exclamation-triangle"></i>
          </span>
          <h4 class="mt-3">You have no promo codes.</h4>
        </div>
      </div>
      <!--            Mobile-->
      <div class="d-block d-sm-none">
        <div class="row mt-3" v-if="!add_promo_code && promo_codes.length > 0 && !edit_promo_code">
          <div class="col">
            <b-table striped hover :items="promo_codes"
                     :fields="['promo_codes']">
              <template v-slot:cell(promo_codes)="row">
                <div class="row">
                  <div class="col">
                    <p style="font-size: medium">{{row.item.name}}</p>
                  </div>
                  <div class="col text-right">
                    <badge type="danger" v-if="row.item.internal_only">internal only</badge>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <small>{{row.item.description}}</small>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    Value:
                    <span v-if="!row.item.redemption_type || (row.item.redemption_type.hasOwnProperty('id') && row.item.redemption_type.id === 0)">{{row.item.value_amount}}%</span>
                    <span v-else>{{currency_symbol}}{{parseFloat(row.item.value_amount).toFixed(2)}}</span>
                    <span v-if="row.item.value_type === 0"> per transaction</span>
                    <span v-if="row.item.value_type === 2"> per participant</span>
                  </div>
                </div>


                <div class="row">
                  <div class="col">
                    <span v-if="row.item.quantity > 0">Remaining: {{row.item.quantity - row.item.used}}</span>
                    <span v-else>Unlimited use</span>
                  </div>
                  <div class="col">
                    <span>Used: x{{row.item.used}}</span>
                    <span v-if="row.item.used > 0">
                        <el-tooltip content="View transactions"
                                    effect="light"
                                    :open-delay="300"
                                    placement="top">
                        <base-button v-on:click="getTransactions(row.item, $event)" icon class="btn btn-link" simple size="sm" type="primary">
                          <i class="tim-icons el-icon-top-right"></i>
                        </base-button>
                      </el-tooltip>
                      </span>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                     <span v-if="row.item.games">
                        <span v-for="(g,i) in row.item.games" :key="'G' + i">
                          <badge v-if="i === 0" type="primary">{{g.name}}</badge>
                          <badge v-else class="ml-2" type="primary">{{g.name}}</badge>
                        </span>
                      </span>
                  </div>
                </div>

                <div
                  class="btn-group btn-group-toggle mt-3"
                  :class="'float-left'"
                  data-toggle="buttons" style="width:100%; max-width: 100%"
                >
                  <label class="btn btn-sm btn-primary btn-simple">
                    <base-button style="padding-left: 0; padding-right: 0"
                                 @click.native="deletePromoCodePrompt(row.item, $event)"
                                 simple
                                 type="primary"
                                 link
                    > <i class="tim-icons icon-trash-simple"></i>
                    </base-button>
                  </label>
                  <label class="btn btn-sm btn-primary btn-simple">
                    <base-button style="padding-left: 0; padding-right: 0"
                                 @click.native="copyToGroupsPrompt(row.item, $event)"
                                 simple
                                 type="primary"
                                 link
                    > <i class="tim-icons icon-single-copy-04"></i>
                    </base-button>
                  </label>
                  <label class="btn btn-sm btn-primary btn-simple">
                    <base-button style="padding-left: 0; padding-right: 0"
                                 @click.native="editPromoCode(row.item, $event)"
                                 simple
                                 type="primary"
                                 link
                    > <i class="tim-icons icon-pencil"></i>
                    </base-button>
                  </label>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <!--            Desktop-->
      <div class="d-none d-sm-block">
        <div class="row mt-3" v-if="!add_promo_code && promo_codes.length > 0 && !edit_promo_code">
          <div class="col">

            <b-table striped hover :items="promo_codes"
                     :fields="['promo_code', 'value', 'purchase_dates', 'event_dates', 'event_times', 'applicable_to', 'remaining', 'used', 'actions']">
              <template v-slot:cell(promo_code)="row">
                <div>{{row.item.name}}</div>
                <badge type="danger" v-if="row.item.internal_only">internal only</badge>
                <div>
                  <small>{{row.item.description}}</small>
                </div>
              </template>
              <template v-slot:cell(value)="row">
                <span v-if="!row.item.redemption_type || (row.item.redemption_type.hasOwnProperty('id') && row.item.redemption_type.id === 0)">{{row.item.value_amount}}%</span>
                <span v-else>{{currency_symbol}}{{parseFloat(row.item.value_amount).toFixed(2)}}</span>
                <span v-if="row.item.value_type === 0"> per transaction</span>
                <span v-if="row.item.value_type === 2"> per participant</span>
              </template>
              <template v-slot:cell(purchase_dates)="row">
                <span v-if="row.item.purchase_is_range">
                  <span>{{getDate(row.item.purchase_start_date)}} - {{getDate(row.item.purchase_end_date)}}</span>
                </span>
                <span v-else>
                  Available any dates
                </span>
              </template>
              <template v-slot:cell(remaining)="row">
                <span v-if="row.item.quantity > 0">{{row.item.quantity - row.item.used}}</span>
                <span v-else>Unlimited</span>
              </template>
              <template v-slot:cell(used)="row">
                <span>{{row.item.used}}</span>
              </template>
              <template v-slot:cell(event_dates)="row">
                <span v-if="row.item.event_is_date_range">
                  <span>{{getDate(row.item.event_start_date)}} - {{getDate(row.item.event_end_date)}}</span>
                </span>
                <span v-else>
                  Available any dates
                </span>
              </template>
              <template v-slot:cell(event_times)="row">
                <span v-if="row.item.event_is_time_range">
                  <span>{{getTime(row.item.event_start_time)}} - {{getTime(row.item.event_end_time)}}</span>
                </span>
                <span v-else>
                  Available any times
                </span>
              </template>
              <template v-slot:cell(applicable_to)="row">
                <badge class="ml-2" v-if="row.item.applicable_to_misc_items" type="default">gift cards</badge>
                <badge class="ml-2" v-if="row.item.applicable_to_misc_items" type="default">merchandise</badge>
                <span v-if="row.item.games">
                  <span v-for="(g,i) in row.item.games" :key="'G' + i">
                    <badge class="ml-2" type="primary">{{g.name}}</badge>
                  </span>
                </span>
                <span v-if="((row.item.games && row.item.games.length <= 0)
                || !row.item.games) && !row.item.applicable_to_misc_items">-</span>
              </template>
              <template v-slot:cell(actions)="row">
                <el-dropdown>
                  <base-button
                    link
                    type="primary"
                    size="sm"
                  > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                  </base-button>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <a class="dropdown-item mt-1 mb-2"  href="#"
                         v-on:click="editPromoCode(row.item, $event)">
                        <i class="tim-icons text-warning icon-pencil mr-2"></i> Edit promo code
                      </a>
                      <a v-if="row.item.used > 0" class="dropdown-item mt-1 mb-2"
                         href="#"
                         v-on:click="getTransactions(row.item, $event)">
                        <i class="tim-icons text-info icon-notes mr-2"></i> View associated transactions
                      </a>
                      <a class="dropdown-item mt-1 mb-2" href="#"
                         v-on:click="copyToGroupsPrompt(row.item, $event)">
                        <i class="tim-icons text-default icon-single-copy-04 mr-2"></i> Copy to other groups
                      </a>
                      <hr>
                      <a v-if="archived === 0"
                         v-on:click="deletePromoCodePrompt(row.item, $event)"
                         class="dropdown-item mt-1 mb-2" href="#">
                        <i class="tim-icons text-danger icon-trash-simple mr-2"></i> Archive promo code
                      </a>
                      <a v-if="archived === 1"
                         v-on:click="unarchivePromoCodePrompt(row.item, $event)"
                         class="dropdown-item mt-1 mb-2" href="#">
                        <i class="tim-icons text-default icon-refresh-02 mr-2"></i> Unarchive promo code
                      </a>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </template>
            </b-table>
          </div>
        </div>
      </div>

      <b-pagination v-show="!add_promo_code && !edit_promo_code"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        v-on:input="getPromos(perPage, getOffset(currentPage, perPage), archived)"
      ></b-pagination>

      <div v-if="add_promo_code || edit_promo_code" :key="un_key">
        <div class="row mb-3">
          <div class="col">
            <h4 class="card-title" v-if="add_promo_code">Create promo code</h4>
            <h4 class="card-title" v-if="edit_promo_code">Edit promo code</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-3 d-none d-sm-block">
            <p class="text-primary" style="font-size: medium">Code</p>
            <small>This is a unique code customers will use to apply the discount. Promo codes must be alphanumeric.</small>
          </div>
          <div class="col-lg-6">
            <div class="row">
              <div class="col">
                <base-input :error="errs.name" label="Promo code" v-model="promo.name" placeholder="Code"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <base-input label="Description">
                  <el-input style="background-color: inherit"
                            v-model="promo.description"
                            :rows="5"
                            type="textarea"
                            placeholder="Promotion description"
                  />
                </base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-3 d-none d-sm-block">
            <p class="text-primary" style="font-size: medium">Value</p>
            <small>These options determine the value of your promo code and how it is computed.</small>
          </div>
          <div class="col">
            <div class="row">
              <div class="col-lg-3">
                <base-input label="Redemption type">
                  <el-select
                    v-model="promo.redemption_type"
                    class="select-primary"
                    name="redemption_type"
                    placeholder="Redemption type"
                    value-key="id"
                  >
                    <el-option
                      v-for="(type, index) in redemptionTypes"
                      class="select-primary"
                      :label="type.name"
                      :value="{name: type.name, id: type.id}"
                      :key="index + 'R'"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-3">
                <base-input :error="errs.value_amount" label="Value amount" v-model="promo.value_amount"></base-input>
              </div>
              <div class="col-lg-3 col-4">
                <label>Quantity</label>
                <span style="color: #1e1e2f; opacity: 0">{{un_key}}</span>
                <base-input v-if="!promo.is_unlimited" v-model="promo.quantity" type="number"></base-input>
                <base-checkbox v-model="promo.is_unlimited" v-on:input="un_key++" label="Unlimited">Unlimited</base-checkbox>
              </div>
              <div class="col-lg-3 col-8">
                <label>
                  <div>&nbsp; Value type
                    <el-popover trigger="hover"
                                placement="top">

                      <div>
                        <div class="popover-body">
                          A percentage or value discount per participant stacks based on the number of participants <br>
                          (e.g., 10% per participant for 5 participants equals 50% off; $10 per participant equals $50 off).<br>
                          To apply a flat percentage discount to the entire transaction, set it as "per transaction" rather <br>than "per participant."
                        </div>
                      </div>
                      <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                    </el-popover>
                  </div>
                </label>
                <base-input>
                  <el-select
                    v-model="promo.value_type"
                    class="select-primary"
                    name="value_type"
                    placeholder="Value type"
                    value-key="id"
                  >
                    <el-option
                      v-for="(type, index) in valueTypes"
                      class="select-primary"
                      :label="type.name"
                      :value="{name: type.name, id: type.id}"
                      :key="index + 'VT'"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-3 d-none d-sm-block">
            <p class="text-primary" style="font-size: medium">Purchase dates</p>
            <small>Determines the dates customers can buy the promo code.</small>
          </div>
          <div class="col">
            <div class="row">
              <div class="col-lg-3">
                <base-input label="Purchase date option">
                  <el-select
                    v-model="promo.purchase_all_dates"
                    class="select-primary"
                    name="value_type"
                    placeholder="Value type"
                    value-key="id"
                    v-on:change="un_key++"
                  >
                    <el-option
                      v-for="(opt, index) in dateOptions"
                      class="select-primary"
                      :label="opt.name"
                      :value="{id: opt.id, name: opt.name}"
                      :key="index + 'DY'"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>

              <div class="col-lg-3 col-6" v-if="promo.purchase_all_dates.id == 1">
                <base-input label="Purchase start date" :error="errs.purchase_start_date">
                  <el-date-picker
                    type="date"
                    v-on:change="validateDateRange()"
                    placeholder="mm-dd-yyyy"
                    value-format="yyyy-MM-dd"
                    name="date"
                    v-model="promo.purchase_start_date"
                    :format="date_format_cal"
                  >
                  </el-date-picker>
                </base-input>
                <!--                      :format="date_format_cal"-->
              </div>
              <div class="col-lg-3 col-6" v-if="promo.purchase_all_dates.id == 1">
                <base-input label="Purchase end date" :error="errs.purchase_end_date">
                  <el-date-picker
                    type="date"
                    v-on:change="validateDateRange()"
                    placeholder="mm-dd-yyyy"
                    value-format="yyyy-MM-dd"
                    name="date"
                    v-model="promo.purchase_end_date"
                    :format="date_format_cal"
                  >
                  </el-date-picker>
                </base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-3 d-none d-sm-block">
            <p class="text-primary" style="font-size: medium">Event times</p>
            <small>Determines the dates and times customers can use the promo code.</small>
          </div>
          <div class="col">
            <div class="row">
              <div class="col-lg-3">
                <base-input label="Event date option">
                  <el-select
                    v-model="promo.event_all_dates"
                    class="select-primary"
                    name="value_type"
                    placeholder="Value type"
                    value-key="id"
                    v-on:change="un_key++"
                  >
                    <el-option
                      v-for="(opt, index) in dateOptions"
                      class="select-primary"
                      :label="opt.name"
                      :value="{id: opt.id, name: opt.name}"
                      :key="index + 'DX'"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>

              <div class="col-lg-3 col-6" v-if="promo.event_all_dates.id == 1" >
                <base-input label="Event start date" :error="errs.event_start_date">
                  <el-date-picker
                    type="date"
                    v-on:change="validateDateRange()"
                    placeholder="mm-dd-yyyy"
                    value-format="yyyy-MM-dd"
                    name="date"
                    v-model="promo.event_start_date"
                    :format="date_format_cal"
                  >
                  </el-date-picker>
                </base-input>
              </div>
              <div class="col-lg-3 col-6" v-if="promo.event_all_dates.id == 1" >
                <base-input label="Event end date" :error="errs.event_end_date">
                  <el-date-picker
                    type="date"
                    v-on:change="validateDateRange()"
                    placeholder="mm-dd-yyyy"
                    value-format="yyyy-MM-dd"
                    name="date"
                    v-model="promo.event_end_date"
                    :format="date_format_cal"
                  >
                  </el-date-picker>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3">
                <base-input label="Event time option" >
                  <el-select
                    v-model="promo.event_all_times"
                    class="select-primary"
                    name="value_type"
                    placeholder="Time option"
                    value-key="id"
                    v-on:change="un_key++"
                  >
                    <el-option
                      v-for="(opt, index) in timeOptions"
                      class="select-primary"
                      :label="opt.name"
                      :value="{id: opt.id, name: opt.name}"
                      :key="index + 'XT'"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-3 col-6" v-if="promo.event_all_times.id == 1">
                <base-input label="Event start time" :error="errs.event_start_time">
                  <el-time-picker v-on:change="validateDateRange()"
                                  v-model="promo.event_start_time"
                                  :format="picker_time_format" placeholder="HH:MM"
                  ></el-time-picker>
                </base-input>
              </div>
              <div class="col-lg-3 col-6" v-if="promo.event_all_times.id == 1">
                <base-input label="Event end time" :error="errs.event_end_time">
                  <el-time-picker v-on:change="validateDateRange()"
                                  v-model="promo.event_end_time"
                                  :format="picker_time_format" placeholder="HH:MM"
                  ></el-time-picker>
                </base-input>
              </div>
            </div>
            <label>Available days of the week</label>
            <div class="row">
              <div class="col ">
                <base-checkbox v-model="promo.available_monday" class="d-inline">Monday</base-checkbox>
                <base-checkbox v-model="promo.available_tuesday" class="d-inline ml-3">Tuesday</base-checkbox>
                <base-checkbox v-model="promo.available_wednesday" class="d-inline ml-3">Wednesday</base-checkbox>
                <base-checkbox v-model="promo.available_thursday" class="d-inline ml-3">Thursday</base-checkbox>
                <base-checkbox v-model="promo.available_friday" class="d-inline ml-3">Friday</base-checkbox>
                <base-checkbox v-model="promo.available_saturday" class="d-inline ml-3">Saturday</base-checkbox>
                <base-checkbox v-model="promo.available_sunday" class="d-inline ml-3">Sunday</base-checkbox>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-3">
                <base-input label="Exclusion dates">
                  <el-date-picker
                    type="dates"
                    placeholder="mm-dd-yyyy"
                    value-format="yyyy-MM-dd"
                    name="date"
                    :format="date_format_cal"
                    v-model="promo.event_exlusions"
                  >
                  </el-date-picker>
                </base-input>
              </div>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-3 d-none d-sm-block">
            <p class="text-primary" style="font-size: medium">Usage</p>
            <small>Determines when and how the code can be used.</small>
          </div>
          <div class="col">
            <div class="row">
              <div class="col-lg-6">
                <base-input label="Applicable to">
                  <el-select multiple
                             v-model="promo.games"
                             class="select-primary"
                             name="value_type"
                             placeholder="Games"
                             value-key="id"
                  >
                    <el-option
                      v-for="(game, index) in games"
                      class="select-primary"
                      :label="game.name"
                      :value="{name: game.name, id: game.id}"
                      :key="index + 'G'"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-6">
                <base-input label="Duplicate to other groups">
                  <el-select multiple
                             v-model="selectedGroups"
                             class="select-primary"
                             name="value_type"
                             placeholder="Groups"
                             value-key="id"
                  >
                    <el-option
                      v-for="(group, i) in groups"
                      class="select-primary"
                      :label="group.name"
                      :value="{ name: group.name, id: group.id} "
                      :key="'FN' + i"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <base-checkbox v-model="promo.applicable_to_misc_items">Applicable to gift cards and merchandise</base-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <base-checkbox v-model="promo.is_combineable">Can be combined with other promo codes</base-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <base-checkbox v-model="promo.internal_only">Can only be applied internally</base-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2" v-if="add_promo_code">
          <div class="col">
            <base-button v-on:click="add_promo_code = false" type="default">
              Cancel
            </base-button>
            <base-button class="ml-2" v-on:click="createPromoCode()" type="primary">
              Save
            </base-button>
          </div>
        </div>
        <div class="row mt-2" v-if="edit_promo_code">
          <div class="col">
            <base-button v-on:click="edit_promo_code = false" type="default">
              Cancel
            </base-button>
            <base-button class="ml-2" v-on:click="savePromoCode(promo)" type="primary">
              Save
            </base-button>
          </div>
        </div>

      </div>
    </card>

<!--    View associated transactions-->
    <modal :show.sync="modals.promo_transaction"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4>Transactions with <span class="text-primary">{{selectedPromoCode.name}}</span> applied</h4>

        <div class="row">
          <div class="col">
            <b-table :items="promo_transactions" :fields="['customer', 'transaction']" :per-page="perPagePromos"
                     :current-page="currentPagePromos">
              <template #head(transaction)="data">
                <span></span>
              </template>
              <template #head(customer)="data">
                <span></span>
              </template>
              <template v-slot:cell(customer)="row">
                <h4>{{row.item.first_name}} {{row.item.last_name}}</h4>
                <small>
                  <i class="tim-icons icon-email-85"></i> {{row.item.email}}
                  <i v-if="row.item.phone" class="tim-icons el-icon-mobile-phone"></i> {{row.item.phone}}
                </small>
              </template>
              <template v-slot:cell(transaction)="row">
                <p class="text-white">Transaction #{{row.item.order_number}}
                  <el-tooltip content="View transaction"
                              effect="light"
                              :open-delay="300"
                              placement="top">
                    <base-button v-on:click="goToTransaction(row.item.id)" icon class="btn btn-link" simple size="sm" type="primary">
                      <i class="tim-icons el-icon-top-right"></i>
                    </base-button>
                  </el-tooltip>
                </p>
                <small>{{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}</small>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPagePromos"
              :total-rows="totalRowsPromos"
              :per-page="perPagePromos"
            ></b-pagination>
          </div>
        </div>

        <div class="row text-center">
          <div class="col">
            <base-button type="default" @click="modals.promo_transaction = false">Return</base-button>
          </div>
        </div>
      </card>
    </modal>

<!--    Copy to other groups-->
    <modal :show.sync="modals.copy_to_groups"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4>Copy <span class="text-primary">{{selectedPromoCode.name}}</span> to other groups</h4>

        <div class="row mb-4" v-if="groups">
          <div class="col">
            <base-input label="Select groups" :error="errs.groups">
              <el-select multiple
                         v-model="selectedGroups"
                         class="select-primary"
                         name="game"
                         placeholder="Group"
                         value-key="id"
              >
                <el-option
                  v-for="(group, i) in groups"
                  class="select-primary"
                  :label="group.name"
                  :value="{ name: group.name, id: group.id} "
                  :key="'F' + i"
                ></el-option>
              </el-select>
            </base-input>
          </div>
        </div>

        <div class="row text-center">
          <div class="col">
            <base-button type="default" @click="modals.copy_to_groups = false">Cancel</base-button>
            <base-button type="primary" class="ml-2" @click="copyPromoCode()">Copy</base-button>
          </div>
        </div>

      </card>
    </modal>

  </div>
</template>

<script>
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import swal from "sweetalert2";
import {Auth} from "aws-amplify";
import BaseAlert from "@/components/BaseAlert";
import Badge from "@/components/Badge";
import {BaseSwitch, ImageUpload} from "@/components";
import {Dialog, Dropdown, DropdownItem, DropdownMenu, Form, FormItem, Switch, TimePicker, Upload} from "element-ui";
import draggable from "vuedraggable";
import Editor from "@tinymce/tinymce-vue";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import {BPagination, BTable} from "bootstrap-vue";
import _ from "lodash";
import currencies from "@/assets/currencies.json";
import {formattedDate, localTime, time} from "@/plugins/dateFormatter";

export default {
  name: "PromoCodes",
  data() {
    return {
      loading: false,
      activeTabIndex: 0,
      promo_codes: [],
      show_filters: false,
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      currentPagePromos: 1,
      totalRowsPromos: 0,
      perPagePromos: 5,
      archived: 0,
      perPageOptions: [5, 10, 25, 50],
      j: 0,
      add_promo_code: false,
      edit_promo_code: false,
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      date_format_cal: '',
      currencies: currencies.currencies,
      currency_symbol: '$',
      picker_time_format: 'hh:mm A',
      un_key: 0,
      modals: {
        promo_transaction: false,
        copy_to_groups: false
      },
      errs: {
        name: '',
        value_amount: '',
        purchase_start_date: '',
        purchase_end_date: '',
        event_start_date: '',
        event_end_date: '',
        event_start_time: '',
        event_end_time: '',
        groups: ''
      },
      promo_transactions: [],
      groups: [],
      selectedGroups: [],
      games: [],
      selectedPromoCode: '',
      filters: {
        name: {
          value: '',
          actual: ''
        },
      },
      promo: {
        name: '',
        description: '',
        redemption_type: {name: 'Percentage discount', id: 0},
        value_amount: '1',
        quantity: '10',
        is_unlimited: true,
        value_type: {name: 'Per transaction', id: 0},
        games: [],
        groups: [],
        purchase_all_dates: {id: 0, name: 'Available any dates'},
        purchase_all_times: {id: 0, name: 'Available any times'},
        purchase_days: [],
        purchase_exlusions: [],
        purchase_start_date: new Date(),
        purchase_end_date: new Date(),
        event_all_dates: {id: 0, name: 'Available any dates'},
        event_all_times: {id: 0, name: 'Available any times'},
        available_monday: true,
        available_tuesday: true,
        available_wednesday: true,
        available_thursday: true,
        available_friday: true,
        available_saturday: true,
        available_sunday: true,
        event_exlusions: [],
        event_start_date: new Date(),
        event_end_date: new Date(),
        event_start_time: new Date(),
        event_end_time: new Date(),
        is_combineable: false
      },
      timeOptions: [
        {
          name: 'Available any times',
          id: 0
        },
        {
          name: 'Available between times',
          id: 1
        }
      ],
      dateOptions: [
        {
          name: 'Available any dates',
          id: 0
        },
        {
          name: 'Available between dates',
          id: 1
        }
      ],
      redemptionTypes: [
        {
          name: 'Percentage discount',
          id: 0
        },
        {
          name: 'Value discount',
          id: 1
        }
      ],
      valueTypes: [
        {
          name: 'Per transaction',
          id: 0
        },
        // {
        //   name: 'Per booking',
        //   id: 1
        // },
        {
          name: 'Per participant',
          id: 2
        }
      ],
    }
  },
  components: {BaseAlert, Badge, BaseSwitch,
    [TimePicker.name]: TimePicker,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Switch.name]: Switch,
    ImageUpload,
    draggable,
    Editor,
    Breadcrumb, BreadcrumbItem, BTable, BPagination
  },
  computed: {
    categories() {
      return [
        { name: 'Promo codes', icon: 'tim-icons icon-tag', active: true },
        { name: 'Archived promo codes', icon: 'tim-icons el-icon-box', active: false },
      ];
    }
  },
  methods: {
    hasPermissions(scope){
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      const matchFound = permissions.some(element => element === scope);
      return matchFound
    },
    async copyPromoCode(){
      if(this.selectedGroups.length <= 0){
        this.errs.groups = 'Please select groups'
        return
      } else {
        this.errs.groups = ''
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/promos/' + this.selectedPromoCode.id
      let data = {
        groups: this.selectedGroups
      }

      this.axios.put(url, data, config)
        .then(response => {
          swal('Success!', 'Promo code has been copied', 'success');
          this.getPromos(this.perPage, this.getOffset(this.currentPage,  this.perPage), this.archived)
          this.modals.copy_to_groups = false
          this.selectedGroups = []
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getGroups(){
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId + '/groups'

      axios.get(url, config)
        .then(response => {
          this.loading = false
          this.groups = response.data
          for(let n in this.groups){
            if(this.groups[n].id === JSON.parse(localStorage.getItem('group')).id){
              this.groups.splice(n, 1)
            }
          }
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async copyToGroupsPrompt(item, event){
      event.preventDefault()
      this.selectedPromoCode = item
      this.selectedGroups = []
      await this.getGroups()
      this.modals.copy_to_groups = true
    },
    goToTransaction(id){
      let documentClasses = document.body.classList;
      documentClasses.remove('modal-open');
      this.$router.push({ path: '/purchases/transactions/details', query: { id: id }})
    },
    getLocalTime(d){
      if(!d){
        return
      }
      return localTime(this.time_format, d)
    },
    getLocalDate(d){
      let local = new Date(d)
      let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
      let final = this.getDate(form)
      return final
    },
    validateDateRange(){
      let errors = 0
      if(this.promo.purchase_all_dates.id === 1){
        if(this.promo.purchase_start_date instanceof Date){
          this.promo.purchase_start_date = this.promo.purchase_start_date.toISOString()
        }
        if(this.promo.purchase_end_date instanceof Date){
          this.promo.purchase_end_date = this.promo.purchase_end_date.toISOString()
        }
        if(this.promo.purchase_start_date.includes('T')){
          this.promo.purchase_start_date = this.promo.purchase_start_date.split('T')[0]
        }
        if(this.promo.purchase_end_date.includes('T')){
          this.promo.purchase_end_date = this.promo.purchase_end_date.split('T')[0]
        }
        let start = new Date()
        start.setFullYear(this.promo.purchase_start_date.split("-")[0])
        start.setMonth(parseInt(this.promo.purchase_start_date.split("-")[1]) - 1, this.promo.purchase_start_date.split("-")[2])

        let end = new Date()
        end.setFullYear(this.promo.purchase_end_date.split("-")[0])
        end.setMonth(parseInt(this.promo.purchase_end_date.split("-")[1]) - 1, this.promo.purchase_end_date.split("-")[2])
        if(start > end){
          this.errs.purchase_start_date = 'Start date cannot be greater than end date'
          errors ++
        } else {
          this.errs.purchase_start_date = ''
        }
      }

      if(this.promo.event_all_dates.id === 1){
        if(this.promo.event_start_date instanceof Date){
          this.promo.event_start_date = this.promo.event_start_date.toISOString()
        }
        if(this.promo.event_end_date instanceof Date){
          this.promo.event_end_date = this.promo.event_end_date.toISOString()
        }

        if(this.promo.event_start_date.includes('T')){
          this.promo.event_start_date = this.promo.event_start_date.split('T')[0]
        }
        if(this.promo.event_end_date.includes('T')){
          this.promo.event_end_date = this.promo.event_end_date.split('T')[0]
        }
        let event_start = new Date()
        event_start.setFullYear(this.promo.event_start_date.split("-")[0])
        event_start.setMonth(parseInt(this.promo.event_start_date.split("-")[1]) - 1, this.promo.event_start_date.split("-")[2])

        let event_end = new Date()
        event_end.setFullYear(this.promo.event_end_date.split("-")[0])
        event_end.setMonth(parseInt(this.promo.event_end_date.split("-")[1]) - 1, this.promo.event_end_date.split("-")[2])

        if(event_start > event_end){
          this.errs.event_start_date = 'Start date cannot be greater than end date'
          console.log(this.errs.event_start_date)
          errors ++
        } else {
          this.errs.event_start_date = ''
        }
      }

      if(this.promo.event_all_times.id === 1){

        if(this.promo.event_start_time > this.promo.event_end_time){
          this.errs.event_start_time = 'Start time cannot be greater than end time'
          errors ++
        } else {
          this.errs.event_start_time = ''
        }
      }

      return errors
    },
    resetAll(){
      this.filters = {
        name: {
          value: '',
          actual: ''
        }
      }
      this.getPromos(this.perPage, this.getOffset(this.currentPage, this.perPage), this.archived, 'filter')
    },
    resetField(field1){
      if(field1){
        field1.value = ''
        field1.actual = ''
      }
      this.getPromos(this.perPage, this.getOffset(this.currentPage, this.perPage), this.archived, 'filter')
    },
    async getTransactions(item, event){
      event.preventDefault()
      this.selectedPromoCode = item
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/promos/' + item.id

      axios.get(url, config)
        .then((response) => {
          console.log(response)
          this.modals.promo_transaction = true
          this.promo_transactions = response.data.transactions
          this.totalRowsPromos = this.promo_transactions.length
          this.loading = false

        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })

    },
    getDate(d){
      return formattedDate(this.date_format, d)
    },
    getTime(d){
      return time(this.time_format, d)
    },
    isPromoValid(){
      let errors = 0

      //check that name is unique
      let name = this.promo.name
      let id = this.promo.id
      let non_unique = _.find(this.promo_codes, function(o) { return o.name === name && o.id !== id })
      if(non_unique){
        this.errs.name = 'Promo code name must be unique'
        errors++
      } else if(this.promo.name.length <= 0){
        this.errs.name = 'Promo code name cannot be empty'
        errors++
      } else {
        this.errs.name = ''
      }

      if(isNaN(this.promo.value_amount) || parseFloat(this.promo.value_amount) <= 0){
        this.errs.value_amount = 'Value amount must be greater than 0'
        errors++
      } else {
        this.errs.value_amount = ''
      }
      if(errors > 0){
        return false
      }
      return true
    },
    async savePromoCode(item){
      if(!this.isPromoValid() || this.validateDateRange() > 0){
        return
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/promos/' + item.id
      let data = this.promo
      data.quantity = data.is_unlimited == true ? '-1' : data.quantity
      data.event_start_time = data.event_start_time.getHours() + ':' + data.event_start_time.getMinutes() + ':00'
      data.event_end_time = data.event_end_time.getHours() + ':' + data.event_end_time.getMinutes() + ':00'
      data.groups = this.selectedGroups

      this.axios.put(url, data, config)
        .then(response => {
          swal('Success!', 'Promo code has been updated', 'success');
          this.edit_promo_code = false
          this.getPromos(this.perPage, this.getOffset(this.currentPage,  this.perPage), this.archived)
          this.selectedGroups = []
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async editPromoCode(item, event){
      event.preventDefault()
      this.promo = item
      this.promo.redemption_type = {id: item.redemption_type}
      this.promo.value_type = {id: item.value_type}
      this.promo.purchase_all_dates = {id: item.purchase_is_range}
      this.promo.event_all_dates = {id: item.event_is_date_range}
      this.promo.event_all_times = {id: item.event_is_time_range}

      let dt = new Date()
      if(!(this.promo.event_start_time instanceof Date)){
        dt.setHours(this.promo.event_start_time.split(':')[0])
        dt.setMinutes(this.promo.event_start_time.split(':')[1])
        this.promo.event_start_time = dt
      }
      if(!(this.promo.event_end_time instanceof Date)){
        dt = new Date()
        dt.setHours(this.promo.event_end_time.split(':')[0])
        dt.setMinutes(this.promo.event_end_time.split(':')[1])
        this.promo.event_end_time = dt
      }
      if(item.event_exlusions && !Array.isArray(item.event_exlusions)){
        let exl = []
        let obj = JSON.parse(item.event_exlusions)

        for(let n in obj){
          exl.push(obj[n].exclusion_date)
        }
        item.event_exlusions = exl
      }

      if(this.promo.quantity.toString() === '-1'){

        this.promo.is_unlimited = true
        this.un_key++
      } else {
        this.promo.is_unlimited = false
        this.un_key++
      }
      await this.getGroups()
      await this.getGames()
      this.edit_promo_code = true
    },
    async createPromoCode(){
      if(!this.isPromoValid() || this.validateDateRange() > 0){
        return
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/promos'
      let data = this.promo
      if(this.promo.is_unlimited){
        data.quantity = '-1'
      }
      data.event_start_time = data.event_start_time.getHours() + ':' + data.event_start_time.getMinutes() + ':00'
      data.event_end_time = data.event_end_time.getHours() + ':' + data.event_end_time.getMinutes() + ':00'
      data.groups = this.selectedGroups

      this.axios.post(url, data, config)
        .then(response => {
          swal('Success!', 'Promo code has been created', 'success');
          this.add_promo_code = false
          this.getPromos(this.perPage, this.getOffset(this.currentPage,  this.perPage), this.archived)
          this.selectedGroups = []
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })

    },
    unarchivePromoCodePrompt(item, event){
      event.preventDefault()
      swal({
        title: 'Are you sure?',
        text: "This action will unarchive the promo code, making it available on purchases.",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Unarchive',
      }).then((response)=> {
        if(response.value == true){
          this.restorePromoCode(item)
        }
      })
    },
    async restorePromoCode(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/promos/' + item.id
      let data = {
        restore: 1
      }

      this.axios.put(url, data, config)
        .then(response => {
          swal('Success!', 'Promo code has been unarchived', 'success');
          this.archived = 0
          this.switchTab(0)
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    deletePromoCodePrompt(item, event){
      event.preventDefault()
      swal({
        title: 'Are you sure?',
        text: "This action will archive the promo code, making it unavailable on purchases.",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Archive',
      }).then((response)=> {
        if(response.value == true){
          this.deletePromoCode(item)
        }
      })
    },
    async deletePromoCode(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/promos/' + item.id
      this.axios.delete(url, config)
        .then(response => {
          swal('Success!', 'Promo code has been archived', 'success');
          this.getPromos(this.perPage, this.getOffset(this.currentPage,  this.perPage), this.archived)
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getAllPromos(config, count, name){
      let url = ''
      let promises = []
      let csvData = []
      if(name === undefined){
        name = ''
      }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id

      let offset = 0
      while(offset <= this.totalRows){

        url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/promos?count='
          + count + "&offset=" + offset + "&name=" + encodeURIComponent(name) + "&archived=" + encodeURIComponent(this.archived)
        promises.push(
          axios.get(url, config)
            .then(response =>{
              csvData.push(response.data.promos)
            })
            .catch(err => {
                console.log(err)
                if (err.response.status == 401) {
                  this.$router.push('/')
                } else {
                  swal('Error!', 'Something went wrong', 'error')
                }
              }
            )
        )
        offset = offset + count
      }

      await Promise.all(promises)

      let arr = []
      csvData.forEach((element) => {
        arr = arr.concat(element)

      })
      return arr
    },
    escapeCSVValue(value) {
      if (typeof value === 'string') {
        // Escape double quotes by doubling them
        value = value.replace(/"/g, '""');

        // If the value contains a comma, wrap it in double quotes
        if (value.includes(',') || value.includes('\n')) {
          value = `"${value}"`;
        }
      }
      return value;
    },
    async downloadCSV(){
      //get the entire data set
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }

      this.loading = true
      let count = 5000
      let csvData = await this.getAllPromos(config, count, this.filters.name.value)
      this.loading = false
      let csv =  'Promo name, Description, Redemption type, Value amount, Quantity, Number of times used\n';
      csvData.forEach(row => {
        csv += [this.escapeCSVValue(row.name), this.escapeCSVValue(row.description),
          row.redemption_type ? 'value' : 'percentage', row.value_amount,
          row.quantity > 0 ? row.quantity : 'Unlimited',  row.used]
        csv += "\n";
      });

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'promo_codes.csv';
      anchor.click();
    },
    async addNewPromo(){
      this.promo = {
        name: '',
        is_unlimited: true,
        description: '',
        redemption_type: {name:'Percentage discount', id: 0},
        value_amount: '1',
        quantity: '10',
        value_type: {name: 'Per transaction', id: 0},
        games: [],
        groups: [],
        purchase_all_dates: {id: 0, name: 'Available any dates'},
        purchase_all_times: {id: 0, name: 'Available any times'},
        purchase_days: [],
        purchase_exlusions: [],
        purchase_start_date: new Date(),
        purchase_end_date: new Date(),
        event_all_dates: {id: 0, name: 'Available any dates'},
        event_all_times: {id: 0, name: 'Available any times'},
        available_monday: true,
        available_tuesday: true,
        available_wednesday: true,
        available_thursday: true,
        available_friday: true,
        available_saturday: true,
        available_sunday: true,
        event_exlusions: [],
        event_start_date: new Date(),
        event_end_date: new Date(),
        event_start_time: new Date(),
        event_end_time: new Date(),
        is_combineable: false,
        applicable_to_misc_items: 1
      }
      await this.getGames()
      await this.getGroups()
      this.add_promo_code = true
    },
    async getGames () {
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games'
      this.axios.get(url, config)
        .then(response => {
          this.games = response.data
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    switchTab(index){
      this.activeTabIndex = index
      for(let n in this.categories){
        this.categories[n].active = false
      }
      if(this.activeTabIndex === 0){
        this.archived = 0
        this.currentPage = 1
      } else if(this.activeTabIndex === 1){
        this.archived = 1
        this.currentPage = 1
      }
      this.getPromos(this.perPage, this.getOffset(this.currentPage,  this.perPage), this.archived)
      this.categories[index].active = true
      this.j++
    },
    async getPromos(count, offset, archived, source){
      if(source === 'filter'){
        this.currentPage = 1
      }
      this.filters.name.actual = this.filters.name.value
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/promos?' +
        'count=' + count + '&offset=' + offset + '&archived=' + archived +
        '&name=' + encodeURIComponent(this.filters.name.actual)

      axios.get(url, config)
        .then((res) => {
          this.promo_codes = res.data.promos
          this.totalRows = res.data.totalRows

          for(let n in this.promo_codes){
            if(this.promo_codes[n].games){
              this.promo_codes[n].games = JSON.parse(this.promo_codes[n].games)
            }
            // if(this.promo_codes[n].event_exlusions){
            //   this.promo_codes[n].event_exlusions = JSON.parse(this.promo_codes[n].event_exlusions)
            // }
          }
          this.addQueryParameters()
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    getOffset(currentPage, perPage){
      return Number((currentPage * perPage) - perPage)
    },
    addQueryParameters() {
      this.$nextTick(() => {
        this.currentPage = this.$route.query.page
      });

      const newQuery = {
        ...this.$route.query,
        count: this.perPage,
        page: this.currentPage,
        name: this.filters.name.value,
        archived: this.archived,
      }
      this.$router.replace({query: newQuery}).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    },
  },
  mounted() {
    this.perPage = Number(this.$route.query.count)
    this.currentPage = Number(this.$route.query.page)
    this.archived = Number(this.$route.query.archived)
    if(this.archived){
      this.switchTab(1)
    }
    this.filters = {
      name: {
        value: this.$route.query.name ? this.$route.query.name : '',
        actual: this.$route.query.name ? this.$route.query.name : ''
      }
    }
    this.getPromos(this.perPage, this.getOffset(this.currentPage,  this.perPage), this.archived)
  }
}
</script>

<style scoped>

</style>
