<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>

    <div class="row mt-2 overflow-scroll">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <div class="row mb-3">
            <div class="col-lg-9">
              <div
                class="btn-group btn-group-toggle"
                :class="'float-left'"
                data-toggle="buttons"
              >
                <label
                  v-for="(option, index) in categories"
                  :key="index + j"
                  class="btn btn-sm btn-primary btn-simple"
                  :class="{ active: option.active }"
                  :id="index"
                >
                  <input
                    type="radio"
                    @click="switchTab(index)"
                    name="options"
                    autocomplete="off"
                    :checked="option.active"
                  />
                  <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                  <span class="d-block d-sm-none" style="font-size: xx-small; margin-right: -10px; margin-left: -10px">
                    {{ option.short_name }}
                  </span>
                </label>
              </div>
            </div>
            <div class="col text-right" v-if="activeTabIndex === 0 && hasPermissions('gift_cards_update')">
              <base-button type="primary" size="sm" v-on:click="newGiftCard()">
                <i class="tim-icons el-icon-plus mr-1"></i> <span class="d-none d-sm-block">Create a gift card purchase</span>
              </base-button>
            </div>
            <div class="col text-right" v-if="activeTabIndex === 1 && hasPermissions('gift_cards_update')">
              <base-button type="primary" size="sm" v-on:click="newGiftCardType()">
                <i class="tim-icons el-icon-plus mr-1"></i><span class="d-none d-sm-block">Create a gift card type</span>
              </base-button>
            </div>
          </div>

<!--          Purchases-->
          <div v-if="activeTabIndex === 0">
            <div class="d-block d-sm-none">
              <div class="row justify-content-between">
                <div class="col">
                  <h4 slot="header" class="card-title">Gift purchases</h4>
                </div>
                <div class="col text-right">
                  <b-icon icon="filter" scale="1" class="text-white" v-on:click="show_filters = !show_filters"></b-icon>
                  <b-icon icon="arrow-counterclockwise" scale="1" class="ml-2 text-white" v-on:click="resetAll()"></b-icon>
                </div>
              </div>
              <div class="row justify-content-between" v-show="show_filters">
                <div class="col-8">
                  <base-input>
                    <el-popover trigger="click"
                                placement="bottom">
                      <div>
                        <h3 class="popover-header">Filter by gift card code</h3>
                        <div class="pop popover-body">
                          <div class="row container" style="width: 300px">
                            <el-input
                              type="search"
                              clearable
                              prefix-icon="el-icon-search"
                              placeholder="Code"
                              v-model="filters.code.value"
                            >
                            </el-input>

                          </div>
                          <div class="mt-2">
                            <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.code)" type="primary">Reset</base-button>
                            <base-button size="sm" type="info" v-on:click="getGiftPurchases('filter')">Update</base-button>
                          </div>

                        </div>
                      </div>
                      <base-button slot="reference" simple size="sm" type="primary">
                        Gift card code <span v-if="filters.code.actual">: {{filters.code.actual}}</span> &nbsp;
                        <i class="tim-icons icon-minimal-down"></i>
                      </base-button>
                    </el-popover>
                    <el-popover trigger="click"
                                placement="bottom">
                      <div>
                        <h3 class="popover-header">Filter by customer name or email</h3>
                        <div class="pop popover-body">
                          <div class="row container" style="width: 400px">
                            <el-input
                              type="search"
                              clearable
                              prefix-icon="el-icon-search"
                              placeholder="Customer name or email"
                              v-model="filters.customer.value"
                            >
                            </el-input>

                          </div>
                          <div class="mt-2">
                            <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.customer)" type="primary">Reset</base-button>
                            <base-button size="sm" type="info" v-on:click="getGiftPurchases('filter')">Update</base-button>
                          </div>

                        </div>
                      </div>
                      <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                        Customer <span v-if="filters.customer.actual">: {{filters.customer.actual}}</span> &nbsp;
                        <i class="tim-icons icon-minimal-down"></i>
                      </base-button>
                    </el-popover>

                    <el-popover trigger="click"
                                placement="bottom">
                      <div>
                        <h3 class="popover-header">Filter by recipient name or email</h3>
                        <div class="pop popover-body">
                          <div class="row container" style="width: 400px">
                            <el-input
                              type="search"
                              clearable
                              prefix-icon="el-icon-search"
                              placeholder="Recipient name or email"
                              v-model="filters.recipient.value"
                            >
                            </el-input>

                          </div>
                          <div class="mt-2">
                            <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.recipient)" type="primary">Reset</base-button>
                            <base-button size="sm" type="info" v-on:click="getGiftPurchases('filter')">Update</base-button>
                          </div>

                        </div>
                      </div>
                      <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                        Recipient <span v-if="filters.recipient.actual">: {{filters.recipient.actual}}</span> &nbsp;
                        <i class="tim-icons icon-minimal-down"></i>
                      </base-button>
                    </el-popover>

                    <el-popover trigger="click"
                                placement="bottom">
                      <div>
                        <h3 class="popover-header">Filter by gift card status</h3>
                        <div class="pop popover-body">
                          <div class="row container">
                            <base-radio v-model="filters.status.value" name="active" class="mb-3">
                              Active
                            </base-radio>
                            <base-radio v-model="filters.status.value" name="deactivated" class="mb-3">
                              Deactivated
                            </base-radio>

                          </div>
                          <div class="mt-2">
                            <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.status)" type="primary">Reset</base-button>
                            <base-button size="sm" type="info" v-on:click="getGiftPurchases('filter')">Update</base-button>
                          </div>
                        </div>
                      </div>
                      <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                        Status <span v-if="filters.status.actual">: {{filters.status.actual}}</span> &nbsp;
                        <i class="tim-icons icon-minimal-down"></i>
                      </base-button>
                    </el-popover>
                  </base-input>
                </div>
              </div>
            </div>

<!--            Desktop-->
            <div class="d-none d-sm-block">
              <!--        Filters-->
              <div class="row justify-content-between">
                <div class="col-8">
                  <base-input>
                    <el-popover trigger="click"
                                placement="bottom">
                      <div>
                        <h3 class="popover-header">Filter by gift card code</h3>
                        <div class="pop popover-body">
                          <div class="row container" style="width: 300px">
                            <el-input
                              type="search"
                              clearable
                              prefix-icon="el-icon-search"
                              placeholder="Code"
                              v-model="filters.code.value"
                            >
                            </el-input>

                          </div>
                          <div class="mt-2">
                            <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.code)" type="primary">Reset</base-button>
                            <base-button size="sm" type="info" v-on:click="getGiftPurchases('filter')">Update</base-button>
                          </div>

                        </div>
                      </div>
                      <base-button slot="reference" simple size="sm" type="primary">
                        Gift card code <span v-if="filters.code.actual">: {{filters.code.actual}}</span> &nbsp;
                        <i class="tim-icons icon-minimal-down"></i>
                      </base-button>
                    </el-popover>
                    <el-popover trigger="click"
                                placement="bottom">
                      <div>
                        <h3 class="popover-header">Filter by customer name or email</h3>
                        <div class="pop popover-body">
                          <div class="row container" style="width: 400px">
                            <el-input
                              type="search"
                              clearable
                              prefix-icon="el-icon-search"
                              placeholder="Customer name or email"
                              v-model="filters.customer.value"
                            >
                            </el-input>

                          </div>
                          <div class="mt-2">
                            <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.customer)" type="primary">Reset</base-button>
                            <base-button size="sm" type="info" v-on:click="getGiftPurchases('filter')">Update</base-button>
                          </div>

                        </div>
                      </div>
                      <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                        Customer <span v-if="filters.customer.actual">: {{filters.customer.actual}}</span> &nbsp;
                        <i class="tim-icons icon-minimal-down"></i>
                      </base-button>
                    </el-popover>

                    <el-popover trigger="click"
                                placement="bottom">
                      <div>
                        <h3 class="popover-header">Filter by recipient name or email</h3>
                        <div class="pop popover-body">
                          <div class="row container" style="width: 400px">
                            <el-input
                              type="search"
                              clearable
                              prefix-icon="el-icon-search"
                              placeholder="Recipient name or email"
                              v-model="filters.recipient.value"
                            >
                            </el-input>

                          </div>
                          <div class="mt-2">
                            <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.recipient)" type="primary">Reset</base-button>
                            <base-button size="sm" type="info" v-on:click="getGiftPurchases('filter')">Update</base-button>
                          </div>

                        </div>
                      </div>
                      <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                        Recipient <span v-if="filters.recipient.actual">: {{filters.recipient.actual}}</span> &nbsp;
                        <i class="tim-icons icon-minimal-down"></i>
                      </base-button>
                    </el-popover>

                    <el-popover trigger="click"
                                placement="bottom">
                      <div>
                        <h3 class="popover-header">Filter by gift card status</h3>
                        <div class="pop popover-body">
                          <div class="row container">
                            <base-radio v-model="filters.status.value" name="active" class="mb-3">
                              Active
                            </base-radio>
                            <base-radio v-model="filters.status.value" name="deactivated" class="mb-3">
                              Deactivated
                            </base-radio>

                          </div>
                          <div class="mt-2">
                            <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.status)" type="primary">Reset</base-button>
                            <base-button size="sm" type="info" v-on:click="getGiftPurchases('filter')">Update</base-button>
                          </div>
                        </div>
                      </div>
                      <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                        Status <span v-if="filters.status.actual">: {{filters.status.actual}}</span> &nbsp;
                        <i class="tim-icons icon-minimal-down"></i>
                      </base-button>
                    </el-popover>

                    <el-tooltip content="Reset all filters"
                                effect="light"
                                :open-delay="300"
                                placement="top">
                      <base-button link class="ml-2" v-on:click="resetAll()"  style="font-size: large; color:white">
                        <i class="tim-icons icon-refresh-01"></i>
                      </base-button>
                    </el-tooltip>
                    <span class="text-white">Total&nbsp;results:&nbsp;{{totalRows}}</span>
                  </base-input>
                </div>
                <div class="col text-right">
                  <base-button icon class="btn-link text-white"  v-if="hasPermissions('download_data')" v-on:click="downloadCSV()">
                    <i class="tim-icons icon-cloud-download-93"></i>
                  </base-button>
                  <el-select
                    class="select-primary mb-3 pagination-select"
                    v-model="perPage"
                    placeholder="Per page"
                    v-on:change="getGiftPurchases('filter')"
                  >
                    <el-option
                      class="select-primary"
                      v-for="(item, index) in perPageOptions"
                      :key="'B' + index"
                      :label="item.toString()"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>

<!--            List-->
            <div class="mt-3" v-if="gift_purchases.length <= 0">
              <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
                <h4 class="mt-3">No gift purchases have been made.</h4>
              </div>
            </div>
            <!--        Mobile-->
            <div class="row d-sm-none d-block">
              <div class="col">
                <b-table v-if="gift_purchases.length > 0" striped hover :items="gift_purchases"
                         :fields="['gift_cards']">
                  <template v-slot:head(gift_cards)="row">
                    <span>Total results: {{totalRows}}</span>
                  </template>
                  <template v-slot:cell(gift_cards)="row">
                    <div class="row justify-content-between">
                      <div class="col">
                        <p style="font-weight: lighter; font-size: small">{{getGiftCardCode(row.item.code)}}</p>
                      </div>
                      <div class="col text-right">
                        <badge v-if="row.item.is_active" type="success">active</badge>
                        <badge v-if="!row.item.is_active" type="danger">deactivated</badge>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <p>{{row.item.first_name}} {{row.item.last_name}}
                          <el-tooltip content="View customer"
                                      effect="light"
                                      :open-delay="300"
                                      placement="top">
                            <base-button v-if="hasPermissions('customer_view')" v-on:click="viewCustomer(row.item.customer_id)" icon class="btn btn-link" simple size="sm" type="primary">
                              <i class="tim-icons el-icon-top-right"></i>
                            </base-button>
                          </el-tooltip>
                        </p>

                        <small><i class="tim-icons icon-email-85 mr-1"></i>  {{row.item.email}}</small>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col">
                        <small class="text-white">
                          Purchase date: {{getLocalDate(row.item.date_purchased)}}, {{getLocalTime(row.item.date_purchased)}}
                        </small>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <small class="text-white">
                          Delivery date: {{ getDeliveryDate(row.item.delivery_date, row.item.date_purchased) }}
                        </small>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col">
                        <small class="text-white">
                          Total:  {{ getAmount(row.item.total) }}
                        </small>
                      </div>
                      <div class="col text-right">
                        <small class="text-white">
                          Remaining: {{ getAmount(row.item.remaining) }}
                        </small>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col">
                        <div
                          class="btn-group btn-group-toggle mt-1 mb-2"
                          :class="'float-left'"
                          data-toggle="buttons" style="width:100%"
                        >
                          <label class="btn btn-sm btn-primary btn-simple" v-if="hasPermissions('gift_cards_delete')"
                                 style="padding-right: 0; padding-left: 0">
                            <base-button
                              @click.native="deactivateGiftCardPrompt(row.item)"
                              class="like btn-link"
                              type="primary"
                              size="sm"
                              icon
                            ><i class="tim-icons icon-trash-simple"></i>
                            </base-button>
                          </label>
                          <label class="btn btn-sm btn-primary btn-simple"
                                 v-if="hasPermissions('transactions_view')"
                                 style="padding-right: 0; padding-left: 0">
                            <base-button
                              @click.native="viewTransaction(row.item)"
                              class="like btn-link"
                              type="primary"
                              size="sm"
                              icon
                            ><i class="tim-icons icon-notes"></i>
                            </base-button>
                          </label>
                          <label class="btn btn-sm btn-primary btn-simple" style="padding-right: 0; padding-left: 0">
                            <base-button
                              @click.native="sendGiftCardEmailPrompt(row.item)"
                              class="like btn-link"
                              type="primary"
                              size="sm"
                              icon
                            ><i class="tim-icons icon-email-85"></i>
                            </base-button>
                          </label>
                          <label class="btn btn-sm btn-primary btn-simple" style="padding-right: 0; padding-left: 0">
                            <base-button
                              @click.native="downloadPDF(row.item)"
                              class="like btn-link" type="primary"
                              size="sm"
                              icon
                            ><i class="tim-icons icon-cloud-download-93"></i>
                            </base-button>
                          </label>
                        </div>
                      </div>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
            <!--        Large screens-->
            <div class="row d-sm-block d-none" style="word-break: break-all">
              <div class="col">
                <b-table v-if="gift_purchases.length > 0" striped hover :items="gift_purchases"
                         :fields="['gift_card_code', 'purchased_by', 'recipient', 'value', 'purchase_date', 'activation_date', 'delivery_date', 'expires', 'actions']">
                  <template v-slot:cell(gift_card_code)="row">
                    <p class="mt-2" style="font-weight: lighter; font-size: small">
                      {{getGiftCardCode(row.item.code)}}
                    </p>
                    <badge v-if="row.item.is_active" type="success">active</badge>
                    <badge v-if="!row.item.is_active" type="danger">deactivated</badge>
                  </template>
                  <template v-slot:cell(purchased_by)="row">
                    <div class="row">
                      <div class="col">
                        <p>{{row.item.first_name}} {{row.item.last_name}}
                          <el-tooltip content="View customer"
                                      effect="light"
                                      :open-delay="300"
                                      placement="top">
                            <base-button v-on:click="viewCustomer(row.item.customer_id)" v-if="hasPermissions('customer_view')" icon class="btn btn-link" simple size="sm" type="primary">
                              <i class="tim-icons el-icon-top-right"></i>
                            </base-button>
                          </el-tooltip>
                        </p>

                        <small>{{row.item.email}}</small>
                      </div>
                    </div>
                  </template>
                  <template v-slot:cell(recipient)="row">
                    <div class="row" v-for="(r, i) in getRecipients(row.item.recipients)" :key="'A' + i">
                      <div class="col">
                        <p>{{r.first_name}} {{r.last_name}}
                          <el-tooltip content="View customer"
                                      effect="light"
                                      :open-delay="300"
                                      placement="top">
                            <base-button v-if="hasPermissions('customer_view')" v-on:click="viewCustomer(r.user_id)" icon class="btn btn-link" simple size="sm" type="primary">
                              <i class="tim-icons el-icon-top-right"></i>
                            </base-button>
                          </el-tooltip>
                        </p>
                        <small>{{r.email}}</small>
                      </div>
                    </div>
                  </template>
                  <template v-slot:cell(value)="row">
                    <div style="font-size: small">Purchased value: {{ getAmount(row.item.total) }}</div>
                    <div style="font-size: small">Price: {{ getAmount(row.item.price) }}</div>
                    <div style="font-size: small">Remaining: {{ getAmount(row.item.remaining) }}</div>
                  </template>
                  <template v-slot:cell(purchase_date)="row">
                     <span style="font-size: small">{{getLocalDate(row.item.date_purchased)}},
                       {{getLocalTime(row.item.date_purchased)}}</span>
                  </template>
                  <template v-slot:cell(delivery_date)="row">
                    <span style="font-size: small">{{ getDeliveryDate(row.item.delivery_date, row.item.date_purchased) }}</span>
                  </template>
                  <template v-slot:cell(activation_date)="row">
                    <span style="font-size: small">{{ getFormattedDate(row.item.activation_date) }}</span>
                  </template>
                  <template v-slot:cell(expires)="row">
                    <badge v-if="!row.item.expiration_date">does not expire</badge>
                    <span style="font-size: small" v-if="row.item.expiration_date">{{ getFormattedDate(row.item.expiration_date) }}</span>
                  </template>
                  <template v-slot:cell(actions)="row">
                    <el-dropdown>
                      <base-button
                        simple
                        type="primary"
                        link
                      > <i style="font-size: medium" class="tim-icons el-icon-more"></i>
                      </base-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <a v-if="hasPermissions('gift_cards_update')"
                              class="dropdown-item mt-2 mb-2" @click="editGiftCardPrompt(row.item, $event)" href="#">
                            <i class="tim-icons icon-pencil text-warning mr-2"></i> Edit gift card
                          </a>
                          <a  v-if="hasPermissions('transactions_view')" target="_blank"
                              class="dropdown-item mt-2 mb-2"
                              :href="'/purchases/transactions/details?id=' + row.item.transaction_id">
                            <i class="tim-icons icon-notes text-info mr-2"></i> View transaction
                          </a>
                          <a v-if="row.item.is_active"
                              class="dropdown-item mt-2 mb-2" @click="sendGiftCardEmailPrompt(row.item, $event)" href="#">
                            <i style="color: #42b883" class="tim-icons icon-email-85 mr-2"></i> Resend delivery email
                          </a>
                          <a v-if="row.item.is_active"
                             class="dropdown-item mt-2 mb-2" @click="downloadPDF(row.item, $event)" href="#">
                            <i class="tim-icons text-default icon-cloud-download-93 mr-2"></i> Download PDF
                          </a>
                          <hr v-if="hasPermissions('gift_cards_delete') || hasPermissions('gift_cards_update')">
                          <a v-if="hasPermissions('gift_cards_delete') && row.item.is_active" class="dropdown-item mt-2 mb-2" @click="deactivateGiftCardPrompt(row.item, 0, $event)" href="#">
                            <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="inactive-dot mr-2"></span> Deactivate gift card
                          </a>
                          <a v-if="hasPermissions('gift_cards_update') && !row.item.is_active" class="dropdown-item mt-2 mb-2" @click="deactivateGiftCardPrompt(row.item, 1, $event)" href="#">
                            <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="active-dot mr-2"></span> Activate gift card
                          </a>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>
                </b-table>
                <b-pagination  @click.native="getGiftPurchases()"
                               v-model="currentPage"
                               :total-rows="totalRows"
                               :per-page="perPage"
                ></b-pagination>
              </div>
            </div>
          </div>

          <div v-if="activeTabIndex === 1">
            <div class="mt-3" v-if="gift_card_types.length <= 0">
              <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
                <h4 class="mt-3">No gift card types have been created.</h4>
              </div>
            </div>

<!--            Types-->

            <div class="row d-sm-block d-none">
              <div class="col">
                <b-table v-if="gift_card_types.length > 0" striped hover :items="gift_card_types"
                         :fields="['external_name', 'internal_label', 'value_type', 'activation', 'delivery',  'expiration', 'status', 'actions']">
                  <template v-slot:cell(external_name)="row">
                    <div>{{row.item.name}}</div>
                    <small>{{row.item.description}}</small>
                  </template>
                  <template v-slot:cell(internal_label)="row">
                    <div v-if="row.item.internal_label">{{row.item.internal_label}}</div>
                    <div v-else>-</div>
                  </template>
                  <template v-slot:cell(value_type)="row">
                    <badge v-if="row.item.value_type === 0">Arbitrary value</badge>
                    <badge v-if="row.item.value_type === 1">Fixed value</badge>
                    <badge v-if="row.item.value_type === 2">Game value</badge>
                  </template>
                  <template v-slot:cell(activation)="row">
                    <span v-if="row.item.activate_immediately">Immediately after purchase</span>
                    <span v-if="!row.item.activate_immediately">
                      <span v-if="row.item.activation_type === 1">{{row.item.activation_value}} {{getPeriodName(row.item.activation_period)}} after purchase</span>
                      <span v-if="row.item.activation_type === 2">On {{getFormattedDate(row.item.activation_date)}}</span>
                    </span>
                  </template>
                  <template v-slot:cell(delivery)="row">
                    <span v-if="row.item.allow_customer_set_delivery_date">Set by customer</span>
                    <span v-if="!row.item.allow_customer_set_delivery_date">
                      <span v-if="row.item.delivery_type === 0">Immediately after purchase</span>
                      <span v-if="row.item.delivery_type === 1">{{row.item.delivery_value}} {{getPeriodName(row.item.delivery_period)}} after purchase</span>
                      <span v-if="row.item.delivery_type === 2">On {{getFormattedDate(row.item.delivery_date)}}</span>
                    </span>
                  </template>
                  <template v-slot:cell(expiration)="row">
                    <span v-if="!row.item.has_expiration_date">Does not expire</span>
                    <span v-if="row.item.has_expiration_date">
                      <span v-if="row.item.expiration_type === 1">{{row.item.expiration_value}} {{getPeriodName(row.item.expiration_period)}} after purchase</span>
                      <span v-if="row.item.expiration_type === 2">On {{getFormattedDate(row.item.expiration_date)}}</span>
                    </span>
                  </template>

                  <template v-slot:cell(status)="row">
                    <badge v-if="row.item.active" type="success">active</badge>
                    <badge v-if="!row.item.active" type="danger">deactivated</badge>
                  </template>

                  <template v-slot:cell(actions)="row">
                    <el-dropdown>
                      <base-button
                        simple
                        type="primary"
                        link
                      > <i style="font-size: medium" class="tim-icons el-icon-more"></i>
                      </base-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <a class="dropdown-item mt-2 mb-2" @click="editGiftCardType(row.item, $event)" href="#">
                            <i class="tim-icons icon-pencil text-warning mr-2"></i> Edit gift card type
                          </a>
                          <a class="dropdown-item" v-if="row.item.active" @click="activateGiftCardTypePrompt(row.item, $event, 0)" href="#">
                            <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="inactive-dot mr-2"></span> Deactivate gift card type
                          </a>
                          <a class="dropdown-item" v-if="!row.item.active" @click="activateGiftCardTypePrompt(row.item, $event, 1)" href="#">
                            <span style="padding-left: 0px; padding-bottom: 0px; margin-left: -3px; margin-bottom: -3px" class="active-dot mr-2"></span> Activate gift card type
                          </a>
                          <hr>
                          <a class="dropdown-item mt-2 mb-2" @click="archiveGiftCardTypePrompt(row.item, $event)" href="#">
                            <i class="tim-icons icon-trash-simple text-danger mr-2"></i> Archive gift card type
                          </a>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>
                </b-table>
              </div>
            </div>

            <div class="row d-sm-none d-block">
              <div class="col">
                <b-table v-if="gift_card_types.length > 0" striped hover :items="gift_card_types"
                         :fields="['gift_card_types']">
                  <template v-slot:head(gift_card_types)="row">
                    <span>Total results: {{gift_card_types.length}}</span>
                  </template>
                  <template v-slot:cell(gift_card_types)="row">
                    <div class="row justify-content-between">
                      <div class="col">
                        <div>{{row.item.name}}</div>
                        <small>{{row.item.description}}</small>
                      </div>
                      <div class="col-4 text-right">
                        <badge style="font-size: 8px; padding-left: 7px; padding-right: 7px" v-if="row.item.value_type === 0">Arbitrary value</badge>
                        <badge style="font-size: 8px; padding-left: 7px; padding-right: 7px" v-if="row.item.value_type === 1">Fixed value</badge>
                        <badge style="font-size: 8px; padding-left: 7px; padding-right: 7px" v-if="row.item.value_type === 2">Game value</badge>
                      </div>
                    </div>
                    <div v-if="row.item.internal_label">{{row.item.internal_label}}</div>

                    <div class="row mt-2">
                      <div class="col">
                        <div
                          class="btn-group btn-group-toggle mt-1 mb-2"
                          :class="'float-left'"
                          data-toggle="buttons" style="width:100%"
                        >
                          <label class="btn btn-sm btn-primary btn-simple"
                                 style="padding-right: 0; padding-left: 0">
                            <base-button
                              @click.native="archiveGiftCardTypePrompt(row.item, $event)"
                              class="like btn-link"
                              type="primary"
                              size="sm"
                              icon
                            ><i class="tim-icons icon-trash-simple"></i>
                            </base-button>
                          </label>
                          <label class="btn btn-sm btn-primary btn-simple" style="padding-right: 0; padding-left: 0">
                            <base-button
                              @click.native="editGiftCardType(row.item, $event)"
                              class="like btn-link" type="primary"
                              size="sm"
                              icon
                            ><i class="tim-icons icon-pencil"></i>
                            </base-button>
                          </label>
                        </div>
                      </div>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </div>


          <div v-if="activeTabIndex === 2">
            <div class="row">
              <div class="col-lg-4">
                <link v-if="!loading" rel="stylesheet" :href="'https://fonts.googleapis.com/css?family=' + booking_site_settings.font_family_card">
                <card :style="{ 'background-color': booking_site_settings.card_color}">
                  <div slot="image" class="card-img-top" style="padding-bottom: 0; height: 300px">
                    <div :style="{'background-color': gift_card_settings.background_color}" style="border-radius: 5px; width: 100%; height: 300px; position: relative">
                      <img
                        :style="{'transform': 'translate(0%, 0%) scale(' + gift_card_settings.scale*0.01 + ')',
                      'left': gift_card_settings.x + 'px', 'top': gift_card_settings.y + 'px', 'object-fit':  gift_card_settings.object_fit, 'height': '100%'}"
                        class="card-img"
                        style="position: absolute; transform-origin: center center; will-change: transform"
                        slot="image" :src="gift_card_settings.url"  alt="Card image cap"/>
                    </div>
                  </div>


                  <div class="row">
                    <div class="col" :style="{ 'font-family': booking_site_settings.font_family_card,
                                  'border-color': booking_site_settings.button_color,
                                  'color': booking_site_settings.button_color }">
                      <h4 :style="{'font-family': booking_site_settings.font_family_card, 'color': booking_site_settings.text_color_card}">
                        {{ gift_card_settings.title }}
                      </h4>
                      <p :style="{'font-family': booking_site_settings.font_family_card,
                       'color': booking_site_settings.text_color_card}">{{ gift_card_settings.subtext }}</p>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col" :style="{ 'font-family': booking_site_settings.font_family_card,
                                  'border-color': booking_site_settings.button_color,
                                  'color': booking_site_settings.button_color }">
                      <base-button :style="{ 'font-family': booking_site_settings.font_family_card,
                                  'border-color': booking_site_settings.button_color,
                                  'color': booking_site_settings.button_color, 'width': '100%', 'padding-left': '3px',  'padding-right': '3px' }"
                                   simple disabled>
                        {{ gift_card_settings.button_text }}
                      </base-button>
                    </div>
                  </div>
                </card>
              </div>
              <div class="col-lg-4">
                <div class="mb-2">
                  <image-editor text="Upload a new header image" @saveImage="saveImage"></image-editor>
                </div>
                <label>
                  <div>Card title
                    <el-popover trigger="hover"
                                placement="top">

                      <div>
                        <div class="popover-body">This text will appear as the title on the booking site's gift card section</div>
                      </div>
                      <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                    </el-popover>
                  </div>
                </label>

                <base-input v-model="gift_card_settings.title" placeholder="Title"></base-input>

                <label>
                  <div>Card text
                    <el-popover trigger="hover"
                                placement="top">

                      <div>
                        <div class="popover-body">This text will appear as the subtext on the booking site's gift card section</div>
                      </div>
                      <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                    </el-popover>
                  </div>
                </label>

                <base-input  v-model="gift_card_settings.subtext" placeholder="Card text"></base-input>

                <label>
                  <div>Button text
                    <el-popover trigger="hover"
                                placement="top">

                      <div>
                        <div class="popover-body">This text will appear on the button to select gift card purchase</div>
                      </div>
                      <i slot="reference" style="color: #1d8cf8" class="tim-icons el-icon-info"></i>
                    </el-popover>
                  </div>
                </label>

                <base-input  v-model="gift_card_settings.button_text" placeholder="Card text"></base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4">
                <base-button type="primary" :disabled="loading" v-on:click="saveGiftCardSettings()">Save</base-button>
              </div>
            </div>

          </div>
        </card>
      </div>
    </div>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :paginate-elements-by-height="1400"
      filename="gift_card"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"

      @beforeDownload="beforeDownload($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <h1>hello</h1>
        <div class="row justify-content-center">
          <div class="col text-center">
            <h3 class="text-default">Your gift card from {{ group.name }} is here!</h3>
          </div>
          <!--        <p style="font-size: large" class="text-primary"> Gift card </p>-->
          <!--        <p class="text-black-50">{{selectedGiftCard}}</p>-->
          <!--&lt;!&ndash;        <img crossorigin="*" style="object-fit: cover; height: 75px" :src="selectedGiftCard.design_url"/>&ndash;&gt;-->
          <!--        <img style="object-fit: cover; height: 75px" :src="gift_card_img"/>-->

          <!--&lt;!&ndash;        <p class="text-black-50">{{ gift_card_img }}</p>&ndash;&gt;-->
          <!--        <i class="tim-icons icon-cloud-download-93 text-primary"></i>-->


        </div>
        <div class="row justify-content-center" style="background-color: #e3e3e3">
          <div class="col text-center" >
            <img style="object-fit: cover; height: 250px" :src="gift_card_img"/>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col text-center" >
            <div style="font-size: xxx-large" class="mt-4 text-default">{{getAmount(selectedGiftCard.total)}}</div>
            <div style="font-size: x-small" class="text-black-50">Current balance</div>
            <p class="mt-3 text-black-50">{{selectedGiftCard.personal_message}} </p>
          </div>
        </div>

        <hr class="mt-3">
        <div class="row justify-content-center">
          <div class="col-lg-8 text-center" >
            <div style="font-size: x-large" class=" mt-3 text-default">Gift card code</div>
            <small class="text-black-50" style="margin-top: -10px">Provide the gift card code at checkout to redeem the gift card.</small>
            <div style="background-color: #e3e3e3; border-radius: 5px; margin-top: 20px; padding-top: 50px; padding-bottom: 50px">{{ getGiftCardCode(selectedGiftCard.code) }}</div>
          </div>
        </div>
        <hr class="mt-5">
        <div class="row justify-content-center">
          <div class="col text-center" >
            <img style="object-fit: cover; height: 50px" :src="logo"/>
            <p class="text-black-50">{{group.name}}</p>
            <p class="text-black-50">{{group.address_line_1}} {{group.address_line_1}} {{group.city}} {{group.state}} {{group.zip}}</p>
          </div>
        </div>
      </section>
    </vue-html2pdf>

    <!--    Edit GC-->
    <modal :show.sync="modals.edit_gc"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4>Edit gift card</h4>

        <div class="row">
          <div class="col">
            <label>Gift card code</label>
            <p class="text-white">{{getGiftCardCode(selectedGiftCard.code)}}</p>
          </div>
        </div>
        <label>Recipient</label>
        <div class="row" :key="j + 'R'">
          <div class="col-12 col-lg-6">
            <base-input :error="errs.recipient_first_name" v-on:change="j++"
                        v-model="selectedGiftCard.recipient_first_name"
                        placeholder="Recipient's first name"></base-input>
          </div>
          <div class="col-12 col-lg-6" >
            <base-input :error="errs.recipient_last_name"  v-on:change="j++"
                        v-model="selectedGiftCard.recipient_last_name"
                        placeholder="Recipient's last name"></base-input>
          </div>
        </div>
        <div class="row" :key="j + 'RE'">
          <div class="col">
            <base-input :error="errs.recipient_email"  v-on:change="j++"
                        v-model="selectedGiftCard.recipient_email" placeholder="Recipient's email"></base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <base-input label="Activation date" :error="errs.activation_date">
              <el-date-picker
                type="date"
                placeholder="Select date"
                :format="day_of_week + date_format_cal"
                v-model="selectedGiftCard.activation_date"
              >
              </el-date-picker>
            </base-input>
          </div>
          <div class="col-lg-6">
            <base-input label="Expiration date" :error="errs.expiration_date">
              <el-date-picker
                type="date"
                placeholder="Select date"
                :format="day_of_week + date_format_cal"
                v-model="selectedGiftCard.expiration_date"
              >
              </el-date-picker>
            </base-input>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col text-center">
            <base-button type="default" v-on:click="modals.edit_gc = false" class="mr-2">Cancel</base-button>
            <base-button type="primary" :disabled="loading" v-on:click="saveGiftCard()">Save</base-button>
          </div>
        </div>

      </card>
    </modal>

        <!--    GC purchase-->
    <modal :show.sync="modals.new" id="modal"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4>New gift purchase</h4>

        <div class="row justify-content-center">
          <div class="col-lg-8 text-center">
            <el-carousel v-on:change="setGiftCardDesign" :autoplay="false" indicator-position="outside">
              <el-carousel-item v-for="(item, index) in designs" :key="'CS' + index">
                <img style="border: 3px solid white; object-fit: contain; width: 600px; height: 300px" :src="item.url">
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>

        <div class="row mb-2" >
          <div class="col-lg-5">
            <base-radio v-model="gift_card.custom_code" name="0">Automatically generate gift card code</base-radio>
          </div>
          <div class="col-lg-5">
            <base-radio v-model="gift_card.custom_code" name="1">Create a custom gift card code</base-radio>
          </div>
        </div>
        <div class="row" v-if="gift_card.custom_code == 1">
          <div class="col">
            <base-input :error="errs.custom_code" label="Custom code" placeholder="Custom code" v-model="gift_card.code"></base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4">
            <!--          <base-input label="Gift card value" v-on:change="calculateTotals()" placeholder="0.00" v-model="gift_card.total"></base-input>-->
            <base-input :label="'Gift card type'">
              <el-select
                label="Select value"
                v-model="selectedGiftCardType"
                class="select-primary"
                value-key="id"
                v-on:change="selectGiftCardType()"
              >
                <el-option
                  v-for="(gct, index) in gift_card_types"
                  class="select-primary"
                  :label="gct.name"
                  :value="{id: gct.id, name: gct.name,
                    value_type: gct.value_type, designs: gct.designs, price: gct.price,
                     redemption_amount: gct.redemption_amount}"
                  :key="'AMOUNT' + index"
                >
                  <span>{{gct.name}}</span>
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col-lg-4" v-if="selectedGiftCardType.value_type === 0">
            <base-input :label="'Custom value  (' + currency_symbol + ')'"
                        v-on:change="calculateTotals()" placeholder="0.00"
                        v-model.number="gift_card.custom_amount"></base-input>
          </div>

          <div class="col-lg-4" v-if="selectedGiftCardType.value_type === 2">
            <base-input label="Games">
              <el-select
                label="Select game"
                v-model="selectedGame"
                class="select-primary"
                value-key="id"
                v-on:change="calculateGameValue()"
              >
                <el-option
                  v-for="(game, index) in games"
                  class="select-primary"
                  :label="game.name"
                  :value="{id: game.id,
                name: game.name,
                cumulative_category_pricing: game.cumulative_category_pricing,
                game_pricing: game.game_pricing,
                pricing_type_id: game.pricing_type_id,
                pricing_categories: game.pricing_categories}"
                  :key="'GAME' + index"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col-lg-4" v-if="selectedGiftCardType.value_type === 2" :key="gc_key">
            <div v-for="(c, i) in selectedGame.pricing_categories" :key="'C' + i">
              <label class="mt-2 text-uppercase">{{c.category}}</label>
              <base-input>
                <el-input-number @change="calculateGameValue()" v-model="c.number_of_players" :min="0" />
              </base-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <p class="text-white text-uppercase">value ({{currency_symbol}})</p>
            <h2 style="margin-bottom: 0px" class="warning">{{ getAmount(gift_card.unit_value) }}</h2>
          </div>
          <div class="col-lg-4" v-if="selectedGiftCardType.value_type === 1">
            <p class="text-white text-uppercase">price ({{currency_symbol}})</p>
            <h2 style="margin-bottom: 0px" class="warning">{{ getAmount(gift_card.unit_price) }}</h2>
          </div>
          <div class="col-lg-4">
            <p class="text-white text-uppercase">total cost ({{currency_symbol}})</p>
            <h2 style="margin-bottom: 0px" class="warning">{{ getAmount(gift_card.total) }}</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 ">
            <base-input label="Quantity">
              <el-input-number v-on:change="calculateTotals()" v-model="gift_card.quantity" placeholder="1" :min="1"></el-input-number>
            </base-input>
          </div>
        </div>

        <div class="row mb-3" >
          <div class="col-lg-3">
            <base-radio v-on:input="setDeliveryDate()" v-model="gift_card.send_instantly" name="1">Send instantly</base-radio>
          </div>
          <div class="col-lg-4">
            <base-radio v-model="gift_card.send_instantly" name="0">Send on a future date</base-radio>
          </div>
        </div>

        <div class="row mt-2 justify-content-between" v-if="gift_card.send_instantly == 0">
          <div class="col-lg-6">
            <base-input label="Delivery date">
              <el-date-picker
                class="custom-cursor"
                type="date"
                placeholder="Select date"
                value-format="yyyy-MM-dd"
                :format="day_of_week + '' + date_format_cal"
                v-model="gift_card.delivery_date"
                :picker-options="{ disabledDate: (time) => disabledDates(time, gift_card.delivery_date) }"
              >
              </el-date-picker>
            </base-input>
          </div>
        </div>

        <!--      <label>Recipient</label>-->
        <div class="row mb-3">
          <div class="col">
            <base-checkbox type="checkbox"
                           v-model="send_gift_card_to_self"> Purchasing customer is the recipient
            </base-checkbox>
          </div>
        </div>
        <div class="row justify-content-center" v-if="!send_gift_card_to_self">
          <div class="col-12 col-lg-6">
            <base-input :error="errs.recipient_first_name" v-model="gift_card.recipient_first_name" placeholder="Recipient's first name"></base-input>
          </div>
          <div class="col-12 col-lg-6" >
            <base-input :error="errs.recipient_last_name" v-model="gift_card.recipient_last_name" placeholder="Recipient's last name"></base-input>
          </div>
        </div>
        <div class="row justify-content-center" v-if="!send_gift_card_to_self">
          <div class="col">
            <base-input :error="errs.recipient_email" v-model="gift_card.recipient_email" placeholder="Recipient's email"></base-input>
          </div>
        </div>

        <div class="row  justify-content-center">
          <div class="col">
            <base-input label="Personal message">
              <textarea class="form-control" placeholder="Add your personal message..." v-model="gift_card.personal_message" rows="2"></textarea>
            </base-input>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col">
            <base-checkbox v-model="no_confirmation_email">Do not send booking confirmation email</base-checkbox>
          </div>
          <div class="col text-right">
            <base-checkbox v-model="waive_fee" v-on:input="calculateTotals()">Waive fees</base-checkbox>
          </div>
        </div>

        <div class="row mt-2" v-if="!override_price">
          <div class="col">
            <base-button type="primary" simple size="sm" v-on:click="override_price = true">Override price</base-button>
          </div>
        </div>
        <div class="row mt-2" v-if="override_price">
          <div class="col-lg-4">
            <base-input :label="'Price (' + currency_symbol + ')' " v-model.number="new_price"></base-input>
            <base-button type="default" simple size="sm" v-on:click="cancelOverridePrice()">Cancel</base-button>
            <base-button type="primary" class="ml-2" size="sm" v-on:click="updatePrice()">Override</base-button>
          </div>
        </div>

        <div class="row justify-content-end">
          <div class="col text-right">
            <hr style="border-top: 1px solid #f4f5f7;">
          </div>
        </div>

        <div class="row justify-content-end">
          <div class="col" >
            <div class="row">
              <div class="col-9">
                <p class="text-white"><span class="text-uppercase">
                  Subtotal</span>
                </p>
              </div>
              <div class="col text-right">
                <p class="text-white"><span class="text-uppercase">
                    {{getAmount(subtotal)}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-end" v-if="!waive_fee && subtotal > 0" v-for="(f, i) in fees" :key="'F' + i">
          <div class="col" >
            <div class="row">
              <div class="col-9">
                <p class="text-white"><span class="text-uppercase">
                  {{f.name}}</span>
                </p>
              </div>
              <div class="col text-right">
                <p class="text-white"><span class="text-uppercase">
                   {{getAmount(getFee(f))}}</span>
                </p>
              </div>
            </div>

          </div>
        </div>

        <div class="row justify-content-end" v-show="subtotal > 0" v-for="(p, i) in promos" :key="pc_key + i">
          <div class="col" >
            <div class="row">
              <div class="col-9">
                <p class="text-white"><span class="text-uppercase">
                      {{p.name}}</span>
                  <base-button @click="removePromoCode(p)" style="padding: 0; margin: 0" type="danger" icon class="btn-link d-inline">
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>

                </p>
              </div>
              <div class="col text-right">
                <p class="text-white"><span class="text-uppercase">
                         -{{getAmount(getDiscount(p))}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-end" v-if="subtotal > 0">
          <div class="col text-right">
            <hr style="border-top: 1px solid #f4f5f7;">
          </div>
        </div>
        <div class="row justify-content-end" v-if="subtotal > 0">
          <div class="col">
            <div class="row">
              <div class="col">
                <p class="text-white"><span class="text-uppercase">
                  Total: </span>
                </p>
              </div>
              <div class="col text-right">
                <p class="text-white"><span class="text-uppercase">
                      {{getAmount(total)}}</span>
                </p>
                <!--               -->
                <!--                <p v-if="!waive_tax && waive_fee" class="text-white"><span class="text-uppercase">-->
                <!--                      {{getAmount(getTotal('edit') - getFees())}}</span>-->
                <!--                </p>-->
                <!--                <p v-if="waive_tax && waive_fee" class="text-white"><span class="text-uppercase">-->
                <!--                      {{getAmount(getTotal('edit') - getTaxes() - getFees())}}</span>-->
                <!--                </p>-->
              </div>
            </div>

          </div>
        </div>

        <label>Promo code</label>
        <div class="row">
          <div class="col-lg-8" style="display: flex; width: 100%;">
            <base-input style="flex-grow: 1; border-radius: 0px;">
              <el-autocomplete style="width: 100%"
                               v-model="promo_code"
                               :fetch-suggestions="promoSearch"
                               placeholder="Search promo..."
                               :trigger-on-focus="false"
                               @select="handlePromoSelect"
              >
                <template #default="{ item }">
                  <div class="row">
                    <div class="col">
                      <div ><span class="text-primary">{{ item.name }} - </span>
                        <small>
                          <span v-if="item.redemption_type === 0">{{item.value_amount}}%</span>
                          <span v-if="item.redemption_type === 1">{{getAmount(item.value_amount)}}</span>
                          <span v-if="item.value_type === 0"> per transaction</span>
                          <span v-if="item.value_type === 2"> per participant</span>
                          <span class="ml-2"
                                style="text-transform: uppercase; line-height: 9px; text-decoration: none; border: none;
                            color: white; background-color: #344675; border-radius: 2px; padding: 2px 5px 2px 5px; font-size: 9px"
                                v-if="item.internal_only">internal only</span>
                        </small>
                      </div>
                    </div>
                  </div>
                </template>
              </el-autocomplete>
            </base-input>
            <base-button v-on:click="applyPromoCode()" class="mt-0 ml-2" style="flex-grow: 0; margin-bottom: 10px" type="primary">Apply</base-button>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <base-input label="Customer lookup (search by name, email, or phone)">
              <el-autocomplete style="width: 100%"
                               v-model="lookup_value"
                               :fetch-suggestions="querySearch"
                               placeholder="Search"
                               :trigger-on-focus="false"
                               @select="handleSelect"
              >
                <template #default="{ item }">
                  <p style="margin-bottom: -12px" class="text-primary">{{ item.first_name }} {{ item.last_name }}</p>
                  <small class="font-italic">
                    <i class="tim-icons icon-email-85"></i> {{item.email }} &nbsp;
                    <i v-if="item.phone" class="tim-icons el-icon-mobile-phone"></i> {{item.phone }}
                  </small>
                </template>
              </el-autocomplete>
            </base-input>
          </div>
          <div class="col-lg-3 col-md-3 col-4">
            <label class="mt-5"></label>
            <base-button simple type="primary" v-on:click="clearCustomer()">Clear</base-button>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <base-input :error="errs.first_name" label="First name" v-model="user.first_name" placeholder="First name"></base-input>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <base-input :error="errs.last_name" label="Last name" v-model="user.last_name" placeholder="Last name"></base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <base-input :error="errs.email" label="Email" v-model="user.email" placeholder="Email"></base-input>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <base-input label="Phone (Optional)" v-model="user.phone" :placeholder="country.phone_placeholder"></base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <base-input label="Payment options" :error="errs.payment_option">
              <el-select
                         class="select-primary"
                         v-model="selectedPaymentOption"
                         placeholder="Option"
                         value-key="id"
              >
                <el-option
                  class="select-primary"
                  v-for="(item, index) in paymentOptions"
                  :key="index + 'P'"
                  :label="item.name"
                  :value="{id: item.id, name: item.name, content: item.content, is_default: item.is_default}"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>
        </div>

        <div class="row" v-if="!selectedPaymentOption.is_default">
          <div class="col">
            <div v-html="selectedPaymentOption.content"></div>
          </div>
        </div>

        <div class="row" v-show="selectedPaymentOption.name === 'Customer credit'">
          <div class="col-lg-3 col-md-3 col-6">
            <base-input label="Available ($)" v-model="available_credit" disabled></base-input>
          </div>
          <div class="col-lg-3 col-md-3 col-6">
            <base-input label="Amount to apply($)" v-model="applied_credit"></base-input>
          </div>
        </div>

        <div class="row" v-show="selectedPaymentOption.name === 'Gift card' && selectedGiftCardCode">
          <div class="col-lg-4 col-md-3 col-6">
            <base-input label="Available balance ($)" v-model="selectedGiftCardCode.remaining" disabled></base-input>
          </div>
        </div>

        <div class="row mb-3" v-show="selectedPaymentOption.name === 'Gift card'">
          <div class="col">
            <base-input label="Gift card code" :error="errs.gift_card_code">
              <el-autocomplete style="width: 100%"
                               v-model="gift_card_code"
                               :fetch-suggestions="giftCardSearch"
                               placeholder="Search by code..."
                               :trigger-on-focus="false"
                               @select="handleGiftCardSelect"
              >
                <template #default="{ item }">
                  <div class="row">
                    <div class="col">
                      <p style="margin-bottom: -12px" class="text-primary">{{ getGiftCardCode(item.code) }}</p>
                      <small class="font-italic">
                        <i class="tim-icons icon-money-coins"></i> Total {{getAmount(item.total)}} Remaining {{getAmount(item.remaining)}}
                      </small>
                      <br/>
                    </div>
                  </div>
                </template>
              </el-autocomplete>
            </base-input>
          </div>
        </div>

        <!--            Authorize.net-->
        <div class="row" v-if="selectedPaymentOption.name === 'Card' && settings.payment_gateway_id === 1">
          <div class="col-12">
            <base-input :error="errs.ccn" v-mask="'#### #### #### ####'" placeholder="4444 4444 4444 4444" v-model="payment.cardNumber" label="Credit card number"></base-input>
          </div>

        </div>
        <div class="row mb-3" v-if="selectedPaymentOption.name === 'Card' && settings.payment_gateway_id === 1">
          <div class="col-4">
            <base-input :error="errs.zip" :placeholder="country.zip_placeholder" v-model="payment.zip" type="text" :label="country.zip_label"></base-input>
          </div>
          <div class="col-4">
            <base-input :error="errs.expiration" v-mask="'##/##'" placeholder="11/27" v-model="payment.expirationDate" label="Expiration"></base-input>
          </div>
          <div class="col-4">
            <base-input :error="errs.cvv" v-mask="'####'" placeholder="111" v-model="payment.cardCode" type="tel" label="CVV"></base-input>
          </div>
        </div>

        <!--                  Square-->
        <div class="row" v-show="selectedPaymentOption.name === 'Card' && settings.payment_gateway_id === 2">
          <div class="col-12">
            <form id="payment-form-square" >
              <div id="card-container"></div>
            </form>
            <div id="payment-status-container"></div>
          </div>
        </div>

        <!--        Stripe-->
        <div class="row mb-5" v-show="selectedPaymentOption.name === 'Card' && settings.payment_gateway_id === 3">
          <div class="col">
            <form id="payment-form">
              <label for="card-element">
                Credit or debit card
              </label>
              <div id="card-element">
                <!-- A Stripe Element will be inserted here. -->
              </div>
              <div id="card-errors" role="alert"></div>
            </form>
          </div>
        </div>

        <div class="row text-center">
          <div class="col">
            <base-button type="secondary" @click="modals.new = false">Cancel</base-button>
            <base-button class="ml-3" type="primary" :disabled="loading" @click="createGiftPurchase()">Create</base-button>
          </div>
        </div>
      </card>
    </modal>


  </div>
</template>

<script>
import {Auth} from "aws-amplify";
import currencies from "@/assets/currencies.json";
import {API_LOCATION} from "@/api-config";
import {BTable, BPagination, BIcon, BIconFilter, BIconArrowCounterclockwise} from 'bootstrap-vue'
import {dayOfWeek, formattedDate, time} from "@/plugins/dateFormatter";
import axios from "axios";
import {Badge, BaseAlert} from '@/components';
import swal from "sweetalert2";
import VueHtml2pdf from 'vue-html2pdf'
import {CarouselItem, Carousel, Autocomplete, Switch, Dropdown, DropdownMenu, DropdownItem} from "element-ui";
import _ from "lodash";
import countries from "@/assets/countries.json";
import ImageEditor from "@/components/ImageEditor";
import moment from "moment"

export default {
  name: "GiftCards",
  components: {
    ImageEditor,
    BTable,
    BPagination,
    Badge,
    VueHtml2pdf,
    [CarouselItem.name]: CarouselItem,
    [Carousel.name]: Carousel,
    [Autocomplete.name]: Autocomplete,
    [Switch.name]: Switch,
    BIcon,
    BIconFilter,
    BIconArrowCounterclockwise,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    BaseAlert
  },
  data() {
    return {
      booking_site_settings: '',
      gift_card_settings: {
        title: '',
        image_id: '',
        subtext: '',
        button_text: ''
      },
      send_gift_card_to_self: false,
      games: [],
      new_price: 0,
      number_of_players: 0,
      override_price: false,
      show_filters: false,
      squareAppId: '',
      selectedGiftCardCode: '',
      customer_credits: 0,
      available_credit: 0,
      applied_credit: 0,
      gift_card_code: '',
      squareLocationId: '',
      payment: {
        cardNumber: '',
        expirationDate: '',
        cardCode: '',
        zip: ''
      },
      date_format_cal: '',
      paymentOptions: [],
      selectedPaymentOption: {name: '', content: '', id: '', is_default: ''},
      gc_key: 0,
      gift_card: {
        type: '',
        custom_code: 0,
        code: '',
        personal_message: '',
        design_url: '',
        custom_amount: '',
        recipient_first_name: '',
        recipient_last_name: '',
        recipient_email: '',
        total: {amount: 0},
        quantity: 1,
        send_instantly: 1,
        delivery_date: new Date(),
        gift_card_type_id: '',
        unit_value: 0,
        unit_price: 0
      },
      settings: '',
      amounts: [],
      designs: [],
      no_confirmation_email: false,
      selected_gift_card_amount: '',
      selectedGame: '',
      modals: {
        new: false,
        edit_gc: false
      },
      selectedGiftCard: '',
      gift_card_img: '',
      logo: '',
      country: '',
      countries: countries.countries,
      currencies: currencies.currencies,
      number_format: JSON.parse(localStorage.getItem('group')).number_format,
      currency_symbol: '$',
      currency: JSON.parse(localStorage.getItem('group')).currency,
      currency_format: JSON.parse(localStorage.getItem('group')).currency_format,
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      group: JSON.parse(localStorage.getItem('group')),
      day_of_week: '',
      gift_purchases: [],
      user: {
        first_name: '',
        last_name: '',
        email: '',
        phone: ''
      },
      loading: false,
      perPageOptions: [5, 10, 25, 50],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      filters: {
        customer: {
          value: '',
          actual: ''
        },
        recipient: {
          value: '',
          actual: ''
        },
        status: {
          value: '',
          actual: ''
        },
        code: {
          value: '',
          actual: ''
        },
      },
      waive_fee: false,
      fees: [],
      subtotal: 0,
      total: 0,
      gift_card_designs: [],
      lookup_value: '',
      promo_code: '',
      selectedPromoCode: '',
      promos: [],
      pc_key: 0,
      errs: {
        payment_option: '',
        first_name: '',
        last_name: '',
        email: '',
        payment_amount: '',
        partial_amount: '',
        recipient_first_name: '',
        recipient_last_name: '',
        recipient_email: '',
        custom_code: '',
        value: '',
        ccn: '',
        cvv: '',
        zip: '',
        expiration: '',
        activation_date: ''
      },
      j: 0,
      activeTabIndex: 0,
      gift_card_types: [],
      selectedGiftCardType: '',
      periods: [
        {id: 0, name: 'minute(s)'},
        {id: 1, name: 'hour(s)'},
        {id: 2, name: 'day(s)'},
        {id: 3, name: 'week(s)'},
        {id: 4, name: 'month(s)'},
        {id: 5, name: 'years(s)'}
      ],
    }
  },
  computed: {
    categories() {
      return [{name: 'Gift card purchases', short_name: 'Purchases', icon: 'tim-icons icon-gift-2', active: true},
        {name: 'Gift card types', short_name: 'Types', icon: 'tim-icons icon-settings-gear-63', active: false},
        {name: 'Gift card settings', short_name: 'Settings', icon: 'tim-icons icon-settings-gear-63', active: false}
      ];
    },
  },
  methods: {
    isEmailValid(emailString) {
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegex.test(emailString);
    },
    validateGiftCardFields(){
      let errors = 0
      if(!this.selectedGiftCard.activation_date ||
        !(moment(this.selectedGiftCard.activation_date, 'YYYY-MM-DD').isValid())){
        this.errs.activation_date = "Activation date cannot be empty"
        errors++
      } else {
        this.errs.activation_date = ""
      }

      if(!this.selectedGiftCard.recipient_first_name){
        this.errs.recipient_first_name = "First name cannot be empty"
        errors++
      } else {
        this.errs.recipient_first_name = ""
      }

      if(!this.selectedGiftCard.recipient_last_name){
        this.errs.recipient_last_name = "Last name cannot be empty"
        errors++
      } else {
        this.errs.recipient_last_name = ""
      }

      if(!this.isEmailValid(this.selectedGiftCard.recipient_email)){
        this.errs.recipient_email = "Invalid email"
        errors++
      } else {
        this.errs.recipient_email = ""
      }

      return  errors > 0 ? false : true
    },
    async saveGiftCard(){
      if(!this.validateGiftCardFields()){
        return
      }

      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/gifts/' + this.selectedGiftCard.id

      let data = {
        activation_date: moment(this.selectedGiftCard.activation_date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        expiration_date: moment(this.selectedGiftCard.expiration_date, 'YYYY-MM-DD').isValid()
          ? moment(this.selectedGiftCard.expiration_date, 'YYYY-MM-DD').format('YYYY-MM-DD')
          : null,
        recipient_first_name: this.selectedGiftCard.recipient_first_name,
        recipient_last_name: this.selectedGiftCard.recipient_last_name,
        recipient_email: this.selectedGiftCard.recipient_email
      }

      axios.put(url, data, config)
        .then(response => {
          swal("Success", "Gift card has been updated", "success")
          this.modals.edit_gc = false
          this.getGiftPurchases()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    editGiftCardPrompt(item, event){
      event.preventDefault()
      this.selectedGiftCard = {...item}
      let recipients = JSON.parse(item.recipients)

      this.selectedGiftCard.recipient_first_name = recipients.length > 0 ? recipients[0].first_name : ''
      this.selectedGiftCard.recipient_last_name = recipients.length > 0 ? recipients[0].last_name : ''
      this.selectedGiftCard.recipient_email = recipients.length > 0 ? recipients[0].email : ''

      this.selectedGiftCard.activation_date = moment(this.selectedGiftCard.activation_date, 'YYYY-MM-DD')
      this.selectedGiftCard.expiration_date = moment(this.selectedGiftCard.expiration_date, 'YYYY-MM-DD').isValid()
        ? moment(this.selectedGiftCard.expiration_date, 'YYYY-MM-DD') : null
      this.modals.edit_gc = true
    },
    async saveImage(value){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/images'

      let data = {
        url: value.url,
        scale: value.scale,
        description: value.description,
        background_color: value.background_color,
        x: value.x,
        y: value.y,
        is_primary: 0,
        object_fit: value.object_fit
      }

      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.gift_card_settings.image_id = response.data
          this.gift_card_settings.url = value.url
          this.gift_card_settings.x = value.x
          this.gift_card_settings.y = value.y
          this.gift_card_settings.scale = value.scale
          this.gift_card_settings.object_fit = value.object_fit
          this.gift_card_settings.background_color = value.background_color
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    selectGiftCardType(){
      this.designs = this.selectedGiftCardType ? JSON.parse(this.selectedGiftCardType.designs) : []
      this.gift_card.design_url = this.designs.length > 0 ? this.designs[0] : ''


      if(this.selectedGiftCardType.value_type === 0 || this.selectedGiftCardType.value_type === 2){
        this.gift_card.custom_amount = ''
        this.gift_card.unit_value = 0
        this.gift_card.total = 0
        this.subtotal = 0
        this.total = 0
      }

      if(this.selectedGiftCardType.value_type === 1){
        this.calculateTotals()
      }
      if(this.selectedGiftCardType.value_type === 2){
        this.selectedGame = this.games[0]
        this.calculateGameValue()
      }
      if(!this.selectedGiftCardType){
        this.gift_card.total = 0
      }

      this.gc_key++
    },
    getPeriodName(period_id){
      let period = _.find(this.periods, {id: period_id})
      return period ? period.name : ''
    },
    activateGiftCardTypePrompt(item, event, action){
      event.preventDefault()
      let action_type = 'activated and will immediately appear on the booking site'
      if(action === 0){
        action_type = 'permanently deactivated and will no longer be available on the booking site'
      }
      swal({
        title: 'Are you sure?',
        text: `The gift card type will be ` + action_type,
        type: 'warning',
        showCancelButton: true,
      }).then((response)=> {
        if(response.value == true){
          this.activateGiftCardType(item, action, action_type)
        }
      })
    },
    async activateGiftCardType(item, action, action_type){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/gift-card-types/' + item.id + '/activate'
      this.loading = true
      let data = {
        action: action
      }
      this.axios.put(url, data, config)
        .then(response => {
          swal('Success', 'Gift card type has been '  + action_type, 'success')
          this.getGiftCardTypes()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    archiveGiftCardTypePrompt(item, event){
      event.preventDefault()
      swal({
        title: 'Are you sure?',
        text: `The gift card type will be permanently archive and will no longer be available on the booking site`,
        type: 'warning',
        showCancelButton: true,
      }).then((response)=> {
        if(response.value == true){
          this.archiveGiftCardType(item)
        }
      })
    },
    async archiveGiftCardType(item){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/gift-card-types/' + item.id
      this.loading = true
      this.axios.delete(url, config)
        .then(response => {
          swal('Success', 'Gift card type has been archived', 'success')
          this.getGiftCardTypes()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    editGiftCardType(item, event){
      event.preventDefault()
      this.$router.push({ path: '/purchases/gift-cards/settings', query: { id:  item.id }})
    },
    newGiftCardType(){
      this.$router.push('/purchases/gift-cards/settings')
    },
    switchTab(index){
      this.activeTabIndex = index
      for(let n in this.categories){
        this.categories[n].active = false
      }
      this.categories[index].active = true
      this.loadData()
      this.j++
    },
    async giftCardSearch(queryString, cb){
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/gifts/autocomplete?q=' + encodeURIComponent(queryString)
      axios.get(url, config)
        .then(response => {
          let suggestions = []
          for(let n in response.data.gift_cards){
            if(response.data.gift_cards[n].code != null){
              suggestions.push({
                value: JSON.stringify(response.data.gift_cards[n]),
                id: response.data.gift_cards[n].id,
                code: response.data.gift_cards[n].code,
                customer_id: response.data.gift_cards[n].customer_id,
                date_purchased: response.data.gift_cards[n].date_purchased,
                email: response.data.gift_cards[n].email,
                first_name: response.data.gift_cards[n].first_name,
                last_name: response.data.gift_cards[n].last_name,
                remaining: response.data.gift_cards[n].remaining,
                total: response.data.gift_cards[n].total
              })
            }

          }
          const results = suggestions

          cb(results)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    handleGiftCardSelect(item){
      this.gift_card_code = this.getGiftCardCode(item.code)
      this.selectedGiftCardCode = item
    },
    hasPermissions(scope){
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      const matchFound = permissions.some(element => element === scope);
      return matchFound
    },
    calculateGameValue(){
      this.gift_card.unit_value = 0
      this.number_of_players = 0
      if(this.selectedGame.cumulative_category_pricing){
        if(this.selectedGame.pricing_type_id === 1) {
          for (let m in this.selectedGame.pricing_categories) {
            this.number_of_players += this.selectedGame.pricing_categories[m].number_of_players
          }
          for (let m in this.selectedGame.pricing_categories) {
            for(let n in this.selectedGame.game_pricing){
              if(this.selectedGame.game_pricing[n].category_id === this.selectedGame.pricing_categories[m].category_id
                && this.number_of_players >= this.selectedGame.game_pricing[n].min_players
                && this.number_of_players <= this.selectedGame.game_pricing[n].max_players) {
                this.gift_card.unit_value += this.selectedGame.game_pricing[n].price * this.selectedGame.pricing_categories[m].number_of_players
                break
              }
            }
            let last_index = _.findLastKey(this.selectedGame.game_pricing, {category_id: this.selectedGame.pricing_categories[m].category_id})
            if(this.number_of_players > this.selectedGame.game_pricing[last_index].max_players){
              this.gift_card.unit_value += this.selectedGame.game_pricing[last_index].price * this.selectedGame.pricing_categories[m].number_of_players
            }
            let smallest_price_category = _.find(this.selectedGame.game_pricing, {category_id : this.selectedGame.pricing_categories[m].category_id})
            if(this.number_of_players !== 0 &&
              this.number_of_players < smallest_price_category.min_players){
              this.gift_card.unit_value += smallest_price_category.price * this.selectedGame.pricing_categories[m].number_of_players
            }
          }
        }
        else if(this.selectedGame.pricing_type_id === 2){
          for (let m in this.selectedGame.pricing_categories) {
            this.number_of_players += this.selectedGame.pricing_categories[m].number_of_players
          }
          for(let m in this.selectedGame.pricing_categories){
            for(let n in this.selectedGame.game_pricing){
              if(this.selectedGame.game_pricing[n].category_id === this.selectedGame.pricing_categories[m].category_id
                && this.number_of_players >= this.selectedGame.game_pricing[n].min_players
                && this.number_of_players <= this.selectedGame.game_pricing[n].max_players) {
                this.gift_card.unit_value += this.selectedGame.game_pricing[n].price
                break
              }
            }
            let last_index = _.findLastKey(this.selectedGame.game_pricing, {category_id: this.selectedGame.pricing_categories[m].category_id})
            if(this.number_of_players > this.selectedGame.game_pricing[last_index].max_players){
              this.gift_card.unit_value += this.selectedGame.game_pricing[last_index].price
            }

            let smallest_price_category = _.find(this.selectedGame.game_pricing, {category_id : this.selectedGame.pricing_categories[m].category_id})
            if(this.number_of_players !== 0 &&
              this.number_of_players < smallest_price_category.min_players){
              this.gift_card.unit_value += smallest_price_category.price
            }
          }
        }
      } else {
        if(this.selectedGame.pricing_type_id === 1){
          for(let m in this.selectedGame.pricing_categories){

            this.number_of_players += this.selectedGame.pricing_categories[m].number_of_players
            for(let n in this.selectedGame.game_pricing){
              if(this.selectedGame.game_pricing[n].category_id === this.selectedGame.pricing_categories[m].category_id
                && this.selectedGame.pricing_categories[m].number_of_players >= this.selectedGame.game_pricing[n].min_players
                && this.selectedGame.pricing_categories[m].number_of_players <= this.selectedGame.game_pricing[n].max_players) {
                this.gift_card.unit_value += this.selectedGame.game_pricing[n].price * this.selectedGame.pricing_categories[m].number_of_players
                break
              }
            }
            let last_index = _.findLastKey(this.selectedGame.game_pricing, {category_id: this.selectedGame.pricing_categories[m].category_id})
            if(this.selectedGame.pricing_categories[m].number_of_players > this.selectedGame.game_pricing[last_index].max_players){
              this.gift_card.unit_value += this.selectedGame.game_pricing[last_index].price * this.selectedGame.pricing_categories[m].number_of_players
            }

            let smallest_price_category = _.find(this.selectedGame.game_pricing, {category_id : this.selectedGame.pricing_categories[m].category_id})
            if(this.selectedGame.pricing_categories[m].number_of_players !== 0 &&
              this.selectedGame.pricing_categories[m].number_of_players < smallest_price_category.min_players){
              this.gift_card.unit_value += smallest_price_category.price * this.selectedGame.pricing_categories[m].number_of_players
            }
          }
        }
        else if(this.selectedGame.pricing_type_id === 2){
          for(let m in this.selectedGame.pricing_categories){
            this.number_of_players += this.selectedGame.pricing_categories[m].number_of_players
            for(let n in this.selectedGame.game_pricing){
              if(this.selectedGame.game_pricing[n].category_id === this.selectedGame.pricing_categories[m].category_id
                && this.selectedGame.pricing_categories[m].number_of_players >= this.selectedGame.game_pricing[n].min_players
                && this.selectedGame.pricing_categories[m].number_of_players <= this.selectedGame.game_pricing[n].max_players) {
                this.gift_card.unit_value += this.selectedGame.game_pricing[n].price
                break
              }
            }
            let last_index = _.findLastKey(this.selectedGame.game_pricing, {category_id: this.selectedGame.pricing_categories[m].category_id})
            if(this.selectedGame.pricing_categories[m].number_of_players > this.selectedGame.game_pricing[last_index].max_players){
              this.gift_card.unit_value += this.selectedGame.game_pricing[last_index].price
            }

            let smallest_price_category = _.find(this.selectedGame.game_pricing, {category_id : this.selectedGame.pricing_categories[m].category_id})
            if(this.selectedGame.pricing_categories[m].number_of_players !== 0 &&
              this.selectedGame.pricing_categories[m].number_of_players < smallest_price_category.min_players){
              this.gift_card.unit_value += smallest_price_category.price
            }
          }
        }
      }

      this.calculateTotals()

    },
    getLabel(amount){
      if(amount.id === 0 || amount.id === 1){
        return amount.name
      } else {
        return this.currency_symbol + this.roundToFixed(amount.amount, 2)
      }
    },
    removePromoCode(item){
      _.remove(this.promos, {
        id: item.id
      });
      this.pc_key++
      this.calculateTotals()
    },
    getDiscount(promo){
      let discount = 0
      if(promo.value_type === 0){
        if(promo.redemption_type === 1){
          discount = this.roundToFixed(promo.original_amount, 2)
          if(discount > this.subtotal){
            discount = this.roundToFixed(this.subtotal, 2)
          }
        } else {
          discount = this.getPercentage(promo.value_amount, this.subtotal)
        }
      }
      // Per participant
      else if (promo.value_type === 2){
        if(promo.redemption_type === 1){
          discount = promo.original_amount * this.number_of_players
          if(discount > this.subtotal){
            discount = this.subtotal
          }
        } else {
          discount = this.getPercentage(promo.value_amount, this.subtotal)
        }
      }

      if(discount > this.subtotal){
        discount = this.roundToFixed(this.subtotal, 2)
      }
      return discount
    },
    applyPromoCode(){
      if(this.selectedPromoCode.hasOwnProperty('id')){
        let dupe = 0
        for(let n in this.promos){
          if(this.selectedPromoCode.id === this.promos[n].id){
            dupe++
          }

          if(this.promos[n].is_combineable === 0 || this.selectedPromoCode.is_combineable === 0){
            swal('Error', 'You cannot apply multiple non-combineable promo codes', 'error')
            return
          }
        }
        if(dupe === 0){
          this.selectedPromoCode.original_amount = this.selectedPromoCode.value_amount
          if(this.selectedPromoCode.value_amount > this.subtotal && this.selectedPromoCode.redemption_type === 1){
            //get the value of taxes that are applied after discount
            this.selectedPromoCode.value_amount = this.subtotal
          }
          this.promos.push(this.selectedPromoCode)
        }
      }
      this.promo_code = ''

      this.calculateTotals()
    },
    getTotalDiscount(){
      let total_discount = 0
      for(let n in this.promos){
        total_discount = total_discount + parseFloat(this.getDiscount(this.promos[n]))
      }
      return total_discount
    },
    async promoSearch(queryString, cb){
      let purchase_date = new Date()
      purchase_date = purchase_date.getFullYear() +  "-" +  ('0' + (purchase_date.getMonth() + 1)).slice(-2) + "-" + ('0' + purchase_date.getDate()).slice(-2);

      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/promos?autocomplete=' + encodeURIComponent(queryString) + `&purchase_date=` + purchase_date + `&applicable_to_gift_cards=1`

      axios.get(url, config)
        .then(response => {
          let suggestions = []

          for(let n in response.data.promos){
            if(response.data.promos[n].name != null){
              suggestions.push({
                value: JSON.stringify(response.data.promos[n]),
                id: response.data.promos[n].id,
                name: response.data.promos[n].name,
                description: response.data.promos[n].description,
                value_amount: response.data.promos[n].value_amount,
                redemption_type: response.data.promos[n].redemption_type,
                value_type: response.data.promos[n].value_type,
                internal_only: response.data.promos[n].internal_only,
                is_combineable: response.data.promos[n].is_combineable
              })
            }

          }
          const results = queryString
            ? suggestions.filter(this.createFilter(queryString))
            : suggestions;

          cb(results)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    handlePromoSelect(item){
      this.promo_code = item.name
      this.selectedPromoCode = item
    },
    async initializeStripe(){
      this.stripe = window.Stripe(this.settings.api_name);
      this.elements = this.stripe.elements();

      const style = {
        base: {
          fontSize: '16px',
          fontFamily: 'Poppins, sans-serif',
          color: localStorage.getItem('mode') === '0' ? '#344675' : '#ffffff'
        },
      };

      // Create an instance of the card Element.
      this.card = this.elements.create('card', {style});
      // Add an instance of the card Element into the `card-element` <div>.
      this.card.mount('#card-element');
    },
    async loadSquare(is_live){
      const script = document.createElement('script');
      if(is_live){
        script.src = 'https://web.squarecdn.com/v1/square.js'
      } else {
        script.src = 'https://sandbox.web.squarecdn.com/v1/square.js'
      }
      script.async = true;
      document.body.appendChild(script);
    },
    async initializeSquare(){
      if (!window.Square) {
        throw new Error('Square.js failed to load properly');
      }
      if(this.card){
        await this.card.destroy()
      }
      this.square = window.Square.payments(this.squareAppId, this.squareLocationId);
      try {
        this.card = await this.initializeCard(this.square);
      } catch (e) {
        console.error('Initializing Card failed', e);
        return;
      }
    },
    async initializeCard(payments) {
      const customStyle = {
        '.input-container.is-focus': {
          borderColor: '#e14eca',
        },
        '.input-container.is-error': {
          borderColor: '#ff1600',
        },
        '.message-text.is-error': {
          color: '#ff1600',
        },
        '.message-icon.is-error': {
          color: '#ff1600',
        },
        'input.is-error': {
          color: '#ff1600',
        },
        '.message-icon': {
          color: '#adb5bd',
        },
        '@media screen and (max-width: 600px)': {
          'input': {
            'fontSize': '12px',
          }
        },
        '.input-container': {
          borderRadius: '6px',
        },
        '.message-text': {
          color: '#adb5bd',
        },
        input: {
          backgroundColor: localStorage.getItem('mode') === '0' ? '#ffffff' : '#27293d',
          color: localStorage.getItem('mode') === '0' ? '#344675' : '#ffffff',
        },
        'input::placeholder': {
          color: '#adb5bd',
        },
      }
      const card = await payments.card({
        style: customStyle,
      });
      await card.attach('#card-container');
      return card;
    },
    setDeliveryDate(){
      this.gift_card.delivery_date = new Date()
    },
    clearCustomer(){
      this.lookup_value = ''
      this.user = {
        first_name: '',
        last_name: '',
        email: '',
        phone: ''
      }
    },
    async querySearch(queryString, cb) {
      //make API call to /customers with queryString as filter
      var token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/customers?count=10000000&offset=0&first_name=&last_name=&email=&phone=&game=&tag=&autocomplete=' + encodeURIComponent(queryString)
      axios.get(url, config)
        .then(response => {
          let suggestions = []
          for(let n in response.data.customers){
            if(response.data.customers[n].email != null
              && response.data.customers[n].first_name != null
              && response.data.customers[n].first_name != ''){
              suggestions.push({
                value: JSON.stringify(response.data.customers[n]),
                email: response.data.customers[n].email,
                id: response.data.customers[n].id,
                end_user_id: response.data.customers[n].end_user_id,
                first_name: response.data.customers[n].first_name,
                last_name: response.data.customers[n].last_name,
                phone: response.data.customers[n].phone
              })
            }

          }
          const results = suggestions;

          cb(results)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    createFilter(queryString) {
      return (suggestion) => {
        return suggestion.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1;
      };
    },
    handleSelect(item){
      this.lookup_value = item.email
      this.user.first_name = item.first_name
      this.user.last_name = item.last_name
      this.user.email = item.email
      this.user.phone = item.phone
      this.getCredits(item.end_user_id)
    },
    setGiftCardDesign(index){
      this.gift_card.design_url = this.designs[index]
    },
    disabledDates(time) {
      let date = new Date();
      let previousDate = date.setDate(date.getDate() - 1);
      return time.getTime() < previousDate;
    },
    getFee(f){
      let total_fee = 0
      //value(total)
      if(f.value_type === 0){
        total_fee = f.amount
      }
      //value (per person)
      if(f.value_type === 1){
        total_fee = (f.amount * 0)
      }
      //percentage
      if(f.value_type === 2){
        total_fee = this.getPercentage(f.amount, this.subtotal)
      }
      total_fee = parseFloat(total_fee).toFixed(2)
      return total_fee
    },
    cancelOverridePrice(){
      this.override_price = false
      this.calculateTotals()
    },
    updatePrice(){
      this.subtotal = this.new_price
      this.total = this.subtotal
    },
    roundToFixed(num, decimals) {
      let factor = Math.pow(10, decimals);
      return (Math.round(num * factor) / factor).toFixed(decimals);
    },
    calculateTotals(){
      if(this.selectedGiftCardType.value_type === 2){
        this.gift_card.total = this.gift_card.unit_value * this.gift_card.quantity
      }

      if(this.selectedGiftCardType.value_type === 0){
        if(isNaN(this.gift_card.custom_amount) || this.gift_card.custom_amount === ''){
          this.gift_card.custom_amount = 0
          this.gift_card.unit_price = 0
          this.gift_card.unit_value = 0
        }

        this.gift_card.unit_value = this.gift_card.custom_amount
        this.gift_card.total = this.gift_card.custom_amount * this.gift_card.quantity
      }
      if(this.selectedGiftCardType.value_type === 1){
        this.gift_card.unit_price = this.selectedGiftCardType.price
        this.gift_card.unit_value = this.selectedGiftCardType.redemption_amount
        this.gift_card.total = this.gift_card.unit_price * this.gift_card.quantity
      }
      this.subtotal = this.gift_card.total
      this.total = 0
      this.total_fee = 0
      if(!this.waive_fee){
        for(let n in this.fees){
          let amount = 0
          if(this.fees[n].value_type === 0){
            amount = this.fees[n].amount
          }
          //value (per person)
          if(this.fees[n].value_type === 1){
            amount = (this.fees[n].amount * 0)
          }
          //percentage
          if(this.fees[n].value_type === 2){
            amount = this.getPercentage(this.fees[n].amount, this.subtotal)
          }
          this.total_fee += amount

        }
      }
      this.total = this.subtotal + this.total_fee - this.getTotalDiscount()
    },
    async tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();

      if (tokenResult.status === 'OK') {
        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(
            tokenResult.errors
          )}`;
        }

        throw new Error(errorMessage);
      }
    },
    async verifyBuyer(payments, token) {
      const verificationDetails = {
        amount: this.subtotal.toString(),
        /* collected from the buyer */
        billingContact: {
          familyName: this.user.first_name,
          givenName: this.user.last_name,
        },
        currencyCode: this.currency,
        intent: 'CHARGE',
      };

      const verificationResults = await payments.verifyBuyer(
        token,
        verificationDetails
      );
      return verificationResults.token;
    },
    validateGiftCardForm(){
      let errors = 0

      if(this.selectedPaymentOption.name === ''){
        this.errs.payment_option = 'Please select a payment option'
        errors++
      } else if(this.total <= 0 && this.selectedPaymentOption.name === 'Card' && this.settings.payment_gateway_id !== 3){
        this.errs.payment_option = "Invalid charge amount for payment option"
        errors++
      } else if(this.total <= 0.5 && this.selectedPaymentOption.name === 'Card' && this.settings.payment_gateway_id === 3){
        this.errs.payment_option = "Invalid charge amount for payment option"
        errors++
      } else {
        this.errs.payment_option = ""
      }

      if(this.gift_card.custom_code == 1 && this.gift_card.code === ''){
        this.errs.custom_code = 'Gift card code cannot be empty'
        errors++
      } else if(this.gift_card.custom_code == 1 && this.gift_card.quantity > 1){
        this.errs.custom_code = 'You are trying to create more than one gift card with the same custom code. Code must be unique.'
        errors++
      } else if(this.gift_card.custom_code == 1 && this.gift_card.code.indexOf(' ') >= 0){
        this.errs.custom_code = 'Gift card code cannot have spaces'
        errors++
      }
      else {
        this.errs.custom_code = ''
      }

      if(this.gift_card.recipient_first_name === ''){
        this.errs.recipient_first_name = 'Recipient first name cannot be empty'
        errors++
      } else {
        this.errs.recipient_first_name = ''
      }

      if(this.gift_card.recipient_last_name === ''){
        this.errs.recipient_last_name = 'Recipient last name cannot be empty'
        errors++
      } else {
        this.errs.recipient_last_name = ''
      }

      if(this.gift_card.recipient_email === ''){
        this.errs.recipient_email = 'Recipient email cannot be empty'
        errors++
      } else {
        this.errs.recipient_email = ''
      }

      if(this.user.first_name === ''){
        this.errs.first_name = 'First name cannot be empty'
        errors++
      } else {
        this.errs.first_name = ''
      }

      if(this.user.last_name === ''){
        this.errs.last_name = 'Last name cannot be empty'
        errors++
      } else {
        this.errs.last_name = ''
      }

      if(this.user.email === ''){
        this.errs.email = 'Email cannot be empty'
        errors++
      } else {
        this.errs.email = ''
      }

      return errors > 0 ? false : true

    },
    validateAuthNetForm(){
      let errors = 0

      if(this.payment.cardNumber.toString().length < 15){
        this.errs.ccn = 'Invalid credit card number'
        errors++
      } else {
        this.errs.ccn = ''
      }
      let dt = new Date()
      const year = dt.getFullYear().toString().slice(-2);
      if((this.payment.expirationDate === ''
        || (this.payment.expirationDate.split('/')[0] > 12  || this.payment.expirationDate.split('/')[0] <= 0)
        || this.payment.expirationDate.split('/')[1] < parseInt(year))){
        this.errs.expiration = 'Invalid expiration date'
        errors++
      } else {
        this.errs.expiration = ''
      }

      if((this.payment.cardCode.toString().length < 3 || this.payment.cardCode.toString().length > 4)) {
        this.errs.cvv = 'Invalid CVV'
        errors++
      } else {
        this.errs.cvv = ''
      }

      return errors > 0 ? false : true
    },
    async getCredits(id){
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/customers/' + id + '/customer-credit'
      axios.get(url, config)
        .then(response => {
          this.customer_credits = response.data.customer_credits
          this.getAvailableCredit()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    getAvailableCredit(){
      let available = 0
      for(let n in this.customer_credits){
        available = available + parseFloat(this.customer_credits[n].remaining)
      }
      this.available_credit = available.toFixed(2)
      if(parseFloat(this.available_credit) < parseFloat(this.gift_card.total.amount)){
        this.applied_credit = this.available_credit
      } else {
        this.applied_credit = this.gift_card.total.amount
      }
    },
    async getPaymentMethods(){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/payment-methods'

      axios.get(url, config)
        .then((response) => {
          this.paymentOptions = response.data.payment_methods
          this.selectedPaymentOption = this.paymentOptions.length > 0 ? this.paymentOptions[0] : {}
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async createGiftPurchase(){
      if(this.send_gift_card_to_self){
        this.gift_card.recipient_first_name = this.user.first_name
        this.gift_card.recipient_last_name = this.user.last_name
        this.gift_card.recipient_email = this.user.email
      }

      if(!this.validateGiftCardForm()){
        return
      }
      let tok = await this.getAuthToken()
      let config = {headers: {Authorization: tok}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/'+ companyId + '/groups/' + companyGroupId + '/transactions'

      let payment_type = 'pos'
      if(this.selectedPaymentOption.name === 'Cash'){
        payment_type = 'cash'
      } else if(this.selectedPaymentOption.name === 'Card'){
        payment_type = 'card'
      }
      if(!this.selectedPaymentOption.is_default){
        payment_type = this.selectedPaymentOption.name
      }
      if(this.selectedPaymentOption.name === 'Yappy'){
        payment_type = 'Yappy'
      }

      this.gift_card.price = this.gift_card.unit_price
      this.gift_card.total = this.gift_card.unit_value
      this.gift_card.gift_card_type_id = this.selectedGiftCardType.id
      let data = {
        subtotal: this.subtotal,
        tax: 0,
        fee: this.total_fee,
        total: this.total,
        user: this.user,
        waive_fee: this.waive_fee,
        payment_type: payment_type,
        custom_payment_type: !this.selectedPaymentOption.is_default,
        no_confirmation_email: this.no_confirmation_email,
        promo_codes: this.promos,
        gift_card: this.gift_card,
        discount: this.getTotalDiscount(),
      }
      if(this.selectedPaymentOption.name === 'Customer credit'){
        if(parseFloat(this.applied_credit) > parseFloat(this.gift_card.total.amount)){
          swal('Ooops!', 'Charged amount cannot be greater than the total value of the booking.', 'error')
          return
        }
        if(parseFloat(this.applied_credit) > parseFloat(this.available_credit)){
          swal('Ooops!', 'You cannot apply more credits than available.', 'error')
          return
        }
        data.customer_credit = this.applied_credit
      }
      if(this.selectedPaymentOption.name === 'Gift card'){
        if(!this.gift_card_code
          || !this.selectedGiftCardCode.hasOwnProperty('id')
          || this.getGiftCardCode(this.selectedGiftCardCode.code) !== this.gift_card_code){
          this.errs.gift_card_code = 'You must choose a valid gift card'
          return
        } else {
          this.errs.gift_card_code = ''
        }
        data.gift_card = this.selectedGiftCardCode
        data.payment_type = 'gift_card'
      }

      this.loading = true

      if(this.settings.payment_gateway_id === 1 && this.selectedPaymentOption.name === 'Card'){
        if(!this.validateAuthNetForm()){
          return
        }
        this.payment.cardNumber = this.payment.cardNumber.replace(/\s/g, '');
        data.payment = this.payment
      }
      else if(this.settings.payment_gateway_id === 2 && this.selectedPaymentOption.name === 'Card') {
        const token = await this.tokenize(this.card);
        //SCA
        let verificationToken = await this.verifyBuyer(
          this.square,
          token
        );
        const token2 = await this.tokenize(this.card);
        data.locationId = this.squareLocationId
        data.sourceId = token
        data.sourceId2 = token2
        data.verificationToken = verificationToken

        url = API_LOCATION + 'companies/'+ companyId + '/groups/' + companyGroupId + '/transactions/square'
      }
      else if(this.settings.payment_gateway_id === 3 && this.selectedPaymentOption.name === 'Card') {
        const {token, error} = await this.stripe.createToken(this.card);
        data.token = token.id
        url = API_LOCATION + 'companies/'+ companyId + '/groups/' + companyGroupId + '/transactions/stripe'
      }


      this.axios.post(url, data, config)
        .then(response => {
          this.modals.new = false
          swal('Success', 'Gift card has been created' , 'success')
          this.gift_card =  {
            type: '',
            custom_code: 0,
            code: '',
            personal_message: '',
            design_url: '',
            custom_amount: '',
            recipient_first_name: '',
            recipient_last_name: '',
            recipient_email: '',
            total: 0,
            quantity: 1,
            send_instantly: 1,
            delivery_date: new Date(),
            unit_price: 0,
            unit_value: 0
          }
          this.payment = {
            cardNumber: '',
            expirationDate: '',
            cardCode: '',
            zip: ''
          }
          this.send_gift_card_to_self = false
          this.no_confirmation_email = false
          this.waive_fee = false
          this.selectedPaymentOption = {name: '', content: '', id: '', is_default: ''}
          this.clearCustomer()
          this.getGiftPurchases()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else if(err.response.status == 500){
            swal('Error!', err.response.data, 'error')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })

    },
    getPercentage(amount, subtotal){
      if(subtotal && subtotal.toString().includes(',')){
        subtotal = subtotal.replace(',', '.')
      }
      if(amount && amount.toString().includes(',')){
        amount = amount.replace(',', '.')
      }
      return parseFloat(subtotal) * (parseFloat(amount)*0.01)
    },
    async newGiftCard(){
      this.selectedGiftCardType = this.gift_card_types.length > 0 ? this.gift_card_types[0] : ''
      this.designs = this.selectedGiftCardType ? JSON.parse(this.selectedGiftCardType.designs) : []
      await this.getPaymentMethods()
      this.gift_card.design_url = this.designs.length > 0 ? this.designs[0] : ''
      this.selectGiftCardType()
      this.modals.new = true
      const root = document.getElementById('modal');
      window.requestAnimationFrame(() => {
        root.scrollTo({
          top: 500,
          left: 0,
          behavior: 'smooth',
        });
      });

      this.$nextTick(() => {
        if(this.settings.payment_gateway_id === 2){
          this.initializeSquare()
        } else if(this.settings.payment_gateway_id === 3){
          this.initializeStripe()
        }
      });

    },
    async toDataURL(url) {
      try {
        var result = await axios({
          method: "get",
          url: url + '?' + (Math.random() + 1).toString(36).substring(7),
          responseType: 'blob',
          crossdomain: true
        });
        let blobUrl = URL.createObjectURL(result.data);
        return blobUrl

      }
      catch (err) {
        console.log("err: ", err);
        return "";
      }
    },
    async downloadPDF(item, event){
      if(event){
        event.preventDefault()
      }
      this.selectedGiftCard = item
      this.gift_card_img = await this.toDataURL(this.selectedGiftCard.design_url)
      this.logo = await this.toDataURL(this.group.img)
      await this.$refs.html2Pdf.generatePdf()

    },
    async beforeDownload ({ html2pdf, options, pdfContent }) {
      await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(10)
          pdf.setTextColor(150)
          pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
        }
      }).save()
    },
    resetAll(){
      this.filters = {
        customer: {
          value: '',
          actual: ''
        },
        recipient: {
          value: '',
          actual: ''
        },
        status: {
          value: '',
          actual: ''
        },
        code: {
          value: '',
          actual: ''
        }
      }
      this.getGiftPurchases('filter')
    },
    resetField(field1){
      if(field1){
        field1.value = ''
        field1.actual = ''
      }
      this.getGiftPurchases('filter')
    },
    async sendGiftCardEmail(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/transactions/' + item.transaction_id + '/gift-cards'
      let data = {
        email: JSON.parse(item.recipients)[0].email,
        gift_card_id: item.id
      }

      this.axios.post(url, data, config)
        .then(response => {
          swal({
            title: 'Success',
            text: "Gift card delivery email sent to customer",
            type: 'success',
            confirmButtonColor: '#1d8cf8',
            confirmButtonText: 'OK'
          })
          this.getGiftPurchases()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    sendGiftCardEmailPrompt(item, event){
      if(event){
        event.preventDefault()
      }
      swal({
        title: 'Are you sure?',
        html: "This will send the gift card delivery email to the recipient.",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm'
      }).then((response)=> {
        if(response.value == true){
          this.sendGiftCardEmail(item);
        }
      })
    },
    deactivateGiftCardPrompt(item, action, event){
      if(event){
        event.preventDefault()
      }
      let text = "This action will deactivate the gift card. You will need to manually refund any remaining balance."
      if(action === 1){
        text = "This action will activate the gift card."
      }
      swal({
        title: 'Are you sure?',
        html: text,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm'
      }).then((response)=> {
        if(response.value == true){
          this.deactivateGiftCard(item, action);
        }
      })
    },
    async deactivateGiftCard(item, action){
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id

      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/gifts/' + item.id

      let data = {
        activate: action
      }

      this.loading = true
      axios.put(url, data, config)
        .then(response => {
          this.loading = false

          let text = "Gift card has been deactivated"
          if(action === 1){
            text = "Gift card has been activated"
          }

          swal({
            title: 'Success!',
            text: text,
            type: 'success'
          })

          this.getGiftPurchases()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getAllGiftCards(config, count){
      let url = ''
      let promises = []
      let csvData = []
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id


      let offset = 0
      while(offset <= this.totalRows){

        url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/gifts?count=' + count + "&offset=" + offset
        promises.push(
          axios.get(url, config)
            .then(response =>{
              csvData.push(response.data.gift_purchases)
            })
            .catch(err => {
                console.log(err)
                if (err.response.status == 401) {
                  this.$router.push('/forbidden')
                } else {
                  swal('Error!', 'Something went wrong', 'error')
                }
              }
            )
        )
        offset = offset + count
      }

      await Promise.all(promises)

      // merge all response arrays into one
      let arr = []
      csvData.forEach((element) => {
        arr = arr.concat(element)

      })
      return arr
    },
    escapeCSVValue(value) {
      if (typeof value === 'string') {
        // Escape double quotes by doubling them
        value = value.replace(/"/g, '""');

        // If the value contains a comma, wrap it in double quotes
        if (value.includes(',') || value.includes('\n')) {
          value = `"${value}"`;
        }
      }
      return value;
    },
    async downloadCSV(){
      //get the entire data set
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }

      this.loading = true
      const count = 5000
      const csvData = await this.getAllGiftCards(config, count)
      this.loading = false

      let csv = 'Code, Customer First Name, Customer Last Name, Customer Email,' +
        'Recipient First Name, Recipient Last Name, Recipient Email, Status, ' +
        'Purchased Value, Remaining Value, Price, Purchase Date, Activation Date, Delivery Date, Expiration Date\n';
      csvData.forEach(row => {
        csv += [this.escapeCSVValue(this.getGiftCardCode(row.code)), this.escapeCSVValue(row.first_name), this.escapeCSVValue(row.last_name),
          this.escapeCSVValue(row.email),
          row.recipients ? this.escapeCSVValue(JSON.parse(row.recipients)[0].first_name) : '',
          row.recipients ? this.escapeCSVValue(JSON.parse(row.recipients)[0].last_name) : '',
          row.recipients ? this.escapeCSVValue(JSON.parse(row.recipients)[0].email) : '',
          row.is_active ? 'Active' : 'Deactivated',
          this.getAmount(row.total), this.getAmount(row.remaining), this.getAmount(row.price),
          JSON.stringify(this.getLocalDate(row.date_purchased) + ' ' + this.getLocalTime(row.date_purchased)),
          JSON.stringify(this.getFormattedDate(row.activation_date)),
          JSON.stringify(this.getDeliveryDate(row.delivery_date, row.date_purchased)),
          row.expiration_date ? JSON.stringify(this.getFormattedDate(row.expiration_date)) : 'Does not expire']
        csv += "\n";
      });

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'gift_purchases.csv';
      anchor.click();
    },
    getRecipients(r){
      return JSON.parse(r)
    },
    getAmount(integer){
      if(integer == null){
        return
      }
      if(this.number_format === 1 && this.currency_format === 0){
        return this.currency_symbol + integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',')
      } else if(this.number_format === 1 && this.currency_format === 1){
        return integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',') + ' ' + this.currency
      } else if (this.number_format === 1 && this.currency_format === 1){
        return parseFloat(integer).toFixed(2) + ' ' + this.currency
      }
      return this.currency_symbol + parseFloat(integer).toFixed(2);
    },
    getDayOfWeek(d){
      if(!d){
        return
      }

      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        if(moment.isMoment(d)) {
          return d.format('dddd')
        }
        return dayOfWeek(d) + ', '
      }
      return ''
    },
    getLocalDate(d){
      let local = new Date(d)
      let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
      return this.getFormattedDate(form)
    },
    getLocalTime(d){
      let local = new Date(d)
      let mins =  local.getMinutes()
      if(mins < 10){
        mins = '0' + mins
      }
      return this.getTime(local.getHours() + ':' + mins)
    },
    getTime(d) {
      return time(this.time_format, d)
    },
    getFormattedDate(d){
      if(moment.isMoment(d)) {
        d = d.clone().format('YYYY-MM-DD')
      }
      return this.getDayOfWeek(d) + formattedDate(this.date_format, d)
    },
    getDeliveryDate(delivery_date, date_purchased){
      if(!delivery_date){
        return this.getLocalDate(date_purchased)
      } else {
        return this.getFormattedDate(delivery_date)
      }
    },
    getGiftCardCode(code){
      if(!code){
        return
      }
      if(isNaN(code)){
        return code
      }
      let c = code.match(/.{1,4}/g);
      return c.join(' ')
    },
    viewTransaction(item, event){
      if(event){
        event.preventDefault()
      }
      this.$router.push({ path: '/purchases/transactions/details', query: { id: item.transaction_id }})
    },
    viewCustomer(id){
      this.$router.push({ path: '/customers/details', query: { id:  id }})
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async loadStripe(){
      const stripeExists = document.getElementById('stripev3');
      if (!stripeExists) {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/';
        script.async = true;
        script.id = 'stripev3';
        document.body.appendChild(script);
      }
    },
    getCategories(items){

      const groupedArray = [];

      items.forEach(item => {
        const { category_id, category, minimum_participants, default_participants } = item;

        // Find if the category_id already exists in groupedArray
        let group = groupedArray.find(g => g.category_id === category_id);

        if (!group) {
          // If it doesn't exist, create a new group
          group = {
            category_id: category_id,
            category: category,
            minimum_participants: minimum_participants,
            default_participants: default_participants,
            number_of_players: 1,
            pricing: []
          };
          groupedArray.push(group);
        }

        group.pricing.push(item);
      });
      return groupedArray;
    },
    async getGiftCardTypes(){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/gift-card-types'
      this.loading = true
      this.axios.get(url, config)
        .then(response => {
          this.gift_card_types = response.data.gift_card_types
          this.addQueryParameters()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async saveGiftCardSettings(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/gift-card-settings'
      let data = {
        title: this.gift_card_settings.title,
        subtext: this.gift_card_settings.subtext,
        button_text: this.gift_card_settings.button_text,
        image_id: this.gift_card_settings.image_id,
      }
      this.axios.put(url, data, config)
        .then(response => {
          swal("Success", "Gift card settings have been updated", 'success')
          this.getGiftCardSettings()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getGiftCardSettings(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/gift-card-settings'
      this.axios.get(url, config)
        .then(response => {
          this.gift_card_settings = response.data.gift_card_settings
          this.booking_site_settings = response.data.booking_site_settings
          this.addQueryParameters()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getGiftPurchases(source){
      if(source === 'filter'){
        this.currentPage = 1
      }
      let count = this.perPage
      let offset = Number((this.currentPage * this.perPage) - this.perPage)
      //set customer
      this.filters.customer.actual = this.filters.customer.value

      //set recipient
      this.filters.recipient.actual = this.filters.recipient.value

      //set status
      this.filters.status.actual = this.filters.status.value

      //set code
      this.filters.code.actual = this.filters.code.value

      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/gifts?count=' + count + '&offset=' + offset +
        '&customer=' + encodeURIComponent(this.filters.customer.actual) +
        '&recipient=' + encodeURIComponent(this.filters.recipient.actual) +
        '&status=' + encodeURIComponent(this.filters.status.actual) +
        '&code=' + encodeURIComponent(this.filters.code.actual)
      this.loading = true
      this.axios.get(url, config)
        .then(response => {
          this.gift_purchases = response.data.gift_purchases
          this.totalRows = response.data.total_rows
          this.fees = response.data.fees
          this.games = response.data.games
          this.gift_card_types = response.data.gift_card_types
          this.settings = response.data.settings

          if(this.settings.gift_cards_allow_preset_values){
            this.amounts = response.data.amounts
          }

          for(let n in this.games){
            this.games[n].game_pricing = JSON.parse(this.games[n].game_pricing)
            this.games[n].pricing_categories = this.getCategories(this.games[n].game_pricing)
          }

          if(this.settings.payment_gateway_id === 2){
            this.squareAppId = this.settings.api_name
            this.squareLocationId = this.settings.api_location
            this.loadSquare(this.settings.is_live)
          }
          if(this.settings.payment_gateway_id === 3){
            this.loadStripe()
          }

          this.addQueryParameters()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    addQueryParameters() {
      this.$nextTick(() => {
        this.currentPage = this.$route.query.page
      });

      const newQuery = {
        ...this.$route.query,
        count: this.perPage,
        page: this.currentPage,
        customer: this.filters.customer.value,
        recipient: this.filters.recipient.value,
        status: this.filters.status.value,
        code: this.filters.code.value,
        tab: this.activeTabIndex
      }
      this.$router.replace({query: newQuery}).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    },
    loadData(){
      if(Number(this.activeTabIndex) === 0){
        this.getGiftPurchases()
      } else if(Number(this.activeTabIndex) === 1){
        this.getGiftCardTypes()
      } else if(Number(this.activeTabIndex) === 2){
        this.getGiftCardSettings()
      }
    }
  },
  mounted() {
    this.date_format_cal = this.date_format
    this.date_format_cal =  this.date_format_cal.replaceAll('Y', 'y')
    this.date_format_cal = this.date_format_cal.replaceAll('D', 'd')
    if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
      this.day_of_week = 'ddd, '
    }

    this.country = _.find(this.countries, { code: JSON.parse(localStorage.getItem('group')).country })
    this.currency_symbol =  _.find(this.currencies, { code: JSON.parse(localStorage.getItem('group')).currency }).symbol
    this.perPage = this.$route.query.hasOwnProperty('count') ? Number(this.$route.query.count) : 10
    this.currentPage = this.$route.query.hasOwnProperty('page') ? Number(this.$route.query.page) : 1

    this.filters = {
      customer: {
        value: this.$route.query.customer ? this.$route.query.customer : '',
        actual: this.$route.query.customer ? this.$route.query.customer : ''
      },
      recipient: {
        value: this.$route.query.recipient ? this.$route.query.recipient : '',
        actual: this.$route.query.recipient ? this.$route.query.recipient : ''
      },
      status: {
        value: this.$route.query.status ? this.$route.query.status : '',
        actual: this.$route.query.status ? this.$route.query.status : ''
      },
      code: {
        value: this.$route.query.code ? this.$route.query.code : '',
        actual: this.$route.query.code ? this.$route.query.code : ''
      },
    }
    // this.loadData()
    let tab = this.$route.query.hasOwnProperty('tab') ? this.$route.query.tab : 0
    this.switchTab(Number(tab))
  }
}
</script>

<style scoped>

</style>
