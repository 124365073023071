<template>
  <div>
    <loading-panel :loading="loading"></loading-panel>

    <!--Medium and large screens-->
    <div class="row mt-3 d-none d-sm-block overflow-scroll">
      <div class="col-12">
        <card>
          <h4 slot="header" class="card-title">Checklist history</h4>
          <div class="row">
            <div class="col d-flex">
              <base-input>

                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by checklist</h3>
                    <div class="pop popover-body">
                      <div class="row container">
                        <base-input>
                          <el-select clearable
                            v-model="filters.checklist.value"
                            class="select-primary"
                            name="game"
                            placeholder="Checklist"
                                     value-key="id"
                          >
                            <el-option
                              v-for="(c, i) in checklistOptions"
                              class="select-primary"
                              :label="c.name"
                              :value="{name: c.name, id: c.id}"
                              :key="'C' + i"
                            ></el-option>
                          </el-select>
                        </base-input>
                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.checklist)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="updateCompletedChecklist('filter')">Update</base-button>
                      </div>
                    </div>
                  </div>
                  <base-button slot="reference" simple size="sm" type="primary">
                    Checklist <span v-if="filters.checklist.actual">: {{filters.checklist.value.name}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>

                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by checklist completion time</h3>
                    <div class="pop popover-body">
                      <div class="row container">
                        <div>
                          <base-radio v-model="selectedDateRangeOption" name="radio0" class="mb-3">
                            Within the last
                          </base-radio>
                        </div>
                        <div class="text-dark d-inline col-lg-3 col-md-3 col-sm-6 col-8">
                          <input v-model="filters.timestamp_day.value" class="border-light text-dark form-control" type="number"/>
                        </div>
                        <div class="mt-2">
                          days
                        </div>
                      </div>

                      <div class="row container">
                        <div>
                          <base-radio v-model="selectedDateRangeOption" name="radio1" class="mb-3">
                            From
                          </base-radio>
                        </div>
                        <div class="text-dark d-inline col-lg-5 col-md-4 col-sm-8 col-12">
                          <el-date-picker
                            type="date"
                            placeholder="date"
                            value-format="yyyy-MM-dd"
                            :format="day_of_week + '' + date_format_cal"
                            v-model="filters.timestamp_range.value_from"
                          >
                          </el-date-picker>
                        </div>
                        <div class="mt-2">
                          to
                        </div>
                        <div class="text-dark d-inline col-lg-5 col-md-4 col-sm-8 col-12">
                          <el-date-picker
                            type="date"
                            placeholder="date"
                            value-format="yyyy-MM-dd"
                            :format="day_of_week + '' + date_format_cal"
                            v-model="filters.timestamp_range.value_to"
                          >
                          </el-date-picker>
                        </div>
                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.timestamp_day, filters.timestamp_range)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="updateCompletedChecklist('filter')">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                    Checklist completion time
                    <span v-if="filters.timestamp_day.actual">: within {{filters.timestamp_day.actual}} days</span>
                    <span v-if="filters.timestamp_range.actual_from">:
                          between {{filters.timestamp_range.actual_from}} and {{filters.timestamp_range.actual_to}}
                        </span>
                    &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>
                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by game</h3>
                    <div class="pop popover-body">
                      <div class="row container">
                        <base-input>
                          <el-select
                            v-model="filters.game.value"
                            class="select-primary"
                            name="game"
                            placeholder="Game"
                          >
                            <el-option
                              v-for="game in gameOptions"
                              class="select-primary"
                              :label="game.name"
                              :value="game.name"
                              :key="game.id"
                            ></el-option>
                          </el-select>
                        </base-input>
                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.game)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="updateCompletedChecklist('filter')">Update</base-button>
                      </div>
                    </div>
                  </div>
                  <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                    Game <span v-if="filters.game.actual">: {{filters.game.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>
                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by game master</h3>
                    <div class="pop popover-body">
                      <div class="row container">
                        <el-input
                          type="search"
                          clearable
                          prefix-icon="el-icon-search"
                          placeholder="    Name"
                          v-model="filters.gameMaster.value"
                        >
                        </el-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.gameMaster)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="updateCompletedChecklist('filter')">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                    Game master <span v-if="filters.gameMaster.actual">: {{filters.gameMaster.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>
                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by person completing the checklist</h3>
                    <div class="pop popover-body">
                      <div class="row container">
                        <el-input
                          type="search"
                          clearable
                          prefix-icon="el-icon-search"
                          placeholder="    Name"
                          v-model="filters.resetter.value"
                        >
                        </el-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.resetter)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="updateCompletedChecklist('filter')">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                    Completed by <span v-if="filters.resetter.actual">: {{filters.resetter.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>
                <el-tooltip content="Reset all filters"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <base-button link class="ml-2" v-on:click="resetAll()"  style="font-size: large; color:white">
                    <i class="tim-icons icon-refresh-01"></i>
                  </base-button>
                </el-tooltip>
                <span class="text-white">Total&nbsp;results:&nbsp;{{totalRows}}</span>
              </base-input>
            </div>
            <div class="mr-3">
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="perPage"
                placeholder="Per page"
                v-on:change="updateCompletedChecklist('filter')"
              >
                <el-option
                  class="select-primary"
                  v-for="item in perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="mt-3" v-if="noDataAvailable === true">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No resets have been completed yet.</h4>
            </div>
          </div>
          <div v-if="noDataAvailable === false" class="row table-content">
            <div v-if="load" class="col-md-12 col-lg-12 col-sm-12 col-12">
              <b-table hover :items="completedResets"
                        :current-page="currentPage"
                        :fields="fields">
                <template v-slot:cell(checklist)="row">
                  <div class="row">
                    <div class="col">
                      <p style="font-size: medium">{{row.item.checklist_name}}</p>
                      <small>{{row.item.checklist_description}}</small>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(game)="row">
                  <div v-if="row.item.game_id">
                    <div class="row">
                      <div class="col">
                        <span style="font-size: medium">{{row.item.game_name}}
                        </span>
                      </div>
                    </div>
                    <div class="row" v-if="row.item.game_instance_id">
                      <div class="col">
                      <span>
                        <i class="tim-icons icon-calendar-60 mr-1 text-primary"></i>
                        <span class="mr-2">{{getDayOfWeek(row.item.booking_date)}} {{getBookingDate(row.item.booking_date)}}</span>
                        <i class="tim-icons icon-time-alarm mr-1 text-primary"></i>
                        <span>{{getTime(row.item.start_time)}}</span>
                      </span>
                      </div>
                    </div>
                    <div class="row" v-if="row.item.game_instance_id">
                      <div class="col">
                      <span>
                        <span>Game master: {{row.item.gm_first_name}} {{row.item.gm_last_name}}</span>
                      </span>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    -
                  </div>
                </template>
                <template v-slot:cell(started_at)="row">
                  <span v-if="!row.item.reset_start_time">-</span>
                  <span v-if="row.item.reset_start_time" >
                    {{getLocalDate(row.item.reset_start_time)}},
                    {{getLocalTime(row.item.reset_start_time)}}
                  </span>
                </template>

                <template v-slot:cell(completed_at)="row">
                  <span>
                    {{getLocalDate(row.item.timestamp)}},  &nbsp;
                    {{getLocalTime(row.item.timestamp)}}
                  </span>
                </template>
                <template v-slot:cell(completion_time)="row">
                  {{getCompletionTime(row.item.reset_start_time, row.item.timestamp)}}
                </template>
                <template v-slot:cell(game_master)="row">
                  <span>{{row.item.gm_first_name}} {{row.item.gm_last_name}}</span>
                </template>
                <template v-slot:cell(completed_by)="row">
                  <span>{{row.item.r_first_name}} {{row.item.r_last_name}}</span>
                </template>
                <template v-slot:cell(actions)="row">
                  <el-tooltip content="Edit checklist" v-if="!row.item.editing"
                              effect="light"
                              :open-delay="300"
                              placement="top">
                    <base-button
                      @click.native="editItem(row.item)"
                      class="like btn-link"
                      type="warning"
                      size="sm"
                      icon>
                      <i class="tim-icons icon-pencil"></i>
                    </base-button>
                  </el-tooltip>
                  <el-tooltip content="Cancel" v-if="row.item.editing"
                              effect="light"
                              :open-delay="300"
                              placement="top">
                    <base-button
                      @click.native="row.item.editing = false"
                      class="like btn-link"
                      type="danger"
                      size="sm"
                      icon>
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                  </el-tooltip>
                  <el-tooltip content="Save" v-if="row.item.editing"
                              effect="light"
                              :open-delay="300"
                              placement="top">
                    <base-button
                      @click.native="row.item.editing = false"
                      class="like btn-link"
                      type="success"
                      size="sm"
                      icon>
                      <i class="tim-icons icon-check-2"></i>
                    </base-button>
                  </el-tooltip>

                </template>
              </b-table>
              <b-pagination v-on:input="updateCompletedChecklist()"
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            :limit="3"
              ></b-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>

    <!--Mobile-->
    <div class="row mt-3 d-block d-sm-none">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <div class="row justify-content-between">
            <div class="col">
              <h4 slot="header" class="card-title">Checklist history</h4>
            </div>
            <div class="col text-right">
              <b-icon icon="filter" scale="1" class="text-white" v-on:click="show_filters = !show_filters"></b-icon>
              <b-icon icon="arrow-counterclockwise" scale="1" class="ml-2 text-white" v-on:click="resetAll()"></b-icon>
            </div>
          </div>

          <div class="row" v-if="show_filters">
            <div
              class="col-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between"
            >
              <base-input>

                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by checklist</h3>
                    <div class="pop popover-body">
                      <div class="row container">
                        <base-input>
                          <el-select clearable
                                     v-model="filters.checklist.value"
                                     class="select-primary"
                                     name="game"
                                     placeholder="Checklist"
                                     value-key="id"
                          >
                            <el-option
                              v-for="(c, i) in checklistOptions"
                              class="select-primary"
                              :label="c.name"
                              :value="{name: c.name, id: c.id}"
                              :key="'C' + i"
                            ></el-option>
                          </el-select>
                        </base-input>
                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.checklist)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="updateCompletedChecklist('filter')">Update</base-button>
                      </div>
                    </div>
                  </div>
                  <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                    Checklist <span v-if="filters.checklist.actual">: {{filters.checklist.value.name}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>

                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by checklist completion time</h3>
                    <div class="pop popover-body">
                      <div class="row container">
                        <div>
                          <base-radio v-model="selectedDateRangeOption" name="radio0" class="mb-3">
                            Within the last
                          </base-radio>
                        </div>
                        <div class="text-dark d-inline col-lg-3 col-md-3 col-sm-6 col-8">
                          <input v-model="filters.timestamp_day.value" class="border-light text-dark form-control" type="number"/>
                        </div>
                        <div class="mt-2">
                          days
                        </div>
                      </div>

                      <div class="row container">
                        <div>
                          <base-radio v-model="selectedDateRangeOption" name="radio1" class="mb-3">
                            From
                          </base-radio>
                        </div>
                        <div class="text-dark d-inline col-lg-5 col-md-4 col-sm-8 col-12">
                          <el-date-picker
                            type="date"
                            placeholder="date"
                            value-format="yyyy-MM-dd"
                            :format="day_of_week + '' + date_format_cal"
                            v-model="filters.timestamp_range.value_from"
                          >
                          </el-date-picker>
                        </div>
                        <div class="mt-2">
                          to
                        </div>
                        <div class="text-dark d-inline col-lg-5 col-md-4 col-sm-8 col-12">
                          <el-date-picker
                            type="date"
                            placeholder="date"
                            value-format="yyyy-MM-dd"
                            :format="day_of_week + '' + date_format_cal"
                            v-model="filters.timestamp_range.value_to"
                          >
                          </el-date-picker>
                        </div>
                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.timestamp_day, filters.timestamp_range)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="updateCompletedChecklist('filter')">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                    Checklist completion time
                    <span v-if="filters.timestamp_day.actual">: within {{filters.timestamp_day.actual}} days</span>
                    <span v-if="filters.timestamp_range.actual_from">:
                          between {{filters.timestamp_range.actual_from}} and {{filters.timestamp_range.actual_to}}
                        </span>
                    &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>
                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by game</h3>
                    <div class="pop popover-body">
                      <div class="row container">
                        <base-input>
                          <el-select
                            v-model="filters.game.value"
                            class="select-primary"
                            name="game"
                            placeholder="Game"
                          >
                            <el-option
                              v-for="game in gameOptions"
                              class="select-primary"
                              :label="game.name"
                              :value="game.name"
                              :key="game.id"
                            ></el-option>
                          </el-select>
                        </base-input>
                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.game)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="updateCompletedChecklist('filter')">Update</base-button>
                      </div>
                    </div>
                  </div>
                  <base-button slot="reference" simple size="sm" class="ml-2" type="primary">
                    Game <span v-if="filters.game.actual">: {{filters.game.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>
                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by game master</h3>
                    <div class="pop popover-body">
                      <div class="row container">
                        <el-input
                          type="search"
                          clearable
                          prefix-icon="el-icon-search"
                          placeholder="    Name"
                          v-model="filters.gameMaster.value"
                        >
                        </el-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.gameMaster)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="updateCompletedChecklist('filter')">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                    Game master <span v-if="filters.gameMaster.actual">: {{filters.gameMaster.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>
                <el-popover trigger="click"
                            placement="bottom">
                  <div>
                    <h3 class="popover-header">Filter by person completing the checklist</h3>
                    <div class="pop popover-body">
                      <div class="row container">
                        <el-input
                          type="search"
                          clearable
                          prefix-icon="el-icon-search"
                          placeholder="    Name"
                          v-model="filters.resetter.value"
                        >
                        </el-input>

                      </div>
                      <div class="mt-2">
                        <base-button size="sm" link class="mr-2" v-on:click="resetField(filters.resetter)" type="primary">Reset</base-button>
                        <base-button size="sm" type="info" v-on:click="updateCompletedChecklist('filter')">Update</base-button>
                      </div>

                    </div>
                  </div>
                  <base-button slot="reference" class="ml-2" simple size="sm" type="primary">
                    Completed by <span v-if="filters.resetter.actual">: {{filters.resetter.actual}}</span> &nbsp;
                    <i class="tim-icons icon-minimal-down"></i>
                  </base-button>
                </el-popover>
              </base-input>
            </div>
          </div>
          <span class="text-white">Total&nbsp;results:&nbsp;{{totalRows}}</span>
          <div class="mt-3" v-if="noDataAvailable === true">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No data available.</h4>
            </div>
          </div>
          <div v-if="noDataAvailable === false" class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <b-table  :items="completedResets"
                        :current-page="currentPage"
                        :fields="mobile_fields">
                <template #head(reset)="data">
                  <span> </span>
                </template>

                <template v-slot:cell(game_master)="row">
                  <span>{{row.item.gm_first_name}} {{row.item.gm_last_name}}</span>
                </template>
                <template v-slot:cell(completed_by)="row">
                  <span>{{row.item.r_first_name}} {{row.item.r_last_name}}</span>
                </template>

                <template v-slot:cell(reset)="row">
                  <div class="row">
                    <div class="col">
                      <p style="font-size: medium">{{row.item.checklist_name}}</p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <span class="small">Completed by {{row.item.r_first_name}} {{row.item.r_last_name}} on {{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <span class="small">Completion time: {{getCompletionTime(row.item.reset_start_time, row.item.timestamp)}}</span>
                    </div>
                  </div>

                  <div v-if="row.item.game_id" class="mt-2">
                    <div class="row">
                      <div class="col">
                        <span style="font-size: medium">{{row.item.game_name}}
                        </span>
                      </div>
                    </div>
                    <div class="row" v-if="row.item.game_instance_id">
                      <div class="col">
                      <span>
                        <i class="tim-icons icon-calendar-60 mr-1 text-primary"></i>
                        <span class="mr-2">{{getBookingDate(row.item.booking_date)}}</span>
                        <i class="tim-icons icon-time-alarm mr-1 text-primary"></i>
                        <span>{{getTime(row.item.start_time)}}</span>
                      </span>
                      </div>
                    </div>
                    <div class="row" v-if="row.item.game_instance_id">
                      <div class="col">
                      <span>
                        <span>Game master: {{row.item.gm_first_name}} {{row.item.gm_last_name}}</span>
                      </span>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
              <b-pagination v-on:input="updateCompletedChecklist()"
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
              ></b-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>

<!--    Edit modal-->
    <modal :show.sync="modals.edit_checklist"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-lg">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">

        <h4>Edit checklist</h4>
        <div class="row">
          <div class="col">
            <label>Start date</label>
            <el-date-picker
              v-model="selectedItem.start_date"
              :format="day_of_week + '' + date_format_cal"
            ></el-date-picker>
          </div>
          <div class="col">
            <label>Start time</label>
            <el-time-picker
              v-model="selectedItem.start_time"
              :format="picker_time_format" placeholder="HH:MM"
            ></el-time-picker>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <label>Completion date</label>
            <el-date-picker
              v-model="selectedItem.end_date"
              :format="day_of_week + '' + date_format_cal"
            ></el-date-picker>
          </div>
          <div class="col">
            <label>Completion time</label>
            <el-time-picker
              v-model="selectedItem.end_time"
              :format="picker_time_format" placeholder="HH:MM"
            ></el-time-picker>
          </div>
        </div>

        <div class="row d-none d-sm-block text-center mt-3">
          <div class="col">
            <base-button type="secondary" @click="modals.edit_checklist = false">Cancel</base-button>
            <base-button class="ml-3" type="primary" @click="saveItem()">Save</base-button>
          </div>
        </div>
      </card>
    </modal>
  </div>
</template>

<script>
import { Popover, Select, Option, DatePicker, TimeSelect, TimePicker } from 'element-ui';
import { BaseCheckbox, BaseRadio, Modal } from 'src/components/index';
import { Auth } from 'aws-amplify'
import {BFormInput, BTable, BPagination, BIconFilter, BIcon, BIconArrowCounterclockwise} from 'bootstrap-vue'
import {API_LOCATION} from "@/api-config";
import Badge from "@/components/Badge"
const axios = require('axios')
import {dayOfWeek, formattedDate, localDate, localTime, time} from "@/plugins/dateFormatter";
import swal from "sweetalert2";
import _ from "lodash";

export default {
  name: "ChecklistHistory",
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [TimeSelect.name]: TimeSelect,
    [Popover.name]: Popover,
    [TimePicker.name]: TimePicker,
    BFormInput,
    BTable,
    BIcon,
    BIconFilter,
    BIconArrowCounterclockwise,
    BPagination,
    BaseCheckbox,
    BaseRadio,
    Modal,
    Badge
  },
  data() {
    return {
      selectedChecklistItem: '',
      selectedItem: {
        start_date: '',
        start_time: '',
        end_date: '',
        end_time: ''
      },
      loading: false,
      show_filters: false,
      load: false,
      noDataAvailable: false,
      filters: {
        resetter: {
          value: '',
          actual: ''
        },
        gameMaster: {
          value: '',
          actual: ''
        },
        checklist: {
          value: '',
          actual: ''
        },
        game: {
          value: '',
          actual: ''
        },
        timestamp_day: {
          value: '',
          actual: '',
        },
        timestamp_range:{
          value_from: '',
          actual_from: '',
          value_to: '',
          actual_to: '',
        }
      },
      completedResets: [],
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [5, 10, 25, 50],
      selectedDateRangeOption: 'radio0',
      fields: ['checklist', 'game', 'completed_by', 'started_at', 'completed_at', 'completion_time'],
      mobile_fields: ['reset'],
      editing: [],
      value: '',
      gameOptions: [],
      checklistOptions: [],
      selectedGame: '',
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      date_format_cal: '',
      day_of_week: '',
      picker_time_format: 'hh:mm A',
      modals: {
        edit_checklist: false
      }
    };
  },
  methods: {
    async saveItem(){
      let st = new Date(this.selectedItem.start_time)
      let et = new Date(this.selectedItem.end_time)
      let start_time = new Date(this.selectedItem.start_date)
      start_time.setHours(st.getHours())
      start_time.setMinutes(st.getMinutes())
      let end_time = new Date(this.selectedItem.end_date)
      end_time.setHours(et.getHours())
      end_time.setMinutes(et.getMinutes())
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/checklists/instances'
      let data = {
        id: this.selectedChecklistItem.id,
        start_time: start_time,
        end_time: end_time
      }

      this.loading = true
      this.axios.put(url, data, config)
        .then(response => {
          this.modals.edit_checklist = false
          swal('Success', 'Checklist has been updated', 'success')
          this.updateCompletedChecklist()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })

    },
    editItem(item){
      this.selectedChecklistItem = item
      this.selectedItem.start_date = item.reset_start_time
      this.selectedItem.start_time = item.reset_start_time
      this.selectedItem.end_date = item.timestamp
      this.selectedItem.end_time = item.timestamp
      this.modals.edit_checklist = true
    },
    hasPermissions(scope){
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      const matchFound = permissions.some(element => element === scope);
      return matchFound
    },
    getCompletionTime(start, end){
      if(!start || ! end){
        return '-'
      }
      let s = new Date(start)
      let e = new Date(end)
      let diff = Math.abs(e - s)
      let mins = Math.ceil(diff / (1000 * 60));
      if(mins === 1){
        return mins + ' minute'
      }
      return mins + ' minutes'
    },
    getDayOfWeek(d){
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        return dayOfWeek(d) + ', '
      }
      return ''
    },
    async getChecklists(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/checklists'
      this.axios.get(url, config)
        .then(response => {
          this.checklistOptions = response.data

          this.filters = {
            resetter: {
              value: this.$route.query.resetter ? this.$route.query.resetter : '',
              actual: this.$route.query.resetter ? this.$route.query.resetter : ''
            },
            gameMaster: {
              value: this.$route.query.game_master ? this.$route.query.game_master : '',
              actual: this.$route.query.game_master ? this.$route.query.game_master : ''
            },
            checklist: {
              value: this.$route.query.checklist ? _.find(this.checklistOptions, { id: Number(this.$route.query.checklist)}) : '',
              actual: this.$route.query.checklist ?  _.find(this.checklistOptions, { id: Number(this.$route.query.checklist)}) : ''
            },
            game: {
              value: this.$route.query.game ? this.$route.query.game : '',
              actual: this.$route.query.game ? this.$route.query.game : ''
            },
            timestamp_day: {
              value: '',
              actual: ''
            },
            timestamp_range:{
              value_from: this.$route.query.from ? this.$route.query.from : '',
              actual_from: this.$route.query.from ? this.$route.query.from : '',
              value_to: this.$route.query.to ? this.$route.query.to : '',
              actual_to: this.$route.query.to ? this.$route.query.to : ''
            }
          }
         this.updateCompletedChecklist()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    getBookingDate(d){
      return formattedDate(this.date_format, d)
    },
    getTime(d){
      return time(this.time_format, d)
    },
    getLocalDate(d){
      if(d == undefined){
        return
      }
      let local = new Date(d)
      let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();

      return this.getDayOfWeek(form) + localDate(this.date_format, d)
    },
    getLocalTime(d){
      if(!d){
        return
      }
      return localTime(this.time_format, d)
    },
    async updateCompletedChecklist(source){
      if(source === 'filter'){
        this.currentPage = 1
      }
      //set first name
      this.filters.gameMaster.actual = this.filters.gameMaster.value
      this.filters.resetter.actual = this.filters.resetter.value

      //set game
      this.filters.game.actual = this.filters.game.value

      if(this.filters.checklist.value && this.filters.checklist.value.hasOwnProperty('id')){
        this.filters.checklist.actual = this.filters.checklist.value.id
      }

      // set booking date
      let to = ''
      let from = ''
      if (this.selectedDateRangeOption === 'radio0' && this.filters.timestamp_day.value !== ''){
        this.filters.timestamp_range.actual_from = ''
        this.filters.timestamp_range.actual_to = ''
        this.filters.timestamp_range.value_from = ''
        this.filters.timestamp_range.value_to = ''
        this.filters.timestamp_day.actual = this.filters.timestamp_day.value
        let today = new Date()
        let dt = new Date()
        dt.setDate(dt.getDate() - this.filters.timestamp_day.actual)
        let userStartDate = dt.getFullYear() + "-"  + ('0' + (dt.getMonth() + 1)).slice(-2) + "-" + ('0' + dt.getDate()).slice(-2);
        let userEndDate = today.getFullYear() + "-" + ('0' + (today.getMonth() + 1)).slice(-2) + "-" + ('0' + today.getDate()).slice(-2);


        let startLocalDate = new Date(`${userStartDate}T00:00:00`);
        let endLocalDate = new Date(`${userEndDate}T23:59:59`);

        from = startLocalDate.toISOString();
        to = endLocalDate.toISOString();
      }
      if (this.selectedDateRangeOption === 'radio1' && this.filters.timestamp_range.value_to !== '' && this.filters.timestamp_range.value_from !== ''){
        this.filters.timestamp_day.actual = ''
        this.filters.timestamp_day.value = ''
        this.filters.timestamp_range.actual_from = this.filters.timestamp_range.value_from
        this.filters.timestamp_range.actual_to = this.filters.timestamp_range.value_to

        let userStartDate = this.filters.timestamp_range.actual_from
        let userEndDate = this.filters.timestamp_range.actual_to


        let startLocalDate = new Date(`${userStartDate}T00:00:00`);
        let endLocalDate = new Date(`${userEndDate}T23:59:59`);

        from = startLocalDate.toISOString();
        to = endLocalDate.toISOString();
      }

      var count = this.perPage
      var offset = parseInt((this.currentPage * this.perPage) - this.perPage)
      await this.getCompletedResets(count, offset, this.filters.game.actual, this.filters.gameMaster.actual, this.filters.resetter.actual, to, from, this.filters.checklist.actual)
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    getDate(d){
      let date = new Date(d);
      let year = date.getFullYear()
      let month = date.getMonth()+1
      if(month < 10){
        month = '0' + month
      }
      let day = date.getDate()
      if(day < 10){
        day = '0' + day
      }

      return month + '-' + day + '-' + year

    },
    async getGames () {
      this.loading = true
      var token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games'
      axios.get(url, config)
        .then(response => {
          this.loading = false
          this.gameOptions = response.data
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getCompletedResets (count, offset, game, game_master, resetter, to, from, checklist) {

      if(game_master === undefined){
        game_master = ''
      }
      if(resetter === undefined){
        resetter = ''
      }
      if(game === undefined){
        game = ''
      }
      if(checklist === undefined){
        checklist = ''
      }
      if(to === undefined){
        to = ''
      }
      if(from === undefined){
        from = ''
      }

      var token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/checklists/instances?count=' + count + "&offset=" + offset + "&game=" + encodeURIComponent(game)
        + "&game_master=" + encodeURIComponent(game_master) + '&resetter=' + encodeURIComponent(resetter) + "&to=" + to + "&from=" + from + "&checklist=" + encodeURIComponent(checklist)
      this.load = false
      await this.getGameInstanceURL(url, config)
      this.load = true
    },
    getGameInstanceURL(url, config){
      axios.get(url, config)
        .then(response => {
          if(this.completedResets.length <=0){
            this.currentPage = Number(this.$route.query.page)
          }
          this.completedResets = response.data.checklist_instances
          if(this.completedResets.length <= 0 ){
            this.noDataAvailable = true
          } else {
            this.noDataAvailable = false
          }
          this.totalRows = response.data.totalRows
          this.addQueryParameters()

        })
        .catch(err => {
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else if(err.response.status == 500){
            swal({
              title: 'Error',
              html: 'The feature has not been enabled as part of your subscription plan.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })


    },
    resetField(field1, field2){
      if(field1){
        field1.value = ''
        field1.actual = ''
      }
      if(field2){
        field2.actual_from = ''
        field2.value_from = ''
        field2.actual_to = ''
        field2.value_to = ''
      }
      this.updateCompletedChecklist('filter')
    },
    resetAll(){
      this.filters =  {
        resetter: {
          value: '',
          actual: ''
        },
        gameMaster: {
          value: '',
          actual: ''
        },
        game: {
          value: '',
          actual: ''
        },
        checklist: {
          value: '',
          actual: ''
        },
        timestamp_day: {
          value: '',
          actual: '',
        },
        timestamp_range:{
          value_from: '',
          actual_from: '',
          value_to: '',
          actual_to: '',
        }
      },
        this.updateCompletedChecklist('filter')
    },
    addQueryParameters() {
      this.$nextTick(() => {
        this.currentPage = this.$route.query.page
      });
      const newQuery = {
        ...this.$route.query,
        count: this.perPage,
        page: this.currentPage,
        resetter: this.filters.resetter.value,
        game_master: this.filters.gameMaster.value,
        checklist: this.filters.checklist.actual,
        game: this.filters.game.value,
        to: this.filters.timestamp_range.value_to,
        from: this.filters.timestamp_range.value_from
      }
      this.$router.replace({query: newQuery}).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    },
    getOffset(){
      return Number((this.currentPage * this.perPage) - this.perPage)
    },
  },
  mounted() {
    if(this.hasPermissions('checklists_update')){
      this.fields = ['checklist', 'game', 'completed_by', 'started_at', 'completed_at', 'completion_time', 'actions']
    }
    this.date_format_cal = this.date_format
    this.date_format_cal =  this.date_format_cal.replaceAll('Y', 'y')
    this.date_format_cal = this.date_format_cal.replaceAll('D', 'd')
    if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
      this.day_of_week = 'ddd, '
    }
    if (this.time_format === 'HH:mm') {
      this.picker_time_format = 'HH:mm'
    }
    this.perPage = Number(this.$route.query.count)
    this.currentPage = Number(this.$route.query.page)

    this.selectedDateRangeOption = 'radio1'
    this.getChecklists()
    this.getGames()
  }
};
</script>
<style scoped>
.pagination-select{
  width: 100px;
}
.table-content {
  overflow-x: scroll;
}
.pop {
  max-width: 600px;
}
#loading {
  min-height: 1000px;
  display: flex;
  align-items: center;
}

.el-loading-spinner .path {
  stroke: #6aff8d !important;
}

.el-loading-mask {
  background: rgba(0, 0, 0, 0.7) !important;
}
</style>
