<template>
  <div>
    <div v-if="loading" element-loading-text="Loading..."
         element-loading-spinner="el-icon-loading"
         v-loading="loading" class="el-loading-mask" id="loading">
    </div>
    <div class="mt-3" v-if="noDataAvailable === true">
      <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
        <h4 class="mt-3">No data available.</h4>
      </div>
    </div>
    <div v-if="noDataAvailable === false" class="row mt-3">
      <div class="col-lg-4 col-md-4 col-sm-6 col-12" v-for="game in games" :key="game.id">
        <card>
          <img slot="image" class="card-img-top fixed-size-img" :src="game.url"  alt="Card image cap"/>
          <h4 class="card-title">{{game.name}}</h4>
          <div class="row mt-3">
            <div class="col">
              <base-button size="sm" simple v-on:click="leaderboardSettings(game)" class="mr-2" type="warning">
                <i class="tim-icons icon-settings"></i>Leaderboard settings</base-button>
              <base-button v-show="hasPermissions('leaderboards_view')" size="sm" simple v-on:click="leaderboard(game)" class="mr-2" type="warning"><i class="tim-icons icon-trophy mr-1"></i>Leaderboard</base-button>
              <base-button v-show="hasPermissions('dashboards_view')" size="sm" simple v-on:click="gameAnalytics(game)" type="success"><i class="tim-icons icon-chart-bar-32 mr-1"></i>Analytics</base-button>

            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
  import {Card} from '@/components'
  import { Auth } from 'aws-amplify'
  import { API_LOCATION } from '../../../api-config'
  import { Rate } from 'element-ui';
  import swal from "sweetalert2";
  const axios = require('axios')

  export default {
    name: "GameDashboards",
    components: {
      Card,
      [Rate.name]: Rate,
    },
    data() {
      return {
        games: '',
        loading: false,
        noDataAvailable: false
      }
    },
    methods: {
      leaderboardSettings(game){
        localStorage.setItem('selectedGameStats', JSON.stringify(game))
        this.$router.push('/dashboard/game/leaderboard/settings')
      },
      hasPermissions(scope){
        let permissions = JSON.parse(localStorage.getItem('permissions'))
        const matchFound = permissions.some(element => element === scope);
        return matchFound
      },
      leaderboard(game){
        localStorage.setItem('selectedGameStats', JSON.stringify(game))
        this.$router.push('/dashboard/game/leaderboard')
      },
      gameAnalytics(game){
        localStorage.setItem('selectedGameStats', JSON.stringify(game))
        this.$router.push('/dashboard/game/analytics')

      },
      getAuthToken() {
        return new Promise ( function(resolve) {
          Auth.currentSession()
            .then(data =>{
              resolve(data.idToken.jwtToken)
            })
            .catch(err => console.log(err))
        })
      },
      async getGames () {
        this.loading = true
        var token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games'
        axios.get(url, config)
          .then(response => {
            this.games = response.data
            for (var n in this.games){
              if (this.games[n].img === null){
                this.games[n].img = 'https://console.offthecouch.io/img/placeholder.jpg'
              }
            }
            if (this.games.length <= 0){
              this.noDataAvailable = true
            }
            this.loading = false

          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              this.$router.push('/forbidden')
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
    },
    mounted () {
      this.getGames()
    }
  }
</script>

<style scoped>
  .fixed-size-img {
    width: 100%; /* width of container */
    height: 200px; /* height of container */
    object-fit: contain;
  }
  #loading {
    min-height: 1000px;
    display: flex;
    align-items: center;
  }

  .el-loading-spinner .path {
    stroke: #6aff8d !important;
  }

  .el-loading-mask {
    background: rgba(0, 0, 0, 0.7) !important;
  }
</style>
