import { render, staticRenderFns } from "./EditDashboard.vue?vue&type=template&id=1c63daa0&scoped=true"
import script from "./EditDashboard.vue?vue&type=script&lang=js"
export * from "./EditDashboard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c63daa0",
  null
  
)

export default component.exports