<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>
  <!--    Status-->
  <div class="row mr-2 justify-content-end">
    <base-button size="sm" class="ml-3" v-if="!leaderboard_settings.is_activated"  disabled type="danger" simple>INACTIVE</base-button>
    <base-button size="sm" class="ml-3" v-if="leaderboard_settings.is_activated" disabled type="success" simple>ACTIVE</base-button>
  </div>

  <div class="row d-none d-sm-block mb-3 container">
    <base-button type="primary" v-on:click="saveSettings()">Save changes</base-button>
    <base-button simple class="ml-3" v-if="!leaderboard_settings.is_activated" type="success" v-on:click="toggleActivate(1)">Activate</base-button>
    <base-button simple class="ml-3" v-if="leaderboard_settings.is_activated == 1" type="danger" v-on:click="toggleActivate(0)">Deactivate</base-button>
  </div>
  <div class="row d-block d-sm-none mb-3 container">
    <base-button simple size="sm" type="primary" v-on:click="saveSettings()">Save changes</base-button>
    <base-button simple size="sm" class="ml-3" v-if="!leaderboard_settings.is_activated" type="success" v-on:click="toggleActivate(1)">Activate</base-button>
    <base-button simple size="sm" class="ml-3" v-if="leaderboard_settings.is_activated" type="danger" v-on:click="toggleActivate(0)">Deactivate</base-button>
  </div>

  <div class="row mt-3">
    <div class="col-7">
      <card>
        <h4 slot="header" class="card-title">Leaderboard settings for {{leaderboard_settings.name}}</h4>
        <div class="row">
          <div class="col-3">
            <base-input type="number" label="Number of rows to show" v-model="leaderboard_settings.number_of_rows"></base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <base-checkbox v-model="leaderboard_settings.show_number_of_players">Show number of players</base-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <base-checkbox v-model="leaderboard_settings.show_number_of_clues">Show number of hints received</base-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <base-checkbox v-model="leaderboard_settings.show_no_hints_only">Only show teams who completed the game with no hints</base-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <base-checkbox v-model="leaderboard_settings.show_unnamed_teams">Show unnamed teams (will display "Unnamed team" where team name has not be set)</base-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <base-checkbox v-model="leaderboard_settings.show_time">Show completion time</base-checkbox>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-4">
            <base-checkbox v-model="leaderboard_settings.display_by_completion">Display by puzzle completion rate</base-checkbox>
          </div>
        </div>
        <div class="row ml-2">
          <div class="col-3" v-if="leaderboard_settings.display_by_completion">
            <base-input type="number" v-mask="'###'" label="Completion rate (%)" v-model="leaderboard_settings.completion_rate"></base-input>
          </div>
        </div>
        <small v-if="rate_error" class="text-danger mb-2 ml-4">Completion rate must be between 0 and 100</small>
      </card>
    </div>
<!--    <div class="col-5">-->
<!--      <card>-->
<!--        <h4 slot="header" class="card-title">Embed form</h4>-->
<!--        <p>Copy the following HTML and embed it in your code to display the leaderboard on your website.</p>-->
<!--        <div class="mt-2">-->
<!--          <base-alert type="white" class="text-muted">-->

<!--            <div class="row">-->
<!--              <div class="col-10">-->
<!--                {{ embedded_text }}-->
<!--              </div>-->
<!--              <div class="col text-right">-->
<!--                <base-button class="like btn-link"-->
<!--                             type="default"-->
<!--                             size="sm"-->
<!--                             icon-->
<!--                             v-on:click="copy()">-->
<!--                  <i class="tim-icons el-icon-copy-document"></i>-->
<!--                </base-button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </base-alert>-->
<!--        </div>-->


<!--      </card>-->
<!--    </div>-->
  </div>
</div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION, LEADERBOARD_URL} from "@/api-config";
import axios from "axios";
import { BaseAlert } from '@/components';
import swal from "sweetalert2";


export default {
  name: "LeaderboardSettings",
  components: {
    BaseAlert
  },
  data() {
    return {
      loading: false,
      leaderboard_settings: '',
      embedded_text: '',
      rate_error: false
    }
  },
  methods: {
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    copy(){
      navigator.clipboard.writeText(this.embedded_text);
    },
    getIframe(){
      this.embedded_text = '<iframe src="' + LEADERBOARD_URL + this.leaderboard_settings.game_id + '"  width="890" height="700" frameborder="0" allowfullscreen>'
    },
    async saveSettings(){
      //validate completion rate
      if(this.leaderboard_settings.display_by_completion === 1 && this.leaderboard_settings.completion_rate > 100){
        this.rate_error = true
        return
      } else {
        this.rate_error = false
      }

      this.loading = true
      var token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId
        + '/games/' + JSON.parse(localStorage.getItem('selectedGameStats')).id + '/leaderboard/settings'

      let data = {
        show_number_of_players: this.leaderboard_settings.show_number_of_players,
        show_time: this.leaderboard_settings.show_time,
        number_of_rows: this.leaderboard_settings.number_of_rows,
        show_number_of_clues: this.leaderboard_settings.show_number_of_clues,
        show_no_hints_only: this.leaderboard_settings.show_no_hints_only,
        show_unnamed_teams: this.leaderboard_settings.show_unnamed_teams,
        display_by_completion: this.leaderboard_settings.display_by_completion,
        completion_rate: this.leaderboard_settings.completion_rate
      }
      axios.put(url, data, config)
        .then(response => {
          swal('Success!', 'Leaderboard settings updated', 'success')
          this.getLeaderboardSettings()
          this.loading = false

        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async toggleActivate(activate){
      this.loading = true
      var token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId
        + '/games/' + JSON.parse(localStorage.getItem('selectedGameStats')).id + '/leaderboard/activate'

      let data = {
        is_activated: activate
      }
      axios.post(url, data, config)
        .then(response => {
          swal('Success!', 'Leaderboard updated', 'success')
          this.getLeaderboardSettings()
          this.loading = false

        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async getLeaderboardSettings () {
      this.loading = true
      var token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let url = API_LOCATION + 'companies/' + companyId
        + '/games/' + JSON.parse(localStorage.getItem('selectedGameStats')).id + '/leaderboard/settings'
      axios.get(url, config)
        .then(response => {
          this.leaderboard_settings = response.data[0]
          this.getIframe()
          this.loading = false

        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
  },
  mounted() {
    this.getLeaderboardSettings()
  }
}
</script>

<style scoped>

</style>
