<template>
<div>
  <!--    Breadcrumbs-->
  <breadcrumb style="margin-left: -15px">
    <breadcrumb-item>
      <a href="/purchases/transactions?count=10&page=1">Transactions</a>
    </breadcrumb-item>
    <breadcrumb-item>
      <span>Transaction Details</span>
    </breadcrumb-item>
  </breadcrumb>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>
  <div class="row" >
    <div class="col-lg-12 col-12">
      <card>
<!--        <img slot="image" class="card-img-bottom fixed-size-img" :src="booking.img"  alt="Card image cap"/>-->
        <div class="row justify-content-between">
          <div class="col">
            <h3 slot="header" class="card-title">
              Transaction #{{transaction.order_number}}
              <el-tooltip content="Download invoice"
                          effect="light"
                          :open-delay="300"
                          placement="top">
                <base-button
                  @click.native="downloadTransactionPDF()"
                  class="like btn-link text-white"
                  size="sm"
                  icon
                ><i class="tim-icons icon-cloud-download-93"></i>
                </base-button>
              </el-tooltip>
            </h3>
          </div>

          <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :paginate-elements-by-height="800"
            :enable-download="false"
            filename="invoice"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref="transactionPdf"
            @beforeDownload="beforeDownload($event)"
          >
            <section slot="pdf-content">
              <div style="color: #222a42;
                font-family: 'Poppins', sans-serif;
                padding: 40px;
                width: 800px;">
                <!-- Header Section -->
                <div style=" display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-bottom: 20px;
                      color: #222a42;">
                  <h1 style="color: #222a42">Invoice</h1>
                  <div style="margin: 0;
                        text-align: right;">
                    <h4 style="color: #222a42; padding-bottom: 0px">{{company.name}}</h4>
                    <p v-if="group.company_number" style="color: #222a42">Company #{{group.company_number}}</p>
                    <p v-if="company.address"  style="color: #222a42">{{company.address}}</p>
                    <p v-if="company.address_line_2" style="color: #222a42">{{company.address_line_2}}</p>
                    <p v-if="company.city" style="color: #222a42">{{company.city}}
                      <span v-if="company.state">{{company.state}}</span> {{ company.zip }}</p>
                    <p v-if="company.country" style="color: #222a42">{{ company.country }}</p>
                    <p style="color: #222a42">{{company.phone}}</p>
                  </div>
                </div>

                <!-- Billing Section -->
                <div class="row" style="display: flex;
                    margin-bottom: 20px; margin-top: 10px;">
                  <div class="col-5">
                    <p style="color: #222a42; margin: 0;"><strong style="color: #3358f4">Billed To</strong></p>
                    <p style="color: #222a42; margin: 0;">{{ transaction.first_name }} {{ transaction.last_name }}</p>
                    <p v-if="transaction.email" style="color: #222a42; margin: 0;">{{ transaction.email }}</p>
                    <p v-if="transaction.phone" style="color: #222a42; margin: 0;">{{ transaction.phone }}</p>
                  </div>
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <p style="color: #222a42; margin: 0;"><strong style="color: #3358f4">Date Issued</strong></p>
                      </div>
                      <div class="col">
                        <p style="color: #222a42; margin: 0;"><strong style="color: #3358f4">Order Number</strong></p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <p style="color: #222a42; margin: 0;">{{ getLocalDate(transaction.timestamp) }}</p>
                      </div>
                      <div class="col">
                        <p style="color: #222a42; margin: 0;">#{{transaction.order_number}}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <hr style="border: 1px solid #3358f4">

                <!-- Line Items Section -->
                <table style="width: 100%;
                  border-collapse: collapse;
                  margin-bottom: 20px;">
                  <thead>
                  <tr>
                    <th style="color: #3358f4; padding-bottom: 10px;
                    text-align: left;">Item</th>
                    <th style="color: #3358f4; padding-bottom: 10px;
                    text-align: left;">Quantity</th>
                    <th style="color: #3358f4; padding-bottom: 10px;
                    text-align: right;">Price</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(b, i) in bookings" :key="'BOOKINGPDF' + i">
                      <td style="padding-bottom: 7px;">
                        <span v-if="!b.hasOwnProperty('design_url')">{{ b.name }}</span>
                        <span v-else>
                          <p style="color: #222a42;">Gift card</p>
                          <small>#{{b.code}}</small>
                        </span>
                      </td>
                      <td style="padding-bottom: 7px;">
                        <span v-if="b.hasOwnProperty('booking_date')">{{ b.group_size }}</span>
                        <span v-if="b.hasOwnProperty('category_name')"> {{ b.quantity }}</span>
                        <span v-if="b.hasOwnProperty('design_url')">1</span>

                      </td>
                      <td style="padding-bottom: 7px; text-align: right;">
                        <span v-if="!b.hasOwnProperty('design_url')">{{ getAmount(b.price) }}</span>
                        <span v-else> {{ getAmount(b.total) }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <hr style="border: 1px solid #3358f4">

                <!-- Totals Section -->
                <div class="row justify-content-end">
                  <div class="col-6 text-right" style="margin-bottom: 20px;">
                    <div class="row justify-content-between">
                      <div class="col-9 text-left">
                        <p style="color: #222a42">Subtotal</p>
                      </div>
                      <div class="col text-right">
                        <p style="color: #222a42">{{getAmount(transaction.price)}}</p>
                      </div>
                    </div>
                    <div class="row justify-content-between" v-for="(t, i) in taxes_fees" :key="'TAXNAMEPDF' + i">
                      <div class="col-9 text-left">
                        <p style="color: #222a42">
                          <span v-if="!t.hasOwnProperty('tax_id') && !t.hasOwnProperty('fee_id')">
                            {{ payment_settings.tip_line_item_label }}
                          </span>
                          <span v-else>{{ t.name }}</span>
                          <span v-if="t.tax_number" style="font-size: 10px; color: #222a42">
                          (#{{ t.tax_number }})
                          </span>
                        </p>
                      </div>
                      <div class="col text-right">
                        <p style="color: #222a42">
                          {{getAmount(t.amount)}}</p>
                      </div>
                    </div>
                    <div class="row justify-content-between" v-for="(p, i) in promos" :key="'PROMONAMEPDF' + i" >
                      <div class="col-9 text-left">
                        <p style="color: #222a42">
                          {{ p.name }}
                        </p>
                      </div>
                      <div class="col text-right">
                        <p style="color: #222a42">
                          -{{getAmount(p.amount)}}</p>
                      </div>
                    </div>
                    <hr style="border: 1px solid #3358f4">
                    <div class="row justify-content-between">
                      <div class="col-9 text-left">
                        <p style="color: #222a42"><strong>Total</strong></p>
                      </div>
                      <div class="col text-right">
                        <p style="color: #222a42">{{getAmount(transaction.total)}}</p>
                      </div>
                    </div>
                    <div class="row justify-content-between">
                      <div class="col-9 text-left">
                        <p style="color: #222a42"><strong>Amount paid</strong></p>
                      </div>
                      <div class="col text-right">
                        <p style="color: #222a42">{{getAmount(transaction.paid)}}</p>
                      </div>
                    </div>
                    <div class="row justify-content-between">
                      <div class="col-9 text-left">
                        <p style="color: #222a42"><strong>Amount due</strong></p>
                      </div>
                      <div class="col text-right">
                        <p style="color: #222a42">{{getAmount(transaction.due)}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

          </vue-html2pdf>

          <div class="col mb-2 text-right">
            <base-dropdown v-if="transaction.status === 1"
                           title-classes="dropdown-toggle btn btn-success btn-simple btn-sm"
                           title="Status: Active"
                           menu-on-right
            >
              <a class="text-default dropdown-item" @click="cancelTransactionPrompt()" href="#">Cancel transaction</a>
            </base-dropdown>

            <base-button disabled v-if="transaction.status === 0"  v-on:click="cancelTransactionPrompt()"  simple type="danger">
              Status: Cancelled</base-button>
            <base-button disabled v-if="transaction.status === 2"  v-on:click="cancelTransactionPrompt()"  simple type="danger">
              Status: Pending</base-button>
          </div>
        </div>

        <b-row>
          <b-col>
            <hr style="border: 1px solid #e14eca">
            <div class="row card-stats">
              <div class="col">
                <div class="numbers">
                  <slot>
                    <p class="card-category">Subtotal</p>
                    <h2 class="card-title">{{ getAmount(transaction.price) }}</h2>
                  </slot>
                </div>
              </div>
            </div>
          </b-col>
          <b-col>
            <hr style="border: 1px solid #e14eca">
            <div class="row card-stats">
              <div class="col">
                <div class="numbers">
                  <slot>
                    <p class="card-category">Total</p>
                    <h2 class="card-title">{{ getAmount(transaction.total) }}</h2>
                  </slot>
                </div>
              </div>
            </div>
          </b-col>
          <b-col>
            <hr style="border: 1px solid #e14eca">
            <div class="row card-stats">
              <div class="col">
                <div class="numbers">
                  <slot>
                    <p class="card-category">Paid</p>
                    <h2 class="card-title" v-if="transaction.status === 2">{{ getAmount(0) }}</h2>
                    <h2 class="card-title" v-else>{{ getAmount(transaction.paid) }}</h2>
                  </slot>
                </div>
              </div>
            </div>
          </b-col>
          <b-col>
            <hr style="border: 1px solid #e14eca">
            <div class="row card-stats">
              <div class="col">
                <div class="numbers">
                  <slot>
                    <p class="card-category">Due</p>
                    <h2 class="card-title" v-if="transaction.status === 2">{{ getAmount(transaction.total) }}</h2>
                    <h2 class="card-title" v-else>{{ getAmount(transaction.due) }}</h2>
                  </slot>
                </div>
              </div>
            </div>
          </b-col>

          <b-col>
            <hr style="border: 1px solid #e14eca">
            <div class="row card-stats">
              <div class="col">
                <div class="numbers">
                  <slot>
                    <p class="card-category">Taxes</p>
                    <h2 class="card-title">{{ getAmount(transaction.tax) }}</h2>
                  </slot>
                </div>
              </div>
            </div>
          </b-col>
          <b-col>
            <hr style="border: 1px solid #e14eca">
            <div class="row card-stats">
              <div class="col">
                <div class="numbers">
                  <slot>
                    <p class="card-category">Fees</p>
                    <h2 class="card-title">{{ getAmount(transaction.fee) }}</h2>
                  </slot>
                </div>
              </div>
            </div>
          </b-col>
          <b-col>
            <hr style="border: 1px solid #e14eca">
            <div class="row card-stats">
              <div class="col">
                <div class="numbers">
                  <slot>
                    <p class="card-category">Discount</p>
                    <h2 class="card-title">{{ getAmount(transaction.discount) }}</h2>
                  </slot>
                </div>
              </div>
            </div>
          </b-col>

          <b-col>
            <hr style="border: 1px solid #e14eca">
            <div class="row card-stats">
              <div class="col">
                <div class="numbers">
                  <slot>
                    <p class="card-category">Tips</p>
                    <h2 class="card-title">{{ getAmount(transaction.tips) }}</h2>
                  </slot>
                </div>
              </div>
            </div>
          </b-col>
          <b-col>
            <hr style="border: 1px solid #e14eca">
            <div class="row card-stats">
              <div class="col">
                <div class="numbers">
                  <slot>
                    <p class="card-category">Refunded</p>
                    <h2 class="card-title">{{ getAmount(transaction.refunded) }}</h2>
                  </slot>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="row mt-4">
          <div class="col">
            <h4 class="card-title">Purchased by {{transaction.first_name}} {{transaction.last_name}}
              on {{getLocalDate(transaction.timestamp)}}, {{getLocalTime(transaction.timestamp)}}
            </h4>
            <p v-if="transaction.email" style="font-size: small"><i class="tim-icons icon-email-85"></i> {{transaction.email}}</p>
            <p v-if="transaction.phone" style="font-size: small"><i class="tim-icons el-icon-mobile-phone"></i> {{transaction.phone}}</p>
          </div>
        </div>
      </card>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <card style="min-height: 150px;">
        <div class="row mb-3">
          <div class="col">
            <div
              class="btn-group btn-group-toggle"
              :class="'float-left'"
              data-toggle="buttons"
            >
              <label
                v-for="(option, index) in categories"
                :key="index + j" style="padding-left: 10px; padding-right: 10px"
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: option.active }"
                :id="index"
              >
                <input
                  type="radio"
                  @click="switchTab(index)"
                  name="options"
                  autocomplete="off"
                  :checked="option.active"
                />
                <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                <span class="d-block d-sm-none">
                    <i style="padding-left: 0px; padding-right: 0px" :class="option.icon"></i>
                  </span>
              </label>
            </div>
          </div>
        </div>

        <div v-if="activeTabIndex === 0">
          <div class="row" v-if="transaction.status === 1">
            <div class="col">
              <el-dropdown placement="bottom-start">
                <base-button size="sm"
                             simple
                             type="success" class="mr-2"

                > Add new <i class="tim-icons icon-minimal-down ml-1"></i>
                </base-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <a v-if="hasPermissions('bookings_create')" class="dropdown-item mb-2 mt-2" href="#" @click="addBooking($event)">
                      <i class="tim-icons icon-calendar-60 mr-2 text-info"></i> Booking
                    </a>
                    <a v-if="hasPermissions('gift_cards_update')" class="dropdown-item mb-2" href="#" @click="addGiftCard($event)">
                      <i class="tim-icons icon-gift-2 mr-2 text-warning"></i> Gift card
                    </a>
                    <a class="dropdown-item mb-2" href="#" @click="addPurchase($event)">
                      <i class="tim-icons icon-shape-star mr-2 text-default"></i> Item
                    </a>
                    <a class="dropdown-item mb-2" href="#" @click="addCustomPurchase($event)">
                      <i class="tim-icons icon-trophy mr-2 text-danger"></i> Custom purchase
                    </a>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
<!--          Desktop-->
          <div class="d-sm-block d-none">
            <div v-if="bookings.length <= 0" class="mt-3" >
              <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
                <h4 class="mt-3">No bookings are associated with this transaction.</h4>
              </div>
            </div>
            <b-table v-if="bookings.length > 0" :items="bookings" :fields="bookingFields">
              <template v-slot:cell(purchase_details)="row">
                <div v-if="editing_bookings[row.index] && row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url')">
                  <div class="row">
                    <div class="col">
                      <base-input label="Game">
                        <el-select
                          label="Select game"
                          v-model="row.item.selectedGame"
                          class="select-primary"
                          v-on:change="getInitialSubtotal(row.item)"
                          placeholder="Game">
                          <el-option
                            v-for="game in games"
                            class="select-primary"
                            :label="game.name"
                            :value="game.name"
                            :key="game.id"
                          ></el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <base-input label="Date" :error="errs.booking_date">
                        <el-date-picker
                          type="date"
                          placeholder="Select date"
                          value-format="yyyy-MM-dd"
                          name="date"
                          v-model="row.item.booking_date"
                          :format="date_format_cal"
                          v-on:change="getAvailableBookings(row.item, 'date')"
                        >
                        </el-date-picker>
                      </base-input>
                    </div>
                    <div class="col">
                      <base-input label="Time" :error="errs.start_time">
                        <el-select v-on:change="getNewSubtotalForBookingSlot(row.item.start_time, row.item)"
                          label="Select time"
                          v-model="row.item.start_time"
                          class="select-primary"
                          value-key="id"
                          placeholder="Time">
                          <el-option
                            v-for="(booking, index) in available_bookings"
                            class="select-primary"
                            :label="getTime(booking.start_time)  + '-' + getTime(booking.end_time)"
                            :value="{id: booking.id,
                         booking_date: booking.booking_date,
                         end_date: booking.end_date,
                         pricing_categories: booking.pricing_categories,
                          pricing: booking.pricing,
                          custom_pricing: booking.custom_pricing,
                          game_id: booking.game_id,
                          start_time: booking.start_time,
                          is_custom_pricing_enabled: booking.is_custom_pricing_enabled,
                          slot_cumulative_category_pricing: booking.slot_cumulative_category_pricing,
                          slot_pricing_type_id: booking.slot_pricing_type_id,
                          pricing_type_id: booking.pricing_type_id,
                          cumulative_category_pricing: booking.cumulative_category_pricing,
                          end_time: booking.end_time}"
                            :key="'A' + index"
                          ></el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                </div>
                <div v-if="!editing_bookings[row.index] && row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url')">
                  <p style="font-size: large" class="text-primary">{{ row.item.name }}
                    <el-tooltip content="View game history"
                                effect="light"
                                :open-delay="300"
                                placement="top">
                      <base-button v-if="row.item.status === 'completed'"
                                   v-on:click="goToGame(row.item)" icon class="btn btn-link" simple size="sm" type="primary">
                        <i style="font-size: larger" class="tim-icons el-icon-top-right"></i>
                      </base-button>
                    </el-tooltip>
                  </p>
                  <p>
                    <i class="tim-icons icon-calendar-60"></i> {{getFormattedDate(row.item.booking_date)}}
                    &nbsp; <i class="tim-icons icon-time-alarm"></i> {{getTime(row.item.start_time)}} -  {{getTime(row.item.end_time)}}
                  </p>
                  <p style="font-size: x-small">Booking id: #{{row.item.id}}
                  </p>
                </div>
                <div v-if="!row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url')">
                  <p style="font-size: large" class="text-primary">{{ row.item.name }}</p>
                  <p style="font-size: x-small" v-if="row.item.category_name">{{row.item.category_name}}
                  </p>
                  <p style="font-size: x-small" v-if="row.item.is_custom">{{row.item.description}}
                  </p>
                </div>
                <div v-if="!editing_bookings[row.index] && !row.item.hasOwnProperty('booking_date') && row.item.hasOwnProperty('design_url')">
                  <p style="font-size: large" class="text-primary">Gift card</p>
                  <p style="font-size: x-small">{{getGiftCardCode(row.item.code)}}</p>
                  <p v-if="row.item.delivery_date" style="font-size: x-small">Delivery date: {{getDayOfWeek(row.item.delivery_date)}}  {{getFormattedDate(row.item.delivery_date)}}</p>
                  <p v-if="!row.item.delivery_date" style="font-size: x-small">Delivery date:  {{getLocalDate(transaction.timestamp)}}</p>
                  <p v-if="row.item.game_name" style="font-size: x-small">Purchased for {{row.item.game_name}}, {{row.item.number_of_participants}} participants</p>
                  <img style="object-fit: cover; height: 75px" :src="row.item.design_url"/>
                </div>
              </template>
              <template v-slot:cell(status)="row">
                <badge v-if="row.item.status == 'available'  && row.item.hasOwnProperty('booking_date')" type="success">available</badge>
                <badge v-if="row.item.status == 'completed'  && row.item.hasOwnProperty('booking_date')" type="success">
                  completed
                </badge>
                <badge v-if="row.item.status == 'checked'  && row.item.hasOwnProperty('booking_date')" type="warning">checked in</badge>
                <badge v-if="row.item.status == 'booked'  && row.item.hasOwnProperty('booking_date') && !hasPassed(row.item.booking_date)" type="primary">
                  upcoming
                </badge>
                <badge v-if="row.item.status == 'booked'  && row.item.hasOwnProperty('booking_date') && hasPassed(row.item.booking_date)" type="success">
                  completed
                </badge>
                <badge v-if="row.item.status === 'cancelled'  && row.item.hasOwnProperty('booking_date')" type="danger">cancelled</badge>
                <p v-if="!row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url')">-</p>
                <badge v-if="!row.item.hasOwnProperty('booking_date')
                  && row.item.hasOwnProperty('design_url') && row.item.is_active" type="success">active
                </badge>
                <badge v-if="!row.item.hasOwnProperty('booking_date')
                  && row.item.hasOwnProperty('design_url') && !row.item.is_active" type="danger">deactivated
                </badge>
              </template>
              <template #head(quantity)="row">
                <span></span>
              </template>
              <template v-slot:cell(quantity)="row">
                <div v-if="editing_bookings[row.index] && row.item.hasOwnProperty('booking_date')  && !row.item.hasOwnProperty('design_url')" >
                  <div class="row" :key="pr_key">
                    <div class="col-lg-8">
                      <div  v-for="(c, i) in row.item.pricing_categories" :key="'CX' + i">
                        <label class="text-primary text-uppercase">{{c.category}}</label>
                        <base-input>
                          <el-input-number @change="getSubtotal(row.item)" v-model="c.number_of_players" :min="0" />
                        </base-input>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <base-input :error="errs.number_of_players"><p></p></base-input>
                    </div>
                  </div>
                </div>
                <div v-if="!editing_bookings[row.index] && row.item.hasOwnProperty('booking_date')  && !row.item.hasOwnProperty('design_url')">
                  <label>Participants</label>
                  <p>x{{row.item.group_size}}</p>
                </div>
                <div v-if="!editing_bookings[row.index] && !row.item.hasOwnProperty('booking_date')  && !row.item.hasOwnProperty('design_url')">
                  <label>Quantity</label>
                  <p>x{{row.item.quantity}}</p>
                </div>
                <div v-if="!editing_bookings[row.index] && !row.item.hasOwnProperty('booking_date')  && row.item.hasOwnProperty('design_url')">
                  <label>Quantity</label>
                  <p>x1</p>
                </div>

                <div v-if="editing_bookings[row.index] && !row.item.hasOwnProperty('booking_date')  && !row.item.hasOwnProperty('design_url')">
                  <div class="col-lg-8">
                    <base-input :error="errs.purchase.quantity" label="Quantity">
                      <el-input-number v-model="row.item.quantity" :min="1" />
                    </base-input>
                  </div>
                </div>
              </template>
              <template v-slot:cell(price)="row" >
                <div v-if="editing_bookings[row.index] && row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url')" >
                  <div class="row" :key="pr_key + 'OO'">
                    <div class="col" v-if="!row.item.price_override">
                      <h2 style="margin-bottom: 0px" class="warning">{{ getAmount(row.item.subtotal) }}</h2>
                      <small>
                        <div  v-for="(c, i) in row.item.pricing_categories" :key="'CC' + i">
                          <span class="text-primary">{{getRate(c, row.item)}}</span>
                        </div>
                      </small>
                      <base-button v-on:click="overridePrice(row.item)" class="mt-2" size="sm" type="primary">Override price</base-button>
                    </div>
                    <div v-else>
                      <div v-for="(c, index) in row.item.pricing_categories" :key="'CCOVERRIDE' + index">
                        <label v-if="row.item.pricing_type_id === 1">Price per {{c.category}} ({{currency_symbol}})</label>
                        <label v-if="row.item.pricing_type_id === 2">Price for {{c.category}} ({{currency_symbol}})</label>
                        <div class="row">
                          <div class="col">
                            <base-input>
                              <el-input-number placeholder="0.00" v-model="c.price" :controls="false" :precision="2" :step="0.1" />
                            </base-input>
                          </div>
                          <div class="col">
                            <small class="text-white mr-2">Original price: {{getAmount(c.original_price)}}</small>
                            <el-tooltip class="d-inline"
                                        content="Set to original price"
                                        effect="light"
                                        :open-delay="300"
                                        placement="right"
                            >
                              <i style="cursor: pointer" class="ml-1 tim-icons icon-refresh-02 text-white" v-on:click="setOriginalPrice(c)"></i>
                            </el-tooltip>
                          </div>
                        </div>
                      </div>
                      <base-button size="sm" type="primary" v-on:click="saveOverride(row.item)">Save</base-button>
                    </div>
                  </div>
                </div>
                <div v-if="!editing_bookings[row.index] && row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url')">
                  {{getAmount(row.item.price)}}
                </div>
                <div v-if="!editing_bookings[row.index] && !row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url')">
                  {{getAmount(row.item.amount)}}
                </div>
                <div v-if="!editing_bookings[row.index] && !row.item.hasOwnProperty('booking_date') && row.item.hasOwnProperty('design_url')">
                  <div>Purchased value: {{getAmount(row.item.total)}}</div>
                  <div>Price: {{getAmount(row.item.price)}}</div>
                  <div>Remaining: {{getAmount(row.item.remaining)}}</div>
                </div>
                <div v-if="editing_bookings[row.index] && !row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url')">
                  <div class="col">
                    {{getAmount(getPurchaseAmount(row.item.price, row.item.quantity))}}
                  </div>
                </div>
              </template>
              <template v-slot:cell(actions)="row">
                <el-dropdown placement="bottom-start"
                             v-if="(!row.item.hasOwnProperty('design_url') && !editing_bookings[row.index] &&  row.item.status !== 'cancelled')
                  || (row.item.is_active)">
                  <base-button
                    link
                    type="primary"
                    size="sm"
                  ><i style="font-size: large" class="tim-icons el-icon-more"></i>
                  </base-button>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <a v-if="row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url') &&
                       row.item.status !== 'cancelled' && hasPermissions('bookings_update')"
                         class="dropdown-item mb-2 mt-2" href="#" @click="editBooking(row.item, row.index, $event)">
                        <i class="tim-icons icon-pencil mr-2 text-warning"></i> Edit booking
                      </a>
                      <a v-if="!row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url')"
                         class="dropdown-item mb-2 mt-2" href="#" @click="editPurchase(row.item, row.index, $event)">
                        <i class="tim-icons icon-pencil mr-2 text-warning"></i> Edit item
                      </a>
                      <hr v-if="row.item.hasOwnProperty('booking_date')">
                      <a v-if="row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url') &&
                      row.item.status !== 'cancelled'" :style="{'cursor': cursor_type.resend_confirmation_email}"
                         class="dropdown-item" @click="resendConfirmationEmailPrompt(transaction, $event)" href="#">
                        <i v-if="!emailSettingActive('confirmation')" class="text-danger tim-icons icon-alert-circle-exc mr-2"></i>
                        <i v-else style="color: #42b883" class="tim-icons icon-email-85 mr-2"></i>
                        Send booking confirmation email
                        <p style="font-size: x-small;" class="text-default font-italic">
                          <span v-if="transaction.status === 0" class="ml-4">You cannot send a confirmation email on a cancelled transaction </span>
                          <span v-if="!emailSettingActive('confirmation') && transaction.status === 1" class="ml-4">You must set up the automated email settings to send confirmation emails</span>
                        </p>
                      </a>
                      <a v-if="row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url') &&
                        row.item.status !== 'cancelled'"
                         class="dropdown-item mb-2" href="#" @click="sendFollowUpEmailPrompt(row.item, $event)">
                        <i style="color: #42b883" class="tim-icons icon-email-85 mr-2"></i> Send follow-up email
                      </a>
                      <a v-if="row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url') &&
                          row.item.status !== 'cancelled'"
                         class="dropdown-item mb-2" href="#" @click="sendReminderEmailPrompt(row.item, $event)">
                        <i style="color: #42b883" class="tim-icons icon-email-85 mr-2"></i> Send reminder email
                      </a>
                      <a v-if="!row.item.hasOwnProperty('booking_date') && row.item.hasOwnProperty('design_url') && row.item.is_active"
                         :style="{'cursor': cursor_type.gift_card_confirmation_email}"
                         class="dropdown-item mb-2 mt-2 " href="#" @click="sendGiftCardEmailPrompt(row.item, $event)">
                        <i v-if="!emailSettingActive('gift_card_confirmation')" class="text-danger tim-icons icon-alert-circle-exc mr-2"></i>
                        <i v-else style="color: #42b883" class="tim-icons icon-email-85 mr-2"></i>
                        Send gift card purchase confirmation email
                        <p style="font-size: x-small;" class="text-default font-italic">
                          <span v-if="transaction.status === 0" class="ml-4">You cannot send a confirmation email on a cancelled transaction </span>
                          <span v-if="!emailSettingActive('gift_card_confirmation') && transaction.status === 1" class="ml-4">You must set up the automated email settings to send confirmation emails</span>
                        </p>
                      </a>
                      <hr>
                      <a v-if="row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url') &&
                       row.item.status !== 'cancelled' && hasPermissions('bookings_cancel')"
                         class="dropdown-item mb-2" href="#" @click="cancelBookingPrompt(row.item, $event)">
                        <i class="tim-icons icon-trash-simple mr-2 text-danger"></i> Cancel booking
                      </a>
                      <a v-if="!row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url')"
                         class="dropdown-item mb-2" href="#" @click="deleteItemPrompt(row.item, $event)">
                        <i class="tim-icons icon-trash-simple mr-2 text-danger"></i> Delete item
                      </a>
                      <a v-if="!row.item.hasOwnProperty('booking_date') && row.item.hasOwnProperty('design_url')
                        && hasPermissions('gift_cards_delete') && row.item.is_active === 1"
                         class="dropdown-item mb-2 mt-2" href="#" @click="deactivateGiftCardPrompt(row.item, $event)">
                        <i class="tim-icons icon-trash-simple mr-2 text-danger"></i> Deactivate gift card
                      </a>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
                <span v-if="row.item.hasOwnProperty('design_url') && !editing_bookings[row.index] && !row.item.is_active">-</span>

                <div class="row" v-if="row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url')">
                  <span v-if="row.item.status === 'cancelled'">-</span>
                  <base-button v-if="editing_bookings[row.index] && row.item.status !== 'cancelled'"
                               @click="cancelEdit(row.item, row.index)"
                               type="default"
                               size="sm"
                  > Cancel
                  </base-button>
                  <base-button v-if="editing_bookings[row.index] && row.item.status !== 'cancelled'"
                               @click="saveEditPrompt(row.item, row.index)"
                               class="ml-2"
                               type="primary"
                               size="sm"
                  > Save
                  </base-button>
                </div>
                <div class="row" v-if="!row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url')">
                  <base-button v-if="editing_bookings[row.index]"
                               @click="cancelEdit(row.item, row.index)"
                               type="default"
                               size="sm"
                  > Cancel
                  </base-button>
                  <base-button v-if="editing_bookings[row.index]"
                               @click="saveItemPrompt(row.item, row.index)"
                               class="ml-2"
                               type="primary"
                               size="sm"
                  > Save
                  </base-button>
                </div>
              </template>
            </b-table>
          </div>
<!--          Mobile-->
          <div class="d-sm-none d-block">
            <div v-if="bookings.length <= 0" class="mt-3" >
              <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
                <h4 class="mt-3">No bookings are associated with this transaction.</h4>
              </div>
            </div>
            <b-table v-if="bookings.length > 0" :items="bookings" :fields="['items']">
              <template v-slot:cell(items)="row">
                <div v-if="editing_bookings[row.index] && row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url')">
                  <div class="row">
                    <div class="col">
                      <base-input label="Game">
                        <el-select
                          label="Select game"
                          v-model="row.item.selectedGame"
                          class="select-primary"
                          v-on:change="getInitialSubtotal(row.item)"
                          placeholder="Game">
                          <el-option
                            v-for="game in games"
                            class="select-primary"
                            :label="game.name"
                            :value="game.name"
                            :key="game.id"
                          ></el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <base-input label="Date" :error="errs.booking_date">
                        <el-date-picker
                          type="date"
                          placeholder="Select date"
                          value-format="yyyy-MM-dd"
                          name="date"
                          v-model="row.item.booking_date"
                          :format="date_format_cal"
                          v-on:change="getAvailableBookings(row.item, 'date')"
                        >
                        </el-date-picker>
                      </base-input>
                    </div>
                    <div class="col-12">
                      <base-input label="Time" :error="errs.start_time">
                        <el-select
                          label="Select time"
                          v-model="row.item.start_time"
                          class="select-primary"
                          value-key="id"
                          placeholder="Time">
                          <el-option
                            v-for="(booking, index) in available_bookings"
                            class="select-primary"
                            :label="getTime(booking.start_time)  + '-' + getTime(booking.end_time)"
                            :value="{id: booking.id,
                          booking_date: booking.booking_date,
                          end_date: booking.end_date,
                          start_time: booking.start_time,
                          end_time: booking.end_time}"
                            :key="'A' + index"
                          ></el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                </div>
                <div v-if="!editing_bookings[row.index] && row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url')">
                  <div class="row">
                    <div class="col">
                      <p style="font-size: large" class="text-primary">{{ row.item.name }}
                        <el-tooltip content="View game history"
                                    effect="light"
                                    :open-delay="300"
                                    placement="top">
                          <base-button v-if="row.item.status === 'completed'"
                                       v-on:click="goToGame(row.item)" icon class="btn btn-link" simple size="sm" type="primary">
                            <i style="font-size: larger" class="tim-icons el-icon-top-right"></i>
                          </base-button>
                        </el-tooltip>
                      </p>
                    </div>
                    <div class="col-4 text-right">
                      <badge v-if="row.item.status == 'available'" type="success">available</badge>
                      <badge v-if="row.item.status == 'completed'" type="success">
                        completed
                      </badge>
                      <badge v-if="row.item.status == 'checked'" type="warning">checked in</badge>
                      <badge v-if="row.item.status == 'booked' && !hasPassed(row.item.booking_date)" type="primary">
                        upcoming
                      </badge>
                      <badge v-if="row.item.status == 'booked' && hasPassed(row.item.booking_date)" type="success">
                        completed
                      </badge>
                      <badge v-if="row.item.status === 'cancelled'" type="danger">cancelled</badge>
                      <p v-if="!row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url')">-</p>
                      <badge v-if="!row.item.hasOwnProperty('booking_date')
                  && row.item.hasOwnProperty('design_url') && row.item.is_active" type="success">active
                      </badge>
                      <badge v-if="!row.item.hasOwnProperty('booking_date')
                  && row.item.hasOwnProperty('design_url') && !row.item.is_active" type="danger">deactivated
                      </badge>
                    </div>
                  </div>
                  <p>
                    <i class="tim-icons icon-calendar-60"></i> {{getFormattedDate(row.item.booking_date)}}
                    &nbsp; <i class="tim-icons icon-time-alarm"></i> {{getTime(row.item.start_time)}} -  {{getTime(row.item.end_time)}}
                  </p>
                  <p style="font-size: x-small">Booking id: #{{row.item.id}}
                  </p>
                </div>
                <div class="mb-2" v-if="!row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url')">
                  <p style="font-size: large" class="text-primary">{{ row.item.name }}</p>
                  <p style="font-size: x-small" v-if="row.item.category_name">{{row.item.category_name}}
                  </p>
                  <p style="font-size: x-small" v-if="row.item.is_custom">{{row.item.description}}
                  </p>
                </div>
                <div class="mb-3" v-if="!editing_bookings[row.index] && !row.item.hasOwnProperty('booking_date') && row.item.hasOwnProperty('design_url')">
                  <p style="font-size: large" class="text-primary">Gift card</p>
                  <p style="font-size: x-small">{{getGiftCardCode(row.item.code)}}</p>
                  <p v-if="row.item.delivery_date" style="font-size: x-small">Delivery date: {{getDayOfWeek(row.item.delivery_date)}}  {{getFormattedDate(row.item.delivery_date)}}</p>
                  <p v-if="!row.item.delivery_date" style="font-size: x-small">Delivery date:  {{getLocalDate(transaction.timestamp)}}</p>
                  <p v-if="row.item.game_name" style="font-size: x-small">Purchased for {{row.item.game_name}}, {{row.item.number_of_participants}} participants</p>
                  <img style="object-fit: cover; height: 75px" :src="row.item.design_url"/>
                </div>

                <div v-if="editing_bookings[row.index] && row.item.hasOwnProperty('booking_date')  && !row.item.hasOwnProperty('design_url')" >
                  <div class="row" :key="pr_key">
                    <div class="col-lg-8">
                      <div  v-for="(c, i) in row.item.pricing_categories" :key="'CX' + i">
                        <label class="text-primary text-uppercase">{{c.category}}</label>
                        <base-input>
                          <el-input-number @change="getSubtotal(row.item)" v-model="c.number_of_players" :min="0" />
                        </base-input>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="!editing_bookings[row.index] && row.item.hasOwnProperty('booking_date')  && !row.item.hasOwnProperty('design_url')">
                  Number of participants: x{{row.item.group_size}}
                </div>
                <div v-if="!editing_bookings[row.index] && !row.item.hasOwnProperty('booking_date')  && !row.item.hasOwnProperty('design_url')">
                  Quantity: x{{row.item.quantity}}
                </div>
                <div v-if="!editing_bookings[row.index] && !row.item.hasOwnProperty('booking_date')  && row.item.hasOwnProperty('design_url')">
                  Quantity: x1
                </div>

                <div v-if="editing_bookings[row.index] && !row.item.hasOwnProperty('booking_date')  && !row.item.hasOwnProperty('design_url')">
                  <div class="col-lg-8">
                    <base-input :error="errs.purchase.quantity" label="Quantity">
                      <el-input-number v-model="row.item.quantity" :min="1" />
                    </base-input>
                  </div>
                </div>

                <div v-if="editing_bookings[row.index] && row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url')" >
                  <div class="row">
                    <div class="col mt-2" v-if="!row.item.price_override">
                      <h2 style="margin-bottom: 0px">{{ getAmount(row.item.subtotal) }}</h2>
                      <small>
                        <div  v-for="(c, i) in row.item.pricing_categories" :key="'CC' + i">
                          <span class="text-primary">{{getRate(c, row.item)}}</span>
                        </div>
                      </small>
                      <base-button v-on:click="overridePrice(row.item)" class="mt-2" size="sm" type="primary">Override price</base-button>
                    </div>
                    <div class="col" v-else>
                      <div style="max-width: 90%" v-for="(c, index) in row.item.pricing_categories" :key="'CCDOVERRIDE' + index">
                        <label v-if="row.item.pricing_type_id === 1">Price per {{c.category}} ({{currency_symbol}})</label>
                        <label v-if="row.item.pricing_type_id === 2">Price for {{c.category}} ({{currency_symbol}})</label>
                        <div class="row">
                          <div class="col">
                            <base-input>
                              <el-input-number placeholder="0.00" v-model="c.price" :controls="false" :precision="2" :step="0.1" />
                            </base-input>
                          </div>
                          <div class="col">
                            <small class="text-white mr-2">Original price: {{getAmount(c.original_price)}}</small>
                            <el-tooltip class="d-inline"
                                        content="Set to original price"
                                        effect="light"
                                        :open-delay="300"
                                        placement="right"
                            >
                              <i style="cursor: pointer" class="ml-1 tim-icons icon-refresh-02 text-white" v-on:click="setOriginalPrice(c)"></i>
                            </el-tooltip>
                          </div>
                        </div>
                      </div>
                      <base-button size="sm" type="primary" v-on:click="saveOverride(row.item)">Save</base-button>
                    </div>
                  </div>
                </div>

                <div v-if="!editing_bookings[row.index] && row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url')">
                  Price: {{getAmount(row.item.price)}}
                </div>
                <div v-if="!editing_bookings[row.index] && !row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url')">
                  Price: {{getAmount(row.item.amount)}}
                </div>
                <div v-if="!editing_bookings[row.index] && !row.item.hasOwnProperty('booking_date') && row.item.hasOwnProperty('design_url')">
                  Total: {{getAmount(row.item.total)}} Remaining: {{getAmount(row.item.remaining)}}
                </div>
                <div v-if="editing_bookings[row.index] && !row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url')">
                  <div class="row">
                    <div class="col">
                      <label>Price per unit ({{currency_symbol}})</label>
                      <h4>{{getAmount(row.item.price)}}</h4>
                    </div>
                    <div class="col">
                      <label>Subtotal ({{currency_symbol}})</label>
                      <h4>{{getAmount(getPurchaseAmount(row.item.price, row.item.quantity))}}</h4>
                    </div>
                  </div>
                </div>

                <div
                  class="btn-group btn-group-toggle mt-3"
                  :class="'float-left'"
                  data-toggle="buttons" style="width:100%; max-width: 100%"
                >
                  <label v-if="row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url') &&
                  !editing_bookings[row.index] && row.item.status !== 'cancelled' && hasPermissions('bookings_update')"
                    class="btn btn-sm btn-primary btn-simple">
                    <base-button style="padding-left: 0; padding-right: 0"
                                 @click.native="editBooking(row.item, row.index, $event)"
                                 type="primary"
                                 class="btn btn-link"
                                 size="sm"
                                 icon
                    ><i class="tim-icons icon-pencil"></i>
                    </base-button>
                  </label>
                  <label class="btn btn-sm btn-primary btn-simple"
                         v-if="row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url') &&
                         editing_bookings[row.index] && row.item.status !== 'cancelled'">
                    <base-button style="padding-left: 0; padding-right: 0"
                                 @click.native="cancelEdit(row.item, row.index)"
                                 type="primary"
                                 class="btn btn-link"
                                 size="sm"
                                 icon
                    ><i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                  </label>
                  <label class="btn btn-sm btn-primary btn-simple"
                         v-if="row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url') &&
                         editing_bookings[row.index] && row.item.status !== 'cancelled'">
                    <base-button style="padding-left: 0; padding-right: 0"
                                 @click.native="saveEditPrompt(row.item, row.index)"
                                 class="like btn-link"
                                 type="primary"
                                 size="sm"
                                 icon
                    ><i class="tim-icons icon-check-2"></i>
                    </base-button>
                  </label>
                  <label class="btn btn-sm btn-primary btn-simple"
                         v-if="row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url') &&
                         !editing_bookings[row.index] && row.item.status !== 'cancelled'">
                    <base-button style="padding-left: 0; padding-right: 0"
                                 @click.native="cancelBookingPrompt(row.item, $event)"
                                 type="primary"
                                 class="btn btn-link"
                                 size="sm"
                                 icon
                    ><i class="tim-icons icon-trash-simple"></i>
                    </base-button>
                  </label>
                  <label class="btn btn-sm btn-primary btn-simple"
                         v-if="row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url') &&
                         !editing_bookings[row.index] && row.item.status !== 'cancelled'">
                    <el-dropdown>
                      <base-button style="padding-left: 0; padding-right: 0"
                        simple
                        type="primary"
                        link
                      > <i class="tim-icons icon-email-85"></i>
                      </base-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <a
                            style="font-size: 0.75rem; padding-top: 0.6rem"
                            class="text-default dropdown-item"
                            @click="resendConfirmationEmailPrompt(row.item, $event)" href="#">Booking confirmation email</a>
                          <a style="font-size: 0.75rem; padding-bottom: 0.6rem; padding-top: 0.6rem" class="text-default dropdown-item" @click="sendReminderEmailPrompt(row.item, $event)" href="#">Reminder email</a>
                          <a style="font-size: 0.75rem" class="text-default dropdown-item" @click="sendFollowUpEmailPrompt(row.item, $event)" href="#">Follow-up email</a>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </label>
                  <label class="btn btn-sm btn-primary btn-simple"
                         v-if="!editing_bookings[row.index] && row.item.is_active === 1 && hasPermissions('gift_cards_delete')">
                    <base-button style="padding-left: 0; padding-right: 0"
                                 @click.native="deactivateGiftCardPrompt(row.item)"
                                 class="like btn-link"
                                 type="primary"
                                 size="sm"
                                 icon
                    ><i class="tim-icons icon-trash-simple"></i>
                    </base-button>
                  </label>
                  <label class="btn btn-sm btn-primary btn-simple"
                         v-if="!row.item.hasOwnProperty('booking_date') && row.item.hasOwnProperty('design_url') &&
                         !editing_bookings[row.index]">
                    <el-dropdown>
                      <base-button style="padding-left: 0; padding-right: 0"
                                   simple
                                   type="primary"
                                   link
                      > <i class="tim-icons icon-email-85"></i>
                      </base-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <a
                            style="font-size: 0.75rem; padding-top: 0.6rem"
                            class="text-default dropdown-item"
                            @click="sendGiftCardEmailPrompt(row.item, $event)" href="#">Gift card purchase confirmation email</a>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </label>

                  <label v-if="!row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url') && !editing_bookings[row.index]"
                         class="btn btn-sm btn-primary btn-simple">
                    <base-button style="padding-left: 0; padding-right: 0"
                                 @click.native="editPurchase(row.item, row.index, $event)"
                                 type="primary"
                                 class="btn btn-link"
                                 size="sm"
                                 icon
                    ><i class="tim-icons icon-pencil"></i>
                    </base-button>
                  </label>
                  <label v-if="!row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url') && !editing_bookings[row.index]"
                         class="btn btn-sm btn-primary btn-simple">
                    <base-button style="padding-left: 0; padding-right: 0"
                                 @click.native="deleteItemPrompt(row.item)"
                                 type="primary"
                                 class="btn btn-link"
                                 size="sm"
                                 icon
                    ><i class="tim-icons icon-trash-simple"></i>
                    </base-button>
                  </label>
                  <label class="btn btn-sm btn-primary btn-simple"
                         v-if="!row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url') &&
                         editing_bookings[row.index]">
                    <base-button style="padding-left: 0; padding-right: 0"
                                 @click.native="cancelEdit(row.item, row.index)"
                                 type="primary"
                                 class="btn btn-link"
                                 size="sm"
                                 icon
                    ><i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                  </label>
                  <label class="btn btn-sm btn-primary btn-simple"
                         v-if="!row.item.hasOwnProperty('booking_date') && !row.item.hasOwnProperty('design_url') &&
                         editing_bookings[row.index]">
                    <base-button style="padding-left: 0; padding-right: 0"
                                 @click.native="saveItemPrompt(row.item, row.index)"
                                 type="primary"
                                 class="btn btn-link"
                                 size="sm"
                                 icon
                    ><i class="tim-icons icon-check-2"></i>
                    </base-button>
                  </label>
                </div>
              </template>
            </b-table>
          </div>

        </div>


<!--        Payments-->
        <div class="row" v-if="activeTabIndex === 1">
          <div class="col">
            <el-dropdown placement="bottom-start">
              <base-button size="sm"
                           simple
                           type="success" class="mr-2"

              > Payment options <i class="tim-icons icon-minimal-down ml-1"></i>
              </base-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <a :style="{'cursor': transaction.due <= 0 ? 'not-allowed' : 'pointer'}" class="dropdown-item mb-2 mt-2" href="#" @click="addPaymentModal($event)">
                    <i style="color: #2dce89" class="tim-icons icon-money-coins mr-2"></i> Add new payment
                    <p style="font-size: x-small;" class="text-default font-italic">
                      <span v-if="transaction.due <= 0" class="ml-4">No amount is due </span>
                    </p>
                  </a>
<!--                  <a v-if="payment_settings.payment_gateway_id === 2 && payment_settings.hardware_type_id === 1" class="dropdown-item mb-2" href="#" @click="sendToSquarePOS($event)">-->
<!--                    <i class="tim-icons icon-tablet-2 mr-2 text-default"></i> Send to Square Register-->
<!--                  </a>-->
<!--                  <a v-if="payment_settings.payment_gateway_id === 2 && payment_settings.hardware_type_id === 2" class="dropdown-item mb-2" href="#" @click="sendToSquarePOS($event)">-->
<!--                    <i class="tim-icons icon-tablet-2 mr-2 text-default"></i> Send to Square Terminal-->
<!--                  </a>-->
                  <hr>
                  <a class="dropdown-item mb-2" href="#" @click="enableCustomDeposit($event)">
                    <i class="tim-icons icon-coins mr-2 text-default"></i> Enable custom deposit
                  </a>
                  <a class="dropdown-item mb-2" href="#" @click="copyPaymentRequestLink($event)">
                    <i class="tim-icons icon-link-72 mr-2 text-info"></i> Copy payment request link
                  </a>
                  <a :style="{'cursor': cursor_type.payment_request}" class="dropdown-item" @click="sendPaymentRequestPrompt(transaction)" href="#">
                    <i v-if="email_settings && !email_settings[2].is_active" class="text-danger mr-2 tim-icons icon-alert-circle-exc"></i>
                    <i v-else style="color: #2dce89" class="tim-icons icon-email-85 mr-2"></i>
                    Send payment request
                    <p style="font-size: x-small;" class="text-default font-italic">
                      <span v-if="transaction.status === 0" class="ml-4">You cannot send a payment request email on a cancelled transaction </span>
                      <span v-if="transaction.due <= 0 && transaction.status === 1" class="ml-4">You cannot send a payment request email when no amount is due on the transaction </span>
                      <span v-if="email_settings && !email_settings[2].is_active && transaction.due > 0 && transaction.status === 1" class="ml-4">You must set up the automated email settings to send payment requests </span>
                    </p>
                  </a>
                </el-dropdown-menu>
              </template>
            </el-dropdown>

<!--            <base-button v-on:click="addPaymentModal()" simple type="success" size="sm">-->
<!--              <i class="tim-icons el-icon-plus"></i> Add new payment</base-button>-->
          </div>
          <div class="col-lg-2 text-right" v-if="transaction.custom_deposit_enabled">
            <label >Custom deposit amount</label>
            <p style="font-size: large"
               label="Custom deposit amount">{{getAmount(transaction.custom_deposit_amount)}}</p>

          </div>
        </div>

        <div v-if="activeTabIndex === 1 && payments.length <= 0" >
          <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            <h4 class="mt-3">No payments have been made.</h4>
          </div>
        </div>
        <div class="d-sm-block d-none">
          <b-table v-if="activeTabIndex === 1 && payments.length > 0" :items="payments" :fields="paymentFields">
            <template v-slot:cell(payment_details)="row">
              <div class="row">
                <div class="col">
                  <p v-if="row.item.type == 'card'" style="font-size: large">Card payment
                    <span v-if="row.item.gateway_transaction_id.length > 0 && row.item.card_type">({{row.item.card_type}})</span>
                    <badge class="ml-2" v-if="row.item.gateway_transaction_id.length > 0 && !row.item.card_type && row.item.status === 2">pending</badge>
                    <badge class="ml-2" type="danger" v-if="!row.item.status">failed</badge>
                  </p>
                  <p v-if="row.item.type == 'gift_card'" style="font-size: large">Gift card
                    <badge class="ml-2" type="danger" v-if="!row.item.status">failed</badge>
                  </p>
                  <p v-if="row.item.type == 'bank_transfer'" style="font-size: large">Bank transfer
                    <badge class="ml-2" v-if="row.item.status === 2 || row.item.status === 3">pending</badge>
                  </p>
                  <p v-if="row.item.type == 'cash'" style="font-size: large">Cash payment</p>
                  <p v-if="row.item.type == 'customer_credit'" style="font-size: large">Customer credit payment</p>
                  <p v-if="row.item.type == 'pos'" style="font-size: large">POS payment</p>
                  <p v-if="isCustomPaymentType(row.item.type)"
                     style="font-size: large">{{row.item.type}}
                    <badge class="ml-2" v-if="row.item.status === 2 || row.item.status === 3">pending</badge>
                  </p>

                  <p v-if="row.item.last_four" ><span class="font-weight-600">Card number: </span>xxxx-xxxx-xxxx-{{row.item.last_four}}</p>
                  <p v-if="row.item.cardholder_first_name">
                    <span class="font-weight-600" v-if="row.item.type == 'card'">Cardholder: </span>
                    <span class="font-weight-600" v-else>Payee: </span>
                    {{row.item.cardholder_first_name}} {{row.item.cardholder_last_name}}</p>
                  <p v-if="row.item.gateway_transaction_id && row.item.type === 'gift_card'">
                    <span class="font-weight-600">Gift card code: </span>
                    {{getGiftCardCode(row.item.gateway_transaction_id)}}</p>
                  <p v-else-if="row.item.gateway_transaction_id && row.item.type !== 'gift_card'">
                    <span class="font-weight-600">Gateway reference: </span>
                    {{row.item.gateway_transaction_id}}</p>
                  <p v-if="row.item.timestamp"><span class="font-weight-600">Paid on: </span>{{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}</p>
                  <small v-if="row.item.email" style="font-style: italic"><span class="font-weight-600">Payment collected by: </span>
                    {{row.item.first_name}} {{row.item.last_name}}
                  </small>
                </div>
                <!--              <div class="col">-->
                <!--                <p class="text-white" v-if="row.item.due_amount != null">Total: {{getAmount(row.item.due_amount)}}</p>-->
                <!--                <p class="text-danger" v-if="row.item.due_amount != null">Due: {{getDueAmount(row.item)}}</p>-->
                <!--                <p class="text-success" v-if="row.item.paid_amount != null">Paid: {{getAmount(row.item.paid_amount)}}</p>-->
                <!--              </div>-->
              </div>
              <div v-if="showRefund[row.index]">
                <div class="row mt-1">
                  <div class="col-7">
                    <base-input label="Refund options">
                      <el-select
                        v-model="selectedPaymentAmount"
                        v-on:change="toggleDisabledFlagForAmount(row.item)"
                        class="select-primary"
                        name="game"
                        placeholder="Refund option"
                        value-key="id"
                      >
                        <el-option
                          v-for="option in paymentAmountOptions"
                          class="select-primary"
                          :label="option.name"
                          :value="{id: option.id, name: option.name}"
                          :key="'B' + option.id"
                        ></el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-3">
                    <base-input v-on:change="validateRefundAmount()" :disabled="isAmountFieldDisabled" :label="'Amount (' + currency_symbol + ')'" v-model="refundAmount">
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <base-button v-on:click="$set(showRefund, row.index, 0)" simple size="sm" type="primary">Cancel</base-button>
                    <base-button v-on:click="processRefund(row.item, row.index)" class="ml-2" size="sm" type="primary">Make refund</base-button>

                  </div>
                </div>
              </div>
            </template>
            <template #head(amount)="row">
              <span></span>
            </template>
            <template v-slot:cell(amount)="row">
              <div class="row justify-content-center">
                <div class="col text-center">
                  <small>Amount</small>
                  <p style="font-size: large">{{getAmount(row.item.amount - row.item.refunded)}}</p>
                </div>
                <div v-if="row.item.refunded > 0" class="col text-center">
                  <small>Refunded</small>
                  <p style="font-size: large">{{getAmount(row.item.refunded)}}</p>
                </div>
              </div>

            </template>
            <template v-slot:cell(status)="row">
              <badge v-if="row.item.status == 1" type="success">Active</badge>
              <badge v-if="row.item.status == 0" type="danger">Cancelled</badge>
            </template>
            <!--          <template v-slot:cell(customer_details)="row">-->
            <!--            <p class="text-white">{{row.item.first_name}} {{row.item.last_name}}</p>-->
            <!--            <p>-->
            <!--              <i v-if="row.item.email != null" class="tim-icons icon-email-85"></i> {{ row.item.email }}-->
            <!--            </p>-->
            <!--            <p>-->
            <!--              <i v-if="row.item.phone != null && row.item.length > 0" class="tim-icons el-icon-mobile-phone"></i> {{row.item.phone}}-->
            <!--            </p>-->
            <!--            <base-button v-on:click="viewCustomerDetails(row.item.end_user_id)" size="sm" simple type="info">View customer details</base-button>-->
            <!--          </template>-->

            <template v-slot:cell(actions)="row">
              <p v-if="!row.item.status">-</p>
            <span>
              <el-dropdown v-if="row.item.status">
                <base-button
                  link
                  type="primary"
                  size="sm"
                > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                </base-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <a v-if="isCustomPaymentType(row.item.type) && (row.item.status === 2 || row.item.status === 3)"
                       class="dropdown-item" @click="markAsPaidPrompt(row.item, row.index)" href="#">
                       <i style="color: #2dce89" class="tim-icons icon-check-2 mr-2"></i> Mark as paid
                    </a>
                    <a v-if="(isRefundable(row.item))
                    && row.item.type !== 'bank_transfer'
                    && !isCustomPaymentType(row.item.type)"
                       class="dropdown-item" @click="showRefundForPayment(row.item, row.index)" href="#">
                       <i class="tim-icons text-default icon-refresh-01 mr-2"></i> Refund payment
                    </a>
                    <a class="dropdown-item" v-if="isRefundable(row.item) && (row.item.type === 'card' || row.item.type === 'bank_transfer' ||
                    isCustomPaymentType(row.item.type)) && (row.item.status !== 2 && row.item.status !== 3)"
                       @click="markAsRefundedPrompt(row.item, row.index)" href="#">

                      <div class="row">
                        <div class="col">
                          <i class="tim-icons text-default icon-tag mr-2"></i>
                           Mark as refunded
                        </div>
                      </div>
                      <div class="row">
                        <div class="col ml-4">
                           <small style="font-style: italic">Will not process card payment</small>
                        </div>
                      </div>
                    </a>
                    <a class="dropdown-item mb-1" @click="refundEmailConfirmationPrompt(row.item)" href="#">
                       <i style="color: #42b883" class="tim-icons icon-email-85 mr-2"></i>  Send refund email
                    </a>
                    <hr v-if="isRemovablePayment(row.item)">
                    <a v-if="isRemovablePayment(row.item)" class="dropdown-item mb-1" @click="deletePaymentRecordPrompt(row.item, row.index)" href="#">
                       <i class="tim-icons text-danger icon-trash-simple mr-2"></i> Delete payment record
                    </a>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </span>
            </template>
          </b-table>
        </div>
<!--        Mobile-->
        <div class="d-sm-none d-block">
          <b-table v-if="activeTabIndex === 1 && payments.length > 0" :items="payments" :fields="['payments']">
            <template v-slot:cell(payments)="row">
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-9">
                      <p v-if="row.item.type == 'card'" style="font-size: large">Card payment
                        <span v-if="row.item.gateway_transaction_id.length > 0 && row.item.card_type">({{row.item.card_type}})</span>
                        <badge class="ml-2" v-if="row.item.gateway_transaction_id.length > 0 && !row.item.card_type">pending</badge>
                        <badge class="ml-2" type="danger" v-if="!row.item.status">failed</badge>
                      </p>
                      <p v-if="row.item.type == 'gift_card'" style="font-size: large">Gift card
                        <badge class="ml-2" type="danger" v-if="!row.item.status">failed</badge>
                      </p>
                      <p v-if="row.item.type == 'bank_transfer'" style="font-size: large">Bank transfer
                        <badge class="ml-2" v-if="row.item.status === 2 || row.item.status === 3">pending</badge>
                        <badge class="ml-2" type="danger" v-if="!row.item.status">failed</badge>
                      </p>
                      <p v-if="row.item.type == 'cash'" style="font-size: large">Cash payment
                        <badge class="ml-2" type="danger" v-if="!row.item.status">failed</badge>
                      </p>
                      <p v-if="row.item.type == 'customer_credit'" style="font-size: large">Customer credit payment
                        <badge class="ml-2" type="danger" v-if="!row.item.status">failed</badge>
                      </p>
                      <p v-if="row.item.type == 'pos'" style="font-size: large">POS payment
                        <badge class="ml-2" type="danger" v-if="!row.item.status">failed</badge>
                      </p>
                    </div>
                    <div class="col text-right">
                      <span v-if="!showRefund[row.index]">
                        <el-dropdown v-if="row.item.status">
                          <base-button
                            link
                            type="primary"
                            size="sm"
                          > <i style="font-size: large" class="tim-icons el-icon-more"></i>
                          </base-button>
                          <template #dropdown>
                            <el-dropdown-menu>
                              <a v-if="isCustomPaymentType(row.item.type) && (row.item.status === 2 || row.item.status === 3)" class="dropdown-item" @click="markAsPaidPrompt(row.item, row.index)" href="#">
                                 <i style="color: #2dce89" class="tim-icons icon-check-2 mr-2"></i> Mark as paid
                              </a>
                              <a v-if="isRefundable(row.item) && row.item.type !== 'bank_transfer' && !isCustomPaymentType(row.item.type)"
                                 class="dropdown-item" @click="showRefundForPayment(row.item, row.index)" href="#">
                                 <i class="tim-icons text-default icon-refresh-01 mr-2"></i> Refund payment
                              </a>
                              <a class="dropdown-item" v-if="isRefundable(row.item) &&
                              (row.item.type === 'card' || row.item.type === 'bank_transfer' ||
                              isCustomPaymentType(row.item.type)) && (row.item.status !== 2 && row.item.status !== 3)" @click="markAsRefundedPrompt(row.item, row.index)" href="#">

                                <div class="row">
                                  <div class="col">
                                    <i class="tim-icons text-default icon-tag mr-2"></i>
                                     Mark as refunded
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col ml-4">
                                     <small style="font-style: italic">Will not process card payment</small>
                                  </div>
                                </div>

                              </a>
                              <a class="dropdown-item mb-1" @click="refundEmailConfirmationPrompt(row.item)" href="#">
                                 <i style="color: #42b883" class="tim-icons icon-email-85 mr-2"></i>  Send refund email
                              </a>
                              <hr v-if="isRemovablePayment(row.item)">
                              <a v-if="isRemovablePayment(row.item)" class="dropdown-item mb-1" @click="deletePaymentRecordPrompt(row.item, row.index)" href="#">
                                 <i class="tim-icons text-danger icon-trash-simple mr-2"></i> Delete payment record
                              </a>
                            </el-dropdown-menu>
                          </template>
                        </el-dropdown>
                      </span>
                    </div>
                  </div>
                  <p v-if="row.item.last_four" ><span class="font-weight-600">Card number: </span>xxxx-xxxx-xxxx-{{row.item.last_four}}</p>
                  <p v-if="row.item.cardholder_first_name">
                    <span class="font-weight-600" v-if="row.item.type == 'card'">Cardholder: </span>
                    <span class="font-weight-600" v-else>Payee: </span>
                    {{row.item.cardholder_first_name}} {{row.item.cardholder_last_name}}</p>
                  <p v-if="row.item.gateway_transaction_id && row.item.type === 'gift_card'">
                    <span class="font-weight-600">Gift card code: </span>
                    {{getGiftCardCode(row.item.gateway_transaction_id)}}</p>
                  <p v-else-if="row.item.gateway_transaction_id && row.item.type !== 'gift_card'">
                    <span class="font-weight-600">Gateway reference: </span>
                    {{row.item.gateway_transaction_id}}</p>
                  <p v-if="row.item.timestamp"><span class="font-weight-600">Paid on: </span>{{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}</p>
                  <div class="row">
                    <div class="col">
                      <p>Amount: {{getAmount(row.item.amount - row.item.refunded)}}</p>
                    </div>
                    <div v-if="row.item.refunded > 0" class="col text-center">
                      <p>Refunded: {{getAmount(row.item.refunded)}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="showRefund[row.index]">
                <div class="row mt-1">
                  <div class="col-12">
                    <base-input label="Refund options">
                      <el-select
                        v-model="selectedPaymentAmount"
                        v-on:change="toggleDisabledFlagForAmount(row.item)"
                        class="select-primary"
                        name="game"
                        placeholder="Refund option"
                        value-key="id"
                      >
                        <el-option
                          v-for="option in paymentAmountOptions"
                          class="select-primary"
                          :label="option.name"
                          :value="{id: option.id, name: option.name}"
                          :key="'B' + option.id"
                        ></el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-12">
                    <base-input type="number" :disabled="isAmountFieldDisabled" :label="'Amount (' + currency_symbol + ')'" v-model="refundAmount">
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <base-button v-on:click="$set(showRefund, row.index, 0)" simple size="sm" type="primary">Cancel</base-button>
                    <base-button v-on:click="processRefund(row.item, row.index)" class="ml-2" size="sm" type="primary">Make refund</base-button>
                  </div>
                </div>
              </div>
            </template>
          </b-table>
        </div>



        <!--        Promotions-->
        <div v-if="activeTabIndex === 2">
          <div class="row" v-if="!add_promo && transaction.status === 1">
            <div class="col">
              <base-button v-on:click="addPromoCode()" simple type="success" size="sm">
                <i class="tim-icons el-icon-plus"></i> Add promo code</base-button>
            </div>
          </div>
          <div class="row" v-if="add_promo">
            <div class="col-lg-4">
              <div class="row">
                <div class="col">
                  <base-input label="Promo" :error="errs.promo_code">
                    <el-autocomplete style="width: 100%"
                                     v-model="promo_code"
                                     :fetch-suggestions="promoSearch"
                                     placeholder="Search by code..."
                                     :trigger-on-focus="false"
                                     @select="handlePromoSelect"
                    >
                      <template #default="{ item }">
                        <div class="row">
                          <div class="col">
                            <div ><span class="text-primary">{{ item.name }} - </span>
                              <small>
                                <span v-if="item.redemption_type === 0">{{item.value_amount}}%</span>
                                <span v-if="item.redemption_type === 1">{{getAmount(item.value_amount)}}</span>
                                <span v-if="item.value_type === 0"> per transaction</span>
                                <span v-if="item.value_type === 2"> per participant</span>
                                <span class="ml-2"
                                      style="text-transform: uppercase; line-height: 9px; text-decoration: none; border: none;
                            color: white; background-color: #344675; border-radius: 2px; padding: 2px 5px 2px 5px; font-size: 9px"
                                      v-if="item.internal_only">internal only</span>
                              </small>
                            </div>
                          </div>
                        </div>
                      </template>
                    </el-autocomplete>
                  </base-input>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <base-button v-on:click="add_promo = false" type="default">Cancel</base-button>
                  <base-button v-on:click="applyPromoCode()" class="ml-2" type="primary">Apply</base-button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="promos.length <= 0 && !add_promo" class="mt-3" >
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No promos have been applied.</h4>
            </div>
          </div>
          <div class="d-sm-block d-none">
            <b-table v-if="promos.length > 0 && !add_promo" :items="promos" :fields="['code', 'description', 'amount', 'date_applied', 'actions']">
              <template v-slot:cell(code)="row">
                <p v-if="row.item.hasOwnProperty('name')">{{ row.item.name }}</p>
              </template>
              <template v-slot:cell(description)="row">
                <span v-if="row.item.description">{{ row.item.description }}</span>
                <span v-if="row.item.design_url">
                <img style="object-fit: cover; height: 75px" :src="row.item.design_url"/>
              </span>
                <span v-if="!row.item.description && !row.item.design_url">-</span>
              </template>
              <template v-slot:cell(amount)="row">
                <span>{{ getAmount(row.item.amount) }}</span>
              </template>
              <template v-slot:cell(date_applied)="row">
                <span>{{ getLocalDate(row.item.timestamp) }} {{ getLocalTime(row.item.timestamp) }}</span>
              </template>
              <template v-slot:cell(actions)="row">
                <el-tooltip content="Delete"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <base-button
                    type="danger"
                    class="btn btn-link"
                    aria-label="Close"
                    icon
                    @click="deleteDiscountPrompt(row.item)"
                  >
                    <i class="tim-icons icon-trash-simple"></i>
                  </base-button>
                </el-tooltip>
              </template>
            </b-table>
          </div>
<!--          Mobile-->
          <div class="d-block d-sm-none">
            <b-table v-if="promos.length > 0 && !add_promo" :items="promos" :fields="['promo_codes']">
              <template v-slot:cell(promo_codes)="row">
                <div class="row">
                  <div class="col">
                    <p v-if="row.item.hasOwnProperty('name')">{{ row.item.name }}</p>
                    <small v-if="row.item.description">{{ row.item.description }}</small>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <span>Amount: {{ getAmount(row.item.amount) }}</span>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <span>Date applied: {{ getLocalDate(row.item.timestamp) }} {{ getLocalTime(row.item.timestamp) }}</span>
                  </div>
                </div>

                <div
                  class="btn-group btn-group-toggle mt-3"
                  :class="'float-left'"
                  data-toggle="buttons" style="width:100%; max-width: 100%"
                >
                  <label
                         class="btn btn-sm btn-primary btn-simple">
                    <base-button style="padding-left: 0; padding-right: 0"
                                 @click.native="deleteDiscountPrompt(row.item)"
                                 type="primary"
                                 class="btn btn-link"
                                 size="sm"
                                 icon
                    ><i class="tim-icons icon-trash-simple"></i>
                    </base-button>
                  </label>
                </div>
              </template>
            </b-table>
          </div>
        </div>

<!--        Taxes/fees-->
        <div v-if="activeTabIndex === 3">
          <div class="row" v-if="!add_fee && transaction.status === 1">
            <div class="col">
              <base-button v-on:click="addFee()" simple type="success" size="sm">
                <i class="tim-icons el-icon-plus"></i> Add tax, fee, or tip</base-button>
            </div>
          </div>
          <div class="row" v-if="add_fee">
            <div class="col">
              <div class="row">
                <div class="col-lg-2">
                  <base-input label="Type" >
                    <el-select
                      v-model="tax_fee_type"
                      class="select-primary"
                      name="bookings"
                      placeholder="Tax or fee"
                      value-key="id"
                    >
                      <el-option
                        v-for="(tft, i) in tax_fee_types"
                        class="select-primary"
                        :label="tft.name"
                        :value="{id: tft.id, name: tft.name}"
                        :key="'TFT' + i"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>

                <div class="col-lg-3" v-if="tax_fee_type && tax_fee_type.id === 0">
                  <base-input label="Taxes" :error="errs.tax">
                    <el-select
                      v-model="selectedTax"
                      class="select-primary"
                      name="bookings"
                      placeholder="Tax"
                      value-key="id"
                    >
                      <el-option
                        v-for="(t, i) in taxes"
                        class="select-primary"
                        :label="t.name"
                        :value="{id: t.id, name: t.name, amount: t.amount, is_compound: t.is_compound, value_type: t.value_type, pricing_type: t.pricing_type}"
                        :key="'T' + i"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-lg-3" v-if="tax_fee_type && tax_fee_type.id === 1">
                  <base-input label="Fees" :error="errs.fee">
                    <el-select
                      v-model="selectedFee"
                      class="select-primary"
                      name="bookings"
                      placeholder="Fee"
                      value-key="id"
                    >
                      <el-option
                        v-for="(f, i) in fees"
                        class="select-primary"
                        :label="f.name"
                        :value="{id: f.id, name: f.name, amount: f.amount,
                        value_type: f.value_type,
                        apply_fee_to_gift_cards: f.apply_fee_to_gift_cards }"
                        :key="'F' + i"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
<!--                Tip-->
                <div class="col-lg-2" v-if="tax_fee_type && tax_fee_type.id === 2">
                  <base-input :error="errs.tip_amount" placeholder="0" :label="'Tip amount (' + currency_symbol + ')'" v-model.number="tip_amount"></base-input>
                </div>
                <div class="col-lg-3" v-if="tax_fee_type && tax_fee_type.id === 2">
                  <base-input label="Assignee (optional)">
                    <el-autocomplete style="width: 100%"
                                     v-model="company_user_value"
                                     :fetch-suggestions="querySearchCu"
                                     placeholder="Search"
                                     :trigger-on-focus="false"
                                     @select="handleSelectCu"
                    >
                      <template #default="{ item }">
                        <p style="margin-bottom: -12px" class="text-primary">{{ item.first_name }} {{ item.last_name }}</p>
                        <small class="font-italic">
                          <i class="tim-icons icon-email-85"></i> {{item.email }} &nbsp;
                        </small>
                      </template>
                    </el-autocomplete>
                  </base-input>
                </div>
                <div class="col-lg-2" v-if="tax_fee_type && tax_fee_type.id === 0">
                  <label>Amount ({{currency_symbol}})</label>
                  <h4>{{getAmount(getTaxAmount())}}</h4>
                </div>
                <div class="col-lg-2" v-if="tax_fee_type && tax_fee_type.id === 1">
                  <label>Amount ({{currency_symbol}})</label>
                  <h4>{{getAmount(getFeeAmount())}}</h4>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <base-button v-on:click="add_fee = false" type="default">Cancel</base-button>
                  <base-button v-on:click="applyTax()" class="ml-2" type="primary">Apply</base-button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="taxes_fees.length <= 0 && !add_fee" class="mt-3" >
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No taxes or fees have been applied.</h4>
            </div>
          </div>
          <div class="d-sm-block d-none">
            <b-table v-if="taxes_fees.length > 0 && !add_fee" :items="taxes_fees" :fields="['name', 'type', 'amount', 'date_applied', 'actions']">
              <template v-slot:cell(name)="row">
                <span>{{ row.item.name }}</span>
                <span v-if="row.item.email">{{ row.item.first_name }} {{ row.item.last_name }}</span>
                <span v-if="!row.item.name && !row.item.email">Generic</span>
              </template>
              <template v-slot:cell(type)="row">
                <span v-if="row.item.hasOwnProperty('email')"><badge type="info">tip</badge> </span>
                <span v-if="row.item.tax_id"><badge type="success">tax</badge> </span>
                <span v-if="row.item.fee_id"><badge type="warning">fee</badge> </span>
              </template>
              <template v-slot:cell(amount)="row">
                <span>{{ getAmount(row.item.amount) }}</span>
              </template>
              <template v-slot:cell(date_applied)="row">
                <span>{{ getLocalDate(row.item.timestamp) }} {{ getLocalTime(row.item.timestamp) }}</span>
              </template>
              <template v-slot:cell(actions)="row">
                <el-tooltip content="Delete"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <base-button
                    type="danger"
                    class="btn btn-link"
                    aria-label="Close"
                    icon
                    @click="deleteTaxFeePrompt(row.item)"
                  >
                    <i class="tim-icons icon-trash-simple"></i>
                  </base-button>
                </el-tooltip>
              </template>
            </b-table>
          </div>
<!--          Mobile-->
          <div class="d-block d-sm-none">
            <b-table v-if="taxes_fees.length > 0 && !add_fee" :items="taxes_fees" :fields="['taxes_and_fees']">
              <template v-slot:cell(taxes_and_fees)="row">
                <div class="row">
                  <div class="col">
                    <span>{{ row.item.name }}</span>
                  </div>
                  <div class="col text-right">
                    <span v-if="row.item.tax_id"><badge type="success">tax</badge> </span>
                    <span v-if="row.item.fee_id"><badge type="warning">fee</badge> </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <small>Amount: {{ getAmount(row.item.amount) }}</small>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <small>Date applied: {{ getLocalDate(row.item.timestamp) }} {{ getLocalTime(row.item.timestamp) }}</small>
                  </div>
                </div>
                <div
                  class="btn-group btn-group-toggle mt-3"
                  :class="'float-left'"
                  data-toggle="buttons" style="width:100%; max-width: 100%"
                >
                  <label
                    class="btn btn-sm btn-primary btn-simple">
                    <base-button style="padding-left: 0; padding-right: 0"
                                 @click.native="deleteTaxFeePrompt(row.item)"
                                 type="primary"
                                 class="btn btn-link"
                                 size="sm"
                                 icon
                    ><i class="tim-icons icon-trash-simple"></i>
                    </base-button>
                  </label>
                </div>
              </template>
            </b-table>
          </div>
        </div>


<!--        Customers-->
        <div v-if="activeTabIndex === 4" class="mt-3" >
          <div class="row">
            <div class="col">
              <router-link class="btn btn-primary btn-sm" tag="a" :to="'/customers/details?id=' + transaction.end_user_id">
                View customer details
              </router-link>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-lg-6 col-12">
              <base-input label="Customer lookup (search by name, email, or phone number)">
                <el-autocomplete style="width: 100%"
                                 v-model="lookup_value"
                                 :fetch-suggestions="querySearch"
                                 placeholder="Search"
                                 :trigger-on-focus="false"
                                 @select="handleSelect"
                >
                  <template #default="{ item }">
                    <p style="margin-bottom: -12px" class="text-primary">{{ item.first_name }} {{ item.last_name }}</p>
                    <small class="font-italic">
                      <i class="tim-icons icon-email-85"></i> {{item.email }} &nbsp;
                      <i v-if="item.phone" class="tim-icons el-icon-mobile-phone"></i> {{item.phone }}
                    </small>

                  </template>
                </el-autocomplete>
              </base-input>
            </div>
            <div class="col">
              <base-input class="d-sm-none d-block">
                <base-button size="sm" simple type="success" v-on:click="clearCustomer()">Clear</base-button>
              </base-input>
              <base-input class="d-sm-block d-none" label=" ">
                <base-button simple type="success" v-on:click="clearCustomer()">Clear</base-button>
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12">
              <base-input label="First name" v-model="customer.first_name" placeholder="First name"></base-input>
              <p v-show="errs.first_name" class="text-danger small">First name cannot be empty</p>
            </div>
            <div class="col-lg-6 col-12">
              <base-input label="Last name" v-model="customer.last_name" placeholder="Last name"></base-input>
              <p v-show="errs.last_name" class="ml-1 text-danger small">Last name cannot be empty</p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12">
              <base-input label="Email" v-model="customer.email" placeholder="Email"></base-input>
              <p v-show="errs.email" class="ml-1 text-danger small">Please provide a valid email</p>
            </div>
            <div class="col-lg-6 col-12">
              <base-input label="Phone (Optional)" v-model="customer.phone" :placeholder="country.phone_placeholder"></base-input>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <base-button v-on:click="updateCustomer()" type="primary">
                Update customer
              </base-button>
            </div>
          </div>
        </div>

<!--        Waivers-->
        <div v-if="activeTabIndex === 5 && waivers.length <= 0" class="mt-3" >
          <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            <h4 class="mt-3">No waivers have been signed.</h4>
          </div>
        </div>
        <div class="d-none d-sm-block">
          <b-table v-if="activeTabIndex === 5 && waivers.length > 0" :items="waivers" :fields="waiverFields">
            <template v-slot:cell(customer_details)="row">
              <p>{{ row.item.first_name }} {{row.item.last_name}}
                <badge type="primary" v-if="row.item.is_minor" class="ml-1 mr-2">minor</badge>
                <badge v-if="row.item.not_participating === 1" type="danger" class="mr-2">not participating</badge>
                <el-tooltip
                  content="View customer details"
                  effect="light"
                  :open-delay="300"
                  placement="right"
                >
                  <i style="cursor: pointer" v-on:click="viewCustomerDetails(row.item.user_id)" class="tim-icons el-icon-top-right text-primary"></i>
                </el-tooltip>
              </p>
              <p v-if="row.item.birthday && row.item.birthday !== '0000-00-00'">
                <i class="tim-icons icon-gift-2"></i> {{getFormattedDate(row.item.birthday)}}
              </p>
              <p v-if="row.item.email != null">
                <i class="tim-icons icon-email-85"></i> {{ row.item.email }}
              </p>
              <p v-if="row.item.phone != null && row.item.phone.length > 0 ">
                <i class="tim-icons el-icon-mobile-phone"></i> {{row.item.phone}}
              </p>
            </template>
            <template v-slot:cell(date_signed)="row">
              <p><i class="tim-icons icon-calendar-60 mr-1"></i> {{ getLocalDate(row.item.created_time) }}
                <i class="tim-icons icon-time-alarm mr-1 ml-1"></i> {{getLocalTime(row.item.created_time)}}</p>
            </template>
            <template v-slot:cell(signature)="row">
              <p v-if="!row.item.signature" class="text-white">-</p>
              <img v-if="row.item.signature" style="background-color: white" :src="renderSignature(row.item.signature)" height="50">
            </template>

            <template v-slot:cell(actions)="row">
              <div class="row">
                <el-tooltip content="Delete waiver"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <base-button
                    @click="deleteWaiverPrompt(row.item)"
                    size="sm" type="danger" link
                    icon
                  ><i class="tim-icons icon-trash-simple"></i>
                  </base-button>
                </el-tooltip>
                <el-tooltip  content="Edit waiver"
                             effect="light"
                             :open-delay="300"
                             placement="top">
                  <base-button
                    @click.native="editWaiver(row.item)"
                    class="like btn-link"
                    type="warning"
                    size="sm"
                    icon
                  ><i class="tim-icons icon-pencil"></i>
                  </base-button>
                </el-tooltip>

                <vue-html2pdf
                  :show-layout="false"
                  :float-layout="true"
                  :enable-download="false"
                  :paginate-elements-by-height="800"
                  filename="waiver"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="portrait"
                  pdf-content-width="800px"
                  @beforeDownload="beforeDownload($event)"
                  ref="html2Pdf"
                >
                  <section slot="pdf-content">
                    <!-- Main Container -->
                    <div style="margin: 40px; font-family: Arial, sans-serif; color: #333;">
                      <!-- Game Name -->
                      <p style="color: black; font-size: 24px; font-weight: bold; color: #3358f4;">
                        {{ selectedWaiver.game_name }}
                      </p>

                      <!-- Booking Date and Time -->
                      <p style="color: black; font-size: 18px;">
                        {{ getFormattedDate(selectedWaiver.booking_date) }}, {{ getTime(selectedWaiver.start_time) }}
                      </p>

                      <!-- Waiver Text -->
                      <p style="color: black; font-size: 16px; margin-top: 20px;" v-if="selectedWaiver.waiver_text">Waiver</p>
                      <p v-if="selectedWaiver.waiver_text" v-html="selectedWaiver.waiver_text"
                         style="color: black; padding: 10px 10px 10px 10px; font-size: 14px; margin-top: 10px; line-height: 1.6; padding: 10px; background-color: #f0f0f0; border-radius: 5px;">
                      </p>

                      <!-- Signature Date and Customer Information -->
                      <p style="color: black; font-size: 16px; margin-top: 20px;">
                        Signed on {{ getLocalDate(selectedWaiver.created_time) }}, {{ getLocalTime(selectedWaiver.created_time) }}
                      </p>
                      <p style="color: black; font-size: 16px;">
                        Signed by {{ selectedWaiver.first_name && selectedWaiver.last_name ?
                        selectedWaiver.first_name + ' ' + selectedWaiver.last_name : selectedWaiver.email }}
                      </p>
                    </div>

                    <!-- Signature Section -->
                    <section class="pdf-item" style="margin-left: 40px; margin-top: 20px;">
                      <p style="font-size: 16px;">Signature</p>
                      <img :src="selectedWaiver.signature" style="max-width: 200px; height: auto;">
                    </section>
                  </section>
                </vue-html2pdf>

                <el-tooltip content="Download PDF"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                  <base-button
                    @click="downloadWaiver(row.item)"
                    class="like btn-link text-white"
                    size="sm"
                    icon
                  ><i class="tim-icons icon-cloud-download-93"></i>
                  </base-button>
                </el-tooltip>
              </div>

            </template>
          </b-table>
        </div>
<!--        Mobile-->
        <div class="d-sm-none d-block">
          <b-table v-if="activeTabIndex === 5 && waivers.length > 0" :items="waivers" :fields="['waivers']">
            <template v-slot:cell(waivers)="row">
              <p>{{ row.item.first_name }} {{row.item.last_name}}
                <badge type="primary" v-if="row.item.is_minor" class="ml-1 mr-2">minor</badge>
                <badge v-if="row.item.not_participating === 1" type="danger" class="mr-2">not participating</badge>
                <el-tooltip
                  content="View customer details"
                  effect="light"
                  :open-delay="300"
                  placement="right"
                >
                  <i style="cursor: pointer" v-on:click="viewCustomerDetails(row.item.user_id)" class="tim-icons el-icon-top-right text-primary"></i>
                </el-tooltip>
              </p>
              <p v-if="row.item.birthday && row.item.birthday !== '0000-00-00'">
                <i class="tim-icons icon-gift-2"></i> {{getFormattedDate(row.item.birthday)}}
              </p>
              <p v-if="row.item.email != null">
                <i class="tim-icons icon-email-85"></i> {{ row.item.email }}
              </p>
              <p v-if="row.item.phone != null && row.item.phone.length > 0 ">
                <i class="tim-icons el-icon-mobile-phone"></i> {{row.item.phone}}
              </p>
              <small>Date signed: {{ getLocalDate(row.item.created_time) }},
                {{getLocalTime(row.item.created_time)}}</small>
              <img v-if="row.item.signature" style="background-color: white" :src="renderSignature(row.item.signature)" height="50">
              <div
                class="btn-group btn-group-toggle mt-3"
                :class="'float-left'"
                data-toggle="buttons" style="width:100%; max-width: 100%"
              >
                <label
                  class="btn btn-sm btn-primary btn-simple">
                  <base-button style="padding-left: 0; padding-right: 0"
                               @click.native="editWaiver(row.item)"
                               type="primary"
                               class="btn btn-link"
                               size="sm"
                               icon
                  ><i class="tim-icons icon-pencil"></i>
                  </base-button>
                </label>
                <label
                  class="btn btn-sm btn-primary btn-simple">
                  <base-button style="padding-left: 0; padding-right: 0"
                               @click.native="deleteWaiverPrompt(row.item)"
                               type="primary"
                               class="btn btn-link"
                               size="sm"
                               icon
                  ><i class="tim-icons icon-trash-simple"></i>
                  </base-button>
                </label>
              </div>
            </template>
          </b-table>
        </div>

<!--        Custom fields-->
        <div v-if="activeTabIndex === 6 && custom_fields.length <= 0" class="mt-3" >
          <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            <h4 class="mt-3">No additional information has been recorded for this transaction.</h4>
          </div>
        </div>
        <div  v-if="activeTabIndex === 6 && custom_fields.length > 0" class="d-sm-block d-none">
          <b-table  :items="custom_fields"
                    :fields="['custom_field', 'response', 'timestamp']">
            <template v-slot:cell(custom_field)="row">
              {{row.item.label}}
              <span  v-if="row.item.type === 1">
                <span v-if="row.item.external_links" v-for="(el, i) in JSON.parse(row.item.external_links)" :key="'ELI' + i">
                  <a target="_blank" :href="el.link">{{el.link_text}}</a>
                </span>
                <span v-if="row.item.policies" v-for="(p, i) in JSON.parse(row.item.policies)" :key="'PLI' + i">
                  <base-button style="color: #ba54f5; font-weight:300; font-size: small; padding: 0"
                               size="sm" link class="btn btn-link" href="" v-on:click="openPolicy(p)">{{p.policy_link_text}}</base-button>
                </span>
                      <modal :show.sync="modals.policy"
                             body-classes="p-0"
                             modal-classes="modal-dialog-centered">
                        <card type="secondary"
                              header-classes="bg-white pb-5"
                              body-classes="px-lg-4 py-lg-4s"
                              class="border-0 mb-0">

                          <p class="text-white" v-html="selectedPolicy"></p>
                          <div class="row mt-3 justify-content-center">
                            <div class="col text-center">
                              <base-button v-on:click="modals.policy = false" type="primary">I agree</base-button>
                            </div>
                          </div>
                        </card>
                      </modal>
              </span>
            </template>
            <template v-slot:cell(response)="row">
              <span v-if="row.item.type === 1 && !row.item.response">No</span>
              <span v-if="row.item.type === 1 && row.item.response">Yes</span>
              <span v-if="row.item.type !== 1">{{row.item.response}}</span>
            </template>
            <template v-slot:cell(timestamp)="row">
              {{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}
            </template>
          </b-table>
        </div>
        <div class="d-sm-none d-block"  v-if="activeTabIndex === 6 && custom_fields.length > 0">
          <b-table :items="custom_fields"
                   :fields="['custom_field']">
            <template v-slot:cell(custom_field)="row">
              <p class="text-primary">{{row.item.label}}</p>
              <p v-if="row.item.type === 1 && !row.item.response">No</p>
              <p v-if="row.item.type === 1 && row.item.response">Yes</p>
              <p v-if="row.item.type !== 1">{{row.item.response}}</p>
              <small style="font-style: italic">{{getLocalDate(row.item.timestamp)}}, {{getLocalTime(row.item.timestamp)}}</small>
            </template>
          </b-table>
        </div>

<!--        Email events-->
        <div v-if="activeTabIndex === 7">
          <base-button class="text-white" style="padding-left: 0" link v-on:click="getTransaction()">
            <i class="tim-icons icon-refresh-01 mr-1 text-white"></i> Refresh
          </base-button>
          <div v-if="email_events.length <= 0">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">No emails associated with this transaction.</h4>
            </div>
          </div>
<!--          Mobile-->
          <div v-if="email_events.length > 0" class="d-sm-none d-block">
            <b-table :items="email_events" :fields="['email_event']">
              <template v-slot:cell(email_event)="row">
                <div class="row">
                  <div class="col">
                    <p style="font-weight: bold; font-size: medium">{{capitalizeFirstLetter(row.item.email_type)}}</p>
                  </div>
                  <div class="col text-right">
                    <badge v-if="row.item.event_type==='Open' || row.item.event_type==='Click' || row.item.event_type==='Subscription'" type="success">{{row.item.event_type}}</badge>
                    <badge v-if="row.item.event_type==='Bounce' || row.item.event_type==='Complaint' || row.item.event_type==='Reject'
                      || row.item.event_type==='Rendering Failure' || row.item.event_type==='DeliveryDelay'"
                           type="danger">{{row.item.event_type}}</badge>
                    <badge v-if="row.item.event_type==='Delivery' || row.item.event_type==='Send'" type="default">{{row.item.event_type}}</badge>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <small style="font-style: italic">{{getLocalDate(row.item.timestamp)}}, {{ getLocalTime(row.item.timestamp)}}</small>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    Recipient: {{row.item.customer_first_name}} {{row.item.customer_last_name}}
                  </div>
                </div>
                <div class="row" v-if="row.item.sender_email">
                  <div class="col">
                    Sender: {{row.item.sender_first_name}} {{row.item.sender_last_name}}
                  </div>
                </div>
              </template>
            </b-table>
          </div>
          <div v-if="email_events.length > 0" class="d-sm-block d-none">
            <b-table :items="email_events" :fields="['email_type', 'email_status', 'time_of_event', 'recipient', 'sender']">
              <template v-slot:cell(email_type)="row">
                {{capitalizeFirstLetter(row.item.email_type)}}
              </template>
              <template v-slot:cell(email_status)="row">
                <badge v-if="row.item.event_type==='Open' || row.item.event_type==='Click' || row.item.event_type==='Subscription'" type="success">{{row.item.event_type}}</badge>
                <badge v-if="row.item.event_type==='Bounce' || row.item.event_type==='Complaint' || row.item.event_type==='Reject'
                      || row.item.event_type==='Rendering Failure' || row.item.event_type==='DeliveryDelay'"
                       type="danger">{{row.item.event_type}}</badge>
                <badge v-if="row.item.event_type==='Delivery' || row.item.event_type==='Send'" type="default">{{row.item.event_type}}</badge>
              </template>
              <template v-slot:cell(time_of_event)="row">
                {{getLocalDate(row.item.timestamp)}}, {{ getLocalTime(row.item.timestamp)}}
              </template>
              <template v-slot:cell(recipient)="row">
                <div>{{row.item.customer_first_name}} {{row.item.customer_last_name}}</div>
                <small> <i class="tim-icons icon-email-85 ml-1"></i> {{row.item.customer_email}}</small>
              </template>
              <template v-slot:cell(sender)="row">
                <div v-if="row.item.sender_email">
                  <div>{{row.item.sender_first_name}} {{row.item.sender_last_name}}</div>
                  <small><i class="tim-icons icon-email-85 ml-1"></i>  {{row.item.sender_email}}</small>
                </div>
                <div v-else>System</div>
              </template>
            </b-table>
          </div>

        </div>
      </card>
    </div>
  </div>
  <div class="d-sm-block d-none">
    <div class="row">
      <div class="col-lg-5">
        <!--      <card>-->
        <div v-if="activity.length > 0">
          <h4 slot="header" class="card-title">Activity</h4>
          <div style="overflow-y: scroll; overflow-x: hidden; height: 650px; max-height: 650px;">
            <div class="row">
              <div class="col ml-2 mr-2">
                <time-line type="simple">
                  <time-line-item v-for="(a, i) in activity" :key="'A' + i"
                                  inverted
                                  badge-type="success"
                                  :badge-text="getInitials(a)"

                  >
                    <p slot="content">

                    <span v-if="a.company_user_id">
                      {{a.company_user_first_name}} {{a.company_user_last_name}}
                    </span>
                    <span v-if="a.user_id">
                      {{a.first_name}} {{a.last_name}}
                    </span>
                      <span v-if="!a.user_id && !a.company_user_id">
                      System User
                    </span>
                      {{a.description}}.
                    </p>

                    <h6 slot="footer">
                      <i class="ti-time"></i> {{ getLocalDate(a.timestamp)}}, {{ getLocalTime(a.timestamp)}}
                    </h6>
                  </time-line-item>
                </time-line>
              </div>
            </div>
          </div>
        </div>

        <div v-if="activity.length > 0" class="mt-5 mb-5">

        </div>
      </div>
      <div class="col-lg-7">
        <card>
          <h4 slot="header" class="card-title">Notes</h4>
          <div class="row mb-3">
            <div class="col">
              <div
                class="btn-group btn-group-toggle"
                :class="'float-left'"
                data-toggle="buttons"
              >
                <label
                  v-for="(option, index) in noteCategories"
                  :key="index + b"
                  class="btn btn-sm btn-primary btn-simple"
                  :class="{ active: option.active }"
                  :id="index"
                >
                  <input
                    type="radio"
                    @click="switchTabNotes(index)"
                    name="options"
                    autocomplete="off"
                    :checked="option.active"
                  />
                  <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                  <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div style="overflow-y: scroll; overflow-x: hidden; max-height: 250px;">
            <div v-if="activeTabNotesIndex === 0 || activeTabNotesIndex === 1" class="row" v-for="(n, i) in notes" :key="'N' + i">
              <div class="col mt-2">
                <base-alert type="white" style="margin-bottom: -5px;">
                  <div class="row" >
                    <div class="col text-black-50">
                      <base-button
                        type="danger"
                        class="closed btn btn-link"
                        aria-label="Close"
                        icon
                        @click="deleteNote(n.id)"
                      >
                        <i class="tim-icons icon-trash-simple"></i>
                      </base-button>
                      <badge type="success">Transaction #{{ transaction.order_number }}</badge>
                    </div>
                  </div>
                  <div class="row" >
                    <div class="col text-black-50">
                      {{n.note}}
                    </div>
                  </div>
                </base-alert>
                <div class="mt-1">
                  <small class="font-italic">{{getLocalDate(n.timestamp)}}, {{getLocalTime(n.timestamp)}}, {{n.first_name}} {{n.last_name}}
                  </small>
                </div>
              </div>
            </div>

            <div v-if="activeTabNotesIndex === 0 || activeTabNotesIndex === 2" class="row" v-for="(bn, i) in booking_notes" :key="'BN' + i">
              <div class="col mt-2">
                <base-alert type="white" style="margin-bottom: -5px;">
                  <div class="row" >
                    <div class="col text-black-50">
                      <base-button
                        type="danger"
                        class="closed btn btn-link"
                        aria-label="Close"
                        icon
                        @click="deleteBookingNote(bn.booking_slot_id, bn.id)"
                      >
                        <i class="tim-icons icon-trash-simple"></i>
                      </base-button>
                      <badge type="warning">Booking #{{ bn.booking_slot_id }}</badge>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col text-black-50">
                      {{bn.note}}
                    </div>
                  </div>
                </base-alert>
                <div class="mt-1">
                  <small class="font-italic">{{getLocalDate(bn.timestamp)}}, {{getLocalTime(bn.timestamp)}}, {{bn.first_name}} {{bn.last_name}}
                  </small>
                </div>
              </div>
            </div>

          </div>

          <div class="row justify-content-center mt-4">
            <div class="col">
              <base-input label="Add a new note">
                <el-input
                  v-model="note"
                  :rows="5"
                  type="textarea"
                  placeholder="Notes..."
                />
              </base-input>
            </div>
          </div>

          <label>Note for:</label>
          <div class="row">
            <div class="col">
              <base-radio v-model="note_type" name="transaction">Transaction #{{transaction.order_number}}</base-radio>
            </div>
          </div>
          <div class="row" v-for="(b, i) in bookings" :key="i+'NB'">
            <div class="col">
              <base-radio v-model="note_type" :name="b.id">Booking #{{b.id}}</base-radio>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col">
              <base-button type="primary" @click="addNewComment()">Create note</base-button>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>

  <div class="d-sm-none d-block">
    <div class="row">
      <div class="col-12">
        <card>
          <h4 slot="header" class="card-title">Notes</h4>
          <div class="row mb-3">
            <div class="col">
              <div
                class="btn-group btn-group-toggle"
                :class="'float-left'"
                data-toggle="buttons"
              >
                <label
                  v-for="(option, index) in noteCategories"
                  :key="index + b"
                  class="btn btn-sm btn-primary btn-simple"
                  :class="{ active: option.active }"
                  :id="index"
                >
                  <input
                    type="radio"
                    @click="switchTabNotes(index)"
                    name="options"
                    autocomplete="off"
                    :checked="option.active"
                  />
                  <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                  <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div style="overflow-y: scroll; overflow-x: hidden; max-height: 250px;">
            <div v-if="activeTabNotesIndex === 0 || activeTabNotesIndex === 1" class="row" v-for="(n, i) in notes" :key="'N' + i">
              <div class="col mt-2">
                <base-alert type="white" style="margin-bottom: -5px;">
                  <div class="row" >
                    <div class="col text-black-50">
                      <base-button
                        type="danger"
                        class="closed btn btn-link"
                        aria-label="Close"
                        icon
                        @click="deleteNote(n.id)"
                      >
                        <i class="tim-icons icon-trash-simple"></i>
                      </base-button>
                      <badge type="success">Transaction #{{ transaction.order_number }}</badge>
                    </div>
                  </div>
                  <div class="row" >
                    <div class="col text-black-50">
                      {{n.note}}
                    </div>
                  </div>
                </base-alert>
                <div class="mt-1">
                  <small class="font-italic">{{getLocalDate(n.timestamp)}}, {{getLocalTime(n.timestamp)}}, {{n.first_name}} {{n.last_name}}
                  </small>
                </div>
              </div>
            </div>

            <div v-if="activeTabNotesIndex === 0 || activeTabNotesIndex === 2" class="row" v-for="(bn, i) in booking_notes" :key="'BN' + i">
              <div class="col mt-2">
                <base-alert type="white" style="margin-bottom: -5px;">
                  <div class="row" >
                    <div class="col text-black-50">
                      <base-button
                        type="danger"
                        class="closed btn btn-link"
                        aria-label="Close"
                        icon
                        @click="deleteBookingNote(bn.booking_slot_id, bn.id)"
                      >
                        <i class="tim-icons icon-trash-simple"></i>
                      </base-button>
                      <badge type="warning">Booking #{{ bn.booking_slot_id }}</badge>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col text-black-50">
                      {{bn.note}}
                    </div>
                  </div>
                </base-alert>
                <div class="mt-1">
                  <small class="font-italic">{{getLocalDate(bn.timestamp)}}, {{getLocalTime(bn.timestamp)}}, {{bn.first_name}} {{bn.last_name}}
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center mt-4">
            <div class="col">
              <base-input label="Add a new note">
                <el-input
                  v-model="note"
                  :rows="5"
                  type="textarea"
                  placeholder="Notes..."
                />
              </base-input>
            </div>
          </div>

          <label>Note for:</label>
          <div class="row">
            <div class="col">
              <base-radio v-model="note_type" name="transaction">Transaction #{{transaction.order_number}}</base-radio>
            </div>
          </div>
          <div class="row" v-for="(b, i) in bookings" :key="i+'NB'">
            <div class="col">
              <base-radio v-model="note_type" :name="b.id">Booking #{{b.id}}</base-radio>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col">
              <base-button type="primary" @click="addNewComment()">Create note</base-button>
            </div>
          </div>
        </card>
      </div>
      <div class="col-12">
        <!--      <card>-->
        <div v-if="activity.length > 0">
          <h4 slot="header" class="card-title">Activity</h4>
          <div>
            <div class="row">
              <div class="col ml-2 mr-2">

                <time-line type="simple">
                  <time-line-item v-for="(a, i) in activity" :key="'A' + i"
                                  inverted
                                  badge-type="success"
                                  :badge-text="getInitials(a)"

                  >
                    <p slot="content">
                  <span v-if="a.company_user_first_name || a.company_user_last_name">
                    {{a.company_user_first_name}} {{a.company_user_last_name}}
                  </span>
                      <span v-if="a.first_name || a.last_name">
                    {{a.first_name}} {{a.last_name}}
                  </span>
                      {{a.description}}.
                    </p>

                    <h6 slot="footer">
                      <i class="ti-time"></i> {{ getLocalDate(a.timestamp)}}, {{ getLocalTime(a.timestamp)}}
                    </h6>
                  </time-line-item>
                </time-line>
              </div>
            </div>
          </div>
        </div>

        <div v-if="activity.length > 0" class="mt-5 mb-5">

        </div>

      </div>
    </div>
  </div>

  <!--    Cancel booking Modal-->
  <modal :show.sync="modals.cancel_booking"
         body-classes="p-0"
         modal-classes="modal-dialog-centered">
    <card type="secondary"
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0">

      <h4 class="card-title">Cancel booking #{{selectedBookingSlot.id}}</h4>
      <base-alert type="danger">
        <i class="tim-icons icon-alert-circle-exc"></i>
        Cancelling this booking will result in the time slot becoming available to book. Any amount paid will need to be manually refunded to the customer.
      </base-alert>

      <div class="row">
        <div class="col">
          <base-input label="Select status for the freed up time slot">
            <el-select
              label="Select status"
              v-model="selectedCancellationStatus"
              class="select-primary"
              value-key="id"
              placeholder="Status">
              <el-option
                v-for="(status, index) in slot_statuses"
                class="select-primary"
                :label="status.label"
                :value="{id: status.id, label: status.label, status: status.status}"
                :key="'CSTATUS' + index"
              ></el-option>
            </el-select>
          </base-input>
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <base-button type="secondary" @click="modals.cancel_booking = false">Cancel</base-button>
        <base-button class="ml-3" type="primary" @click="cancelBooking(selectedBookingSlot, selectedCancellationStatus.status)">Cancel booking</base-button>
      </div>
    </card>
  </modal>

  <!--    Cancel booking Modal-->
  <modal :show.sync="modals.cancel_transaction"
         body-classes="p-0"
         modal-classes="modal-dialog-centered">
    <card type="secondary"
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0">

      <h4 class="card-title">Cancel transaction</h4>
      <base-alert type="danger">
        <i class="tim-icons icon-alert-circle-exc"></i>
        Cancelling this transaction will open up availability for all bookings made in this transaction. Any amount paid will need to be manually refunded to the customer.
      </base-alert>

      <div class="row">
        <div class="col">
          <base-input label="Select status for the freed up time slots">
            <el-select
              label="Select status"
              v-model="selectedCancellationStatus"
              class="select-primary"
              value-key="id"
              placeholder="Status">
              <el-option
                v-for="(status, index) in slot_statuses"
                class="select-primary"
                :label="status.label"
                :value="{id: status.id, label: status.label, status: status.status}"
                :key="'CTSTATUS' + index"
              ></el-option>
            </el-select>
          </base-input>
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <base-button type="secondary" @click="modals.cancel_transaction = false">Cancel</base-button>
        <base-button class="ml-3" type="primary" @click="cancelTransaction(selectedCancellationStatus.status)">Cancel transaction</base-button>
      </div>
    </card>
  </modal>

<!--  Add gift card-->
  <modal :show.sync="modals.add_gift_card"
         body-classes="p-0"
         modal-classes="modal-dialog-centered modal-lg">
    <card type="secondary"
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0">

      <h4>Add gift card</h4>

      <div class="row justify-content-center">
        <div class="col-lg-8 text-center">
          <el-carousel v-on:change="setGiftCardDesign" :autoplay="false" indicator-position="outside">
            <el-carousel-item v-for="(item, index) in gift_card_designs" :key="'GCDESIGNS' + index">
              <img style="border: 3px solid white; object-fit: contain; width: 600px; height: 300px" :src="item.url">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>

      <div class="row mb-2" >
        <div class="col-lg-5">
          <base-radio v-model="gift_card.custom_code" name="0">Automatically generate gift card code</base-radio>
        </div>
        <div class="col-lg-5">
          <base-radio v-model="gift_card.custom_code" name="1">Create a custom gift card code</base-radio>
        </div>
      </div>
      <div class="row" v-if="gift_card.custom_code == 1">
        <div class="col">
          <base-input :error="errs.custom_code" label="Custom code" placeholder="Custom code" v-model="gift_card.code"></base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <!--          <base-input label="Gift card value" v-on:change="calculateTotals()" placeholder="0.00" v-model="gift_card.total"></base-input>-->
          <base-input :label="'Gift card type'">
            <el-select
              label="Select type"
              v-model="selectedGiftCardType"
              class="select-primary"
              value-key="id"
              v-on:change="selectGiftCardType()"
            >
              <el-option
                v-for="(gct, index) in gift_card_types"
                class="select-primary"
                :label="gct.name"
                :value="{id: gct.id, name: gct.name,
                    value_type: gct.value_type, designs: gct.designs, price: gct.price,
                     redemption_amount: gct.redemption_amount}"
                :key="'AMOUNT' + index"
              >
                <span>{{gct.name}}</span>
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-lg-4" v-if="selectedGiftCardType.value_type === 0">
          <base-input :label="'Custom value  (' + currency_symbol + ')'"
                      v-on:change="calculateTotals()" placeholder="0.00"
                      v-model.number="gift_card.custom_amount"></base-input>
        </div>
        <div class="col-lg-4" v-if="selectedGiftCardType.value_type === 2">
          <base-input label="Games">
            <el-select
              label="Select game"
              v-model="selectedGame"
              class="select-primary"
              value-key="id"
              v-on:change="calculateGameValue()"
            >
              <el-option
                v-for="(game, index) in gift_card_games"
                class="select-primary"
                :label="game.name"
                :value="{id: game.id,
                name: game.name,
                cumulative_category_pricing: game.cumulative_category_pricing,
                game_pricing: game.game_pricing,
                pricing_type_id: game.pricing_type_id,
                pricing_categories: game.pricing_categories}"
                :key="'GAME' + index"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-lg-4" v-if="selectedGiftCardType.value_type === 2" :key="gc_key">
          <div v-for="(c, i) in selectedGame.pricing_categories" :key="'C' + i">
            <label class="mt-2 text-uppercase">{{c.category}}</label>
            <base-input>
              <el-input-number @change="calculateGameValue()" v-model="c.number_of_players" :min="0" />
            </base-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <p class="text-white text-uppercase">value ({{currency_symbol}})</p>
          <h2 style="margin-bottom: 0px" class="warning">{{ getAmount(gift_card.unit_value) }}</h2>
        </div>
        <div class="col-lg-4" v-if="selectedGiftCardType.value_type === 1">
          <p class="text-white text-uppercase">price ({{currency_symbol}})</p>
          <h2 style="margin-bottom: 0px" class="warning">{{ getAmount(gift_card.unit_price) }}</h2>
        </div>
        <div class="col-lg-4">
          <p class="text-white text-uppercase">total cost ({{currency_symbol}})</p>
          <h2 style="margin-bottom: 0px" class="warning">{{ getAmount(gift_card.total) }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 ">
          <base-input label="Quantity">
            <el-input-number v-on:change="calculateTotals()" v-model="gift_card.quantity" placeholder="1" :min="1"></el-input-number>
          </base-input>
        </div>
      </div>

      <div class="row mb-3" >
        <div class="col-lg-3">
          <base-radio v-on:input="setDeliveryDate()" v-model="gift_card.send_instantly" name="1">Send instantly</base-radio>
        </div>
        <div class="col-lg-4">
          <base-radio v-model="gift_card.send_instantly" name="0">Send on a future date</base-radio>
        </div>
      </div>

      <div class="row mt-2 justify-content-between" v-if="gift_card.send_instantly == 0">
        <div class="col-lg-6">
          <base-input label="Delivery date">
            <el-date-picker
              class="custom-cursor"
              type="date"
              placeholder="Select date"
              value-format="yyyy-MM-dd"
              :format="day_of_week + '' + date_format_cal"
              v-model="gift_card.delivery_date"
              :picker-options="{ disabledDate: (time) => disabledDates(time, gift_card.delivery_date) }"
            >
            </el-date-picker>
          </base-input>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col">
          <base-checkbox type="checkbox"
                         v-model="send_gift_card_to_self"> Purchasing customer is the recipient
          </base-checkbox>
        </div>
      </div>
      <div class="row justify-content-center" v-if="!send_gift_card_to_self">
        <div class="col-12 col-lg-6">
          <base-input :error="errs.recipient_first_name" v-model="gift_card.recipient_first_name" placeholder="Recipient's first name"></base-input>
        </div>
        <div class="col-12 col-lg-6" >
          <base-input :error="errs.recipient_last_name" v-model="gift_card.recipient_last_name" placeholder="Recipient's last name"></base-input>
        </div>
      </div>
      <div class="row justify-content-center" v-if="!send_gift_card_to_self">
        <div class="col">
          <base-input :error="errs.recipient_email" v-model="gift_card.recipient_email" placeholder="Recipient's email"></base-input>
        </div>
      </div>

      <div class="row  justify-content-center">
        <div class="col">
          <base-input label="Personal message">
            <textarea class="form-control" placeholder="Add your personal message..." v-model="gift_card.personal_message" rows="2"></textarea>
          </base-input>
        </div>
      </div>

      <div class="row d-none d-sm-block text-center">
        <base-button type="secondary" @click="modals.add_gift_card = false">Cancel</base-button>
        <base-button class="ml-3" type="primary" :disabled="loading" @click="createGiftCard()">Save</base-button>
      </div>
    </card>
  </modal>


  <!--    Add Bookings Modal-->
  <modal :show.sync="modals.add_booking"
         body-classes="p-0"
         modal-classes="modal-dialog-centered modal-lg">
    <card type="secondary"
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0">

      <h4>Add booking</h4>
      <div>
        <label>Game</label>
        <base-input :error="errs.game">
          <el-select
            label="Select game"
            v-model="selectedGame"
            class="select-primary"
            v-on:change="getInitialSubtotal(selectedGame, 'new')"
            value-key="id"
            placeholder="Game">
            <el-option
              v-for="(game, index) in games"
              class="select-primary"
              :label="game.name"
              :value="{name: game.name, id: game.id}"
              :key="index + 'GAMES'"
            ></el-option>
          </el-select>
        </base-input>
        <div v-if="selectedGame != ''" class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <label >Booking date</label>
            <base-input :error="errs.booking_date">
              <el-date-picker
                type="date"
                placeholder="Select date"
                :format="day_of_week + date_format_cal"
                name="date"
                v-model="booking_date"
                v-on:change="getAvailableBookings(booking_date)"
              >
              </el-date-picker>
            </base-input>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <label>Time slot</label>
            <base-input :error="errs.time_slot">
              <el-select v-on:change="selectTimeSlot(selectedTimeSlot)"
                         label="Select time"
                         v-model="selectedTimeSlot"
                         class="select-primary"
                         value-key="id"
                         placeholder="Time">
                <el-option
                  v-for="(booking, index) in available_bookings"
                  class="select-primary"
                  :label="getTime(booking.start_time)  + '-' + getTime(booking.end_time)"
                  :value="{id: booking.id,
                          booking_date: booking.booking_date,
                          end_date: booking.end_date,
                          pricing: booking.pricing,
                          custom_pricing: booking.custom_pricing,
                          game_id: booking.game_id,
                          start_time: booking.start_time,
                          is_custom_pricing_enabled: booking.is_custom_pricing_enabled,
                          slot_cumulative_category_pricing: booking.slot_cumulative_category_pricing,
                          slot_hide_pricing_rate: booking.slot_hide_pricing_rate,
                          slot_pricing_type_id: booking.slot_pricing_type_id,
                          pricing_type_id: booking.pricing_type_id,
                          cumulative_category_pricing: booking.cumulative_category_pricing,
                          hide_pricing_rate: booking.hide_pricing_rate,
                          end_time: booking.end_time}"
                  :key="'A' + index"
                ></el-option>
              </el-select>
            </base-input>
          </div>
        </div>
      </div>

      <div v-if="selectedGame != '' && selectedTimeSlot">
        <div class="row mt-1">
          <div class="col-lg-4 col-md-4 col-12" v-if="!selectedTimeSlot.price_override">
            <p class="text-white text-uppercase">Quantity</p>

            <div v-if="!selectedTimeSlot.is_custom_pricing_enabled" v-for="(c, i) in pricing_categories" :key="'CP1' + i">
              <label class="text-primary text-uppercase">{{c.category}}
              </label>
              <base-input>
                <el-input-number @change="getSubtotal(selectedTimeSlot, 'new')" v-model="c.number_of_players" :min="0" />
              </base-input>
            </div>

            <div v-if="selectedTimeSlot.is_custom_pricing_enabled" v-for="(c, i) in custom_pricing_categories" :key="'C1' + i">
              <label class="text-primary text-uppercase">{{c.category}}
              </label>
              <base-input>
                <el-input-number @change="getSubtotal(selectedTimeSlot, 'new')" v-model="c.number_of_players" :min="0" />
              </base-input>
            </div>
          </div>
          <div class="col-lg-4 col-md-3 col-6" v-if="!selectedTimeSlot.price_override">
            <p class="text-white text-uppercase">price ({{currency_symbol}})</p>
            <h2 style="margin-bottom: 0px" class="warning">{{ getAmount(selectedTimeSlot.subtotal) }}</h2>

            <small>
              <div v-if="!selectedTimeSlot.is_custom_pricing_enabled" v-for="(c, i) in pricing_categories" :key="'CC1' + i">
                <span class="text-primary">{{getRate(c, selectedTimeSlot, 'new')}}</span>
              </div>
              <div v-if="selectedTimeSlot.is_custom_pricing_enabled" v-for="(c, i) in custom_pricing_categories" :key="'CPC1' + i">
                <span class="text-primary">{{getRate(c, selectedTimeSlot, 'new')}}</span>
              </div>
            </small>
            <base-button v-if="custom_pricing_categories.length > 0 || pricing_categories.length > 0"
                         size="sm" type="primary" v-on:click="overridePrice(selectedTimeSlot, 'new')">Override price</base-button>
          </div>
          <div class="col-lg-6 col-md-6 col" v-show="selectedTimeSlot.price_override" :key="pr_key + 'NEW'">
            <p class="text-white text-uppercase">Price override</p>
            <div v-if="!selectedTimeSlot.is_custom_pricing_enabled" v-for="(c, index) in pricing_categories" :key="'CCOVERRIDE' + index">
              <label v-if="pricing_type_id === 1">Price per {{c.category}} ({{currency_symbol}})</label>
              <label v-if="pricing_type_id === 2">Price for {{c.category}} ({{currency_symbol}})</label>
              <div class="row">
                <div class="col">
                  <base-input>
                    <el-input-number placeholder="0.00" v-model="c.price" :controls="false" :precision="2" :step="0.1" />
                  </base-input>
                </div>
                <div class="col">
                  <small class="text-white mr-2">Original price: {{getAmount(c.original_price)}}</small>
                  <el-tooltip class="d-inline"
                              content="Set to original price"
                              effect="light"
                              :open-delay="300"
                              placement="right"
                  >
                    <i style="cursor: pointer" class="ml-1 tim-icons icon-refresh-02 text-white" v-on:click="setOriginalPrice(c)"></i>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <div v-if="selectedTimeSlot.is_custom_pricing_enabled" v-for="(c, index) in custom_pricing_categories"
                 :key="'CCPOVERRIDE' + index">
              <label v-if="pricing_type_id === 1">Price per {{c.category}} ({{currency_symbol}})</label>
              <label v-if="pricing_type_id === 2">Price for {{c.category}} ({{currency_symbol}})</label>
              <div class="row">
                <div class="col">
                  <base-input>
                    <el-input-number placeholder="0.00" v-model="c.price" :controls="false" :precision="2" :step="0.1" />
                  </base-input>
                </div>
                <div class="col">
                  <small class="text-white mr-2">Original price: {{getAmount(c.original_price)}}</small>
                  <el-tooltip class="d-inline"
                              content="Set to original price"
                              effect="light"
                              :open-delay="300"
                              placement="right"
                  >
                    <i style="cursor: pointer" class="ml-1 tim-icons icon-refresh-02 text-white" v-on:click="setOriginalPrice(c)"></i>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <base-button size="sm" type="primary" v-on:click="saveOverride(selectedTimeSlot, 'new')">Save</base-button>
          </div>
        </div>
      </div>

      <div class="row justify-content-center mt-3">
        <base-button type="secondary" @click="modals.add_booking = false">Cancel</base-button>
        <base-button class="ml-3" :disabled="loading"
                     type="primary" @click="createBooking()">Save</base-button>
      </div>
    </card>
  </modal>

  <!--    Add Item Modal-->
  <modal :show.sync="modals.add_item"
         body-classes="p-0"
         modal-classes="modal-dialog-centered modal-lg">
    <card type="secondary"
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0">

      <h4>Add item</h4>


      <base-input label="Search items">
        <el-autocomplete style="width: 100%"
                         v-model="selectedItem"
                         :fetch-suggestions="querySearchItem"
                         placeholder="Search"
                         :trigger-on-focus="false"
                         @select="handleSelectItem"
        >
          <template #default="{ item }">
            <p style="margin-bottom: -12px" class="text-primary">{{ item.name }} </p>
            <small class="font-italic">
              Price: {{getAmount(item.price) }}, Available: x{{item.available}}
            </small>
          </template>
        </el-autocomplete>
      </base-input>

      <hr v-if="purchase.name" style="border-top: 1px solid #f4f5f7; margin-top: 20px">
      <div class="row mt-3" v-if="purchase.name">
        <div class="col-lg-4">
          <h4 class="card-title">{{purchase.name}}</h4>
        </div>
      </div>

      <div class="row" v-if="purchase.name">
        <div class="col-lg-4">
          <base-input :error="errs.purchase.quantity" label="Quantity">
            <el-input-number v-model="purchase.quantity" :min="1" />
          </base-input>
        </div>
        <div class="col-lg-4">
          <label>Price per unit ({{currency_symbol}})</label>
          <h4>{{getAmount(purchase.price)}}</h4>
        </div>

        <div class="col-lg-4">
          <label>Subtotal ({{currency_symbol}})</label>
          <h4>{{getAmount(getPurchaseAmount(purchase.price, purchase.quantity))}}</h4>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col text-center">
          <base-button type="secondary" @click="modals.add_item = false">Cancel</base-button>
          <base-button class="ml-3" type="primary" :disabled="loading" @click="createPurchase()">Save</base-button>
        </div>
      </div>
    </card>
  </modal>

  <modal :show.sync="modals.custom_purchase"
         body-classes="p-0"
         modal-classes="modal-dialog-centered modal-lg">
    <card type="secondary"
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0">

      <h4>Add custom purchase</h4>

      <div class="row">
        <div class="col">
          <base-input :error="errs.purchase.name" label="Purchase name" placeholder="Purchase name" v-model="purchase.name">
          </base-input>
        </div>
      </div>

      <div class="row" >
        <div class="col">
          <base-input label="Description">
            <el-input
              v-model="purchase.description"
              :rows="5"
              type="textarea"
              placeholder="Description"
            />
          </base-input>
        </div>
      </div>


      <div class="row">
        <div class="col-lg-4">
          <base-input :error="errs.purchase.quantity" label="Quantity">
            <el-input-number v-model="purchase.quantity" :min="1" />
          </base-input>
        </div>
        <div class="col-lg-4">
          <label>Price per unit ({{currency_symbol}})</label>
          <base-input :error="errs.purchase.price" placeholder="0" v-model.number="purchase.price"></base-input>
        </div>

        <div class="col-lg-4">
          <label>Subtotal ({{currency_symbol}})</label>
          <h4>{{getAmount(getPurchaseAmount(purchase.price, purchase.quantity))}}</h4>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col text-center">
          <base-button type="secondary" @click="modals.custom_purchase = false">Cancel</base-button>
          <base-button class="ml-3" type="primary" :disabled="loading" @click="createCustomPurchase()">Save</base-button>
        </div>
      </div>
    </card>
  </modal>

  <!--    Add Payment Modal-->
  <modal :show.sync="modals.add_payment"
         body-classes="p-0"
         modal-classes="modal-dialog-centered modal-lg">
    <card type="secondary"
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0">

      <h4>Add payment to transaction #{{transaction.order_number}}</h4>
      <div class="row mt-1">
        <div class="col-6">
          <label>Amount ({{currency_symbol}})</label>
          <base-input :error="errs.payment_amount" v-model="payment_amount"></base-input>
        </div>
        <div class="col-6" v-if="selectedPaymentOption.name === 'Customer credit'">
          <label>Available credit ({{currency_symbol}})</label>
          <base-input v-model="available_credit" disabled></base-input>
        </div>
        <div class="col-6" v-if="selectedPaymentOption.name === 'Gift card' && selectedGiftCardCode">
          <label>Available gift card amount ({{currency_symbol}})</label>
          <base-input v-model="selectedGiftCardCode.remaining" disabled></base-input>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <base-input label="Payment options">
            <el-select
              class="select-primary mb-3"
              v-model="selectedPaymentOption"
              placeholder="Option"
              value-key="id"
            >
              <el-option
                class="select-primary"
                v-for="(item, index) in paymentOptions"
                :key="index + 'P'"
                :label="item.name"
                :value="{id: item.id, name: item.name, content: item.content, is_default: item.is_default}"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
      </div>

      <div class="row" v-if="!selectedPaymentOption.is_default">
        <div class="col">
          <div v-html="selectedPaymentOption.content"></div>
        </div>
      </div>

      <div class="row mb-3" v-if="selectedPaymentOption.name === 'Gift card'">
        <div class="col">
          <base-input label="Gift card code" :error="errs.gift_card_code">
            <el-autocomplete style="width: 100%"
                             v-model="gift_card_code"
                             :fetch-suggestions="giftCardSearch"
                             placeholder="Search by code..."
                             :trigger-on-focus="false"
                             @select="handleGiftCardSelect"
            >
              <template #default="{ item }">
                <div class="row">
                  <div class="col">
                    <p style="margin-bottom: -12px" class="text-primary">{{ getGiftCardCode(item.code) }}</p>
                    <small class="font-italic">
                      <i class="tim-icons icon-money-coins"></i> Total {{getAmount(item.total)}} Remaining {{getAmount(item.remaining)}}
                    </small>
                    <br/>
                  </div>
                </div>
              </template>
            </el-autocomplete>
          </base-input>
        </div>
      </div>

      <div class="row" v-if="selectedPaymentOption.name === 'Card'">
        <div class="col-6">
          <base-input :error="errs.first_name" label="Cardholder first name" v-model="user.first_name" placeholder="First name"></base-input>
        </div>
        <div class="col-6">
          <base-input :error="errs.last_name" label="Cardholder last name" v-model="user.last_name" placeholder="Last name"></base-input>
        </div>
      </div>

      <!--                  Square-->
      <div class="row mt-2" v-show="selectedPaymentOption.name === 'Card' && payment_settings.payment_gateway_id === 2">
        <div class="col">
          <form id="payment-form-square">
            <div id="card-container"></div>
          </form>
          <div id="payment-status-container"></div>
        </div>
      </div>

      <!--        Stripe-->
      <div class="row mb-5" v-show="selectedPaymentOption.name === 'Card' && payment_settings.payment_gateway_id === 3">
        <div class="col">
          <form id="payment-form">
            <label for="card-element">
              Credit or debit card
            </label>
            <div id="card-element">
              <!-- A Stripe Element will be inserted here. -->
            </div>
            <div id="card-errors" role="alert"></div>
          </form>
        </div>
      </div>

      <!--        Authorize.net-->
      <div class="row" v-if="selectedPaymentOption.name === 'Card' && payment_settings.payment_gateway_id === 1">
        <div class="col-12">
          <base-input :error="errs.ccn" v-mask="'#### #### #### ####'" placeholder="4444 4444 4444 4444" v-model="payment.cardNumber" label="Credit card number"></base-input>
        </div>

      </div>
      <div class="row" v-if="selectedPaymentOption.name === 'Card' && payment_settings.payment_gateway_id === 1">
        <div class="col-4">
          <base-input :error="errs.zip" :placeholder="country.zip_placeholder" v-model="payment.zip" type="text"
                      :label="country.zip_label"></base-input>
        </div>
        <div class="col-4">
          <base-input :error="errs.expiration" v-mask="'##/##'" placeholder="11/27" v-model="payment.expirationDate" label="Expiration"></base-input>
        </div>
        <div class="col-4">
          <base-input :error="errs.cvv" v-mask="'####'" placeholder="111" v-model="payment.cardCode" type="tel" label="CVV"></base-input>
        </div>
      </div>

      <div class="row d-none d-sm-block text-center">
        <base-button type="secondary" @click="modals.add_payment = false">Cancel</base-button>
        <base-button class="ml-3" type="primary" :disabled="loading" @click="addPayment()">Add payment</base-button>
      </div>
      <div class="row d-block d-sm-none text-center">
        <base-button simple size="sm" type="secondary" @click="modals.add_payment = false">Cancel</base-button>
        <base-button simple size="sm" class="ml-3" :disabled="loading" type="primary" @click="addPayment()">Add payment</base-button>
      </div>
    </card>
  </modal>

<!--  Waiver reassign bookings-->
  <modal :show.sync="modals.bookings"
         body-classes="p-0"
         modal-classes="modal-dialog-centered">
    <card type="secondary"
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0">

      <h4>Booking slots</h4>

      <div class="row">
        <div class="col-8">
          <base-input style="border: 0; border-width:0px;">
            <el-date-picker
              style="border: 0; border-width:0px;"
              type="date"
              placeholder="Select date"
              value-format="yyyy-MM-dd"
              :format="day_of_week + '' + date_format_cal"
              v-model="current"
              @change="getBookings()"
            >
            </el-date-picker>
          </base-input>
        </div>
      </div>
      <!--   No data panel -->
      <div v-if="assignable_bookings.length <= 0" class="mt-3" >
        <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
          <h4 class="mt-3">There are no bookings on this day.</h4>
        </div>
      </div>


      <div v-if="assignable_bookings.length > 0" class="row" v-for="(b, i) in assignable_bookings" :key="'A' + i">
        <div class="col">
          <div class="row">
            <div
              class="form-check form-check-radio form-check-in-table"
            >
              <label class="form-check-label">
                <input
                  v-model="selectedBooking"
                  class="form-check-input"  :name="b.id" :value="b"
                  type="radio"
                />
                <slot></slot> <span class="form-check-sign"></span>
                <span class="text-success ml-1" style="font-size: medium">
                      {{b.game_name}}</span>
              </label>

              <span class="ml-2 text-white"><i class="text-success tim-icons icon-calendar-60"></i>
               {{getDate(b.booking_date)}}</span>
              <span class="ml-2 text-white"><i class="text-success tim-icons icon-time-alarm"></i>
               {{getTime(b.start_time)}}</span>&nbsp;
              <badge v-if="b.status == 'available'" type="info">{{b.status}}</badge>
              <badge v-if="b.status == 'booked'" type="success">{{b.status}}</badge>
              <badge v-if="b.status == 'checked'" type="info">available</badge>
              <badge v-if="b.status == 'completed'" type="warning">completed</badge>
            </div>
          </div>
        </div>
      </div>

      <div class="row text-center mt-3">
        <div class="col">
          <base-button type="secondary" @click="modals.bookings = false">Cancel</base-button>
          <base-button class="ml-3" type="primary" @click="updateWaiver()">Save</base-button>
        </div>
      </div>
    </card>
  </modal>

<!--  Custom deposit-->
  <modal :show.sync="modals.custom_deposit"
         body-classes="p-0"
         modal-classes="modal-dialog-centered">
    <card type="secondary"
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0">

      <h4>Custom deposit</h4>

      <base-alert type="info">
        <i class="tim-icons el-icon-info mr-2"></i>
        This option enables you to specify a custom deposit amount that will be shown on payment requests, overriding other deposit settings.
      </base-alert>
      <div class="row">
        <div class="col">
          <base-checkbox v-model="deposit.custom_deposit_enabled">Enable custom deposit</base-checkbox>
        </div>
      </div>
      <div class="row mt-2" v-if="deposit.custom_deposit_enabled">
        <div class="col">
          <base-input v-model.number="deposit.custom_deposit_amount" placeholder="0"
                      :label="'Custom deposit amount (' + currency_symbol + ')'"></base-input>
        </div>
      </div>


      <div class="row text-center mt-3">
        <div class="col">
          <base-button type="secondary" @click="modals.custom_deposit = false">Cancel</base-button>
          <base-button class="ml-3" type="primary" @click="saveCustomDeposit()">Save</base-button>
        </div>
      </div>
    </card>
  </modal>

</div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import swal from "sweetalert2";
import { Badge, BaseAlert, TimeLine, TimeLineItem } from '@/components';
import {BTable, BRow, BCol} from "bootstrap-vue";
import VueHtml2pdf from "vue-html2pdf";
import {dayOfWeek, formattedDate, localTime, time} from "@/plugins/dateFormatter";
import _ from "lodash";
import currencies from "@/assets/currencies.json";
import {Autocomplete, Dropdown, DropdownMenu, DropdownItem, Button, CarouselItem, Carousel} from "element-ui";
import axios from "axios";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import countries from "@/assets/countries.json";

export default {
  name: "TransactionDetails",
  components: {
    Breadcrumb,
    BreadcrumbItem,
    BTable,
    BRow,
    BCol,
    [Autocomplete.name]: Autocomplete,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [CarouselItem.name]: CarouselItem,
    [Carousel.name]: Carousel,
    [Button.name]: Button,
    Badge,
    VueHtml2pdf,
    BaseAlert,
    TimeLine,
    TimeLineItem
  },
  data() {
    return {
      company: JSON.parse(localStorage.getItem('company')),
      group: JSON.parse(localStorage.getItem('group')),
      gc_key: 0,
      gift_card_designs: [],
      gift_card_settings: '',
      gift_card_types: [],
      gift_card_games: [],
      gift_card: {
        custom_code: 0,
        code: '',
        personal_message: '',
        design_url: '',
        custom_amount: '',
        recipient_first_name: '',
        recipient_last_name: '',
        recipient_email: '',
        total: {amount: 0},
        quantity: 1,
        send_instantly: 1,
        delivery_date: new Date()
      },
      booking_date: '',
      selectedTimeSlot: '',
      day_of_week: '',
      current: new Date(),
      custom_fields: [],
      country: '',
      countries: countries.countries,
      currencies: currencies.currencies,
      number_format: JSON.parse(localStorage.getItem('group')).number_format,
      currency_symbol: '$',
      currency: JSON.parse(localStorage.getItem('group')).currency,
      currency_format: JSON.parse(localStorage.getItem('group')).currency_format,
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      date_format_cal: '',
      email_settings: '',
      refundAmount: 0,
      lookup_value: '',
      company_user_value: '',
      booking_amount_label: 'Price per person',
      showRefund: [],
      selectedGame: '',
      selectedRefundOption: '',
      isAmountFieldDisabled: true,
      refundOptions: [
        {
          id: 0,
          name: 'Refund card payment with Authorize.Net'
        },
        {
          id: 1,
          name: 'Mark payment as refunded only'
        }
      ],
      selectedPaymentAmount: '',
      paymentAmountOptions: [
        {
          id: 0,
          name: 'Refund full amount'
        },
        {
          id: 1,
          name: 'Refund partial amount'
        },
        {
          id: 2,
          name: 'Refund as customer credit'
        }
      ],
      note: '',
      empty: false,
      loading: false,
      transaction: '',
      activity: [],
      payments: [],
      waivers: [],
      bookings: [],
      is_price_per_person: false,
      available_bookings: [],
      editing_bookings: [],
      games: [],
      notes: [],
      booking_notes: [],
      gift_cards: [],
      note_type: 'transaction',
      paymentFields: ['payment_details', 'amount', 'actions'],
      waiverFields: ['customer_details', 'date_signed', 'signature', 'actions'],
      bookingFields: ['purchase_details', 'quantity', 'price', 'status', 'actions'],
      selectedWaiver: '',
      modals: {
        add_payment: false,
        policy: false,
        bookings: false,
        add_item: false,
        add_booking: false,
        add_gift_card: false,
        custom_deposit: false,
        custom_purchase: false,
        cancel_booking: false,
        cancel_transaction: false
      },
      selectedPolicy: '',
      user: {
        first_name: '',
        last_name: '',
        email: '',
        phone: ''
      },
      company_user: {
        first_name: '',
        last_name: '',
        email: '',
      },
      customer: {
        first_name: '',
        last_name: '',
        email: '',
        phone: ''
      },
      errs: {
        custom_deposit: '',
        recipient_first_name: '',
        recipient_last_name: '',
        recipient_email: '',
        custom_code: '',
        number_of_players: '',
        promo_code: '',
        gift_card_code: '',
        ccn: '',
        expiration: '',
        cvv: '',
        zip: '',
        first_name: '',
        last_name: '',
        payment_amount: '',
        email: '',
        booking_date: '',
        time_slot: '',
        start_time: '',
        group_size: '',
        price: '',
        tax: '',
        fee: '',
        tip_amount: '',
        purchase: {
          name: '',
          category: '',
          available: '',
          amount: '',
          quantity: '',
          price: ''
        }
      },
      payment: {
        cardNumber: '',
        expirationDate: '',
        cardCode: '',
        zip: ''
      },
      j: 0,
      b: 0,
      activeTabIndex: 0,
      activeTabNotesIndex: 0,
      selectedPaymentOption: {name: '', content: '', id: '', is_default: ''},
      paymentOptions: [],
      customer_credits: 0,
      available_credit: 0,
      applied_credit: 0,
      card: '',
      square: '',
      squareAppId: '',
      squareLocationId: '',
      elements: '',
      stripe: '',
      payment_settings: [],
      payment_amount: '',
      cursor_type: {
        resend_confirmation_email: 'pointer',
        payment_request: 'pointer',
        gift_card_confirmation_email: 'pointer',
        no_amount_due: 'pointer'
      },
      promos: [],
      add_promo: false,
      promo_code: '',
      gift_card_code: '',
      selectedPromoCode: '',
      selectedGiftCardCode: '',
      add_fee: false,
      taxes_fees: [],
      tax_fee_types: [
        {id: 0, name: 'Tax'}, {id: 1, name: 'Fee'}, {id: 2, name: 'Tip'}
      ],
      tax_fee_type: {id: 0},
      taxes: [],
      fees: [],
      selectedTax: '',
      selectedFee: '',
      deposit: {
        custom_deposit_amount: 0,
        custom_deposit_enabled: false,
      },
      purchase: {
        name: '',
        category: '',
        amount: '',
        quantity: '',
        price: 0,
        available: ''
      },
      selectedItem: '',
      item_categories: [],
      available_items: [],
      is_custom_purchase: false,
      custom_category: '',
      cat_key: 0,
      pricing_type_id: 1,
      pricing_categories: [],
      custom_pricing_categories: [],
      subtotal: 0,
      tickets: [],
      price_override: false,
      pr_key: 0,
      assignable_bookings: [],
      selectedBooking: '',
      email_events: [],
      tip_amount: 0,
      send_gift_card_to_self: false,
      selectedGiftCardType: '',
      selectedCancellationStatus: {id: 1},
      slot_statuses: [
        {id: 1, label: 'Available', status: 'available'},
        {id: 2, label: 'Blocked', status: 'blocked'},
        {id: 3, label: 'Call to book', status: 'call_to_book'},
      ],
      selectedBookingSlot: ''
    }
  },
  computed: {
    categories() {
      return [{ name: 'All Purchases', icon: 'tim-icons icon-cart', active: true },
        { name: 'Payments', icon: 'tim-icons icon-money-coins', active: false },
        { name: 'Promos', icon: 'tim-icons icon-gift-2', active: false },
        { name: 'Taxes/Fees/Tips', icon: 'tim-icons icon-coins', active: false },
        { name: 'Customer', icon: 'tim-icons  icon-single-02',active: false },
        { name: 'Waivers', icon: 'tim-icons  icon-notes',active: false },
        { name: 'Additional information', icon: 'tim-icons icon-alert-circle-exc',active: false },
        { name: 'Email events', icon: 'tim-icons icon-email-85',active: false },
      ];
    },
    noteCategories() {
      return [{ name: 'All notes', icon: 'tim-icons icon-notes', active: true },
        { name: 'Transaction notes', icon: 'tim-icons icon-money-coins', active: false },
        { name: 'Booking notes', icon: 'tim-icons icon-calendar-60',active: false }
      ];
    }
  },
  methods: {
    async sendToSquarePOS(event){
      event.preventDefault()
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/transactions/' + this.transaction.id + '/payments/square/terminal'

      let data = {}
      this.axios.post(url, data, config)
        .then(response => {
          console.log(response)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    calculateTotals(){
      if(this.selectedGiftCardType.value_type === 2){
        this.gift_card.total = this.gift_card.unit_value * this.gift_card.quantity
      }

      if(this.selectedGiftCardType.value_type === 0){
        if(isNaN(this.gift_card.custom_amount) || this.gift_card.custom_amount === ''){
          this.gift_card.custom_amount = 0
          this.gift_card.unit_price = 0
          this.gift_card.unit_value = 0
        }

        this.gift_card.unit_value = this.gift_card.custom_amount
        this.gift_card.total = this.gift_card.custom_amount * this.gift_card.quantity
      }
      if(this.selectedGiftCardType.value_type === 1){
        this.gift_card.unit_price = this.selectedGiftCardType.price
        this.gift_card.unit_value = this.selectedGiftCardType.redemption_amount
        this.gift_card.total = this.gift_card.unit_price * this.gift_card.quantity
      }
    },
    selectGiftCardType(){
      this.gift_card_designs = JSON.parse(this.selectedGiftCardType.designs)
      this.gift_card.design_url = this.gift_card_designs[0]

      if(this.selectedGiftCardType.value_type === 0 || this.selectedGiftCardType.value_type === 2){
        this.gift_card.custom_amount = ''
        this.gift_card.unit_value = 0
        this.gift_card.total = 0
        this.subtotal = 0
        this.total = 0
      }

      if(this.selectedGiftCardType.value_type === 1){
        this.calculateTotals()
      }
      if(this.selectedGiftCardType.value_type === 2){
        this.selectedGame = this.gift_card_games[0]
        this.calculateGameValue()
      }

      this.gc_key++
    },
    copyPaymentRequestLink(event){
      event.preventDefault()
      let url = 'https://offthecouch.io/pay/' + this.transaction.url_hash
      navigator.clipboard.writeText(url);
    },
    emailSettingActive(email_type){
      let setting = _.find(this.email_settings, {email_type: email_type})
      if(!setting){return false}
      return setting.is_active
    },
    async saveCustomDeposit(){
      if(this.deposit.custom_deposit_enabled && parseFloat(this.deposit.custom_deposit_amount) <= 0){
        this.errs.custom_deposit = 'Invalid amount'
        return
      } else {
        this.errs.custom_deposit = ''
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/'  + companyId + '/groups/' + companyGroupId + '/transactions/' + this.transaction.id + '/deposit'
      let data = {
        custom_deposit_enabled: this.deposit.custom_deposit_enabled,
        custom_deposit_amount: this.deposit.custom_deposit_amount
      }

      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.modals.custom_deposit = false
          swal('Success!', 'Custom deposit settings have been updated', 'success')
          this.getTransaction()
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    enableCustomDeposit(event){
      event.preventDefault()
      this.deposit.custom_deposit_enabled = this.transaction.custom_deposit_enabled
      this.deposit.custom_deposit_amount = this.transaction.custom_deposit_amount
      this.modals.custom_deposit = true
    },
    refundEmailConfirmationPrompt(item){
      swal({
        title: 'Are you sure?',
        html: "This action will send a refund email to customer.",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm'
      }).then((response)=> {
        if(response.value == true){
          this.sendRefundEmail(item);
        }
      })
    },
    downloadTransactionPDF(){
      this.$refs.transactionPdf.generatePdf()
    },
    calculateGameValue(){
      this.gift_card.unit_value = 0
      this.number_of_players = 0
      if(this.selectedGame.cumulative_category_pricing){
        if(this.selectedGame.pricing_type_id === 1) {
          for (let m in this.selectedGame.pricing_categories) {
            this.number_of_players += this.selectedGame.pricing_categories[m].number_of_players
          }
          for (let m in this.selectedGame.pricing_categories) {
            for(let n in this.selectedGame.game_pricing){
              if(this.selectedGame.game_pricing[n].category_id === this.selectedGame.pricing_categories[m].category_id
                && this.number_of_players >= this.selectedGame.game_pricing[n].min_players
                && this.number_of_players <= this.selectedGame.game_pricing[n].max_players) {
                this.gift_card.unit_value += this.selectedGame.game_pricing[n].price * this.selectedGame.pricing_categories[m].number_of_players
                break
              }
            }
            let last_index = _.findLastKey(this.selectedGame.game_pricing, {category_id: this.selectedGame.pricing_categories[m].category_id})
            if(this.number_of_players > this.selectedGame.game_pricing[last_index].max_players){
              this.gift_card.unit_value += this.selectedGame.game_pricing[last_index].price * this.selectedGame.pricing_categories[m].number_of_players
            }
            let smallest_price_category = _.find(this.selectedGame.game_pricing, {category_id : this.selectedGame.pricing_categories[m].category_id})
            if(this.number_of_players !== 0 &&
              this.number_of_players < smallest_price_category.min_players){
              this.gift_card.unit_value += smallest_price_category.price * this.selectedGame.pricing_categories[m].number_of_players
            }
          }
        }
        else if(this.selectedGame.pricing_type_id === 2){
          for (let m in this.selectedGame.pricing_categories) {
            this.number_of_players += this.selectedGame.pricing_categories[m].number_of_players
          }
          for(let m in this.selectedGame.pricing_categories){
            for(let n in this.selectedGame.game_pricing){
              if(this.selectedGame.game_pricing[n].category_id === this.selectedGame.pricing_categories[m].category_id
                && this.number_of_players >= this.selectedGame.game_pricing[n].min_players
                && this.number_of_players <= this.selectedGame.game_pricing[n].max_players) {
                this.gift_card.unit_value += this.selectedGame.game_pricing[n].price
                break
              }
            }
            let last_index = _.findLastKey(this.selectedGame.game_pricing, {category_id: this.selectedGame.pricing_categories[m].category_id})
            if(this.number_of_players > this.selectedGame.game_pricing[last_index].max_players){
              this.gift_card.unit_value += this.selectedGame.game_pricing[last_index].price
            }

            let smallest_price_category = _.find(this.selectedGame.game_pricing, {category_id : this.selectedGame.pricing_categories[m].category_id})
            if(this.number_of_players !== 0 &&
              this.number_of_players < smallest_price_category.min_players){
              this.gift_card.unit_value += smallest_price_category.price
            }
          }
        }
      } else {
        if(this.selectedGame.pricing_type_id === 1){
          for(let m in this.selectedGame.pricing_categories){

            this.number_of_players += this.selectedGame.pricing_categories[m].number_of_players
            for(let n in this.selectedGame.game_pricing){
              if(this.selectedGame.game_pricing[n].category_id === this.selectedGame.pricing_categories[m].category_id
                && this.selectedGame.pricing_categories[m].number_of_players >= this.selectedGame.game_pricing[n].min_players
                && this.selectedGame.pricing_categories[m].number_of_players <= this.selectedGame.game_pricing[n].max_players) {
                this.gift_card.unit_value += this.selectedGame.game_pricing[n].price * this.selectedGame.pricing_categories[m].number_of_players
                break
              }
            }
            let last_index = _.findLastKey(this.selectedGame.game_pricing, {category_id: this.selectedGame.pricing_categories[m].category_id})
            if(this.selectedGame.pricing_categories[m].number_of_players > this.selectedGame.game_pricing[last_index].max_players){
              this.gift_card.unit_value += this.selectedGame.game_pricing[last_index].price * this.selectedGame.pricing_categories[m].number_of_players
            }

            let smallest_price_category = _.find(this.selectedGame.game_pricing, {category_id : this.selectedGame.pricing_categories[m].category_id})
            if(this.selectedGame.pricing_categories[m].number_of_players !== 0 &&
              this.selectedGame.pricing_categories[m].number_of_players < smallest_price_category.min_players){
              this.gift_card.unit_value += smallest_price_category.price * this.selectedGame.pricing_categories[m].number_of_players
            }
          }
        }
        else if(this.selectedGame.pricing_type_id === 2){
          for(let m in this.selectedGame.pricing_categories){
            this.number_of_players += this.selectedGame.pricing_categories[m].number_of_players
            for(let n in this.selectedGame.game_pricing){
              if(this.selectedGame.game_pricing[n].category_id === this.selectedGame.pricing_categories[m].category_id
                && this.selectedGame.pricing_categories[m].number_of_players >= this.selectedGame.game_pricing[n].min_players
                && this.selectedGame.pricing_categories[m].number_of_players <= this.selectedGame.game_pricing[n].max_players) {
                this.gift_card.unit_value += this.selectedGame.game_pricing[n].price
                break
              }
            }
            let last_index = _.findLastKey(this.selectedGame.game_pricing, {category_id: this.selectedGame.pricing_categories[m].category_id})
            if(this.selectedGame.pricing_categories[m].number_of_players > this.selectedGame.game_pricing[last_index].max_players){
              this.gift_card.unit_value += this.selectedGame.game_pricing[last_index].price
            }

            let smallest_price_category = _.find(this.selectedGame.game_pricing, {category_id : this.selectedGame.pricing_categories[m].category_id})
            if(this.selectedGame.pricing_categories[m].number_of_players !== 0 &&
              this.selectedGame.pricing_categories[m].number_of_players < smallest_price_category.min_players){
              this.gift_card.unit_value += smallest_price_category.price
            }
          }
        }
      }

      this.calculateTotals()

    },
    getCategories(items){

      const groupedArray = [];

      items.forEach(item => {
        const { category_id, category, minimum_participants, default_participants } = item;

        // Find if the category_id already exists in groupedArray
        let group = groupedArray.find(g => g.category_id === category_id);

        if (!group) {
          // If it doesn't exist, create a new group
          group = {
            category_id: category_id,
            category: category,
            minimum_participants: minimum_participants,
            default_participants: default_participants,
            number_of_players: 1,
            pricing: []
          };
          groupedArray.push(group);
        }

        group.pricing.push(item);
      });
      return groupedArray;
    },
    disabledDates(time) {
      let date = new Date();
      let previousDate = date.setDate(date.getDate() - 1);
      return time.getTime() < previousDate;
    },
    setDeliveryDate(){
      this.gift_card.delivery_date = new Date()
    },
    getLabel(amount){
      if(amount.id === 0 || amount.id === 1){
        return amount.name
      } else {
        return this.currency_symbol + this.roundToFixed(amount.amount, 2)
      }
    },
    setGiftCardDesign(index){
      this.gift_card.design_url = this.gift_card_designs[index]
    },
    addGiftCard(event){
      event.preventDefault();
      this.getGiftCardSettings()
      this.modals.add_gift_card = true
    },
    addBooking(event){
      event.preventDefault();
      this.getGames()
      this.modals.add_booking = true
    },
    async getGiftCardSettings(){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/transactions/' + this.transaction.id + '/gift-cards'
      this.axios.get(url, config)
        .then(response => {
          this.gift_card_settings = response.data.settings
          this.gift_card_types = response.data.gift_card_types
          this.gift_card_games = response.data.games
          this.selectedGiftCardType = this.gift_card_types.length > 0 ? this.gift_card_types[0] : ''
          this.gift_card_designs = JSON.parse(this.selectedGiftCardType.designs)
          this.gift_card.design_url = this.gift_card_designs[0]

          for(let n in this.gift_card_games){
            this.gift_card_games[n].game_pricing = JSON.parse(this.gift_card_games[n].game_pricing)
            this.gift_card_games[n].pricing_categories = this.getCategories(this.gift_card_games[n].game_pricing)
          }
          this.selectGiftCardType()

        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    hasPermissions(scope){
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      const matchFound = permissions.some(element => element === scope);
      return matchFound
    },
    capitalizeFirstLetter(string){
      if (!string) {
        return string;
      }
      string = string.replace(/_/g, ' ');
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    isCustomPaymentType(type){
      return type !== 'pos' && type !== 'customer_credit' && type !== 'cash'
        && type !== 'bank_transfer' && type !== 'gift_card' && type !== 'card'
    },
    async updateWaiver(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/waivers/' + this.selectedWaiver.id

      let data = {
        booking_slot_id: this.selectedBooking.id
      }

      axios.put(url, data, config)
        .then(response => {
          this.loading = false
          this.modals.bookings = false
          this.getTransaction()
          swal({
            title: 'Congratulations!',
            text: "The waiver has been reassigned",
            type: 'success',
            confirmButtonColor: '#1d8cf8',
            confirmButtonText: "OK"
          })
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    getDate(d){
      return formattedDate(this.date_format, d)
    },
    async getBookings(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/bookings?day=' + this.current + '&type=2'

      axios.get(url, config)
        .then(response => {
          this.loading = false
          this.assignable_bookings = response.data.bookings
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    editWaiver(item, index){
      this.selectedWaiver = item
      this.modals.bookings = true
    },
    deleteWaiverPrompt(item){
      swal({
        title: 'Are you sure?',
        text: "The waiver will be permanently deleted",
        type: 'warning',
        showCancelButton: true
      }).then((response)=> {
        if(response.value == true){
          this.deleteWaiver(item)
        }
      })
    },
    async deleteWaiver(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/waivers/' + item.id

      axios.delete(url, config)
        .then(response => {
          this.loading = false
          this.getTransaction()
          swal({
            title: 'Congratulations!',
            text: "The waiver has been deleted",
            type: 'success',
            confirmButtonColor: '#1d8cf8',
            confirmButtonText: "OK"
          })
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else if (err.response.status == 500){
            swal({
              title: 'Ooops!',
              text: "You cannot delete a waiver for a completed or in-progress game",
              type: 'error'
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })

    },
    openPolicy(item){
      this.selectedPolicy = item.policy
      this.modals.policy = true
    },
    isRemovablePayment(item){
      if(item.type !== 'card'){
        return true
      }
      return false
    },
    goToGame(item){
      this.$router.push({ path: '/games/history/instance', query: { id: item.game_instance_id }})
    },
    hasPassed(date){
      if(!date){
        return
      }
      let dt = new Date()
      let booking_date = new Date()
      booking_date.setFullYear(date.split('T')[0].split('-')[0])
      booking_date.setMonth(date.split('T')[0].split('-')[1] - 1, date.split('T')[0].split('-')[2])
      return dt > booking_date ? true : false
    },
    deletePaymentRecordPrompt(item, index){
      swal({
        title: 'Are you sure?',
        html: "The payment record will be permanently deleted",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm'
      }).then((response)=> {
        if(response.value == true){
          this.deletePaymentRecord(item);
        }
      })
    },
    async deletePaymentRecord(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/transactions/' + this.transaction.id + '/payments/' + item.id

      this.axios.delete(url, config)
        .then(response => {
          swal('Success!', 'The payment record has been deleted.', 'success');
          this.getTransaction()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          swal('Error!', 'Something went wrong', 'error');
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })

    },
    sendGiftCardEmailPrompt(item, event){
      event.preventDefault()
      if(!this.emailSettingActive('gift_card_confirmation')){
        return
      }
      let html =  '<small>Completing this action will send a gift card purchase confirmation email</small>'
      let title = 'Send confirmation email'

      swal({
        title: title,
        html: html,
        type: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#1d8cf8',
        confirmButtonColor: '#fd5d93',
        confirmButtonText: 'Send'
      }).then((response)=> {
        if(response.value == true){
          this.resendConfirmationEmail(item, 'gift_card');
        }
      })
    },
    sendReminderEmailPrompt(item, event){
      event.preventDefault()
      swal({
        title: 'Are you sure?',
        html: "This action will send a reminder email to customer.",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm'
      }).then((response)=> {
        if(response.value == true){
          this.sendReminderEmail(item);
        }
      })
    },
    sendFollowUpEmailPrompt(item, event){
      event.preventDefault()
      swal({
        title: 'Are you sure?',
        html: "This action will send a follow up email to customer.",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm'
      }).then((response)=> {
        if(response.value == true){
          this.sendFollowUpEmail(item);
        }
      })
    },
    async sendRefundEmail(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/transactions/' + this.transaction.id + '/payments/' + item.id
      let data = {
        email: this.transaction.email,
        refund_amount: item.refunded
      }

      this.axios.post(url, data, config)
        .then(response => {
          swal({
            title: 'Success',
            text: "Refund email sent to customer",
            type: 'success',
            confirmButtonColor: '#1d8cf8',
            confirmButtonText: 'OK'
          })
          this.getTransaction()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async sendReminderEmail(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/transactions/' + this.transaction.id + '/reminder'
      let data = {
        email: this.transaction.email,
        booking_id: item.id
      }

      this.axios.post(url, data, config)
        .then(response => {
          swal({
            title: 'Success',
            text: "Reminder email sent to customer",
            type: 'success',
            confirmButtonColor: '#1d8cf8',
            confirmButtonText: 'OK'
          })
          this.getTransaction()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async sendFollowUpEmail(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/transactions/' + this.transaction.id + '/follow-up'
      let data = {
        email: this.transaction.email,
        booking_id: item.id
      }

      this.axios.post(url, data, config)
        .then(response => {
          swal({
            title: 'Success',
            text: "Follow up email sent to customer",
            type: 'success',
            confirmButtonColor: '#1d8cf8',
            confirmButtonText: 'OK'
          })
          this.getTransaction()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    deactivateGiftCardPrompt(item){
      swal({
        title: 'Are you sure?',
        html: "This action will deactivate the gift card. You will need to manually refund any remaining balance.",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm'
      }).then((response)=> {
        if(response.value == true){
          this.deactivateGiftCard(item);
        }
      })
    },
    async deactivateGiftCard(item){
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id

      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/transactions/' + this.transaction.id + '/gift-cards?id=' + item.id

      this.loading = true
      axios.delete(url, config)
        .then(response => {
          this.loading = false

          swal({
            title: 'Success!',
            text: "Gift card has been deactivated",
            type: 'success'
          })

          this.getTransaction()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    getGiftCardCode(code){
      if(!code){
        return
      }
      if(isNaN(code)){
        return code
      }
      let c = code.match(/.{1,4}/g);
      return c.join(' ')
    },
    overridePrice(item, source){

      if(source === 'new'){
        if(item.is_custom_pricing_enabled){
          for(let n in this.custom_pricing_categories){
            if(item.cumulative_category_pricing){
              //Price
              if(!this.custom_pricing_categories[n].hasOwnProperty('original_price')){
                for(let m in this.custom_pricing_categories[n].category_pricing){
                  if(item.number_of_players >= this.custom_pricing_categories[n].category_pricing[m].min_players
                    && item.number_of_players <= this.custom_pricing_categories[n].category_pricing[m].max_players){
                    this.custom_pricing_categories[n].original_price = this.custom_pricing_categories[n].category_pricing[m].price
                    this.custom_pricing_categories[n].price = this.custom_pricing_categories[n].category_pricing[m].price
                  }
                }
                let last_index = _.findLastKey(this.custom_pricing_categories[n].category_pricing)
                if(last_index && item.number_of_players > this.custom_pricing_categories[n].category_pricing[last_index].max_players){
                  this.custom_pricing_categories[n].original_price = this.custom_pricing_categories[n].category_pricing[last_index].price
                  this.custom_pricing_categories[n].price = this.custom_pricing_categories[n].category_pricing[last_index].price
                }
                let smallest_price_category = _.find(this.custom_pricing_categories[n].category_pricing)
                if(smallest_price_category && item.number_of_players !== 0 &&
                  item.number_of_players < smallest_price_category.min_players){
                  this.custom_pricing_categories[n].original_price = smallest_price_category.price
                  this.custom_pricing_categories[n].price = smallest_price_category.price
                }
              }
              //Original price
              if(this.custom_pricing_categories[n].hasOwnProperty('original_price')){
                for(let m in this.custom_pricing_categories[n].category_pricing){
                  if(item.number_of_players >= this.custom_pricing_categories[n].category_pricing[m].min_players
                    && item.number_of_players <= this.custom_pricing_categories[n].category_pricing[m].max_players){
                    this.custom_pricing_categories[n].original_price = this.custom_pricing_categories[n].category_pricing[m].price
                  }
                }

                let last_index = _.findLastKey(this.custom_pricing_categories[n].category_pricing)
                if(last_index && item.number_of_players > this.custom_pricing_categories[n].category_pricing[last_index].max_players){
                  this.custom_pricing_categories[n].original_price = this.custom_pricing_categories[n].category_pricing[last_index].price
                }
                let smallest_price_category = _.find(this.custom_pricing_categories[n].category_pricing)
                if(smallest_price_category && item.number_of_players !== 0 &&
                  this.number_of_players < smallest_price_category.min_players){
                  this.custom_pricing_categories[n].original_price = smallest_price_category.price
                }
              }
            }
            else {

              for(let m in this.custom_pricing_categories[n].category_pricing){
                if(this.custom_pricing_categories[n].number_of_players >= this.custom_pricing_categories[n].category_pricing[m].min_players
                  && this.custom_pricing_categories[n].number_of_players <= this.custom_pricing_categories[n].category_pricing[m].max_players) {
                  this.custom_pricing_categories[n].original_price = this.custom_pricing_categories[n].category_pricing[m].price
                  this.custom_pricing_categories[n].price = this.custom_pricing_categories[n].category_pricing[m].price
                }
              }
              let last_index = _.findLastKey(this.custom_pricing_categories[n].category_pricing)
              if(last_index && this.custom_pricing_categories[n].number_of_players > this.custom_pricing_categories[n].category_pricing[last_index].max_players){
                this.custom_pricing_categories[n].original_price = this.custom_pricing_categories[n].category_pricing[last_index].price
                this.custom_pricing_categories[n].price = this.custom_pricing_categories[n].category_pricing[last_index].price
              }

              let smallest_price_category = _.find(this.custom_pricing_categories[n].category_pricing)
              if(smallest_price_category && this.custom_pricing_categories[n].number_of_players !== 0 &&
                this.custom_pricing_categories[n].number_of_players < smallest_price_category.min_players){
                this.custom_pricing_categories[n].original_price = smallest_price_category.price
                this.custom_pricing_categories[n].price = smallest_price_category.price
              }
            }
          }
        } else {
          for(let n in this.pricing_categories){
            if(item.cumulative_category_pricing){
              if(!this.pricing_categories[n].hasOwnProperty('original_price')){
                for(let m in this.pricing_categories[n].category_pricing){
                  if(item.number_of_players >= this.pricing_categories[n].category_pricing[m].min_players
                    && item.number_of_players <= this.pricing_categories[n].category_pricing[m].max_players){
                    this.pricing_categories[n].original_price = this.pricing_categories[n].category_pricing[m].price
                    this.pricing_categories[n].price = this.pricing_categories[n].category_pricing[m].price
                  }
                }
                let last_index = _.findLastKey(this.pricing_categories[n].category_pricing)
                if(last_index && item.number_of_players > this.pricing_categories[n].category_pricing[last_index].max_players){
                  this.pricing_categories[n].original_price = this.pricing_categories[n].category_pricing[last_index].price
                  this.pricing_categories[n].price = this.pricing_categories[n].category_pricing[last_index].price
                }
                let smallest_price_category = _.find(this.pricing_categories[n].category_pricing)
                if(smallest_price_category && item.number_of_players !== 0 &&
                  item.number_of_players < smallest_price_category.min_players){
                  this.pricing_categories[n].original_price = smallest_price_category.price
                  this.pricing_categories[n].price = smallest_price_category.price
                }
              }
              //Original price
              if(this.pricing_categories[n].hasOwnProperty('original_price')){
                for(let m in this.pricing_categories[n].category_pricing){
                  if(item.number_of_players >= this.pricing_categories[n].category_pricing[m].min_players
                    && item.number_of_players <= this.pricing_categories[n].category_pricing[m].max_players){
                    this.pricing_categories[n].original_price = this.pricing_categories[n].category_pricing[m].price
                  }
                }

                let last_index = _.findLastKey(this.pricing_categories[n].category_pricing)
                if(last_index && item.number_of_players > this.pricing_categories[n].category_pricing[last_index].max_players){
                  this.pricing_categories[n].original_price = this.pricing_categories[n].category_pricing[last_index].price
                }
                let smallest_price_category = _.find(this.pricing_categories[n].category_pricing)
                if(smallest_price_category && item.number_of_players !== 0 &&
                  item.number_of_players < smallest_price_category.min_players){
                  this.pricing_categories[n].original_price = smallest_price_category.price
                }
              }
            } else {
              for(let m in this.pricing_categories[n].category_pricing){
                if(this.pricing_categories[n].number_of_players >= this.pricing_categories[n].category_pricing[m].min_players
                  && this.pricing_categories[n].number_of_players <= this.pricing_categories[n].category_pricing[m].max_players) {
                  this.pricing_categories[n].original_price = this.pricing_categories[n].category_pricing[m].price
                  this.pricing_categories[n].price = this.pricing_categories[n].category_pricing[m].price
                }
              }
              let last_index = _.findLastKey(this.pricing_categories[n].category_pricing)
              if(last_index && this.pricing_categories[n].number_of_players > this.pricing_categories[n].category_pricing[last_index].max_players){
                this.pricing_categories[n].original_price = this.pricing_categories[n].category_pricing[last_index].price
                this.pricing_categories[n].price = this.pricing_categories[n].category_pricing[last_index].price
              }

              let smallest_price_category = _.find(this.pricing_categories[n].category_pricing)
              if(smallest_price_category && this.pricing_categories[n].number_of_players !== 0 &&
                this.pricing_categories[n].number_of_players < smallest_price_category.min_players){
                this.pricing_categories[n].original_price = smallest_price_category.price
                this.pricing_categories[n].price = smallest_price_category.price
              }
            }
          }
        }
      } else {
        for(let n in item.pricing_categories){
          if(item.cumulative_category_pricing){
            if(!item.pricing_categories[n].hasOwnProperty('original_price')){
              for(let m in item.pricing_categories[n].category_pricing){
                if(item.number_of_players >= item.pricing_categories[n].category_pricing[m].min_players
                  && item.number_of_players <= item.pricing_categories[n].category_pricing[m].max_players){
                  item.pricing_categories[n].original_price = item.pricing_categories[n].category_pricing[m].price
                  item.pricing_categories[n].price = item.pricing_categories[n].category_pricing[m].price
                }
              }
              let last_index = _.findLastKey(item.pricing_categories[n].category_pricing)
              if(last_index && item.number_of_players > item.pricing_categories[n].category_pricing[last_index].max_players){
                item.pricing_categories[n].original_price = item.pricing_categories[n].category_pricing[last_index].price
                item.pricing_categories[n].price = item.pricing_categories[n].category_pricing[last_index].price
              }
              let smallest_price_category = _.find(item.pricing_categories[n].category_pricing)
              if(smallest_price_category && item.number_of_players !== 0 &&
                item.number_of_players < smallest_price_category.min_players){
                item.pricing_categories[n].original_price = smallest_price_category.price
                item.pricing_categories[n].price = smallest_price_category.price
              }
            }
            //Original price
            if(item.pricing_categories[n].hasOwnProperty('original_price')){
              for(let m in item.pricing_categories[n].category_pricing){
                if(item.number_of_players >= item.pricing_categories[n].category_pricing[m].min_players
                  && item.number_of_players <= item.pricing_categories[n].category_pricing[m].max_players){
                  item.pricing_categories[n].original_price = item.pricing_categories[n].category_pricing[m].price
                }
              }

              let last_index = _.findLastKey(item.pricing_categories[n].category_pricing)
              if(last_index && item.number_of_players > item.pricing_categories[n].category_pricing[last_index].max_players){
                item.pricing_categories[n].original_price = item.pricing_categories[n].category_pricing[last_index].price
              }
              let smallest_price_category = _.find(item.pricing_categories[n].category_pricing)
              if(smallest_price_category && item.number_of_players !== 0 &&
                item.number_of_players < smallest_price_category.min_players){
                item.pricing_categories[n].original_price = smallest_price_category.price
              }
            }
          } else {
            for(let m in item.pricing_categories[n].category_pricing){
              if(item.pricing_categories[n].number_of_players >= item.pricing_categories[n].category_pricing[m].min_players
                && item.pricing_categories[n].number_of_players <= item.pricing_categories[n].category_pricing[m].max_players) {
                item.pricing_categories[n].original_price = item.pricing_categories[n].category_pricing[m].price
                item.pricing_categories[n].price = item.pricing_categories[n].category_pricing[m].price
              }
            }
            let last_index = _.findLastKey(item.pricing_categories[n].category_pricing)
            if(last_index && item.pricing_categories[n].number_of_players > item.pricing_categories[n].category_pricing[last_index].max_players){
              item.pricing_categories[n].original_price = item.pricing_categories[n].category_pricing[last_index].price
              item.pricing_categories[n].price = item.pricing_categories[n].category_pricing[last_index].price
            }

            let smallest_price_category = _.find(item.pricing_categories[n].category_pricing)
            if(smallest_price_category && item.pricing_categories[n].number_of_players !== 0 &&
              item.pricing_categories[n].number_of_players < smallest_price_category.min_players){
              item.pricing_categories[n].original_price = smallest_price_category.price
              item.pricing_categories[n].price = smallest_price_category.price
            }
          }
        }
      }

      item.price_override = true
      this.pr_key++
    },
    saveOverride(item, source){
      if(source === 'new'){
        if(item.is_custom_pricing_enabled){
          for(let n in this.custom_pricing_categories){
            if(this.custom_pricing_categories[n].hasOwnProperty('original_price') &&
              this.custom_pricing_categories[n].original_price  === this.custom_pricing_categories[n].price){
              delete this.custom_pricing_categories[n].original_price
            }
          }
        } else {
          for(let n in this.pricing_categories){
            if(this.pricing_categories[n].hasOwnProperty('original_price') &&
              this.pricing_categories[n].original_price  === this.pricing_categories[n].price){
              delete this.pricing_categories[n].original_price
            }
          }
        }
      } else {
        for(let n in item.pricing_categories){
          if(item.pricing_categories[n].hasOwnProperty('original_price') &&
            item.pricing_categories[n].original_price  === item.pricing_categories[n].price){
            delete item.pricing_categories[n].original_price
          }
        }
      }

      this.getSubtotal(item, source)
      item.price_override = false
      this.pr_key++
    },
    setOriginalPrice(category){
      category.price = category.original_price
      this.pr_key++
    },
    getSubtotal(item, source){
      let pricing = []
      let pricing_type_id = ''
      let cumulative_category_pricing = ''
      if(source === 'new'){
        pricing = item.is_custom_pricing_enabled ? item.custom_pricing : item.pricing
        item.pricing_categories = item.is_custom_pricing_enabled ? this.custom_pricing_categories : this.pricing_categories
        pricing_type_id = item.is_custom_pricing_enabled ? item.slot_pricing_type_id : item.pricing_type_id
        cumulative_category_pricing = item.is_custom_pricing_enabled ? item.slot_cumulative_category_pricing : item.cumulative_category_pricing
      } else {
        pricing = item.is_custom_pricing_enabled ? JSON.parse(item.custom_pricing) : JSON.parse(item.game_pricing)
        pricing_type_id =  item.is_custom_pricing_enabled ? item.slot_pricing_type_id : item.pricing_type_id
        cumulative_category_pricing = item.is_custom_pricing_enabled ?  item.slot_cumulative_category_pricing : item.cumulative_category_pricing
      }

      item.subtotal = 0
      item.number_of_players = 0
      if(cumulative_category_pricing){
        if(pricing_type_id === 1){
          for (let m in item.pricing_categories) {
            item.number_of_players += item.pricing_categories[m].number_of_players
          }
          for(let m in item.pricing_categories){
            if(item.pricing_categories[m].archived){
              pricing = item.pricing_categories[m].pricing
            }
            for(let n in pricing){
              if(pricing[n].category_id === item.pricing_categories[m].category_id
                && item.number_of_players >= pricing[n].min_players
                && item.number_of_players <= pricing[n].max_players) {
                if(item.pricing_categories[m].hasOwnProperty('original_price')){
                  item.subtotal += item.pricing_categories[m].price * item.pricing_categories[m].number_of_players
                } else {
                  item.subtotal += pricing[n].price * item.pricing_categories[m].number_of_players
                }
                break
              }
            }

            let last_index = _.findLastKey(pricing, {category_id: item.pricing_categories[m].category_id})
            if(item.number_of_players > pricing[last_index].max_players){
              if(item.pricing_categories[m].hasOwnProperty('original_price')){
                item.subtotal += item.pricing_categories[m].price * item.pricing_categories[m].number_of_players
              } else {
                item.subtotal += pricing[last_index].price * item.pricing_categories[m].number_of_players
              }

            }

            let smallest_price_category = _.find(pricing, {category_id : item.pricing_categories[m].category_id})
            if(item.number_of_players !== 0 &&
              item.number_of_players < smallest_price_category.min_players){
              if(item.pricing_categories[m].hasOwnProperty('original_price')){
                item.subtotal += item.pricing_categories[m].price * item.pricing_categories[m].number_of_players
              } else {
                item.subtotal += smallest_price_category.price * item.pricing_categories[m].number_of_players
              }

            }
          }
        }
        else if(pricing_type_id === 2){
          for (let m in item.pricing_categories) {
            item.number_of_players += item.pricing_categories[m].number_of_players
          }
          for(let m in item.pricing_categories){
            if(item.pricing_categories[m].archived){
              pricing = item.pricing_categories[m].pricing
            }
            for(let n in pricing){
              if(pricing[n].category_id === item.pricing_categories[m].category_id
                && item.number_of_players >= pricing[n].min_players
                && item.number_of_players <= pricing[n].max_players) {
                if(item.pricing_categories[m].hasOwnProperty('original_price')){
                  item.subtotal += item.pricing_categories[m].price
                } else {
                  item.subtotal += pricing[n].price
                }

                break
              }
            }
            let last_index = _.findLastKey(pricing, {category_id: item.pricing_categories[m].category_id})
            if(item.number_of_players > pricing[last_index].max_players){
              if(item.pricing_categories[m].hasOwnProperty('original_price')){
                item.subtotal += item.pricing_categories[m].price
              } else {
                item.subtotal += pricing[last_index].price
              }

            }

            let smallest_price_category = _.find(pricing, {category_id : item.pricing_categories[m].category_id})
            if(item.number_of_players !== 0 &&
              item.number_of_players < smallest_price_category.min_players){
              if(item.pricing_categories[m].hasOwnProperty('original_price')){
                item.subtotal += item.pricing_categories[m].price
              } else {
                item.subtotal += smallest_price_category.price
              }

            }
          }
        }
      } else {
        if(pricing_type_id === 1){
          for(let m in item.pricing_categories){
            item.number_of_players += item.pricing_categories[m].number_of_players
            if(item.pricing_categories[m].archived){
              pricing = item.pricing_categories[m].pricing
            }
            for(let n in pricing){
              if(pricing[n].category_id === item.pricing_categories[m].category_id
                && item.pricing_categories[m].number_of_players >= pricing[n].min_players
                && item.pricing_categories[m].number_of_players <= pricing[n].max_players) {
                if(item.pricing_categories[m].hasOwnProperty('original_price')){
                  item.subtotal += item.pricing_categories[m].price * item.pricing_categories[m].number_of_players
                } else {
                  item.subtotal += pricing[n].price * item.pricing_categories[m].number_of_players
                }

                break
              }
            }

            let last_index = _.findLastKey(pricing, {category_id: item.pricing_categories[m].category_id})
            if(item.pricing_categories[m].number_of_players > pricing[last_index].max_players){
              if(item.pricing_categories[m].hasOwnProperty('original_price')){
                item.subtotal += item.pricing_categories[m].price * item.pricing_categories[m].number_of_players
              } else {
                item.subtotal += pricing[last_index].price * item.pricing_categories[m].number_of_players
              }

            }

            let smallest_price_category = _.find(pricing, {category_id : item.pricing_categories[m].category_id})
            if(item.pricing_categories[m].number_of_players !== 0 &&
              item.pricing_categories[m].number_of_players < smallest_price_category.min_players){
              if(item.pricing_categories[m].hasOwnProperty('original_price')){
                item.subtotal += item.pricing_categories[m].price * item.pricing_categories[m].number_of_players
              } else {
                item.subtotal += smallest_price_category.price * item.pricing_categories[m].number_of_players
              }

            }
          }
        }
        else if(pricing_type_id === 2){
          for(let m in item.pricing_categories){
            item.number_of_players += item.pricing_categories[m].number_of_players
            if(item.pricing_categories[m].archived){
              pricing = item.pricing_categories[m].pricing
            }
            for(let n in pricing){
              if(pricing[n].category_id === item.pricing_categories[m].category_id
                && item.pricing_categories[m].number_of_players >= pricing[n].min_players
                && item.pricing_categories[m].number_of_players <= pricing[n].max_players) {
                if(item.pricing_categories[m].hasOwnProperty('original_price')){
                  item.subtotal += item.pricing_categories[m].price
                } else {
                  item.subtotal += pricing[n].price
                }

                break
              }
            }
            let last_index = _.findLastKey(pricing, {category_id: item.pricing_categories[m].category_id})
            if(item.pricing_categories[m].number_of_players > pricing[last_index].max_players){
              if(item.pricing_categories[m].hasOwnProperty('original_price')){
                item.subtotal += item.pricing_categories[m].price
              } else {
                item.subtotal += pricing[last_index].price
              }

            }

            let smallest_price_category = _.find(pricing, {category_id : item.pricing_categories[m].category_id})
            if(item.pricing_categories[m].number_of_players !== 0 &&
              item.pricing_categories[m].number_of_players < smallest_price_category.min_players){
              if(item.pricing_categories[m].hasOwnProperty('original_price')){
                item.subtotal += item.pricing_categories[m].price
              } else {
                item.subtotal += smallest_price_category.price
              }

            }
          }
        }
      }

      this.pr_key++
    },
    getRate(item, game, source){
      let pricing = []
      let pricing_type_id = ''
      let cumulative_category_pricing = ''
      if(source === 'new'){
        pricing = game.is_custom_pricing_enabled ? game.custom_pricing : game.pricing
        pricing_type_id = game.is_custom_pricing_enabled ? game.slot_pricing_type_id : game.pricing_type_id
        cumulative_category_pricing = game.is_custom_pricing_enabled ? game.slot_cumulative_category_pricing : game.cumulative_category_pricing

      } else {
        pricing = game.is_custom_pricing_enabled ? JSON.parse(game.custom_pricing): JSON.parse(game.game_pricing)
        pricing_type_id = game.is_custom_pricing_enabled ? game.slot_pricing_type_id : game.pricing_type_id
        cumulative_category_pricing = game.is_custom_pricing_enabled ? game.slot_cumulative_category_pricing : game.cumulative_category_pricing
      }
      if(item.archived){
        pricing = item.pricing
      }

      if(cumulative_category_pricing){
        for(let n in pricing){
          if(pricing[n].category_id === item.category_id
            && game.number_of_players >= pricing[n].min_players
            && game.number_of_players <= pricing[n].max_players) {
            if(item.hasOwnProperty('original_price')){
              return pricing_type_id === 1 ? this.getAmount(item.price) + ` per ` + item.category
                : this.getAmount(item.price) + ' for ' + item.category + ' (' + pricing[n].min_players + '-' + pricing[n].max_players + ' players)'
            } else {
              return pricing_type_id === 1 ? this.getAmount(pricing[n].price) + ` per ` + item.category
                : this.getAmount(pricing[n].price) + ' for ' + item.category + ' (' + pricing[n].min_players + '-' + pricing[n].max_players + ' players)'
            }

          }
        }
        let last_index = _.findLastKey(pricing, {category_id: item.category_id})
        if(last_index && game.number_of_players > pricing[last_index].max_players){
          if(item.hasOwnProperty('original_price')){
            return pricing_type_id === 1 ? this.getAmount(item.price) + ` per ` + item.category
              : this.getAmount(item.price) + ' for ' + item.category + ' (' + pricing[last_index].min_players + '-' + pricing[last_index].max_players + ' players)'
          } else {
            return pricing_type_id === 1 ? this.getAmount(pricing[last_index].price) + ` per ` + item.category
              : this.getAmount(pricing[last_index].price) + ' for ' + item.category + ' (' + pricing[last_index].min_players + '-' + pricing[last_index].max_players + ' players)'
          }
        }

        let smallest_price_category = _.find(pricing, {category_id : item.category_id})
        if(game.number_of_players !== 0 &&
          game.number_of_players < smallest_price_category.min_players){
          if(item.hasOwnProperty('original_price')){
            return pricing_type_id === 1 ? this.getAmount(item.price) + ` per ` + item.category
              : this.getAmount(item.price) + ' for ' + item.category + ' (' + smallest_price_category.min_players + '-' + smallest_price_category.max_players + ' players)'
          } else {
            return pricing_type_id === 1 ? this.getAmount(smallest_price_category.price) + ` per ` + item.category
              : this.getAmount(smallest_price_category.price) + ' for ' + item.category + ' (' + smallest_price_category.min_players + '-' + smallest_price_category.max_players + ' players)'
          }

        }
      } else {
        for(let n in pricing){

          if(pricing[n].category_id === item.category_id
            && item.number_of_players >= pricing[n].min_players
            && item.number_of_players <= pricing[n].max_players) {
            if(item.hasOwnProperty('original_price')){
              return pricing_type_id === 1 ? this.getAmount(item.price) + ` per ` + item.category
                : this.getAmount(item.price) + ' for ' + item.category + ' (' + pricing[n].min_players + '-' + pricing[n].max_players + ' players)'
            } else {
              return pricing_type_id === 1 ? this.getAmount(pricing[n].price) + ` per ` + item.category
                : this.getAmount(pricing[n].price) + ' for ' + item.category + ' (' + pricing[n].min_players + '-' + pricing[n].max_players + ' players)'
            }

          }
        }
        let last_index = _.findLastKey(pricing, {category_id: item.category_id})
        if(last_index && item.number_of_players > pricing[last_index].max_players){
          if(item.hasOwnProperty('original_price')){
            return pricing_type_id === 1 ? this.getAmount(item.price) + ` per ` + item.category
              : this.getAmount(item.price) + ' for ' + item.category + ' (' + pricing[last_index].min_players + '-' + pricing[last_index].max_players + ' players)'
          } else {
            return pricing_type_id === 1 ? this.getAmount(pricing[last_index].price) + ` per ` + item.category
              : this.getAmount(pricing[last_index].price) + ' for ' + item.category + ' (' + pricing[last_index].min_players + '-' + pricing[last_index].max_players + ' players)'
          }

        }

        let smallest_price_category = _.find(pricing, {category_id : item.category_id})
        if(item.number_of_players !== 0 &&
          item.number_of_players < smallest_price_category.min_players){
          if(item.hasOwnProperty('original_price')){
            return pricing_type_id === 1 ? this.getAmount(item.price) + ` per ` + item.category
              : this.getAmount(item.price) + ' for ' + item.category + ' (' + smallest_price_category.min_players + '-' + smallest_price_category.max_players + ' players)'
          } else {
            return pricing_type_id === 1 ? this.getAmount(smallest_price_category.price) + ` per ` + item.category
              : this.getAmount(smallest_price_category.price) + ' for ' + item.category + ' (' + smallest_price_category.min_players + '-' + smallest_price_category.max_players + ' players)'
          }

        }
      }

      return ''
    },
    checkIfCustom(item){
      if(item.name === 'Custom'){
        this.is_custom_purchase = true
      } else {
        this.is_custom_purchase = false
      }
      this.cat_key++
    },

    deleteItemPrompt(item){
      swal({
        title: 'Are you sure?',
        html: "The item will be removed from the transaction",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm'
      }).then((response)=> {
        if(response.value == true){
          this.deleteItem(item);
        }
      })
    },
    async deleteItem(item){
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id

      //validate
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/transactions/' + this.transaction.id + '/items?id=' + item.id

      this.loading = true
      axios.delete(url, config)
        .then(response => {
          this.loading = false

          swal({
            title: 'Success!',
            text: "Item has been removed from the transaction",
            type: 'success'
          })

          this.getTransaction()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    validateCustomPurchase(item){
      let errors = 0
      if(item.name === ''){
        this.errs.purchase.name = 'Purchase name cannot be empty'
        errors++
      } else {
        this.errs.purchase.name = ''
      }

      if(isNaN(item.price) || item.price < 0 || item.price === ''){
        this.errs.purchase.price = 'Invalid price'
        errors++
      } else {
        this.errs.purchase.price = ''
      }

      if(errors > 0){
        return false
      }
      return true
    },
    validatePurchase(item){
      let errors = 0
      if(!item.is_custom && item.quantity > item.available){
        this.errs.purchase.quantity = 'Quantity exceeds number of available items'
        errors++
      } else {
        this.errs.purchase.quantity = ''
      }

      if(errors > 0){
        return false
      }
      return true
    },
    validateGiftCardForm(){
      let errors = 0

      if(this.gift_card.custom_code == 1 && this.gift_card.code === ''){
        this.errs.custom_code = 'Gift card code cannot be empty'
        errors++
      } else if(this.gift_card.custom_code == 1 && this.gift_card.quantity > 1){
        this.errs.custom_code = 'You are trying to create more than one gift card with the same custom code. Code must be unique.'
        errors++
      } else if(this.gift_card.custom_code == 1 && this.gift_card.code.indexOf(' ') >= 0){
        this.errs.custom_code = 'Gift card code cannot have spaces'
        errors++
      }
      else {
        this.errs.custom_code = ''
      }

      if(this.gift_card.recipient_first_name === ''){
        this.errs.recipient_first_name = 'Recipient first name cannot be empty'
        errors++
      } else {
        this.errs.recipient_first_name = ''
      }

      if(this.gift_card.recipient_last_name === ''){
        this.errs.recipient_last_name = 'Recipient last name cannot be empty'
        errors++
      } else {
        this.errs.recipient_last_name = ''
      }

      if(this.gift_card.recipient_email === ''){
        this.errs.recipient_email = 'Recipient email cannot be empty'
        errors++
      } else {
        this.errs.recipient_email = ''
      }

      return errors > 0 ? false : true

    },
    async createGiftCard(){
      if(this.send_gift_card_to_self){
        this.gift_card.recipient_first_name = this.transaction.first_name
        this.gift_card.recipient_last_name = this.transaction.last_name
        this.gift_card.recipient_email = this.transaction.email
      }
      if(!this.validateGiftCardForm()){
        return
      }
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id

      //validate
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/transactions/' + this.transaction.id + '/gift-cards'
      this.gift_card.gift_card_type_id = this.selectedGiftCardType.id
      this.gift_card.price = this.gift_card.unit_price

      let data = {
        gift_card: this.gift_card
      }

      // data.gift_card.total_amount = data.gift_card.total.amount
      // if(!this.gift_card.design_url){
      //   this.gift_card.design_url = this.gift_card_designs[0]
      // }

      this.loading = true
      axios.put(url, data, config)
        .then(response => {
          this.loading = false
          this.modals.add_gift_card = false
          this.gift_card = {
            custom_code: 0,
            code: '',
            personal_message: '',
            design_url: '',
            custom_amount: '',
            recipient_first_name: '',
            recipient_last_name: '',
            recipient_email: '',
            total: {amount: 0},
            quantity: 1,
            send_instantly: 1,
            delivery_date: new Date()
          }
          this.send_gift_card_to_self = false
          swal({
            title: 'Success!',
            text: "Gift card has been added to the transaction",
            type: 'success'
          })

          this.getTransaction()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    validateBookingForm(){
      let errors = 0

      if(this.booking_date === null || this.booking_date === ''){
        this.errs.booking_date = 'Booking date cannot be empty'
        errors++
      } else {
        this.errs.booking_date = ''
      }

      if(this.selectedTimeSlot === ''){
        this.errs.time_slot = 'Time slot cannot be empty'
        errors++
      } else {
        this.errs.time_slot = ''
      }

      return errors > 0 ? false : true

    },
    async createBooking(){
      if(!this.validateBookingForm()){
        return
      }
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id

      //validate
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/transactions/' + this.transaction.id + '/bookings'
      let data = {
        booking_slot_id: this.selectedTimeSlot.id,
        number_of_players: this.selectedTimeSlot.number_of_players,
        price: this.selectedTimeSlot.subtotal,
        tickets: this.selectedTimeSlot.is_custom_pricing_enabled ? this.custom_pricing_categories : this.pricing_categories
      }

      this.loading = true
      axios.put(url, data, config)
        .then(response => {
          this.loading = false
          this.modals.add_booking = false
          this.selectedTimeSlot = ''
          this.selectedGame = ''
          swal({
            title: 'Success!',
            text: "Booking has been added to the transaction",
            type: 'success'
          })

          this.getTransaction()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async createCustomPurchase(){
      if(!this.validateCustomPurchase(this.purchase)){
        return
      }

      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id

      //validate
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/transactions/' + this.transaction.id + '/items'
      let data = this.purchase
      data.amount = this.getPurchaseAmount(this.purchase.price, this.purchase.quantity)

      this.loading = true
      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.modals.add_item = false
          this.modals.custom_purchase = false
          this.purchase = {
            name: '',
            description: '',
            amount: '',
            quantity: '',
            price: 0
          }
          swal({
            title: 'Success!',
            text: "Custom purchase has been added to the transaction",
            type: 'success'
          })

          this.getTransaction()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async createPurchase(){
      //validation
      if(!this.validatePurchase(this.purchase)){
        return
      }
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id

      //validate
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/transactions/' + this.transaction.id + '/items'
      let data = this.purchase
      data.amount = this.getPurchaseAmount(this.purchase.price, this.purchase.quantity)

      this.loading = true
      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.modals.add_item = false
          this.purchase = {
            name: '',
            category: '',
            amount: '',
            quantity: '',
            price: 0
          }
          swal({
            title: 'Success!',
            text: "Item has been added to the transaction",
            type: 'success'
          })

          this.getTransaction()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    getPurchaseAmount(price, quantity){
      return price * quantity
    },
    async applyTax(){
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id

      //validate
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/transactions/' + this.transaction.id + '/taxes'
      let html = "Tax has been added to the transaction"
      let data = this.selectedTax
      if(this.tax_fee_type.id === 0){
        if(this.selectedTax == ''){
          this.errs.tax = 'You must select a tax'
          return
        } else {
          this.errs.tax = ''
        }
      } else if(this.tax_fee_type.id === 1) {
        url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
          '/transactions/' + this.transaction.id + '/fees'
        html = "Fee has been added to the transaction"
        data = this.selectedFee
        if(this.selectedFee == ''){
          this.errs.fee = 'You must select a fee'
          return
        } else {
          this.errs.fee = ''
        }
      } else {
        url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
          '/transactions/' + this.transaction.id + '/tips'
        html = "Tip has been added to the transaction"
        data =  {
          amount: this.tip_amount,
          company_user_id: this.company_user.id
        }
        if(this.tip_amount <= 0){
          this.errs.tip_amount = 'Invalid tip amount'
          return
        } else {
          this.errs.tip_amount = ''
        }
      }
      this.loading = true
      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.add_fee = false
          this.tax_fee_type.id = 0
          swal({
            title: 'Success!',
            text: html,
            type: 'success'
          })

          this.getTransaction()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    getFeeAmount(){

      let f = this.selectedFee
      let total_fee = 0
      let non_gc_cart_total = 0
      let number_of_players = 0
      let booking_in_cart = 0
      let gc_in_cart = false
      for(let n in this.bookings){
        if(this.bookings[n].hasOwnProperty('group_size') && this.bookings[n].status !== 'cancelled'){
          number_of_players += this.bookings[n].group_size
          booking_in_cart++
        } else if (this.bookings[n].hasOwnProperty('item_id')){
          booking_in_cart++
        }

        if(!this.bookings[n].hasOwnProperty('code')){
          non_gc_cart_total += this.bookings[n].price
        } else {
          gc_in_cart = true
        }
      }
      console.log(f)
      //value(total)
      if(f.value_type === 0 && ((gc_in_cart && f.apply_fee_to_gift_cards) || booking_in_cart > 0)){
        total_fee = f.amount
      }
      //value (per person)
      if(f.value_type === 1){
        total_fee = (f.amount * number_of_players)
      }
      //percentage
      if(f.value_type === 2){
        if(f.apply_fee_to_gift_cards){
          total_fee = this.getPercentage(f.amount, this.transaction.price)
        } else {
          total_fee = this.getPercentage(f.amount, non_gc_cart_total)
        }
      }
      total_fee = parseFloat(total_fee).toFixed(2)
      return total_fee
    },
    getTaxAmount(){
      let t = this.selectedTax

      if(parseFloat(this.transaction.price) == 0){
        return 0
      }
      let total_tax = 0

      if(t.pricing_type === 0){
        //value (total)
        if(t.value_type === 0){
          total_tax = t.amount
        }
        //value (per person)
        if(t.value_type === 1){
          let number_of_players = 0
          for(let n in this.bookings){
            if(this.bookings[n].hasOwnProperty('group_size') && this.bookings[n].status !== 'cancelled') {
              number_of_players += this.bookings[n].group_size
            }
          }
          total_tax = (t.amount * number_of_players)
        }
        //percentage
        if(t.value_type === 2){
          //not compound
          if(!t.is_compound){
            total_tax = this.getPercentage(t.amount, this.transaction.price)
          } else {
            total_tax = this.getPercentage(t.amount, parseFloat(this.transaction.total) - parseFloat(this.transaction.fee) + - parseFloat(this.transaction.discount))
          }
        }
      }
      total_tax = this.roundToFixed(total_tax, 2)
      return total_tax
    },
    getPercentage(amount, subtotal){
      if(subtotal && subtotal.toString().includes(',')){
        subtotal = subtotal.replace(',', '.')
      }
      if(amount && amount.toString().includes(',')){
        amount = amount.replace(',', '.')
      }
      return parseFloat(subtotal) * (parseFloat(amount)*0.01)
    },
    deleteDiscountPrompt(item){
      let html = 'The discount will be removed from the transaction'

      swal({
        title: 'Are you sure?',
        html: html,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm'
      }).then((response)=> {
        if(response.value == true){
          this.deleteDiscount(item);
        }
      })
    },
    async deleteDiscount(item){
      if(item.transaction_promo_link_id === undefined){
        item.transaction_promo_link_id = ''
      }
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/transactions/' + this.transaction.id + '/discounts?promo_id=' + item.transaction_promo_link_id
      axios.delete(url, config)
        .then(response => {
          this.loading = false
          swal({
            title: 'Success!',
            text: "Discount has been removed from the transaction.",
            type: 'success'
          })
          this.getTransaction()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    deleteTaxFeePrompt(item){
      let html = '<small>The fee will be removed from the transaction'
      if(item.hasOwnProperty('tax_id')){
        html = '<small>The tax will be removed from the transaction'
      }
      html += '.</small>'

      swal({
        title: 'Are you sure?',
        html: html,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm'
      }).then((response)=> {
        if(response.value == true && item.hasOwnProperty('tax_id')){
          this.deleteTax(item);
        } else if(response.value == true && item.hasOwnProperty('fee_id')){
          this.deleteFee(item)
        } else if (response.value == true){
          this.deleteTip(item)
        }
      })
    },
    async deleteTip(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/transactions/' + this.transaction.id + '/tips?id=' + item.id
      axios.delete(url, config)
        .then(response => {
          this.loading = false
          swal({
            title: 'Success!',
            text: "Tip has been removed from the transaction.",
            type: 'success'
          })
          this.getTransaction()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async deleteTax(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/transactions/' + this.transaction.id + '/taxes?id=' + item.id
      axios.delete(url, config)
        .then(response => {
          this.loading = false
          swal({
            title: 'Success!',
            text: "Tax has been removed from the transaction.",
            type: 'success'
          })
          this.getTransaction()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async deleteFee(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/transactions/' + this.transaction.id + '/fees?id=' + item.id
      axios.delete(url, config)
        .then(response => {
          this.loading = false
          swal({
            title: 'Success!',
            text: "Fee has been removed from the transaction.",
            type: 'success'
          })
          this.getTransaction()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async giftCardSearch(queryString, cb){
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/gifts/autocomplete?q=' + encodeURIComponent(queryString)
      axios.get(url, config)
        .then(response => {
          let suggestions = []
          for(let n in response.data.gift_cards){
            if(response.data.gift_cards[n].code != null){
              suggestions.push({
                value: JSON.stringify(response.data.gift_cards[n]),
                id: response.data.gift_cards[n].id,
                code: response.data.gift_cards[n].code,
                customer_id: response.data.gift_cards[n].customer_id,
                date_purchased: response.data.gift_cards[n].date_purchased,
                email: response.data.gift_cards[n].email,
                first_name: response.data.gift_cards[n].first_name,
                last_name: response.data.gift_cards[n].last_name,
                remaining: response.data.gift_cards[n].remaining,
                total: response.data.gift_cards[n].total
              })
            }

          }
          const results = suggestions

          cb(results)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    handleGiftCardSelect(item){
      this.gift_card_code = this.getGiftCardCode(item.code)
      this.selectedGiftCardCode = item
    },
    async promoSearch(queryString, cb){
      let today = new Date()
      let purchase_date = today.getFullYear() +  "-" +  ('0' + (today.getMonth() + 1)).slice(-2) + "-" + ('0' + today.getDate()).slice(-2);

      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/promos?autocomplete=' + encodeURIComponent(queryString) + '&purchase_date=' + purchase_date
      axios.get(url, config)
        .then(response => {
          let suggestions = []
          for(let n in response.data.promos){
            if(response.data.promos[n].name != null){
              suggestions.push({
                value: JSON.stringify(response.data.promos[n]),
                id: response.data.promos[n].id,
                name: response.data.promos[n].name,
                description: response.data.promos[n].description,
                value_amount: response.data.promos[n].value_amount,
                redemption_type: response.data.promos[n].redemption_type,
                value_type: response.data.promos[n].value_type,
                games: JSON.parse(response.data.promos[n].games),
                event_exlusions: response.data.promos[n].event_exlusions,
                internal_only: response.data.promos[n].internal_only,
                event_is_date_range: response.data.promos[n].event_is_date_range,
                event_is_time_range: response.data.promos[n].event_is_time_range,
                event_start_date: response.data.promos[n].event_start_date,
                event_end_date: response.data.promos[n].event_end_date,
                event_start_time: response.data.promos[n].event_start_time,
                event_end_time: response.data.promos[n].event_end_time,
                available_days: [response.data.promos[n].available_sunday,
                  response.data.promos[n].available_monday,
                  response.data.promos[n].available_tuesday,
                  response.data.promos[n].available_wednesday,
                  response.data.promos[n].available_thursday,
                  response.data.promos[n].available_friday,
                  response.data.promos[n].available_saturday]
              })
            }

          }
          const results = queryString
            ? suggestions.filter(this.createFilter(queryString))
            : suggestions;

          cb(results)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    handlePromoSelect(item){
      this.promo_code = item.name
      this.selectedPromoCode = item
    },
    async applyPromoCode(){
      if(this.selectedPromoCode === ''){
        this.errs.promo_code = 'Please add a valid promo code'
        return
      } else {
        this.errs.promo_code = ''
      }

      let id = this.selectedPromoCode.id
      if(id){
        let non_unique = _.find(this.promos, function(o) { return o.id === id })
        if(non_unique){
          swal({
            title: 'Error!',
            text: "Promo code has already been applied",
            type: 'error'
          })
          return
        }
      }

      let today = new Date()
      today = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/transactions/' + this.transaction.id + '/discounts?date=' + today
      let data = {
        promo_code: this.selectedPromoCode
      }

      this.loading = true
      axios.post(url, data, config)
        .then(response => {
          swal({
            title: 'Success!',
            text: "Promo code has been applied",
            type: 'success'
          })
          this.promo_code = ''
          this.selectedPromoCode = ''
          this.add_promo = false
          this.getTransaction()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.promo_code = ''
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal({
              title: 'Error!',
              text: err.response.data,
              type: 'error'
            })
          }
        })
    },
    addCustomPurchase(event){
      event.preventDefault();
      this.modals.custom_purchase = true
      this.errs.purchase.name = ''
      this.errs.purchase.price = ''
      this.purchase = {
        name: '',
        category: '',
        description: '',
        amount: '',
        quantity: '',
        price: ''
      }
    },
    addPurchase(event){
      event.preventDefault();
      this.modals.add_item = true
      this.errs.purchase.name = false
      this.errs.purchase.price = false
      this.purchase = {
            name: '',
            category: '',
            available: '',
            amount: '',
            quantity: '',
            price: ''
        }
      this.selectedItem = ''
    },
    addFee(){
      this.tip_amount = 0
      this.company_user_value = ''
      this.add_fee = true
    },
    addPromoCode(){
      this.add_promo = true
    },
    async updateCustomer(){
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/transactions/' + this.transaction.id
      let data = {
        first_name: this.customer.first_name,
        last_name: this.customer.last_name,
        phone: this.customer.phone,
        email: this.customer.email
      }
      this.loading = true
      axios.put(url, data, config)
        .then(response => {
          swal({
            title: 'Success',
            text: "Customer details have been updated",
            type: 'success',
            confirmButtonColor: '#1d8cf8',
            confirmButtonText: 'OK'
          })
          this.getTransaction()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })

    },
    clearCustomer(){
      this.lookup_value = ''
    },
    async querySearchItem(queryString, cb){
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/inventory?count=10000000&autocomplete=' + encodeURIComponent(queryString)
      axios.get(url, config)
        .then(response => {
          let suggestions = []
          for(let n in response.data.items){
            suggestions.push({
              value: JSON.stringify(response.data.items[n]),
              name: response.data.items[n].name,
              id: response.data.items[n].id,
              price: response.data.items[n].price,
              available: response.data.items[n].available
            })
          }
          const results = suggestions;
          cb(results)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async querySearchCu(queryString, cb) {
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/company-users?count=10000000&autocomplete=' + encodeURIComponent(queryString)
      axios.get(url, config)
        .then(response => {
          let suggestions = []
          for(let n in response.data.company_users){
            suggestions.push({
              value: JSON.stringify(response.data.company_users[n]),
              email: response.data.company_users[n].email,
              id: response.data.company_users[n].id,
              first_name: response.data.company_users[n].first_name,
              last_name: response.data.company_users[n].last_name
            })
          }
          const results = suggestions;
          cb(results)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async querySearch(queryString, cb) {
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/customers?count=10000000&offset=0&first_name=&last_name=&email=&phone=&game=&tag=&autocomplete=' + encodeURIComponent(queryString)
      axios.get(url, config)
        .then(response => {
          let suggestions = []
          for(let n in response.data.customers){
            if(response.data.customers[n].email != null
              && response.data.customers[n].first_name != null
              && response.data.customers[n].first_name != ''){
              suggestions.push({
                value: JSON.stringify(response.data.customers[n]),
                email: response.data.customers[n].email,
                id: response.data.customers[n].id,
                first_name: response.data.customers[n].first_name,
                last_name: response.data.customers[n].last_name,
                phone: response.data.customers[n].phone
              })
            }

          }
          const results = suggestions;
          cb(results)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    createFilter(queryString) {
      return (suggestion) => {
        return suggestion.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1;
      };
    },
    handleSelectItem(item){
      this.selectedItem = item.name
      this.purchase.name = item.name
      this.purchase.price = item.price
      this.purchase.available = item.available
      this.purchase.id = item.id
    },
    handleSelectCu(item){
      this.company_user_value = item.email
      this.company_user.first_name = item.first_name
      this.company_user.last_name = item.last_name
      this.company_user.email = item.email
      this.company_user.id = item.id
    },
    handleSelect(item){
      this.lookup_value = item.email
      this.customer.first_name = item.first_name
      this.customer.last_name = item.last_name
      this.customer.email = item.email
      this.customer.phone = item.phone
    },
    async addPaymentModal(event){
      event.preventDefault()
      if (this.transaction.due <= 0){
        return
      }

      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/payment-methods'

      axios.get(url, config)
        .then((response) => {
          this.loading = false
          this.paymentOptions = response.data.payment_methods
          this.selectedPaymentOption = this.paymentOptions.length > 0 ?
            this.paymentOptions[0] : {name: '', content: '', id: '', is_default: ''}
          this.modals.add_payment = true
          this.payment_amount = parseFloat(this.transaction.due).toFixed(2)
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })

      if(this.payment_settings.payment_gateway_id === 2){
        await this.initializeSquare()
      }
      if(this.payment_settings.payment_gateway_id === 3){
        await this.initializeStripe()
      }
    },
    async loadPaystack(){
      const script = document.createElement('script');
      script.src = 'https://js.paystack.co/v2/inline.js'
      script.async = true;
      document.body.appendChild(script);
    },
    async loadStripe(){
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/';
      script.async = true;
      document.body.appendChild(script);
    },
    async initializeStripe(){
      this.stripe = window.Stripe(this.payment_settings.api_name);
      this.elements = this.stripe.elements();

      const style = {
        base: {
          fontSize: '16px',
          fontFamily: 'Poppins, sans-serif',
          color: localStorage.getItem('mode') === '0' ? '#344675' : '#ffffff'
        },
      };

      // Create an instance of the card Element.
      this.card = this.elements.create('card', {style});
      // Add an instance of the card Element into the `card-element` <div>.
      this.card.mount('#card-element');
    },
    async loadSquare(is_live){
      const script = document.createElement('script');
      if(is_live){
        script.src = 'https://web.squarecdn.com/v1/square.js'
      } else {
        script.src = 'https://sandbox.web.squarecdn.com/v1/square.js'
      }
      script.async = true;
      document.body.appendChild(script);
    },
    async initializeSquare(){
      if (!window.Square) {
        throw new Error('Square.js failed to load properly');
      }
      if(this.card){
        await this.card.destroy()
      }
      this.square = window.Square.payments(this.squareAppId, this.squareLocationId);
      try {
        this.card = await this.initializeCard(this.square);

      } catch (e) {
        console.error('Initializing Card failed', e);
        return;
      }
    },
    async initializeCard(payments) {
      const customStyle = {
        '.input-container.is-focus': {
          borderColor: '#e14eca',
        },
        '.input-container.is-error': {
          borderColor: '#ff1600',
        },
        '.message-text.is-error': {
          color: '#ff1600',
        },
        '.message-icon.is-error': {
          color: '#ff1600',
        },
        'input.is-error': {
          color: '#ff1600',
        },
        '.message-icon': {
          color: '#adb5bd',
        },
        '@media screen and (max-width: 600px)': {
          'input': {
            'fontSize': '12px',
          }
        },
        '.input-container': {
          borderRadius: '6px',
        },
        '.message-text': {
          color: '#adb5bd',
        },
        input: {
          backgroundColor: localStorage.getItem('mode') === '0' ? '#ffffff' : '#27293d',
          color: localStorage.getItem('mode') === '0' ? '#344675' : '#ffffff',
        },
        'input::placeholder': {
          color: '#adb5bd',
        },
      }
      const card = await payments.card({
        style: customStyle,
      });
      await card.attach('#card-container');
      return card;
    },
    async verifyBuyer(payments, token) {
      const verificationDetails = {
        amount: this.payment_amount.toString(),
        /* collected from the buyer */
        billingContact: {
          familyName: this.user.first_name,
          givenName: this.user.last_name,
        },
        currencyCode: this.currency,
        intent: 'CHARGE',
      };

      const verificationResults = await payments.verifyBuyer(
        token,
        verificationDetails
      );
      return verificationResults.token;
    },
    cancelBookingPrompt(item, event){
      event.preventDefault()
      this.modals.cancel_booking = true
      this.selectedBookingSlot = item
      this.selectedCancellationStatus = {id: 1, label: 'Available', status: 'available'}
    },
    async cancelBooking(item, new_status){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/'
        + companyGroupId + '/bookings/' + item.id + '?new_status=' + new_status

      this.axios.delete(url, config)
        .then(response => {
          this.loading = false
          swal({
            title: 'Success',
            text: "Booking has been cancelled",
            type: 'success',
            confirmButtonColor: '#1d8cf8',
            confirmButtonText: 'OK'
          })
          this.modals.cancel_booking = true
          this.getTransaction()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } if(err.response.status == 500){
            swal('Error!', err.response.data, 'error')
          }
          else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    getGameObj(game){
      if(this.games != ''){
        let selectedGame = this.games.filter(obj => {
          return obj.name === game
        })
        return selectedGame
      }
    },
    getInitialSubtotal(item, source){

      item.number_of_players = 0
      item.pricing_categories = []
      item.booking_date = null
      item.start_time = null
      if(source === 'new'){
        this.booking_date = null
        this.selectedTimeSlot = ''
      } else {
        this.getSubtotal(item)
      }

    },
    selectTimeSlot(item){
      let pricing = []
      if(item.is_custom_pricing_enabled){
        this.pricing_type_id = item.pricing_type_id
        pricing = item.custom_pricing
        this.number_of_players = 0
        this.custom_pricing_categories = []
        let categories = this.getCategories(pricing)
        for(let n in pricing){
          if(!(_.find(this.custom_pricing_categories, { category_id: pricing[n].category_id }))){
            this.custom_pricing_categories.push({
              category_id: pricing[n].category_id,
              category: pricing[n].category.toLowerCase(),
              number_of_players: 1,
              price: pricing[n].price,
              category_pricing: _.find(categories, {category_id: pricing[n].category_id}).pricing
            })
            this.number_of_players++
          }
        }
      } else {
        this.pricing_type_id = item.slot_pricing_type_id
        pricing = item.pricing
        this.number_of_players = 0
        this.pricing_categories = []
        let categories = this.getCategories(pricing)
        for(let n in pricing){
          if(!(_.find(this.pricing_categories, { category_id: pricing[n].category_id }))){
            this.pricing_categories.push({
              category_id: pricing[n].category_id,
              category: pricing[n].category.toLowerCase(),
              number_of_players: 1,
              price: pricing[n].price,
              category_pricing: _.find(categories, {category_id: pricing[n].category_id}).pricing
            })
            this.number_of_players++
          }
        }
      }
      this.getSubtotal(item, 'new')
    },
    getNewSubtotalForBookingSlot(new_time_slot, original_item){
      let pricing = []
      if(new_time_slot.is_custom_pricing_enabled){
        pricing = new_time_slot.custom_pricing
        this.number_of_players = 0
        this.custom_pricing_categories = []
        let categories = this.getCategories(pricing)
        for(let n in pricing){

          if(!(_.find(this.custom_pricing_categories, { category_id: pricing[n].category_id }))){
            this.custom_pricing_categories.push({
              category_id: pricing[n].category_id,
              category: pricing[n].category.toLowerCase(),
              number_of_players: 0,
              price: pricing[n].price,
              category_pricing: _.find(categories, {category_id: pricing[n].category_id}).pricing
            })
            this.number_of_players++
          }
        }
        original_item.pricing_categories = this.custom_pricing_categories
      } else {
        pricing = new_time_slot.pricing
        this.number_of_players = 0
        this.pricing_categories = []
        for(let n in pricing){
          let categories = this.getCategories(pricing)
          if(!(_.find(this.pricing_categories, { category_id: pricing[n].category_id }))){
            this.pricing_categories.push({
              category_id: pricing[n].category_id,
              category: pricing[n].category.toLowerCase(),
              number_of_players:  0,
              price: pricing[n].price,
              category_pricing: _.find(categories, {category_id: pricing[n].category_id}).pricing
            })
            this.number_of_players++
          }
        }
        original_item.pricing_categories = this.pricing_categories
      }
      original_item.game_pricing = JSON.stringify(pricing)
      original_item.custom_pricing = JSON.stringify(pricing)
      original_item.pricing_type_id = new_time_slot.pricing_type_id
      original_item.cumulative_category_pricing = new_time_slot.cumulative_category_pricing
      original_item.is_custom_pricing_enabled = new_time_slot.is_custom_pricing_enabled
      original_item.slot_pricing_type_id = new_time_slot.slot_pricing_type_id
      original_item.slot_cumulative_category_pricing = new_time_slot.slot_cumulative_category_pricing

      this.pr_key++
      this.getSubtotal(original_item)
    },
    async getAvailableBookings(item, source){
      if(source === 'date'){
        item.start_time = null
      }

      let url = ''
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      if(item.hasOwnProperty('selectedGame')){
        url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/bookings/available?t='
          + item.booking_date + '&game_id='
          + this.getGameObj(item.selectedGame)[0].id
          + '&booking_id=' + item.id
      } else {
        let dt = new Date(this.booking_date)
        let date = dt.getFullYear() + '-' +  ('0' + (dt.getMonth()+1)).slice(-2) + '-' + ('0' + dt.getDate()).slice(-2)

        url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/bookings/available?t='
          + date + '&game_id='
          + this.selectedGame.id
      }


      this.loading = true
      this.axios.get(url, config)
        .then(response => {
          this.available_bookings = response.data.bookings
          for(let n in this.available_bookings){
            this.available_bookings[n].pricing = JSON.parse(this.available_bookings[n].pricing)
            this.available_bookings[n].custom_pricing = JSON.parse(this.available_bookings[n].custom_pricing)
            this.available_bookings[n].pricing_categories = []
          }

          if(item.booking_date !== null){
            this.errs.booking_date = ''
          }
          if(item.start_time !== null){
            this.errs.start_time = ''
          }
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    switchTab(index){
      this.activeTabIndex = index
      for(let n in this.categories){
        this.categories[n].active = false
      }
      this.categories[index].active = true
      this.j++
    },
    switchTabNotes(index){
      this.activeTabNotesIndex = index
      for(let n in this.noteCategories){
        this.noteCategories[n].active = false
      }
      this.noteCategories[index].active = true
      this.b++
    },
    async getGames (item) {
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games'
      this.axios.get(url, config)
        .then(response => {
          this.games = response.data
          if(item){
            item.selectedGame = item.name
            this.getAvailableBookings(item)
          } else {
            this.selectedGame = this.games[0]
          }

          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    cancelEdit(item, index) {
      if(item.hasOwnProperty('start_time')){
        item.game_pricing = item.placeholder_game_pricing
        item.subtotal = item.placeholder_subtotal
        item.pricing_categories = JSON.parse(item.placeholder_pricing_categories)
        item.pricing_type_id = item.placeholder_pricing_type_id
        item.group_size = item.placeholder_group_size
        item.booking_date = item.placeholder_booking_date
        item.end_date = item.placeholder_end_date
        item.start_time = item.placeholder_start_time
        item.group_size = item.placeholder_group_size
      }
      this.$set(this.editing_bookings, index, false);
    },
    saveItemPrompt(item, index){
      if(!this.validatePurchase(item)){
        return
      }
      swal({
        title: "Are you sure?",
        html: "This change will affect the transaction",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm'
      }).then((response)=> {
        if(response.value == true){
          this.updateItem(item, index);
        }
      })
    },
    async updateItem(item, index){

      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id

      //validate
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/transactions/' + this.transaction.id + '/items'
      let data = item
      data.amount = this.getPurchaseAmount(item.price, item.quantity)

      if(item.category.name === 'Custom'){
        data.custom_category = this.custom_category
      }

      this.loading = true
      axios.put(url, data, config)
        .then(response => {
          this.loading = false
          this.$set(this.editing_bookings, index, false);

          swal({
            title: 'Success!',
            text: "Item has been updated",
            type: 'success'
          })

          this.getTransaction()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })

    },
    saveEditPrompt(item, index){
      if(!this.isBookingValid(item)){
        return
      }
      let html = '<small>Are you sure you want to make changes to this booking?</small>'

      if(this.email_settings[1].is_active){
        html =  '<small>Completing this action will make changes to this booking and send an email to ' + this.transaction.first_name + ' ' + this.transaction.last_name +
          '.</small>'
      }

      let title = 'Update booking'

      swal({
        title: title,
        html: html,
        type: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#1d8cf8',
        confirmButtonColor: '#fd5d93',
        confirmButtonText: 'Confirm'
      }).then((response)=> {
        if(response.value == true){
          this.saveEdit(item, index);
        }
      })
    },
    isBookingValid(item){
      let errors = 0
      if(item.booking_date === null || item.booking_date === '' || item.booking_date.length <=0){
        this.errs.booking_date = 'Invalid booking date'
        errors++
      } else {
        this.errs.booking_date = ''
      }
      if(item.start_time == null  || item.start_time === '' || item.start_time.length <=0){
        this.errs.start_time = 'Invalid time'
        errors++
      } else {
        this.errs.start_time = ''
      }

      if(item.number_of_players <= 0){
        this.errs.number_of_players = 'Please select a valid group size'
        errors++
      } else {
        this.errs.number_of_players = ''
      }

      if(errors > 0){
        return false
      }
      return true
    },
    getTransactionTotal(item){
      let total = 0
      for(let n in this.bookings){
        if(item.id === this.bookings[n].id){
          total += this.subtotal
        } else {
          total += parseFloat(this.bookings[n].price)
        }
      }
      return total
    },
    async saveEdit(item, index) {
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/bookings/' + item.id

      //group size
      let new_group_size = 0
      for(let n in item.pricing_categories){
        new_group_size += item.pricing_categories[n].number_of_players
      }

      let data = {
        price: item.new_price ? item.new_price : item.subtotal,
        transaction: {
          price: this.getTransactionTotal(item)
        },
        booking_date: item.booking_date,
        end_date: item.start_time.end_date ? item.start_time.end_date : item.end_date,
        start_time: item.start_time.start_time,
        end_time: item.start_time.end_time,
        game_id: this.getGameObj(item.selectedGame)[0].id,
        tickets: item.pricing_categories,
        group_size: new_group_size,
        booking_copy: item.start_time.id
      }

      this.loading = true
      this.axios.put(url, data, config)
        .then(response => {
          this.$set(this.editing_bookings, index, false);
          this.price_override = false
          swal({
            title: 'Success',
            text: "Booking has been updated",
            type: 'success',
            confirmButtonColor: '#1d8cf8',
            confirmButtonText: 'OK'
          })
          this.getTransaction()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else if(err.response.status == 500){
            swal('Error!', err.response.data, 'error')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    editPurchase(item, index, event){
      event.preventDefault()
      item.category = {id: item.category_id}
      this.is_custom_purchase = false
      this.$set(this.editing_bookings, index, true);
    },
    async getTickets(bookingId){
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/bookings/' + bookingId + '/tickets'

      return new Promise ( function(resolve) {
        axios.get(url, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(err => {
            console.log(err)
          })
      })

    },
    async editBooking(item, index, event){
      event.preventDefault()
      // item.start_time = this.getTime(item.start_time)  + '-' + this.getTime(item.end_time)
      this.date_format_cal = this.date_format
      this.date_format_cal =  this.date_format_cal.replaceAll('Y', 'y')
      this.date_format_cal = this.date_format_cal.replaceAll('D', 'd')
      await this.getGames(item)

      // this.pricing_type_id = item.pricing_type_id
      // let pricing = []
      // if(item.is_custom_pricing_enabled){
      //   if(item.slot_pricing_type_id === 1){
      //     item.booking_amount_label = 'Flat price (' + this.currency_symbol + ')'
      //   } else {
      //     item.booking_amount_label = 'Price per person (' + this.currency_symbol + ')'
      //   }
      //   item.price_override = false
      //
      //   pricing = JSON.parse(item.custom_pricing)
      //
      // } else {
      //   if(item.pricing_type_id === 1){
      //     item.booking_amount_label = 'Flat price (' + this.currency_symbol + ')'
      //   } else {
      //     item.booking_amount_label = 'Price per person (' + this.currency_symbol + ')'
      //   }
      //   item.price_override = false
      //
      //   pricing = JSON.parse(item.game_pricing)
      // }
      //
      //
      // item.number_of_players = 0
      // item.pricing_categories = []
      // let ticket = ''
      // let categories = this.getCategories(pricing)
      // for(let n in pricing){
      //   if(!(_.find(item.pricing_categories, { category_id: pricing[n].category_id }))){
      //     ticket = _.filter(JSON.parse(item.tickets), { category_id: pricing[n].category_id })
      //     let num = 0
      //     for(let m in ticket){
      //       num += ticket[m].number_of_players
      //     }
      //     item.pricing_categories.push({
      //       category_id: pricing[n].category_id,
      //       category: pricing[n].category.toLowerCase(),
      //       number_of_players: num,
      //       price: pricing[n].price,
      //       category_pricing: _.find(categories, {category_id: pricing[n].category_id}).pricing
      //     })
      //   }
      // }

      item.pricing_categories = await this.getTickets(item.id)

      for(let n in item.pricing_categories){
        item.pricing_categories[n].pricing = JSON.parse(item.pricing_categories[n].pricing)
        item.pricing_categories[n].category_pricing = item.pricing_categories[n].pricing
      }

      this.getSubtotal(item)
      item.placeholder_booking_date = item.booking_date
      item.placeholder_end_date = item.end_date
      item.placeholder_start_time = item.start_time
      item.placeholder_game_pricing = item.game_pricing
      item.placeholder_subtotal = item.subtotal
      item.placeholder_pricing_categories = JSON.stringify(item.pricing_categories)
      item.placeholder_pricing_type_id = item.pricing_type_id
      item.placeholder_group_size = item.group_size
      item.start_time = this.getTime(item.start_time)  + '-' + this.getTime(item.end_time)
      this.$set(this.editing_bookings, index, true);
    },
    async resendConfirmationEmail(item, target){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/transactions/' + this.transaction.id + '/confirmation'
      let data = {
        email: this.transaction.email,
        bookings: this.bookings
      }
      if(target === 'gift_card'){
        data.target = 'gift_card'
      }

      this.axios.post(url, data, config)
        .then(response => {
          let text = "Booking confirmation sent to customer"
          if(target === 'gift_card'){
            text = "Gift card purchase confirmation email sent to customer"
          }
          swal({
            title: 'Success',
            text: text,
            type: 'success',
            confirmButtonColor: '#1d8cf8',
            confirmButtonText: 'OK'
          })
          this.getTransaction()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    resendConfirmationEmailPrompt(item, event){

      event.preventDefault()
      if (!this.emailSettingActive('confirmation')){
        return
      }
      let html =  '<small>Completing this action will send a booking confirmation email to ' + item.first_name + ' ' + item.last_name +
        '.</small>'
      let title = 'Send booking confirmation'

      swal({
        title: title,
        html: html,
        type: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#1d8cf8',
        confirmButtonColor: '#fd5d93',
        confirmButtonText: 'Send'
      }).then((response)=> {
        if(response.value == true){
          this.resendConfirmationEmail(item);
        }
      })
    },
    showSendPaymentButton(){
      let payment_request_email = _.find(this.email_settings, {
        email_type: 'payment_request'
      });


      if (this.transaction.status === 1 && this.transaction.due > 0 && payment_request_email.is_active){
        return true
      }
      return false
    },
    async sendPaymentRequest(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/transactions/' + item.id + '/payments/request'
      let data = {
        email: this.transaction.email,
        bookings: this.bookings
      }

      this.axios.post(url, data, config)
        .then(response => {
          swal({
            title: 'Success',
            text: "Payment request sent to customer",
            type: 'success',
            confirmButtonColor: '#1d8cf8',
            confirmButtonText: 'OK'
          })
          this.getTransaction()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else if(err.response.status == 500){
            swal('Error!', err.response.data, 'error')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    showSurveyButton(){
      let payment_request_email = _.find(this.email_settings, {
        email_type: 'payment_request'
      });


      if (this.transaction.status === 1 && this.transaction.due > 0 && payment_request_email.is_active){
        return true
      }
      return false
    },
    sendSurveyEmailPrompt(item){
      if (!this.showSurveyButton()){
        return
      }
      let html =  '<small>Completing this action will send a payment request to ' + item.first_name + ' ' + item.last_name +
        '.</small>'
      let title = 'Send payment request'

      swal({
        title: title,
        html: html,
        type: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#1d8cf8',
        confirmButtonColor: '#fd5d93',
        confirmButtonText: 'Send'
      }).then((response)=> {
        if(response.value == true){
          this.sendPaymentRequest(item);
        }
      })
    },
    sendPaymentRequestPrompt(item){
      if (!this.showSendPaymentButton()){
        return
      }
      let html =  '<small>Completing this action will send a payment request to ' + item.first_name + ' ' + item.last_name +
        '.</small>'
      let title = 'Send payment request'

      swal({
        title: title,
        html: html,
        type: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#1d8cf8',
        confirmButtonColor: '#fd5d93',
        confirmButtonText: 'Send'
      }).then((response)=> {
        if(response.value == true){
          this.sendPaymentRequest(item);
        }
      })
    },
    cancelTransactionPrompt() {
      this.modals.cancel_transaction = true
      this.selectedCancellationStatus = {id: 1, label: 'Available', status: 'available'}
    },
    async cancelTransaction(new_status){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/'
        + companyGroupId + '/transactions/' + this.transaction.id + '?new_status=' + new_status

      this.axios.delete(url, config)
        .then(response => {
          swal({
            title: 'Success',
            text: "Transaction has been cancelled",
            type: 'success',
            confirmButtonColor: '#1d8cf8',
            confirmButtonText: 'OK'
          })
          this.modals.cancel_transaction = false
          this.getTransaction()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    toggleDisabledFlagForAmount(item){
      if(this.selectedPaymentAmount.id === 0){
        this.refundAmount = (item.amount.toFixed(2) - item.refunded.toFixed(2)).toFixed(2)
        this.isAmountFieldDisabled = true
      } else {
        this.isAmountFieldDisabled = false
      }
    },
    isRefundable(item){
      if((item.amount - item.refunded) > 0){
        return true
      }
      return false
    },
    markAsPaidPrompt(item){
      swal({
        title: 'Are you sure?',
        text: "This will mark the payment as paid",
        type: 'warning',
        showCancelButton: true
      }).then((response)=> {
        if(response.value == true){
          this.markAsPaid(item)
        }
      })
    },
    async markAsPaid(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/'
        + companyGroupId + '/transactions/' + this.transaction.id + '/payments/' + item.id

      let data = {}

      this.axios.put(url, data, config)
        .then(response => {
          swal('Success!', 'Payment has been marked as paid', 'success');
          this.getTransaction()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else if(err.response.status == 500){
            swal('Error!', err.response.data, 'error')
          }
        })
    },
    markAsRefundedPrompt(item, index){
      swal({
        title: 'Are you sure?',
        text: "This will mark the payment as refunded on the transaction without processing the credit card on file",
        type: 'warning',
        showCancelButton: true
      }).then((response)=> {
        if(response.value == true){
          this.markAsRefunded(item, index)
        }
      })
    },
    async markAsRefunded(item, index){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/'
        + companyGroupId + '/transactions/' + this.transaction.id + '/payments/' + item.id + '/refund?refund_only=1'

      let data = item
      data.refunded = item.amount

      this.axios.post(url, data, config)
        .then(response => {
          this.$set(this.showRefund, index, 0)
          swal('Success!', 'The refund has been processed.', 'success');
          this.getTransaction()
          this.showRefund = []
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          swal('Error!', 'There was an issue with the refund.', 'error');
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else if(err.response.status == 500){
            swal('Error!', err.response.data, 'error')
          }
        })
    },
    showRefundForPayment(item, index){
      this.selectedPaymentAmount = this.paymentAmountOptions[0]
      this.refundAmount = (item.amount.toFixed(2) - item.refunded.toFixed(2)).toFixed(2)
      this.$set(this.showRefund, index, 1)
    },
    validateRefundAmount(){
      if(isNaN(this.refundAmount) || this.refundAmount < 0){
        this.refundAmount = 0
      }
    },
    async processRefund(payment, index){
      //check that refund value doens't exceed the amount
      if(this.refundAmount > payment.amount){
        swal('Error!', 'The refund amount cannot exceed the paid amount.', 'error');
        return
      }
      payment.currency_format = this.currency_format
      payment.currency_symbol = this.currency_symbol
      payment.currency = this.currency
      payment.number_format = this.number_format

      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/transactions/' + this.transaction.id + '/payments/' + payment.id + '/refund'

      let data = payment
      //data.refunded = parseFloat(this.refundAmount).toFixed(2)
       data.refunded = parseFloat(this.refundAmount).toFixed(2).toString()
      if(payment.type === 'customer_credit' || this.selectedPaymentAmount.id === 2){
        data.customer_credit = true
        data.customer_id = this.transaction.end_user_id
      } else {
        data.customer_credit = false
      }
      this.axios.post(url, data, config)
        .then(response => {
          this.$set(this.showRefund, index, 0)
          swal('Success!', 'The refund has been processed.', 'success');
          this.getTransaction()
          this.showRefund = []
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          swal('Error!', 'There was an issue with the refund.', 'error');
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else if(err.response.status == 500){
            swal('Error!', err.response.data, 'error')
          }
        })
    },
    async deleteBookingNote(booking_id, id){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/bookings/' + booking_id + '/notes/' + id

      this.axios.delete(url, config)
        .then(response => {
          this.getTransaction()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async deleteNote(id){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/transactions/' + this.transaction.id + '/notes/' + id

      this.axios.delete(url, config)
        .then(response => {
          this.getTransaction()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async addNewComment(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/transactions/' + this.transaction.id + '/notes'
      let data = {
        note_type: this.note_type,
        note: this.note
      }
      this.axios.post(url, data, config)
        .then(response => {
          this.note = ''
          this.getTransaction()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    viewCustomerDetails(user_id){
      this.$router.push({ path: '/customers/details', query: { id:  user_id }})
    },
    async beforeDownload ({ html2pdf, options, pdfContent }) {
      this.loading = true
      await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(10)
          pdf.setTextColor(150)
          pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
        }
      })
        .save()
      this.loading = false
    },
    downloadWaiver(waiver){
      this.selectedWaiver = {
        game_name: waiver.game_name,
        created_time: waiver.created_time,
        waiver_text: waiver.waiver_text,
        booking_date: waiver.booking_date,
        start_time: waiver.start_time,
        first_name: waiver.first_name,
        last_name: waiver.last_name,
        email: waiver.email,
        signature: this.renderSignature(waiver.signature)
      }
      this.$refs.html2Pdf.generatePdf()

    },
    renderSignature(blob){
      if(blob == null){
        return ''
      }
      var base64String = btoa(String.fromCharCode.apply(null, new Uint8Array(blob.data)));
      let final = atob(base64String)
      return final
    },
    viewBookingDetails(id){
      localStorage.setItem('bookingSlotId', id)
      this.$router.push('/booking/details')
    },
    getInitials(item){
      if(item == null){
        return
      } else if(!item.company_user_id && !item.user_id) {
        return 'SU'
      } else if(item.company_user_first_name && item.company_user_last_name) {
        return item.company_user_first_name[0].toUpperCase() + item.company_user_last_name[0].toUpperCase()
      } else if(item.company_user_first_name && !item.company_user_last_name) {
        return item.company_user_first_name[0].toUpperCase()
      } else if(item.first_name){
        let ln = item.last_name ? item.last_name[0].toUpperCase() : ''
        return item.first_name[0].toUpperCase() + ln
      }
    },
    getLocalTime(d){
      if(!d){
        return
      }
      return localTime(this.time_format, d)
    },
    isValidTime(timeStr) {
      const pattern = /^(?:[01]\d|2[0-3]):[0-5]\d(?::[0-5]\d)?$/;
      return pattern.test(timeStr);
    },
    getTime(d) {
      if(!this.isValidTime(d)){
        return
      }
      return time(this.time_format, d)
    },
    getDayOfWeek(d){
      if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
        return dayOfWeek(d) + ', '
      }
      return ''
    },
    getLocalDate(d){
      let local = new Date(d)
      let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
      let final = this.getFormattedDate(form)
      return this.getDayOfWeek(form) + final
    },
    getFormattedDate(d){
      return formattedDate(this.date_format, d)
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    roundToFixed(num, decimals) {
      let factor = Math.pow(10, decimals);
      return (Math.round(num * factor) / factor).toFixed(decimals);
    },
    getAmount(integer){
      if(integer == null){
        return
      }
      if(this.number_format === 1 && this.currency_format === 0){
        return this.currency_symbol + integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',')
      } else if(this.number_format === 1 && this.currency_format === 1){
        return integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',') + ' ' + this.currency
      } else if (this.number_format === 1 && this.currency_format === 1){
        return parseFloat(integer).toFixed(2) + ' ' + this.currency
      }
      return this.currency_symbol + this.roundToFixed(integer, 2)
    },
    isEmailValid(emailString) {
      var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegex.test(emailString);
    },
    paymentRequest(url, data, config){
      this.loading = true
      this.axios.post(url, data, config)
        .then(response => {
          swal('Success', 'Payment has been processed' , 'success')
          this.modals.add_payment = false
          this.selectedPaymentOption = this.paymentOptions.length > 0 ?
            this.paymentOptions[0] : {name: '', content: '', id: '', is_default: ''}
          this.user.first_name = ''
          this.user.last_name = ''
          this.user.email = ''
          this.payment = {
            cardNumber: '',
            expirationDate: '',
            cardCode: '',
            zip: ''
          }
          this.gift_card_code = ''
          this.getTransaction()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else if(err.response.status == 500){
            swal('Error!', err.response.data, 'error')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();

      if (tokenResult.status === 'OK') {
        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(
            tokenResult.errors
          )}`;
        }

        throw new Error(errorMessage);
      }
    },
    async createSquarePayment(){
      this.loading = true
      try {
        const token = await this.tokenize(this.card);
        const verificationToken = await this.verifyBuyer(this.square, token);
        const token2 = await this.tokenize(this.card);
        let tok = await this.getAuthToken()
        let config = {headers: {Authorization: tok}}
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/'+ companyId + '/groups/' + companyGroupId + '/transactions/' + this.transaction.id + '/payments/square'
        let data = {
          amount: this.payment_amount,
          user: this.user,
          locationId: this.squareLocationId,
          sourceId: token,
          verificationToken: verificationToken,
          sourceId2: token2
        }
        let response = await this.paymentRequest(url, data, config)
      } catch (error) {
        //Square
        this.loading = false
      }
    },
    async handleGiftCardPayment(){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId +  '/groups/' + companyGroupId + '/transactions/' + this.transaction.id + '/payments'
      let data = {
        amount: this.payment_amount,
        gift_card: this.selectedGiftCardCode,
        payment_type: 'gift_card',
      }
      let response = await this.paymentRequest(url, data, config)
    },
    async handleCashPayment(payment_type, is_default){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId +  '/groups/' + companyGroupId + '/transactions/' + this.transaction.id + '/payments'
      let data = {
        amount: this.payment_amount,
        user: this.user,
        payment_type: payment_type,
        is_default: is_default
      }
      let response = await this.paymentRequest(url, data, config)
    },
    isValidPaymentForm(){
      let errors = 0
      if(this.selectedPaymentOption.name === 'Card' && this.user.first_name == ''){
        this.errs.first_name = 'First name cannot be empty'
        errors++
      } else {
        this.errs.first_name = ''
      }

      if(this.selectedPaymentOption.name === 'Card' && this.user.last_name == ''){
        this.errs.last_name = 'Last name cannot be empty'
        errors++
      } else {
        this.errs.last_name = ''
      }

      if(this.selectedPaymentOption.name === 'Card' &&  this.payment_settings.payment_gateway_id === 1 &&
        this.payment.cardNumber.toString().length < 15 ){
        this.errs.ccn = 'Invalid credit card number'
        errors++
      } else {
        this.errs.ccn = ''
      }

      let dt = new Date()
      const year = dt.getFullYear().toString().slice(-2);
      if(this.selectedPaymentOption.name === 'Card'
        &&  this.payment_settings.payment_gateway_id === 1
        && (this.payment.expirationDate === ''
        || (this.payment.expirationDate.split('/')[0] > 12  || this.payment.expirationDate.split('/')[0] <= 0)
        || this.payment.expirationDate.split('/')[1] < parseInt(year))) {
        this.errs.expiration = 'Invalid expiration date'
        errors++
      } else {
        this.errs.expiration = ''
      }

      // if(this.selectedPaymentOption === 'Card'
      //   &&  this.payment_settings.payment_gateway_id === 1) {
      //   this.errs.zip = 'Invalid zip'
      //   errors++
      // } else {
      //   this.errs.zip = ''
      // }

      if(this.selectedPaymentOption.name === 'Card'
        &&  this.payment_settings.payment_gateway_id === 1
        && (this.payment.cardCode.toString().length < 3 || this.payment.cardCode.toString().length > 4)) {
        this.errs.cvv = 'Invalid CVV'
        errors++
      } else {
        this.errs.cvv = ''
      }

      if(parseFloat(this.payment_amount) > parseFloat(this.transaction.due)){
        this.errs.payment_amount = 'Amount cannot exceed due'
        errors++
      } else if (this.selectedPaymentOption.name === 'Customer credit'
        && parseFloat(this.payment_amount) > parseFloat(this.available_credit)) {
        this.errs.payment_amount = 'Amount cannot exceed available customer credits'
        errors++
      } else if (isNaN(this.payment_amount) || this.payment_amount <= 0) {
        this.errs.payment_amount = 'Invalid payment amount'
        errors++
      }  else if (this.selectedPaymentOption.name === 'Gift card'
        && parseFloat(this.payment_amount) > parseFloat(this.selectedGiftCardCode.remaining)) {
        this.errs.payment_amount = 'Amount cannot exceed available gift card balance'
        errors++
      } else {
        this.errs.payment_amount = ''
      }

      if(this.selectedPaymentOption.name === 'Gift card' && (!this.gift_card_code
        || !this.selectedGiftCardCode.hasOwnProperty('id')
        || this.getGiftCardCode(this.selectedGiftCardCode.code) !== this.gift_card_code)){
        this.errs.gift_card_code = 'You must choose a valid gift card'
        errors++
      } else {
        this.errs.gift_card_code = ''
      }

      if(errors > 0){
        return false
      }
      return true

    },
    async payWithPaystack() {
      let handler = PaystackPop.setup({
        key: this.payment_settings.api_name, // Replace with your public key
        email: this.transaction.email,
        amount: (parseFloat(this.payment_amount) * 100).toFixed(0).toString(),
        ref: ''+Math.floor((Math.random() * 1000000000) + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
        callback: (response) => {
          this.handlePaystackPayment(response)
        }
      });

      handler.openIframe();
    },
    async handlePaystackPayment(reference){
      this.loading = true
      let tok = await this.getAuthToken()
      let config = {headers: {Authorization: tok}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/'+ companyId + '/groups/' + companyGroupId + '/transactions/' + this.transaction.id + '/payments'
      let data = data = {
        amount: this.payment_amount,
        user: this.user,
        paystack_reference: reference,
      }

      let response = await this.paymentRequest(url, data, config)
    },
    async handleStripePayment(){
      this.loading = true
      const {token, error} = await this.stripe.createToken(this.card);
      let tok = await this.getAuthToken()
      let config = {headers: {Authorization: tok}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/'+ companyId + '/groups/' + companyGroupId + '/transactions/' + this.transaction.id + '/payments/stripe'
      let data = data = {
        amount: this.payment_amount,
        user: this.user,
        token: token.id
      }

      let response = await this.paymentRequest(url, data, config)
    },
    async handleCustomerCreditPayment(){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId +  '/groups/' + companyGroupId + '/transactions/' + this.transaction.id + '/payments'
      let data = {
        amount: this.payment_amount,
        user: this.user,
        customer_credit: this.payment_amount
      }
      let response = await this.paymentRequest(url, data, config)
    },
    async handleAuthorizeNetPayment(){
      this.payment.cardNumber = this.payment.cardNumber.replace(/\s/g, '');
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/transactions/' + this.transaction.id + '/payments'
      let data = {
        amount: this.payment_amount,
        user: this.user,
        payment: this.payment,
      }

      let response = await this.paymentRequest(url, data, config)
    },
    async addPayment() {
      //validation
      if(!this.isValidPaymentForm()){
        return
      }
      if(this.payment_settings.payment_gateway_id === 3 && this.selectedPaymentOption.name === 'Card' ){
        let stripePayment = await this.handleStripePayment()
      } else if(this.payment_settings.payment_gateway_id === 2 && this.selectedPaymentOption.name === 'Card' ){
        let squarePayment = await this.createSquarePayment()
      }  else if(this.payment_settings.payment_gateway_id === 4 && this.selectedPaymentOption.name === 'Card' ){
        let paystack = await this.payWithPaystack()
        return
      } else if (!this.selectedPaymentOption.is_default || this.selectedPaymentOption.name === 'Yappy' ||
        this.selectedPaymentOption.name === 'Cash'
        || this.selectedPaymentOption.name === 'POS payment'){
        let payment_type = 'pos'
        if(this.selectedPaymentOption.name === 'Cash'){
          payment_type = 'cash'

        }
        if(!this.selectedPaymentOption.is_default){
          payment_type = this.selectedPaymentOption.name
        }
        if(this.selectedPaymentOption.name === 'Yappy'){
          payment_type = 'Yappy'
        }

        await this.handleCashPayment(payment_type, this.selectedPaymentOption.is_default)
      } else if(this.selectedPaymentOption.name === 'Customer credit'){
        let customerCreditPayment = await this.handleCustomerCreditPayment()
      } else if(this.selectedPaymentOption.name === 'Gift card'){
        let giftCardPayment = await this.handleGiftCardPayment()
      } else if(this.payment_settings.payment_gateway_id === 1 && this.selectedPaymentOption.name === 'Card' ){
        let authorizeNetPayment = await this.handleAuthorizeNetPayment()
        this.payment = {
                cardNumber: '',
                expirationDate: '',
                cardCode: '',
                zip: ''
              }
      }
    },
    getAvailableCredit(){
      let available = 0
      for(let n in this.customer_credits){
        available = available + parseFloat(this.customer_credits[n].remaining)
      }
      this.available_credit = available.toFixed(2)
      if(parseFloat(this.available_credit) < parseFloat(this.transaction.price)){
        this.applied_credit = this.available_credit
      } else {
        this.applied_credit = this.transaction.price
      }
    },
    async getTransaction(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let transactionId = this.$route.query.id
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +  '/transactions/' + transactionId
      this.axios.get(url, config)
        .then(response => {
          this.transaction = response.data.transaction
          this.activity = response.data.activity
          this.payments = response.data.payments
          this.waivers = response.data.waivers
          this.bookings = response.data.bookings.concat(response.data.items).concat(response.data.gift_cards)
          this.notes = response.data.notes
          this.promos = response.data.promos
          this.booking_notes = response.data.booking_notes
          this.taxes_fees = _.orderBy(response.data.taxes.concat(response.data.fees).concat(response.data.tips), 'timestamp', 'desc')
          this.taxes = response.data.taxes_for_group
          this.fees = response.data.fees_for_group
          this.email_settings = response.data.email_settings
          this.custom_fields = response.data.custom_fields
          this.email_events = response.data.email_events

          if(!this.email_settings[2].is_active || this.transaction.due <= 0){
            this.cursor_type.payment_request = 'not-allowed'
          } else {
            this.cursor_type.payment_request = 'pointer'
          }
          if(!this.email_settings[0].is_active){
            this.cursor_type.resend_confirmation_email = 'not-allowed'
          } else {
            this.cursor_type.resend_confirmation_email = 'pointer'
          }

          if(!this.emailSettingActive('gift_card_confirmation')){
            this.cursor_type.gift_card_confirmation_email = 'not-allowed'
          } else {
            this.cursor_type.gift_card_confirmation_email = 'pointer'
          }

          this.customer_credits = response.data.customer_credit
          this.payment_settings = response.data.payment_settings
          this.customer.first_name = response.data.transaction.first_name
          this.customer.last_name = response.data.transaction.last_name
          this.customer.phone = response.data.transaction.phone
          this.customer.email = response.data.transaction.email
          if(this.payment_settings.payment_gateway_id === 2){
            this.squareAppId = this.payment_settings.api_name
            this.squareLocationId = this.payment_settings.api_location
            this.loadSquare(this.payment_settings.is_live)
          }
          if(this.payment_settings.payment_gateway_id === 3){
            this.loadStripe()
          }
          if(this.payment_settings.payment_gateway_id === 4){
            this.loadPaystack()
          }
          this.getAvailableCredit()
          this.selectedTax = ''
          this.selectedFee = ''
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }else if(err.response.status == 500){
            swal({
              title: 'Ooops',
              text: 'Transaction does not exist',
              type: 'error',
              confirmButtonColor: '#fd5d93',
              confirmButtonText: 'Back to transactions'
            }).then((response)=> {
              if(response.value == true){
                this.$router.push('/purchases/transactions?count=10&page=1')
              }
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    setToday(){
      let current = new Date()
      this.current = current.getFullYear() + '-' + (current.getMonth()+1) + '-' + current.getDate()
      this.getBookings()
    },
  },
  mounted(){
    this.date_format_cal = this.date_format
    this.date_format_cal =  this.date_format_cal.replaceAll('Y', 'y')
    this.date_format_cal = this.date_format_cal.replaceAll('D', 'd')
    if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
      this.day_of_week = 'ddd, '
    }
    this.setToday()
    this.country = _.find(this.countries, { code: JSON.parse(localStorage.getItem('group')).country })
    this.currency_symbol =  _.find(this.currencies, { code: JSON.parse(localStorage.getItem('group')).currency }).symbol
    this.getTransaction()
  }
}
</script>

<style scoped>
.closed{
  position: absolute;
  pointer-events: all;
  z-index:10;
  right: 15px;
  top: 50%;
  margin-top: -13px;
  width: 25px;
  height: 25px;
  padding: 3px;
}
.closed:hover {
  color: #560606 !important;
}

.company-info p {
  margin: 0;
  text-align: right;
}

</style>
