<template>
  <div class="ml-5 mr-5 mt-5">
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto text-center">
        <h1 class="title">{{game}} Leaderboard</h1>
      </div>
    </div>
    <div v-if="full_screen === false" class="row">
      <div class="col">
        <base-button
          @click.native="fullscreen()"
          type="success"
          size="sm"
          simple
        >Fullscreen
        </base-button>
      </div>
    </div>
    <!--Medium and large screens-->
    <div class="row mt-3 d-none d-sm-block">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <card card-body-classes="table-full-width">
          <div class="mt-3" v-if="noDataAvailable === true">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">Leaderboard not available.</h4>
            </div>
          </div>
          <div v-if="noDataAvailable === false" class="row">
            <div  class="col-md-12 col-lg-12 col-sm-12 col-12">
              <b-table  striped hover :items="leaderboard" :fields="fields">
                <template v-slot:cell(rank)="row">
                  <i v-if="row.index == 0" class="tim-icons icon-trophy gold"></i>
                  <i v-if="row.index == 1" class="tim-icons icon-trophy silver"></i>
                  <i v-if="row.index == 2" class="tim-icons icon-trophy bronze"></i>

                  <span v-if="row.index > 2">{{row.index + 1}}</span>
                </template>
                <template v-slot:cell(completion_time)="row">
                  <span >{{getTime(row.item.completion_time)}} min</span>
                </template>
                <template v-slot:cell(number_of_players)="row">
                  <p>{{row.item.players_count}} </p>
                </template>
                <template v-slot:cell(number_of_hints)="row">
                  <p>{{row.item.number_of_clues}} </p>
                </template>
                <template v-slot:cell(actions)="row">
                  <div class="row">
                    <router-link tag="a" :to="'/games/history/instance?id=' + row.item.id">
                      View game details
                    </router-link>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </card>
      </div>
    </div>
    <!--Mobile-->
    <div class="row mt-3 d-block d-sm-none">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12" v-for="(person, index) in leaderboard" :key="index">
        <card class="card-stats">
          <div class="mt-3" v-if="noDataAvailable === true">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">Leaderboard not available.</h4>
            </div>
          </div>
          <div v-if="noDataAvailable === false" class="row">
            <div class="col-3">
              <div class="info-icon icon-primary text-center">
                  <span class="mt-5 text-white rank-text">{{index+1}}</span>
              </div>
            </div>
            <div class="col-9">
              <div class="numbers">
                <slot>
                  <p class="card-category">
                    <i v-if="index == 0" class="tim-icons icon-trophy gold mr-1"></i>
                    <i v-if="index == 1" class="tim-icons icon-trophy silver mr-1"></i>
                    <i v-if="index == 2" class="tim-icons icon-trophy bronze mr-1"></i>

                    {{person.team_name}}
                  </p>
                  <p>
                    <span v-if="fields.includes('completion_time')"><i class="tim-icons icon-time-alarm mr-1 text-warning"></i>{{getTime(person.completion_time)}} min &nbsp;</span>
                    <span v-if="fields.includes('number_of_hints')"><i class="tim-icons icon-puzzle-10 mr-1 text-warning"></i>{{person.number_of_clues}} hints &nbsp;</span>
                    <span v-if="fields.includes('number_of_players')"><i class="tim-icons el-icon-user mr-1 text-warning"></i>{{person.players_count}} players</span>
                  </p>

<!--                  <h3 v-if="fields.includes('number_of_hints')" class="card-title">{{person.number_of_clues}} hints</h3>-->
<!--                  <h3 v-if="fields.includes('number_of_players')" class="card-title">{{person.players_count}} players</h3>-->
                </slot>
              </div>
            </div>
          </div>
          <base-button
            @click.native="viewDetails(person)"
            type="primary"
            size="sm"
            simple style="width: 100%"
          >View game details
          </base-button>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
  import {Card} from '@/components'
  import {Tooltip} from 'element-ui'
  import { Auth } from 'aws-amplify'
  import { API_LOCATION } from '../../../api-config'
  import { BTable } from 'bootstrap-vue'
  import swal from "sweetalert2";
  const axios = require('axios')

  export default {
    name: "Leaderboard",
    components: {
      Card,
      BTable,
      Tooltip
    },
    data() {
      return {
        leaderboard: [],
        full_screen: false,
        game: '',
        noDataAvailable: false,
        settings: '',
        loading: false,
        fields: ['rank', 'team_name'],
      }
    },
    methods: {
      getAuthToken() {
        return new Promise ( function(resolve) {
          Auth.currentSession()
            .then(data =>{
              resolve(data.idToken.jwtToken)
            })
            .catch(err => console.log(err))
        })
      },
      getTime(time){
        if(time == undefined){
          return
        }
        let hours = time.split(':')[0]
        let minutes = time.split(':')[1]
        return parseInt(parseInt(hours)*60 + parseInt(minutes))
      },
      viewDetails(item){
        this.$router.push({ path: '/games/history/instance', query: { id: item.id }})
      },
      fullscreen(){
        this.$router.push('/dashboard/game/leaderboard/fullscreen')
      },
      async getLeaderboard () {
        this.loading = true
        var token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let url = API_LOCATION + 'companies/' + companyId + '/games/' + JSON.parse(localStorage.getItem('selectedGameStats')).id + '/leaderboard'
        axios.get(url, config)
          .then(response => {
            this.leaderboard = response.data.leaderboard

            if(this.leaderboard.length <= 0){
              this.noDataAvailable = true
            } else {
              if(this.leaderboard[0].hasOwnProperty('players_count')){
                this.fields.push('number_of_players')
              }
              if(this.leaderboard[0].hasOwnProperty('number_of_clues')){
                this.fields.push('number_of_hints')
              }
              if(this.leaderboard[0].hasOwnProperty('completion_time')){
                this.fields.push('completion_time')
              }
              this.fields.push('actions')
            }
            this.game = JSON.parse(localStorage.getItem('selectedGameStats')).name
            if(this.$route.path === '/dashboard/game/leaderboard/fullscreen'){
              console.log('full')
              this.full_screen = true
            }
            this.loading = false

          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              this.$router.push('/forbidden')
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
    },
    mounted () {
      this.getLeaderboard()
    }
  }
</script>

<style scoped>
  .gold {
    color: gold;
  }
  .silver {
    color: silver;
  }
  .bronze {
    color: darkorange;
  }
  .rank-text {
    font-size: xx-large;
  }
</style>
