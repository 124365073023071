import moment from "moment";

function getLocalDateTime(d, date_format, time_format){
  if(time_format === 'hh:mm'){
    time_format += ' A'
  }
  const format = date_format + ', ' + time_format
  return moment(d).format(format)
}

export {getLocalDateTime as localDateTime}
