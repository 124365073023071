<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>
  <div class="row">
    <div class="col">

      <card style="min-height: 150px;">
        <div class="row">
          <div class="col">
            <div
              class="btn-group btn-group-toggle"
              :class="'float-left'"
              data-toggle="buttons"
            >
              <label
                v-for="(option, index) in categories"
                :key="index + j"
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: option.active }"
                :id="index"
              >
                <input
                  type="radio"
                  @click="switchTab(index)"
                  name="options"
                  autocomplete="off"
                  :checked="option.active"
                />
                <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
              </label>
            </div>
          </div>
          <div class="col d-sm-none d-block text-right">
            <base-button :style="{'font-size': 'x-large', 'font-weight': 'bold'}" icon class="btn btn-link" type="primary" v-on:click="addAnotherBooking()">
              <i :style="{'font-size': 'x-large', 'font-weight': 'bold'}"  class="tim-icons el-icon-back"></i>
            </base-button>
          </div>
          <div class="col d-sm-block d-none  text-right">
            <base-button simple size="sm" type="primary" v-on:click="addAnotherBooking()"> + Add another booking</base-button>
          </div>
        </div>

<!--        Basket-->
        <div v-if="activeTabIndex === 0 && cart.length <= 0" class="mt-3" >
          <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            <h4 class="mt-3">No items in basket.</h4>
          </div>
        </div>

        <div v-if="activeTabIndex === 0 && cart.length > 0" class="row mt-3" >
          <div class="col d-sm-block d-none">
            <b-table :items="cart" :fields="cartFields">
              <template v-slot:cell(booking)="row">
                <div class="row">
                  <div class="col-2 mt-1">
                    <img :src="row.item.url"/>
                  </div>
                  <div class="col-5">
                    <div class="row">
                      <div class="col">
                        <span style="font-size: medium">{{row.item.game_name}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                      <span>
                        <i class="tim-icons icon-calendar-60 mr-1 text-primary"></i>
                        <span class="mr-2">{{getBookingDate(row.item.booking_date)}}</span>
                        <i class="tim-icons icon-time-alarm mr-1 text-primary"></i>
                        <span v-if="row.item.start_time.includes('M')">{{row.item.start_time}}</span>
                        <span v-else>{{getTime(row.item.start_time)}}</span>

                      </span>
                      </div>
                    </div>

                  </div>
                </div>
              </template>
              <template v-slot:cell(number_of_players)="row">
                <div class="row" v-for="(c, i) in row.item.tickets" :key="'C' + i" v-if="!price_override[row.index]">
                  <div class="col-lg-6">
                    <label class="text-primary text-uppercase">{{c.category}}</label>
                    <base-input>
                      <el-input-number @change="getBookingSubtotal(row.item)" v-model="c.number_of_players" :min="0" />
                    </base-input>

                  </div>
                </div>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(price)="row">
                <div v-if="!price_override[row.index]">
                  <h2 style="margin-bottom: 0px" class="warning"> {{getAmount(row.item.total)}}</h2>
                  <small>
                    <div class="text-primary" v-for="(c, i) in row.item.tickets" :key="'CC' + i">
                      <span v-if="row.item.pricing_type_id === 1">{{getAmount(getRate(c, row.item))}} per {{c.category}}</span>
                      <span v-if="row.item.pricing_type_id === 2">{{getRate(c, row.item)}}</span>
                    </div>
                  </small>

                  <base-button v-if="row.item.tickets.length > 0" class="mt-2"
                               size="sm" type="primary" v-on:click="overridePrice(row.item.tickets, row.index, row.item)">Override price</base-button>
                </div>
                <div v-else>
                  <div style="max-width: 70%" v-for="(c, index) in row.item.tickets" :key="'CCOVERRIDE' + index">
                    <label v-if="row.item.pricing_type_id === 1">Price per {{c.category}} ({{currency_symbol}})</label>
                    <label v-if="row.item.pricing_type_id === 2">Price for {{c.category}} ({{currency_symbol}})</label>
                    <div class="row">
                      <div class="col">
                        <base-input>
                          <el-input-number placeholder="0.00" v-model="c.price" :controls="false" :precision="2" :step="0.1" />
                        </base-input>
                      </div>
                      <div class="col">
                        <small class="text-white mr-2">Original price: {{getAmount(c.original_price)}}</small>
                        <el-tooltip class="d-inline"
                                    content="Set to original price"
                                    effect="light"
                                    :open-delay="300"
                                    placement="right"
                        >
                          <i style="cursor: pointer" class="ml-1 tim-icons icon-refresh-02 text-white" v-on:click="setOriginalPrice(c)"></i>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                  <base-button size="sm" type="primary" v-on:click="saveOverride(row.item.tickets, row.index, row.item)">Save</base-button>
                </div>
              </template>
              <template v-slot:cell(actions)="row">
                <div style="min-width: 100px">
                  <el-tooltip content="Delete booking"
                              effect="light"
                              :open-delay="300"
                              placement="top">
                    <base-button
                      @click="deleteBooking(row.index)"
                      simple
                      type="danger"
                      link
                    > <i class="tim-icons icon-trash-simple"></i>
                    </base-button>
                  </el-tooltip>
                </div>
              </template>

            </b-table>
          </div>
<!--          Mobile-->
          <div class="col d-sm-none d-block">
            <b-table :items="cart" :fields="cartFieldsMobile">
              <template v-slot:cell(cart)="row">
                <div class="row">
                  <div class="col-3 mt-1">
                    <img :src="row.item.url"/>
                  </div>
                  <div class="col-9">
                    <div class="row">
                      <div class="col">
                        <span style="font-size: medium">{{row.item.game_name}}</span>
                      </div>
                      <div class="col text-right">
                        <base-button
                                     @click="deleteBooking(row.index)"
                                     simple
                                     type="danger"
                                     link
                        > <i class="tim-icons icon-trash-simple"></i>
                        </base-button>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col">
                      <span>
                        <i class="tim-icons icon-calendar-60 mr-1 text-primary"></i>
                        <span class="mr-2">{{getBookingDate(row.item.booking_date)}}</span>
                        <i class="tim-icons icon-time-alarm mr-1 text-primary"></i>
                        <span v-if="row.item.start_time.includes('M')">{{row.item.start_time}}</span>
                        <span v-else>{{getTime(row.item.start_time)}}</span>
                      </span>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col">
                        <span v-if="!editing[row.index]">Booked for x{{row.item.group_size}} people</span>
                        <label v-if="editing[row.index]">Group size</label>
                        <base-input v-if="editing[row.index]" type="number" style="width: 50%" v-model="row.item.group_size"></base-input>
                        <small v-if="row.item.group_size <= 0" class="text-danger">Group size must be greater than 0</small>
                      </div>
                    </div>

                    <div class="row" v-for="(c, i) in row.item.tickets" :key="'C' + i" v-if="!price_override[row.index]">
                      <div class="col-lg-6">
                        <label class="text-primary text-uppercase">{{c.category}}</label>
                        <base-input v-if="price_override[row.index]">
                          <el-input-number @change="getBookingSubtotal(row.item)" v-model="c.number_of_players" :min="0" />
                        </base-input>

                      </div>
                    </div>
                    <span v-else>-</span>

                    <div class="row mt-2">
                      <div class="col">
                        <div v-if="!price_override[row.index]">
                          <h2 style="margin-bottom: 0px" class="warning">{{getAmount(row.item.total)}}</h2>
                          <small>
                            <div class="text-primary" v-for="(c, i) in row.item.tickets" :key="'CC' + i">
                              <span v-if="row.item.pricing_type_id === 1">{{getAmount(getRate(c, row.item))}} per {{c.category}}</span>
                              <span v-if="row.item.pricing_type_id === 2">{{getRate(c, row.item)}}</span>
                            </div>
                          </small>

                          <base-button v-if="row.item.tickets.length > 0" class="mt-2"
                                       size="sm" type="primary" v-on:click="overridePrice(row.item.tickets, row.index, row.item)">Override price</base-button>
                        </div>
                        <div v-else>
                          <div style="max-width: 90%" v-for="(c, index) in row.item.tickets" :key="'CCOVERRIDE' + index">
                            <label v-if="row.item.pricing_type_id === 1">Price per {{c.category}} ({{currency_symbol}})</label>
                            <label v-if="row.item.pricing_type_id === 2">Price for {{c.category}} ({{currency_symbol}})</label>
                            <div class="row">
                              <div class="col">
                                <base-input>
                                  <el-input-number placeholder="0.00" v-model="c.price" :controls="false" :precision="2" :step="0.1" />
                                </base-input>
                              </div>
                              <div class="col">
                                <small class="text-white mr-2">Original price: {{getAmount(c.original_price)}}</small>
                                <el-tooltip class="d-inline"
                                            content="Set to original price"
                                            effect="light"
                                            :open-delay="300"
                                            placement="right"
                                >
                                  <i style="cursor: pointer" class="ml-1 tim-icons icon-refresh-02 text-white" v-on:click="setOriginalPrice(c)"></i>
                                </el-tooltip>
                              </div>
                            </div>
                          </div>
                          <base-button size="sm" type="primary" v-on:click="saveOverride(row.item.tickets, row.index, row.item)">Save</base-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

            </b-table>
          </div>
        </div>

<!--        Customer-->
        <div v-if="activeTabIndex === 1" class="row mt-3" >
          <div class="col">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-12">
                <base-input label="Customer lookup (search by name, email, or phone number)">
                  <el-autocomplete style="width: 100%"
                    v-model="lookup_value"
                    :fetch-suggestions="querySearch"
                    placeholder="Search"
                    :trigger-on-focus="false"
                     @select="handleSelect"
                  >
                    <template #default="{ item }">
                      <p style="margin-bottom: -12px" class="text-primary">{{ item.first_name }} {{ item.last_name }}</p>
                      <small class="font-italic">
                        <i class="tim-icons icon-email-85"></i> {{item.email }} &nbsp;
                        <i v-if="item.phone" class="tim-icons el-icon-mobile-phone"></i> {{item.phone }}
                      </small>

                    </template>
                  </el-autocomplete>
                </base-input>
              </div>
              <div class="col">
                <base-button class="d-sm-none d-block" size="sm" simple type="primary" v-on:click="clearCustomer()">Clear</base-button>
                <base-input label=" ">
                  <base-button class="d-sm-block d-none" simple type="primary" v-on:click="clearCustomer()">Clear</base-button>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-12">
                <base-input :error="errs.first_name" label="First name" v-model="user.first_name" v-on:change="updateCustomer()" placeholder="First name"></base-input>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <base-input :error="errs.last_name" v-on:change="updateCustomer()" label="Last name" v-model="user.last_name" placeholder="Last name"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-12">
                <base-input :error="errs.email" label="Email" v-on:change="updateCustomer()" v-model="user.email" placeholder="Email"></base-input>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <base-input label="Phone (Optional)" v-on:change="updateCustomer()"
                            v-model="user.phone" :placeholder="country.phone_placeholder"></base-input>
              </div>
            </div>
          </div>

        </div>

  <!--        Payments-->
        <div v-if="activeTabIndex === 2" class="row mt-3" >
          <div class="col">
            <div class="row">
              <div class="col">
                <base-button v-if="add_payment == false" simple v-on:click="addPaymentNow()" size="sm" type="primary">Add payment</base-button>
                <base-button v-if="add_payment == true" simple v-on:click="payOnArrival()" size="sm" type="primary">Pay on arrival</base-button>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <base-checkbox v-model="no_confirmation_email">Do not send booking confirmation email</base-checkbox>
                <base-checkbox v-if="(add_payment == false) || (pay_partial_amount && partial_amount < total)" v-model="send_payment_request">Send payment request email to customer</base-checkbox>
                <base-checkbox v-on:input="setPartialAmount()" v-if="add_payment == true && selectedPaymentOption.name !== 'Customer credit'" v-model="pay_partial_amount">Pay partial amount</base-checkbox>

              </div>
            </div>
            <div class="row mt-2" v-if="add_payment && pay_partial_amount && selectedPaymentOption.name !== 'Customer credit'">
              <div class="col-lg-3">
                <base-input :error="errs.partial_amount" :key="pa_key" v-model="partial_amount" placeholder="0.00" :label="'Amount (' + currency_symbol + ')'"></base-input>
              </div>
            </div>

            <div class="row mt-2" v-if="add_payment">
              <div class="col-lg-4">
                <base-input label="Promo code">
                  <el-autocomplete style="width: 100%"
                                   v-model="promo_code"
                                   :fetch-suggestions="promoSearch"
                                   placeholder="Search by code..."
                                   :trigger-on-focus="false"
                                   @select="handlePromoSelect"
                  >
                    <template #default="{ item }">
                      <div class="row">
                        <div class="col">
                          <div ><span class="text-primary">{{ item.name }} - </span>
                            <small>
                              <span v-if="item.redemption_type === 0">{{item.value_amount}}%</span>
                              <span v-if="item.redemption_type === 1">{{getAmount(item.value_amount)}}</span>
                              <span v-if="item.value_type === 0"> per transaction</span>
                              <span v-if="item.value_type === 2"> per participant</span>
                              <span class="ml-2"
                                    style="text-transform: uppercase; line-height: 9px; text-decoration: none; border: none;
                            color: white; background-color: #344675; border-radius: 2px; padding: 2px 5px 2px 5px; font-size: 9px"
                                    v-if="item.internal_only">internal only</span>
                            </small>
                          </div>
                        </div>
                      </div>
                    </template>
                  </el-autocomplete>
                </base-input>
              </div>
              <div class="col mt-4">
                <base-button v-on:click="applyPromoCode()" style="padding: -25px" type="primary">Apply</base-button>
                <base-button v-on:click="promo_code = ''" simple style="padding: -25px" type="primary" class="ml-2">Clear</base-button>
              </div>
            </div>
            <div class="row mt-2" v-show="add_payment">
              <div class="col-lg-3 col-md-6 col-12">
                <base-input label="Payment options">
                  <el-select
                    class="select-primary mb-3"
                    v-model="selectedPaymentOption"
                    placeholder="Option"
                    value-key="id"
                  >
                    <el-option
                      class="select-primary"
                      v-for="(item, index) in paymentOptions"
                      :key="index + 'P'"
                      :label="item.name"
                      :value="{id: item.id, name: item.name, content: item.content, is_default: item.is_default}"
                    >
                    </el-option>
                  </el-select>
                </base-input>

              </div>
            </div>

            <div class="row" v-if="!selectedPaymentOption.is_default">
              <div class="col">
                <div v-html="selectedPaymentOption.content"></div>
              </div>
            </div>

            <div class="row" v-show="selectedPaymentOption.name === 'Customer credit' && add_payment">
              <div class="col-lg-3 col-md-3 col-6">
                <base-input label="Available ($)" v-model="available_credit" disabled></base-input>
              </div>
              <div class="col-lg-3 col-md-3 col-6">
                <base-input label="Amount to apply($)" v-model="applied_credit"></base-input>
              </div>
            </div>

            <div class="row" v-show="add_payment == true && selectedPaymentOption.name === 'Gift card' && selectedGiftCardCode">
              <div class="col-lg-2 col-md-3 col-6">
                <base-input label="Available balance ($)" v-model="selectedGiftCardCode.remaining" disabled></base-input>
              </div>
            </div>

            <div class="row mb-3" v-show="add_payment === true && selectedPaymentOption.name === 'Gift card'">
              <div class="col-lg-6">
                <base-input label="Gift card code" :error="errs.gift_card_code">
                  <el-autocomplete style="width: 100%"
                                   v-model="gift_card_code"
                                   :fetch-suggestions="giftCardSearch"
                                   placeholder="Search by code..."
                                   :trigger-on-focus="false"
                                   @select="handleGiftCardSelect"
                  >
                    <template #default="{ item }">
                      <div class="row">
                        <div class="col">
                          <p style="margin-bottom: -12px" class="text-primary">{{ getGiftCardCode(item.code) }}</p>
                          <small class="font-italic">
                            <i class="tim-icons icon-money-coins"></i> Total {{getAmount(item.total)}} Remaining {{getAmount(item.remaining)}}
                          </small>
                          <br/>
                        </div>
                      </div>
                    </template>
                  </el-autocomplete>
                </base-input>
              </div>
            </div>

            <!--                  Square-->
            <div class="row" v-show="selectedPaymentOption.name === 'Card' && payment_settings.payment_gateway_id === 2 && add_payment">
              <div class="col-lg-6 col-md-6 col-12">
                <form id="payment-form-square" >
                  <div id="card-container"></div>
                </form>
                <div id="payment-status-container"></div>
              </div>
            </div>

            <!--        Stripe-->
            <div class="row mb-5" v-show="selectedPaymentOption.name === 'Card' && payment_settings.payment_gateway_id === 3 && add_payment">
              <div class="col-lg-6 col-md-6 col-12">
                <form id="payment-form">
                  <label for="card-element">
                    Credit or debit card
                  </label>
                  <div id="card-element">
                    <!-- A Stripe Element will be inserted here. -->
                  </div>
                  <div id="card-errors" role="alert"></div>
                </form>
              </div>
            </div>

            <div class="row" v-if="selectedPaymentOption.name === 'Card' && payment_settings.payment_gateway_id === 1 && add_payment">
              <div class="col-12 col-lg-6">
                <base-input :error="errs.ccn" v-mask="'#### #### #### ####'" placeholder="4444 4444 4444 4444" v-model="payment.cardNumber" label="Credit card number"></base-input>
              </div>

            </div>
            <div class="row" v-if="selectedPaymentOption.name === 'Card' && payment_settings.payment_gateway_id === 1 && add_payment">
              <div class="col-4 col-lg-2">
                <base-input :error="errs.zip" :placeholder="country.zip_placeholder"
                            v-model="payment.zip" type="text" :label="country.zip_label"></base-input>
              </div>
              <div class="col-4 col-lg-2">
                <base-input :error="errs.expiration" placeholder="11/27" v-model="payment.expirationDate" label="Expiration"></base-input>
              </div>
              <div class="col-4 col-lg-2">
                <base-input :error="errs.cvv" v-mask="'####'" placeholder="111" v-model="payment.cardCode" type="tel" label="CVV"></base-input>
              </div>
            </div>
          </div>

        </div>
<!--Complete transaction-->
        <div class="row justify-content-end">
          <div class="col text-right">
            <base-checkbox v-model="waive_tax" v-on:input="recalculateDiscount()" class="d-inline">Waive taxes</base-checkbox>
            <base-checkbox v-model="waive_fee" v-on:input="recalculateDiscount()" class="d-inline ml-3">Waive fees</base-checkbox>
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col text-right">
            <hr style="border-top: 1px solid #f4f5f7;">
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-8">
                <h4 class="card-title text-white text-uppercase">Subtotal</h4>
              </div>
              <div class="col text-right">
                <h4 class="text-white"><span class="text-uppercase">
                   {{getAmount(subtotal)}}</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-end" v-for="(p, i) in promos" :key="pc_key + i">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-8">
                <h4 class="text-white card-title"><span class="text-uppercase">
                    {{p.name}}</span>
                  <base-button @click="removePromoCode(p)" style="padding: 0; margin: 0" type="danger" icon class="btn-link d-inline">
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </h4>
              </div>
              <div class="col text-right">
                <!--                <h4 class="card-title text-white" v-if="p.redemption_type === 0"><span class="text-uppercase">-->
                <!--                      -{{getAmount(getDiscount(p.value_amount, subtotal))}}</span>-->
                <!--                </h4>-->
                <h4 class="card-title text-white"><span class="text-uppercase">
                      -{{getAmount(getDiscount(p))}}</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!waive_tax" class="row justify-content-end" v-for="(t, i) in taxes" :key="'T' + i">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-8">
                <h4 class="card-title text-white text-uppercase">{{t.name}}</h4>
              </div>
              <div class="col text-right">
                <h4 class="text-white"><span class="text-uppercase">
                   {{getAmount(getTax(t))}}</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!waive_fee" class="row justify-content-end" v-for="(f, i) in fees" :key="'F' + i">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-8">
                <h4 class="card-title text-white text-uppercase">{{f.name}}</h4>
              </div>
              <div class="col text-right">
                <h4 class="text-white"><span class="text-uppercase">
                   {{getAmount(getFee(f))}}</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-end" v-if="taxes.length > 0">
          <div class="col d-sm-none d-block text-right">
            <hr style="border-top: 1px solid #f4f5f7;">
          </div>
          <div class="col-6 d-sm-block d-none text-right ">
            <hr style="border-top: 1px solid #f4f5f7;">
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-8">
                <h4 class="card-title text-white text-uppercase">Total</h4>
              </div>
              <div class="col text-right">
                <h4 v-if="!waive_tax && !waive_fee" class="card-title text-white">
                  {{getAmount(getTotal())}}
                </h4>
                <h4 v-if="waive_tax && !waive_fee" class="card-title text-white">
                  {{getAmount(getTotal() - getTaxes())}}
                </h4>
                <h4 v-if="!waive_tax && waive_fee" class="card-title text-white">
                  {{getAmount(getTotal() - getFees())}}
                </h4>
                <h4 v-if="waive_tax && waive_fee" class="card-title text-white">
                  {{getAmount(getTotal() - getTaxes() - getFees())}}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-sm-none d-block">
          <div class="col text-right">
            <base-button v-on:click="cancelTransaction()" type="default">Cancel</base-button>
            <base-button class="ml-2" :disabled="loading" type="primary" v-on:click="createBooking()">Create</base-button>
          </div>
        </div>
        <div class="row justify-content-end d-sm-block d-none">
          <div class="col text-right">
            <base-button v-on:click="cancelTransaction()" type="default">Cancel transaction</base-button>
            <base-button class="ml-2" :disabled="loading" type="primary" v-on:click="createBooking()">Complete transaction</base-button>
          </div>
        </div>
      </card>
    </div>
  </div>
</div>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import {formattedDate, time} from "@/plugins/dateFormatter";
import swal from "sweetalert2";
import {Autocomplete} from 'element-ui';
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import {Auth} from "aws-amplify";
import _ from "lodash";
import currencies from "@/assets/currencies.json";
import countries from "@/assets/countries.json";


export default {
  name: "NewTransaction",
  components: {
    BTable,
    [Autocomplete.name]: Autocomplete,
  },
  computed: {
    categories() {
      return [{ name: 'Basket', icon: 'tim-icons icon-cart', active: true },
        { name: 'Customers', icon: 'tim-icons  icon-single-02', active: false },
        { name: 'Payment', icon: 'tim-icons  icon-money-coins', active: false },
      ];
    }
  },
  data() {
    return {
      selectedGiftCardCode: '',
      gift_card_code: '',
      no_confirmation_email: false,
      waive_fee: false,
      waive_tax: false,
      country: '',
      countries: countries.countries,
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      currencies: currencies.currencies,
      lookup_value: '',
      loading: false,
      cart: [],
      taxes: [],
      fees: [],
      subtotal: 0,
      total_tax: 0,
      total_fee: 0
,      total: 0,
      activeTabIndex: 0,
      selectedPaymentOption: {name: '', content: '', id: '', is_default: ''},
      paymentOptions: [],
      cartFields: ["booking", "number_of_players", "price", "actions"],
      cartFieldsMobile: ['cart'],
      available_credit: 0,
      applied_credit: 0,
      j: 0,
      editing: [],
      price_override: [],
      payment: {
        cardNumber: '',
        expirationDate: '',
        cardCode: '',
        zip: ''
      },
      user: {
        first_name: '',
        last_name: '',
        email: '',
        phone: ''
      },
      errs: {
        gift_card_code: '',
        first_name: '',
        last_name: '',
        email: '',
        ccn: '',
        cvv: '',
        zip: '',
        expiration: ''
      },
      card: '',
      square: '',
      squareAppId: '',
      squareLocationId: '',
      elements: '',
      stripe: '',
      payment_settings: '',
      add_payment: true,
      currency: JSON.parse(localStorage.getItem('group')).currency,
      currency_symbol: '$',
      number_format: JSON.parse(localStorage.getItem('group')).number_format,
      currency_format: JSON.parse(localStorage.getItem('group')).currency_format,
      send_payment_request: false,
      pay_partial_amount: false,
      partial_amount: 0,
      pa_key: 0,
      promos: [],
      promo_code: '',
      selectedPromoCode: '',
      pc_key: 0,
    }
  },
  methods: {
    payOnArrival() {
      this.pay_partial_amount = false
      this.add_payment = false
    },
    addPaymentNow(){
      this.add_payment = true
      this.send_payment_request = false
    },
    overridePrice(pricing_categories, index, item){
      for(let n in pricing_categories){
        if(item.cumulative_category_pricing){
          //Price
          if(!pricing_categories[n].hasOwnProperty('original_price')){
            for(let m in pricing_categories[n].category_pricing){
              if(item.group_size >= pricing_categories[n].category_pricing[m].min_players
                && item.group_size <= pricing_categories[n].category_pricing[m].max_players){
                pricing_categories[n].original_price = pricing_categories[n].category_pricing[m].price
                pricing_categories[n].price = pricing_categories[n].category_pricing[m].price
              }
            }
            let last_index = _.findLastKey(pricing_categories[n].category_pricing)
            if(last_index && item.group_size > pricing_categories[n].category_pricing[last_index].max_players){
              pricing_categories[n].original_price = pricing_categories[n].category_pricing[last_index].price
              pricing_categories[n].price = pricing_categories[n].category_pricing[last_index].price
            }
            let smallest_price_category = _.find(pricing_categories[n].category_pricing)
            if(smallest_price_category && item.number_of_players !== 0 &&
              item.group_size < smallest_price_category.min_players){
              pricing_categories[n].original_price = smallest_price_category.price
              pricing_categories[n].price = smallest_price_category.price
            }
          }
          //Original price
          if(pricing_categories[n].hasOwnProperty('original_price')){
            for(let m in pricing_categories[n].category_pricing){
              if(item.group_size >= pricing_categories[n].category_pricing[m].min_players
                && item.group_size <= pricing_categories[n].category_pricing[m].max_players){
                pricing_categories[n].original_price = pricing_categories[n].category_pricing[m].price
              }
            }

            let last_index = _.findLastKey(pricing_categories[n].category_pricing)
            if(last_index && item.group_size > pricing_categories[n].category_pricing[last_index].max_players){
              pricing_categories[n].original_price = pricing_categories[n].category_pricing[last_index].price
            }
            let smallest_price_category = _.find(pricing_categories[n].category_pricing)
            if(smallest_price_category && item.group_size !== 0 &&
              item.group_size < smallest_price_category.min_players){
              pricing_categories[n].original_price = smallest_price_category.price
            }
          }
        }
        else {

          for(let m in pricing_categories[n].category_pricing){
            if(pricing_categories[n].number_of_players >= pricing_categories[n].category_pricing[m].min_players
              && pricing_categories[n].number_of_players <= pricing_categories[n].category_pricing[m].max_players) {
              pricing_categories[n].original_price = pricing_categories[n].category_pricing[m].price
              pricing_categories[n].price = pricing_categories[n].category_pricing[m].price
            }
          }
          let last_index = _.findLastKey(pricing_categories[n].category_pricing)
          if(last_index && pricing_categories[n].number_of_players > pricing_categories[n].category_pricing[last_index].max_players){
            pricing_categories[n].original_price = pricing_categories[n].category_pricing[last_index].price
            pricing_categories[n].price = pricing_categories[n].category_pricing[last_index].price
          }

          let smallest_price_category = _.find(pricing_categories[n].category_pricing)
          if(smallest_price_category && pricing_categories[n].number_of_players !== 0 &&
            pricing_categories[n].number_of_players < smallest_price_category.min_players){
            pricing_categories[n].original_price = smallest_price_category.price
            pricing_categories[n].price = smallest_price_category.price
          }
        }
      }
      this.$set(this.price_override, index, true);
    },
    saveOverride(pricing_categories, index, item){
      for(let n in pricing_categories){
        if(pricing_categories[n].hasOwnProperty('original_price') &&
          pricing_categories[n].original_price  === pricing_categories[n].price){
          delete pricing_categories[n].original_price
        }
      }
      this.getBookingSubtotal(item)
      this.$set(this.price_override, index, false);
    },
    setOriginalPrice(category){
      category.price = category.original_price
    },
    async giftCardSearch(queryString, cb){
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/gifts/autocomplete?q=' + encodeURIComponent(queryString)
      axios.get(url, config)
        .then(response => {
          let suggestions = []
          for(let n in response.data.gift_cards){
            if(response.data.gift_cards[n].code != null){
              suggestions.push({
                value: JSON.stringify(response.data.gift_cards[n]),
                id: response.data.gift_cards[n].id,
                code: response.data.gift_cards[n].code,
                customer_id: response.data.gift_cards[n].customer_id,
                date_purchased: response.data.gift_cards[n].date_purchased,
                email: response.data.gift_cards[n].email,
                first_name: response.data.gift_cards[n].first_name,
                last_name: response.data.gift_cards[n].last_name,
                remaining: response.data.gift_cards[n].remaining,
                total: response.data.gift_cards[n].total
              })
            }

          }
          const results = suggestions

          cb(results)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            swal({
              title: 'Error',
              html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
              type: 'error',
            }).then((response)=> {
              this.$router.push('/')
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    handleGiftCardSelect(item){
      this.gift_card_code = this.getGiftCardCode(item.code)
      this.selectedGiftCardCode = item
    },
    getGiftCardCode(code){
      if(!code){
        return
      }
      if(isNaN(code)){
        return code
      }
      let c = code.match(/.{1,4}/g);
      return c.join(' ')
    },
    recalculateDiscount(){
      this.promos = []
    },
    getBookingSubtotal(game){
      let pricing = JSON.parse(game.game_pricing)
      if(game.is_custom_pricing_enabled){
        pricing = game.custom_pricing
      }

      game.total = 0
      game.group_size = 0
      if(game.cumulative_category_pricing){
        if(game.pricing_type_id === 1){
          for (let m in game.tickets) {
            game.group_size += game.tickets[m].number_of_players
          }
          for(let m in game.tickets){
            for(let n in pricing){
              if(pricing[n].category_id === game.tickets[m].category_id
                && game.group_size >= pricing[n].min_players
                && game.group_size <= pricing[n].max_players) {
                if(game.tickets[m].hasOwnProperty('original_price')){
                  game.total += game.tickets[m].price * game.tickets[m].number_of_players
                } else {
                  game.total += pricing[n].price * game.tickets[m].number_of_players
                }
                break
              }
            }
            let last_index = _.findLastKey(pricing, {category_id: game.tickets[m].category_id})
            if(game.group_size > pricing[last_index].max_players){
              if(game.tickets[m].hasOwnProperty('original_price')){
                game.total += game.tickets[m].price * game.tickets[m].number_of_players
              } else {
                game.total += pricing[last_index].price * game.tickets[m].number_of_players
              }

            }

            let smallest_price_category = _.find(pricing, {category_id : game.tickets[m].category_id})
            if(game.group_size !== 0 &&
              game.group_size < smallest_price_category.min_players){
              if(game.tickets[m].hasOwnProperty('original_price')){
                game.total += game.tickets[m].price * game.tickets[m].number_of_players
              } else {
                game.total += smallest_price_category.price * game.tickets[m].number_of_players
              }

            }
          }
        }
        else if(game.pricing_type_id === 2){
          for (let m in game.tickets) {
            game.group_size += game.tickets[m].number_of_players
          }
          for(let m in game.tickets){
            for(let n in pricing){
              if(pricing[n].category_id === game.tickets[m].category_id
                && game.group_size >= pricing[n].min_players
                && game.group_size <= pricing[n].max_players) {
                if(game.tickets[m].hasOwnProperty('original_price')){
                  game.total += game.tickets[m].price
                } else {
                  game.total += pricing[n].price
                }

                break
              }
            }
            let last_index = _.findLastKey(pricing, {category_id: game.tickets[m].category_id})
            if(game.group_size > pricing[last_index].max_players){
              if(game.tickets[m].hasOwnProperty('original_price')){
                game.total += game.tickets[m].price
              } else {
                game.total += pricing[last_index].price
              }
            }

            let smallest_price_category = _.find(pricing, {category_id : game.tickets[m].category_id})
            if(game.group_size !== 0 &&
              game.group_size < smallest_price_category.min_players){
              if(game.tickets[m].hasOwnProperty('original_price')){
                game.total += game.tickets[m].price
              } else {
                game.total += smallest_price_category.price
              }

            }
          }
        }
      } else {
        if(game.pricing_type_id === 1){
          for(let m in game.tickets){

            game.group_size += game.tickets[m].number_of_players
            for(let n in pricing){
              if(pricing[n].category_id === game.tickets[m].category_id
                && game.tickets[m].number_of_players >= pricing[n].min_players
                && game.tickets[m].number_of_players <= pricing[n].max_players) {
                if(game.tickets[m].hasOwnProperty('original_price')){
                  game.total += game.tickets[m].price * game.tickets[m].number_of_players
                } else {
                  game.total += pricing[n].price * game.tickets[m].number_of_players
                }

                break
              }
            }
            let last_index = _.findLastKey(pricing, {category_id: game.tickets[m].category_id})
            if(game.tickets[m].number_of_players > pricing[last_index].max_players){
              if(game.tickets[m].hasOwnProperty('original_price')){
                game.total += game.tickets[m].price * game.tickets[m].number_of_players
              } else {
                game.total += pricing[last_index].price * game.tickets[m].number_of_players
              }

            }

            let smallest_price_category = _.find(pricing, {category_id : game.tickets[m].category_id})
            if(game.tickets[m].number_of_players !== 0 &&
              game.tickets[m].number_of_players < smallest_price_category.min_players){
              if(game.tickets[m].hasOwnProperty('original_price')){
                game.total += game.tickets[m].price * game.tickets[m].number_of_players
              } else {
                game.total += smallest_price_category.price * game.tickets[m].number_of_players
              }

            }
          }
        }
        else if(game.pricing_type_id === 2){
          for(let m in game.tickets){
            game.group_size += game.tickets[m].number_of_players
            for(let n in pricing){
              if(pricing[n].category_id === game.tickets[m].category_id
                && game.tickets[m].number_of_players >= pricing[n].min_players
                && game.tickets[m].number_of_players <= pricing[n].max_players) {
                if(game.tickets[m].hasOwnProperty('original_price')){
                  game.total += game.tickets[m].price
                } else {
                  game.total += pricing[n].price
                }

                break
              }
            }
            let last_index = _.findLastKey(pricing, {category_id: game.tickets[m].category_id})
            if(game.tickets[m].number_of_players > pricing[last_index].max_players){
              if(game.tickets[m].hasOwnProperty('original_price')){
                game.total += game.tickets[m].price
              } else {
                game.total += pricing[last_index].price
              }

            }

            let smallest_price_category = _.find(pricing, {category_id : game.tickets[m].category_id})
            if(game.tickets[m].number_of_players !== 0 &&
              game.tickets[m].number_of_players < smallest_price_category.min_players){
              if(game.tickets[m].hasOwnProperty('original_price')){
                game.total += game.tickets[m].price
              } else {
                game.total += smallest_price_category.price
              }
            }
          }
        }
      }

      this.getSubTotal()
    },
    getRate(item, game){
      let pricing = JSON.parse(game.game_pricing)
      if(game.is_custom_pricing_enabled){
        pricing = game.custom_pricing
      }
      if(game.cumulative_category_pricing){
        for(let n in pricing){
          if(pricing[n].category_id === item.category_id
            && game.group_size >= pricing[n].min_players
            && game.group_size <= pricing[n].max_players) {
            if(item.hasOwnProperty('original_price')){
              return game.pricing_type_id === 1 ? item.price
                : this.getAmount(item.price) + ' for '  + pricing[n].min_players + '-'
                + pricing[n].max_players + ' ' + item.category
            } else {
              return game.pricing_type_id === 1 ? pricing[n].price
                : this.getAmount(pricing[n].price) + ' for ' + pricing[n].min_players + '-'
                + pricing[n].max_players + ' ' + item.category
            }
          }
        }

        let last_index = _.findLastKey(pricing, {category_id: item.category_id})
        if(game.group_size > pricing[last_index].max_players){
          if(item.hasOwnProperty('original_price')){
            return game.pricing_type_id === 1 ? item.price
              : this.getAmount(item.price) + ' for '
              + pricing[last_index].min_players + '-' + pricing[last_index].max_players + ' ' + item.category
          } else {
            return game.pricing_type_id === 1 ? pricing[last_index].price
              : this.getAmount(pricing[last_index].price) + ' for ' + pricing[last_index].min_players + '-' + pricing[last_index].max_players + ' ' + item.category
          }

        }

        let smallest_price_category = _.find(pricing, {category_id : item.category_id})
        if(game.group_size !== 0 &&
          game.group_size < smallest_price_category.min_players){
          if(item.hasOwnProperty('original_price')){
            return game.pricing_type_id === 1 ? item.price
              : this.getAmount(item.price) + ' for ' + smallest_price_category.min_players + '-' + smallest_price_category.max_players + ' ' + item.category
          } else {
            return game.pricing_type_id === 1 ?smallest_price_category.price
              : this.getAmount(smallest_price_category.price) + ' for ' + smallest_price_category.min_players + '-' + smallest_price_category.max_players + ' ' + item.category
          }
        }
      } else {
        for(let n in pricing){
          if(pricing[n].category_id === item.category_id
            && item.number_of_players >= pricing[n].min_players
            && item.number_of_players <= pricing[n].max_players) {
            if(item.hasOwnProperty('original_price')){
              return game.pricing_type_id === 1 ? item.price
                : this.getAmount(item.price) + ' for '  + pricing[n].min_players + '-'
                + pricing[n].max_players + ' ' + item.category
            } else {
              return game.pricing_type_id === 1 ? pricing[n].price
                : this.getAmount(pricing[n].price) + ' for '  + pricing[n].min_players + '-'
                + pricing[n].max_players + ' ' + item.category
            }
          }
        }

        let last_index = _.findLastKey(pricing, {category_id: item.category_id})
        if(item.number_of_players > pricing[last_index].max_players){
          if(item.hasOwnProperty('original_price')){
            return game.pricing_type_id === 1 ? item.price
              : this.getAmount(item.price)
              + ' for ' + pricing[last_index].min_players + '-' + pricing[last_index].max_players + ' ' + item.category
          } else {
            return game.pricing_type_id === 1 ? pricing[last_index].price
              : this.getAmount(pricing[last_index].price) + ' for ' + pricing[last_index].min_players + '-' + pricing[last_index].max_players + ' ' + item.category
          }

        }

        let smallest_price_category = _.find(pricing, {category_id : item.category_id})
        if(item.number_of_players !== 0 &&
          item.number_of_players < smallest_price_category.min_players){
          if(item.hasOwnProperty('original_price')){
            return game.pricing_type_id === 1 ? item.price
              : this.getAmount(item.price) + ' for ' + smallest_price_category.min_players + '-' + smallest_price_category.max_players + ' ' + item.category
          } else {
            return game.pricing_type_id === 1 ? smallest_price_category.price
              : this.getAmount(smallest_price_category.price) + ' for ' + smallest_price_category.min_players + '-' + smallest_price_category.max_players + ' ' + item.category
          }
        }
      }

      return 0
    },
    removePromoCode(item){
      _.remove(this.promos, {
        id: item.id
      });
      for(let m in this.cart){
        _.remove(this.cart[m].promos, {
          id: item.id
        });
      }
      this.pc_key++
    },
    applyPromoCode(){
      if(this.selectedPromoCode.hasOwnProperty('id')){

        let dupe = 0
        for(let n in this.promos){
          if(this.selectedPromoCode.id === this.promos[n].id){
            dupe++
          }
          if(this.promos[n].is_combineable === 0 || this.selectedPromoCode.is_combineable === 0){
            swal('Error', 'You cannot apply multiple non-combineable promo codes', 'error')
            return
          }
        }

        //Validations
        let game_allowed = false
        let event_date_allowed = false
        let event_time_allowed = false
        let day_allowed = false
        let is_exclusion = false
        let allowed_for_booking = 0

        for(let n in this.cart){
          allowed_for_booking = 0
          //1. If games are allowed
          if(this.selectedPromoCode.games){
            for(let m in this.selectedPromoCode.games){
              if(this.cart[n].game_id === this.selectedPromoCode.games[m].id){
                game_allowed = true
                allowed_for_booking = 0
                break
              } else {
                allowed_for_booking++
              }
            }
          }
          //2. If event date is allowed
          console.log(this.selectedPromoCode)
          let booking_date = new Date()
          booking_date.setFullYear(this.cart[n].booking_date.split('T')[0].split('-')[0])
          booking_date.setMonth(this.cart[n].booking_date.split('T')[0].split('-')[1] -1, this.cart[n].booking_date.split('T')[0].split('-')[2])
          if(this.selectedPromoCode.event_is_date_range){
            let start = new Date()
            start.setFullYear(this.selectedPromoCode.event_start_date.split('T')[0].split('-')[0])
            start.setMonth(this.selectedPromoCode.event_start_date.split('T')[0].split('-')[1] -1, this.selectedPromoCode.event_start_date.split('T')[0].split('-')[2])
            let end = new Date()
            end.setFullYear(this.selectedPromoCode.event_end_date.split('T')[0].split('-')[0])
            end.setMonth(this.selectedPromoCode.event_end_date.split('T')[0].split('-')[1] -1, this.selectedPromoCode.event_end_date.split('T')[0].split('-')[2])
            if(booking_date >= start && booking_date <= end){
              event_date_allowed = true
            } else {
              allowed_for_booking++
            }
          } else {
            event_date_allowed = true
          }


          //3. If event time is allowed
          if(this.selectedPromoCode.event_is_time_range){
            let booking_time = new Date()
            booking_time.setHours(this.cart[n].start_time.split(':')[0])
            booking_time.setMinutes(this.cart[n].start_time.split(':')[1])
            let start = new Date()
            start.setHours(this.selectedPromoCode.event_start_time.split(':')[0])
            start.setMinutes(this.selectedPromoCode.event_start_time.split(':')[1])
            let end = new Date()
            end.setHours(this.selectedPromoCode.event_end_time.split(':')[0])
            end.setMinutes(this.selectedPromoCode.event_end_time.split(':')[1])

            if(booking_time >= start && booking_time <= end){
              event_time_allowed = true
            } else {
              allowed_for_booking++
            }
          } else {
            event_time_allowed = true
          }


          //4. If day of week is allowed
          let day_of_week = booking_date.getDay()
          if(this.selectedPromoCode.available_days[day_of_week] == true){
            day_allowed = true
          } else {
            allowed_for_booking++
          }


          //5.If there are exclusion dates for event
          if(this.selectedPromoCode.event_exlusions){
            let bd = this.cart[n].booking_date.split('T')[0]
            let exc = this.selectedPromoCode.event_exlusions.split(',')
            for(let e in exc){
              if(exc[e] === bd){
                is_exclusion = true
                allowed_for_booking++
              }
            }
          }


          if(!game_allowed || !event_date_allowed || !event_time_allowed || !day_allowed || is_exclusion){
            swal('Error', 'The promo code cannot be applied to bookings in your cart', 'error')
            return
          }

          if(dupe !== 0){
            swal('Error', 'You cannot apply the same promo code more than once', 'error')
            return
          }
          //Update booking with added promo code
          if(allowed_for_booking === 0){
            this.cart[n].promos.push(this.selectedPromoCode)
          }
        }
        if(this.selectedPromoCode.value_amount > this.total && this.selectedPromoCode.redemption_type === 1){
          //get the value of taxes that are applied after discount
          let apply_after_discount_taxes = 0
          let waived_taxes = 0
          let waived_fees = 0
          for(let n in this.taxes){
            if(this.taxes[n].apply_after_discount === 1 && !this.waive_tax){
              apply_after_discount_taxes += parseFloat(this.getTax(this.taxes[n]))
            } else if (this.waive_tax){
              waived_taxes += parseFloat(this.getTax(this.taxes[n]))
            }
          }
          if(this.waive_fee){
            for(let n in this.fees){
              waived_fees += parseFloat(this.getFee(this.fees[n]))
            }
          }
          this.selectedPromoCode.value_amount = this.total - apply_after_discount_taxes - waived_taxes - waived_fees
        }

        this.promos.push(this.selectedPromoCode)
      }
      this.promo_code = ''
    },
    handlePromoSelect(item){
      this.promo_code = item.name
      this.selectedPromoCode = item
    },
    setPartialAmount(){
      this.partial_amount = this.total.toFixed(2)
    },
    getFees(){
      let total_fee = 0
      for(let n in this.fees){
        //value
        if(this.fees[n].value_type === 0){
          total_fee += this.fees[n].amount
        }
        //value (per person)
        if(this.fees[n].value_type === 1){
          let number_of_players = 0
          for(let m in this.cart){
            number_of_players += this.cart[m].group_size
          }
          total_fee += (parseFloat(this.fees[n].amount) * parseInt(number_of_players))
        }
        //percentage
        if(this.fees[n].value_type === 2){
          //not compound
          total_fee += this.getPercentage(this.fees[n].amount, this.subtotal)
        }
      }
      total_fee = parseFloat(total_fee).toFixed(2)
      this.total_fee = total_fee
      return total_fee
    },
    getTaxes(){
      if(parseFloat(this.subtotal) == 0){
        this.total_tax = 0
        return 0
      }
      let total_tax = 0
      for(let t in this.taxes){
        //exclusive of price
        if(this.taxes[t].pricing_type === 0){
          //value (total)
          if(this.taxes[t].value_type === 0){
            total_tax += parseFloat(this.taxes[t].amount)
          }
          //value (per person)
          if(this.taxes[t].value_type === 1){
            let number_of_players = 0
            for(let n in this.cart){
              number_of_players += this.cart[n].group_size
            }
            total_tax += (parseFloat(this.taxes[t].amount) * parseInt(number_of_players))
          }
          //percentage
          if(this.taxes[t].value_type === 2){
            let amount = this.taxes[t].amount
            //not compound
            if(!this.taxes[t].is_compound){
              if(this.taxes[t].apply_after_discount) {
                let after_discount = this.subtotal - this.getDiscounts()
                if(after_discount < 0){
                  amount = 0
                }
                total_tax += this.getPercentage(amount, after_discount)
              } else {
                total_tax += this.getPercentage(amount, this.subtotal)
              }
            } else {
              let compound_subtotal = parseFloat(this.subtotal) + parseFloat(total_tax)
              if(this.taxes[t].apply_after_discount){
                let after_discount = compound_subtotal - this.getDiscounts()
                if(after_discount < 0){
                  amount = 0
                }
                total_tax += this.getPercentage(amount, after_discount)
              } else {
                total_tax += this.getPercentage(amount, compound_subtotal)
              }
            }
          }
        }
      }
      total_tax = parseFloat(total_tax).toFixed(2)
      this.total_tax = total_tax
      return total_tax
    },
    getFee(f){
      let total_fee = 0
      //value(total)
      if(f.value_type === 0){
        total_fee = f.amount
      }
      //value (per person)
      if(f.value_type === 1){
        let number_of_players = 0
        for(let n in this.cart){
          number_of_players += this.cart[n].group_size
        }
        total_fee = (f.amount * number_of_players)
      }
      //percentage
      if(f.value_type === 2){
        total_fee = this.getPercentage(f.amount, this.subtotal)
      }
      total_fee = parseFloat(total_fee).toFixed(2)
      return total_fee
    },
    checkWaivedTaxFee(){
      if(this.waive_tax){
        this.total = this.total - this.total_tax
        this.total_tax = 0
      }
      if(this.waive_fee){
        this.total = this.total - this.total_fee
        this.total_fee = 0
      }
    },
    getCompoundTax(tax){
      let total_tax = 0
      for(let t in this.taxes){
        //exclusive of price
        if(this.taxes[t].pricing_type === 0){
          //value (total)
          if(this.taxes[t].value_type === 0){
            total_tax += this.taxes[t].amount
          }
          //value (per person)
          if(this.taxes[t].value_type === 1){
            let number_of_players = 0
            for(let n in this.cart){
              number_of_players += this.cart[n].group_size
            }
            total_tax += (this.taxes[t].amount * number_of_players)
          }
          //percentage
          if(this.taxes[t].value_type === 2){
            //not compound
            if(!this.taxes[t].is_compound){
              total_tax += this.getPercentage(this.taxes[t].amount, this.subtotal)
            }
          }
        }
        if(tax.id === this.taxes[t].id && this.taxes[t].is_compound){
          total_tax = parseFloat(total_tax).toFixed(2)
          return total_tax
        }
      }
      total_tax = parseFloat(total_tax).toFixed(2)
      return total_tax
    },
    getTax(t){
      if(parseFloat(this.subtotal) == 0){
        return 0
      }
      let total_tax = 0
      if(t.pricing_type === 0){
        //value (total)
        if(t.value_type === 0){
          total_tax = t.amount
        }
        //value (per person)
        if(t.value_type === 1){
          let number_of_players = 0
          for(let n in this.cart){
            number_of_players += this.cart[n].group_size
          }
          total_tax = (t.amount * number_of_players)
        }
        //percentage
        if(t.value_type === 2){
          let amount = t.amount
          //not compound
          if(!t.is_compound){
            if(t.apply_after_discount){

              let after_discount = this.subtotal - this.getDiscounts()
              if(after_discount <= 0){
                amount = 0
              }
              total_tax = this.getPercentage(amount, after_discount)
            } else {
              total_tax = this.getPercentage(amount, this.subtotal)
            }
          } else {
            let compound_tax = this.getCompoundTax(t)
            let compound_subtotal = parseFloat(this.subtotal) + parseFloat(compound_tax)
            if(t.apply_after_discount){
              let after_discount = compound_subtotal - this.getDiscounts()
              if(after_discount < 0){
                amount = 0
              }
              total_tax = this.getPercentage(amount, after_discount)
            } else {
              total_tax = this.getPercentage(amount, compound_subtotal)
            }
          }
        }
      }
      total_tax = parseFloat(total_tax).toFixed(2)
      return total_tax
    },
    getPercentage(amount, subtotal){
      if(subtotal && subtotal.toString().includes(',')){
        subtotal = subtotal.replace(',', '.')
      }
      if(amount && amount.toString().includes(',')){
        amount = amount.replace(',', '.')
      }
      return parseFloat(subtotal) * (parseFloat(amount)*0.01)
    },
    getAmount(integer){
      if(integer == null){
        return
      }
      if(this.number_format === 1 && this.currency_format === 0){
        return this.currency_symbol + integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',')
      } else if(this.number_format === 1 && this.currency_format === 1){
        return integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',') + ' ' + this.currency
      } else if (this.number_format === 1 && this.currency_format === 1){
        return parseFloat(integer).toFixed(2) + ' ' + this.currency
      }
      return this.currency_symbol + parseFloat(integer).toFixed(2);
    },
    async initializeCard(payments) {
      const customStyle = {
        '.input-container.is-focus': {
          borderColor: '#e14eca',
        },
        '.input-container.is-error': {
          borderColor: '#ff1600',
        },
        '.message-text.is-error': {
          color: '#ff1600',
        },
        '.message-icon.is-error': {
          color: '#ff1600',
        },
        'input.is-error': {
          color: '#ff1600',
        },
        '.message-icon': {
          color: '#adb5bd',
        },
        '@media screen and (max-width: 600px)': {
          'input': {
            'fontSize': '12px',
          }
        },
        '.input-container': {
          // borderColor: 'inherit',
          // backgroundColor: this.bss.card_color,
          // color: this.bss.text_color_card,
          // 'font-family': this.bss.font_family_card,
          borderRadius: '6px',
        },
        '.message-text': {
          color: '#adb5bd',
          // 'font-family': this.bss.font_family_card
        },
        input: {
          backgroundColor: localStorage.getItem('mode') === '0' ? '#ffffff' : '#27293d',
          color: localStorage.getItem('mode') === '0' ? '#344675' : '#ffffff',

          // fontFamily: 'helvetica neue, sans-serif',
          //fontFamily: this.bss.font_family_card
        },
        'input::placeholder': {
          color: '#adb5bd',
        },
      }
      const card = await payments.card({
        style: customStyle,
      });
      await card.attach('#card-container');
      return card;
    },
    async verifyBuyer(payments, token) {

      const verificationDetails = {
        amount: this.total.toString(),
        /* collected from the buyer */
        billingContact: {
          familyName: this.user.first_name,
          givenName: this.user.last_name,
        },
        currencyCode: this.currency,
        intent: 'CHARGE',
      };

      const verificationResults = await payments.verifyBuyer(
        token,
        verificationDetails
      );
      return verificationResults.token;
    },
    async handlePaystackPayment(type, reference){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId +  '/groups/' + companyGroupId +'/transactions'

      this.checkWaivedTaxFee()
      let data = {
        amount: this.getTotal(),
        subtotal: this.subtotal,
        tax: this.getTaxes(),
        discount: this.getDiscounts(),
        no_confirmation_email: this.no_confirmation_email,
        send_payment_request: this.send_payment_request,
        promo_codes: this.promos,
        fee: this.getFees(),
        total: this.getTotal(),
        user: this.user,
        bookings: this.cart,
        token: token.id,
        paystack_reference: reference,
        payment: {cardNumber: '1111'},
      }

      if(this.pay_partial_amount){
        if(parseFloat(this.partial_amount) > parseFloat(this.total)){
          this.errs.partial_amount = 'Amount cannot be greater than total'
          this.pa_key++
          return
        } else {
          this.errs.partial_amount = ''
          this.pa_key++
        }
        data.partial_amount = this.partial_amount
      }
      data.user.phone = data.user.phone === null ? '' : data.user.phone
      this.axios.post(url, data, config)
        .then(response => {
          swal('Success!', 'The transaction was created', 'success')
          localStorage.removeItem('cart')
          localStorage.removeItem('taxes')
          localStorage.removeItem('customer')
          this.addAnotherBooking()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else if (err.response.status == 500) {
            swal({
              title: 'Error!',
              text: err.response.data,
              type: 'error',
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async payWithPaystack() {
      let handler = PaystackPop.setup({
        key: this.payment_settings.api_name, // Replace with your public key
        email: this.user.email,
        amount: (parseFloat(this.getTotal()) * 100).toFixed(0).toString(),
        ref: ''+Math.floor((Math.random() * 1000000000) + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
        callback: (response) => {
          this.handlePaystackPayment('new', response)
        }
      });

      handler.openIframe();
    },
    async loadPaystack(){
      const script = document.createElement('script');
      script.src = 'https://js.paystack.co/v2/inline.js'
      script.async = true;
      document.body.appendChild(script);
    },
    async loadStripe(){
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/';
      script.async = true;
      document.body.appendChild(script);
    },
    async loadSquare(is_live){
      const script = document.createElement('script');
      console.log(is_live)
      if(is_live){
        script.src = 'https://web.squarecdn.com/v1/square.js'
      } else {
        script.src = 'https://sandbox.web.squarecdn.com/v1/square.js'
      }
      script.async = true;
      document.body.appendChild(script);
    },
    async initializeSquare(type){
      if (!window.Square) {
        throw new Error('Square.js failed to load properly');
      }
      if(this.card){
        this.card.destroy()
      }
      this.square = window.Square.payments(this.squareAppId, this.squareLocationId);
      try {
        if(type == 'add_payment'){
          this.card = await this.initializeCardAddPayment(this.square);
        } else {
          this.card = await this.initializeCard(this.square);
        }

      } catch (e) {
        console.error('Initializing Card failed', e);
        return;
      }
    },
    async initializeStripe(){
      this.stripe = window.Stripe(this.payment_settings.api_name);
      this.elements = await this.stripe.elements();
      const style = {
        base: {
          fontSize: '16px',
          fontFamily: 'Poppins, sans-serif',
          color: localStorage.getItem('mode') === '0' ? '#344675' : '#ffffff'
        },
      };
      // Create an instance of the card Element.
      this.card = await this.elements.create('card', {style});
      // Add an instance of the card Element into the `card-element` <div>.
      this.card.mount('#card-element');
    },
    async handleStripePayment(){
      const {token, error} = await this.stripe.createToken(this.card);
      if(error){
        swal('Error!', 'There was an issue with your payment', 'error')
        this.loading = false
        return
      }
      this.loading = true
      let tok = await this.getAuthToken()
      let config = {headers: {Authorization: tok}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/'+ companyId + '/groups/' + companyGroupId + '/transactions/stripe'
      let data = {
        amount: this.getTotal(),
        subtotal: this.subtotal,
        tax: this.getTaxes(),
        discount: this.getDiscounts(),
        no_confirmation_email: this.no_confirmation_email,
        send_payment_request: this.send_payment_request,
        promo_codes: this.promos,
        fee: this.getFees(),
        total: this.getTotal(),
        user: this.user,
        bookings: this.cart,
        token: token.id
      }

      if(this.pay_partial_amount){
        if(parseFloat(this.partial_amount) > parseFloat(this.total)){
          this.errs.partial_amount = 'Amount cannot be greater than total'
          this.pa_key++
          return
        } else {
          this.errs.partial_amount = ''
          this.pa_key++
        }
        data.partial_amount = this.partial_amount
      }
      data.user.phone = data.user.phone === null ? '' : data.user.phone

      this.axios.post(url, data, config)
        .then(response => {
          swal('Success!', 'The transaction was created', 'success')
          localStorage.removeItem('cart')
          localStorage.removeItem('taxes')
          localStorage.removeItem('customer')
          this.addAnotherBooking()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else if (err.response.status == 500) {
            swal({
              title: 'Error!',
              text: err.response.data,
              type: 'error',
            })
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async createBooking(){
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId +  '/groups/' + companyGroupId +'/transactions'

      if(this.user.first_name === '' || this.user.last_name === '' || this.user.email === ''){
        swal('Ooops!', 'You must assign a customer to the transaction.', 'error')
        return
      }
      if(this.add_payment === false){
        this.checkWaivedTaxFee()
        let data = {
          bookings: this.cart,
          subtotal: this.subtotal,
          tax: this.getTaxes(),
          discount: this.getDiscounts(),
          no_confirmation_email: this.no_confirmation_email,
          promo_codes: this.promos,
          fee: this.getFees(),
          total: this.getTotal(),
          user: this.user,
          send_payment_request: this.send_payment_request
        }
        this.loading = true
        this.axios.post(url, data, config)
          .then(response => {
            swal('Success!', 'The transaction was created', 'success')
            localStorage.removeItem('cart')
            localStorage.removeItem('taxes')
            localStorage.removeItem('customer')
            this.send_payment_request = false
            this.addAnotherBooking()
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              this.$router.push('/forbidden')
            } else if (err.response.status == 500) {
              swal('Error!', err.response.data, 'error')
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      }
      else if (!this.selectedPaymentOption.is_default || this.selectedPaymentOption.name === 'Yappy' ||
        this.selectedPaymentOption.name === 'Cash'
        || this.selectedPaymentOption.name === 'POS payment'){
        let payment_type = 'pos'
        if(this.selectedPaymentOption.name === 'Cash'){
          payment_type = 'cash'
        }
        if(!this.selectedPaymentOption.is_default){
          payment_type = this.selectedPaymentOption.name
        }
        if(this.selectedPaymentOption.name === 'Yappy'){
          payment_type = 'Yappy'
        }
        this.checkWaivedTaxFee()
        let data = {
          bookings: this.cart,
          subtotal: this.subtotal,
          tax: this.getTaxes(),
          discount: this.getDiscounts(),
          no_confirmation_email: this.no_confirmation_email,
          send_payment_request: this.send_payment_request,
          promo_codes: this.promos,
          fee: this.getFees(),
          total: this.getTotal(),
          user: this.user,
          payment_type: payment_type,
          custom_payment_type: !this.selectedPaymentOption.is_default
        }

        if(this.pay_partial_amount){
          if(parseFloat(this.partial_amount) > parseFloat(this.total)){
            this.errs.partial_amount = 'Amount cannot be greater than total'
            this.pa_key++
            return
          } else {
            this.errs.partial_amount = ''
            this.pa_key++
          }
          data.partial_amount = this.partial_amount
        }
        data.user.phone = data.user.phone === null ? '' : data.user.phone

        this.loading = true
        this.axios.post(url, data, config)
          .then(response => {
            swal('Success!', 'The transaction was created', 'success')
            localStorage.removeItem('cart')
            localStorage.removeItem('taxes')
            localStorage.removeItem('customer')
            this.addAnotherBooking()
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              this.$router.push('/forbidden')
            } else if (err.response.status == 500) {
              swal('Error!', err.response.data, 'error')
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      }
      else if(this.payment_settings.payment_gateway_id === 3 && this.selectedPaymentOption.name === 'Card' ){
        this.checkWaivedTaxFee()
        let stripePayment = await this.handleStripePayment('new')
      }
      else if(this.payment_settings.payment_gateway_id === 2 && this.selectedPaymentOption.name === 'Card' ){
        this.checkWaivedTaxFee()
        let squarePayment = await this.createSquarePayment()
      } else if(this.payment_settings.payment_gateway_id === 4 && this.selectedPaymentOption.name === 'Card' ){
        this.checkWaivedTaxFee()
        let payStack = await this.payWithPaystack()
      }
      else {
        //TODO: make this a separate function
        if(!this.validateCard()){
          return
        }
        this.payment.cardNumber = this.payment.cardNumber.replace(/\s/g, '');
        this.checkWaivedTaxFee()
        let data = {
          bookings: this.cart,
          subtotal: this.subtotal,
          tax: this.getTaxes(),
          fee: this.getFees(),
          discount: this.getDiscounts(),
          no_confirmation_email: this.no_confirmation_email,
          send_payment_request: this.send_payment_request,
          promo_codes: this.promos,
          total: this.getTotal(),
          user: this.user,
          payment: this.payment
        }

        if(this.selectedPaymentOption.name === 'Customer credit'){
          if(parseFloat(this.applied_credit) > parseFloat(this.total)){
            swal('Ooops!', 'Charged amount cannot be greater than the total value of the booking.', 'error')
            return
          }
          if(parseFloat(this.applied_credit) > parseFloat(this.available_credit)){
            swal('Ooops!', 'You cannot apply more credits than available.', 'error')
            return
          }
          data.customer_credit = this.applied_credit
        }

        if(this.selectedPaymentOption.name === 'Gift card'){
          if(!this.gift_card_code
            || !this.selectedGiftCardCode.hasOwnProperty('id')
            || this.getGiftCardCode(this.selectedGiftCardCode.code) !== this.gift_card_code){
            this.errs.gift_card_code = 'You must choose a valid gift card'
            return
          } else {
            this.errs.gift_card_code = ''
          }
          data.gift_card = this.selectedGiftCardCode
          data.payment_type = 'gift_card'
        }
        if(this.pay_partial_amount){
          if(parseFloat(this.partial_amount) > parseFloat(this.total)){
            this.errs.partial_amount = 'Amount cannot be greater than total'
            this.pa_key++
            return
          } else {
            this.errs.partial_amount = ''
            this.pa_key++
          }
          data.partial_amount = this.partial_amount
        }
        data.user.phone = data.user.phone === null ? '' : data.user.phone
        this.loading = true
        this.axios.post(url, data, config)
          .then(response => {
            swal('Success!', 'The transaction was created', 'success')
            localStorage.removeItem('cart')
            localStorage.removeItem('taxes')
            localStorage.removeItem('customer')
            this.addAnotherBooking()
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              this.$router.push('/forbidden')
            } else if (err.response.status == 500) {
              swal('Error!', err.response.data, 'error')
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      }
    },
    validateCard(){
      let error = 0

      if(this.add_payment == true && this.selectedPaymentOption.name === 'Card'){
        if(this.payment.cardNumber == '' ) {
          this.errs.ccn = 'Invalid card number'
          error++
        } else {
          this.errs.ccn = ''
        }
        if(this.payment.expirationDate == '' ) {
          this.errs.expiration = 'Invalid expiration date'
          error++
        } else {
          this.errs.expiration = ''
        }
        if(this.payment.cardCode == '' ) {
          this.errs.cvv = 'Invalid CVV'
          error++
        } else {
          this.errs.cvv = ''
        }
        if(this.payment.zip == '' ) {
          this.errs.zip = 'Invalid zip'
          error++
        } else {
          this.errs.zip = ''
        }
      }

      if(error > 0){
        return false
      }
      return true
    },
    clearCustomer(){
      this.lookup_value = ''
      this.user = {
        first_name: '',
          last_name: '',
          email: '',
          phone: ''
      }
    },
    async tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();

      if (tokenResult.status === 'OK') {
        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(
            tokenResult.errors
          )}`;
        }

        throw new Error(errorMessage);
      }
    },
    async createSquarePayment(type){
      this.loading = true
      try {
        const token = await this.tokenize(this.card);
        const verificationToken = await this.verifyBuyer(this.square, token);
        const token2 = await this.tokenize(this.card);
        let tok = await this.getAuthToken()
        let config = {headers: {Authorization: tok}}
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/'+ companyId + '/groups/' + companyGroupId + '/transactions/square'
        let data = {
          amount: this.total,
          subtotal: this.subtotal,
          tax: this.getTaxes(),
          discount: this.getDiscounts(),
          no_confirmation_email: this.no_confirmation_email,
          send_payment_request: this.send_payment_request,
          promo_codes: this.promos,
          fee: this.getFees(),
          total: this.getTotal(),
          user: this.user,
          bookings: this.cart,
          locationId: this.squareLocationId,
          sourceId: token,
          sourceId2: token2,
          verificationToken: verificationToken
        }
        if(this.pay_partial_amount){
          if(parseFloat(this.partial_amount) > parseFloat(this.total)){
            this.errs.partial_amount = 'Amount cannot be greater than total'
            this.pa_key++
            return
          } else {
            this.errs.partial_amount = ''
            this.pa_key++
          }
          data.partial_amount = this.partial_amount
        }
        data.user.phone = data.user.phone === null ? '' : data.user.phone

        this.axios.post(url, data, config)
          .then(response => {
            swal('Success!', 'The transaction was created', 'success')
            localStorage.removeItem('cart')
            localStorage.removeItem('taxes')
            localStorage.removeItem('customer')
            this.addAnotherBooking()
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              this.$router.push('/forbidden')
            } else if (err.response.status == 500) {
              swal('Error!', err.response.data, 'error')
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      } catch (error) {
        //Square
        this.loading = false
      }

    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async promoSearch(queryString, cb){
      let today = new Date()
      let purchase_date = today.getFullYear() +  "-" +  ('0' + (today.getMonth() + 1)).slice(-2) + "-" + ('0' + today.getDate()).slice(-2);

      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/promos?autocomplete=' + encodeURIComponent(queryString) + '&purchase_date=' + purchase_date
      axios.get(url, config)
        .then(response => {
          let suggestions = []
          if(response.data.hasOwnProperty('gift_card')){
            this.selectedPromoCode = response.data.gift_card
          }
          for(let n in response.data.promos){
            if(response.data.promos[n].name != null){
              suggestions.push({
                value: JSON.stringify(response.data.promos[n]),
                id: response.data.promos[n].id,
                name: response.data.promos[n].name,
                description: response.data.promos[n].description,
                value_amount: response.data.promos[n].value_amount,
                redemption_type: response.data.promos[n].redemption_type,
                value_type: response.data.promos[n].value_type,
                games: JSON.parse(response.data.promos[n].games),
                event_exlusions: response.data.promos[n].event_exlusions,
                internal_only: response.data.promos[n].internal_only,
                event_is_date_range: response.data.promos[n].event_is_date_range,
                event_is_time_range: response.data.promos[n].event_is_time_range,
                event_start_date: response.data.promos[n].event_start_date,
                event_end_date: response.data.promos[n].event_end_date,
                event_start_time: response.data.promos[n].event_start_time,
                event_end_time: response.data.promos[n].event_end_time,
                available_days: [response.data.promos[n].available_sunday,
                  response.data.promos[n].available_monday,
                  response.data.promos[n].available_tuesday,
                  response.data.promos[n].available_wednesday,
                  response.data.promos[n].available_thursday,
                  response.data.promos[n].available_friday,
                  response.data.promos[n].available_saturday]
              })
            }

          }
          const results = queryString
            ? suggestions.filter(this.createFilter(queryString))
            : suggestions;

          cb(results)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    async querySearch(queryString, cb) {
      //make API call to /customers with queryString as filter
      var token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/customers?count=10000000&offset=0&first_name=&last_name=&email=&phone=&game=&tag=&autocomplete=' + encodeURIComponent(queryString)
      axios.get(url, config)
        .then(response => {
          let suggestions = []
          for(let n in response.data.customers){
            if(response.data.customers[n].email != null
              && response.data.customers[n].first_name != null
              && response.data.customers[n].first_name != ''){
              suggestions.push({
                value: JSON.stringify(response.data.customers[n]),
                email: response.data.customers[n].email,
                end_user_id: response.data.customers[n].end_user_id,
                id: response.data.customers[n].id,
                first_name: response.data.customers[n].first_name,
                last_name: response.data.customers[n].last_name,
                phone: response.data.customers[n].phone
              })
            }

          }


          const results = suggestions;

          cb(results)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    createFilter(queryString) {
      return (suggestion) => {
        return suggestion.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1;
      };
    },
    updateCustomer(){
      localStorage.setItem('customer', JSON.stringify(this.user))
      this.getCredits(this.user)
    },
    getAvailableCredit(){
      let available = 0
      for(let n in this.customer_credits){
        available = available + parseFloat(this.customer_credits[n].remaining)
      }
      this.available_credit = available.toFixed(2)
      if(parseFloat(this.available_credit) < parseFloat(this.subtotal)){
        this.applied_credit = this.available_credit
      } else {
        this.applied_credit = this.subtotal
      }
    },
    handleSelect(item){
      this.lookup_value = item.email
      this.user.first_name = item.first_name
      this.user.last_name = item.last_name
      this.user.email = item.email
      this.user.phone = item.phone
      //update LS
      localStorage.setItem('customer', JSON.stringify(item))
      this.getCredits(item)

    },
    async getCredits(item){
      console.log(item)
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId +
        '/customers/' + item.end_user_id + '/customer-credit'
      axios.get(url, config)
        .then(response => {
          this.customer_credits = response.data.customer_credits
          this.getAvailableCredit()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    },
    saveBooking(index){
      let pricing =  JSON.parse(this.cart[index].price)
      if(this.price_override[index]){
        if(pricing[0].min_players !== 0){
          this.cart[index].total = parseFloat(this.cart[index].ppp) * parseInt(this.cart[index].group_size)
        } else {
          this.cart[index].total = this.cart[index].ppg
        }
      } else {
        if(pricing[0].min_players === 0){
          this.cart[index].total = pricing[0].price
        } else {
          for(let m in pricing){
            if(pricing[m].min_players >= this.cart[index].group_size && pricing[m].max_players <= this.cart[index].group_size){
              this.cart[index].ppp = parseFloat(pricing[m].price)
              this.cart[index].total  = this.cart[index].group_size *  parseFloat(pricing[m].price)
            }
          }
          if(this.cart[index].group_size > pricing[pricing.length -1].min_players){
            this.cart[index].ppp = parseFloat(pricing[pricing.length -1].price)
            this.cart[index].total = (parseFloat(this.cart[index].group_size) * parseFloat(pricing[pricing.length -1].price))
          }
        }
      }

      localStorage.setItem('cart', JSON.stringify(this.cart))
      this.getSubTotal()
      this.getTaxes()
      this.getFees()
      this.getTotal()
      this.$set(this.editing, index, false);
      this.$set(this.price_override, index, false);
    },
    editBooking(index){
      this.$set(this.editing, index, true);
    },
    addAnotherBooking(){
      this.$router.push('/bookings')
    },
    cancelTransaction(){
      swal('Are you sure?', 'This will remove all items from cart', 'warning')
        .then((response)=> {
          localStorage.removeItem('cart')
          localStorage.removeItem('taxes')
          localStorage.removeItem('customer')
          this.$router.push('/bookings')
        })
    },
    switchTab(index){
      this.activeTabIndex = index
      for(let n in this.categories){
        this.categories[n].active = false
      }
      this.categories[index].active = true
      this.j++

      if(this.activeTabIndex === 2){
        this.getPaymentGateway()
      }
    },
    getTotal(){
      this.total = parseFloat(this.subtotal) + parseFloat(this.getTaxes()) + parseFloat(this.getFees()) - parseFloat(this.getDiscounts())
      return this.total
    },
    getDiscount(promo){
      let discount = 0
      if(promo.value_type === 0){
        if(promo.redemption_type === 1){
          discount = promo.value_amount
          if(discount > this.subtotal){
            discount = this.subtotal
          }
        } else {
          let subtotal = 0
          for(let m in this.cart){
            for(let k in this.cart[m].promos){
              if(this.cart[m].promos[k].id === promo.id){
                subtotal += this.cart[m].total
              }
            }
          }
          discount = this.getPercentage(promo.value_amount, subtotal)
        }
      }
      else if(promo.value_type === 2){
        let number_of_players = 0
        for(let m in this.cart){
          for(let k in this.cart[m].promos){
            if(this.cart[m].promos[k].id === promo.id){
              number_of_players += this.cart[m].group_size
            }
          }
        }
        if(promo.redemption_type === 1){
          discount = promo.value_amount * number_of_players
          if(discount > this.subtotal){
            discount = this.subtotal
          }
        } else {
          let subtotal = 0
          for(let m in this.cart){
            for(let k in this.cart[m].promos){
              if(this.cart[m].promos[k].id === promo.id){
                subtotal += this.cart[m].total
              }
            }
          }
          discount = this.getPercentage(promo.value_amount, subtotal) * number_of_players
        }
      }
      //Check if taxes and fees are covered
      let covered_fees = 0
      for(let n in this.fees){
        if(this.fees[n].covered_by_promo_codes){
          covered_fees += parseFloat(this.getFee(this.fees[n]).toString())
        }
      }
      let covered_taxes = 0
      for(let n in this.taxes){
        if(this.taxes[n].covered_by_promo_codes){
          covered_taxes += parseFloat(this.getTax(this.taxes[n]).toString())
        }
      }
      promo.applied_discount = discount + covered_fees + covered_taxes
      return discount + covered_fees + covered_taxes
    },
    getDiscounts(){
      let total_discounts = 0
      for(let n in this.promos){
        if (this.promos[n].value_type === 0){
          if(this.promos[n].redemption_type === 1){
            total_discounts += this.promos[n].value_amount
          } else {
            //Must compute against qualified games only
            let subtotal = 0
            for(let m in this.cart){
              for(let k in this.cart[m].promos){
                if(this.cart[m].promos[k].id === this.promos[n].id){
                  subtotal += this.cart[m].total
                }
              }
            }
            total_discounts += this.getPercentage(this.promos[n].value_amount, subtotal)
            if(total_discounts > this.subtotal){
              total_discounts = this.subtotal
            }
          }
        }
        else if (this.promos[n].value_type === 2){
          let number_of_players = 0
          for(let m in this.cart){
            for(let k in this.cart[m].promos){
              if(this.cart[m].promos[k].id === this.promos[n].id){
                number_of_players += this.cart[m].group_size
              }
            }
          }
          if(this.promos[n].redemption_type === 1){

            total_discounts = this.promos[n].value_amount * number_of_players
          } else {
            let subtotal = 0
            for(let m in this.cart){
              for(let k in this.cart[m].promos){
                if(this.cart[m].promos[k].id === this.promos[n].id){
                  subtotal += this.cart[m].total
                }
              }
            }
            total_discounts += this.getPercentage(this.promos[n].value_amount, subtotal) * number_of_players
          }
        }
      }

      //Check if taxes and fees are covered
      let covered_fees = 0
      for(let n in this.fees){
        if(this.fees[n].covered_by_promo_codes){
          covered_fees += parseFloat(this.getFee(this.fees[n]).toString())
        }
      }

      let covered_taxes = 0
      for(let n in this.taxes){
        if(this.taxes[n].covered_by_promo_codes){
          covered_taxes += parseFloat(this.getTax(this.taxes[n]).toString())
        }
      }

      total_discounts = total_discounts + covered_fees + covered_taxes
      return total_discounts
    },
    getSubTotal(){
      this.subtotal = 0
      for(let n in this.cart){
        this.subtotal += parseFloat(this.cart[n].total) // + taxes and fees
      }
    },
    deleteBooking(index){
      this.cart.splice(index, 1)
      localStorage.setItem('cart', JSON.stringify(this.cart))
      this.getBasket()

    },
    getBookingDate(d){
      return formattedDate(this.date_format, d)
    },
    getTime(d){
      return time(this.time_format, d)
    },
    getBasket(){
      let cart = JSON.parse(localStorage.getItem('cart')) || []
      let pricing = []
      for(let m in cart){
        if(cart[m].is_custom_pricing_enabled){
          pricing = cart[m].custom_pricing
        } else {
          pricing = JSON.parse(cart[m].game_pricing)
        }

        cart[m].group_size = 0
        cart[m].tickets = []
        let categories = this.getCategories(pricing)
        for(let n in pricing){
          if(!(_.find(cart[m].tickets, { category_id: pricing[n].category_id }))){
            cart[m].tickets.push({
              category_id: pricing[n].category_id,
              category: pricing[n].category.toLowerCase(),
              number_of_players: cart[m].min_players_count,
              price: pricing[n].price,
              category_pricing: _.find(categories, {category_id: pricing[n].category_id}).pricing
            })
            cart[m].group_size++
          }
        }

        this.getBookingSubtotal(cart[m])

        cart[m].promos = []
      }
      this.cart = cart


      this.getSubTotal()
      this.getTaxes()
      this.getFees()
      this.getTotal()
    },
    getCategories(items){

      const groupedArray = [];

      items.forEach(item => {
        const { category_id, category, minimum_participants, default_participants } = item;

        // Find if the category_id already exists in groupedArray
        let group = groupedArray.find(g => g.category_id === category_id);

        if (!group) {
          // If it doesn't exist, create a new group
          group = {
            category_id: category_id,
            category: category,
            minimum_participants: minimum_participants,
            default_participants: default_participants,
            pricing: []
          };
          groupedArray.push(group);
        }

        group.pricing.push(item);
      });
      return groupedArray;
    },
    getPaymentGateway(){
      this.payment_settings = JSON.parse(localStorage.getItem('payment_settings'))
      if(this.payment_settings.payment_gateway_id === 3){
        this.initializeStripe()
      }
      if(this.payment_settings.payment_gateway_id === 2){
        this.initializeSquare()
      }
    },
    getUser(){
      if(localStorage.getItem('customer')){
        this.user = JSON.parse(localStorage.getItem('customer'))
        this.getCredits(this.user)
      }
    },
    async getPaymentMethods(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/payment-methods'

      axios.get(url, config)
        .then((response) => {
          this.loading = false
          this.paymentOptions = response.data.payment_methods
          this.selectedPaymentOption = this.paymentOptions.length > 0 ? this.paymentOptions[0] : {}
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal('Error!', 'Something went wrong', 'error')
          }
        })
    }
  },
  mounted() {
    this.currency_symbol =  _.find(this.currencies, { code: JSON.parse(localStorage.getItem('group')).currency }).symbol
    this.taxes = JSON.parse(localStorage.getItem('taxes')) || []
    this.fees = JSON.parse(localStorage.getItem('fees')) || []
    this.no_confirmation_email = false
    this.country = _.find(this.countries, { code: JSON.parse(localStorage.getItem('group')).country })
    this.payment_settings = JSON.parse(localStorage.getItem('payment_settings'))
    if(this.payment_settings.payment_gateway_id === 3){
      this.loadStripe()
    }
    if(this.payment_settings.payment_gateway_id === 2){
      this.squareAppId = this.payment_settings.api_name
      this.squareLocationId = this.payment_settings.api_location
      this.loadSquare(this.payment_settings.is_live)
    }
    if(this.payment_settings.payment_gateway_id === 4){
      this.loadPaystack()
    }
    this.getPaymentMethods()
    this.getUser()
    this.getBasket()
  }
}
</script>

<style scoped>
img {
  width: 60px;
  height: 60px;
  -webkit-border-radius: 60px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 60px;
  -moz-background-clip: padding;
  border-radius: 60px;
  background-clip: padding-box;
  margin: 7px 0 0 5px;
  float: left;
  background-size: cover;
  background-position: center center;
  margin-top: -7px;
  margin-bottom: -7px;
}
</style>
