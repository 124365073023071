<template>
  <div>
    <!--    Breadcrumbs-->
    <breadcrumb style="margin-left: -15px">
      <breadcrumb-item>
        <a href="/games/settings">Game settings</a>
      </breadcrumb-item>
      <breadcrumb-item v-if="$route.path === '/games/edit'">
        <span>Edit {{ game_name }}</span>
      </breadcrumb-item>
      <breadcrumb-item v-if="$route.path === '/games/create'">
        <span>Create game</span>
      </breadcrumb-item>
    </breadcrumb>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>
    <div class="row mt-3">
      <div class="col">
        <card card-body-classes="table-full-width">
          <div class="row mb-3">
            <div class="col">
              <div
                class="btn-group btn-group-toggle"
                :class="'float-left'"
                data-toggle="buttons"
              >
                <label
                  v-for="(option, index) in categories"
                  :key="index + j"
                  class="btn btn-sm btn-primary btn-simple"
                  :class="{ active: option.active }"
                  :id="index"
                >
                  <input
                    type="radio"
                    @click="switchTab(index)"
                    name="options"
                    autocomplete="off"
                    :checked="option.active"
                  />
                  <span style="font-size: medium; font-weight: lighter"  class="d-none d-sm-block">{{ option.name }}</span>
                  <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
                </label>
              </div>
            </div>
            <div class="col text-right">
              <base-button v-if="!game.archived"
                           v-on:click="toggleActivationPrompt(game)" size="sm" simple type="success">
               Active
              </base-button>
              <base-button v-if="game.archived"
                v-on:click="toggleActivationPrompt(game)" size="sm" simple type="danger">
                Archived
              </base-button>
            </div>
          </div>
<!--          General-->
          <div v-if="activeTabIndex === 0">
            <div class="row">
              <div class="col-lg-6">
                <base-input label="Game name" :error="errs.name"
                  v-model="game.name"
                  placeholder="Game name">
                </base-input>
              </div>
              <div class="col-lg-3">
                <base-input :error="errs.duration" v-model="game.duration_minutes" label="Game duration (minutes)">
                </base-input>
              </div>
            </div>
            <label>Game description</label>
            <div class="row mb-3" style="border-radius: 30px">
              <div class="col-lg-9">
                <editor v-model="game.description" style="background-color: #2bffc6"
                        api-key="qs89hwib76rceahcpau0cxv1bxz229nbfkfwoxfgo7kd96wz"
                        :init="{
                     height: 200,
                     menubar: false,
                     font_formats: 'Andale Mono=andale mono,times; ' +
                      'Arial=arial,helvetica,sans-serif; ' +
                       'Arial Black=arial black,avant garde; ' +
                        'Book Antiqua=book antiqua,palatino; ' +
                         'Comic Sans MS=comic sans ms,sans-serif; ' +
                          'Courier New=courier new,courier; ' +
                           'Georgia=georgia,palatino; Helvetica=helvetica; ' +
                            'Impact=impact,chicago; Oswald=oswald; Symbol=symbol; ' +
                             'Tahoma=tahoma,arial,helvetica,sans-serif; ' +
                              'Terminal=terminal,monaco; ' +
                               'Times New Roman=times new roman,times; ' +
                                'Trebuchet MS=trebuchet ms,geneva; ' +
                                 'Ubuntu;' +
                                 'Verdana=verdana,geneva;' +
                                  ' Webdings=webdings; ' +
                                   'Wingdings=wingdings,zapf dingbats',
                     plugins: [
                       'advlist autolink lists link image charmap print preview anchor',
                       'searchreplace visualblocks code fullscreen',
                       'insertdatetime media table paste code help wordcount'
                     ],
                     force_br_newlines : true,
                    force_p_newlines : false,
                    forced_root_block : '',
                    branding: false,
                    statusbar: false,
                     toolbar:
                       'fontselect | formatselect | bold italic forecolor backcolor | \
                       alignleft aligncenter alignright alignjustify | \
                       bullist numlist outdent indent '
                   }"
                />
              </div>
            </div>
            <div class="row" >
              <div class="col-lg-3">
                <label>Minimum number of players</label>
                <div class="row">
                  <div class="col">
                    <base-input>
                      <el-input-number size="small" v-model="game.min_players_count" :min="1" :max="game.max_players_count"/>
                    </base-input>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <label>Maximum number of players</label>
                <div class="row">
                  <div class="col">
                    <base-input>
                      <el-input-number size="small" v-model="game.max_players_count" :min="game.min_players_count" />
                    </base-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-2" >
              <div class="col">
                <label>Number of resources required to run a single game</label>
                <div class="row">
                  <div class="col-lg-3">
                    <base-input>
                      <el-input-number size="small" v-model="game.required_resource_count" :min="1" />
                    </base-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col">
                <el-switch active-color="#e14eca"
                           v-model="game.block_concurrent_events_when_booked"  />
                <label> &nbsp; Block <b>all</b> concurrent events when booked</label>
              </div>
            </div>

            <div class="row mt-2 mb-3">
              <div class="col">
                <el-switch active-color="#e14eca"
                           v-model="game.include_game_difficulty"  />
                <label> &nbsp; Show game difficulty on booking site</label>
              </div>
            </div>
            <div class="row" v-if="game.include_game_difficulty">
              <div class="col-lg-3">
                <base-input label="Game difficulty">
                  <b-form-rating size="lg" show-clear
                                 :icon-empty="game.difficulty_icon"
                                 :icon-full="game.difficulty_icon + '-fill'"
                                 :color="game.difficulty_color" style="cursor: pointer"
                                 no-border
                                 v-model="game.difficulty_level">
<!--                    <template v-slot:icon-full>-->
<!--                      <i class="tim-icons icon-pencil"></i>-->
<!--                    </template>-->
                  </b-form-rating>
                </base-input>
              </div>
              <div class="col-lg-3">
                <base-input label="Difficulty label" v-model="game.difficulty_label">
                </base-input>
              </div>
              <div class="col-lg-3">
                <base-input label="Difficulty icon">
                  <el-select
                    v-model="game.difficulty_icon"
                    class="select-primary"
                    name="email_group"
                  >
                    <el-option
                      v-for="(icon, index) in icon_options"
                      class="select-primary"
                      :label="icon"
                      :value="icon"
                      :key="'I' + index"
                    >
                      <span style="float: left">
                         <b-icon :icon="icon" scale="1" class="mr-1" color="#e14eca"></b-icon>
<!--                        <i :class="'text-primary tim-icons ' + icon "></i>-->
                        {{ capitalizeFirstLetter(icon) }}</span>
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-3">
                <base-input label="Difficulty icon color">
                  <el-color-picker  v-model="game.difficulty_color" show-alpha></el-color-picker>
                </base-input>
              </div>
            </div>
          </div>

<!--          Pricing-->
          <div v-if="activeTabIndex === 1">
            <div class="row">
              <div class="col-lg-2">
                <base-input label="Pricing type">
                  <el-select
                    label="Select time"
                    v-model="selectedPricingType"
                    class="select-primary"
                    value-key="id"
                    placeholder="Pricing type">
                    <el-option
                      v-for="(pt, index) in pricing_types"
                      class="select-primary"
                      :label="pt.name"
                      :value="{id: pt.id,
                          name: pt.name}"
                      :key="'PT' + index"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col">
                <div class="row" v-for="(p, i) in pricing_categories" :key="'PP' + i">

                  <div class="col-3" >
                    <el-tooltip class="d-inline"
                                content="Remove category"
                                effect="light"
                                :open-delay="300"
                                placement="right"
                    >
                      <base-button style="padding: 0;" v-if="i > 0" size="sm" v-on:click="removeCategory(pricing_categories, i)" link type="danger"><i class="tim-icons icon-simple-remove"></i></base-button>
                    </el-tooltip>
                    <base-input class="d-inline" :error="errs.pricing_categories[i].category" v-model="p.category" :label="'Category'">
                    </base-input>
                    <base-input class="mt-2" :error="errs.pricing_categories[i].minimum_participants"  label="Minimum required participants">
                      <el-input-number :controls="false" v-model="p.minimum_participants" :min="0" placeholder="1" :step="1" />
                    </base-input>
                    <base-input  class="mt-2" :error="errs.pricing_categories[i].default_participants"  label="Default number of participants">
                      <el-input-number :controls="false" v-model="p.default_participants" :min="0" placeholder="1" :step="1" />
                    </base-input>
                  </div>
                  <div class="col" :key="cat_key">
                    <div class="row" v-for="(k, j) in pricing_categories[i].pricing" :key="'PPPP' + j">
                      <div class="col" >
                        <base-input :error="k.error_min_players"  :label="'Minimum participants'">
                          <el-input-number size="small" v-model="k.min_players" :min="getMin(pricing_categories[i].pricing, j)" placeholder="1" :step="1" />
                        </base-input>
                      </div>
                      <div class="col" >
                        <base-input :error="k.error_max_players"  :label="'Maximum participants'">
                          <el-input-number size="small" v-model="k.max_players" :min="1" placeholder="1" :step="1" />
                        </base-input>
                      </div>
                      <div class="col">
                        <base-input :error="k.error_price" :label="'Price (' + currency_symbol + ')'">
                          <el-input-number size="small" placeholder="0.00" v-model="k.price" :controls="false" :precision="2" :step="0.1" />
                        </base-input>
                      </div>
                      <div class="col-2 mr-1" style="margin-top: 35px; padding-left: 0; padding-right: 0;">
                        <el-tooltip
                          content="Remove price range"
                          effect="light"
                          :open-delay="300"
                          placement="right"
                        >
                          <base-button style="padding: 0;" v-if="j > 0" size="sm" v-on:click="removePriceRange(pricing_categories[i].pricing, i, j)" link type="danger"><i class="tim-icons icon-simple-remove"></i></base-button>

                        </el-tooltip>
                        <el-tooltip
                          content="Add price range"
                          effect="light"
                          :open-delay="300"
                          placement="right"
                        >
                          <base-button style="padding: 0;" v-if="j == pricing_categories[i].pricing.length-1" v-on:click="addPriceRange(pricing_categories[i], i)" size="sm" link type="success"><i class="tim-icons icon-simple-add"></i></base-button>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col text-right" >
                    <base-button class="mr-2" v-on:click="addNewCategory()" size="sm" type="primary">Add new category</base-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <base-checkbox v-model="game.cumulative_category_pricing">Cumulative category pricing</base-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-2">
                <base-checkbox v-model="game.allow_deposit">Allow deposit</base-checkbox>
              </div>
              <div class="col-lg-3 mt-2" v-if="game.allow_deposit">
                <base-input label="Deposit type">
                  <el-select
                    v-model="game.deposit_type"
                    class="select-primary"
                    name="deposit_type"
                    placeholder="Value type"
                    value-key="id"
                  >
                    <el-option
                      v-for="(type, index) in valueTypes"
                      class="select-primary"
                      :label="type.name"
                      :value="{name: type.name, id: type.id}"
                      :key="index + 'VT'"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-3 mt-2" v-if="game.allow_deposit">
                <base-input :label="'Deposit amount (' + currency_symbol +')'" placeholder="Deposit amount" v-on:change="validateAmount(game.deposit_amount)"
                             v-model="game.deposit_amount"></base-input>
              </div>
              <div class="col-lg-3 mt-2" v-if="game.allow_deposit">
                <base-checkbox v-model="game.require_deposit">Require only deposit amount during checkout</base-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <base-checkbox v-model="game.hide_pricing_rate">Hide pricing rate on booking site</base-checkbox>
              </div>
            </div>
          </div>


<!--      Puzzles-->
          <div class="row" v-if="activeTabIndex === 2">
            <div class="col">
              <base-button size="sm" v-on:click="addNewPuzzle()" type="primary">
                <i class="tim-icons el-icon-plus"></i> Add puzzle</base-button>
            </div>
          </div>

          <div v-if="activeTabIndex === 2 && puzzles.length <= 0" class="mt-3" >
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="tim-icons icon-puzzle-10"></i>
              </span>
              <h4 class="mt-3">No puzzles have been added.</h4>
            </div>
          </div>

          <!--Medium and large screens-->
          <div class="row d-none d-sm-block" v-if="activeTabIndex === 2">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <table class='table' v-if="puzzles.length > 0">
                <thead>
                <tr>
                  <th scope='col'>Order</th>
                  <th scope='col'>Name</th>
                  <th scope='col'>Description</th>
                  <th scope='col'>Solution</th>
                  <th scope='col'>Image</th>
                  <th class="text-right" scope='col'>Actions</th>
                </tr>
                </thead>
                <tbody tag='tbody' v-model='puzzles' is='draggable'>
                <tr v-for='(item, index) in puzzles' :key="'A' + index" style="cursor: move">
                  <td scope='row'>
                    {{ index  + 1}}
                  </td>
                  <td scope='row'>
                    <base-input style="max-width: 300px">
                        <textarea class="form-control" v-model="item.name"
                                  placeholder="Name" rows="1"></textarea>
                    </base-input>
                  </td>
                  <td scope='row'>
                    <base-input>
                        <textarea class="form-control" v-model="item.description"
                                  placeholder="Description" rows="1"></textarea>
                    </base-input>
                  </td>
                  <td scope='row'>
                    <base-input>
                        <textarea class="form-control" v-model="item.solution"
                                  placeholder="Solution" rows="1"></textarea>
                    </base-input>
                  </td>
                  <td scope='row'>
                    <img v-if="item.solution_img" slot="image" style="width: 50px; height:50px;" :src="item.solution_img"  alt="Card image cap"/>
                    <span v-else>-</span>
                  </td>
                  <td scope='row' class="text-right">

                    <el-tooltip content="Delete puzzle"
                                effect="light"
                                :open-delay="300"
                                placement="top">
                      <base-button
                        @click.native="deletePuzzle(item, index)"
                        class="like btn-link"
                        type="danger"
                        icon
                      ><i class="tim-icons icon-trash-simple" style="font-size: large"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip content="Add image"
                                effect="light"
                                :open-delay="300"
                                placement="top">
                      <base-button
                        class="like btn-link btn-file"
                        type="success"
                        icon
                      ><i class="tim-icons icon-camera-18" style="font-size: large"></i>
                        <input type="file"
                               accept="image/*"
                               @change="handleUpload(item)"
                        />
                      </base-button>
                    </el-tooltip>
                  </td>
                </tr>

                </tbody>
              </table>
            </div>
          </div>
          <!--Mobile-->
          <div class="row mt-3 d-block d-sm-none" v-if="activeTabIndex === 2">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <table class='table' v-if="puzzles.length > 0">
                <thead>
                <tr>
                  <th scope='col'>Puzzle</th>
                </tr>
                </thead>
                <tbody tag='tbody' v-model='puzzles' is='draggable'>
                <tr v-for='(item, index) in puzzles' :key="'B' + index" style="cursor: move">
                  <td scope='row'>
                    <div class="row">
                      <div class="col">
                        <base-input label="Puzzle name">
                        <textarea class="form-control" v-model="item.name"
                                  placeholder="Name" rows="1"></textarea>
                        </base-input>
                      </div>
                      <div class="col text-right">
                        <el-tooltip content="Delete puzzle"
                                    effect="light"
                                    :open-delay="300"
                                    placement="top">
                          <base-button
                            @click.native="deletePuzzle(item, index)"
                            class="like btn-link"
                            type="danger"
                            icon
                          ><i class="tim-icons icon-trash-simple" style="font-size: large"></i>
                          </base-button>
                        </el-tooltip>
                        <el-tooltip content="Add image"
                                    effect="light"
                                    :open-delay="300"
                                    placement="top">
                          <base-button
                            class="like btn-link btn-file"
                            type="success"
                            icon
                          ><i class="tim-icons icon-camera-18" style="font-size: large"></i>
                            <input type="file"
                                   accept="image/*"
                                   @change="handleUpload(item)"
                            />
                          </base-button>
                        </el-tooltip>
                      </div>

                    </div>

                    <base-input label="Puzzle description">
                        <textarea class="form-control" v-model="item.description"
                                  placeholder="Description" rows="1"></textarea>
                    </base-input>
                    <base-input label="Puzzle solution">
                        <textarea class="form-control" v-model="item.solution"
                                  placeholder="Solution" rows="1"></textarea>
                    </base-input>
                    <img v-if="item.solution_img" slot="image" style="width: 100%; height: 250px;" :src="item.solution_img"  alt="Card image cap"/>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
<!--          Save-->
          <div class="row mt-2">
            <div class="col">
              <base-button type="default" class="mr-2" v-on:click="cancel()">
                Cancel</base-button>
              <base-button type="primary" v-if="$route.path === '/games/edit'" v-on:click="saveGame('puzzles')">
                Save changes</base-button>
              <base-button type="primary" v-if="$route.path === '/games/create'" v-on:click="createGame()">
                Create</base-button>
            </div>
          </div>
<!--          <div class="row mt-2 d-sm-none d-block">-->
<!--            <div class="col">-->
<!--              <base-button simple style="width: 100%" v-if="activeTabIndex === 2"  v-on:click="addNewPuzzle()" class="mr-2" type="primary">-->
<!--                <i class="tim-icons el-icon-plus"></i> Add puzzle</base-button>-->
<!--              <base-button style="width: 100%" type="primary" v-on:click="saveGame('puzzles')">-->
<!--                Save changes</base-button>-->
<!--            </div>-->
<!--          </div>-->
        </card>
      </div>

      <div class="col-lg-4" v-if="activeTabIndex === 0 ">
        <card >
          <div class="row">
            <div class="col">
              <h4 class="card-title">Game images</h4>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="mt-3" v-if="game_images.length <= 0">
                <div class="font-icon-detail">
                      <span style="font-size: 3em">
                        <i class="fas fa-exclamation-triangle"></i>
                      </span>
                  <h4 class="mt-3">No images have been uploaded yet.</h4>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2"  v-if="game_images.length > 0">
            <div class="col">

<!--              <div  style="border-radius: 5px; width: 100%; height: 250px; position: relative">-->
<!--                &lt;!&ndash;                      <img&ndash;&gt;-->
<!--                &lt;!&ndash;                        :style="{ 'left': item.x + 'px', 'top': item.y + 'px', 'object-fit': 'contain', 'height': '100%'}"&ndash;&gt;-->
<!--                &lt;!&ndash;                        style="position: absolute;" :src="item.url">&ndash;&gt;-->
<!--                <img-->
<!--                  :style="{'transform': 'translate(0%, 0%) scale(' + game_images[2].scale*0.01 + ')', 'left': game_images[2].x + 'px', 'top': game_images[2].y + 'px', 'object-fit': game_images[2].object_fit, 'height': '100%'}"-->
<!--                  style="position: absolute;transform-origin: center center; will-change: transform" :src="game_images[2].url">-->
<!--              </div>-->
<!--              <div id="image-container"-->
<!--                   style="border-radius: 5px; width: 100%; height: 250px; overflow: hidden; position: relative;"-->
<!--                   :style="{'background-color': game_images[2].background_color}"-->
<!--              >-->
<!--                <img id="img"-->
<!--                     :style="{'transform': 'translate(0%, 0%) scale(' + game_images[2].scale*0.01 + ')', 'object-fit': 'contain', 'height': '100%'}"-->
<!--                     class="card-img"-->
<!--                     style="cursor: move; transform-origin: center center; will-change: transform"-->
<!--                     slot="image" :src="game_images[2].url"  alt="Card image cap"/>-->

<!--              </div>-->
              <div slot="image" style="padding-bottom: 0; height: 250px">
                <el-carousel v-on:change="setActiveImage" :autoplay="false" indicator-position="none" :interval="5000">
                  <el-carousel-item style="border-radius: 5px; width: 100%; height: 250px;"
                                    :style="{'background-color': item.background_color}" v-for="(item, index) in game_images"
                                    :key="'CS' + index">

                    <div  style="border-radius: 5px; width: 100%; height: 250px; position: relative">
                      <img id="img"
                           :style="{'transform': 'translate(0%, 0%) scale(' + item.scale*0.01 + ')', 'left': item.x + 'px', 'top': item.y + 'px', 'object-fit':  item.object_fit, 'height': '100%'}"
                           class="card-img"
                           style="position: absolute; transform-origin: center center; will-change: transform"
                           slot="image" :src="item.url"  alt="Card image cap"/>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>
          <div class="row" v-if="active_image">
            <div class="col-lg-9 mt-3">
              <p v-if="active_image.timestamp">Added on {{getLocalDate(active_image.timestamp)}}
                , {{getLocalTime(active_image.timestamp)}}

              </p>
              <small>{{active_image.description}}</small>
            </div>
            <div class="col text-right">
              <el-tooltip
                          content="Delete image"
                          effect="light"
                          :open-delay="300"
                          placement="top"
              >
                <base-button
                  v-on:click="deleteImagePrompt()" icon class="btn btn-link" type="danger">
                  <i class="tim-icons icon-trash-simple"></i>
                </base-button>
              </el-tooltip>
              <image-editor :edited_image="active_image"
                :edit="true" @saveImage="updateImage"></image-editor>
            </div>
          </div>

          <div class="row" v-if="active_image">
            <div class="col">
              <base-checkbox v-model="active_image.visible_to_customers">Visible to customers</base-checkbox>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col">
              <base-button v-if="active_image" type="primary" class="mr-2" v-on:click="updateImageSettings()">Save changes</base-button>
              <image-editor @saveImage="saveImage"></image-editor>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {Select, Option, Tooltip, Upload, Slider, ColorPicker, CarouselItem, Carousel, Switch} from 'element-ui';
  import { Auth } from 'aws-amplify'
  import { ImageUpload } from 'src/components';
  import { API_LOCATION } from '../../../api-config'
  import swal from 'sweetalert2'
  import currencies from "@/assets/currencies.json";
  const axios = require('axios')
  import _ from "lodash";
  import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
  import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
  import Editor from '@tinymce/tinymce-vue'
  import draggable from 'vuedraggable'
  import ImageEditor from "@/components/ImageEditor";
  import {dayOfWeek, formattedDate, localTime} from "@/plugins/dateFormatter";
  import { BFormRating, BIcon  } from 'bootstrap-vue'

  export default {
    name: "EditGame",
    computed: {
      categories() {
        return [{ name: 'General', icon: 'tim-icons icon-settings-gear-63', active: true },
          { name: 'Pricing', icon: 'tim-icons  icon-money-coins', active: false },
          { name: 'Puzzles', icon: 'tim-icons  icon-puzzle-10', active: false },
        ];
      },
      icon_options(){
        let arr = ['star', 'heart', 'alarm', 'award', 'bag', 'bell', 'diamond', 'dice3', 'dice5', 'droplet', 'egg', 'pencil',
          'emoji-smile', 'emoji-heart-eyes', 'emoji-sunglasses', 'envelope', 'exclamation-triangle', 'eye', 'gift', 'gear', 'hand-thumbs-up',
          'book', 'bookmark', 'bug', 'calculator', 'clock', 'cloud', 'cpu', 'handbag', 'hexagon', 'lightning', 'lock',
          'camera', 'camera-reels', 'chat', 'check-circle', 'circle', 'plug', 'puzzle', 'question-circle', 'shield-lock', 'signpost',
          'suit-club', 'suit-diamond', 'suit-heart', 'suit-spade', 'stopwatch', 'tag', 'telephone', 'tree', 'trophy', 'unlock',
        ]
        return arr.sort()
      }
    },
    components: {
      BreadcrumbItem,
      Breadcrumb,
      [Select.name]: Select,
      [Option.name]: Option,
      [Upload.name]: Upload,
      [Slider.name]: Slider,
      [ColorPicker.name]: ColorPicker,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,
      [Switch.name]: Switch,
      Tooltip,
      ImageUpload,
      'editor': Editor,
      draggable,
      ImageEditor,
      BFormRating,
      BIcon
    },
    data() {
      return {
        payment_gateway_id: JSON.parse(localStorage.getItem('group')).payment_gateway_id,
        time_format: JSON.parse(localStorage.getItem('group')).time_format,
        date_format: JSON.parse(localStorage.getItem('group')).date_format,
        game_name: '',
        game_images: [
          {
            url: 'https://console.offthecouch.io/img/placeholder.jpg',
            scale: 75,
            background_color: 'black',
            description: ''
          }
        ],
        valueTypes: [
          {
            name: 'Per booking',
            id: 0
          },
          {
            name: 'Per participant',
            id: 1
          }
        ],
        modals: {
          image: false
        },
        game: {
          name: '',
          min_players_count: 1,
          max_players_count: 1,
          difficulty_level: 1,
          duration_minutes: 0,
          required_resource_count: 1,
          difficulty_color: '#e14eca',
          difficulty_label: 'Difficulty',
          include_game_difficulty: false,
          difficulty_icon: 'star',
          deposit_type: {id: 0},
          description: '',
          deposit_amount: 0
        },
        currencies: currencies.currencies,
        currency_symbol: '$',
        difficulty_level: 0,
        puzzles: [],
        editing: [],
        editName: false,
        groups: '',
        file: '',
        selectedGroup: '',
        loading: false,
        difficulty: 0,
        errs: {
          name: '',
          duration: '',
          pricing_categories: [{category: '', minimum_participants: '', default_participants: '', pricing: [{min_players: '', max_players: '', price: ''}]}],
          per_person_pricing: [],
          per_game_pricing: '',
          per_category_pricing: [],
          companyGroupId: false
        },
        j: 0,
        activeTabIndex: 0,
        pricing_types: [
          {
            id: 1,
            name: 'Per person'
          },
          {
            id: 2,
            name: 'Flat'
          },
        ],
        selectedPricingType: {id: 1},
        per_person_pricing: [],
        per_category_pricing: [],
        per_game_pricing: '',
        pricing_categories: [{
          category: 'Person',
          minimum_participants: 0,
          default_participants: 0,
          pricing: [{min_players: 1, max_players: 2, price: 0}]
        }],
        cat_key: 0,
        active_image: ''
      }
    },
    methods: {
      cancel(){
        this.$router.push('/games/settings')
      },
      async toggleArchive(item, archived){
        this.loading = true
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games/' + item.id + '/archive'
        let data = {
          archive:  archived
        }
        axios.put(url, data, config)
          .then(response => {
            this.loading = false
            swal('Success!', 'The game has been updated.', 'success')
            this.getGame()
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.status == 500){
              swal({
                title: 'Error',
                html: 'The feature has not been enabled as part of your subscription plan.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      toggleActivationPrompt(item){
        if(!item.archived){
          swal({
            title: 'Are you sure?',
            text: `This action will archive the game. It will no longer be available for bookings or waivers.`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm'
          }).then((response)=> {
            if(response.value == true){
              this.toggleArchive(item, 1)
            }
          })
        } else {
          swal({
            title: 'Are you sure?',
            text: `This action will unarchive the game. It will become immediately available for bookings and waivers.`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm'
          }).then((response)=> {
            if(response.value == true){
              this.toggleArchive(item, 0)
            }
          })
        }
      },
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      getDayOfWeek(d){
        if(JSON.parse(localStorage.getItem('group')).show_day_of_week){
          return dayOfWeek(d) + ', '
        }
        return ''
      },
      getLocalTime(d){
        if(!d){
          return
        }
        return localTime(this.time_format, d)
      },
      getFormattedDate(d){
        return this.getDayOfWeek(d) + formattedDate(this.date_format, d)
      },
      getLocalDate(d){
        let local = new Date(d)
        let form = local.getFullYear() + "-" + (local.getMonth() + 1) + "-" + local.getDate();
        let final = this.getFormattedDate(form)
        return final
      },
      setActiveImage(index){
        this.active_image = this.game_images[index]
      },
      deleteImagePrompt(){
        swal({
          title: 'Are you sure?',
          text: `The image will be permanently removed from this game.`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm'
        }).then((response)=> {
          if(response.value == true){
            this.deleteImage()
          }
        })
      },
      async updateImageSettings(){
        this.loading = true
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/images/' + this.active_image.id + `?link_id=` + this.active_image.link_id

        let data = {
          visible_to_customers: this.active_image.visible_to_customers
        }

        axios.put(url, data, config)
          .then(response => {
            this.loading = false
            swal('Success!', 'Image has been updated.', 'success')
            this.getGame('update')

          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.status == 500){
              swal({
                title: 'Error',
                html: 'The feature has not been enabled as part of your subscription plan.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      async deleteImage(){
        this.loading = true
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/images/' + this.active_image.id + `?link_id=` + this.active_image.link_id

        axios.delete(url, config)
          .then(response => {
            this.loading = false
            swal('Success!', 'Image has been deleted.', 'success')
            this.getGame()
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.status == 500){
              swal({
                title: 'Error',
                html: 'The feature has not been enabled as part of your subscription plan.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      async updateImage(value){
        this.loading = true
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/images/' + value.image_id

        let data = {
          url: value.url,
          scale: value.scale,
          description: value.description,
          background_color: value.background_color,
          x: value.x,
          y: value.y,
          is_primary: 0,
          object_fit: value.object_fit
        }

        axios.put(url, data, config)
          .then(response => {
            this.loading = false
            this.getGame('update')
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.status == 500){
              swal({
                title: 'Error',
                html: 'The feature has not been enabled as part of your subscription plan.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      async saveImage(value){
        this.loading = true
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/images'

        let data = {
          game_id: this.game.id,
          url: value.url,
          scale: value.scale,
          description: value.description,
          background_color: value.background_color,
          x: value.x,
          y: value.y,
          is_primary: 0,
          object_fit: value.object_fit
        }

        axios.post(url, data, config)
          .then(response => {
            this.loading = false
            if(this.$route.path === '/games/edit'){
              this.getGame()
            }
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.status == 500){
              swal({
                title: 'Error',
                html: 'The feature has not been enabled as part of your subscription plan.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      getAmount(integer){
        if(integer == null){
          return
        }
        if(this.number_format === 1 && this.currency_format === 0){
          return this.currency_symbol + integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',')
        } else if(this.number_format === 1 && this.currency_format === 1){
          return integer.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',') + ' ' + this.currency
        } else if (this.number_format === 1 && this.currency_format === 1){
          return parseFloat(integer).toFixed(2) + ' ' + this.currency
        }
        return parseFloat(integer).toFixed(2);
      },
      validateAmount(){
        if(isNaN(this.game.deposit_amount)){
          this.game.deposit_amount = 0
          return
        }
        this.game.deposit_amount = this.getAmount(this.game.deposit_amount)
      },
      getMin(pricing, index){
        if(index < 1){
          return 1
        }
        for(let n in pricing){
          if(parseInt(n) === index){
            return pricing[n-1].max_players + 1
          }
        }
        return 1
      },
      addNewCategory(){
        this.pricing_categories.push({
          category: 'Person',
          minimum_participants: 0,
          default_participants: 0,
          pricing: [{
            min_players: 1,
            max_players: 2,
            price: 0,
            error_min_players: '',
            error_max_players: '',
            error_price: ''
          }]
        })
        this.errs.pricing_categories.push({
          category: '',
          minimum_participants: '',
          default_participants: '',
          pricing: [{
            min_players: '',
            max_players: '',
            price: ''
          }]
        })
      },
      removeCategory(item, index){
        item.splice(index, 1);
        this.errs.pricing_categories.splice(index, 1);
      },
      removePriceRange(item, i1, i2){
        item.splice(i2, 1);
        this.errs.pricing_categories[i1].per_person_pricing.splice(i2, 1);
      },
      addPriceRange(category, index){
        if(category.pricing.length > 0){
          category.pricing.push({
            min_players: category.pricing[category.pricing.length - 1].max_players + 1,
            max_players: category.pricing[category.pricing.length - 1].max_players + 2,
            price: 0,
            error_min_players: '',
            error_max_players: '',
            error_price: ''
          })
        } else {
          category.pricing.push({
            min_players: 1,
            max_players: 2,
            price: 0,
            error_min_players: '',
            error_max_players: '',
            error_price: ''
          })
        }
        // this.errs.pricing_categories[index].pricing.push({
        //   min_players: '',
        //   max_players: '',
        //   price: ''
        // })
      },
      switchTab(index){
        this.activeTabIndex = index
        for(let n in this.categories){
          this.categories[n].active = false
        }
        this.categories[index].active = true
        this.j++
      },
      clearRate(item){
        if(item === this.difficulty){
          item = 0
          this.game.difficulty_level = 0
          this.difficulty = 0
        } else {
          this.difficulty = item
        }
      },
      addNewPuzzle(){
        var data = {
          name: "",
          description: "",
          solution: "",
          solution_img: '',
          position: this.puzzles.length
        }
        this.puzzles.push(data)
      },
      async deletePuzzle(puzzle, index){
        swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          confirmButtonText: 'Yes, delete it!',
          showCancelButton: true
        }).then((response)=> {
          if(response.value == true){
            this.puzzleDeletionConfirmed(puzzle, index)
          }
        })
      },
      async puzzleDeletionConfirmed(puzzle, index){
        if(puzzle.id != null){
          let token = await this.getAuthToken()
          let config = {headers: {Authorization: token}}
          let companyId = localStorage.getItem('companyId')
          let url = API_LOCATION + 'companies/' + companyId + '/games/' + this.game.id + '/puzzles/' + puzzle.id

          this.loading = true
          axios.delete(url, config)
            .then(response => {
              this.loading = false
              swal({
                title: 'Puzzle successfully deleted',
                type: 'success',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#1d8cf8',
              })
            })
            .catch(err => {
              this.loading = false
              console.log(err)
              if(err.response.status == 401){
                swal({
                  title: 'Error',
                  html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              }
              else if(err.response.status == 500){
                swal({
                  title: 'Error',
                  html: 'The feature has not been enabled as part of your subscription plan.',
                  type: 'error',
                }).then((response)=> {
                  this.$router.push('/')
                })
              }
              else {
                swal('Error!', 'Something went wrong', 'error')
              }
            })
        }
        this.puzzles.splice(index, 1)
      },
      validatePerPersonPricing(){
        let errors = 0

        for(let n in this.pricing_categories){
          if(this.pricing_categories[n].category === '' || !this.pricing_categories[n].category){
            this.errs.pricing_categories[n].category = 'Category cannot be empty'
            errors++
          } else {
            this.errs.pricing_categories[n].category = ''
          }

          if(this.pricing_categories[n].minimum_participants === '' || this.pricing_categories[n].minimum_participants === undefined){
            this.errs.pricing_categories[n].minimum_participants = 'Minimum participants cannot be empty'
            errors++
          } else {
            this.errs.pricing_categories[n].minimum_participants = ''
          }

          if(this.pricing_categories[n].default_participants === '' || this.pricing_categories[n].default_participants === undefined){
            this.errs.pricing_categories[n].default_participants = 'Default participants cannot be empty'
            errors++
          } else {
            this.errs.pricing_categories[n].default_participants = ''
          }

          for(let m in this.pricing_categories[n].pricing){
            if(this.pricing_categories[n].pricing[m].price === undefined || this.pricing_categories[n].pricing[m].price.length <= 0){
              this.pricing_categories[n].pricing[m].error_price = 'Price cannot be empty'
              errors++
            } else {
              this.pricing_categories[n].pricing[m].error_price = ''
            }

            if(this.pricing_categories[n].pricing[m].max_players === undefined || this.pricing_categories[n].pricing[m].max_players.length <= 0){
              this.pricing_categories[n].pricing[m].error_max_players = 'Invalid value'
              errors++
            } else if (this.pricing_categories[n].pricing[m].max_players < this.pricing_categories[n].pricing[m].min_players){
              this.pricing_categories[n].pricing[m].error_max_players = 'Invalid range'
              this.pricing_categories[n].pricing[m].error_min_players = 'Invalid range'
              errors++
            } else {
              this.pricing_categories[n].pricing[m].error_max_players = ''
            }

            if(this.pricing_categories[n].pricing[m].min_players === undefined || this.pricing_categories[n].pricing[m].min_players.length <= 0){
              this.pricing_categories[n].pricing[m].error_min_players = 'Invalid value'
              errors++
            } else if (this.pricing_categories[n].pricing[m].max_players < this.pricing_categories[n].pricing[m].min_players){
              this.pricing_categories[n].pricing[m].error_max_players = 'Invalid range'
              this.pricing_categories[n].pricing[m].error_min_players = 'Invalid range'
              errors++
            } else {
              this.pricing_categories[n].pricing[m].error_min_players = ''
            }
          }

        }
        this.cat_key++
        return errors > 0 ? false : true
      },
      isGameValid(){
        let errors = 0
        if(this.game.name === ''){
          this.errs.name = 'Game name cannot be empty'
          errors++
        } else {
          this.errs.name = ''
        }

        if(Number(this.game.duration_minutes) < 0 || isNaN(this.game.duration_minutes)){
          this.errs.duration = 'Invalid duration'
          errors++
        } else {
          this.errs.duration = ''
        }
        return errors > 0 ? false : true
      },
      async createGame(){
        if(!this.isGameValid()){
          return
        }
        if (parseInt(this.game.difficulty_level) === 0 ){
          this.game.difficulty_level = 6
        }

        this.loading = true
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games'
        let data = {
          name: this.game.name,
          allow_deposit: this.game.allow_deposit,
          require_deposit: this.game.require_deposit,
          deposit_amount: this.game.deposit_amount,
          deposit_type: this.game.deposit_type.id,
          min_players_count : parseInt(this.game.min_players_count),
          max_players_count: parseInt(this.game.max_players_count),
          duration_minutes: parseInt(this.game.duration_minutes),
          difficulty_level: parseInt(this.game.difficulty_level),
          include_game_difficulty: this.game.include_game_difficulty,
          difficulty_icon: this.game.difficulty_icon,
          difficulty_label: this.game.difficulty_label,
          difficulty_color: this.game.difficulty_color,
          description: this.game.description,
          img: this.game.img,
          puzzles: this.puzzles,
          pricing_type_id: this.selectedPricingType.id,
          pricing_categories: this.pricing_categories,
          cumulative_category_pricing: this.game.cumulative_category_pricing,
          hide_pricing_rate: this.game.hide_pricing_rate,
          required_resource_count: this.game.required_resource_count
        }

        this.axios.post(url, data, config)
          .then(response => {
            swal('Success', 'Game has been created', 'success')
            this.$router.push({ path: '/games/settings'})
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.status == 500){
              swal({
                title: 'Error',
                html: 'The feature has not been enabled as part of your subscription plan.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      async editGame(){
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games/' + this.game.id
        let data = {
          name: this.game.name,
          allow_deposit: this.game.allow_deposit,
          require_deposit: this.game.require_deposit,
          deposit_amount: this.game.deposit_amount,
          deposit_type: this.game.deposit_type.id,
          min_players_count : parseInt(this.game.min_players_count),
          max_players_count: parseInt(this.game.max_players_count),
          duration_minutes: parseInt(this.game.duration_minutes),
          difficulty_level: parseInt(this.game.difficulty_level),
          include_game_difficulty: this.game.include_game_difficulty,
          difficulty_icon: this.game.difficulty_icon,
          difficulty_label: this.game.difficulty_label,
          difficulty_color: this.game.difficulty_color,
          description: this.game.description,
          img: this.game.img,
          puzzles: this.puzzles,
          pricing_type_id: this.selectedPricingType.id,
          pricing_categories: this.pricing_categories,
          cumulative_category_pricing: this.game.cumulative_category_pricing,
          hide_pricing_rate: this.game.hide_pricing_rate,
          required_resource_count: this.game.required_resource_count,
          block_concurrent_events_when_booked: this.game.block_concurrent_events_when_booked
        }

        return new Promise( function(resolve) {
          axios.put(url, data, config)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              resolve(err)
            })
        })
      },
      async handleUpload(item){
        let file = event.target.files[0];
        item.uploaded_image = URL.createObjectURL(file);

        this.file = file
        this.$emit('change', file);
        this.loading = true
        let save = await this.saveFile('puzzles')
        item.solution_img = this.uploadUrl.split("?")[0]
        this.loading = false
      },
      handlePreview(event) {
        let file = event.target.files[0];
        console.log(file)
        this.game.img = URL.createObjectURL(file);
        this.file = file
        console.log(file)
        this.$emit('change', file);
      },
      getAuthToken() {
        return new Promise ( function(resolve) {
          Auth.currentSession()
            .then(data =>{
              resolve(data.idToken.jwtToken)
            })
            .catch(err => console.log(err))
        })
      },
      async getGroups() {
        this.loading = true
        var token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let companyId = localStorage.getItem('companyId')
        let url = API_LOCATION + 'companies/' + companyId + '/groups'

        axios.get(url, config)
          .then(response => {
            this.loading = false
            this.groups = response.data
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              this.$router.push('/forbidden')
            } else {
              swal('Error!', 'Something went wrong', 'error')
            }
        })
      },
      async getPuzzles(id) {
        this.loading = true
        this.puzzles = []
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let url = API_LOCATION + 'companies/' + companyId + '/games/' + id + '/puzzles'
        axios.get(url, config)
          .then(response => {
            this.loading = false
            this.puzzles = response.data
            this.puzzles.sort((a, b) => (a.position > b.position) ? 1 : -1)
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.status == 500){
              swal({
                title: 'Error',
                html: 'The feature has not been enabled as part of your subscription plan.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      },
      toggleEditName() {
        this.editName = !this.editName
      },
      async saveGame (source) {
        if(!this.isGameValid()){
          return
        }
        if (parseInt(this.game.difficulty_level) === 0 ){
          this.game.difficulty_level = 6
        }
        //validate per person pricing
        if(this.selectedPricingType.id === 1 && !this.validatePerPersonPricing()) {
          return
        }
        // } else if (this.selectedPricingType.id === 2 && !this.validatePerGamePricing()){
        //   return

        this.loading = true
        if(source !== 'puzzles'){
          await this.saveFile()
        }

        let res = await this.editGame()
        this.loading = false
        swal('Success!', 'The game has been updated.', 'success')

        if (parseInt(this.game.difficulty_level) === 6 ){
          this.game.difficulty_level = 0
        }
        await this.getGame()
      },
      async getSignedURL (file) {
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let url = API_LOCATION + 'upload'

        let payload = {
          filePath: file.name,
          contentType: file.type
        }

        return new Promise ( function(resolve) {
          axios.post(url, payload, config)
            .then((res) => {
              resolve(res.data)
            })
            .catch((err) => {
              console.error(err)
            })
        })
      },
      async saveFile(source) {
        if(this.file != ''){
          this.uploadUrl = await this.getSignedURL(this.file)
          let uploaded = await this.uploadFile(this.file)
          //Get the image URL
          let imgUrl = this.uploadUrl.split("?")
          if(source !== 'puzzles'){
            this.game.img = imgUrl[0]
          }
        }
      },
      setSelectedGroup(){
        this.selectedGroup = this.game.company_group_id
      },
      uploadFile (file) {
        let url = this.uploadUrl
        let config = { headers: {
            'Content-Type': file.type
          }}

        let payload = file
        return new Promise ( function(resolve) {
          axios.put(url, payload, config)
            .then((res) => {
              resolve(res)
            })
            .catch((err) => {
              console.error(err)
            })
        })
      },
      async getGame(source){
        this.loading = true
        let token = await this.getAuthToken()
        let config = {headers: {Authorization: token}}
        let companyId = localStorage.getItem('companyId')
        let companyGroupId = JSON.parse(localStorage.getItem('group')).id
        let gameId = this.$route.query.id
        let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/games/' + gameId
        axios.get(url, config)
          .then(response => {
            this.game = response.data.game
            this.game_images = response.data.game_images
            if(this.game_images.length > 0){
              this.active_image = this.game_images[0]
            }
            this.game_name = this.game.name
            this.game.block_concurrent_events_when_booked = Boolean(this.game.block_concurrent_events_when_booked)
            this.game.include_game_difficulty = Boolean(this.game.include_game_difficulty)
            this.game.deposit_amount = this.getAmount(this.game.deposit_amount)
            this.game.deposit_type = { id: this.game.deposit_type}
            this.pricing_types = response.data.pricing_types
            this.selectedPricingType = {id: this.game.pricing_type_id }

            if(response.data.pricing_categories.length <= 0){
              this.pricing_categories.push({
                category: 'Person',
                minimum_participants: 0,
                pricing: [{min_players: 1, max_players: 2, price: 0}]
              })
              this.errs.pricing_categories.push({category: '',  minimum_participants: '', pricing: [{min_players: '', max_players: '', price: ''}]})
            } else {
              this.pricing_categories = response.data.pricing_categories

              for(let n in this.pricing_categories){

                this.errs.pricing_categories.push({
                  category: '',
                  minimum_participants: '',
                  pricing: []
                })
                if(!this.pricing_categories[n].pricing){
                  this.pricing_categories[n].pricing = [{min_players: 1, max_players: 2, price: 0}]
                } else {
                  this.pricing_categories[n].pricing = JSON.parse(this.pricing_categories[n].pricing)

                }
                for(let m in this.pricing_categories[n].pricing){
                  this.errs.pricing_categories[n].pricing.push({min_players: '', max_players: '', price: ''})
                }

              }
            }

            if(this.game.difficulty_level === 6){
              this.game.difficulty_level = 0
            }

            this.getPuzzles(this.game.id)
            this.getGroups()
            this.setSelectedGroup()
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if(err.response.status == 401){
              swal({
                title: 'Error',
                html: 'You do not have access to this feature. Reach out to your admin regarding your site permissions.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else if(err.response.status == 500){
              swal({
                title: 'Error',
                html: 'The feature has not been enabled as part of your subscription plan.',
                type: 'error',
              }).then((response)=> {
                this.$router.push('/')
              })
            }
            else {
              swal('Error!', 'Something went wrong', 'error')
            }
          })
      }
    },
    mounted () {
      this.currency_symbol =  _.find(this.currencies, { code: JSON.parse(localStorage.getItem('group')).currency }).symbol
      if(this.$route.path === '/games/edit'){
        this.getGame()
      }
    }
  }
</script>

<style scoped>
.fixed-size-img {
  width: 100%; /* width of container */
  height: 300px; /* height of container */
  object-fit: cover;
}

.img-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-color: #fff;
  z-index: 1;
  text-align: center;
  color: #005ad9;
  opacity: .75;
  border-radius: 9px;
}


</style>
