<template>
  <div>
    <loading-panel :loading="loading"></loading-panel>

    <!--    Breadcrumbs-->
    <breadcrumb style="margin-left: -15px">
      <breadcrumb-item>
        <a href="/roadmap">Roadmap</a>
      </breadcrumb-item>
      <breadcrumb-item>
        <span>{{ ticket.ticket_key }}</span>
      </breadcrumb-item>
    </breadcrumb>

    <div class="row">
      <div class="col-lg-5">
        <card>

          <div class="row">
            <div class="col" >
              <h4 class="card-title">{{ ticket.title }}  <i v-if="ticket.watching" class="tim-icons el-icon-view text-info ml-2"></i></h4>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label>Ticket type</label>
              <p class="text-white" v-if="ticket.ticket_type_name">{{ticket.ticket_type_name}}</p>
            </div>
            <div class="col">
              <label>Feature</label>
              <div>
                <badge :style="{'background-color': ticket.feature_badge_color}">{{ticket.feature_name}}</badge>
              </div>
            </div>
            <div class="col">
              <label>Priority</label>
              <p class="text-white" v-if="ticket.priority">
              <span style="float: left">
                 <i :class="ticket.priority.icon" class="mr-1" aria-hidden="true" :style="{'color' : ticket.priority.color}"></i>
                {{ ticket.priority.name }}
              </span>
              </p>
            </div>
            <div class="col">
              <label>Status</label>
              <div>
                <badge :style="{'background-color': ticket.status_label_color}">{{ticket.status_name}}</badge>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-lg-3">
              <label>Vote count</label>
              <p class="text-white">{{ticket.vote_count}}

                <span v-if="hasPermissions('roadmap_vote') &&
                (ticket.status !== 4 && ticket.status !== 5 && ticket.status !== 9
                        && total_vote_count < available_votes) ||
                        (ticket.status !== 4 && ticket.status !== 5 && ticket.status !== 9
                        && ticket.my_vote_count > 0)">
                  <el-dropdown >
                    <i class="text-primary tim-icons el-icon-more ml-2"></i>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <a v-if="ticket.status !== 4 && ticket.status !== 5 && ticket.status !== 9
                        && total_vote_count < available_votes" class="dropdown-item mt-2 mb-2" @click="modifyTicket(ticket, 'vote', 1, $event)" href="#">
                          <i style="color: #42b883" class="fa fa-thumbs-up mr-2"></i> Vote for this ticket
                        </a>
                        <a v-if="ticket.status !== 4 && ticket.status !== 5 && ticket.status !== 9
                        && ticket.my_vote_count > 0" class="dropdown-item mt-2 mb-2" @click="modifyTicket(ticket, 'vote', 0, $event)" href="#">
                          <i class="fa fa-thumbs-down mr-2 text-danger"></i> Remove vote
                        </a>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </span>
              </p>
            </div>
            <div class="col">
              <label>Watcher count</label>
              <p class="text-white">{{ticket.watcher_count}}
                <span v-if="hasPermissions('roadmap_watch')">
                  <el-dropdown>
                    <i class="text-primary tim-icons el-icon-more ml-2"></i>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <a v-if="!ticket.watching" class="dropdown-item mt-2 mb-2" @click="modifyTicket(ticket, 'watch', 1, $event)" href="#">
                          <i class="tim-icons el-icon-view text-default mr-2"></i> Watch ticket
                        </a>
                        <a v-if="ticket.watching" class="dropdown-item mt-2 mb-2" @click="modifyTicket(ticket, 'watch', 0, $event)" href="#">
                          <i class="tim-icons el-icon-view text-danger mr-2"></i> Stop watching ticket
                        </a>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </span>
              </p>
            </div>
          </div>


          <div class="row mt-3">
            <div class="col" >
              <label>Description</label>
              <div style="background-color: white; border-radius: 3px; padding: 20px">
                <span v-html="ticket.description"></span>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col">
              <base-button type="secondary" v-on:click="goToRoadmap()">Cancel</base-button>
            </div>
          </div>
        </card>
      </div>
      <div class="col">
        <card>
          <h4 class="card-title">Comments</h4>
          <div class="mt-3" v-if="comments.length <= 0">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="tim-icons icon-chat-33"></i>
              </span>
              <h4 class="mt-3">Be the first one to comment.</h4>
            </div>
          </div>

          <div style="color: black;" class="row mb-3" v-for="(c, index) in comments" :key="'C' + index">
            <div class="col">
              <div style="display: flex;">
                <img
                  :src="c.img"
                  class="avatar"
                  alt="app-logo"
                  style="width: 50px; height: 50px; object-fit: contain; margin-right: 10px;"
                />
                <div style="flex: 1;">
                  <span class="font-italic text-white"
                        style="font-size: small;">{{c.first_name}} {{c.last_name}} [{{c.company_name}}] {{getLocalDateTime(c.created)}}</span>
                  <base-alert class="mt-1"
                    style="color: black; margin-bottom: 0px; background-color: white;"
                    v-html="c.comment"
                  ></base-alert>
                  <div v-if="isAuthorized(c.user_id) && hasPermissions('roadmap_comment')">
                    <base-button size="sm" style="padding: 0" link v-on:click="editComment(c)" type="primary">Edit</base-button>
                    <base-button size="sm" link type="primary" v-on:click="deleteComment(c)">Delete</base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="hasPermissions('roadmap_comment')">
            <h4 v-if="!editing_comment" class="card-title">New comment</h4>
            <h4 v-if="editing_comment" class="card-title">Edit comment</h4>
            <div class="row">
              <div class="col">
                <div style="background-color: white; border-radius: 5px;">
                  <div id="editor-container-comment" style="background-color: white; border-radius: 5px;"></div>
                </div>
              </div>
            </div>

            <div class="row mt-3" v-if="!editing_comment">
              <div class="col">
                <base-button type="primary" @click="createComment()">Create comment</base-button>
              </div>
            </div>

            <div class="row mt-3" v-if="editing_comment">
              <div class="col">
                <base-button class="mr-2" type="default" @click="cancelUpdateComment()">Cancel</base-button>
                <base-button type="primary" @click="updateComment()">Update comment</base-button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>

  </div>
</template>

<script>
import Quill from "quill";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import {Auth} from "aws-amplify";
import {localDateTime} from "@/plugins/dates";
import BaseAlert from "@/components/BaseAlert";
import {Badge} from "@/components";
import swal from "sweetalert2";
import BreadcrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import {Dropdown, DropdownItem, DropdownMenu} from "element-ui";

export default {
  name: "TicketDetails",
  components: {
    BaseAlert,
    Badge,
    BreadcrumbItem,
    Breadcrumb,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
  },
  data(){
    return {
      loading: false,
      date_format: JSON.parse(localStorage.getItem('group')).date_format,
      time_format: JSON.parse(localStorage.getItem('group')).time_format,
      quill_comment: null,
      activeTabIndex: 0,
      j: 0,
      priorities: [
        {id: 1, name: "Highest", icon: 'fa fa-angle-double-up', color: '#ec250d'},
        {id: 2, name: "High", icon: 'fa fa-angle-up', color: '#ec250d'},
        {id: 3, name: "Medium", icon: 'tim-icons icon-simple-delete', color: '#ff8d72'},
        {id: 4, name: "Low", icon: 'fa fa-angle-down', color: '#1d8cf8'},
        {id: 5, name: "Lowest", icon: 'fa fa-angle-double-down', color: '#1d8cf8'},
      ],
      ticket: {},
      votes: [],
      comments: [],
      editing_comment: false,
      selectedComment: '',
      total_vote_count: 0,
      available_votes: 5
    }
  },
  methods: {
    hasPermissions(scope){
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      const matchFound = permissions.some(element => element === scope);
      return matchFound
    },
    cancelUpdateComment(){
      this.quill_comment.deleteText(0, this.quill_comment.getLength()); // Clear all content
      this.quill_comment.setSelection(0); // Set the cursor
      this.quill_comment.format('color', 'black'); // Set default formatting
      this.editing_comment = false
    },
    editComment(c){
      this.selectedComment = {... c}
      this.quill_comment.root.innerHTML = c.comment
      this.editing_comment = true
    },
    isAuthorized(user_id){
      let loggedInUser = Number(JSON.parse(localStorage.getItem('profile')).id)
      return user_id === loggedInUser
    },
    async modifyTicket(item, action, modifier, event){
      event.preventDefault()
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/work/' + item.id

      let data = {
        action: action,
        modifier: modifier
      }

      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          let text = 'You are now watching this ticket'
          if(action === 'vote' && modifier === 1){
            text = 'You added a vote to the ticket'
          } else if(action === 'vote' && modifier === 0){
            text = 'You removed a vote from the ticket'
          }  else if(action === 'watch' && modifier === 0){
            text = 'You stopped watching this ticket'
          }
          swal('Success', text, 'success')
          this.getTicket()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } if(err.response.status == 500){
            swal({
              title: 'Error',
              html: err.response.data,
              type: 'error',
            })
          }
        })
    },
    async deleteComment(item){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let ticketId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' +
        companyGroupId + '/work/' + ticketId + '/comments/' + item.id

      axios.delete(url, config)
        .then(response => {
          this.loading = false
          this.getTicket()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    },
    async updateComment(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let ticketId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' +
        companyGroupId + '/work/' + ticketId + '/comments/' + this.selectedComment.id

      let data = {
        comment: this.quill_comment.root.innerHTML
      }

      axios.put(url, data, config)
        .then(response => {
          this.loading = false
          this.quill_comment.deleteText(0, this.quill_comment.getLength()); // Clear all content
          this.quill_comment.setSelection(0); // Set the cursor
          this.quill_comment.format('color', 'black'); // Set default formatting
          this.getTicket()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    },
    async createComment(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let ticketId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/work/' + ticketId + '/comments'

      let data = {
        comment: this.quill_comment.root.innerHTML
      }

      axios.post(url, data, config)
        .then(response => {
          this.loading = false
          this.quill_comment.deleteText(0, this.quill_comment.getLength()); // Clear all content
          this.quill_comment.setSelection(0); // Set the cursor
          this.quill_comment.format('color', 'black'); // Set default formatting
          this.getTicket()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    },
    goToRoadmap(){
      this.$router.push('/roadmap')
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getTicket(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = { headers: { Authorization: token } }
      let companyId = localStorage.getItem('companyId')
      let companyGroupId = JSON.parse(localStorage.getItem('group')).id
      let ticketId = this.$route.query.id
      let url = API_LOCATION + 'companies/' + companyId + '/groups/' + companyGroupId + '/work/' + ticketId

      axios.get(url, config)
        .then(response => {
          this.loading = false
          this.ticket = response.data.ticket
          this.comments = response.data.comments
          this.total_vote_count = response.data.total_vote_count
          this.ticket.priority = this.getPriority(this.ticket.priority)
          this.ticket.titleFixed = this.ticket.title
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    },
    getLocalDateTime(d){
      return localDateTime(d, this.date_format, this.time_format)
    },
    getPriority(priority){
      let p = _.find(this.priorities, {id: priority})
      return p
    },
  },
  mounted(){
    if(this.hasPermissions('roadmap_comment')){
      const toolbarOptions = [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],
        ['link', 'image'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [
          { 'color': [
              "#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
              "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
              "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
              "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
              "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"
            ]},
          { 'background': [] }
        ],         // dropdown with defaults from theme
        ['clean']                                         // remove formatting button
      ];

      this.quill_comment = new Quill('#editor-container-comment', {
        theme: 'snow', // Choose 'snow' or 'bubble'
        placeholder: 'Add a comment...',
        formats: [
          'header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
          'list', 'bullet', 'check', 'indent', 'link', 'image', 'color', 'background'
        ],
        modules: {
          toolbar: toolbarOptions
        },
      });
      this.quill_comment.format('color', 'black');
      this.quill_comment.root.style.color = 'black';
      this.quill_comment.on('text-change', () => {
        if (this.quill_comment.getLength() === 1) { // If the editor is empty
          this.quill_comment.format('color', 'black'); // Reapply black formatting
        }
      });
    }
    this.getTicket()
  }
}
</script>

<style scoped>

</style>
