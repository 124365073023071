import { render, staticRenderFns } from "./LeaderboardSettings.vue?vue&type=template&id=fd0bfb80&scoped=true"
import script from "./LeaderboardSettings.vue?vue&type=script&lang=js"
export * from "./LeaderboardSettings.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd0bfb80",
  null
  
)

export default component.exports